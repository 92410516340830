import * as React from 'react'

import { inject, observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import AuthenticationStore from '~/client/src/shared/stores/domain/Authentication.store'

import InitialState from '../../stores/InitialState'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import BaseActionButton from '../BaseActionButton/BaseActionButton'
import { Loader } from '../Loader'
import StruxhubInput from '../StruxhubInputs/StruxhubInput'
import EmailLinkerFormStore from './EmailLinkerForm.store'

import './EmailLinkerForm.scss'

const formId = 'link-email-form'

interface IProps {
  onComplete: (linkedEmail?: string) => void

  onSkip?: () => void
  emailRequired?: boolean
  auth?: AuthenticationStore
  state?: InitialState
  projectMembersStore?: ProjectMembersStore
}

@inject('auth', 'state', 'projectMembersStore')
@observer
export default class EmailLinkerForm extends React.Component<IProps> {
  private readonly store: EmailLinkerFormStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new EmailLinkerFormStore(
      props.auth,
      props.state,
      props.projectMembersStore,
      props.onComplete,
      props.onSkip,
    )
  }

  public render() {
    if (this.store.shouldShowLoader) {
      return <Loader />
    }

    const { emailRequired = false } = this.props
    const { email, password, confirmationPassword, errorMessage } = this.store

    return (
      <form id={formId} onSubmit={this.handleSubmit}>
        {!emailRequired && (
          <div className="row mb30">
            {Localization.translator.userEmailLinking.intro}
          </div>
        )}
        <StruxhubInput
          type="email"
          label={Localization.translator.email_noun}
          isRequired={emailRequired}
          value={email}
          onChange={this.handleEmailChange}
          onValueReset={this.handleEmailReset}
        />

        <div className={`password-section ${email ? 'show' : 'hide'}`}>
          <div className="row">
            {Localization.translator.userEmailLinking.setEmailWithPassword}
          </div>
          <div className="col mt40">
            <StruxhubInput
              type="password"
              autoComplete="new-password"
              label={Localization.translator.password}
              isRequired={!!email}
              value={password}
              onChange={this.handlePasswordChange}
              onValueReset={this.handlePasswordReset}
            />
            <StruxhubInput
              type="password"
              autoComplete="new-password"
              label={Localization.translator.confirmNewPassword}
              isRequired={!!email}
              value={confirmationPassword}
              onChange={this.handleConfirmPasswordChange}
              onValueReset={this.handleConfirmPasswordReset}
            />
          </div>

          <div className="row">
            {Localization.translator.passwordErrors.weakPassword}
          </div>
        </div>

        {errorMessage && (
          <div className="text large bold red mt10">{errorMessage}</div>
        )}

        <div className="row mt40">
          <BaseActionButton
            formId={formId}
            isEnabled={true}
            className="scale-blue-theme flex-pull-right"
            title={Localization.translator.done}
          />
        </div>
      </form>
    )
  }

  private handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.store.email = e.currentTarget.value
    this.store.resetError()
  }

  private handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.store.password = e.currentTarget.value
    this.store.resetError()
  }

  private handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.store.confirmationPassword = e.currentTarget.value
    this.store.resetError()
  }

  private handleSubmit = (evt: React.SyntheticEvent<HTMLFormElement>) => {
    evt.preventDefault()
    this.store.linkEmailProvider()
  }

  private handleEmailReset = () => {
    this.store.email = ''
    this.store.resetError()
  }

  private handlePasswordReset = () => {
    this.store.password = ''
    this.store.resetError()
  }

  private handleConfirmPasswordReset = () => {
    this.store.confirmationPassword = ''
    this.store.resetError()
  }
}

import * as React from 'react'

interface IProps {
  countLabel: string
}

export default class CountLabel extends React.PureComponent<IProps> {
  public render() {
    return (
      <span className="ml5 text extra-large light">
        ({this.props.countLabel})
      </span>
    )
  }
}

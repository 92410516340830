import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

import Config from '~/client/src/shared/Config'

export * from './types.generated'

const cacheDefaultOptions: DefaultOptions = {
  query: { fetchPolicy: 'no-cache' },
  mutate: { fetchPolicy: 'no-cache' },
  watchQuery: { fetchPolicy: 'no-cache' },
}

const omitTypename = (key: string, value: any) =>
  key === '__typename' ? undefined : value

export function createGraphClient(
  getAccessToken: () => Promise<string>,
  url?: string,
): ApolloClient<NormalizedCacheObject> {
  // Tenantless mode
  if (!url) {
    return new ApolloClient({
      uri: null,
      cache: new InMemoryCache(),
      defaultOptions: cacheDefaultOptions,
    })
  }

  const secureSuffix =
    (!url.includes('localhost') && Config.TENANTLESS_MODE) ||
    location.protocol === 'https:'
      ? 's'
      : ''

  const wsLink = new WebSocketLink({
    uri: `ws${secureSuffix}://${url}/graphql`,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: async () => {
        const accessToken = await getAccessToken()
        return {
          authorization: `Bearer ${accessToken}`,
        }
      },
    },
  })

  const authLink = setContext(async (_, { headers }) => {
    const accessToken = await getAccessToken()
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    }
  })

  const middleWareLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename,
      )
    }

    return forward(operation)
  })

  const retryLink = new RetryLink()

  const httpLink = authLink
    .concat(middleWareLink)
    .concat(retryLink)
    .concat(
      new HttpLink({
        uri: `http${secureSuffix}://${url}/graphql`,
      }),
    )

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink,
  )

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: splitLink,
    connectToDevTools: true,
    cache: new InMemoryCache(),
    defaultOptions: cacheDefaultOptions,
  })

  client.addResolvers({
    Date: {
      Date: rootValue => rootValue,
    },
  })

  return client
}

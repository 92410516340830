import * as React from 'react'

import { observer } from 'mobx-react'

import CompactConfirmDialog from '~/client/src/shared/components/CompactConfirmDialog/CompactConfirmDialog'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import Localization from '~/client/src/shared/localization/LocalizationManager'

interface IProps {
  isShown: boolean
  onHide(): void
  onApply(): void
}

@observer
export default class FormDeleteConfirm extends React.Component<IProps> {
  public render() {
    const { isShown, onHide, onApply } = this.props

    if (!isShown) return null

    return (
      <MenuCloser isOpen closeMenu={onHide}>
        <CompactConfirmDialog
          title={Localization.translator.deleteWorkflow}
          isShown={isShown}
          onHide={onHide}
          onApply={onApply}
          applyButtonText={`${Localization.translator.yes}, ${Localization.translator.deleteWorkflow}`}
        >
          <div className="text extra-large">
            {Localization.translator.workflowDeletionDialogDescr.confirmDelete}
            <br />
            {Localization.translator.workflowDeletionDialogDescr.confirmDelete2}
          </div>
        </CompactConfirmDialog>
      </MenuCloser>
    )
  }
}

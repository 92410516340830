import { IProject, IQuery, InviteStatus } from '~/client/graph'
import { DeliveriesByProjectIdDocument } from '~/client/graph/operations/generated/Deliveries.generated'
import { UnreadNotificationsCountDocument } from '~/client/graph/operations/generated/Notifications.generated'
import mobileRoutes, {
  shouldRedirect,
} from '~/client/src/mobile/constants/mobileRoutes'
import MobileEventStore from '~/client/src/mobile/stores/EventStore/MobileEvents.store'
import MobileRootStore from '~/client/src/mobile/stores/MobileRoot.store'
import Config from '~/client/src/shared/Config'
import {
  isDeliveryInfoPageRequested,
  isSignUpPageRequested,
} from '~/client/src/shared/constants/commonRoutes'
import Project from '~/client/src/shared/models/Project'
import {
  GET_ACTIVITY_TREE,
  GET_DELIVERY_TREE,
} from '~/client/src/shared/stores/EventStore/EffectsProcessors/WorkerProcessor/workerConstants'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import {
  handleGetDeliveryTicket,
  handleGetDeliveryTicketResult,
  shouldRedirectToAuthLinkingPage,
} from '~/client/src/shared/stores/EventStore/handlers'
import getIntegrationsConfigName from '~/client/src/shared/utils/getIntegrationsConfigName'
import { NOOP } from '~/client/src/shared/utils/noop'

import registerMobileChangeEvents from './mobileChangeEvents'

export default function (
  eventsStore: MobileEventStore,
  rootStore: MobileRootStore,
) {
  eventsStore.on(e.INIT_APP_SELECT_PROJECT, () => {
    return {
      dispatch: [e.DISPLAY_PROJECT_PICKER],
    }
  })

  eventsStore.on(
    e.PROJECT_TYPE_OPTIONS_RECEIVED,
    (state, { projectTypeOptions }: IQuery) => {
      state.loading.set(e.LOAD_AND_LISTEN_TO_PROJECT_TYPE_OPTIONS, false)

      state.projectTypeOptions =
        projectTypeOptions.data[0] || state.getDefaultProjectTypeOptions()

      return {
        dispatchN: [[e.LISTEN_TO_PROJECT_TYPE_OPTIONS]],
      }
    },
  )

  // home page could not be opened by default without info on logistics config unlike any other page
  eventsStore.on(e.GET_LOGISTICS_CONFIG_AND_OPEN_DEFAULT_PAGE, state => {
    if (Config.TENANTLESS_MODE) {
      rootStore.common.displayRedirectorView()
      return
    }

    state.loading.set(e.GET_LOGISTICS_CONFIG_AND_OPEN_DEFAULT_PAGE, true)
    const shouldForceRedirect =
      rootStore.common.history.location.pathname === mobileRoutes.PROJECTS

    return {
      flow: {
        startWith: [[e.LOAD_AND_LISTEN_TO_LOGISTICS_CONFIGURATIONS]],
        rules: [
          {
            when: 'on',
            event: e.LOGISTICS_CONFIGURATIONS_RECEIVED,
            dispatch: [e.DISPLAY_DEFAULT_MOBILE_PAGE, shouldForceRedirect],
          },
        ],
      },
    }
  })

  /**
   * Complete initialization if a project was located
   */
  eventsStore.on(e.INIT_APP_3, state => {
    state.loading.set(e.INIT_APP_2, false)

    if (!Config.TENANTLESS_MODE && shouldRedirectToAuthLinkingPage(rootStore)) {
      return {
        dispatchN: [
          [e.LOAD_AND_LISTEN_TO_COMPANIES],
          [e.DISPLAY_SAVE_PASSWORD],
        ],
      }
    }

    const {
      activeProject,
      projectTypeOptions: { isDeliveriesDisabled, isTrackerDisabled },
    } = state

    if (!activeProject.id) {
      return
    }

    state.filters.currentDate = rootStore.projectDateStore.startOfDay(
      new Date(),
    )
    state.delivery.compactView.activeDate =
      rootStore.projectDateStore.startOfDay(new Date())

    const dispatchN: any = [
      [e.GET_PROJECT_CONFIG],
      [
        e.GET_CONFIGURATION,
        getIntegrationsConfigName(state.activeProject.id),
        e.PROJECT_INTEGRATIONS_CONFIGS_RECEIVED,
      ],
      [e.LOAD_AND_LISTEN_TO_MATERIAL_OPTIONS],
      [e.LOAD_AND_LISTEN_TO_PROJECT_ADDRESS],
      [e.LISTEN_TO_PROJECT],
      [e.LOAD_AND_LISTEN_TO_PROJECT_SU_OPTIONS],
      [e.LOAD_AND_LISTEN_TO_PROJECT_COLORING_OPTIONS],
      [e.LOAD_AND_LISTEN_TO_ACTIVITIES_CONFIGURATIONS],
      [e.GET_LOGISTICS_CONFIG_AND_OPEN_DEFAULT_PAGE],
      [e.LOAD_AND_LISTEN_TO_LOGISTICS_CONFIGURATIONS],
      [e.LOAD_AND_LISTEN_TO_FORMS_CONFIGURATIONS],
      [e.LOAD_AND_LISTEN_TO_LOGISTICS_CONFIGURATIONS],
      [e.LOAD_AND_LISTEN_TO_DELIVERY_CONFIGURATIONS],
      [e.LOAD_AND_LISTEN_TO_ZONEMAP_THRESHOLDS],
      [e.LOAD_AND_LISTEN_TO_PROJECT_HISTORY],
      [e.LOAD_AND_LISTEN_TO_FORM_CATEGORIES],
      [e.LOAD_AND_LISTEN_TO_PERMIT_TYPES],
      [e.REGISTER_SERVICE_WORKER],
      [e.GET_PROJECT_MEMBERS],
      [e.LOAD_AND_LISTEN_TO_OPERATION_RULES],
      [e.LOAD_AND_LISTEN_TO_PROJECT_TEAMS],
      [e.LOAD_AND_LISTEN_TO_PROJECT_ROLES],
      [e.LOAD_AND_LISTEN_TO_PROJECT_TRADES],
      [e.LOAD_LOCATIONS_AND_LISTEN_CHANGES],
      [e.LOAD_AND_LISTEN_TO_SITE_PERMITS],
      [e.LOAD_AND_LISTEN_TO_SITE_PERMIT_INSPECTIONS],
      [e.LOAD_AND_LISTEN_TO_SITE_PERMIT_INSPECTION_CHANGES],
      [e.LOAD_AND_LISTEN_TO_COMPANIES],
      [e.LOAD_AND_LISTEN_TO_USER_PROJECTS],
      [e.LOAD_AND_LISTEN_TO_SITEMAPS],
      [e.LOAD_AND_LISTEN_TO_GLOBE_VIEWS],
      [e.LISTEN_TO_GLOBE_VIEW_ITEMS_DATA],
      [e.LOAD_AND_LISTEN_TO_BASEMAPS],
      [e.LOAD_AND_LISTEN_TO_SITEMAP_ITEMS],
      [e.LOAD_ANNOUNCEMENTS_AND_LISTEN_CHANGES],
      [e.LOAD_ANNOUNCEMENT_ASSIGNMENTS_AND_LISTEN_CHANGES],
      [e.LOAD_AND_LISTEN_TO_MESSAGES],
      [e.LOAD_AND_LISTEN_TO_PHOTOS],
      [e.LOAD_AND_LISTEN_TO_SCANNERS],
      [e.LOAD_AND_LISTEN_TO_SCAN_HISTORIES],
      [e.LOAD_AND_LISTEN_TO_ANNOUNCEMENTS_FOLLOWINGS],
      [e.LOAD_AND_LISTEN_TO_SITE_PERMITS_FOLLOWINGS],
      [e.LOAD_AND_LISTEN_TO_CASTS_FOLLOWINGS],
      [e.LOAD_AND_LISTEN_TO_SITE_PERMIT_ASSIGNMENTS],
      [e.LOAD_CAST_ASSIGNMENTS],
      [e.LOAD_CLOSURES_AND_LISTEN_CHANGES],
      [e.LOAD_AND_LISTEN_TO_MATERIAL_CONFIGURATION],
      [e.LISTEN_TO_USERS],
      [e.UPDATE_USER_PREFERENCES],
      [e.LOAD_AND_LISTEN_TO_NOTIFICATIONS_COUNT],
      [e.LOAD_AND_LISTEN_TO_MATERIAL_CATEGORIES],
      [e.LOAD_AND_LISTEN_TO_MATERIALS],
    ]

    if (!isTrackerDisabled) {
      dispatchN.push(
        [e.LOAD_AND_LISTEN_TO_ACTIVITY_CODE_LOCATION_RELATIONSHIPS],
        [e.LOAD_AND_LISTEN_TO_ACTIVITY_COMPANY_RELATIONSHIPS],
        [e.LOAD_AND_LISTEN_TO_THREADS],
        [e.LOAD_AND_LISTEN_TO_CONTENT_OBJECTS],
        [e.GET_SCHEDULE, new Date()],
        [e.LOAD_AND_LISTEN_TO_ACTIVITY_PRESETS],
        [e.GET_ACTIVITY_CUSTOM_FILTERS],
        [e.GET_HIERARCHY_CONFIGURATION],
        [e.LOAD_AND_LISTEN_TO_ACTIVITY_ASSIGNMENTS],
        [e.LOAD_AND_LISTEN_TO_ACTIVITY_FOLLOWINGS],
      )
    }

    if (!isDeliveriesDisabled) {
      dispatchN.push(
        [e.LOAD_AND_LISTEN_TO_DELIVERY_FIELDS_CONFIGURATIONS],
        [e.LISTEN_AND_LOAD_DELIVERIES],
        [e.LOAD_AND_LISTEN_TO_DELIVERY_ASSIGNMENTS],
        [e.LOAD_AND_LISTEN_TO_DELIVERIES_FOLLOWINGS],
        [e.LISTEN_TO_RECURRING_DELIVERIES_SETTING],
        [e.LOAD_AND_LISTEN_TO_ALL_DELIVERY_ATTRIBUTES],
      )
    } else {
      rootStore.deliveriesStore.isDataReceived = true
    }

    if (!state.isTilesetsDisabled) {
      dispatchN.push([e.LOAD_TILESETS], [e.LISTEN_TO_TILESETS])
    }

    if (activeProject && state.userActiveProjectSettings) {
      if (state.userActiveProjectSettings.isPending) {
        state.userActiveProjectSettings.setInviteStatus(InviteStatus.Accepted)
        dispatchN.push([
          e.SAVE_USER_PROJECTS,
          [state.userActiveProjectSettings.toDto()],
        ])
      }
    } else {
      state.didInitialize = false
    }

    return {
      dispatchN,
    }
  })

  eventsStore.on(e.LOAD_AND_LISTEN_TO_NOTIFICATIONS_COUNT, state => {
    return {
      graphQuery: {
        query: UnreadNotificationsCountDocument,
        variables: {
          projectId: state.activeProject.id,
        },
        onSuccess: [e.NOTIFICATIONS_COUNT_RECEIVED],
        onError: [e.REQUEST_ERROR, e.LOAD_AND_LISTEN_TO_NOTIFICATIONS_COUNT],
      },
      dispatch: [e.LISTEN_TO_NOTIFICATIONS_COUNT],
    }
  })

  eventsStore.on(
    e.NOTIFICATIONS_COUNT_RECEIVED,
    (state, { unreadNotificationsCount }: IQuery) => {
      state.unreadNotificationsCount = unreadNotificationsCount
    },
  )

  eventsStore.on(
    e.CALCULATE_ACTIVITY_TREE,
    (state, isLoading, activityTreeData, onSuccess) => {
      state.loading.set(e.CALCULATE_ACTIVITY_TREE, isLoading)
      return {
        worker: {
          type: GET_ACTIVITY_TREE,
          data: JSON.stringify(activityTreeData),
          onSuccess: [e.CALCULATE_ACTIVITY_TREE_SUCCESS, onSuccess],
          onError: [e.COMPLETE_REQUEST, e.CALCULATE_ACTIVITY_TREE],
        },
      }
    },
  )

  eventsStore.on(
    e.CALCULATE_DELIVERY_TREE,
    (state, deliveryTreeData, onSuccess) => {
      state.loading.set(e.CALCULATE_DELIVERY_TREE, true)

      return {
        worker: {
          type: GET_DELIVERY_TREE,
          data: JSON.stringify(deliveryTreeData),
          onSuccess: [e.CALCULATE_DELIVERY_TREE_SUCCESS, onSuccess],
          onError: [e.COMPLETE_REQUEST, e.CALCULATE_DELIVERY_TREE],
        },
      }
    },
  )

  eventsStore.on(
    e.CALCULATE_DELIVERY_TREE_SUCCESS,
    (state, onSuccess, tree) => {
      if (onSuccess) {
        onSuccess(tree)
      }

      state.loading.set(e.CALCULATE_DELIVERY_TREE, false)
    },
  )

  eventsStore.on(
    e.CALCULATE_ACTIVITY_TREE_SUCCESS,
    (state, onSuccess, tree) => {
      if (onSuccess) {
        onSuccess(tree)
      }

      if (state.loading.get(e.CALCULATE_ACTIVITY_TREE)) {
        state.loading.set(e.CALCULATE_ACTIVITY_TREE, false)
        return {
          dispatch: [e.RESET_INPUT_STATUSES],
        }
      }
    },
  )

  eventsStore.on(
    e.STATUS_UPDATES_RECEIVED,
    (state, { statusUpdates }: IQuery) => {
      const { statusUpdatesStore, activitiesStore } = rootStore
      const list =
        statusUpdates?.data.filter(s =>
          activitiesStore.byId.has(s.activityP6Code),
        ) || []
      statusUpdatesStore.receiveStatusUpdates(list)
      state.loading.set(e.LOAD_AND_LISTEN_TO_STATUS_UPDATES, false)

      const dispatchN = []
      if (list.length) {
        dispatchN.push([e.CALCULATE_ACTIVITY_ACTUAL_DATES])
      }

      dispatchN.push([e.LISTEN_TO_STATUS_UPDATES])
      return {
        dispatchN,
      }
    },
  )

  eventsStore.on(
    e.GET_ACTIVITY_FILTERS_SETTINGS_SUCCESS,
    (state, { activityFiltersSettings }) => {
      rootStore.activityFiltersStore.receiveSettings(activityFiltersSettings)
      state.loading.set(e.GET_ACTIVITY_FILTERS_SETTINGS, false)
    },
  )

  eventsStore.on(e.DISPLAY_PROJECT_PICKER, (state, forceRedirect) => {
    const { pathname } = location

    state.loading.set(e.INIT_APP_2, false)
    if (forceRedirect || shouldRedirect(pathname, mobileRoutes.PROJECTS)) {
      // in case history has not been initialized yet, load this route when its innited
      state.initialRoute = mobileRoutes.PROJECTS
      // if it has in fact been inited, lets just go there
      rootStore.common._displayView(state.initialRoute)
    }
  })

  eventsStore.on(e.ACTIVATE_PROJECT_SUCCESS, (state, project: IProject) => {
    state.activeProject = Project.fromDto(project)
    rootStore.clearStoresData()

    eventsStore.terminateGraphSubscriptions()

    // Tenantless login, redirect to the tenant instance
    if (state.tenantUserSession?.activeTenantHost) {
      rootStore.common.displayRedirectorView()
      return
    }

    return {
      flow: {
        startWith: [[e.RESET_INPUT_STATUSES], [e.GET_AUTH_USER_PROJECT]],
        rules: [
          {
            when: 'on',
            event: e.GET_AUTH_USER_PROJECT_SUCCESS,
            dispatch: [e.LOAD_AND_LISTEN_TO_PROJECT_TYPE_OPTIONS],
          },
          {
            when: 'on',
            event: e.PROJECT_TYPE_OPTIONS_RECEIVED,
            dispatch: [e.INIT_APP_3],
            shouldTerminate: true,
          },
        ],
      },
    }
  })

  eventsStore.on(e.DISPLAY_DEFAULT_MOBILE_PAGE, (state, forceRedirect) => {
    const { history } = rootStore.common
    const { pathname } = history.location

    let defaultRoute = '/'
    if (!state.isLogisticsDisabled) {
      defaultRoute = mobileRoutes.HOME
    } else if (!state.isDeliveriesDisabled) {
      defaultRoute = mobileRoutes.DELIVERIES
    } else if (!state.isTrackerDisabled) {
      defaultRoute = mobileRoutes.ACTIVITIES
    } else if (!state.isFormsDisabled) {
      defaultRoute = mobileRoutes.FORMS
    }

    if (forceRedirect || shouldRedirect(pathname, defaultRoute)) {
      rootStore.common._displayView(defaultRoute)
    }
  })

  eventsStore.on(e.GET_PROJECT_MEMBERS_SUCCESS, (state, { users }: IQuery) => {
    state.loading.set(e.GET_PROJECT_MEMBERS, false)
    rootStore.projectMembersStore.receiveList(users.data)
  })

  eventsStore.on(e.GET_DELIVERY_TICKET, handleGetDeliveryTicket)

  eventsStore.on(e.GET_DELIVERY_TICKET_RESULT, handleGetDeliveryTicketResult)

  eventsStore.on(e.GET_SCHEDULE_SUCCESS, (state, { schedule }: IQuery) => {
    state.activeSchedule = {
      updatedAt: schedule.updatedAt,
      activityQuantity: schedule.activityQuantity,
      companyQuantity: schedule.companyQuantity,
      fileName: schedule.fileName,
      id: schedule.id,
      projectId: schedule.projectId,
    }

    return {
      dispatchN: [
        [e.GET_ACTIVITY_FILTERS_SETTINGS],
        [e.LISTEN_TO_ACTIVITY_CODE_RELATIONSHIP],
        [e.LISTEN_TO_ACTIVITY],
        [e.LISTEN_TO_ACTIVITY_CODE_TYPE],
        [e.LISTEN_TO_ACTIVITY_CODE],
        [e.LISTEN_TO_RESOURCE],
        [e.LISTEN_TO_ACTIVITY_RESOURCE_RELATIONSHIP],
        [e.LOAD_AND_LISTEN_TO_ACTIVITY_COMPANY_RELATIONSHIPS],
      ],
    }
  })

  eventsStore.on(e.INIT_PROJECT_INTEGRATIONS, () => {
    rootStore.concreteDirectIntegrationStore.init()
  })

  eventsStore.on(e.TRY_UNAUTHORIZED_MODE, state => {
    if (
      isDeliveryInfoPageRequested() ||
      isSignUpPageRequested() ||
      location.pathname.includes(mobileRoutes.SEND_SMS_DELIVERY_TICKET)
    ) {
      state.isUnauthorizedMode = true
    }

    return { dispatch: [e.TRY_UNAUTHORIZED_MODE_RESULT] }
  })

  eventsStore.on(e.GO_TO_AVAILABLE_PROJECT, () => {
    rootStore.common.displayProjects()
  })

  eventsStore.on(e.LOAD_DELIVERIES, state => {
    state.loading.set(e.LOAD_DELIVERIES, true)

    const { addMonths, startOfDay } = rootStore.projectDateStore
    const baseDate = startOfDay(Date.now())

    const fromDate = addMonths(baseDate, -1).getTime()
    const toDate = addMonths(baseDate, 1).getTime()

    return {
      graphQuery: {
        query: DeliveriesByProjectIdDocument,
        variables: {
          projectId: state.activeProject.id,
          fromDate,
          toDate,
          limit: 1000,
        },
        onSuccess: [e.LOAD_DELIVERIES_SUCCESS],
        onError: [e.REQUEST_ERROR, e.LOAD_DELIVERIES],
      },
    }
  })

  // service event
  // see
  eventsStore.on(e.SHOW_ONE_TIME_POPUP, NOOP)

  registerMobileChangeEvents(eventsStore, rootStore)
}

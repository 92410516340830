import { IWorkflowsCustomFilter, IWorkflowsFilter } from '~/client/graph'
import {
  DeleteWorkflowCustomFilterDocument,
  SaveWorkflowCustomFilterDocument,
} from '~/client/graph/operations/generated/CustomWorkflowsFilter.generated'

import CustomWorkflowsFilter from '../../types/CustomWorkflowFilter'
import BaseCustomListFiltersStore from '../BaseCustomListFilters.store'

export default class CustomWorkflowsListFiltersStore extends BaseCustomListFiltersStore<
  CustomWorkflowsFilter,
  IWorkflowsCustomFilter
> {
  protected readonly saveMutationDoc = SaveWorkflowCustomFilterDocument
  protected readonly deleteMutationDoc = DeleteWorkflowCustomFilterDocument

  public get byId(): Map<string, CustomWorkflowsFilter> {
    return this.eventsStore.appState.customWorkflowListFilters
  }

  public getInstanceFromDto(
    dto: IWorkflowsCustomFilter,
  ): CustomWorkflowsFilter {
    return CustomWorkflowsFilter.fromDto(dto)
  }

  public createFromValues(
    filtersByFilterType: IWorkflowsFilter[],
    isPublic: boolean,
    defaultForUsers: string[],
    groupBy: string,
  ): CustomWorkflowsFilter {
    return new CustomWorkflowsFilter(
      null,
      '',
      isPublic,
      this.activeProjectId,
      this.activeUserId,
      defaultForUsers,
      filtersByFilterType,
      groupBy,
    )
  }

  public createCopy(original: CustomWorkflowsFilter): CustomWorkflowsFilter {
    const name = original.name + ' - Copy'
    const defaultForUsers = [...original.defaultForUsers]
    const filtersByFilterType = original.filtersByFilterType.map(
      filter =>
        ({ type: filter.type, values: [...filter.values] } as IWorkflowsFilter),
    )
    return new CustomWorkflowsFilter(
      null,
      name,
      original.isPublic,
      original.projectId,
      original.userId,
      defaultForUsers,
      filtersByFilterType,
      original.groupBy,
    )
  }

  public async saveCustomFilter(
    filter: CustomWorkflowsFilter,
  ): Promise<string> {
    const result = await this.saveOne(filter)

    return result?.saveWorkflowsCustomFilter?.id
  }
}

import * as React from 'react'

import { inject, observer } from 'mobx-react'

import FileFullscreenPreview from '~/client/src/shared/components/FileFullscreenPreview/FileFullscreenPreview'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { SHOW_FULLSCREEN_PREVIEW } from '~/client/src/shared/stores/EventStore/eventConstants'

import MobileEventStore from '../../stores/EventStore/MobileEvents.store'
import MobileFullscreenPreviewStore from './MobileFullscreenPreview.store'

import './MobileFullscreenPreview.scss'

interface IMobileFullScreenPreviewProps {
  eventsStore?: MobileEventStore
}

@inject('eventsStore')
@observer
export default class MobileFullScreenPreview extends React.Component<IMobileFullScreenPreviewProps> {
  private readonly store: MobileFullscreenPreviewStore
  private readonly clearPostEventCallback: () => void

  public constructor(props: IMobileFullScreenPreviewProps) {
    super(props)

    this.store = new MobileFullscreenPreviewStore()
    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.onShowFullscreenRequest,
    )
  }

  public componentWillUnmount() {
    if (this.clearPostEventCallback) {
      this.clearPostEventCallback()
    }
  }

  public render() {
    const {
      hideFullscreenPreview,
      shouldShowFullscreenImagePreview,
      files,
      fileIndex,
    } = this.store

    if (!shouldShowFullscreenImagePreview) {
      return <div />
    }

    return (
      <div className="fullscreen">
        <div className="full-height">
          <FileFullscreenPreview
            files={files}
            fileIndex={fileIndex}
            onClose={hideFullscreenPreview}
            isMobile={true}
          />
        </div>
      </div>
    )
  }

  private onShowFullscreenRequest = (eventContext: EventContext) => {
    const [eventType, files, fileIndex] = eventContext.event
    if (eventType === SHOW_FULLSCREEN_PREVIEW) {
      this.store.showFullscreenPreview(files, fileIndex)
    }
  }
}

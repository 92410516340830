import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ISignUpMutationVariables = Types.Exact<{
  projectCode?: Types.Maybe<Types.Scalars['String']>
  firstName: Types.Scalars['String']
  lastName: Types.Scalars['String']
  email?: Types.Maybe<Types.Scalars['String']>
  phoneNumber: Types.Scalars['String']
  companyAlias?: Types.Maybe<Types.Scalars['String']>
  companyId?: Types.Maybe<Types.Scalars['ObjectId']>
}>

export type ISignUpMutation = Pick<Types.IMutation, 'signUp'>

export type IRequestAccessToProjectMutationVariables = Types.Exact<{
  projectCode: Types.Scalars['String']
}>

export type IRequestAccessToProjectMutation = Pick<
  Types.IMutation,
  'requestProjectAccess'
>

export const SignUpDocument = gql`
  mutation SignUp(
    $projectCode: String
    $firstName: String!
    $lastName: String!
    $email: String
    $phoneNumber: String!
    $companyAlias: String
    $companyId: ObjectId
  ) {
    signUp(
      projectCode: $projectCode
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      companyAlias: $companyAlias
      companyId: $companyId
    )
  }
`
export type ISignUpMutationFn = Apollo.MutationFunction<
  ISignUpMutation,
  ISignUpMutationVariables
>

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      projectCode: // value for 'projectCode'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      companyAlias: // value for 'companyAlias'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISignUpMutation,
    ISignUpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISignUpMutation, ISignUpMutationVariables>(
    SignUpDocument,
    options,
  )
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>
export type SignUpMutationResult = Apollo.MutationResult<ISignUpMutation>
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  ISignUpMutation,
  ISignUpMutationVariables
>
export const RequestAccessToProjectDocument = gql`
  mutation RequestAccessToProject($projectCode: String!) {
    requestProjectAccess(projectCode: $projectCode)
  }
`
export type IRequestAccessToProjectMutationFn = Apollo.MutationFunction<
  IRequestAccessToProjectMutation,
  IRequestAccessToProjectMutationVariables
>

/**
 * __useRequestAccessToProjectMutation__
 *
 * To run a mutation, you first call `useRequestAccessToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAccessToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAccessToProjectMutation, { data, loading, error }] = useRequestAccessToProjectMutation({
 *   variables: {
 *      projectCode: // value for 'projectCode'
 *   },
 * });
 */
export function useRequestAccessToProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IRequestAccessToProjectMutation,
    IRequestAccessToProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IRequestAccessToProjectMutation,
    IRequestAccessToProjectMutationVariables
  >(RequestAccessToProjectDocument, options)
}
export type RequestAccessToProjectMutationHookResult = ReturnType<
  typeof useRequestAccessToProjectMutation
>
export type RequestAccessToProjectMutationResult =
  Apollo.MutationResult<IRequestAccessToProjectMutation>
export type RequestAccessToProjectMutationOptions = Apollo.BaseMutationOptions<
  IRequestAccessToProjectMutation,
  IRequestAccessToProjectMutationVariables
>

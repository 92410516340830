import React from 'react'

import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import InitialState from '~/client/src/shared/stores/InitialState'

import commonRoutes, { getTransitionPath } from '../../constants/commonRoutes'
import { LocalStorageKey } from '../../enums/LocalStorageKey'
import Localization from '../../localization/LocalizationManager'
import ApiAuthService from '../../services/ApiAuthService'
import EventsStore from '../../stores/EventStore/Events.store'
import { LOGOUT } from '../../stores/EventStore/eventConstants'
import ProjectsStore from '../../stores/domain/Projects.store'
import TenantsStore from '../../stores/domain/Tenants.store'
import { Loader } from '../Loader'

interface IRedirector {
  state?: InitialState
  projectsStore?: ProjectsStore
  apiAuthService?: ApiAuthService
  tenantsStore?: TenantsStore
  eventsStore?: EventsStore
}

@inject(
  'state',
  'apiAuthService',
  'projectsStore',
  'tenantsStore',
  'eventsStore',
)
@observer
export default class Redirector extends React.Component<IRedirector> {
  @observable private inviteKey: string

  public constructor(props: IRedirector) {
    super(props)

    this.fetchInviteKey()
  }

  @action.bound
  private async fetchInviteKey() {
    if (this.inviteKey) return

    const { state, apiAuthService, tenantsStore } = this.props
    const userSession = state.tenantUserSession

    try {
      const inviteKey = await apiAuthService.getInviteKey(
        userSession.accessToken,
        tenantsStore.activeTenant.host,
      )
      this.inviteKey = inviteKey
    } catch (error) {
      console.error('Error authenticating destination app:', error)
    }
  }

  public render() {
    if (this.url) {
      window.location.href = this.url
    }

    const { projectsStore, tenantsStore } = this.props
    const projectCode = projectsStore.activeProject?.code
    const projectName = projectsStore.activeProject?.name
    const tenantName = tenantsStore.activeTenant?.name
    const tenantHost = tenantsStore.activeTenant?.host

    // Write to session storage to prevent redirect loop
    sessionStorage.setItem(LocalStorageKey.Tenant, tenantHost)

    if (!projectCode || !tenantName) {
      this.props.eventsStore.dispatch(LOGOUT)
    }

    return (
      <Loader
        hint={Localization.translator.redirectingYouTo(
          projectName || projectCode || tenantName,
        )}
      />
    )
  }

  @computed
  private get url(): string {
    if (!this.inviteKey) {
      return null
    }

    const { projectsStore, tenantsStore } = this.props
    const projectCode = projectsStore.activeProject.code
    const tenant = tenantsStore.activeTenant

    if (!tenant?.host) {
      throw new Error(
        `Tenant host is not set, cannot redirect to ${projectCode} project`,
      )
    }

    const protocol = tenant.host.includes('localhost') ? 'http://' : 'https://'
    const route = commonRoutes.HOME
    const path = getTransitionPath(route, projectCode)

    const allParams = {
      inviteKey: this.inviteKey,
      forceInviteKey: 1,
    }

    // write a query string from the params
    const queryString = Object.keys(allParams)
      .filter(key => !!allParams[key])
      .map(key => `${key}=${allParams[key]}`)
      .join('&')

    return `${protocol}${tenant.host}${path}?${queryString}`
  }
}

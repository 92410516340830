export const SHAPE_FILL_LAYER = 'shape_fill'
export const SHAPE_LINE_LAYER = 'shape_line'
export const SHAPE_ARROW_LINE_LAYER = 'shape_arrow_line'
export const PLAN_LAYER = 'plan_layer'
export const TILESET_LAYER = 'tileset_layer'
export const UNDER_PLAN_FILL_LAYER = 'under-plan-fill'
export const UNDER_PLAN_LINE_LAYER = 'under-plan-line'

export const SHAPE_SELECTED_FILL_LAYER = 'shape_selected_fill'
export const SHAPE_SELECTED_LINE_LAYER = 'shape_selected_line'
export const ITEM_EDITOR_SHAPE_FILL_LAYER = 'item_editor_shape_fill'
export const SHAPE_EDITABLE_POINTS_LAYER = 'shape_editable_points'
export const SHAPE_SELECTED_POINTS_LAYER = 'shape_selected_points'
export const POLYLINE_ADDITIONAL_EDITABLE_POINTS_LAYER =
  'polyline_additional_editable_points'
export const TILESET_FILL_LAYER_PREFIX = 'tile_fill_'
export const TILESET_FILL_OUTLINE_LAYER_PREFIX = 'tile_fill_outline_'
export const TILESET_LINE_LAYER_PREFIX = 'tile_line_'
export const TILESET_CIRCLE_LAYER_PREFIX = 'tile_circle_'
export const TILESET_SYMBOL_LAYER_PREFIX = 'tile_symbol_'

import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { ISiteLocation } from '~/client/graph/types.generated'
import * as Icons from '~/client/src/shared/components/Icons'

import SitePermit from '../../models/Permit'
import CompaniesStore from '../../stores/domain/Companies.store'
import PermitTypesStore from '../../stores/domain/PermitTypes.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import { NOOP } from '../../utils/noop'
import { EMPTY_STRING } from '../../utils/usefulStrings'
import PermitTypeIcon from '../PermitTypeIcon/PermitTypeIcon'
import WorkflowBacklinks from '../WorkflowBacklinks/WorkflowBacklinks'
import WorkflowCardLocationLabel from '../WorkflowCard/LocationLabel'
import WorkflowCardStatus from '../WorkflowCard/Status'

import './PermitCard.scss'

interface IProps {
  permit: SitePermit
  className?: string
  onClick?: (permit: SitePermit) => void

  projectDateStore?: ProjectDateStore
  permitTypesStore?: PermitTypesStore
  companiesStore?: CompaniesStore
}

@inject('projectDateStore', 'permitTypesStore', 'companiesStore')
@observer
export default class PermitCard extends React.Component<IProps> {
  public static defaultProps = {
    className: EMPTY_STRING,
    onClick: NOOP,
  }

  public render(): JSX.Element {
    const { className, permit } = this.props

    return (
      <article
        className={classList({
          'permit-card col full-height pa12': true,
          [className]: !!className,
        })}
        onClick={this.onClick}
      >
        {this.renderTitleRow()}
        {this.renderStatusRow()}
        {this.renderCompanyRow()}
        {this.renderLocationsRow(permit.locations)}
        {this.renderLocationsRow(permit.equipment)}
      </article>
    )
  }

  private renderTitleRow = (): JSX.Element => {
    const {
      permit: { getTypeOfPermitType, getCaption, id },
      permitTypesStore,
    } = this.props

    return (
      <div className="row unset-flex-children-direct pb8 ml10 text bold extra-large">
        <PermitTypeIcon
          permitType={getTypeOfPermitType(permitTypesStore)}
          className="no-grow mr4 permit-type-icon"
        />
        <span className="inline-block text-ellipsis">
          {getCaption(permitTypesStore)}
        </span>
        <WorkflowBacklinks id={id} />
      </div>
    )
  }

  private renderStatusRow = (): JSX.Element => {
    const {
      permit: { status, startDate, endDate, isLate, workflowStepLevel },
      projectDateStore: { getWeekdayMonthAndDayToDisplay },
    } = this.props

    return (
      <div className="row pb8 date-status-row">
        <div className="no-grow mr10">
          <WorkflowCardStatus
            status={status}
            workflowStepLevel={workflowStepLevel}
            isLate={isLate}
            alignRight={true}
          />
        </div>
        {`${getWeekdayMonthAndDayToDisplay(
          startDate,
        )}-${getWeekdayMonthAndDayToDisplay(endDate)}`}
      </div>
    )
  }

  private renderCompanyRow = (): JSX.Element => {
    const {
      permit: { companyIds },
      companiesStore: { getCompanyNameById },
    } = this.props

    return (
      <div className="row ml10">
        <Icons.CompanyCompact className="no-grow mr4 company-icon" />
        <span
          className={classList({
            'text large bold ellipsis no-grow no-flex company-mw150': true,
          })}
        >
          {companyIds?.map(id => getCompanyNameById(id)).join(', ')}
        </span>
      </div>
    )
  }

  private renderLocationsRow(locations: ISiteLocation[]): JSX.Element {
    return (
      <div className="row pb8 ml10">
        <WorkflowCardLocationLabel
          locations={locations}
          isOneColor
          shouldShowAsTag
        />
      </div>
    )
  }

  private onClick = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    this.props.onClick(this.props.permit)
  }
}

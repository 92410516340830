import * as React from 'react'

import { Checkbox } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DeliveryControlTypes from '~/client/src/shared/enums/DeliveryControlTypes'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IDeliveryControl from '~/client/src/shared/models/IDeliveryControl'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { EMPTY_STRING, NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import FileInputBase from '../../../FileInput/FileInput'
import Location from '../../../SitemapAttributeSelector/Location'
import StruxhubInput from '../../../StruxhubInputs/StruxhubInput'
import StruxhubPhoneInput from '../../../StruxhubInputs/StruxhubPhoneInput'
import StruxhubTextArea from '../../../StruxhubInputs/StruxhubTextArea'
import DeliveryDetailsStore from '../../DeliveryDetails.store'
import ButtonToggle from './components/ButtonToggle'
import DeliveryFormSelectorWrapper from './components/DeliveryFormSelectorWrapper'
import ForecastField from './components/ForecastField'

import './DeliveryFormFieldWrapper.scss'

// translated

interface IProps {
  projectDateStore?: ProjectDateStore
  deliveryDetailsStore?: DeliveryDetailsStore
  FileInputType: typeof FileInputBase
  onSelectClick: (fieldId: FieldIds, fieldIndex?: number) => void
  onChange?: (fieldId: FieldIds, value: any, fieldIndex?: number) => void
  onFocus?: () => void
  onBlur?: () => void
  onPaste?: (
    event: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  onKeyPress?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  onKeyUp?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  isLabelHidden?: boolean
  isHelperTextCondensed?: boolean
}

const LOCATION_ICON_SIZE = 16

@inject('projectDateStore')
@observer
export default class DeliveryFormFieldWrapper extends React.Component<
  IDeliveryControl & IProps
> {
  public render() {
    const { type, inline, isHidden, onSelectClick } = this.props

    let control
    switch (type) {
      case DeliveryControlTypes.SELECT:
      case DeliveryControlTypes.COMPANY_SELECT:
      case DeliveryControlTypes.USER_SELECT:
      case DeliveryControlTypes.MULTI_COMBOBOX:
        control = (
          <DeliveryFormSelectorWrapper
            {...this.props}
            onClick={onSelectClick}
          />
        )
        break
      case DeliveryControlTypes.DATE:
      case DeliveryControlTypes.TIME:
        control = this.renderTimeOrDateInput()
        break
      case DeliveryControlTypes.IMAGE:
        control = this.renderImageInput()
        break
      case DeliveryControlTypes.CHECKBOX:
        control = this.renderCheckbox()
        break
      case DeliveryControlTypes.TOGGLE:
        control = <ButtonToggle {...this.props} />
        break
      case DeliveryControlTypes.LABEL:
        control = this.renderLabel()
        break
      case DeliveryControlTypes.LOCATION_LABEL:
        control = this.renderLocationLabel()
        break
      case DeliveryControlTypes.TOGGLE_BUTTON:
        control = this.renderToggleButton()
        break
      case DeliveryControlTypes.TEL:
        control = this.renderPhoneNumberInput()
        break
      case DeliveryControlTypes.WEATHER_FORECAST:
        control = (
          <ForecastField
            {...this.props}
            startDate={this.props.deliveryDetailsStore.selectedDates?.startDate}
          />
        )
        break

      default:
        control = this.renderInput()
    }

    return (
      <div
        className={classList({
          hidden: isHidden,
          [`inline-${inline}`]: !!inline,
          'input-wrapper col px8': true,
        })}
      >
        {control}
      </div>
    )
  }

  private renderTimeOrDateInput(): JSX.Element {
    const { type, hints, inline, value, projectDateStore } = this.props
    const {
      getWeekdayMonthDayAndYearToDisplay,
      fromIsoString,
      getFullHourLabel,
      getHoursMinutesFromISOTime,
    } = projectDateStore

    const isDateControl = type === DeliveryControlTypes.DATE

    const label = isDateControl && !!inline ? hints[1] : hints[0]

    let dateTimeValue = ''
    if (isDateControl) {
      dateTimeValue = getWeekdayMonthDayAndYearToDisplay(fromIsoString(value))
    } else {
      const [hours, minutes] = getHoursMinutesFromISOTime(value as string)
      dateTimeValue = value && getFullHourLabel(hours, minutes)
    }

    return (
      <DeliveryFormSelectorWrapper
        {...this.props}
        label={label}
        value={dateTimeValue}
      />
    )
  }

  private renderCheckbox(): JSX.Element {
    const { value, hints, isDisabled, isReadOnly } = this.props
    return (
      <Checkbox
        className="delivery-checkbox my5"
        label={hints[0]}
        onChange={this.onCommonChange}
        disabled={isDisabled}
        readOnly={isReadOnly}
        defaultChecked={value}
      />
    )
  }

  private renderInput(): JSX.Element {
    const { type, hints, inline } = this.props
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, id, index, ...inputProps } = this.props

    const isTextareaControl = type === DeliveryControlTypes.TEXTAREA
    const isLinkifiedTextareaControl =
      type === DeliveryControlTypes.LINKIFIED_TEXT_AREA

    if (isTextareaControl || isLinkifiedTextareaControl) {
      return (
        <StruxhubTextArea
          {...this.props}
          id={index ? `${id}_${index}` : id}
          label={hints[0]}
          isLinkified={isLinkifiedTextareaControl}
          hasShortWidth={!!inline}
          onChange={this.onCommonChange}
        />
      )
    }

    return (
      <StruxhubInput
        {...inputProps}
        id={index ? `${id}_${index}` : id}
        label={hints[0]}
        hasShortWidth={!!inline}
        onChange={this.onCommonChange}
        onValueReset={this.onCommonValueReset}
      />
    )
  }

  private renderLabel(): JSX.Element | null {
    const { value, hints } = this.props

    return value ? (
      <div className="text extra-large center red">{hints[0]}</div>
    ) : null
  }

  private renderLocationLabel = (): JSX.Element => {
    const { value, hints } = this.props

    return (
      <div className="row px8 mt10 mb20">
        <span className="text left large line-extra-large bold text-ellipsis light no-flex mr10">
          {hints[0]}
        </span>
        <span className="row x-end">
          {value ? (
            <Location
              dto={value}
              iconSize={LOCATION_ICON_SIZE}
              tagClassName="flex-unset location-label-tag"
              contentClassName="text large two-line-text-ellipsis"
              isParentChainHidden
            />
          ) : (
            NO_VALUE
          )}
        </span>
      </div>
    )
  }

  private renderToggleButton(): JSX.Element {
    const { value } = this.props

    return (
      <button
        type="button"
        className={classList({
          'toggle-button-control py10 mx30 pointer no-outline': true,
          active: !!value,
        })}
        onClick={this.onToggleClick}
      >
        {Localization.translator.unscheduledDelivery}
      </button>
    )
  }

  private onToggleClick = () => {
    const { id, index, value, onChange } = this.props
    onChange(id, !value, index)
  }

  private renderPhoneNumberInput(): JSX.Element {
    return (
      <StruxhubPhoneInput
        {...this.props}
        label={this.props.hints[0]}
        onChange={this.onPhoneChange}
      />
    )
  }

  private onPhoneChange = (phoneNumber, country) => {
    const { id, index, onChange } = this.props
    onChange(id, { phoneNumber, country }, index)
  }

  private renderImageInput() {
    const {
      id,
      value,
      defaultValue,
      isReadOnly,
      FileInputType,
      hints,
      defaultHint,
      isHidden,
    } = this.props

    const inputValue = value || defaultValue || []
    return (
      !isHidden &&
      inputValue.map((url: string, index: number) => (
        <div className="row py10" key={url + index}>
          <FileInputType
            id={id}
            name={this.getAppropriateHint(hints, index, url, defaultHint)}
            value={url}
            isReadonly={isReadOnly}
            onChange={this.onImageInputChange}
            textClassName="hint text extra-large ml8 lp035"
            className="delivery-details-image-input row"
            shouldHideIconAndOutline={true}
          />
        </div>
      ))
    )
  }

  private getAppropriateHint(
    hints: string[],
    index: number,
    currentValue: string,
    defaultHint: string,
  ): string {
    if (!currentValue) {
      return defaultHint
    }

    if (hints?.length === 1) {
      return hints[0]
    }

    return hints[index]
  }

  private onImageInputChange = (value: string) => {
    const { id, index, onChange } = this.props
    onChange(id, value, index)
  }

  private onCommonChange = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.FormEvent<HTMLTextAreaElement>
      | React.FormEvent<HTMLSelectElement>,
  ) => {
    const { id, index, onChange } = this.props
    onChange(id, event.currentTarget.value, index)
  }

  private onCommonValueReset = () => {
    const { id, index, onChange } = this.props
    onChange(id, EMPTY_STRING, index)
  }
}

import * as React from 'react'

import { classList } from 'react-classlist-helper'

import { ITenant } from '../../stores/domain/Tenants.store'

interface ITenantBlock {
  tenant: ITenant
  isActiveTenant?: boolean
  onClick: (ITenant) => void
}

export function TenantBlock({
  tenant,
  isActiveTenant,
  onClick,
}: Readonly<ITenantBlock>) {
  const handleClick = () => {
    onClick(tenant)
  }

  return (
    <div
      className="project-block indication-click"
      tabIndex={0}
      key={tenant.host}
      onClick={handleClick}
    >
      <div className="row">
        <div
          className={classList({
            'project-name pointer': true,
            active: isActiveTenant,
          })}
        >
          {tenant.name}
        </div>
      </div>
      <div className="row y-start">
        <div className="project-address text-ellipsis">{tenant.host}</div>
      </div>
    </div>
  )
}

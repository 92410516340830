import React, { PropsWithChildren } from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { Popup } from 'react-map-gl'

import SitemapAttributeTag from '../SitemapAttributeTag/SitemapAttributeTag'
import MapViewItemBase from '../SitemapHelpers/models/MapViewItemBase'

import './MapPopup.scss'

interface IProps {
  item: MapViewItemBase

  onClose(): void
  customHeader?: JSX.Element
  className?: string
}

@observer
export default class GeneralMapPopup extends React.Component<
  PropsWithChildren<IProps>
> {
  public render() {
    const { item, onClose, children, customHeader, className } = this.props
    return (
      <Popup
        longitude={item.sitemapItem.coordinates.longitude}
        latitude={item.sitemapItem.coordinates.latitude}
        closeOnClick={false}
        className="mapbox-popup w-fit-content"
        onClose={onClose}
        closeButton={false}
      >
        <div
          className={classList({
            [className]: !!className,
            'col sitemap-draggable-modal relative ba-palette-brand-light no-outline-container':
              true,
          })}
        >
          {customHeader || this.renderHeader()}
          {children}
        </div>
      </Popup>
    )
  }

  private renderHeader() {
    const { item, onClose } = this.props
    return (
      <header className="row x-between text white large bold px12">
        <SitemapAttributeTag
          shouldShowAsTag={false}
          dataObject={item?.dataObject}
        >
          <span>{item.displayName}</span>
        </SitemapAttributeTag>
        <Icon
          icon={IconNames.CROSS}
          className="no-grow pointer"
          onClick={onClose}
        />
      </header>
    )
  }
}

import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { IPermitTypeField, PermitFieldType } from '~/client/graph'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import { MAX_PERMIT_TABLE_ROWS } from '~/client/src/shared/constants/permitTypeFieldsConstants'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import PermitField from '~/client/src/shared/models/PermitField'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import smoothScrollTo from '~/client/src/shared/utils/smoothScrollTo'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import CompanyProfilePreview from '../../../CompanyProfilePreview/CompanyProfilePreview'
import PermitFieldIcon from '../../../PermitFieldIcon/PermitFieldIcon'
import SitemapAttributeTag from '../../../SitemapAttributeTag/SitemapAttributeTag'
import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'
import PermitFieldRemoveIcon from './PermitFieldRemoveIcon'
import PermitFormAdditionalDateField from './PermitFormAdditionalDateField'
import PermitFormCompanyField from './PermitFormCompanyField'
import PermitFormInputField from './PermitFormInputField'
import PermitFormLinkedWorkflowField from './PermitFormLinkedWorkflowField'
import PermitFormLocationField from './PermitFormLocationFIeld'
import PermitFormMeasureField from './PermitFormMeasureField'
import PermitFormPhoneNumberField from './PermitFormPhoneNumberField'
import PermitFormSelectField from './PermitFormSelectField'
import PermitFormTextAreaField from './PermitFormTextAreaField'
import PermitFormUserField from './PermitFormUserField'

const tableFieldToComponentMap = {
  [PermitFieldType.AdditionalDate]: PermitFormAdditionalDateField,
  [PermitFieldType.InputText]: PermitFormInputField,
  [PermitFieldType.InputNumber]: PermitFormInputField,
  [PermitFieldType.Email]: PermitFormInputField,
  [PermitFieldType.PhoneNumber]: PermitFormPhoneNumberField,
  [PermitFieldType.InputTextArea]: PermitFormTextAreaField,
  [PermitFieldType.Measure]: PermitFormMeasureField,
  [PermitFieldType.Select]: PermitFormSelectField,
  [PermitFieldType.User]: PermitFormUserField,
  [PermitFieldType.Time]: PermitFormInputField,
  [PermitFieldType.LocationInformational]: PermitFormLocationField,
  [PermitFieldType.CompanyInformational]: PermitFormCompanyField,
  [PermitFieldType.LinkedWorkflow]: PermitFormLinkedWorkflowField,
}

interface IProps {
  typeField: IPermitTypeField
  store: SitePermitCreationFormStore
  isViewMode: boolean

  fieldsStore: IPermitFieldsStore

  projectDateStore?: ProjectDateStore
  projectMembersStore?: ProjectMembersStore
  locationAttributesStore?: LocationAttributesStore
  companiesStore?: CompaniesStore
  sitePermitsStore?: SitePermitsStore
  permitTypesStore?: PermitTypesStore
}

const VALUE_SEPARATOR = ', '
const SCROLL_ANIMATION_TIME = 0.3

const _rowNumber = (rowNumber: number) => `Row #${rowNumber}`
const _addNewRow = '+ Add a new row'
const _noFieldsInTable = 'There are no fields in the table'

@inject(
  'projectDateStore',
  'projectMembersStore',
  'locationAttributesStore',
  'companiesStore',
  'sitePermitsStore',
  'permitTypesStore',
)
@observer
export default class PermitFormTableField extends React.Component<IProps> {
  private tableRef: HTMLDivElement

  public render() {
    const { typeField, isViewMode, store, fieldsStore } = this.props
    const areRowNumbersShown = this.tableRowValues.length > 1

    if (isViewMode) {
      return this.viewModeElement
    }

    return (
      <div className="permit-field-table col mt8 mb15 px8 brada4 ba-light-input-border bg-light-cool-grey">
        <span className="my10 px8 text bold extra-large">
          {typeField.caption}
        </span>
        <div
          className="table-row-containter overflow-auto"
          ref={this.setTableRef}
        >
          {this.tableRowValues.map((_, index) => (
            <div
              key={index}
              className="brada4 ba-light-input-border bg-white pa8 my20"
            >
              {areRowNumbersShown && (
                <div className="table-row-header row y-center sticky bg-white">
                  <span className="text bold large my10">
                    {_rowNumber(index + 1)}
                  </span>
                  <PermitFieldRemoveIcon
                    index={index}
                    onClick={this.removeRow}
                  />
                </div>
              )}
              {this.tableFields.length ? (
                this.tableFields.map(field => {
                  const TableFieldComponent =
                    tableFieldToComponentMap[field.type.toString()]

                  return (
                    <TableFieldComponent
                      key={`${field.id}_${index}`}
                      typeField={field}
                      store={store}
                      fieldsStore={fieldsStore}
                      tableId={typeField.id}
                      tableRowIndex={index}
                    />
                  )
                })
              ) : (
                <span className="text large bold px10">{_noFieldsInTable}</span>
              )}
            </div>
          ))}
        </div>
        {this.shouldShowAddRow && (
          <span
            className="pointer text end large blue-highlight ellipsis px8 as-end mt10 mb15 mw300"
            title={_addNewRow}
            onClick={this.addNewRow}
          >
            {_addNewRow}
          </span>
        )}
      </div>
    )
  }

  private get viewModeElement(): JSX.Element {
    const { typeField } = this.props

    return (
      <>
        <div className="row no-grow y-start py12">
          <PermitFieldIcon fieldName={typeField.type} />
          <div className="col pr6 overflow-hidden">
            <div className="text light large pb4 w-max-content">
              {typeField.caption}
            </div>
          </div>
        </div>

        <div className="permit-field-table view-mode relative overflow-auto mb15 full-width">
          <table className="pb10" cellPadding={10} cellSpacing={0}>
            <thead className="bg-light-cool-grey sticky">
              <tr>
                <th className="bt-light-grey br-light-grey bb-light-grey">#</th>
                {this.tableFields.map(field => (
                  <th
                    key={field.id}
                    className="field-header bt-light-grey br-light-grey bb-light-grey"
                  >
                    {field.caption}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.tableRowValues.map((tableRow, index) => (
                <tr key={index} className="bb-light-grey bt-light-grey">
                  <td className="br-light-grey bb-light-grey text bold large center">
                    {index + 1}
                  </td>
                  {this.tableFields.map(field => (
                    <td
                      key={`${field.id}_${index}`}
                      className="field-value-cell br-light-grey bb-light-grey"
                    >
                      {this.getFormattedCellValue(field, tableRow)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    )
  }

  private get tableRowValues(): PermitField[][] {
    const { typeField, fieldsStore } = this.props
    const values = fieldsStore.getFieldValues<PermitField[]>(typeField.id)
    return values?.length ? values : [[]]
  }

  private get tableFields(): IPermitTypeField[] {
    const { fieldsStore, typeField } = this.props
    return fieldsStore.getTableFields(typeField.id)
  }

  private get shouldShowAddRow(): boolean {
    return (
      !!this.tableFields.length &&
      this.tableRowValues.length < MAX_PERMIT_TABLE_ROWS
    )
  }

  private addNewRow = () => {
    if (this.shouldShowAddRow) {
      const { fieldsStore, typeField } = this.props
      fieldsStore.addNewFieldValue(typeField, [])

      Promise.resolve().then(() => this.scrollToBottom())
    }
  }

  private removeRow = (rowIndex: number) => {
    const { fieldsStore, typeField } = this.props
    const { removeFieldValue, changeValidationState } = fieldsStore

    const rowFields = this.tableRowValues[rowIndex] || []

    this.tableFields.forEach(field => {
      this.getFieldValueFromCell(rowFields, field.id).forEach((_, vIdx) =>
        changeValidationState(field.id, vIdx, true, rowIndex),
      )
    })

    removeFieldValue(typeField.id, rowIndex)
  }

  private getFieldValueFromCell(fields: PermitField[], typeFieldId: string) {
    return fields.find(({ fieldId }) => fieldId === typeFieldId)?.values || []
  }

  private getFormattedCellValue(
    field: IPermitTypeField,
    allCells: PermitField[],
  ): string | JSX.Element {
    const {
      projectDateStore: {
        getWeekdayMonthDayAndYearToDisplay,
        combineISODateTime,
        getTimeToDisplay,
      },
      projectMembersStore,
      locationAttributesStore,
      companiesStore,
      sitePermitsStore,
      permitTypesStore,
    } = this.props

    const values = this.getFieldValueFromCell(allCells, field.id)

    if (values.every(v => !v)) {
      return NO_VALUE
    }

    if (field.type === PermitFieldType.CompanyInformational) {
      return (
        <div className="row overflow-hidden">
          {values.map(id => (
            <CompanyProfilePreview
              key={id}
              company={companiesStore.getCompanyById(id)}
              isDeactivatedShown
            />
          ))}
        </div>
      )
    }

    if (field.type === PermitFieldType.User) {
      return (
        <div className="row overflow-hidden">
          {values
            .filter(id => projectMembersStore.hasById(id))
            .map(usrId => (
              <UserProfilePreview
                key={usrId}
                user={projectMembersStore.getById(usrId)}
              />
            ))}
        </div>
      )
    }
    if (field.type === PermitFieldType.LocationInformational) {
      return (
        <div className="row overflow-hidden">
          {values.map(({ id }, index) => {
            const location = locationAttributesStore.getById(id)
            if (location) {
              return (
                <SitemapAttributeTag
                  key={`${location.id}_${index}`}
                  shouldShowAsTag={false}
                  dataObject={location}
                  className="row"
                  contentContainerClassName="text extra-large ellipsis parent-tag"
                >
                  {location.name}
                </SitemapAttributeTag>
              )
            }
          })}
        </div>
      )
    }
    if (field.type === PermitFieldType.Measure) {
      const measureValues = values.filter(mv => mv?.value || mv?.units)
      return !measureValues.length
        ? NO_VALUE
        : measureValues.map(v => `${v.value} ${v.units}`).join(VALUE_SEPARATOR)
    }
    if (field.type === PermitFieldType.AdditionalDate) {
      return values
        .map(v => getWeekdayMonthDayAndYearToDisplay(v))
        .join(VALUE_SEPARATOR)
    }
    if (field.type === PermitFieldType.Time) {
      return values
        .map(v => getTimeToDisplay(combineISODateTime(undefined, v)))
        .join(VALUE_SEPARATOR)
    }

    if (field.type === PermitFieldType.LinkedWorkflow) {
      return (
        <>
          {values.map(id =>
            sitePermitsStore.getFormById(id)?.getCaption(permitTypesStore),
          )}
        </>
      )
    }

    return values.join(VALUE_SEPARATOR)
  }

  private setTableRef = (ref: HTMLDivElement) => {
    this.tableRef = ref
  }

  private scrollToBottom = () => {
    if (!this.tableRef) {
      return
    }

    const { scrollHeight, clientHeight } = this.tableRef
    smoothScrollTo(
      this.tableRef,
      scrollHeight - clientHeight,
      0,
      SCROLL_ANIMATION_TIME,
    )
  }
}

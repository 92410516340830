import * as React from 'react'

import { inject, observer } from 'mobx-react'

import UserFieldId from '../../enums/UserFieldId'
import Localization from '../../localization/LocalizationManager'
import EventsStore from '../../stores/EventStore/Events.store'
import GraphExecutorStore from '../../stores/domain/GraphExecutor.store'
import CommonStore from '../../stores/ui/Common.store'
import BaseSignUp from '../BaseSignUp/BaseSignUp'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import SignUpStore from './SignUp.store'

import './SignUp.scss'

interface IProps {
  eventsStore?: EventsStore
  graphExecutorStore?: GraphExecutorStore
  common?: CommonStore
}

// translated

@inject('eventsStore', 'graphExecutorStore', 'common')
@observer
export default class SignUp extends React.Component<IProps> {
  private readonly store: SignUpStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new SignUpStore(
      props.eventsStore,
      props.graphExecutorStore,
      [UserFieldId.Email],
      props.common,
    )
  }

  public render() {
    return (
      <BaseSignUp store={this.store} header={this.renderHeader()}>
        {this.props.children}
      </BaseSignUp>
    )
  }

  private renderHeader = (): JSX.Element => {
    const { initProjectName } = this.store

    const { signUpTo, _brandName, requestAccessTo } = Localization.translator
    return (
      <>
        <div className="as-end pb20 languages-selector-container">
          {Localization.shouldShowLanguageSelector && <LanguageSelector />}
        </div>
        <div className="text uppercase header blue-brand pb8">
          {`${signUpTo} ${_brandName}`}
        </div>
        {!!initProjectName && (
          <div className="text extra-large">
            {`${requestAccessTo} `}
            <span className="text extra-large bold">{initProjectName}</span>
          </div>
        )}
      </>
    )
  }
}

import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { Line } from 'react-chartjs-2'

import Localization from '../../../localization/LocalizationManager'
import LocationIntegration, {
  KnownLocationIntegrationMetadataKeys,
} from '../../../models/LocationObjects/LocationIntegration'
import EventsStore from '../../../stores/EventStore/Events.store'
import GraphExecutorStore from '../../../stores/domain/GraphExecutor.store'
import ProjectDateStore from '../../../stores/ui/ProjectDate.store'
import { Loader } from '../../Loader'
import MapViewItemBase from '../../SitemapHelpers/models/MapViewItemBase'
import SitemapDraggableModalWrapper from '../SitemapDraggableModalWrapper'
import MaturixStationDetailsStore, {
  MATURIX_CHART_OPTIONS,
} from './MaturixStationDetailsStore.store'

interface IProps {
  item: MapViewItemBase
  containerRef: HTMLElement
  onClose(): void
  graphExecutorStore?: GraphExecutorStore
  eventsStore?: EventsStore
  projectDateStore?: ProjectDateStore
  topOffset: number
  leftOffset: number
}

@inject('graphExecutorStore', 'eventsStore', 'projectDateStore')
@observer
export default class SitemapMaturixDraggableModal extends React.Component<IProps> {
  private store: MaturixStationDetailsStore

  public constructor(props: IProps) {
    super(props)
    this.store = new MaturixStationDetailsStore(
      props.eventsStore,
      props.graphExecutorStore,
      props.projectDateStore,
    )
  }

  public componentDidMount(): void {
    const { dataObject } = this.props.item
    this.store.loadData(
      (dataObject as LocationIntegration).metadata[
        KnownLocationIntegrationMetadataKeys.MonitoringId
      ],
    )
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.item.id !== prevProps.item.id) {
      const { dataObject } = this.props.item
      this.store.loadData(
        (dataObject as LocationIntegration).metadata[
          KnownLocationIntegrationMetadataKeys.MonitoringId
        ],
      )
    }
  }

  private renderLineChart(dataSet: any) {
    return (
      <div className="px10">
        <Line data={dataSet} options={MATURIX_CHART_OPTIONS} />
      </div>
    )
  }

  public render() {
    const { item, containerRef, onClose, topOffset, leftOffset } = this.props
    const { temperatureDataSet, strengthDataSet, isLoading, monitoring } =
      this.store

    return (
      <SitemapDraggableModalWrapper
        className="overflow-auto"
        item={item}
        containerRef={containerRef}
        onClose={onClose}
        topOffset={topOffset}
        leftOffset={leftOffset}
        isItemLoading={isLoading}
      >
        <div className="relative overflow-auto draggable-elements-list">
          {isLoading ? (
            <div className="mt30 mb30">
              <Loader hint={Localization.translator.loadingMonitoringData} />
            </div>
          ) : monitoring ? (
            <div>
              <p className="text large ml5 mt5">
                {Localization.translator.deviceId}: {monitoring.deviceId}
              </p>
              <p className="text large ml5">
                {Localization.translator.monitoringName}:{' '}
                {monitoring.monitoringName}
              </p>
              <p className="text large ml5">
                {Localization.translator.status}: {monitoring.status}
              </p>
              {!!temperatureDataSet && this.renderLineChart(temperatureDataSet)}
              {!!strengthDataSet && this.renderLineChart(strengthDataSet)}
            </div>
          ) : (
            <div className="mt30 mb30">
              <p className="text large ml5">
                {Localization.translator.noInformationAvailableOnThisMonitoring}
              </p>
            </div>
          )}
        </div>
      </SitemapDraggableModalWrapper>
    )
  }
}

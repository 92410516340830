import React from 'react'

import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import MultiDayEventsBar from '~/client/src/mobile/components/MultiDayEventsBar/MultiDayEventsBar'
import MobileInitialState from '~/client/src/mobile/stores/MobileInitialState'
import CalendarDayView, {
  CalendarDayMode,
} from '~/client/src/shared/components/CalendarDayView/CalendarDayView'
import CalendarDayViewStore from '~/client/src/shared/components/CalendarDayView/CalendarDayView.store'
import WeatherForecastBar from '~/client/src/shared/components/WeatherForecastBar/WeatherForecastBar'
import CalendarEventLabelType from '~/client/src/shared/enums/CalendarEventLabelType'
import CalendarEvent, {
  CalendarEventEntityType,
} from '~/client/src/shared/models/CalendarEvent'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import EventStyleCssAdapter from '~/client/src/shared/utils/EventStyleCssAdapter'

import MobileLogisticsStore from '../../stores/MobileLogistics.store'
import MobileLogisticsFilterStore from '../../stores/MobileLogisticsFilter.store'
import MobileLogisticsListStore from '../../stores/MobileLogisticsList.store'
import CompactLogisticsFilter from '../CompactLogsiticsFilter/CompactLogisticsFilter'
import LogisticsFilters from '../LogisticsFilters/LogisticsFilters'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps {
  logisticsStore: MobileLogisticsStore
  onNewEventCreate: () => void
  onEventClicked: (event: CalendarEvent) => void
  logisticsListStore: MobileLogisticsListStore
  isPermitOnly: boolean

  eventsStore?: EventsStore
  projectDateStore?: ProjectDateStore
  companiesStore?: CompaniesStore
  permitTypesStore?: PermitTypesStore
  locationAttributesStore?: LocationAttributesStore
  userProjectsStore?: UserProjectsStore
  logisticsFilterStore?: MobileLogisticsFilterStore
  projectMembersStore?: ProjectMembersStore
  tagsStore?: TagsStore
  state?: MobileInitialState
}

@inject(
  'eventsStore',
  'projectDateStore',
  'companiesStore',
  'permitTypesStore',
  'locationAttributesStore',
  'userProjectsStore',
  'projectMembersStore',
  'tagsStore',
  'state',
)
@observer
export default class MobileLogisticsCalendar extends React.Component<IProps> {
  private store: CalendarDayViewStore
  @observable private isFiltersModalOpen = false

  public constructor(props: IProps) {
    super(props)

    this.store = new CalendarDayViewStore(
      props.projectDateStore,
      props.companiesStore,
      CalendarEventEntityType.Form,
      null,
      true,
    )
  }

  public render() {
    const {
      eventsStore,
      logisticsStore: {
        viewingDate,
        setViewingDate,
        prevDate,
        nextDate,
        startDate,
        isCreationAvailable,
      },
      logisticsFilterStore,
    } = this.props

    const mode = eventsStore.appState.isPresentationMode
      ? CalendarDayMode.VIEW
      : CalendarDayMode.CREATE

    return (
      <div className="col full-height">
        <div className="body-header full-width bb-light-grey sticky z-index-10 bg-white">
          <LogisticsFilters
            companiesStore={this.props.companiesStore}
            logisticsStore={this.props.logisticsStore}
            toggleFilters={this.toggleFilters}
            isPermitOnly={this.props.isPermitOnly}
            logisticsFilterStore={logisticsFilterStore}
            state={this.props.state}
            userProjectsStore={this.props.userProjectsStore}
            selectedOptionsCount={logisticsFilterStore.selectedOptionsCount}
          />
          <WeatherForecastBar
            className="row nowrap bg-palette-brand-lightest"
            date={startDate}
          />
          <MultiDayEventsBar
            events={this.events}
            viewingDate={viewingDate}
            setViewingDate={setViewingDate}
            calendarStore={this.store}
            onEventClicked={this.props.onEventClicked}
          />
        </div>

        <CalendarDayView
          events={this.events}
          store={this.store}
          activeDate={viewingDate}
          mode={mode}
          onNewEventCreate={this.props.onNewEventCreate}
          onEventClicked={this.props.onEventClicked}
          onLeftSwipe={nextDate}
          onRightSwipe={prevDate}
          shouldHandleMouse={false}
          isEventCreationDisabled={!isCreationAvailable}
        />
        <CompactLogisticsFilter
          store={this.props.logisticsFilterStore}
          onClose={this.toggleFilters}
          isShown={this.isFiltersModalOpen}
          selectedOptionsCount={logisticsFilterStore.selectedOptionsCount}
          companiesStore={this.props.companiesStore}
          projectMembersStore={this.props.projectMembersStore}
          tagsStore={this.props.tagsStore}
        />
      </div>
    )
  }

  @computed
  private get events(): CalendarEvent[] {
    const { logisticsStore, locationAttributesStore, logisticsListStore } =
      this.props

    return logisticsListStore.filteredCollection.map(logisticItem => {
      const form = logisticsStore.getFormById(logisticItem.id)
      const location = locationAttributesStore.getLocationBySiteLocationObj(
        form.locations[0],
      )

      const eventStyle = form.getEventStyleByColor(
        location?.color || Colors.primary20,
      )
      const cssAdapter = new EventStyleCssAdapter(eventStyle)

      return CalendarEvent.createEvent({
        startDate: new Date(form.startDate),
        endDate: new Date(form.endDate),
        dataId: form.id,
        projectDateStore: this.props.projectDateStore,
        entityType: CalendarEventEntityType.Form,
        labelType: CalendarEventLabelType.Default,
        styles: cssAdapter.getStyles(),
        data: form,
        label: form.getCaption(this.props.permitTypesStore),
        isAllDay: form.isAllDay,
      })
    })
  }

  @action.bound
  private toggleFilters() {
    this.isFiltersModalOpen = !this.isFiltersModalOpen
  }
}

import { SAVE_SITE_PERMITS } from '~/client/src/shared/stores/EventStore/eventConstants'
import BaseActionBarStore from '~/client/src/shared/stores/ui/BaseActionBar.store'

import Message from '../models/Message'
import SitePermit from '../models/Permit'
import MessageDto from '../types/MessageDto'

export default class PermitLogActionBarStore extends BaseActionBarStore {
  protected updateThreadEntity(threadEntity: SitePermit) {
    this.eventsStore.dispatch(SAVE_SITE_PERMITS, [threadEntity])
  }

  protected saveMessage(
    threadEntityId: string,
    message: Message,
  ): Promise<MessageDto> {
    return this.messagesStore.saveWorkflowMessage(threadEntityId, message)
  }
}

import {
  Auth,
  AuthErrorCodes,
  ConfirmationResult,
  RecaptchaVerifier,
  User,
} from 'firebase/auth'

import { createGraphClient } from '~/client/graph'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'

import { GrantTypesEnum } from '../../enums/GrantTypes'
import IUserSession from '../../interfaces/IUserSession'
import Localization from '../../localization/LocalizationManager'
import ApiAuthService, {
  EmailAuth,
  PhoneAuth,
} from '../../services/ApiAuthService'
import FirebaseAuthService, {
  FIREBASE_PASSWORD_PROVIDER_ID,
} from '../../services/FirebaseAuthService'
import { getTokenPayload } from '../../utils/JwtHelper'
import EventsStore from '../EventStore/Events.store'
import { INIT_APP, INIT_AUTH_USER } from '../EventStore/eventConstants'
import InitialState from '../InitialState'
import TenantsStore, { ITenant } from './Tenants.store'

export default class AuthenticationStore {
  public get withPasswordProvider(): boolean {
    return this.firebaseAuthService.withPasswordProvider
  }

  public get isAuthenticated(): boolean {
    return !!this.firebaseAuthService.authUser
  }

  public get fromMicrosoftAzure(): boolean {
    return this.firebaseAuthService.fromMicrosoftAzure
  }

  /**
   * Returns the email of the user that is currently authenticated.
   * If the user is authenticated with the internal auth API, the email is taken from the tenantUserSession (means it's tenantless).
   * Otherwise, the email is taken from the Firebase user.
   */
  public get authEmail(): string {
    return (
      this.initialState.tenantUserSession?.email ||
      this.firebaseAuthService.authUser.email
    )
  }

  public get firebaseAuth(): Auth {
    return this.firebaseAuthService.auth
  }

  public constructor(
    private readonly commonStore: CommonStore,
    private readonly firebaseAuthService: FirebaseAuthService,
    private readonly apiService: ApiAuthService,
    private readonly eventsStore: EventsStore,
    private readonly initialState: InitialState,
    private readonly apiAuthService: ApiAuthService,
    private readonly tenantsStore: TenantsStore,
  ) {}

  /**
   * Returns a function that can be used to get a valid access token.
   * @param noLogout Use to prevent nesting when the caller is the LOGOUT event handler itself.
   */
  public getValidAccessTokenCallback(
    noLogout?: boolean,
  ): () => Promise<string> {
    try {
      return this.apiService.getValidAccessToken
    } catch (error) {
      console.error(error)
      if (!noLogout) {
        this.eventsStore.dispatch(e.LOGOUT)
      }
    }
  }

  public async getTenantsForUser(accessToken: string): Promise<Array<ITenant>> {
    return this.apiService.getTenantsForUser(accessToken)
  }

  public observeAuthState() {
    this.onAuthStateChanged(user => {
      if (user) {
        user.getIdToken().then(accessToken => {
          this.firebaseAuthService.setAccessToken(accessToken)
          this.eventsStore.dispatch(INIT_APP, user)
        })
      } else {
        this.firebaseAuthService.resetAccessToken()
        this.commonStore.displayLoginView()
      }
    })
  }

  public onAuthStateChanged(cb: (user: User) => void) {
    this.firebaseAuthService.onAuthStateChanged(cb)
  }

  public async loginWithInviteKey(inviteKey: string) {
    const { data } = await this.apiService.loginWithInviteKey(inviteKey)
    await this.firebaseAuthService.loginWithInviteKey(data.fbCustomToken)
  }

  public async reauthenticateWithInviteKey() {
    const accessToken = await this.firebaseAuthService.getValidAccessToken()
    const freshInviteToken = await this.apiService.getInviteKey(
      accessToken,
      this.tenantsStore.activeTenant?.host,
    )

    await this.loginWithInviteKey(freshInviteToken)
  }

  public async verifyPasswordResetCode(code: string) {
    return this.firebaseAuthService.verifyPasswordResetCode(code)
  }

  public async confirmPasswordReset(resetCode: string, newPassword: string) {
    return this.firebaseAuthService.confirmPasswordReset(resetCode, newPassword)
  }

  public async resetPassword(email: string) {
    return this.firebaseAuthService.resetPassword(email)
  }

  /**
   * Decide which login method to use based on the detected tenant mode
   */
  public async login(email: string, password: string) {
    if (this.tenantsStore.activeTenant) {
      await this.loginWithFirebase(email, password)
    } else {
      await this.loginWithCustomStxAuthUnPw(email, password)
    }
  }

  private async loginWithFirebase(email: string, password: string) {
    await this.firebaseAuthService.login(email, password)
  }

  public async loginWithCustomStxAuthPhoneOtc(
    phone: string,
    oneTimeCode: string,
    nonsense: string,
  ) {
    const authData = {
      phone,
      oneTimeCode,
      grantType: GrantTypesEnum.authorization_code,
      nonsense,
    }
    return this.loginWithCustomStxAuth(authData)
  }
  private async loginWithCustomStxAuthUnPw(email: string, password: string) {
    const authData = {
      email,
      password,
      grantType: GrantTypesEnum.password,
    }
    return this.loginWithCustomStxAuth(authData)
  }
  private async loginWithCustomStxAuth(authData: EmailAuth | PhoneAuth) {
    const result = await this.apiService.logInViaStruxhub(authData)

    if (!result) {
      throw new Error(
        'Server encountered an error. Make sure you entered your credentials correctly',
      )
    }

    const payload = getTokenPayload(result.accessToken)
    const userSession: IUserSession = {
      accessToken: result.accessToken,
      refreshToken: result.refreshToken,
      nameId: payload.nameid,
      email: payload.email,
      activeTenantHost: null,
    }

    this.initialState.tenantUserSession = userSession
    const tenants = await this.apiAuthService.getTenantsForUser(
      userSession.accessToken,
    )

    if (!tenants?.length) {
      throw new Error('No tenants found')
    }

    this.tenantsStore.setTenants(tenants)

    if (tenants.length === 1) {
      this.tenantsStore.setActiveTenant(tenants[0].host)
      this.eventsStore.dispatch(INIT_AUTH_USER)
    }
  }

  public async loginWithPhoneNumber(
    phoneNumber: string,
    applicationVerifier: RecaptchaVerifier,
  ): Promise<ConfirmationResult> {
    const doesUserExist = await this.apiService.checkUserByPhoneNumber(
      phoneNumber,
    )

    if (!doesUserExist) {
      throw {
        code: AuthErrorCodes.USER_DELETED,
        message: Localization.translator.userNotFoundError,
      }
    }

    return this.firebaseAuthService.loginWithPhoneNumber(
      phoneNumber,
      applicationVerifier,
    )
  }

  public async loginWithProvider(
    providerId: string,
    providerType: string,
    onLogin: () => void,
    onLinkProviders: () => void,
  ): Promise<void> {
    return await this.firebaseAuthService.loginWithProvider(
      providerId,
      providerType,
      onLogin,
      onLinkProviders,
    )
  }

  public async linkProvidersAndLogin(password: string): Promise<void> {
    return await this.firebaseAuthService.linkProviderAndSignIn(password)
  }

  public updatePassword(newPassword: string) {
    return this.firebaseAuthService.updatePassword(newPassword)
  }

  public logout() {
    return this.firebaseAuthService.logout()
  }

  public async savePassword(password: string) {
    try {
      await this.firebaseAuthService.updatePassword(password)
    } catch (e) {
      if (e.code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) {
        await this.reauthenticateWithInviteKey()
        return this.savePassword(password)
      }

      throw e
    }

    // Firebase rejects existing refreshToken after auth sensitive operation (e.g. password updating)
    // Post re-authentication is required for further interaction with API without re-logging
    await this.reauthenticateWithInviteKey()
  }

  public async linkEmailProvider(email: string, password: string) {
    try {
      await this.firebaseAuthService.linkEmailProvider(email, password)
    } catch (e) {
      if (e.code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) {
        await this.reauthenticateWithInviteKey()
        return this.linkEmailProvider(email, password)
      }

      if (e.code === AuthErrorCodes.PROVIDER_ALREADY_LINKED) {
        await this.reauthenticateWithInviteKey()
        await this.unlinkProvider(FIREBASE_PASSWORD_PROVIDER_ID)
        return this.linkEmailProvider(email, password)
      }

      throw e
    }

    // Firebase rejects existing refreshToken after auth sensitive operation (e.g. provider linking)
    // Post re-authentication is required for further interaction with API without re-logging
    await this.reauthenticateWithInviteKey()
  }

  public unlinkProvider(providerId: string): Promise<User> {
    return this.firebaseAuthService.unlinkProvider(providerId)
  }

  public async resendInviteKey(expiredInviteKey: string): Promise<void> {
    return this.apiService.resendInviteKey(expiredInviteKey)
  }

  /**
   * Initiate the user through the custom STX auth service.
   * If not found, defer back to INIT_AUTH_USER_2 where all the usual logic is.
   */
  public async initAuthUserTenantlessMode() {
    try {
      const user = this.initialState.tenantUserSession
      if (!user) {
        return this.eventsStore.dispatch(e.INIT_AUTH_USER_2)
      }

      this.initialState.onAuthStateChangedCallback()
      const tenants = !this.tenantsStore.userTenants?.length
        ? await this.getTenantsForUser(user.accessToken)
        : this.tenantsStore.userTenants

      if (!tenants?.length) {
        console.warn('No tenants available! Logging out')
        this.eventsStore.dispatch(e.LOGOUT)
        return
      }

    if (tenants.length === 1) {
      this.tenantsStore.setActiveTenant(tenants[0].host)
    }

      if (!user.activeTenantHost) {
        this.initialState.loading.set(e.INIT_AUTH_USER, false)
        this.eventsStore.dispatch(e.LOGOUT)
        return
      }

      this.initialState.graphClient = createGraphClient(
        this.getValidAccessTokenCallback(),
        user.activeTenantHost,
      )

      // Must set active tenant after re-creating graph client
      this.tenantsStore.setActiveTenant(user.activeTenantHost)

      return this.eventsStore.dispatch(e.INIT_APP, null, user.email)
    } catch (error) {
      console.error(
        'An error occurred during tenantless mode initialization',
        error,
      )
      this.eventsStore.dispatch(e.LOGOUT)
    }
  }
}

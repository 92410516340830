import { IFormCategory } from '~/client/graph/types.generated'
import Guard from '~/client/src/shared/utils/Guard'

import Localization from '../localization/LocalizationManager'

export default class FormCategory {
  public static fromDto(dto: IFormCategory) {
    return new FormCategory(
      dto.id,
      dto.name,
      dto.projectId,
      dto.isDeleted,
      dto.createdAt,
      dto.updatedAt,
    )
  }

  public constructor(
    public id: string,
    private _name: string,
    public readonly projectId?: string,
    public isDeleted?: boolean,
    public readonly createdAt?: number,
    public readonly updatedAt?: number,
  ) {
    Guard.requireAll({
      _name,
    })
  }

  public get name(): string {
    return this.isDeleted
      ? `[${Localization.translator.deleted}] ${this._name}`
      : this._name
  }
}

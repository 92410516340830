import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type INotificationFieldsFragment = Pick<
  Types.INotification,
  | 'activityObjectId'
  | 'authorId'
  | 'createdAt'
  | 'entityId'
  | 'id'
  | 'isArchived'
  | 'recipientId'
  | 'scheduleId'
  | 'messageId'
  | 'projectId'
  | 'type'
  | 'updatedAt'
  | 'wasRead'
>

export type INotificationsByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type INotificationsByProjectIdQuery = {
  notifications?: Types.Maybe<{ data: Array<INotificationFieldsFragment> }>
}

export type IUnreadNotificationsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IUnreadNotificationsQuery = {
  unreadNotifications?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.INotification, 'activityObjectId' | 'entityId' | 'messageId'>
      >
    >
  >
}

export type IUnreadNotificationsCountQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IUnreadNotificationsCountQuery = Pick<
  Types.IQuery,
  'unreadNotificationsCount'
>

export type INotifyEntityRepliedQueryVariables = Types.Exact<{
  data: Types.INotifyEntityInput
}>

export type INotifyEntityRepliedQuery = Pick<
  Types.IQuery,
  'notifyActivityEntityChanged'
>

export type IListenNotificationsByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenNotificationsByProjectIdSubscription = {
  notification?: Types.Maybe<
    Pick<Types.INotificationChangeEvent, 'id'> & {
      item?: Types.Maybe<INotificationFieldsFragment>
    }
  >
}

export type ISaveNotificationMutationVariables = Types.Exact<{
  notification: Types.INotificationInput
}>

export type ISaveNotificationMutation = {
  saveNotification?: Types.Maybe<Pick<Types.INotification, 'id'>>
}

export type ISaveManyNotificationsMutationVariables = Types.Exact<{
  notifications:
    | Array<Types.Maybe<Types.INotificationInput>>
    | Types.Maybe<Types.INotificationInput>
}>

export type ISaveManyNotificationsMutation = {
  saveManyNotifications?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.INotification, 'id'>>>
  >
}

export const NotificationFieldsFragmentDoc = gql`
  fragment NotificationFields on Notification {
    activityObjectId
    authorId
    createdAt
    entityId
    id
    isArchived
    recipientId
    scheduleId
    messageId
    projectId
    type
    updatedAt
    wasRead
  }
`
export const NotificationsByProjectIdDocument = gql`
  query NotificationsByProjectId($projectId: ObjectId!) {
    notifications(limit: 1000000, projectId: $projectId) {
      data {
        ...NotificationFields
      }
    }
  }
  ${NotificationFieldsFragmentDoc}
`

/**
 * __useNotificationsByProjectIdQuery__
 *
 * To run a query within a React component, call `useNotificationsByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useNotificationsByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    INotificationsByProjectIdQuery,
    INotificationsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    INotificationsByProjectIdQuery,
    INotificationsByProjectIdQueryVariables
  >(NotificationsByProjectIdDocument, options)
}
export function useNotificationsByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    INotificationsByProjectIdQuery,
    INotificationsByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    INotificationsByProjectIdQuery,
    INotificationsByProjectIdQueryVariables
  >(NotificationsByProjectIdDocument, options)
}
export type NotificationsByProjectIdQueryHookResult = ReturnType<
  typeof useNotificationsByProjectIdQuery
>
export type NotificationsByProjectIdLazyQueryHookResult = ReturnType<
  typeof useNotificationsByProjectIdLazyQuery
>
export type NotificationsByProjectIdQueryResult = Apollo.QueryResult<
  INotificationsByProjectIdQuery,
  INotificationsByProjectIdQueryVariables
>
export const UnreadNotificationsDocument = gql`
  query UnreadNotifications($projectId: ObjectId!) {
    unreadNotifications(projectId: $projectId) {
      activityObjectId
      entityId
      messageId
    }
  }
`

/**
 * __useUnreadNotificationsQuery__
 *
 * To run a query within a React component, call `useUnreadNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadNotificationsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUnreadNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IUnreadNotificationsQuery,
    IUnreadNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IUnreadNotificationsQuery,
    IUnreadNotificationsQueryVariables
  >(UnreadNotificationsDocument, options)
}
export function useUnreadNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IUnreadNotificationsQuery,
    IUnreadNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IUnreadNotificationsQuery,
    IUnreadNotificationsQueryVariables
  >(UnreadNotificationsDocument, options)
}
export type UnreadNotificationsQueryHookResult = ReturnType<
  typeof useUnreadNotificationsQuery
>
export type UnreadNotificationsLazyQueryHookResult = ReturnType<
  typeof useUnreadNotificationsLazyQuery
>
export type UnreadNotificationsQueryResult = Apollo.QueryResult<
  IUnreadNotificationsQuery,
  IUnreadNotificationsQueryVariables
>
export const UnreadNotificationsCountDocument = gql`
  query UnreadNotificationsCount($projectId: ObjectId!) {
    unreadNotificationsCount(projectId: $projectId)
  }
`

/**
 * __useUnreadNotificationsCountQuery__
 *
 * To run a query within a React component, call `useUnreadNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadNotificationsCountQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUnreadNotificationsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    IUnreadNotificationsCountQuery,
    IUnreadNotificationsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IUnreadNotificationsCountQuery,
    IUnreadNotificationsCountQueryVariables
  >(UnreadNotificationsCountDocument, options)
}
export function useUnreadNotificationsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IUnreadNotificationsCountQuery,
    IUnreadNotificationsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IUnreadNotificationsCountQuery,
    IUnreadNotificationsCountQueryVariables
  >(UnreadNotificationsCountDocument, options)
}
export type UnreadNotificationsCountQueryHookResult = ReturnType<
  typeof useUnreadNotificationsCountQuery
>
export type UnreadNotificationsCountLazyQueryHookResult = ReturnType<
  typeof useUnreadNotificationsCountLazyQuery
>
export type UnreadNotificationsCountQueryResult = Apollo.QueryResult<
  IUnreadNotificationsCountQuery,
  IUnreadNotificationsCountQueryVariables
>
export const NotifyEntityRepliedDocument = gql`
  query NotifyEntityReplied($data: NotifyEntityInput!) {
    notifyActivityEntityChanged(notifyActivityEntityChanged: $data)
  }
`

/**
 * __useNotifyEntityRepliedQuery__
 *
 * To run a query within a React component, call `useNotifyEntityRepliedQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotifyEntityRepliedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotifyEntityRepliedQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNotifyEntityRepliedQuery(
  baseOptions: Apollo.QueryHookOptions<
    INotifyEntityRepliedQuery,
    INotifyEntityRepliedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    INotifyEntityRepliedQuery,
    INotifyEntityRepliedQueryVariables
  >(NotifyEntityRepliedDocument, options)
}
export function useNotifyEntityRepliedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    INotifyEntityRepliedQuery,
    INotifyEntityRepliedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    INotifyEntityRepliedQuery,
    INotifyEntityRepliedQueryVariables
  >(NotifyEntityRepliedDocument, options)
}
export type NotifyEntityRepliedQueryHookResult = ReturnType<
  typeof useNotifyEntityRepliedQuery
>
export type NotifyEntityRepliedLazyQueryHookResult = ReturnType<
  typeof useNotifyEntityRepliedLazyQuery
>
export type NotifyEntityRepliedQueryResult = Apollo.QueryResult<
  INotifyEntityRepliedQuery,
  INotifyEntityRepliedQueryVariables
>
export const ListenNotificationsByProjectIdDocument = gql`
  subscription ListenNotificationsByProjectId($projectId: ObjectId!) {
    notification(projectId: $projectId) {
      id
      item {
        ...NotificationFields
      }
    }
  }
  ${NotificationFieldsFragmentDoc}
`

/**
 * __useListenNotificationsByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenNotificationsByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenNotificationsByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenNotificationsByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenNotificationsByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenNotificationsByProjectIdSubscription,
    IListenNotificationsByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenNotificationsByProjectIdSubscription,
    IListenNotificationsByProjectIdSubscriptionVariables
  >(ListenNotificationsByProjectIdDocument, options)
}
export type ListenNotificationsByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenNotificationsByProjectIdSubscription
>
export type ListenNotificationsByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenNotificationsByProjectIdSubscription>
export const SaveNotificationDocument = gql`
  mutation SaveNotification($notification: NotificationInput!) {
    saveNotification(notification: $notification) {
      id
    }
  }
`
export type ISaveNotificationMutationFn = Apollo.MutationFunction<
  ISaveNotificationMutation,
  ISaveNotificationMutationVariables
>

/**
 * __useSaveNotificationMutation__
 *
 * To run a mutation, you first call `useSaveNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNotificationMutation, { data, loading, error }] = useSaveNotificationMutation({
 *   variables: {
 *      notification: // value for 'notification'
 *   },
 * });
 */
export function useSaveNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveNotificationMutation,
    ISaveNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveNotificationMutation,
    ISaveNotificationMutationVariables
  >(SaveNotificationDocument, options)
}
export type SaveNotificationMutationHookResult = ReturnType<
  typeof useSaveNotificationMutation
>
export type SaveNotificationMutationResult =
  Apollo.MutationResult<ISaveNotificationMutation>
export type SaveNotificationMutationOptions = Apollo.BaseMutationOptions<
  ISaveNotificationMutation,
  ISaveNotificationMutationVariables
>
export const SaveManyNotificationsDocument = gql`
  mutation SaveManyNotifications($notifications: [NotificationInput]!) {
    saveManyNotifications(notifications: $notifications) {
      id
    }
  }
`
export type ISaveManyNotificationsMutationFn = Apollo.MutationFunction<
  ISaveManyNotificationsMutation,
  ISaveManyNotificationsMutationVariables
>

/**
 * __useSaveManyNotificationsMutation__
 *
 * To run a mutation, you first call `useSaveManyNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyNotificationsMutation, { data, loading, error }] = useSaveManyNotificationsMutation({
 *   variables: {
 *      notifications: // value for 'notifications'
 *   },
 * });
 */
export function useSaveManyNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyNotificationsMutation,
    ISaveManyNotificationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyNotificationsMutation,
    ISaveManyNotificationsMutationVariables
  >(SaveManyNotificationsDocument, options)
}
export type SaveManyNotificationsMutationHookResult = ReturnType<
  typeof useSaveManyNotificationsMutation
>
export type SaveManyNotificationsMutationResult =
  Apollo.MutationResult<ISaveManyNotificationsMutation>
export type SaveManyNotificationsMutationOptions = Apollo.BaseMutationOptions<
  ISaveManyNotificationsMutation,
  ISaveManyNotificationsMutationVariables
>

import * as React from 'react'

import CompactCompaniesDirectory from '~/client/src/shared/components/CompactCompaniesDirectory/CompactCompaniesDirectory'
import Company from '~/client/src/shared/models/Company'

import SitePermitCreationFormStore from '../../../../../SitePermitCreationForm.store'

// localization: no text to translate

interface IProps {
  store: SitePermitCreationFormStore
}

export default class FormCompanyPicker extends React.Component<IProps> {
  public render() {
    return (
      <CompactCompaniesDirectory
        isPhoneHidden
        onCompanyRowClick={this.onCompanyRowClick}
        companyPredicate={this.companyPredicate}
      />
    )
  }

  private companyPredicate = (company: Company): boolean => {
    return !this.props.store.selectedFieldValues?.includes(company.id)
  }

  private onCompanyRowClick = ({ id }: Company) => {
    const {
      isWorkflowCompanySelected,
      changeSelectedFieldValue,
      deselectUsersIfNeeded,
      unsetValuesByCompanyRestriction,
    } = this.props.store

    if (!isWorkflowCompanySelected) {
      return this.changeCompanyInfoValue(id)
    }

    changeSelectedFieldValue(id)
    deselectUsersIfNeeded()
    unsetValuesByCompanyRestriction()
  }

  private changeCompanyInfoValue = (id: string) => {
    this.props.store.changeSelectedFieldValue(id)
  }
}

import React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized'

import SitePermit from '../../models/Permit'
import SitePermitsStore from '../../stores/domain/SitePermits.store'
import PermitCard from '../SitemapCards/PermitCard'
import WorkflowDirectoryStore from './WorkflowDirectory.store'

import './WorkflowDirectory.scss'

interface IProps {
  selectedWorkflowIds?: string[]
  onItemClick?: (permit: SitePermit) => void
  workflowPredicate?: (workflow: SitePermit) => boolean

  sitePermitsStore?: SitePermitsStore
}

const OVERSCAN_ROW_COUNT = 10
const ROW_HEIGHT = 64

@inject('sitePermitsStore')
@observer
export default class WorkflowDirectory extends React.Component<IProps> {
  private readonly cellMeasurerCache: CellMeasurerCache = new CellMeasurerCache(
    {
      fixedWidth: true,
      defaultHeight: ROW_HEIGHT,
    },
  )

  private readonly store: WorkflowDirectoryStore

  public constructor(props: IProps) {
    super(props)
    this.store = new WorkflowDirectoryStore(
      props.sitePermitsStore,
      props.workflowPredicate,
    )
  }

  public render() {
    const { workflows } = this.store
    return (
      <div className="workflow-directory col">
        <div className="virtualized-list-smart-wrapper">
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowCount={workflows.length}
                rowHeight={this.cellMeasurerCache.rowHeight}
                rowRenderer={this.renderWorkflow}
                overscanRowCount={OVERSCAN_ROW_COUNT}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }

  private renderWorkflow = ({ index, key, style, parent }) => {
    const { selectedWorkflowIds } = this.props
    const { workflows } = this.store
    const workflow = workflows[index]
    const isSelected = selectedWorkflowIds?.includes(workflow.id)
    return (
      <CellMeasurer
        cache={this.cellMeasurerCache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <div
            ref={registerChild}
            key={workflow.id}
            className={classList({
              'workflow-directory-row': true,
              selected: isSelected,
            })}
            style={style}
          >
            <PermitCard permit={workflow} onClick={this.props.onItemClick} />
          </div>
        )}
      </CellMeasurer>
    )
  }
}

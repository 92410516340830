import { action, computed, observable } from 'mobx'

import { IDefaultPermitType, IPermitType } from '~/client/graph/types.generated'
import PermitType from '~/client/src/shared/models/PermitType'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'

import { reduceByCategories } from '../../utils/util'
import EventsStore from '../EventStore/Events.store'

function compareByUpdatedAt(a: PermitType, b: PermitType): number {
  return b.updatedAt - a.updatedAt
}
function compareByOrderIndex(a: PermitType, b: PermitType): number {
  return a.orderIndex - b.orderIndex
}

export default class PermitTypesStore {
  private readonly projectPermitTypesMap = observable(
    new Map<string, PermitType>(),
  )
  private readonly defaultPermitTypesMap = observable(
    new Map<string, PermitType>(),
  )

  public constructor(private readonly eventsStore: EventsStore) {}

  @computed
  public get allTypes(): PermitType[] {
    return [...this.defaultTypes, ...this.projectSpecificTypes]
  }

  @computed
  public get actualTypes(): PermitType[] {
    const basedOnFilteredTypes = this.allTypes.filter(
      ({ id, isDeleted, type }) =>
        !isDeleted && !!type && !this.allTypes.some(t => id === t.basedOn),
    )

    const types = reduceByCategories(basedOnFilteredTypes, t => [t.type, t])

    const latestActualTypes = Object.keys(types).reduce((list, typeKey) => {
      const filteredTypes = types[typeKey].sort(compareByUpdatedAt)

      list.push(filteredTypes[0])
      return list
    }, [] as PermitType[])

    const predefinedTypes = latestActualTypes
      .filter(t => !t.isCustom)
      .sort(compareByOrderIndex)
    const userCustomTypes = latestActualTypes
      .filter(t => t.isCustom)
      .sort(compareByOrderIndex)

    return predefinedTypes.concat(userCustomTypes)
  }

  @computed
  public get enabledTypes(): PermitType[] {
    if (this.appState.isFormsDisabled) {
      return []
    }

    return this.actualTypes.filter(type => type.isEnabled)
  }

  public get isDataReceived(): boolean {
    return !this.appState.loading.get(e.LOAD_AND_LISTEN_TO_PERMIT_TYPES)
  }

  public getLastUpdatedTypeByType = (type: string): PermitType => {
    return this.allTypes
      .filter(pt => pt.type === type)
      .sort(compareByUpdatedAt)?.[0]
  }

  public getPermitTypeById = (permitTypeId: string): PermitType => {
    return (
      this.defaultPermitTypesMap.get(permitTypeId) ||
      this.projectPermitTypesMap.get(permitTypeId)
    )
  }

  public receiveDefaultTypes(dtos: IDefaultPermitType[]) {
    dtos.forEach(dto => {
      const permitType = PermitType.fromDefault(dto)
      this.defaultPermitTypesMap.set(dto.id, permitType)
    })
  }

  public receiveProjectSpecificTypes(dtos: IPermitType[]) {
    this.projectPermitTypesMap.clear()

    dtos.forEach(dto => {
      const permitType = PermitType.fromDto(dto)
      this.projectPermitTypesMap.set(dto.id, permitType)
    })
  }

  public receiveProjectSpecificType(id: string, dto: IPermitType) {
    if (dto) {
      this.projectPermitTypesMap.set(id, PermitType.fromDto(dto))
    }
  }

  @action.bound
  public createCustomType(
    name: string,
    categoryId: string,
    basedOnType: PermitType,
    callbackFn?: (permitTypes: PermitType[]) => void,
  ) {
    const newType = basedOnType.getCopy() as PermitType

    newType.id = null
    newType.basedOn = null
    newType.name = name
    newType.categoryId = categoryId
    newType.orderIndex = this.actualTypes.length
    newType.workflowSteps.forEach(s => (s.workflowRuleIds = []))

    this.saveMany([newType], callbackFn)
  }

  @action.bound
  public saveMany(
    permitTypes: PermitType[],
    callbackFn?: (permitTypes: PermitType[]) => void,
  ) {
    if (!permitTypes?.length) {
      return
    }

    this.eventsStore.dispatch(
      e.SAVE_PERMIT_TYPES,
      permitTypes.map(type => type.getDto()),
      callbackFn,
    )
  }

  @action.bound
  public deleteTypes(typeIds: string[]) {
    if (!typeIds?.length) {
      return
    }

    // TODO: rework and move this logic to the back-end
    const idsToDelete = this.allTypes
      .filter(t => typeIds.includes(t.type))
      .map(t => t.id)

    this.eventsStore.dispatch(e.REMOVE_PERMIT_TYPES, idsToDelete)
  }

  @action.bound
  public markTypesAsDeleted(ids: string[]) {
    if (!ids?.length) {
      return
    }

    ids.forEach(id => {
      if (this.projectPermitTypesMap.has(id)) {
        this.projectPermitTypesMap.get(id).isDeleted = true
      }
    })
  }

  @computed
  public get defaultTypes(): PermitType[] {
    return [...this.defaultPermitTypesMap.values()]
  }

  @computed
  private get projectSpecificTypes(): PermitType[] {
    return [...this.projectPermitTypesMap.values()]
  }

  private get appState() {
    return this.eventsStore.appState
  }
}

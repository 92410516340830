import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'

import CompactCustomNodeWrapper from '~/client/src/shared/components/ActivitiesTree/components/ActivitiesTreeNode/CompactCustomNodeWrapper'
import CompactLocationContainerNode from '~/client/src/shared/components/ActivitiesTree/components/ActivitiesTreeNode/CompactLocationContainerNode'
import DeliveriesViewStore from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import DeliveriesTree from '~/client/src/shared/components/DeliveryTree/DeliveryTree'
import FileInputBase from '~/client/src/shared/components/FileInput/FileInput'
import { Content, View } from '~/client/src/shared/components/Layout'
import Spinner from '~/client/src/shared/components/Spinner/Spinner'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import DeliveryListComponentStore from '../DeliveryListComponent.store'

const noDeliveriesPassingFilters = 'No Deliveries Passing Filters'

const ICON_SIZE = 20
const SPINNER_SIZE = 50

interface IProps {
  deliveriesViewStore: DeliveriesViewStore

  eventsStore?: EventsStore
  projectDateStore?: ProjectDateStore
  FileInputType: typeof FileInputBase
}

@inject('eventsStore', 'projectDateStore')
@observer
export default class DeliveryListComponent extends React.Component<IProps> {
  private clearPostEventCallback: () => void

  public constructor(props: IProps) {
    super(props)

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.store.onTreeRequest,
    )

    this.store.calculateDeliveryTree()
  }

  public componentWillUnmount() {
    if (this.clearPostEventCallback) {
      this.clearPostEventCallback()
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      deliveriesViewStore: { currentStartDate, currentEndDate },
      projectDateStore: { isSameDay },
    } = this.props

    if (
      (prevProps.deliveriesViewStore.currentStartDate &&
        !isSameDay(
          prevProps.deliveriesViewStore.currentStartDate,
          currentStartDate,
        )) ||
      !isSameDay(prevProps.deliveriesViewStore.currentEndDate, currentEndDate)
    ) {
      this.store.calculateDeliveryTree()
    }
  }

  public render() {
    const { isLoading } = this.store

    return (
      <View className="overflow-hidden full-width">
        <Content scrollable={true}>
          {isLoading ? this.loader : this.deliveryList}
        </Content>
      </View>
    )
  }

  private get deliveryList() {
    if (this.store.isTreeLoading) {
      return this.loader
    }

    const { deliveriesTree, changeContainerExpandState, currentStartDate } =
      this.store

    return deliveriesTree.length ? (
      <DeliveriesTree
        nodes={deliveriesTree}
        startViewDate={currentStartDate}
        CustomNodeWrapperType={CompactCustomNodeWrapper}
        LocationContainerNodeType={CompactLocationContainerNode}
        changeContainerExpandState={changeContainerExpandState}
        FileInputType={this.props.FileInputType}
        compactDeliveryListStore={this.store}
      />
    ) : (
      <div className="col x-center y-center pt10">
        <div className="row">
          <Icon
            icon="warning-sign"
            iconSize={ICON_SIZE}
            className="warning-icon mr5"
          />
          {noDeliveriesPassingFilters}
        </div>
      </div>
    )
  }

  private get loader() {
    return (
      <div className="col x-center y-center">
        <Spinner size={SPINNER_SIZE} className="loader" />
      </div>
    )
  }

  private get store(): DeliveryListComponentStore {
    return this.props.deliveriesViewStore.listStore
  }
}

import { NotificationType } from '~/client/graph'

export function isPermitClosedType(type: NotificationType) {
  return [NotificationType.PermitClosed].includes(type)
}
export function isPermitAcceptedType(type: NotificationType) {
  return [NotificationType.PermitAccepted].includes(type)
}
export function isPermitSubmittedType(type: NotificationType) {
  return [NotificationType.PermitSubmitted].includes(type)
}
export function isPermitChangedType(type: NotificationType) {
  return [NotificationType.PermitChanged].includes(type)
}
export function isPermitDeniedType(type: NotificationType) {
  return [NotificationType.PermitDenied].includes(type)
}
export function isPermitToInspectType(type: NotificationType) {
  return [NotificationType.PermitToInspect].includes(type)
}
export function isPermitActivatedType(type: NotificationType) {
  return [NotificationType.PermitActivated].includes(type)
}
export function isPermitReviewedType(type: NotificationType) {
  return NotificationType.PermitReviewed === type
}
export function isPermitOnSiteType(type: NotificationType) {
  return NotificationType.PermitOnsite === type
}
export function isPermitFailedType(type: NotificationType) {
  return NotificationType.PermitFailed === type
}
export function isPermitPassedType(type: NotificationType) {
  return NotificationType.PermitPassed === type
}
export function isPermitDeletedType(type: NotificationType) {
  return NotificationType.PermitDeleted === type
}
export function isPermitEndedType(type: NotificationType) {
  return NotificationType.PermitEnded === type
}

export function isFollowingType(type: NotificationType) {
  return [
    NotificationType.ActivityFollowed,
    NotificationType.DeliveryFollowed,
    NotificationType.PermitFollowed,
  ].includes(type)
}

export function isWorkflowMessageType(type: NotificationType) {
  return NotificationType.PermitMessageReceived === type
}

export function isFlagType(type: NotificationType): boolean {
  return [NotificationType.FlagCreated, NotificationType.FlagReplied].includes(
    type,
  )
}

export function isScheduleCommentType(type: NotificationType): boolean {
  return [
    NotificationType.ScheduleCommentCreated,
    NotificationType.ScheduleCommentReplied,
  ].includes(type)
}

export function isRFIType(type: NotificationType): boolean {
  return [
    NotificationType.RequestForInformationCreated,
    NotificationType.RfiReplied,
  ].includes(type)
}

export function isActivityChangedType(type: NotificationType): boolean {
  return [NotificationType.ActivityChanged].includes(type)
}

export function isPermitType(type: NotificationType): boolean {
  return [
    NotificationType.PermitAccepted,
    NotificationType.PermitSubmitted,
    NotificationType.PermitClosed,
    NotificationType.PermitChanged,
    NotificationType.PermitDenied,
    NotificationType.PermitToInspect,
    NotificationType.PermitActivated,
    NotificationType.PermitReviewed,
    NotificationType.PermitOnsite,
    NotificationType.PermitFailed,
    NotificationType.PermitPassed,
    NotificationType.PermitDeleted,
    NotificationType.PermitEnded,
    NotificationType.PermitFollowed,
  ].includes(type)
}

export function isAnnouncementType(type: NotificationType): boolean {
  return [
    NotificationType.AnnouncementCreated,
    NotificationType.AnnouncementStarted,
    NotificationType.AnnouncementFollowed,
  ].includes(type)
}

export function isActivityType(type: NotificationType): boolean {
  return [
    NotificationType.StatusUpdateCreated,
    NotificationType.StatusUpdateReplied,
    NotificationType.RequestForInformationCreated,
    NotificationType.RfiReplied,
    NotificationType.FlagCreated,
    NotificationType.FlagReplied,
    NotificationType.ScheduleCommentCreated,
    NotificationType.ScheduleCommentReplied,
    NotificationType.ActivityFollowed,
    NotificationType.ActivityChanged,
  ].includes(type)
}

export function isDeliveryType(type: NotificationType): boolean {
  return [
    NotificationType.DeliveryRequested,
    NotificationType.DeliveryRequestedByLocationClosure,
    NotificationType.DeliveryScheduled,
    NotificationType.DeliveryOnsite,
    NotificationType.DeliveryPassedInspection,
    NotificationType.DeliveryFailedInspection,
    NotificationType.DeliveryDone,
    NotificationType.DeliveryIncompleteDone,
    NotificationType.DeliveryUpdated,
    NotificationType.DeliveryDeleted,
    NotificationType.DeliveryDenied,
    NotificationType.DeliveryFollowed,
    NotificationType.DeliveryCanceled,
    NotificationType.DeliveryPaused,
    NotificationType.DeliveryOnhold,
    NotificationType.DeliveryDelivering,
  ].includes(type)
}

export function isDeliveryDenied(type: NotificationType): boolean {
  return NotificationType.DeliveryDenied === type
}

export function isDeliveryRequested(type: NotificationType): boolean {
  return [
    NotificationType.DeliveryRequested,
    NotificationType.DeliveryRequestedByLocationClosure,
  ].includes(type)
}

export function isDeliveryScheduled(type: NotificationType): boolean {
  return NotificationType.DeliveryScheduled === type
}

export function isDeliveryCanceled(type: NotificationType): boolean {
  return NotificationType.DeliveryCanceled === type
}

export function isDeliveryOnsite(type: NotificationType): boolean {
  return NotificationType.DeliveryOnsite === type
}

export function isDeliveryOnHold(type: NotificationType): boolean {
  return NotificationType.DeliveryOnhold === type
}

export function isDeliveryDelivering(type: NotificationType): boolean {
  return NotificationType.DeliveryDelivering === type
}

export function isDeliveryPaused(type: NotificationType): boolean {
  return NotificationType.DeliveryPaused === type
}

export function isDeliveryPassedInspection(type: NotificationType): boolean {
  return NotificationType.DeliveryPassedInspection === type
}

export function isDeliveryFailedInspection(type: NotificationType): boolean {
  return NotificationType.DeliveryFailedInspection === type
}

export function isDeliveryUpdated(type: NotificationType): boolean {
  return NotificationType.DeliveryUpdated === type
}

export function isDeliveryDone(type: NotificationType): boolean {
  return [
    NotificationType.DeliveryDone,
    NotificationType.DeliveryIncompleteDone,
  ].includes(type)
}

export function isStatusUpdateType(type: NotificationType): boolean {
  return [
    NotificationType.StatusUpdateCreated,
    NotificationType.StatusUpdateReplied,
  ].includes(type)
}

export function isReplyToThreadType(type: NotificationType): boolean {
  return [
    NotificationType.StatusUpdateReplied,
    NotificationType.FlagReplied,
    NotificationType.RfiReplied,
    NotificationType.ScheduleCommentReplied,
  ].includes(type)
}

export function isDeleteType(type: NotificationType): boolean {
  return NotificationType.DeliveryDeleted === type
}

export function isScannerDeactivatedType(type: NotificationType): boolean {
  return NotificationType.ScannerDeactivated === type
}

import * as React from 'react'

import { observer } from 'mobx-react'

import PermitListItem from '~/client/src/shared/components/PermitListItem'
import SitePermit from '~/client/src/shared/models/Permit'

import MapViewItemBase from '../SitemapHelpers/models/MapViewItemBase'
import GeneralMapPopup from './GeneralMapPopup'

import './MapPopup.scss'

interface IProps {
  item: MapViewItemBase
  selectedSitePermits: SitePermit[]
  selectedPermitId: string

  openPermit(permit: SitePermit): void
  onClose(): void
}

@observer
export default class FormMapPopup extends React.Component<IProps> {
  public render() {
    const {
      item,
      selectedSitePermits = [],
      selectedPermitId,
      openPermit,
      onClose,
    } = this.props

    if (!selectedSitePermits.length) {
      return
    }

    return (
      <GeneralMapPopup item={item} onClose={onClose} className="brada4">
        <div className="relative overflow-auto draggable-elements-list">
          {selectedSitePermits.map(permit => (
            <PermitListItem
              key={permit.id}
              permit={permit}
              onClick={openPermit}
              isSelected={permit.id === selectedPermitId}
            />
          ))}
        </div>
      </GeneralMapPopup>
    )
  }
}

import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import MobileFileInput from '~/client/src/mobile/components/FileInput/MobileFileInput'
import MobileView from '~/client/src/mobile/components/MobileView'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import DaySeparator from '~/client/src/shared/components/DaySeparator'
import { Loader } from '~/client/src/shared/components/Loader'
import StatusUpdateThread from '~/client/src/shared/components/StatusUpdateEntry/StatusUpdateThread'
import StatusUpdateMessagesViewBase from '~/client/src/shared/components/StatusUpdateMessagesViewBase'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

interface IProps {
  common: MobileCommonStore
  slackBarStore: SlackBarStore
}

interface IRouteParams {
  statusUpdateId: string
}

@inject('common', 'slackBarStore')
@observer
class StatusUpdateMessagesView extends StatusUpdateMessagesViewBase<
  IProps & RouteComponentProps<IRouteParams>
> {
  public constructor(props) {
    super(props)

    this.statusUpdateId = this.props.match.params.statusUpdateId
  }

  @action.bound
  public goBack() {
    const store = this.props.slackBarStore
    if (store.shouldShowPreview) {
      return store.hidePreview()
    }

    const { common } = this.props
    common.toggleNavBar()
    common.history.goBack()
  }

  @action.bound
  public goToActivityDetails() {
    this.props.common.displayActivityDetailsView(this.activity.code)
  }

  public render() {
    return (
      <MobileView
        content={this.content}
        footer={this.footer}
        header={this.header}
        imagePreview={this.imagePreview}
      />
    )
  }

  public get content() {
    if (!this.activity.code) {
      return <Loader />
    }

    const currEntryDate = new Date(this.statusUpdate.createdAt)

    return (
      <div className="activity-log">
        <DaySeparator date={currEntryDate} />
        <StatusUpdateThread
          statusUpdate={this.statusUpdate}
          isStatusUpdateViewDisplayed={true}
          hideButtons={true}
          FileInputType={MobileFileInput}
        />
      </div>
    )
  }

  public componentDidMount() {
    const { common } = this.props
    common.hideNavBar()
  }
}

export default withRouter(StatusUpdateMessagesView)

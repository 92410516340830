import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import { IPermitTypeField } from '~/client/graph'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import SitePermit from '~/client/src/shared/models/Permit'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'

import StruxhubWorkflowSelector from '../../../StruxhubInputs/StruxhubSelector/StruxhubWorkflowSelector'
import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'
import PermitBaseFormField from './PermitBaseFormField'

interface IProps {
  typeField: IPermitTypeField

  store: SitePermitCreationFormStore
  fieldsStore: IPermitFieldsStore

  isViewMode: boolean

  tableRowIndex?: number
  tableId?: string
  permitTypesStore?: PermitTypesStore
}

type FieldValue = { id: string; caption?: string }

const getDefaultValue = (): FieldValue => ({
  id: '',
  caption: '',
})

@inject('permitTypesStore')
@observer
export default class PermitFormLinkedWorkflowField extends React.Component<IProps> {
  public render(): JSX.Element {
    const { isViewMode, typeField } = this.props

    return (
      <PermitBaseFormField
        typeField={typeField}
        isViewMode={isViewMode}
        viewModeElements={this.viewModeElements}
        onAddFieldClick={this.addNewFieldValue}
      >
        {this.fieldValues.map((value, index) => (
          <StruxhubWorkflowSelector
            key={`${value.id}_${value.caption}_${index}`}
            value={value.id}
            caption={value.caption}
            onClick={this.onOpenWorkflowModal.bind(this, index)}
            label={typeField.caption}
            isRequired={typeField.isMandatory}
          />
        ))}
      </PermitBaseFormField>
    )
  }

  private get fieldValues(): FieldValue[] {
    const { typeField, fieldsStore, tableId, tableRowIndex } = this.props
    const values = fieldsStore.getFieldValues<string | SitePermit>(
      typeField.id,
      tableId,
      tableRowIndex,
    )

    if (!values.length) return [getDefaultValue()]

    return values.map(value => {
      if (typeof value === 'string') return { id: value, caption: '' }

      return {
        id: value.id,
        caption: value.id
          ? ''
          : `New ${value.getCaption(this.props.permitTypesStore)}`,
      }
    })
  }

  private onOpenWorkflowModal(fieldIndex: number) {
    const { store, typeField, tableId, tableRowIndex } = this.props
    store.setSelectedField(typeField, fieldIndex, tableId, tableRowIndex)
    store.setSelectedOnChangeHandler(this.onChange.bind(this, fieldIndex))
  }

  @action.bound
  private onChange(index: number, value: string) {
    const { fieldsStore, typeField, tableId, tableRowIndex } = this.props
    const { changeFieldValue, changeTableFieldValue } = fieldsStore

    if (this.isTableField) {
      changeTableFieldValue(tableId, tableRowIndex, typeField, index, value)
      return
    }
    changeFieldValue(this.props.typeField, index, value)
  }

  private get viewModeElements(): JSX.Element[] {
    return this.fieldValues.map(workflow => (
      <StruxhubWorkflowSelector key={workflow.id} value={workflow.id} />
    ))
  }

  private addNewFieldValue = () => {
    if (!this.isTableField) {
      const { fieldsStore, typeField } = this.props
      fieldsStore.addNewFieldValue(typeField, '')
    }
  }

  private get isTableField(): boolean {
    return !!this.props.tableId
  }
}

import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import CalendarPlaceholderStore from '~/client/src/shared/components/CalendarDayView/components/CalendarPlaceholder.store'
import FileInputBase from '~/client/src/shared/components/FileInput/FileInput'
import WorkflowDirectory from '~/client/src/shared/components/WorkflowDirectory/WorkflowDirectory'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import SitePermit from '~/client/src/shared/models/Permit'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ConstraintsStore from '~/client/src/shared/stores/domain/Constraints.store'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaterialCategoriesStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import PermitInspectionChangesStore from '~/client/src/shared/stores/domain/PermitInspectionChanges.store'
import PermitInspectionsStore from '~/client/src/shared/stores/domain/PermitInspections.store'
import PermitStatusChangesStore from '~/client/src/shared/stores/domain/PermitStatusChanges.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import SitePermitCreationFormStore from '../../../../../SitePermitCreationForm.store'
import SitePermitCreationFormContent from '../../../../../SitePermitCreationFormContent'

interface IProps {
  store: SitePermitCreationFormStore

  projectDateStore?: ProjectDateStore
  eventsStore?: EventsStore
  fileUploadingStore?: FileUploadingStore
  permitTypesStore?: PermitTypesStore
  permitStatusChangesStore?: PermitStatusChangesStore
  permitInspectionsStore?: PermitInspectionsStore
  permitInspectionChangesStore?: PermitInspectionChangesStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  companiesStore?: CompaniesStore
  calendarPlaceholderStore?: CalendarPlaceholderStore
  locationAttributesStore?: LocationAttributesStore
  materialsStore?: MaterialsStore
  materialCategoryStore?: MaterialCategoriesStore
  tagsStore?: TagsStore
  constraintsStore?: ConstraintsStore
}

enum WorkflowPickerMode {
  New = 'new',
  Existing = 'existing',
}

@inject(
  'projectDateStore',
  'eventsStore',
  'fileUploadingStore',
  'permitTypesStore',
  'permitStatusChangesStore',
  'permitInspectionsStore',
  'permitInspectionChangesStore',
  'userProjectsStore',
  'projectMembersStore',
  'companiesStore',
  'locationAttributesStore',
  'materialsStore',
  'materialCategoryStore',
  'calendarPlaceholderStore',
  'tagsStore',
  'constraintsStore',
)
@observer
export default class FormWorkflowPicker extends React.Component<IProps> {
  @observable private selectedMode: WorkflowPickerMode =
    WorkflowPickerMode.Existing

  private readonly store: SitePermitCreationFormStore

  public constructor(props: IProps) {
    super(props)

    const currentValue =
      this.props.store.selectedFieldValues[this.props.store.selectedFieldIndex]

    this.store = new SitePermitCreationFormStore(
      props.eventsStore,
      props.projectDateStore,
      props.fileUploadingStore,
      props.permitTypesStore,
      props.userProjectsStore,
      props.projectMembersStore,
      props.companiesStore,
      props.locationAttributesStore,
      props.materialsStore,
      props.materialCategoryStore,
      props.tagsStore,
      props.constraintsStore,
      null,
      props.permitStatusChangesStore,
      props.permitInspectionsStore,
      props.permitInspectionChangesStore,
      props.calendarPlaceholderStore,
    )

    if (currentValue instanceof SitePermit) {
      this.store.workflowFieldsStore.setDuplicateWorkflowData(currentValue)
      this.selectedMode = WorkflowPickerMode.New
    }
  }

  public render() {
    return (
      <div className="overflow-y-auto col">
        {this.isCreateNewWorkflowModalOpen
          ? this.renderNewWorkflow()
          : this.renderExistingWorkflow()}
      </div>
    )
  }

  private renderNewWorkflow() {
    const { canSaveWorkflow } = this.store
    return (
      <>
        <SitePermitCreationFormContent
          store={this.store}
          FileInputType={FileInputBase}
          isReadonly={false}
        />
        <div className="row x-between pa10">
          <BaseActionButton
            title={Localization.translator.selectExistingWorkflow}
            onClick={this.toggleMode}
            isEnabled
          />
          <BaseActionButton
            title={Localization.translator.apply}
            onClick={this.onApply}
            isEnabled={canSaveWorkflow}
            className="primary-theme"
          />
        </div>
      </>
    )
  }

  private renderExistingWorkflow() {
    return (
      <>
        <WorkflowDirectory
          workflowPredicate={this.existingWorkflowsFilter}
          onItemClick={this.onItemClick}
          selectedWorkflowIds={this.props.store.selectedFieldValues}
        />
        <div className="pa10">
          <BaseActionButton
            title={Localization.translator.createNewWorkflow}
            onClick={this.toggleMode}
            isEnabled
          />
        </div>
      </>
    )
  }

  private existingWorkflowsFilter = (workflow: SitePermit) => {
    const { editablePermit, modalSearchValue } = this.props.store
    const searchValue = modalSearchValue || EMPTY_STRING

    const isSameWorkflow = editablePermit.id === workflow.id
    const includesName = workflow
      .getCaption(this.props.permitTypesStore)
      .toLowerCase()
      .includes(searchValue.toLowerCase())

    return !isSameWorkflow && includesName
  }

  @action.bound
  private toggleMode() {
    this.selectedMode =
      this.selectedMode === WorkflowPickerMode.New
        ? WorkflowPickerMode.Existing
        : WorkflowPickerMode.New
    if (this.selectedMode === WorkflowPickerMode.New && !this.store.template) {
      this.store.resetSelectedField()
      this.store.setDefaultSitePermit(null, this.props.store.template.id, false)
    }
  }

  private onApply = () => {
    this.props.store.changeSelectedFieldValue(this.store.editablePermit)
    this.props.store.updateSearchValue(EMPTY_STRING)
  }

  private get isCreateNewWorkflowModalOpen(): boolean {
    return this.selectedMode === 'new'
  }

  private onItemClick = ({ id }: SitePermit) => {
    this.props.store.changeSelectedFieldValue(id)
    this.props.store.updateSearchValue(EMPTY_STRING)
  }
}

type CommonRoute = keyof typeof commonRoutes

const projectBase = '/project/'
export const projectBased = (subRoute: string) =>
  projectBase + ':projectCode' + subRoute

export function getProjectUrl(projectCode: string): string {
  return location.origin + getTransitionPath(projectBased(''), projectCode)
}

export const bareRoutes = {
  HOME: '/home',
  FORMS: '/forms',
  PROJECT_SETUP: '/projectSetup',
  DELIVERIES: '/deliveries',
  ACTIVITIES: '/activities',
  NOTIFICATIONS: '/notifications',
  NOTIFICATION_SETTINGS: '/notification-settings',
  DOCUMENTS: '/documents',
  RESET_PASSWORD: '/reset-password',
  USERS_DIRECTORY: '/users',
  BULK_STATUS_UPDATE: '/bulk-status-update',
  SIGN_UP: '/sign-up',
  QR_CODES: '/qr-codes',
}

const commonRoutes = {
  LOGIN: '/login',
  SAVE_PASSWORD: '/save-password',
  RESET_PASSWORD: `${bareRoutes.RESET_PASSWORD}/:resetCode?`,

  INFO: '/info/:code?/:payload?',
  FORM_TYPES: '/form-types',
  PROJECTS: '/projects',
  REDIRECTOR: '/redirector',

  SIGN_UP: projectBased(bareRoutes.SIGN_UP),

  HOME: projectBased(bareRoutes.HOME),
  FORMS: projectBased(bareRoutes.FORMS),
  DELIVERIES: projectBased(bareRoutes.DELIVERIES),
  ACTIVITIES: projectBased(bareRoutes.ACTIVITIES),
  NOTIFICATIONS: projectBased(bareRoutes.NOTIFICATIONS),

  ACTIVITY_DETAIL: projectBased(`${bareRoutes.ACTIVITIES}/:code`),
  DELIVERY_DETAILS: projectBased(`${bareRoutes.DELIVERIES}/:deliveryId?`),
}

export function isCommonRoute(route): route is CommonRoute {
  return Object.keys(commonRoutes).includes(route)
}

export enum UrlParamKey {
  PasswordResetCode = 'oobCode',
  InviteKey = 'inviteKey',
  ForceInviteKey = 'forceInviteKey',
  OverrideNavBar = 'overrideNavBar',
  DeliveryId = 'deliveryId',
  Announcement = 'ann',
  Form = 'form',
}

export const getURLParam = (path: string = location.pathname) =>
  path.split('/').slice(-1)[0]

export function isSignUpPageRequested(path: string = location.pathname) {
  return path.includes(bareRoutes.SIGN_UP)
}

export function isPathToDetails(path: string): boolean {
  const chunkCount = path.split('/').filter(c => !!c).length

  if (isProjectBasedPath(path)) {
    return chunkCount > 3
  }

  return chunkCount > 1
}

export const isDeliveryInfoPageRequested = (
  path: string = location.pathname,
): boolean => {
  return path.includes(bareRoutes.DELIVERIES) && isPathToDetails(path)
}

export function getTransitionPath(
  route: string = location.pathname,
  projectCode: string,
): string {
  if (isProjectBasedPath(route)) {
    return route.replace(':projectCode', projectCode)
  }

  return route
}

export const getInitProjectCodeFromPath = (): string => {
  const path = location.pathname

  if (isProjectBasedPath(path)) {
    return path.split('/')[2]
  }

  return ''
}

export function isProjectBasedPath(path: string = location.pathname): boolean {
  return path.startsWith(projectBase)
}

export const getRedirect = (
  { isDesktopVersion, redirects },
  isMobile: boolean,
  isTablet: boolean,
): {
  redirectRequired: boolean
  redirect: any
  toDesktop: boolean
  host: string
} => {
  let redirectRequired = false
  let host = location.origin
  let toDesktop = isDesktopVersion
  let redirect

  // check if redirect to correct device is needed
  if (
    !isTablet &&
    ((isDesktopVersion && isMobile) || (!isDesktopVersion && !isMobile))
  ) {
    const url = window.location.origin
    redirectRequired = true
    toDesktop = !isDesktopVersion
    redirect = redirects.find(r => url.includes(r.from))
    if (redirect) {
      host = host.replace(redirect.from, redirect.to)
    }
  }

  return { redirectRequired, redirect, toDesktop, host }
}

export default commonRoutes

// Inspired in MemberSearchList
import * as React from 'react'

import { Icon, Spinner } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Keys from '~/client/src/shared/enums/Keys'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Guard from '~/client/src/shared/utils/Guard'

import BaseStruxhubInput from '../BaseStruxhubInput'

import './StruxhubIncrementalSearch.scss'

interface IProps<T> {
  label: string

  /** Search pattern */
  pattern: string

  /** Array of members to show as applied */
  membersList: T[]
  value: T[]

  isRequired: boolean
  isLoading?: boolean

  /** Max number of values to store. Set to 1 to be single-value. */
  maxNumber?: number
  onClear: () => void

  /** Function to handle value change. Should perform search and update `membersList` */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void

  onApply: (member: T) => void
  onCancel: (event: React.ChangeEvent<HTMLInputElement>) => void

  previewMember: (member: T) => JSX.Element

  /** Minimum input length to start searching. Default is 1 */
  minLength?: number
  maxResults?: number // Maximum number of results to show at once

  additionalHelperText?: string
  validationMessage?: string
  isValid?: boolean
  isChanged?: boolean
}

const navigationKeys = new Set<string>([
  Keys.ArrowDown,
  Keys.ArrowUp,
  Keys.Enter,
])

const MIN_LENGTH = 1 // DO NOT SET 0
const MAX_RESULTS = 5

interface Member {
  id: string
  name: string
}

@observer
export default class StruxhubIncrementalSearch<
  T extends Member,
> extends React.Component<IProps<T>> {
  public constructor(props: IProps<T>) {
    super(props)
    const { onChange, onApply, onCancel, previewMember, label } = props
    Guard.requireAll([onChange, onApply, onCancel, previewMember, label])
  }

  @observable private pointer = 0
  @observable private isOpen = false
  @observable private didInteract = false
  @observable private didCancelValue = false

  @computed private get membersList() {
    return this.props.membersList
      .filter(member => !this.props.value.some(val => val.id === member.id))
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(0, this.props.maxResults || MAX_RESULTS)
  }

  public componentDidUpdate(prevProps: Readonly<IProps<T>>): void {
    if (prevProps.value.length !== this.props.value.length) {
      this.isOpen = true
      this.didInteract = true
    }

    if (this.didCancelValue) {
      this.didCancelValue = false

      // Ref would be better but way greater effort to same effect
      document.getElementById('incrementalSearchInput')?.focus()
    }
  }

  public render() {
    const { pattern, onClear, isLoading } = this.props

    return (
      <BaseStruxhubInput {...this.props} value={pattern}>
        {(isValueInvalid, isInFocus, onFocus, onBlur) => {
          const handleFocus = () => {
            this.isOpen = true
            onFocus()
          }
          const handleBlur = () => {
            setTimeout(() => {
              if (this.didInteract) {
                this.didInteract = false
                return
              }
              this.isOpen = false
              onBlur()
            }, 100)
          }

          return (
            <div className="incremental-search">
              {this.renderAppliedValue()}
              {this.isTextFieldVisible && (
                <>
                  <div className="form-setup-search-input">
                    <StruxhubInput
                      id="incrementalSearchInput"
                      label={this.props.label}
                      isRequired={this.props.isRequired}
                      isLabelHidden
                      value={pattern}
                      onChange={this.handleChange}
                      onValueReset={onClear}
                      onKeyDown={this.handleKeyDown}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      withDelay
                      inputDelay={50}
                      customRightIcon={isLoading ? <Spinner size={20} /> : null}
                      additionalHelperText={this.additionalHelperText}
                    />
                  </div>
                  {this.isOpen &&
                    this.shouldSearch &&
                    this.renderSearchResult()}
                </>
              )}
            </div>
          )
        }}
      </BaseStruxhubInput>
    )
  }

  @computed
  private get additionalHelperText() {
    return !this.props.isLoading &&
      !this.membersList.length &&
      this.shouldSearch
      ? Localization.translator.noResults
      : this.props.additionalHelperText
  }

  @computed
  private get isTextFieldVisible() {
    return (
      !this.props.maxNumber || this.props.value.length < this.props.maxNumber
    )
  }

  private renderSearchResult() {
    return (
      <div
        className="form-setup-search-input-result ba-light-grey bg-palette-brand-lightest"
        onKeyDown={this.handleKeyDown}
        tabIndex={0}
      >
        {this.membersList.map((member, index) => {
          return (
            <div
              key={member.id}
              className={classList({
                'result-row row y-center pa10 pointer': true,
                current: index === this.pointer,
              })}
              onClick={this.handleApply.bind(this, member)}
              onMouseOver={this.handleMouseOver.bind(this, index)}
              aria-label={`Select ${member?.name}`}
            >
              {this.props.previewMember(member)}
            </div>
          )
        })}
      </div>
    )
  }

  private handleCancelValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onCancel(event)
    this.didCancelValue = true
  }

  private renderAppliedValue() {
    const { value } = this.props
    if (!value?.length) {
      return
    }

    return (
      <div className="form-setup-result-list">
        {value.map((member, index) => {
          return (
            <div className="result-row row pt7 mx10" key={index}>
              {this.props.previewMember(member)}
              <Icon
                className="bg-grey-scale-light br-rounded pointer text white ml10"
                icon={IconNames.SMALL_CROSS}
                onClick={this.handleCancelValue.bind(this, member)}
              />
            </div>
          )
        })}
      </div>
    )
  }

  @computed
  private get shouldSearch() {
    return this.props.pattern?.length >= (this.props.minLength || MIN_LENGTH)
  }

  private readonly handleApply = (member: any) => {
    this.props.onApply(member)
  }

  @action.bound
  private readonly handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.props.onChange(event)
    this.pointer = 0
  }

  @action.bound
  private readonly handleKeyDown = (event: React.KeyboardEvent) => {
    if (!navigationKeys.has(event?.key)) {
      return
    }

    event?.preventDefault()

    if (!this.membersList.length) {
      return
    }

    switch (event?.key) {
      case Keys.ArrowUp:
        if (this.pointer > 0) {
          this.pointer--
        }
        break
      case Keys.ArrowDown:
        if (this.pointer < this.membersList.length - 1) {
          this.pointer++
        }
        break
      case Keys.Enter:
        this.handleApply(this.membersList[this.pointer])
        break
    }
  }

  @action.bound
  private readonly handleMouseOver = (index: number) => {
    this.pointer = index
  }
}

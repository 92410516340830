import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import MobileView from '~/client/src/mobile/components/MobileView'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import WorkflowViewFormWrapper from '~/client/src/shared/components/SitePermitCreationForm/WorkflowViewFormWrapper'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ConstraintsStore from '~/client/src/shared/stores/domain/Constraints.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

import { Loader } from '../../shared/components/Loader'
import SitePermitCreationFormStore from '../../shared/components/SitePermitCreationForm/SitePermitCreationForm.store'
import { FileUploadingStore } from '../../shared/stores/domain/FileUploading.store'
import FormCategoriesStore from '../../shared/stores/domain/FormCategories.store'
import LocationAttributesStore from '../../shared/stores/domain/LocationAttributes.store'
import MaterialCategoriesStore from '../../shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '../../shared/stores/domain/Materials.store'
import PermitInspectionChangesStore from '../../shared/stores/domain/PermitInspectionChanges.store'
import PermitInspectionsStore from '../../shared/stores/domain/PermitInspections.store'
import PermitStatusChangesStore from '../../shared/stores/domain/PermitStatusChanges.store'
import PermitTypesStore from '../../shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '../../shared/stores/domain/ProjectMembers.store'
import SitePermitsStore from '../../shared/stores/domain/SitePermits.store'
import UserProjectsStore from '../../shared/stores/domain/UserProjects.store'
import ProjectDateStore from '../../shared/stores/ui/ProjectDate.store'
import MobileFileInput from '../components/FileInput/MobileFileInput'
import MobileEventStore from '../stores/EventStore/MobileEvents.store'

interface IProps {
  common?: MobileCommonStore
  sitePermitsStore?: SitePermitsStore
  eventsStore?: MobileEventStore
  projectDateStore?: ProjectDateStore
  fileUploadingStore?: FileUploadingStore
  permitTypesStore?: PermitTypesStore
  permitStatusChangesStore?: PermitStatusChangesStore
  permitInspectionsStore?: PermitInspectionsStore
  permitInspectionChangesStore?: PermitInspectionChangesStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  companiesStore?: CompaniesStore
  locationAttributesStore?: LocationAttributesStore
  materialsStore?: MaterialsStore
  materialCategoryStore?: MaterialCategoriesStore
  tagsStore?: TagsStore
  constraintsStore?: ConstraintsStore
  formCategoriesStore?: FormCategoriesStore
}

interface IRouteParams {
  formId: string
}

@inject(
  'common',
  'sitePermitsStore',
  'eventsStore',
  'projectDateStore',
  'fileUploadingStore',
  'permitTypesStore',
  'permitStatusChangesStore',
  'permitInspectionsStore',
  'permitInspectionChangesStore',
  'userProjectsStore',
  'projectMembersStore',
  'companiesStore',
  'locationAttributesStore',
  'materialsStore',
  'materialCategoryStore',
  'tagsStore',
  'constraintsStore',
  'formCategoriesStore',
)
@observer
class PermitView extends React.Component<
  IProps & RouteComponentProps<IRouteParams>
> {
  private readonly permitStore: SitePermitCreationFormStore

  public constructor(props: IProps & RouteComponentProps<IRouteParams>) {
    super(props)

    this.permitStore = new SitePermitCreationFormStore(
      props.eventsStore,
      props.projectDateStore,
      props.fileUploadingStore,
      props.permitTypesStore,
      props.userProjectsStore,
      props.projectMembersStore,
      props.companiesStore,
      props.locationAttributesStore,
      props.materialsStore,
      props.materialCategoryStore,
      props.tagsStore,
      props.constraintsStore,
      props.sitePermitsStore,
      props.permitStatusChangesStore,
      props.permitInspectionsStore,
      props.permitInspectionChangesStore,
    )

    if (props.sitePermitsStore.isDataReceived) {
      this.permitStore.setDefaultSitePermit(props.match.params.formId)
    }
  }

  public UNSAFE_componentWillUpdate() {
    const { sitePermitsStore, match } = this.props
    if (sitePermitsStore.isDataReceived) {
      this.permitStore.setDefaultSitePermit(match.params.formId)
    }
  }

  public componentDidMount() {
    const { common } = this.props
    common.hideNavBar()
  }

  public render() {
    if (this.isLoading) {
      return <Loader />
    }

    return (
      <MobileView
        content={this.content}
        footer={null}
        header={null}
        imagePreview={null}
      />
    )
  }

  private get content(): JSX.Element {
    return (
      <WorkflowViewFormWrapper
        workflowToShow={this.permitStore.editablePermit}
        onClose={this.goBack}
        FileInputType={MobileFileInput}
        isMobileMode={true}
      />
    )
  }

  @action.bound
  private goBack() {
    const { common } = this.props
    common.toggleNavBar()
    common.history.goBack()
  }

  private get isLoading(): boolean {
    const {
      sitePermitsStore,
      permitTypesStore,
      companiesStore,
      formCategoriesStore,
    } = this.props
    return (
      !sitePermitsStore.isDataReceived ||
      !permitTypesStore.isDataReceived ||
      !companiesStore.isDataReceived ||
      !formCategoriesStore.isDataReceived
    )
  }
}

export default withRouter(PermitView)

import * as React from 'react'

import { Icon, Switch } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import {
  Content,
  Footer,
  Header,
  Padding,
  Thickness,
  View,
} from '~/client/src/shared/components/Layout'
import ChooseTenant from '~/client/src/shared/components/Tenants/ChooseTenant'
import Text from '~/client/src/shared/components/Typography/Text'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import InitialState from '~/client/src/shared/stores/InitialState'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import TenantsStore from '~/client/src/shared/stores/domain/Tenants.store'

import Config from '../../Config'
import { EMPTY_STRING } from '../../utils/usefulStrings'
import ProjectBlock from './components/ProjectBlock/ProjectBlock'

import './Projects.scss'

interface IProjectsView {
  state?: InitialState
  projectsStore?: ProjectsStore
  companiesStore?: CompaniesStore
  tenantsStore?: TenantsStore
}

const ICON_SIZE = 20

@inject('state', 'projectsStore', 'companiesStore', 'tenantsStore')
@observer
export default class ProjectsListView extends React.Component<IProjectsView> {
  /**
   * Keeping state for the back button has many advantages
   * over actually manipulating tenant data back to the previous state:
   * - Simpler
   * - Much faster if you stick with the same tenant
   * - Less prone to bugs
   */
  @observable private isBackToTenantSelection = false

  @observable private rememberChoice = false

  public componentDidMount() {
    const { projectsStore, state, tenantsStore } = this.props
    const { list: projects } = projectsStore

    const previousSessionInfo = localStorage.getItem(
      state.tenantUserSession?.nameId,
    )
    if (previousSessionInfo) {
      const tenantHostHash = btoa(tenantsStore.activeTenant?.host)
      const lastViewedProjectId =
        JSON.parse(previousSessionInfo)?.[tenantHostHash]?.lastViewedProject

      // If there is a last viewed project for the current tenant, select it
      if (projectsStore.hasProjectById(lastViewedProjectId)) {
        projectsStore.selectProject(lastViewedProjectId)
      }
    }

    // If there is only one project, select it
    if (projects.length === 1) {
      projectsStore.selectProject(projects[0].id)
    }
  }

  public render() {
    const {
      state: { userActiveProjectSettings },
      companiesStore,
      projectsStore: { list: projects },
      tenantsStore,
    } = this.props

    if (
      this.isBackToTenantSelection ||
      (tenantsStore.userTenants.length > 1 && !tenantsStore.activeTenant)
    ) {
      return (
        <ChooseTenant
          isBack={this.isBackToTenantSelection}
          tenantsStore={tenantsStore}
          onTenantSelected={this.handleTenantSelected}
        />
      )
    }

    const projectBlocks = projects.map(p => {
      return (
        <Padding padding={Thickness.fromTRBL(0, 0, 10, 10)} key={p.id}>
          <ProjectBlock project={p} onClick={this.handleProjectSelected} />
        </Padding>
      )
    })

    return (
      <View className="projects-list">
        <Header>
          <Padding padding={Thickness.fromTRBL(20, 0, 10, 18)}>
            <Text bold className="header">
              {tenantsStore.userTenants.length > 1 &&
                tenantsStore.activeTenant && (
                  <Icon
                    icon={IconNames.CHEVRON_LEFT}
                    onClick={this.handleBackClick}
                    size={ICON_SIZE}
                    className="pointer mr4"
                    title={Localization.translator.goBack}
                  />
                )}
              {companiesStore.getCompanyNameById(
                userActiveProjectSettings?.companyId,
                projects.length
                  ? Localization.translator.chooseProject
                  : EMPTY_STRING,
              )}
            </Text>
          </Padding>
          <Padding padding={Thickness.fromTRBL(0, 0, 10, 18)}>
            <div className="bb-light-input-border" />
          </Padding>
        </Header>
        <Content scrollable={true}>
          <Padding padding={Thickness.symmetric(18, 10)}>
            {projectBlocks}
          </Padding>
        </Content>
        <Footer>
          {Config.TENANTLESS_MODE && (
            <Padding padding={Thickness.symmetric(20, 20)}>
              <div className="bb-light-input-border">
                <Switch
                  checked={this.rememberChoice}
                  labelElement={
                    <span className="text bold header">
                      {Localization.translator.rememberChoice}
                    </span>
                  }
                  onChange={this.toggleRememberChoice}
                />
              </div>
            </Padding>
          )}
        </Footer>
      </View>
    )
  }

  private handleProjectSelected = (id: string) => {
    this.props.projectsStore.selectProject(id)
    this.saveCurrentChoice(id)
  }

  private saveCurrentChoice(id: string) {
    if (!Config.TENANTLESS_MODE) return

    const userId = this.props.state.tenantUserSession?.nameId
    const tenantHost = this.props.tenantsStore.activeTenant?.host

    if (!this.rememberChoice || !userId || !tenantHost) return

    const localStoredRaw = localStorage.getItem(userId)
    const localStored = localStoredRaw ? JSON.parse(localStoredRaw) : {}

    // Obfuscate the tenant host just in case. It's not a security measure, just a precaution.
    const obfuscatedTenantHost = btoa(tenantHost)
    localStored[obfuscatedTenantHost] = {
      lastViewedProject: id,
    }
    localStorage.setItem(userId, JSON.stringify(localStored))
  }

  @action.bound
  private toggleRememberChoice() {
    this.rememberChoice = !this.rememberChoice
  }

  @action.bound
  private handleBackClick() {
    this.isBackToTenantSelection = true
  }

  @action.bound
  private handleTenantSelected() {
    this.isBackToTenantSelection = false
  }
}

import React from 'react'

import { Position, Toaster } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

const AppToaster = Toaster.create({
  className: 'recipe-toaster no-outline-container',
  position: Position.BOTTOM,
})

const DEFAULT_TOAST_TIMEOUT = 3000

export enum ToastTheme {
  SUCCESS = 'primary-blue-theme',
  ERROR = 'error-theme',
}

export function showToast(
  message: React.ReactNode,
  className: string,
  icon: any = null,
  timeout: number = DEFAULT_TOAST_TIMEOUT,
  key?: string,
) {
  return AppToaster.show(
    {
      message,
      icon,
      className,
      timeout,
    },
    key,
  )
}

export function dismissToast(key: string) {
  AppToaster.dismiss(key)
}

export function showErrorToast(
  message: string = '',
  timeout: number = DEFAULT_TOAST_TIMEOUT,
) {
  showToast(message, ToastTheme.ERROR, IconNames.ERROR, timeout)
}

import { FirebaseApp } from 'firebase/app'
import { Auth, getAuth } from 'firebase/auth'

import { createGraphClient } from '~/client/graph'
import MobileEventStore from '~/client/src/mobile/stores/EventStore/MobileEvents.store'
import registerMobileEvents from '~/client/src/mobile/stores/EventStore/mobileEvents'
import MobileInitialState from '~/client/src/mobile/stores/MobileInitialState'
import MobileRootStore from '~/client/src/mobile/stores/MobileRoot.store'
import Configuration, { IAppConfig } from '~/client/src/shared/Config'
import ApiAuthService from '~/client/src/shared/services/ApiAuthService'
import FirebaseAuthService from '~/client/src/shared/services/FirebaseAuthService'
import EffectsProcessor from '~/client/src/shared/stores/EventStore/EffectsProcessors/EffectsProcessor'
import FlowProcessor from '~/client/src/shared/stores/EventStore/EffectsProcessors/FlowProcessor/FlowProcessor'
import registerCommonEvents from '~/client/src/shared/stores/EventStore/commonEvents'

import CommonContainer from '../shared/CommonContainer'
import ProcoreService from '../shared/services/ProcoreService'
import GraphProcessor from '../shared/stores/EventStore/EffectsProcessors/GraphProcessors/GraphProcessor'
import WorkerProcessor from '../shared/stores/EventStore/EffectsProcessors/WorkerProcessor/WorkerProcessor'
import GraphExecutor from '../shared/utils/GraphExecutor'

export default class Container extends CommonContainer {
  private configuration: IAppConfig = Configuration
  private $auth: Auth
  private $rootStore: MobileRootStore
  private initialState: MobileInitialState
  protected eventsStore: MobileEventStore
  private firebaseAuthService: FirebaseAuthService
  private apiAuthService: ApiAuthService
  private procoreService: ProcoreService
  private graphExecutor: GraphExecutor

  public constructor(private app: FirebaseApp, private isTestEnv: boolean) {
    super()

    this.app = app

    this.initialState = new MobileInitialState()

    this.procoreService = new ProcoreService(this.initialState)

    this.firebaseAuthService = new FirebaseAuthService(this.auth)

    this.apiAuthService = new ApiAuthService(
      this.initialState,
      this.graphExecutor,
    )

    const getValidToken = () => {
      return Configuration.TENANTLESS_MODE
        ? this.apiAuthService.getValidAccessToken()
        : this.firebaseAuthService.getValidAccessToken()
    }

    this.initialState.graphClient = createGraphClient(
      getValidToken,
      Configuration.TENANTLESS_MODE ? undefined : location.host,
    )

    this.graphExecutor = new GraphExecutor(this.initialState)

    this.eventsStore = new MobileEventStore(
      new EffectsProcessor(
        getValidToken,
        new FlowProcessor(),
        new WorkerProcessor(),
        new GraphProcessor(this.initialState, this.graphExecutor),
      ),
      this.initialState,
    )

    registerCommonEvents(this.eventsStore, this.rootStore)
    registerMobileEvents(this.eventsStore, this.rootStore)

    this.registerGlobalEventListeners()
  }

  private get auth() {
    if (!this.$auth) {
      this.$auth = getAuth(this.app)
    }

    return this.$auth
  }

  public get rootStore() {
    if (!this.$rootStore) {
      this.$rootStore = new MobileRootStore(
        this.configuration,
        this.initialState,
        this.eventsStore,
        this.firebaseAuthService,
        this.apiAuthService,
        this.procoreService,
        this.graphExecutor,
        this.isTestEnv,
      )
    }

    return this.$rootStore
  }
}

import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { IReactionDisposer, action, reaction } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { CellMeasurerCache } from 'react-virtualized'

import { Loader } from '~/client/src/shared/components/Loader'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { QRResponse } from '~/client/src/shared/models/User'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ScanHistoriesStore from '~/client/src/shared/stores/domain/ScanHistories.store'
import ScannersStore from '~/client/src/shared/stores/domain/Scanners.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { NOOP } from '~/client/src/shared/utils/noop'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import EventsStore from '../../../../stores/EventStore/Events.store'
import InitialState from '../../../../stores/InitialState'
import TagsStore from '../../../../stores/domain/Tags.store'
import CommonStore from '../../../../stores/ui/Common.store'
import CompactConfirmDialog from '../../../CompactConfirmDialog/CompactConfirmDialog'
import ConfirmDialog from '../../../ConfirmDialog/ConfirmDialog'
import QrCodesStore from '../../QRCodes.store'
import EndRideConfirm from '../EndRideConfirm'
import QRCodesScannerLogList from '../QRCodesScannerLogList/QRCodesScannerLogList'
import QRCodesScannerLogListStore from '../QRCodesScannerLogList/QRCodesScannerLogList.store'
import {
  QRCodesViewFormStore,
  SCANNER_READER_ID,
  TabIds,
} from './QRCodesViewForm.store'
import ScannerFooter from './footer/ScannerFooter'

import './QRCodesViewEditForm.scss'

interface IProps {
  store: QrCodesStore

  isFullscreenAllowed: boolean

  state?: InitialState
  common?: CommonStore
  projectMembersStore?: ProjectMembersStore
  scannersStore?: ScannersStore
  eventsStore?: EventsStore
  scanHistoriesStore?: ScanHistoriesStore
  userProjectsStore?: UserProjectsStore
  projectDateStore?: ProjectDateStore

  tagsStore?: TagsStore
}

const DEFAULT_ROW_HEIGHT = 47

@inject(
  'state',
  'common',
  'projectMembersStore',
  'scannersStore',
  'eventsStore',
  'scanHistoriesStore',
  'userProjectsStore',
  'projectDateStore',
  'tagsStore',
)
@observer
export default class QRCodesViewForm extends React.Component<IProps> {
  private readonly clearPostEventCallback: () => void = null
  private readonly cellMeasurerCache: CellMeasurerCache = null
  private store: QRCodesViewFormStore = null
  private readonly scannerLogListStore: QRCodesScannerLogListStore = null
  private listRef: any = null
  private disposeFullScreenUpdateReaction: IReactionDisposer

  public constructor(props: IProps) {
    super(props)

    this.store = new QRCodesViewFormStore(
      props.store,
      props.common,
      props.scanHistoriesStore,
      props.projectDateStore,
      props.projectMembersStore,
      props.tagsStore,
      props.scannersStore,
      props.userProjectsStore,
      props.state,
    )

    this.scannerLogListStore = new QRCodesScannerLogListStore(
      props.store,
      props.common,
      props.state,
      props.scanHistoriesStore,
      props.projectDateStore,
      props.projectMembersStore,
    )

    this.cellMeasurerCache = new CellMeasurerCache({
      defaultHeight: DEFAULT_ROW_HEIGHT,
      fixedWidth: true,
    })

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.handlePostEventCallback,
    )
  }

  public componentDidMount(): void {
    this.props.store.setLastUsedScanner()
    this.store.initiateScannerView()

    this.disposeFullScreenUpdateReaction = reaction(
      () => this.props.store.isFullScreenMode,
      this.store.toggleFullScreenMode,
    )
    window.addEventListener('resize', this.onComponentResized)
  }

  public UNSAFE_componentWillUpdate() {
    this.props.store.setLastUsedScanner()
  }

  public componentWillUnmount(): void {
    this.clearPostEventCallback?.()
    this.disposeFullScreenUpdateReaction?.()

    if (this.store.html5QrcodeScanner) {
      this.store.html5QrcodeScanner.clear()
    }
    window.removeEventListener('resize', this.onComponentResized)
  }

  @action.bound
  private onComponentResized() {
    this.store.resizeScannerComponent()
  }

  public render(): JSX.Element {
    const { isLoading } = this.props.store

    if (isLoading) {
      return <Loader />
    }

    return (
      <>
        <div className="qr-codes-scanner-active col relative full-height">
          <div className="col full-height">
            {this.renderHeader()}
            {this.renderBody()}
          </div>
        </div>
        <ScannerFooter store={this.store} qrCodesStore={this.props.store} />
      </>
    )
  }

  private renderBody() {
    const {
      isEndConfirmShown,
      toggleEndRideConfirm,
      isRideModeModalShown,
      toggleRideModeModalShown,
      isStartSessionShown,
      toggleStartSessionShown,
    } = this.props.store
    const { selectedTabId, onEndRide, toggleMode, onStartFromQueue } =
      this.store
    const isScanView = selectedTabId === TabIds.Scan

    return (
      <>
        <EndRideConfirm
          title={Localization.translator.endRideConfirm}
          onHide={toggleEndRideConfirm}
          onApply={onEndRide.bind(null, false)}
          applyButtonText={Localization.translator.yes}
          cancelButtonText={Localization.translator.cancel}
          isShown={isEndConfirmShown}
        />
        <EndRideConfirm
          title={Localization.translator.changeModeConfirm}
          onHide={toggleRideModeModalShown}
          onApply={toggleMode}
          applyButtonText={Localization.translator.yes}
          cancelButtonText={Localization.translator.cancel}
          isShown={isRideModeModalShown}
        />
        <CompactConfirmDialog
          title={Localization.translator.startSessionConfirm}
          isShown={isStartSessionShown}
          onHide={toggleStartSessionShown}
          onApply={onStartFromQueue}
          applyButtonText={Localization.translator.yes}
          cancelButtonText={Localization.translator.cancel}
        />
        {this.renderDeletionConfirmDialog()}
        {isScanView ? this.renderScannerForm() : this.renderWholeLog()}
      </>
    )
  }

  private renderHeader(): JSX.Element {
    const { isScanMaster, isFullScreenMode } = this.props.store

    return (
      <>
        {!isFullScreenMode && (
          <div className="px10 bb-light-input-border">
            {isScanMaster && (
              <div className="row py8">
                <div className="text large medium-bold left pa8">
                  {Localization.translator.selectScanner}
                </div>
                {this.renderScannerSelector()}
              </div>
            )}
            <div className="row pt10">
              {Object.keys(TabIds).map(tabId => this.renderTab(tabId))}
            </div>
          </div>
        )}
      </>
    )
  }

  private renderScannerSelector(): JSX.Element {
    const { setScannerSelectionMode, scanner } = this.props.store

    return (
      <div
        className="scanner-selector row y-center bg-white ba-grey no-outline py7 brada4 pointer"
        onClick={setScannerSelectionMode}
      >
        <span className="text left large line-extra-large text-ellipsis">
          {scanner?.name ?? EMPTY_STRING}
        </span>
        <div className="relative no-grow float-right text light unclickable-element">
          <Icon icon={IconNames.CHEVRON_DOWN} />
        </div>
      </div>
    )
  }

  private renderTab(tabId: string): JSX.Element {
    const { toggleSelectedTab } = this.store

    return (
      <div
        onClick={toggleSelectedTab}
        className={classList({
          'text large medium-bold center pa10 pointer': true,
          'selected-tab primary-blue': tabId === this.store.selectedTabId,
        })}
        key={tabId}
      >
        {TabIds[tabId]}
      </div>
    )
  }

  private renderWholeLog = (): JSX.Element => {
    const isLoading = this.props.state.loading.get(e.SAVE_SCAN_HISTORIES)
    return (
      <div className="col overflow-auto qr-log">
        {isLoading ? <Loader /> : this.renderScannerLog()}
      </div>
    )
  }
  private renderScannerLog = (): JSX.Element => {
    return (
      <div className="col full-width overflow-auto multi-grid-container">
        <QRCodesScannerLogList
          store={this.scannerLogListStore}
          projectMembersStore={this.props.projectMembersStore}
        />
      </div>
    )
  }

  private renderScannerForm(): JSX.Element {
    return <>{this.renderScannerScreen()}</>
  }

  private renderDeletionConfirmDialog(): JSX.Element {
    const {
      isScannerDeleteModalShown,
      isLoading,
      performDelete,
      toggleDeleteModal,
    } = this.props.store

    return (
      <ConfirmDialog
        isOpen={isScannerDeleteModalShown}
        onCancelClicked={toggleDeleteModal}
        onDoneClicked={performDelete}
        doneTitle={Localization.translator.delete}
        loading={isLoading}
      >
        <div className="text large pre-line">
          {Localization.translator.shouldDeleteScanner}
        </div>
      </ConfirmDialog>
    )
  }

  private renderScannerScreen(): JSX.Element {
    const {
      store: { isFullScreenMode, isFullScreenFocused, response },
    } = this.props
    const { toggleFullscreenFocused } = this.store
    const isRedBackground = response === QRResponse.notAllowed
    const isGreenBackground = response === QRResponse.allowed

    return (
      <div
        className={classList({
          'col x-center overflow-hidden': true,
          'y-center bg-light-cool-grey': isFullScreenMode,
          'fixed fullscreen-scanner full-height full-width':
            isFullScreenMode && !isFullScreenFocused,
          'bg-red': isRedBackground,
          'bg-palette-green': isGreenBackground,
        })}
      >
        <div
          id={SCANNER_READER_ID}
          style={{
            width: '100%',
            height: '100%',
          }}
          // Can't use onClick here. The html5-qrcode library triggers clicks and may break the logic.
          // https://github.com/mebjas/html5-qrcode/blob/master/src/html5-qrcode-scanner.ts
          onMouseUp={isFullScreenMode ? toggleFullscreenFocused : NOOP}
        />
      </div>
    )
  }

  private handlePostEventCallback = (eventContext: EventContext) => {
    const [eventType] = eventContext.event

    this.store.onScannerUpdated(eventContext)

    if (eventType === e.SCAN_HISTORIES_RECEIVED) {
      this.recomputeGridSize()
    }
  }

  private recomputeGridSize = (): void => {
    this.listRef?.recomputeGridSize()
    this.cellMeasurerCache?.clearAll()
  }
}

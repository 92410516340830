import { DocumentNode } from 'graphql'
import { ObservableMap, action, computed, observable } from 'mobx'

import Assignment, {
  IAssignment,
} from '~/client/src/shared/models/Assignment/Assignment'

import EventsStore from './EventStore/Events.store'
import {
  DELETE_ASSIGNMENTS,
  SAVE_ASSIGNMENTS,
} from './EventStore/eventConstants'
import TagsStore from './domain/Tags.store'

export interface IAssignmentsStore {
  getAssignmentByEntityId: (entityId: string) => Assignment
  getAssignedEntitiesByUserId: (userId: string) => string[]
  save: (assignments: IAssignment[], cb?: (message: string) => void) => void
  delete: (ids: string[], cb?: (message: string) => void) => void
}

export default abstract class BaseAssignmentsStore
  implements IAssignmentsStore
{
  @observable public isDataReceived: boolean = false

  protected abstract byId: ObservableMap<string, Assignment>

  protected constructor(
    protected readonly eventsStore: EventsStore,
    protected readonly tagsStore: TagsStore,
    protected readonly saveDocument: DocumentNode,
    protected readonly deleteDocument: DocumentNode,
  ) {}

  public get list(): Assignment[] {
    return Array.from(this.byId.values())
  }

  public receiveList(list: IAssignment[]) {
    this.clearList()
    this.updateFromList(list)
    this.isDataReceived = true
  }

  public updateFromList(list: IAssignment[]) {
    list.forEach(dto => {
      this.receiveOne(dto.id, dto)
    })
  }

  public receiveOne(id: string, dto: IAssignment) {
    if (dto) {
      const assignment = Assignment.fromDto(dto)
      this.byId.set(assignment.id, assignment)
    } else {
      this.byId.delete(id)
    }
  }

  public clearList() {
    this.isDataReceived = false
    this.byId.clear()
  }

  @computed
  public get assignedEntitiesByUserIdMap(): {
    [id: string]: string[]
  } {
    const map = {}

    this.list.forEach(({ entityId, recipients = [] }) => {
      recipients.forEach(({ ids = [] }) => {
        ids.forEach(id => {
          if (!map[id]) {
            map[id] = []
          }

          const entitiesIds = map[id]
          if (!entitiesIds.includes(entityId)) {
            entitiesIds.push(entityId)
          }
        })
      })
    })

    return map
  }

  public getAssignmentByEntityId = (entityId: string): Assignment => {
    return this.list.find(assignment => assignment.entityId === entityId)
  }

  public getAssignedEntitiesByUserId = (userId: string): string[] => {
    return this.assignedEntitiesByUserIdMap[userId] || []
  }

  @action.bound
  public save(draftAssignments: IAssignment[], cb?: (message: string) => void) {
    this.eventsStore.dispatch(
      SAVE_ASSIGNMENTS,
      this.saveDocument,
      draftAssignments,
      cb,
    )
  }

  @action.bound
  public delete(ids: string[], cb?: (message: string) => void) {
    this.eventsStore.dispatch(DELETE_ASSIGNMENTS, this.deleteDocument, ids, cb)
  }
}

import { action, observable } from 'mobx'

import { InviteStatus } from '~/client/graph'
import { SignUpDocument } from '~/client/graph/operations/generated/UserAccess.generated'

import InfoCode from '../../enums/InfoCode'
import UserFieldId from '../../enums/UserFieldId'
import Localization from '../../localization/LocalizationManager'
import EventsStore from '../../stores/EventStore/Events.store'
import GraphExecutorStore from '../../stores/domain/GraphExecutor.store'
import BaseSignUpStore, {
  projectCodeKey,
} from '../../stores/ui/BaseSignUp.store'
import CommonStore from '../../stores/ui/Common.store'

export default class SignUpStore extends BaseSignUpStore {
  @observable private _isLoading: boolean = false

  public constructor(
    eventsStore: EventsStore,
    graphExecutorStore: GraphExecutorStore,
    optionFieldsIds: UserFieldId[] = [],
    private readonly common: CommonStore,
  ) {
    super(eventsStore, graphExecutorStore, optionFieldsIds)
  }

  public get isLoading(): boolean {
    return this._isLoading
  }

  @action.bound
  protected async submit() {
    this._isLoading = true

    const res = await this.graphExecutorStore.mutate(SignUpDocument, {
      [projectCodeKey]: this.appState.initProjectCode,
      ...this.formattedFieldMapForSubmission,
    })

    this._isLoading = false

    if (!res.data) {
      return this.handleSubmitError(
        res.error?.message ||
          Localization.translator.somethingWentWrongDuringAPIInteraction,
      )
    }

    const { signUp: inviteStatus } = res.data
    if (inviteStatus === InviteStatus.Pending) {
      this.common.displayInfoPage(InfoCode.ACCOUNT_CREATED_AND_INVITED, '')
    } else {
      const withEmail = !!this.formattedFieldMapForSubmission[UserFieldId.Email]
      this.common.displayInfoPage(InfoCode.ACCOUNT_CREATED, withEmail)
    }
  }

  @action.bound
  private handleSubmitError(errorMessage: string) {
    this.setError(errorMessage)
  }
}

import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

interface IFilterHandleButton {
  hasIndent?: boolean
  className?: string
  onClick?: () => void
  isSelected?: boolean
  isActive?: boolean
  isDisabled?: boolean
  isBold?: boolean
}

@observer
export default class FilterHandleButton extends React.Component<IFilterHandleButton> {
  public static defaultProps = {
    className: '',
    hasIndent: true,
    isActive: false,
  }

  public render() {
    const {
      children,
      className,
      hasIndent,
      onClick,
      isSelected,
      isActive,
      isDisabled,
      isBold,
    } = this.props

    return (
      <div
        className={classList({
          'row x-center y-center btn-filter gant-header-btn no-flex': true,
          'gant-header-btn-active': isSelected,
          'gant-header-btn-outline': !isActive,
          'not-allowed': isDisabled,
          'bold-font': isBold,
          ml15: hasIndent,
          [className]: true,
        })}
        onClick={onClick}
      >
        {children}
      </div>
    )
  }
}

import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import ReactDOM from 'react-dom'

import SitePermit from '../../models/Permit'
import PermitTypesStore from '../../stores/domain/PermitTypes.store'
import SitePermitsStore from '../../stores/domain/SitePermits.store'
import * as Icons from '../Icons'
import MenuCloser from '../MenuCloser'
import PermitTypeIcon from '../PermitTypeIcon/PermitTypeIcon'

import './WorkflowBacklinks.scss'

interface IProps {
  id: string
  sitePermitsStore?: SitePermitsStore
  permitTypesStore?: PermitTypesStore
  className?: string

  onWorkflowClick?: (workflow: SitePermit) => void
}

@inject('sitePermitsStore', 'permitTypesStore')
@observer
export default class WorkflowBacklinks extends React.Component<IProps> {
  @observable public isMenuOpen = false

  private portalContainer: HTMLDivElement = null
  private triggerRef: React.RefObject<HTMLDivElement> = React.createRef()

  public componentDidMount() {
    this.portalContainer = document.createElement('div')
    document.body.appendChild(this.portalContainer)
  }

  public componentWillUnmount() {
    if (this.portalContainer) {
      document.body.removeChild(this.portalContainer)
    }
  }

  public render() {
    if (!this.linkedWorkflows.length) {
      return null
    }

    const { className } = this.props

    return (
      <div
        className={classList({
          'workflow-backlinks relative inline-block ': true,
          [className]: !!className,
        })}
      >
        <div ref={this.triggerRef} className="trigger-icon pointer ml4">
          <Icons.Link onClick={this.onTriggerClick} />
        </div>
        {this.isMenuOpen && this.renderPermitLinks()}
      </div>
    )
  }

  private renderPermitLinks(): JSX.Element | null {
    if (!this.portalContainer || !this.triggerRef.current) {
      return null
    }

    const rect = this.triggerRef.current.getBoundingClientRect()

    const popup = (
      <MenuCloser closeMenu={this.toggleMenu} isOpen={this.isMenuOpen}>
        <div
          className="workflow-backlinks-popup absolute bg-white ba-light-input-border brada4 z-index-100"
          style={{
            top: rect.bottom + window.scrollY,
            left: rect.right + window.scrollX,
          }}
        >
          {this.linkedWorkflows.map(workflow => (
            <div
              key={workflow.id}
              className="row y-center no-flex-children-direct py8 px12 pointer"
              onClick={this.onWorkflowClick.bind(this, workflow)}
            >
              <PermitTypeIcon
                permitType={workflow.getTypeOfPermitType(
                  this.props.permitTypesStore,
                )}
                className="mr4"
              />
              <span className="text-ellipsis min-width0 flex-unset">
                {workflow.getCaption(this.props.permitTypesStore)}
              </span>
            </div>
          ))}
        </div>
      </MenuCloser>
    )

    return ReactDOM.createPortal(popup, this.portalContainer)
  }

  private onTriggerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    this.toggleMenu()
  }

  @action.bound
  private toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen
  }

  private get linkedWorkflows(): SitePermit[] {
    const { sitePermitsStore } = this.props
    return (sitePermitsStore.linkedPermits[this.props.id] || []).map(id =>
      sitePermitsStore.getFormById(id),
    )
  }

  private onWorkflowClick = (
    workflow: SitePermit,
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.stopPropagation()
    this.props.onWorkflowClick?.(workflow)
    this.toggleMenu()
  }
}

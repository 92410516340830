import * as React from 'react'

import { classList } from 'react-classlist-helper'

import './SidebarSections.scss'

export function SidebarTitleColumn(props: {
  text: string
  bold: boolean
  isDisabled: boolean
  isBadgeShown: boolean
}) {
  return (
    <div
      className={classList({
        'sidebar-column title text extra-large': true,
        opacity3: props.isDisabled,
        'no-grow': props.isBadgeShown,
      })}
    >
      {props.text}
    </div>
  )
}

export function SidebarIconColumn(props: {
  isDisabled: boolean
  children: React.ReactNode
}) {
  return (
    <div
      className={classList({
        'row no-flex-children no-grow x-start y-center sidebar-column icon':
          true,
        opacity3: props.isDisabled,
      })}
    >
      {props.children}
    </div>
  )
}

export function SidebarBadgeColumn(props: {
  isDisabled: boolean
  children: React.ReactNode
}) {
  return (
    <div
      className={classList({
        'row no-grow no-flex-children x-center y-center ml5': true,
        opacity3: props.isDisabled,
      })}
    >
      {props.children}
    </div>
  )
}

import * as Sentry from '@sentry/browser'
import * as fbAuth from 'firebase/auth'
import {
  OAuthCredential,
  OAuthProvider,
  linkWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth'

import Guard from '~/client/src/shared/utils/Guard'

import AuthErrorCodes from '../types/AuthErrorCodes'
import { isTokenAboutToExpire } from '../utils/JwtHelper'

export const FIREBASE_PASSWORD_PROVIDER_ID = 'password'
export const STX_AZURE_PROVIDER_ID = 'saml.stx-azure'
export const MICROSOFT_PROVIDER_ID = 'microsoft.com'

export default class FirebaseAuthService {
  private linkEmail: string = null
  private linkCredential: OAuthCredential = null
  private updatingToken: Promise<string> = null
  private accessToken: string = null
  private unsubscribeOnAuthStateChanged: fbAuth.Unsubscribe = null

  public constructor(public readonly auth: fbAuth.Auth) {
    Guard.require(auth, 'auth')
  }

  public get authUser(): fbAuth.User {
    return this.auth.currentUser
  }

  public get withPasswordProvider(): boolean {
    if (!this.authUser) {
      return false
    }

    return this.authUser.providerData?.some(
      ({ providerId }) => providerId === FIREBASE_PASSWORD_PROVIDER_ID,
    )
  }

  public get fromMicrosoftAzure(): boolean {
    if (!this.authUser) {
      return false
    }

    return this.authUser.providerData?.some(
      ({ providerId }) =>
        providerId === STX_AZURE_PROVIDER_ID ||
        providerId === MICROSOFT_PROVIDER_ID,
    )
  }

  public async loginWithInviteKey(
    inviteKey: string,
  ): Promise<fbAuth.UserCredential> {
    return fbAuth.signInWithCustomToken(this.auth, inviteKey)
  }

  public async getFreshAccessToken(): Promise<string> {
    return this.authUser.getIdToken(true)
  }

  public login(
    email: string,
    password: string,
  ): Promise<fbAuth.UserCredential> {
    return fbAuth.signInWithEmailAndPassword(this.auth, email, password)
  }

  public async loginWithPhoneNumber(
    phoneNumber: string,
    applicationVerifier: fbAuth.ApplicationVerifier,
  ): Promise<fbAuth.ConfirmationResult> {
    return fbAuth.signInWithPhoneNumber(
      this.auth,
      phoneNumber,
      applicationVerifier,
    )
  }

  public async loginWithProvider(
    providerId: string,
    providerType: string,
    onLogin: () => void,
    onLinkProviders: () => void,
  ) {
    const provider = new fbAuth[providerType](providerId)

    await this.signInWithProvider(provider, onLogin, onLinkProviders)
  }

  public async linkProviderAndSignIn(password: string) {
    try {
      const signInResult = await signInWithEmailAndPassword(
        this.auth,
        this.linkEmail,
        password,
      )
      await linkWithCredential(signInResult.user, this.linkCredential)
    } catch (linkError) {
      Sentry.withScope(scope => {
        scope.setTag('event', 'auth error')
        scope.setUser({
          email: this.linkEmail,
        })
        scope.setLevel(Sentry.Severity.Error)

        Sentry.captureException(new Error(linkError))
      })
      throw new Error(`Error linking accounts ${linkError}`)
    } finally {
      this.linkCredential = null
      this.linkEmail = null
    }
  }

  private async signInWithProvider(
    provider: OAuthProvider,
    onLogin: () => void,
    onLinkProviders: () => void,
  ) {
    try {
      await signInWithPopup(this.auth, provider)
      onLogin()
    } catch (error) {
      if (error.code !== AuthErrorCodes.ACCOUNT_WITH_DIFFERENT_CREDENTIALS) {
        throw new Error(`Error during sign-in ${error}`)
      }
      this.linkCredential = OAuthProvider.credentialFromResult(
        error.customData,
      ) as OAuthCredential
      this.linkEmail = error.customData.email
      onLinkProviders()
    }
  }

  public logout(): Promise<void> {
    return this.auth.signOut()
  }

  public onAuthStateChanged(cb: (user: fbAuth.User) => void): any {
    this.unsubscribeOnAuthStateChanged?.() // only one active subscriber available to avoid conflicts
    this.unsubscribeOnAuthStateChanged = fbAuth.onAuthStateChanged(
      this.auth,
      cb,
    )
  }

  public verifyPasswordResetCode(resetCode) {
    return fbAuth.verifyPasswordResetCode(this.auth, resetCode)
  }

  public updatePassword(newPassword: string) {
    return fbAuth.updatePassword(this.authUser, newPassword)
  }

  public linkEmailProvider(
    email: string,
    password: string,
  ): Promise<fbAuth.UserCredential> {
    const credential = fbAuth.EmailAuthProvider.credential(email, password)
    return fbAuth.linkWithCredential(this.authUser, credential)
  }

  public unlinkProvider(providerId: string): Promise<fbAuth.User> {
    return fbAuth.unlink(this.authUser, providerId)
  }

  public confirmPasswordReset(resetCode, newPassword) {
    return fbAuth.confirmPasswordReset(this.auth, resetCode, newPassword)
  }

  public resetPassword(email) {
    return fbAuth.sendPasswordResetEmail(this.auth, email)
  }

  public updateAccessToken(): Promise<string> {
    if (this.updatingToken) {
      return this.updatingToken
    }

    return (this.updatingToken = this.getFreshAccessToken().then(
      freshAccessToken => {
        this.updatingToken = null
        return (this.accessToken = freshAccessToken)
      },
    ))
  }

  public getValidAccessToken = async (): Promise<string> => {
    if (this.accessToken && isTokenAboutToExpire(this.accessToken)) {
      return this.updateAccessToken()
    }

    return this.accessToken
  }

  public setAccessToken(token: string) {
    this.accessToken = token
  }

  public resetAccessToken() {
    this.accessToken = null
  }
}

import SitePermit from '../../models/Permit'
import SitePermitsStore from '../../stores/domain/SitePermits.store'

export default class WorkflowDirectoryStore {
  public constructor(
    private readonly sitePermitsStore: SitePermitsStore,
    private readonly workflowPredicate?: (workflow: SitePermit) => boolean,
  ) {}

  public get workflows(): SitePermit[] {
    return this.sitePermitsStore.list
      .filter(this.workflowPredicate)
      .sort((a, b) => b.createdAt - a.createdAt)
  }
}

import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { action, computed, observable } from 'mobx'
import { MobXProviderContext, Provider, inject, observer } from 'mobx-react'

import BaseMapView from '~/client/src/shared/components/BaseMapView/BaseMapView'
import KonvaWorkflowDeliveriesPin from '~/client/src/shared/components/Konva/KonvaWorkflowDeliveriesPin'
import MapLayersMenu from '~/client/src/shared/components/MapLayersSelector/components/MapLayersMenu'
import BaseMapViewSetUpStore from '~/client/src/shared/components/SitemapHelpers/BaseMapViewSetUp.store'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import Delivery from '~/client/src/shared/models/Delivery'
import GlobeView from '~/client/src/shared/models/GlobeView'
import InitialState from '~/client/src/shared/stores/InitialState'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import DeliverySitemapPinStore from '~/client/src/shared/stores/ui/DeliverySitemapPin.store'

import MapBoxViewerStore from '../../../../MapBoxViewer/MapBoxViewer.store'
import SitemapElementsWrapper from '../../../../SitemapElementsWrapper'
import GlobeViewDeliveryMapPin from '../../../../SitemapHelpers/components/GlobePins/GlobeViewDeliveryMapPin'
import GlobeViewItems from '../../../../SitemapHelpers/components/GlobeViewItems'
import GlobeViewPlans from '../../../../SitemapHelpers/components/GlobeViewPlans'
import GlobeViewTilesets from '../../../../SitemapHelpers/components/GlobeViewTilesets'
import SitemapItems from '../../../../SitemapHelpers/components/SitemapItems'
import MapViewItemBase from '../../../../SitemapHelpers/models/MapViewItemBase'
import DeliveriesMapViewStore from '../DeliveriesMapView.store'
import DeliveriesMapSwipeableCards from './DeliveriesMapSwipeableCards'

interface IProps {
  sitemapId: string
  globeViewsStore?: GlobeViewsStore

  globe?: GlobeView
  textboxesCache: ICanvasImageCache

  mapStore: DeliveriesMapViewStore
  mapBoxViewerStore: MapBoxViewerStore

  state?: InitialState
  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  sitemapItemsStore?: SitemapItemsStore
  locationAttributesStore?: LocationAttributesStore
  companiesStore?: CompaniesStore

  isCompactMode?: boolean
  isLayersMenuShown?: boolean
  toggleLayersMenu?: () => void
}

@inject(
  'state',
  'sitemapsStore',
  'basemapsStore',
  'sitemapItemsStore',
  'locationAttributesStore',
  'companiesStore',
  'globeViewsStore',
)
@observer
export default class MobileDeliveryMapView extends React.Component<IProps> {
  public static contextType = MobXProviderContext
  @observable private deliverySitemap: BaseMapView
  private readonly store: BaseMapViewSetUpStore = null
  private readonly sitemapPinStore: DeliverySitemapPinStore = null
  private selectedDeliveriesMap: { [attrId: string]: boolean } = {}
  private selectedMapboxDeliveriesMap: { [attrId: string]: string } = {}

  public constructor(props: IProps) {
    super(props)

    this.store = new BaseMapViewSetUpStore(
      props.sitemapsStore,
      props.globeViewsStore,
      props.basemapsStore,
      props.sitemapItemsStore,
      props.locationAttributesStore,
    )
    this.sitemapPinStore = new DeliverySitemapPinStore(props.state)

    props.mapBoxViewerStore.setViewportFromAddress()
    props.mapBoxViewerStore.setDefaultMapMode()
    this.store.selectSitemapFromId(props.sitemapId)
  }

  public componentDidMount(): void {
    this.props.mapStore.setSelectedDeliveryId(
      this.props.mapStore.displayedSitemapDeliveries?.[0]?.id,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.props.sitemapId !== prevProps.sitemapId) {
      this.store.selectSitemapFromId(this.props.sitemapId)
    }
  }

  public render() {
    const {
      toggleLayersMenu,
      isLayersMenuShown,
      mapBoxViewerStore,
      state,
      globe,
      basemapsStore,
      sitemapId,
      sitemapsStore,
      isCompactMode,
    } = this.props
    const { sitemapUrl } = this.store

    const { setViewport } = mapBoxViewerStore
    const sitemap = sitemapsStore.byId.get(sitemapId)
    return (
      <div className="relative general-map-container full-width full-height">
        {isLayersMenuShown && (
          <div className="layers-container compact-view">
            <MapLayersMenu
              store={mapBoxViewerStore}
              onClose={toggleLayersMenu}
              className="compact-view"
            />
          </div>
        )}
        <BaseMapView
          globe={globe}
          selectedSitemap={sitemap}
          isDeliveryView={true}
          sitemapUrl={sitemapUrl}
          isDraggable={true}
          mapBoxViewerStore={mapBoxViewerStore}
          setViewport={setViewport}
          basemapsStore={basemapsStore}
          projectAddress={state.projectAddress}
          ref={this.setDeliverySitemap}
          isCompactMode={isCompactMode}
          isDraggingMode={false}
        >
          {({ width, height }) => {
            if (!this.deliverySitemap) {
              return null
            }
            return (
              <Provider {...this.context}>
                {globe
                  ? this.renderGlobeViewChildren()
                  : this.renderSitemapChildren(width, height)}
              </Provider>
            )
          }}
        </BaseMapView>
        <DeliveriesMapSwipeableCards store={this.props.mapStore} />
      </div>
    )
  }

  public get viewport() {
    return this.props.mapBoxViewerStore.viewport
  }

  private setDeliverySitemap = ref => {
    this.deliverySitemap = ref
  }

  private renderGlobeViewChildren = () => {
    const { mapBoxViewerStore } = this.props

    const { displayedGlobeViewItems } = mapBoxViewerStore
    return (
      <>
        <GlobeViewPlans
          mapBoxViewerStore={mapBoxViewerStore}
          sitemapWithBasemaps={mapBoxViewerStore.sitemapWithBasemapsOnGlobe}
        />
        <GlobeViewTilesets mapBoxViewerStore={mapBoxViewerStore} />
        <GlobeViewItems
          items={displayedGlobeViewItems || []}
          mapBoxViewerStore={mapBoxViewerStore}
          renderMarkerPin={this.renderMarkerPin}
        />
      </>
    )
  }

  private renderMarkerPin = (item: MapViewItemBase) => {
    const {
      state: { user },
      mapStore: { selectedDeliveryId },
    } = this.props
    const {
      shouldShowPin,
      shouldShowPinAsDone,
      shouldShowPinAsAssigned,
      shouldShowPinAsCanceled,
    } = this.sitemapPinStore
    const deliveries = this.attrIdToMapboxDeliveriesMap[item.id] || []

    if (!deliveries?.length || !shouldShowPin(item, deliveries)) {
      return null
    }

    const { id: sitemapItemId } = item

    const isPinSelected = deliveries?.some(
      d => this.selectedDeliveriesMap[d.id],
    )

    return (
      <GlobeViewDeliveryMapPin
        key={sitemapItemId}
        isDone={shouldShowPinAsDone(deliveries)}
        isAssigned={shouldShowPinAsAssigned(deliveries, user)}
        isSelected={
          isPinSelected || deliveries.some(d => d.id === selectedDeliveryId)
        }
        isCanceled={shouldShowPinAsCanceled(deliveries)}
        itemsCount={deliveries.length}
        onClick={this.onDeliveryMarkerClick.bind(null, deliveries)}
      />
    )
  }

  private renderSitemapChildren = (width: number, height: number) => {
    const {
      textboxesCache,
      mapStore: { selectedDeliveryId },
    } = this.props

    const { attrIdToDeliveriesMap } = this

    return (
      <>
        <SitemapItems
          items={this.store.displayedSitemapItems || []}
          containerWidth={width}
          containerHeight={height}
          textboxesCache={textboxesCache}
        />
        <SitemapElementsWrapper>
          {this.sortedSitemapItems.map(item =>
            this.renderWorkflowPin(
              item,
              width,
              height,
              attrIdToDeliveriesMap,
              selectedDeliveryId,
            ),
          )}
        </SitemapElementsWrapper>
      </>
    )
  }

  private renderWorkflowPin(
    item: MapViewItemBase,
    cWidth: number,
    cHeight: number,
    attrIdToDeliveriesMap: { [attrId: string]: Delivery[] },
    selectedDeliveryId: string,
  ): JSX.Element {
    const deliveries = attrIdToDeliveriesMap[item.id]
    const {
      companiesStore,
      state: { user },
    } = this.props

    const {
      shouldShowPin,
      getPinPosition,
      getPinLabel,
      shouldRenderCircle,
      shouldShowPinAsDone,
      shouldShowPinAsAssigned,
      shouldShowPinAsCanceled,
    } = this.sitemapPinStore

    if (!shouldShowPin(item, deliveries)) {
      return null
    }

    const { id: sitemapItemId } = item
    const { x, y } = getPinPosition(item, cWidth, cHeight)

    const isSelected = deliveries.some(d => d.id === selectedDeliveryId)

    return (
      <KonvaWorkflowDeliveriesPin
        key={sitemapItemId}
        isSelected={isSelected}
        isDone={shouldShowPinAsDone(deliveries)}
        isAssigned={shouldShowPinAsAssigned(deliveries, user)}
        isCanceled={shouldShowPinAsCanceled(deliveries)}
        text={getPinLabel(deliveries, companiesStore)}
        shouldRenderCircle={shouldRenderCircle(deliveries)}
        x={x}
        y={y}
        onClick={this.onDeliveryPillTouch.bind(this, deliveries)}
        onTouchEnd={this.onDeliveryPillTouch.bind(this, deliveries)}
      />
    )
  }

  @computed
  public get attrIdToDeliveriesMap(): { [attrId: string]: Delivery[] } {
    const { displayedSitemapDeliveries } = this.props.mapStore

    this.resetSelectedDeliveriesMap()

    return this.sortedSitemapItems
      .map(i => i.id)
      .reduce((acc, attrId) => {
        acc[attrId] = displayedSitemapDeliveries.filter(
          d => d.isRelatedAttr(attrId) && !this.selectedDeliveriesMap[d.id],
        )

        this.selectDeliveries(acc[attrId].map(d => d.id))
        return acc
      }, {})
  }

  @computed
  public get attrIdToMapboxDeliveriesMap(): { [attrId: string]: Delivery[] } {
    const { availableDeliveries } = this.props.mapStore
    this.resetMapboxSelectedDeliveriesMap()

    const displayedAttrsIds =
      this.props.mapBoxViewerStore.displayedGlobeViewItems.map(si => si.id)

    return displayedAttrsIds.reduce((acc, attrId) => {
      acc[attrId] = availableDeliveries.filter(
        d => d.isRelatedAttr(attrId) && !this.selectedMapboxDeliveriesMap[d.id],
      )

      this.selectMapboxDeliveries(
        acc[attrId].map(d => d.id),
        attrId,
      )
      return acc
    }, {})
  }

  @computed
  private get sortedSitemapItems(): MapViewItemBase[] {
    return this.sitemapPinStore
      .getItemsByPinLocationOption(this.store.displayedSitemapItems)
      .filter(item => !item.isDataLess)
  }

  private selectDeliveries = (deliveriesIds: string[]) => {
    deliveriesIds.forEach(id => {
      this.selectedDeliveriesMap[id] = true
    })
  }

  private selectMapboxDeliveries = (
    deliveriesIds: string[],
    attrId: string,
  ) => {
    deliveriesIds.forEach(id => {
      this.selectedMapboxDeliveriesMap[id] = attrId
    })
  }

  @action.bound
  private resetMapboxSelectedDeliveriesMap(): void {
    this.selectedMapboxDeliveriesMap = {}
  }

  @action.bound
  private resetSelectedDeliveriesMap(): void {
    this.selectedDeliveriesMap = {}
  }

  @action.bound
  private onDeliveryPillTouch(
    deliveries: Delivery[],
    event: KonvaEventObject<TouchEvent>,
  ): void {
    const {
      isGallerySwipingDisabled,
      displayedSitemapDeliveries,
      setDisplayedDeliveryIdx,
      setSelectedDeliveryId,
    } = this.props.mapStore

    if (!isGallerySwipingDisabled) {
      const deliveryId = deliveries?.[0]?.id
      const deliveryIndex = displayedSitemapDeliveries.findIndex(
        item => item.id === deliveryId,
      )

      setDisplayedDeliveryIdx(deliveryIndex)
      setSelectedDeliveryId(deliveryId)
    }

    event.cancelBubble = true
  }

  @action.bound
  private onDeliveryMarkerClick(deliveries: Delivery[], event): void {
    event.stopPropagation()
    const {
      isGallerySwipingDisabled,
      displayedSitemapDeliveries,
      setDisplayedDeliveryIdx,
      setSelectedDeliveryId,
    } = this.props.mapStore

    if (!isGallerySwipingDisabled) {
      const deliveryId = deliveries?.[0]?.id
      const deliveryIndex = displayedSitemapDeliveries.findIndex(
        item => item.id === deliveryId,
      )

      setDisplayedDeliveryIdx(deliveryIndex)
      setSelectedDeliveryId(deliveryId)
    }

    event.cancelBubble = true
  }
}

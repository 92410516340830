import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import { HeaderBar } from '~/client/src/shared/components/HeaderBar'
import * as Icons from '~/client/src/shared/components/Icons'
import QrCodesStore, {
  QRCodesMode,
} from '~/client/src/shared/components/QRCodes/QRCodes.store'
import QRCodesContent from '~/client/src/shared/components/QRCodes/components/QRCodesContent'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ScanHistoriesStore from '~/client/src/shared/stores/domain/ScanHistories.store'
import ScannersStore from '~/client/src/shared/stores/domain/Scanners.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import MobileView from '../../components/MobileView'
import MobileEventStore from '../../stores/EventStore/MobileEvents.store'
import MobileCommonStore from '../../stores/ui/MobileCommon.store'

import './QRCodes.scss'

interface IProps {
  eventsStore?: MobileEventStore
  common?: MobileCommonStore
  scannersStore?: ScannersStore
  projectMembersStore?: ProjectMembersStore
  userProjectsStore?: UserProjectsStore
  scanHistoriesStore?: ScanHistoriesStore
}

@inject(
  'common',
  'scannersStore',
  'eventsStore',
  'projectMembersStore',
  'userProjectsStore',
  'scanHistoriesStore',
)
@observer
class QRCodes extends React.Component<IProps & RouteComponentProps> {
  private qrCodesStore: QrCodesStore = null

  public constructor(props: IProps & RouteComponentProps) {
    super(props)

    this.qrCodesStore = new QrCodesStore(
      props.eventsStore,
      props.scannersStore,
      props.scanHistoriesStore,
      props.projectMembersStore,
      props.userProjectsStore,
    )
  }

  public componentDidMount(): void {
    this.props.common.hideNavBar()
  }

  public render(): JSX.Element {
    const { className } = this.qrCodesStore
    const containerClassName = `col layout-content qr-codes ${className}`

    return (
      <MobileView
        content={
          <QRCodesContent
            store={this.qrCodesStore}
            isFullscreenAllowed={true}
          />
        }
        header={this.header}
        className={containerClassName}
      />
    )
  }

  @computed
  private get header() {
    return this.renderHeaderBar()
  }

  private getHeaderParameters() {
    const {
      mode,
      scanner,
      newScanner,
      setScannerSelectionMode,
      setScannerViewMode,
    } = this.qrCodesStore
    const { common } = this.props

    switch (mode) {
      case QRCodesMode.SELECT:
        return {
          navClickHandler: scanner ? setScannerViewMode : common.history.goBack,
          headerText: Localization.translator.selectScanner,
        }
      case QRCodesMode.EDIT:
        const isNewScanner = !newScanner?.id
        return {
          navClickHandler: setScannerSelectionMode,
          headerText: isNewScanner
            ? Localization.translator.createNewScanner
            : Localization.translator.editScannerText,
        }
      case QRCodesMode.VIEW:
        return {
          navClickHandler: common.history.goBack,
          headerText: Localization.translator.scanner,
          rightSideElement: this.renderFullscreenButton(),
        }
    }
  }

  private renderHeaderBar(): JSX.Element {
    const headerParams = this.getHeaderParameters()

    return (
      <HeaderBar className="py8 px8">
        <Icon
          className="row no-grow navigation-icon px8 text grey-30"
          icon={IconNames.ARROW_LEFT}
          size={24}
          onClick={headerParams.navClickHandler}
        />
        <div className="text center extra-large medium-bold line-24 text-ellipsis half-flex-basis">
          {headerParams.headerText}
        </div>
        {headerParams.rightSideElement ?? <div className="mw40"></div>}
      </HeaderBar>
    )
  }

  private renderFullscreenButton(): JSX.Element {
    const { isFullScreenMode, toggleFullscreenMode } = this.qrCodesStore
    return (
      <div
        onClick={toggleFullscreenMode}
        className="no-grow px10 pointer text grey-30"
      >
        {isFullScreenMode ? <Icons.FullscreenExit /> : <Icons.Fullscreen />}
      </div>
    )
  }
}

export default withRouter(QRCodes)

import React from 'react'

import { inject, observer } from 'mobx-react'
import { Layer, Source } from 'react-map-gl'

import { IMapboxTileset } from '~/client/graph'

import InitialState from '../../../stores/InitialState'
import MapBoxViewerStore from '../../MapBoxViewer/MapBoxViewer.store'
import {
  TILESET_CIRCLE_LAYER_PREFIX,
  TILESET_FILL_LAYER_PREFIX,
  TILESET_FILL_OUTLINE_LAYER_PREFIX,
  TILESET_LAYER,
  TILESET_LINE_LAYER_PREFIX,
  TILESET_SYMBOL_LAYER_PREFIX,
} from '../../MapBoxViewer/mapboxConstants'

interface IProps {
  mapBoxViewerStore: MapBoxViewerStore
  isEditor?: boolean
  isRubberMode?: boolean
  state?: InitialState

  className?: string
}

@inject('state')
@observer
export default class GlobeViewTilesets extends React.Component<IProps> {
  public render(): JSX.Element {
    const { globeTilesets } = this.props.mapBoxViewerStore.tilesetsControlStore
    if (this.props.state.isTilesetsDisabled || !globeTilesets?.length) {
      return null
    }

    return (
      <>
        {globeTilesets.map((tileset, idx) =>
          this.renderGlobeTileset(tileset, idx),
        )}
      </>
    )
  }

  private renderGlobeTileset(tile: IMapboxTileset, idx: number) {
    const {
      tilesetsControlStore: { selectedTilesetFeature },
    } = this.props.mapBoxViewerStore
    const layerId = idx + 1
    const sourceLayerId = TILESET_LAYER + layerId
    return (
      <Source
        key={sourceLayerId}
        id={sourceLayerId}
        type={tile.type}
        url={`mapbox://${tile.id}`}
      >
        <Layer
          id={TILESET_FILL_LAYER_PREFIX + layerId}
          type="fill"
          filter={['==', ['geometry-type'], 'Polygon']}
          source={sourceLayerId}
          source-layer="features"
          paint={{
            'fill-color': [
              'match',
              ['id'],
              selectedTilesetFeature?.id || 0,
              '#0037ff',
              '#cca329',
            ],
            'fill-outline-color': '#fcba03',
            'fill-opacity': [
              'match',
              ['id'],
              selectedTilesetFeature?.id || 0,
              1,
              0.3,
            ],
          }}
        />
        <Layer
          id={TILESET_FILL_OUTLINE_LAYER_PREFIX + layerId}
          type="line"
          filter={['==', ['geometry-type'], 'Polygon']}
          source={sourceLayerId}
          source-layer="features"
          paint={{
            'line-color': '#f6ff00',
            'line-width': 0.5,
          }}
        />
        <Layer
          id={TILESET_LINE_LAYER_PREFIX + layerId}
          type="line"
          filter={['==', ['geometry-type'], 'LineString']}
          source={tile.id}
          source-layer="features"
          paint={{
            'line-color': '#ff7b00',
            'line-width': 1,
          }}
        />
        <Layer
          id={TILESET_CIRCLE_LAYER_PREFIX + layerId}
          type="circle"
          filter={['==', ['geometry-type'], 'Point']}
          source={sourceLayerId}
          source-layer="features"
          paint={{
            'circle-radius': [
              'match',
              ['id'],
              selectedTilesetFeature?.id || 0,
              4,
              2,
            ],
            'circle-color': [
              'match',
              ['id'],
              selectedTilesetFeature?.id || 0,
              '#0037ff',
              '#fcba03',
            ],
            'circle-stroke-width': 0.5,
            'circle-stroke-color': 'black',
          }}
        />
        <Layer
          id={TILESET_SYMBOL_LAYER_PREFIX + layerId}
          type="symbol"
          source={sourceLayerId}
          filter={['has', 'description']}
          source-layer="features"
          layout={{
            'text-field': ['get', 'description'],
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
            'text-size': 10,
            'text-anchor': 'bottom',
            'text-offset': [0, 1.5],
          }}
          paint={{
            'text-color': '#fcba03',
            'text-halo-color': '#000000',
            'text-halo-width': 1,
          }}
        />
      </Source>
    )
  }
}

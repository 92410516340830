export interface ICountry {
  name?: string
  dialCode: string
  countryCode?: string
  format: string
}

function isCountry(object: unknown): object is ICountry {
  return (
    !!object &&
    Object.prototype.hasOwnProperty.call(object, 'dialCode') &&
    Object.prototype.hasOwnProperty.call(object, 'format')
  )
}

export interface IPhoneNumber {
  country: ICountry
  phoneNumber: string
}

export function isPhoneNumber(object: unknown): object is IPhoneNumber {
  return (
    !!object &&
    Object.prototype.hasOwnProperty.call(object, 'country') &&
    Object.prototype.hasOwnProperty.call(object, 'phoneNumber') &&
    isCountry((object as any).country)
  )
}

export const PREFERRED_COUNTRY_CODES = ['us']
const MAX_COUNTRY_DIAL_CODE_LENGTH = '+9999'.length
const SPECIAL_SYMBOLS = ['-', ' ', '(', ')']
const REG_EXP = new RegExp(SPECIAL_SYMBOLS.join('|\\'), 'gi')

export function formatPhoneNumber(phoneNumber: string): string {
  return phoneNumber ? phoneNumber.replace(REG_EXP, '') : ''
}

export function getRawPhoneNumbers(phoneNumbers: IPhoneNumber[]): string[] {
  if (!phoneNumbers) {
    return []
  }

  return phoneNumbers.map(v => (isPhoneNumber(v) ? v.phoneNumber : v))
}

export function getFormattedPhoneNumbersForSubmission(
  rawPhoneNumber: string[],
): string[] {
  const formattedPhoneNumbers = rawPhoneNumber
    .map(phoneNumber => getFormattedPhoneNumberForSubmission(phoneNumber))
    .filter(v => !!v)
  const uniquePhoneNumbers = Array.from(new Set(formattedPhoneNumbers))
  return uniquePhoneNumbers
}

export function getFormattedPhoneNumberForSubmission(
  rawPhoneNumber: string,
): string {
  return isPhoneNumberEntered(rawPhoneNumber)
    ? formatPhoneNumber(rawPhoneNumber)
    : ''
}

export function isPhoneNumberValidForSubmission(
  phoneNumber: string,
  country: ICountry,
  isRequired?: boolean,
): boolean {
  if (isRequired) {
    return isPhoneNumberValid(phoneNumber, country)
  }

  return (
    !isPhoneNumberEntered(phoneNumber) ||
    isPhoneNumberValid(phoneNumber, country)
  )
}

export function isPhoneNumberValid(
  rawPhoneNumber: string,
  { format, dialCode }: ICountry,
): boolean {
  if (!rawPhoneNumber || !/\d/.test(rawPhoneNumber)) {
    return false
  }

  if (!format || !dialCode) {
    return true
  }

  const formattedPhoneNumber = formatPhoneNumber(rawPhoneNumber)
  return (
    formattedPhoneNumber.length === formatPhoneNumber(format).length &&
    formattedPhoneNumber.startsWith(`+${dialCode}`)
  )
}

function isPhoneNumberEntered(phoneNumber: string): boolean {
  if (!phoneNumber) {
    return false
  }

  let formattedPhoneNumber = phoneNumber
  if (!isPhoneFormatted(phoneNumber)) {
    formattedPhoneNumber = formatPhoneNumber(phoneNumber)
  }

  return formattedPhoneNumber.length > MAX_COUNTRY_DIAL_CODE_LENGTH
}

function isPhoneFormatted(phoneNumber: string): boolean {
  return SPECIAL_SYMBOLS.every(ss => !phoneNumber.includes(ss))
}

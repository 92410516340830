import { IWorkflowStep, SitePermitStatus } from '~/client/graph'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { getFormStatusDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'
import Tag, { ITag } from '~/client/src/shared/models/Tag'
import { enumToList } from '~/client/src/shared/utils/converters'

import Colors from '~/client/src/shared/theme.module.scss'

const colorByFormStatusMap: { [K in SitePermitStatus]: string } = {
  [SitePermitStatus.Requested]: Colors.primary60,
  [SitePermitStatus.Submitted]: Colors.primary60,
  [SitePermitStatus.Changed]: Colors.primary60,
  [SitePermitStatus.Accepted]: Colors.primary20,
  [SitePermitStatus.Reviewed]: Colors.primary20,
  [SitePermitStatus.Active]: Colors.secondary40,
  [SitePermitStatus.OnSite]: Colors.secondary40,
  [SitePermitStatus.ToInspect]: Colors.warning40,
  [SitePermitStatus.Failed]: Colors.error50,
  [SitePermitStatus.Denied]: Colors.error50,
  [SitePermitStatus.Done]: Colors.neutral0,
  [SitePermitStatus.Ended]: Colors.neutral0,
  [SitePermitStatus.Passed]: Colors.success40,
  [SitePermitStatus.Deleted]: Colors.error50,
}

const getStatusText = (status: string, isLate?: boolean) => {
  return isLate ? status + ', ' + Localization.translator.late : status
}

export const getFormsStatusAsTag = (
  status: SitePermitStatus,
  workflowStepLevel?: number,
  isLate?: boolean,
): ITag => {
  const color = isLate ? Colors.error50 : colorByFormStatusMap[status]
  const name = getStatusText(
    getFormStatusDisplayName(status, workflowStepLevel),
    isLate,
  )

  return new Tag(status, name, color, status, TagType.Status)
}

export const getFormsStatusesAsTags = (): ITag[] => {
  return enumToList(SitePermitStatus).map(status => getFormsStatusAsTag(status))
}

export const getWorkflowStepLevel = (
  stepId: string,
  workflowSteps: IWorkflowStep[],
): number => {
  const step = workflowSteps.find(s => s.id === stepId)
  const sameSteps = workflowSteps.filter(s => !!step && s.type === step?.type)

  if (sameSteps.length <= 1) return 0

  const stepIndex = sameSteps.findIndex(s => s.id === stepId)
  return stepIndex === -1 ? 0 : stepIndex + 1
}

import { action } from 'mobx'

import { IQuery } from '~/client/graph'
import { IAppConfig } from '~/client/src/shared/Config'
import CalendarPlaceholderStore from '~/client/src/shared/components/CalendarDayView/components/CalendarPlaceholder.store'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import InitialState from '~/client/src/shared/stores/InitialState'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityCodeRelationshipsStore from '~/client/src/shared/stores/domain/ActivityCodeRelationships.store'
import ActivityCodeTypesStore from '~/client/src/shared/stores/domain/ActivityCodeTypes.store'
import ActivityCodesStore from '~/client/src/shared/stores/domain/ActivityCodes.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import ActivityFollowingsStore from '~/client/src/shared/stores/domain/ActivityFollowings.store'
import ActivityPresetsStore from '~/client/src/shared/stores/domain/ActivityPresets.store'
import AuthenticationStore from '~/client/src/shared/stores/domain/Authentication.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import CustomActivityListFiltersStore from '~/client/src/shared/stores/domain/CustomActivityListFilters.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryStatusChangesStore from '~/client/src/shared/stores/domain/DeliveryStatusChanges.store'
import DeliveryUnitsStore from '~/client/src/shared/stores/domain/DeliveryUnits.store'
import DeliveryVehicleTypesStore from '~/client/src/shared/stores/domain/DeliveryVehicleTypes.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import GatesStore from '~/client/src/shared/stores/domain/Gates.store'
import HierarchyConfigurationStore from '~/client/src/shared/stores/domain/HierarchyConfiguration.store'
import LocationsStore from '~/client/src/shared/stores/domain/Locations.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import OffloadingEquipmentsStore from '~/client/src/shared/stores/domain/OffloadingEquipments.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'
import ProjectRolesStore from '~/client/src/shared/stores/domain/ProjectRoles.store'
import ProjectTeamsStore from '~/client/src/shared/stores/domain/ProjectTeams.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import ResourcesStore from '~/client/src/shared/stores/domain/Resources.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ThreadsStore from '~/client/src/shared/stores/domain/ThreadsStore/Threads.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'
import Guard from '~/client/src/shared/utils/Guard'

import ActivityDetailsStore from '../components/ActivityDetails/ActivityDetails.store'
import ApiAuthService from '../services/ApiAuthService'
import ChatService from '../services/ChatService/Chat.service'
import FirebaseAuthService from '../services/FirebaseAuthService'
import ProcoreService from '../services/ProcoreService'
import GraphExecutor from '../utils/GraphExecutor'
import { listToMap } from '../utils/converters'
import ActivityAssignmentsStore from './domain/ActivityAssignments.store'
import ActivityCodeLocationRelationshipsStore from './domain/ActivityCodeLocationRelationships.store'
import ActivityCompanyRelationshipsStore from './domain/ActivityCompanyRelationships.store'
import AnnouncementAssignmentsStore from './domain/AnnouncementAssignments.store'
import AnnouncementFollowingsStore from './domain/AnnouncementFollowings.store'
import AnnouncementsStore from './domain/Announcements.store'
import AreasStore from './domain/Areas.store'
import BasemapsStore from './domain/Basemaps.store'
import BuildingsStore from './domain/Buildings.store'
import CalendarStore from './domain/Calendars.store'
import CastAssignmentsStore from './domain/CastAssignments.store'
import CastFollowingsStore from './domain/CastFollowings.store'
import CategoriesOfVarianceStore from './domain/CategoriesOfVariance.store'
import ClosureAssignmentsStore from './domain/ClosureAssignments.store'
import ClosureFollowingsStore from './domain/ClosureFollowings.store'
import ClosuresStore from './domain/Closures.store'
import ConcreteDirectIntegrationStore from './domain/ConcreteDirectStores/ConcreteDirectIntegration.store'
import ConcreteDirectOrdersStore from './domain/ConcreteDirectStores/ConcreteDirectOrders.store'
import ConcreteDirectPayloadsStore from './domain/ConcreteDirectStores/ConcreteDirectPayloads.store'
import ConstraintsStore from './domain/Constraints.store'
import CustomDeliveryListFiltersStore from './domain/CustomDeliveryListFilters.store'
import CustomWorkflowsListFiltersStore from './domain/CustomWorkflowsListFilter.store'
import DeliveryAssignmentsStore from './domain/DeliveryAssignments.store'
import DeliveryFollowingsStore from './domain/DeliveryFollowings.store'
import { FileUploadingStore } from './domain/FileUploading.store'
import FormCategoriesStore from './domain/FormCategories.store'
import GlobeViewsStore from './domain/GlobeViews.store'
import GraphExecutorStore from './domain/GraphExecutor.store'
import InteriorDoorsStore from './domain/InteriorDoors.store'
import InteriorPathsStore from './domain/InteriorPaths.store'
import LevelsStore from './domain/Levels.store'
import LocationAttributesStore from './domain/LocationAttributes.store'
import LocationIntegrationsStore from './domain/LocationIntegrations.store'
import LogisticsObjectsStore from './domain/LogisticsObjects.store'
import MaterialCategoryStore from './domain/MaterialCategories.store'
import MaterialConfigurationStore from './domain/MaterialConfiguration.store'
import MaterialsStore from './domain/Materials.store'
import MaturixCastsStore from './domain/MaturixStores/MaturixCasts.store'
import MaturixProjectsStore from './domain/MaturixStores/MaturixProjects.store'
import OperationRulesStore from './domain/OperationRules.store'
import PermitInspectionChangesStore from './domain/PermitInspectionChanges.store'
import PermitInspectionsStore from './domain/PermitInspections.store'
import PermitStatusChangesStore from './domain/PermitStatusChanges.store'
import ProjectDefaultTeamsStore from './domain/ProjectDefaultTeams.store'
import ProjectMembersStore from './domain/ProjectMembers.store'
import ProjectTradesStore from './domain/ProjectTrades.store'
import RecurringDeliveriesSettingsStore from './domain/RecurringDeliveriesSettings.store'
import RoutesStore from './domain/Routes.store'
import SafetyHazardsStore from './domain/SafetyHazards.store'
import ScanHistoriesStore from './domain/ScanHistories.store'
import ScannersStore from './domain/Scanners.store'
import SitePermitAssignmentsStore from './domain/SitePermitAssignments.store'
import SitePermitFollowingsStore from './domain/SitePermitFollowings.store'
import SitePermitsStore from './domain/SitePermits.store'
import SitemapItemsStore from './domain/SitemapItems.store'
import SitemapsStore from './domain/Sitemaps.store'
import StagingsStore from './domain/Stagings.store'
import TenantsStore from './domain/Tenants.store'
import TilesetsStore from './domain/Tilesets.store'
import UserProjectsStore from './domain/UserProjects.store'
import VerticalObjectsStore from './domain/VerticalObjects.store'
import WbsStore from './domain/WbsStore/Wbs.store'
import WeatherForecastsStore from './domain/WeatherForecasts.store'
import WhiteListItemsStore from './domain/WhiteListItems.store'
import ZonesStore from './domain/Zones.store'
import ProjectDateStore from './ui/ProjectDate.store'

// TODO Split stores into domain and ui stores
// TODO Constructor inject all stores
class RootStore {
  public threadsStore: ThreadsStore
  public flagsStore: FlagsStore
  public photosStore: PhotosStore
  public scheduleCommentsStore: ScheduleCommentsStore
  public activityAssignmentsStore: ActivityAssignmentsStore
  public castAssignmentsStore: CastAssignmentsStore
  public deliveryAssignmentsStore: DeliveryAssignmentsStore
  public sitePermitAssignmentsStore: SitePermitAssignmentsStore
  public closureAssignmentsStore: ClosureAssignmentsStore
  public activityFollowingsStore: ActivityFollowingsStore
  public announcementFollowingsStore: AnnouncementFollowingsStore
  public sitePermitFollowingsStore: SitePermitFollowingsStore
  public closureFollowingsStore: ClosureFollowingsStore
  public castFollowingsStore: CastFollowingsStore
  public deliveryFollowingsStore: DeliveryFollowingsStore
  public deliveriesStore: DeliveriesStore
  public activitiesStore: ActivitiesStore
  public wbsStore: WbsStore
  public statusUpdatesStore: StatusUpdatesStore
  public common: CommonStore
  public auth: AuthenticationStore
  public slackBarStore: SlackBarStore
  public messagesStore: MessagesStore
  public rfisStore: RfisStore
  public categoriesOfVarianceStore: CategoriesOfVarianceStore
  public safetyHazardsStore: SafetyHazardsStore
  public projectsStore: ProjectsStore
  public whiteListItemsStore: WhiteListItemsStore
  public maturixProjectsStore: MaturixProjectsStore
  public maturixCastsStore: MaturixCastsStore
  public deliveryDetailsStore: DeliveryDetailsStore
  public constraintsStore: ConstraintsStore
  public levelsStore: LevelsStore
  public areasStore: AreasStore
  public logisticsObjectsStore: LogisticsObjectsStore
  public locationIntegrationsStore: LocationIntegrationsStore
  public stagingsStore: StagingsStore
  public interiorDoorsStore: InteriorDoorsStore
  public interiorPathsStore: InteriorPathsStore
  public verticalObjectsStore: VerticalObjectsStore
  public zonesStore: ZonesStore
  public offloadingEquipmentsStore: OffloadingEquipmentsStore
  public gatesStore: GatesStore
  public buildingsStore: BuildingsStore
  public routesStore: RoutesStore
  public locationAttributesStore: LocationAttributesStore
  public deliveryVehicleTypesStore: DeliveryVehicleTypesStore
  public deliveryUnitsStore: DeliveryUnitsStore
  public activityCodesStore: ActivityCodesStore
  public activityCodeRelationshipsStore: ActivityCodeRelationshipsStore
  public activityCodeTypesStore: ActivityCodeTypesStore
  public activityCodeLocationRelationshipsStore: ActivityCodeLocationRelationshipsStore
  public activityCompanyRelationshipsStore: ActivityCompanyRelationshipsStore
  public activityDetailsStore: ActivityDetailsStore
  public resourcesStore: ResourcesStore
  public activityFiltersStore: ActivityFiltersStore
  public locationStore: LocationsStore
  public customActivityListFiltersStore: CustomActivityListFiltersStore
  public customDeliveryListFiltersStore: CustomDeliveryListFiltersStore
  public customWorkflowsListFiltersStore: CustomWorkflowsListFiltersStore
  public calendarPlaceholderStore: CalendarPlaceholderStore
  public deliveryStatusChangesStore: DeliveryStatusChangesStore
  public permitStatusChangesStore: PermitStatusChangesStore
  public permitInspectionsStore: PermitInspectionsStore
  public permitInspectionChangesStore: PermitInspectionChangesStore
  public activityPresetsStore: ActivityPresetsStore
  public hierarchyConfigurationStore: HierarchyConfigurationStore
  public basemapsStore: BasemapsStore
  public sitemapsStore: SitemapsStore
  public tilesetsStore: TilesetsStore
  public sitemapItemsStore: SitemapItemsStore
  public projectDateStore: ProjectDateStore
  public materialCategoryStore: MaterialCategoryStore
  public materialsStore: MaterialsStore
  public calendarStore: CalendarStore
  public companiesStore: CompaniesStore
  public permitTypesStore: PermitTypesStore
  public projectRolesStore: ProjectRolesStore
  public projectTeamsStore: ProjectTeamsStore
  public projectTradesStore: ProjectTradesStore
  public projectDefaultTeamsStore: ProjectDefaultTeamsStore
  public announcementsStore: AnnouncementsStore
  public announcementAssignmentsStore: AnnouncementAssignmentsStore
  public sitePermitsStore: SitePermitsStore
  public tagsStore: TagsStore
  public closuresStore: ClosuresStore
  public operationRulesStore: OperationRulesStore
  public userProjectsStore: UserProjectsStore
  public projectMembersStore: ProjectMembersStore
  public graphExecutorStore: GraphExecutorStore
  public weatherForecastsStore: WeatherForecastsStore
  public fileUploadingStore: FileUploadingStore
  public recurringDeliveriesSettingsStore: RecurringDeliveriesSettingsStore
  public concreteDirectIntegrationStore: ConcreteDirectIntegrationStore
  public concreteDirectOrdersStore: ConcreteDirectOrdersStore
  public concreteDirectPayloadsStore: ConcreteDirectPayloadsStore
  public scannersStore: ScannersStore
  public scanHistoriesStore: ScanHistoriesStore
  public globeViewsStore: GlobeViewsStore
  public materialConfigurationStore: MaterialConfigurationStore
  public tenantsStore: TenantsStore
  public formCategoriesStore: FormCategoriesStore

  public readonly chatService: ChatService = null

  public constructor(
    public state: InitialState,
    public configuration: IAppConfig,
    public eventsStore: EventsStore,
    CommonStoreModel: new (state: InitialState, root: RootStore) => CommonStore,
    protected firebaseAuthService: FirebaseAuthService,
    protected apiAuthService: ApiAuthService,
    public procoreService: ProcoreService,
    graphExecutor: GraphExecutor,
    isTestEnv: boolean,
  ) {
    Guard.againstUndefined(state, 'initialState')
    Guard.againstUndefined(firebaseAuthService, 'firebaseAuthService')
    Guard.againstUndefined(apiAuthService, 'apiAuthService')
    Guard.requireAll({ configuration })

    this.chatService = new ChatService(firebaseAuthService.getValidAccessToken)

    this.graphExecutorStore = new GraphExecutorStore(
      graphExecutor,
      eventsStore,
      isTestEnv,
    )
    this.fileUploadingStore = new FileUploadingStore(this.graphExecutorStore)
    this.projectDateStore = new ProjectDateStore(state)

    this.operationRulesStore = new OperationRulesStore(this.eventsStore)

    this.userProjectsStore = new UserProjectsStore(this.eventsStore)

    this.projectMembersStore = new ProjectMembersStore(
      this.eventsStore,
      this.userProjectsStore,
      this.graphExecutorStore,
    )

    this.buildingsStore = new BuildingsStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.levelsStore = new LevelsStore(
      this.eventsStore,
      this.graphExecutorStore,
      this.buildingsStore,
    )
    this.areasStore = new AreasStore(this.eventsStore, this.graphExecutorStore)

    this.logisticsObjectsStore = new LogisticsObjectsStore(
      this.eventsStore,
      this.graphExecutorStore,
    )
    this.locationIntegrationsStore = new LocationIntegrationsStore(
      this.eventsStore,
      this.graphExecutorStore,
    )
    this.stagingsStore = new StagingsStore(
      this.eventsStore,
      this.graphExecutorStore,
    )
    this.interiorDoorsStore = new InteriorDoorsStore(
      this.eventsStore,
      this.graphExecutorStore,
    )
    this.interiorPathsStore = new InteriorPathsStore(
      this.eventsStore,
      this.graphExecutorStore,
    )
    this.verticalObjectsStore = new VerticalObjectsStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.zonesStore = new ZonesStore(this.eventsStore, this.graphExecutorStore)

    this.offloadingEquipmentsStore = new OffloadingEquipmentsStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.gatesStore = new GatesStore(this.eventsStore, this.graphExecutorStore)

    this.routesStore = new RoutesStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.locationAttributesStore = new LocationAttributesStore(
      this.gatesStore,
      this.zonesStore,
      this.buildingsStore,
      this.routesStore,
      this.offloadingEquipmentsStore,
      this.stagingsStore,
      this.interiorDoorsStore,
      this.interiorPathsStore,
      this.levelsStore,
      this.areasStore,
      this.logisticsObjectsStore,
      this.locationIntegrationsStore,
      this.verticalObjectsStore,
    )

    this.activityCodeRelationshipsStore = new ActivityCodeRelationshipsStore(
      this.eventsStore,
    )
    this.activityCodesStore = new ActivityCodesStore(
      this.eventsStore,
      this.activityCodeRelationshipsStore,
    )
    this.activityCodeTypesStore = new ActivityCodeTypesStore(
      this.eventsStore,
      this.activityCodesStore,
      this.activityCodeRelationshipsStore,
    )

    this.activityCodeLocationRelationshipsStore =
      new ActivityCodeLocationRelationshipsStore(this.eventsStore)

    this.activityCompanyRelationshipsStore =
      new ActivityCompanyRelationshipsStore(this.eventsStore)

    this.calendarPlaceholderStore = new CalendarPlaceholderStore(
      this.projectDateStore,
      this.companiesStore,
    )

    this.activitiesStore = new ActivitiesStore(this, this.graphExecutorStore)
    this.wbsStore = new WbsStore(this.activitiesStore)

    this.resourcesStore = new ResourcesStore(this.state, this.activitiesStore)

    this.companiesStore = new CompaniesStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.activityFiltersStore = new ActivityFiltersStore(
      this.eventsStore,
      this.activityCodesStore,
      this.activityCodeTypesStore,
      this.resourcesStore,
      this.activityCompanyRelationshipsStore,
      this.activityCodeLocationRelationshipsStore,
      this.activitiesStore,
      this.buildingsStore,
      this.levelsStore,
      this.zonesStore,
      this.companiesStore,
      this.userProjectsStore,
      this.projectMembersStore,
      this.graphExecutorStore,
    )

    this.locationStore = new LocationsStore(this.activityFiltersStore)

    this.statusUpdatesStore = new StatusUpdatesStore(
      this.activityFiltersStore,
      this.eventsStore,
      this.activitiesStore,
      this.projectDateStore,
    )

    this.whiteListItemsStore = new WhiteListItemsStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.projectsStore = new ProjectsStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.maturixCastsStore = new MaturixCastsStore(
      this.levelsStore,
      this.locationIntegrationsStore,
    )

    this.maturixProjectsStore = new MaturixProjectsStore(
      this.eventsStore,
      this.graphExecutorStore,
      this.maturixCastsStore,
    )

    this.hierarchyConfigurationStore = new HierarchyConfigurationStore(
      this.eventsStore,
      this.activityFiltersStore,
    )

    this.deliveryStatusChangesStore = new DeliveryStatusChangesStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.permitStatusChangesStore = new PermitStatusChangesStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.permitInspectionsStore = new PermitInspectionsStore(this.eventsStore)

    this.permitInspectionChangesStore = new PermitInspectionChangesStore(
      this.eventsStore,
    )

    this.common = new CommonStoreModel(this.state, this)

    this.activityPresetsStore = new ActivityPresetsStore(this.eventsStore)

    this.projectTeamsStore = new ProjectTeamsStore(this.eventsStore)

    this.projectTradesStore = new ProjectTradesStore(this.eventsStore)

    this.projectDefaultTeamsStore = new ProjectDefaultTeamsStore(
      this.eventsStore,
    )

    this.projectRolesStore = new ProjectRolesStore(this.eventsStore)

    this.formCategoriesStore = new FormCategoriesStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.sitePermitsStore = new SitePermitsStore(
      this.eventsStore,
      this.graphExecutorStore,
      this.projectDateStore,
      this.locationAttributesStore,
    )

    this.permitTypesStore = new PermitTypesStore(this.eventsStore)

    this.tenantsStore = new TenantsStore(
      apiAuthService.getValidAccessToken,
      this.state,
    )

    this.auth = new AuthenticationStore(
      this.common,
      this.firebaseAuthService,
      this.apiAuthService,
      this.eventsStore,
      this.state,
      this.apiAuthService,
      this.tenantsStore,
    )

    this.messagesStore = new MessagesStore(
      this.eventsStore,
      this.activitiesStore,
      this.graphExecutorStore,
    )

    this.threadsStore = new ThreadsStore(this.eventsStore)

    this.scheduleCommentsStore = new ScheduleCommentsStore(
      this.messagesStore,
      this.eventsStore,
    )

    this.flagsStore = new FlagsStore(this.messagesStore, this.eventsStore)

    this.rfisStore = new RfisStore(this.messagesStore, this.eventsStore)

    this.categoriesOfVarianceStore = new CategoriesOfVarianceStore(
      this.messagesStore,
      this.eventsStore,
    )

    this.safetyHazardsStore = new SafetyHazardsStore(
      this.messagesStore,
      this.eventsStore,
    )

    this.photosStore = new PhotosStore(
      this.eventsStore,
      this.fileUploadingStore,
      this.messagesStore,
      this.projectDateStore,
    )

    this.deliveryUnitsStore = new DeliveryUnitsStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.deliveriesStore = new DeliveriesStore(
      this.eventsStore,
      this.deliveryUnitsStore,
    )

    this.deliveryVehicleTypesStore = new DeliveryVehicleTypesStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.customWorkflowsListFiltersStore = new CustomWorkflowsListFiltersStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.customDeliveryListFiltersStore = new CustomDeliveryListFiltersStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.customActivityListFiltersStore = new CustomActivityListFiltersStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.activityFollowingsStore = new ActivityFollowingsStore(this.eventsStore)

    this.announcementFollowingsStore = new AnnouncementFollowingsStore(
      this.eventsStore,
    )

    this.sitePermitFollowingsStore = new SitePermitFollowingsStore(
      this.eventsStore,
    )

    this.closureFollowingsStore = new ClosureFollowingsStore(this.eventsStore)

    this.castFollowingsStore = new CastFollowingsStore(this.eventsStore)

    this.deliveryFollowingsStore = new DeliveryFollowingsStore(this.eventsStore)

    this.scannersStore = new ScannersStore(this.eventsStore)
    this.scanHistoriesStore = new ScanHistoriesStore(
      this.eventsStore,
      this.scannersStore,
    )

    this.announcementsStore = new AnnouncementsStore(
      this.eventsStore,
      this.projectDateStore,
    )

    this.closuresStore = new ClosuresStore(
      this.state,
      this.graphExecutorStore,
      this.locationAttributesStore,
      this.announcementsStore,
      this.sitePermitsStore,
      this.projectDateStore,
    )

    this.calendarStore = new CalendarStore(this.eventsStore)

    this.basemapsStore = new BasemapsStore(this.eventsStore)

    this.sitemapsStore = new SitemapsStore(this.eventsStore)

    this.tilesetsStore = new TilesetsStore(
      this.eventsStore,
      this.fileUploadingStore,
    )

    this.sitemapItemsStore = new SitemapItemsStore(this.eventsStore)

    this.materialCategoryStore = new MaterialCategoryStore(this.eventsStore)
    this.materialsStore = new MaterialsStore(
      this.eventsStore,
      this.graphExecutorStore,
      this.deliveriesStore,
      this.materialCategoryStore,
      this.locationAttributesStore,
      this.sitePermitsStore,
      this.permitTypesStore,
    )

    this.tagsStore = new TagsStore(
      this.locationAttributesStore,
      this.companiesStore,
      this.projectTeamsStore,
      this.projectDefaultTeamsStore,
      this.projectRolesStore,
      this.projectTradesStore,
      this.userProjectsStore,
      this.projectMembersStore,
      this.formCategoriesStore,
    )

    this.announcementAssignmentsStore = new AnnouncementAssignmentsStore(
      this.eventsStore,
      this.tagsStore,
      this.projectMembersStore,
      this.state,
    )

    this.activityAssignmentsStore = new ActivityAssignmentsStore(
      this.eventsStore,
      this.tagsStore,
    )

    this.castAssignmentsStore = new CastAssignmentsStore(
      this.eventsStore,
      this.tagsStore,
    )

    this.deliveryAssignmentsStore = new DeliveryAssignmentsStore(
      this.eventsStore,
      this.tagsStore,
    )

    this.sitePermitAssignmentsStore = new SitePermitAssignmentsStore(
      this.eventsStore,
      this.tagsStore,
    )

    this.closureAssignmentsStore = new ClosureAssignmentsStore(
      this.eventsStore,
      this.tagsStore,
    )

    this.constraintsStore = new ConstraintsStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.globeViewsStore = new GlobeViewsStore(this.eventsStore)

    this.recurringDeliveriesSettingsStore =
      new RecurringDeliveriesSettingsStore(this.eventsStore)

    this.concreteDirectIntegrationStore = new ConcreteDirectIntegrationStore(
      this.eventsStore,
      this.graphExecutorStore,
    )

    this.concreteDirectOrdersStore = new ConcreteDirectOrdersStore(
      this.eventsStore,
      this.graphExecutorStore,
      this.concreteDirectIntegrationStore,
    )

    this.concreteDirectPayloadsStore = new ConcreteDirectPayloadsStore(
      this.eventsStore,
      this.graphExecutorStore,
      this.concreteDirectIntegrationStore,
    )

    this.materialConfigurationStore = new MaterialConfigurationStore(
      this.eventsStore.appState,
      this.graphExecutorStore,
    )

    this.deliveryDetailsStore = new DeliveryDetailsStore(
      this.eventsStore,
      this.activitiesStore,
      this.sitemapsStore,
      this.deliveryVehicleTypesStore,
      this.deliveryUnitsStore,
      this.deliveryStatusChangesStore,
      this.calendarPlaceholderStore,
      this.projectDateStore,
      this.materialCategoryStore,
      this.materialsStore,
      this.companiesStore,
      this.deliveriesStore,
      this.threadsStore,
      this.messagesStore,
      this.photosStore,
      this.closuresStore,
      this.tagsStore,
      this.sitemapItemsStore,
      this.fileUploadingStore,
      this.userProjectsStore,
      this.projectMembersStore,
      this.graphExecutorStore,
      this.deliveryFollowingsStore,
      this.constraintsStore,
      this.locationAttributesStore,
      this.common,
      this.deliveryAssignmentsStore,
      this.recurringDeliveriesSettingsStore,
      this.concreteDirectOrdersStore,
      this.concreteDirectPayloadsStore,
      this.globeViewsStore,
    )

    this.slackBarStore = new SlackBarStore(
      this.eventsStore,
      this.threadsStore,
      this.messagesStore,
      this.activitiesStore,
      this.flagsStore,
      this.photosStore,
      this.rfisStore,
      this.categoriesOfVarianceStore,
      this.safetyHazardsStore,
      this.deliveriesStore,
      this.statusUpdatesStore,
      this.scheduleCommentsStore,
      this.deliveryStatusChangesStore,
      this.fileUploadingStore,
      this.userProjectsStore,
      this.tagsStore,
      this.activityFollowingsStore,
    )

    this.weatherForecastsStore = new WeatherForecastsStore(
      this.eventsStore,
      this.projectDateStore,
    )

    this.activityDetailsStore = new ActivityDetailsStore(
      this.state,
      this.activitiesStore,
      this.graphExecutorStore,
      this.locationAttributesStore,
      this.projectDateStore,
      this.companiesStore,
      this.projectMembersStore,
      this.tagsStore,
      this.constraintsStore,
    )
  }

  @action
  public clearOutScheduleData() {
    this.activitiesStore.clearList()
    this.activitiesStore.selection = ''
    this.activitiesStore.multiSelection = []
    this.activityCodesStore.clearList()
    this.activityCodeRelationshipsStore.clearList()
    this.activityCodeTypesStore.clearList()
    this.resourcesStore.clearList()
    this.calendarStore.clearList()

    this.activityFiltersStore.receiveSettings(null)
  }

  public setProjectDataFromSchedule(schedule: IQuery) {
    const {
      activities,
      activityCodes,
      activityCodeRelationships,
      activityCodeTypes,
      resources,
      activityResourceRelationships,
      calendars,
    } = schedule

    this.activitiesStore.clearStoreAndReceiveJson(
      listToMap(
        activities,
        e => e.code,
        e => e,
      ),
    )
    this.activityCodesStore.clearStoreAndReceiveJson(
      listToMap(
        activityCodes.data,
        e => e.id,
        e => e,
      ),
    )
    this.activityCodeRelationshipsStore.clearStoreAndReceiveJson(
      listToMap(
        activityCodeRelationships.data,
        e => e.id,
        e => e,
      ),
    )
    this.resourcesStore.clearStoreAndReceiveJson(
      resources.data,
      activityResourceRelationships.data,
    )
    this.activityCodeTypesStore.clearStoreAndReceiveJson(
      listToMap(
        activityCodeTypes.data,
        e => e.id,
        e => e,
      ),
    )
    this.activitiesStore.setActivityData()
    this.calendarStore.receiveList(calendars.data)
  }
}

export default RootStore

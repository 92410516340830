import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IFormCategoryFragmentFragment = Pick<
  Types.IFormCategory,
  'id' | 'name' | 'projectId' | 'isDeleted' | 'createdAt' | 'updatedAt'
>

export type IGetFormCategoriesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetFormCategoriesQuery = {
  formCategories?: Types.Maybe<{ data: Array<IFormCategoryFragmentFragment> }>
}

export type IListenFormCategoriesByProjectIdSubscriptionVariables =
  Types.Exact<{
    projectId: Types.Scalars['ObjectId']
  }>

export type IListenFormCategoriesByProjectIdSubscription = {
  formCategory?: Types.Maybe<
    Pick<Types.IFormCategoryChangeEvent, 'id'> & {
      item?: Types.Maybe<IFormCategoryFragmentFragment>
    }
  >
}

export type ISaveFormCategoriesMutationVariables = Types.Exact<{
  formCategories:
    | Array<Types.Maybe<Types.IFormCategoryInput>>
    | Types.Maybe<Types.IFormCategoryInput>
}>

export type ISaveFormCategoriesMutation = {
  saveManyFormCategories?: Types.Maybe<
    Array<Types.Maybe<IFormCategoryFragmentFragment>>
  >
}

export type IDeleteFormCategoriesMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteFormCategoriesMutation = Pick<
  Types.IMutation,
  'softDeleteManyFormCategories'
>

export const FormCategoryFragmentFragmentDoc = gql`
  fragment FormCategoryFragment on FormCategory {
    id
    name
    projectId
    isDeleted
    createdAt
    updatedAt
  }
`
export const GetFormCategoriesDocument = gql`
  query GetFormCategories($projectId: ObjectId!) {
    formCategories(limit: 1000000, projectId: $projectId) {
      data {
        ...FormCategoryFragment
      }
    }
  }
  ${FormCategoryFragmentFragmentDoc}
`

/**
 * __useGetFormCategoriesQuery__
 *
 * To run a query within a React component, call `useGetFormCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormCategoriesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetFormCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetFormCategoriesQuery,
    IGetFormCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetFormCategoriesQuery,
    IGetFormCategoriesQueryVariables
  >(GetFormCategoriesDocument, options)
}
export function useGetFormCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetFormCategoriesQuery,
    IGetFormCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetFormCategoriesQuery,
    IGetFormCategoriesQueryVariables
  >(GetFormCategoriesDocument, options)
}
export type GetFormCategoriesQueryHookResult = ReturnType<
  typeof useGetFormCategoriesQuery
>
export type GetFormCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetFormCategoriesLazyQuery
>
export type GetFormCategoriesQueryResult = Apollo.QueryResult<
  IGetFormCategoriesQuery,
  IGetFormCategoriesQueryVariables
>
export const ListenFormCategoriesByProjectIdDocument = gql`
  subscription ListenFormCategoriesByProjectId($projectId: ObjectId!) {
    formCategory(projectId: $projectId) {
      id
      item {
        ...FormCategoryFragment
      }
    }
  }
  ${FormCategoryFragmentFragmentDoc}
`

/**
 * __useListenFormCategoriesByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenFormCategoriesByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenFormCategoriesByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenFormCategoriesByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenFormCategoriesByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenFormCategoriesByProjectIdSubscription,
    IListenFormCategoriesByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenFormCategoriesByProjectIdSubscription,
    IListenFormCategoriesByProjectIdSubscriptionVariables
  >(ListenFormCategoriesByProjectIdDocument, options)
}
export type ListenFormCategoriesByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenFormCategoriesByProjectIdSubscription
>
export type ListenFormCategoriesByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenFormCategoriesByProjectIdSubscription>
export const SaveFormCategoriesDocument = gql`
  mutation SaveFormCategories($formCategories: [FormCategoryInput]!) {
    saveManyFormCategories(formCategories: $formCategories) {
      ...FormCategoryFragment
    }
  }
  ${FormCategoryFragmentFragmentDoc}
`
export type ISaveFormCategoriesMutationFn = Apollo.MutationFunction<
  ISaveFormCategoriesMutation,
  ISaveFormCategoriesMutationVariables
>

/**
 * __useSaveFormCategoriesMutation__
 *
 * To run a mutation, you first call `useSaveFormCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFormCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFormCategoriesMutation, { data, loading, error }] = useSaveFormCategoriesMutation({
 *   variables: {
 *      formCategories: // value for 'formCategories'
 *   },
 * });
 */
export function useSaveFormCategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveFormCategoriesMutation,
    ISaveFormCategoriesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveFormCategoriesMutation,
    ISaveFormCategoriesMutationVariables
  >(SaveFormCategoriesDocument, options)
}
export type SaveFormCategoriesMutationHookResult = ReturnType<
  typeof useSaveFormCategoriesMutation
>
export type SaveFormCategoriesMutationResult =
  Apollo.MutationResult<ISaveFormCategoriesMutation>
export type SaveFormCategoriesMutationOptions = Apollo.BaseMutationOptions<
  ISaveFormCategoriesMutation,
  ISaveFormCategoriesMutationVariables
>
export const DeleteFormCategoriesDocument = gql`
  mutation DeleteFormCategories($ids: [ObjectId]!) {
    softDeleteManyFormCategories(ids: $ids)
  }
`
export type IDeleteFormCategoriesMutationFn = Apollo.MutationFunction<
  IDeleteFormCategoriesMutation,
  IDeleteFormCategoriesMutationVariables
>

/**
 * __useDeleteFormCategoriesMutation__
 *
 * To run a mutation, you first call `useDeleteFormCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormCategoriesMutation, { data, loading, error }] = useDeleteFormCategoriesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteFormCategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteFormCategoriesMutation,
    IDeleteFormCategoriesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteFormCategoriesMutation,
    IDeleteFormCategoriesMutationVariables
  >(DeleteFormCategoriesDocument, options)
}
export type DeleteFormCategoriesMutationHookResult = ReturnType<
  typeof useDeleteFormCategoriesMutation
>
export type DeleteFormCategoriesMutationResult =
  Apollo.MutationResult<IDeleteFormCategoriesMutation>
export type DeleteFormCategoriesMutationOptions = Apollo.BaseMutationOptions<
  IDeleteFormCategoriesMutation,
  IDeleteFormCategoriesMutationVariables
>

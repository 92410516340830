import {
  ActivityStatus,
  BlockTypeEnum,
  CalendricalType,
  DeliveryFilterType,
  DeliveryListFilterType,
  MonitoringStatus,
  SitePermitStatus,
  WorkflowStepType,
} from '~/client/graph'

import ClosureStatus from '../enums/ClosureStatus'
import MemberFilterType from '../enums/MemberFilterType'
import { WorkflowStepAction } from '../enums/WorkflowStepAction'
import { WorkflowStepCategory } from '../enums/WorkflowStepCategory'
import Localization from './LocalizationManager'

export const getActivityStatusDisplayName = (status: ActivityStatus) => {
  switch (status) {
    case ActivityStatus.NotStarted:
      return Localization.translator.activityStatusValues.NotStarted
    case ActivityStatus.InProgress:
      return Localization.translator.activityStatusValues.InProgress
    case ActivityStatus.Completed:
      return Localization.translator.activityStatusValues.Completed
    case ActivityStatus.DueToday:
      return Localization.translator.activityStatusValues.DueToday
    case ActivityStatus.DueTomorrow:
      return Localization.translator.activityStatusValues.DueTomorrow
    case ActivityStatus.LateFinish:
      return Localization.translator.activityStatusValues.LateFinish
    case ActivityStatus.LateStart:
      return Localization.translator.activityStatusValues.LateStart
    case ActivityStatus.StartsToday:
      return Localization.translator.activityStatusValues.StartsToday
    case ActivityStatus.Done:
      return Localization.translator.activityStatusValues.Done
    case ActivityStatus.Incomplete:
      return Localization.translator.activityStatusValues.Incomplete
  }
}

export const getDeliveryFilterTypeDisplayName = (type: DeliveryFilterType) => {
  switch (type) {
    case DeliveryFilterType.Level:
      return Localization.translator.level
    case DeliveryFilterType.Zone:
      return Localization.translator.zone
    case DeliveryFilterType.Status:
      return Localization.translator.status
    case DeliveryFilterType.Company:
      return Localization.translator.company
    case DeliveryFilterType.Assignee:
      return Localization.translator.assignee
    case DeliveryFilterType.Locations:
      return Localization.translator.location
    case DeliveryFilterType.Area:
      return Localization.translator.area
    case DeliveryFilterType.Gate:
      return Localization.translator.gate
    case DeliveryFilterType.Building:
      return Localization.translator.building
    case DeliveryFilterType.Route:
      return Localization.translator.route
    case DeliveryFilterType.Equipment:
      return Localization.translator.equipment
    case DeliveryFilterType.Assigners:
      return Localization.translator.assigners
    case DeliveryFilterType.Followers:
      return Localization.translator.followers
  }
}

export const getMemberFilterTypeDisplayName = (type: MemberFilterType) => {
  switch (type) {
    case MemberFilterType.AccountType:
      return Localization.translator.accountType
    case MemberFilterType.InviteStatus:
      return Localization.translator.inviteStatus
  }
}

export function getFormStatusDisplayName(
  status: SitePermitStatus,
  workflowStepLevel?: number,
): string {
  let translatedStatus: string
  switch (status) {
    case SitePermitStatus.Requested:
      translatedStatus = Localization.translator.requested
      break
    case SitePermitStatus.Submitted:
      translatedStatus = Localization.translator.submitted
      break
    case SitePermitStatus.Reviewed:
      translatedStatus = Localization.translator.reviewed
      break
    case SitePermitStatus.Changed:
      translatedStatus = Localization.translator.changed
      break
    case SitePermitStatus.Accepted:
      translatedStatus = Localization.translator.approved
      break
    case SitePermitStatus.Denied:
      translatedStatus = Localization.translator.denied
      break
    case SitePermitStatus.Active:
      translatedStatus = Localization.translator.active
      break
    case SitePermitStatus.OnSite:
      translatedStatus = Localization.translator.onSite
      break
    case SitePermitStatus.Done:
      translatedStatus = Localization.translator.done
      break
    case SitePermitStatus.ToInspect:
      translatedStatus = Localization.translator.readyToInspect
      break
    case SitePermitStatus.Failed:
      translatedStatus = Localization.translator.failed
      break
    case SitePermitStatus.Passed:
      translatedStatus = Localization.translator.passed
      break
    case SitePermitStatus.Deleted:
      return Localization.translator.deleted
    case SitePermitStatus.Ended:
      return Localization.translator.ended
  }

  return workflowStepLevel
    ? `${translatedStatus} #${workflowStepLevel}`
    : translatedStatus
}

export function getClosureStatusDisplayName(status: ClosureStatus): string {
  switch (status) {
    case ClosureStatus.OPENED:
      return Localization.translator.opened
    case ClosureStatus.CLOSED:
      return Localization.translator.closed
  }
}

export function getMonitoringStatusDisplayName(
  status: MonitoringStatus,
): string {
  switch (status) {
    case MonitoringStatus.Active:
      return Localization.translator.active
    case MonitoringStatus.Planned:
      return Localization.translator.planned
    case MonitoringStatus.Stopped:
      return Localization.translator.stopped
  }
}

export function getWorkflowStepDisplayName(
  stepType: WorkflowStepType,
  stepLevel?: number,
): string {
  let translatedStepName: string
  switch (stepType) {
    case WorkflowStepType.Request:
      translatedStepName = Localization.translator.request_noun
      break
    case WorkflowStepType.Submission:
      translatedStepName = Localization.translator.submission
      break
    case WorkflowStepType.Review:
      translatedStepName = Localization.translator.review_noun
      break
    case WorkflowStepType.Approval:
      translatedStepName = Localization.translator.approval
      break
    case WorkflowStepType.Start:
      translatedStepName = Localization.translator.start_noun
      break
    case WorkflowStepType.OnSite:
      translatedStepName = Localization.translator.onSite
      break
    case WorkflowStepType.Finish:
      translatedStepName = Localization.translator.finish_noun
      break
    case WorkflowStepType.Inspection:
      translatedStepName = Localization.translator.inspection
      break
    case WorkflowStepType.ReadyToInspect:
      translatedStepName = Localization.translator.readyToInspect
      break
    case WorkflowStepType.RecurringInspection:
      translatedStepName = Localization.translator.recurringInspection
      break
  }

  return stepLevel ? `${translatedStepName} #${stepLevel}` : translatedStepName
}

export function getWorkflowActionDisplayName(
  action: WorkflowStepAction,
): string {
  switch (action) {
    case WorkflowStepAction.REQUEST:
      return Localization.translator.request_noun
    case WorkflowStepAction.SUBMIT:
      return Localization.translator.submit_verb
    case WorkflowStepAction.CHANGE:
      return Localization.translator.change_verb
    case WorkflowStepAction.DENY:
      return Localization.translator.deny_verb
    case WorkflowStepAction.APPROVE:
      return Localization.translator.approve_verb
    case WorkflowStepAction.START:
      return Localization.translator.start_verb
    case WorkflowStepAction.ON_SITE:
      return Localization.translator.onSite
    case WorkflowStepAction.REVIEW:
      return Localization.translator.review_verb
    case WorkflowStepAction.READY_TO_INSPECT:
      return Localization.translator.readyToInspect
    case WorkflowStepAction.FAIL:
      return Localization.translator.fail
    case WorkflowStepAction.PASS:
      return Localization.translator.pass_verb
    case WorkflowStepAction.ADD_INSPECTION:
      return Localization.translator.addInspection
    case WorkflowStepAction.FINISH:
      return Localization.translator.finish_verb
  }
}

export function getCalendricalTypeDisplayName(
  type: CalendricalType,
  x: number,
): string {
  switch (type) {
    case CalendricalType.Day:
      return Localization.translator.xDays(x)
    case CalendricalType.Week:
      return Localization.translator.xWeeks(x)
    case CalendricalType.Month:
      return Localization.translator.xMonths(x)
  }
}

export function getWorkflowCategoryDisplayName(
  category: WorkflowStepCategory,
): string {
  switch (category) {
    case WorkflowStepCategory.INITIATION:
      return Localization.translator.initiation
    case WorkflowStepCategory.APPROVAL:
      return Localization.translator.approval
    case WorkflowStepCategory.START:
      return Localization.translator.start_noun
    case WorkflowStepCategory.INSPECTION:
      return Localization.translator.inspection
    case WorkflowStepCategory.FINISH:
      return Localization.translator.finish_noun
  }
}

export function getDeliveryGroupingDisplayName(
  groupingType: DeliveryListFilterType,
): string {
  switch (groupingType) {
    case DeliveryListFilterType.None:
      return Localization.translator.none
    case DeliveryListFilterType.Company:
      return Localization.translator.company
    case DeliveryListFilterType.OnSiteContact:
      return Localization.translator.onsiteContact
    case DeliveryListFilterType.Lbs:
      return Localization.translator.location
    case DeliveryListFilterType.Building:
      return Localization.translator.building
    case DeliveryListFilterType.Zone:
      return Localization.translator.zone
    case DeliveryListFilterType.Route:
      return Localization.translator.route
    case DeliveryListFilterType.Gate:
      return Localization.translator.gate
    case DeliveryListFilterType.Status:
      return Localization.translator.status
    case DeliveryListFilterType.Vendor:
      return Localization.translator.vendor
    case DeliveryListFilterType.MaterialsCategory:
      return Localization.translator.materialsCategory
    case DeliveryListFilterType.VehicleType:
      return Localization.translator.deliveryVehicleType
  }
}

export function getBlockTypeDisplayName(blockType: BlockTypeEnum): string {
  switch (blockType) {
    case BlockTypeEnum.Hide:
      return Localization.translator.hide
    case BlockTypeEnum.Warn:
      return Localization.translator.warnUsers
    case BlockTypeEnum.Block:
      return Localization.translator.blockUsers
  }
}

import { observable } from 'mobx'

import {
  IAttachment,
  IMessage,
  IMessageInput,
  IReadBy,
  IReadByInput,
  IThread,
} from '~/client/graph'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import BaseModel from '~/client/src/shared/models/BaseModel'
import Thread from '~/client/src/shared/models/Thread'
import User from '~/client/src/shared/models/User'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

export default class Message extends BaseModel<IMessage> {
  public activityId: string
  @observable public readBy: IReadBy[] = []
  public userId: string
  public threadId: string
  public text: string
  @observable public photoId: string
  public attachments: IAttachment[]
  public projectId: string

  public updateFromJson(json: IMessage) {
    this.text = json.text
    this.userId = json.userId
    this.threadId = json.threadId
    this.readBy = json.readBy
    this.createdAt = json.createdAt
    this.updatedAt = json.updatedAt
    this.photoId = json.photoId
    this.activityId = json.activityId
    this.attachments = json.attachments
    this.projectId = json.projectId
  }

  public markAsReadByUser(user: User) {
    if (!user || this.readBy.find(readBy => readBy.userId === user.id)) {
      return
    }
    const readByUser = {
      userId: user.id,
      isRead: true,
    } as IReadBy

    this.readBy.push(readByUser)
  }

  public setThread(thread: Thread | IThread): void {
    if (thread) {
      this.threadId = thread.id
    }
  }

  public getDto(): IMessageInput {
    return {
      id: this.id || null,
      text: this.text,
      userId: this.userId,
      threadId: this.threadId,
      readBy: this.readBy.map(({ userId, isRead }) => {
        return {
          userId,
          isRead,
        } as IReadByInput
      }),
      photoId: this.photoId,
      activityId: this.activityId,
      attachments: this.attachments,
      projectId: this.projectId,
    } as IMessageInput
  }

  public get notificationText(): string {
    const hasAttachmentOrPhoto = this.attachments?.length || this.photoId
    return (
      this.text ||
      (hasAttachmentOrPhoto && Localization.translator.attachmentPhotoAdded) ||
      EMPTY_STRING
    )
  }
}

import * as React from 'react'

import { classList } from 'react-classlist-helper'

import { NOOP } from '../../utils/noop'
import { Loader } from '../Loader'

import './BaseActionButton.scss'

interface IBaseActionButtonProps {
  title: string
  icon?: any
  id?: string
  rightIcon?: any
  className?: string
  isActive?: boolean
  isEnabled?: boolean
  onClick?: (...param: any) => void
  onMouseDown?: () => void
  shouldShowBorder?: boolean
  isAnimated?: boolean
  isGrow?: boolean
  withAutoIconSize: boolean
  formId?: string
  isLoading?: boolean
  children?: React.ReactNode
}

export default class BaseActionButton extends React.PureComponent<IBaseActionButtonProps> {
  public static defaultProps = {
    id: '',
    isGrow: false,
    className: '',
    onClick: NOOP,
    onMouseDown: NOOP,
    isAnimated: false,
    shouldShowBorder: true,
    withAutoIconSize: false,
    formId: '',
    isLoading: false,
  }

  public render() {
    const {
      id,
      icon,
      title,
      isGrow,
      formId,
      onClick,
      isActive,
      isLoading,
      isEnabled,
      className,
      rightIcon,
      isAnimated,
      onMouseDown,
      shouldShowBorder,
      withAutoIconSize,
      children
    } = this.props

    return (
      <button
        id={id}
        form={formId}
        className={classList({
          'row base-action-btn brada4 px10 pointer': true,
          'text large light capitalize ba-palette-brand-light no-outline': true,
          'no-grow': !isGrow,
          active: isActive,
          'inactive-element': !isEnabled || isLoading,
          'no-border-color': !shouldShowBorder,
          [className]: true,
          animated: isAnimated,
          'fixed-icon-size': !withAutoIconSize && !isLoading,
        })}
        onMouseDown={onMouseDown}
        onClick={onClick}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {icon && <span className="pr4 no-grow">{icon}</span>}
            <span className="base-action-btn-title ellipsis">{title}</span>
            {rightIcon && <span className="pl4 no-grow">{rightIcon}</span>}
            {!!children && children}
          </>
        )}
      </button>
    )
  }
}

import { computed } from 'mobx'

import { IClosure } from '~/client/graph'
import {
  DeleteClosuresDocument,
  SaveClosuresDocument,
} from '~/client/graph/operations/generated/Closures.generated'

import Closure from '../../models/Closure'
import LocationBase from '../../models/LocationObjects/LocationBase'
import { reduceByCategories } from '../../utils/util'
import InitialState from '../InitialState'
import BaseStoreWithById from '../baseStores/BaseWithById.store'
import ProjectDateStore from '../ui/ProjectDate.store'
import AnnouncementsStore from './Announcements.store'
import GraphExecutorStore from './GraphExecutor.store'
import LocationAttributesStore from './LocationAttributes.store'
import SitePermitsStore from './SitePermits.store'

export default class ClosuresStore extends BaseStoreWithById<
  Closure,
  IClosure
> {
  public constructor(
    private readonly state: InitialState,
    private readonly graphExecutorStore: GraphExecutorStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly announcementsStore: AnnouncementsStore,
    private readonly sitePermitsStore: SitePermitsStore,
    private readonly projectDateStore: ProjectDateStore,
  ) {
    super(Closure)
  }

  public get byId() {
    return this.state.closures
  }

  public receiveOne(id: string, dto: IClosure) {
    if (dto) {
      this.updateOne(id, dto)
    } else {
      this.byId.delete(id)
    }
  }

  public getLocationByClosure = (closure: Closure): LocationBase => {
    if (closure.locationId) {
      return this.locationAttributesStore.getById(closure.locationId)
    } else if (closure.associatedAnnouncementId) {
      const associatedAnnouncement = this.announcementsStore.byId.get(
        closure.associatedAnnouncementId,
      )

      return this.locationAttributesStore.getById(
        associatedAnnouncement?.location?.id,
      )
    } else if (closure.associatedPermitId) {
      const associatedPermit = this.sitePermitsStore.getFormById(
        closure.associatedPermitId,
      )
      return this.locationAttributesStore.getById(
        associatedPermit?.locations?.[0]?.id,
      )
    }
  }

  public saveClosures = async (closures: Closure[]): Promise<void> => {
    const res = await this.graphExecutorStore.mutate(SaveClosuresDocument, {
      closures,
    })
    res?.data?.saveManyClosures?.forEach?.(closure =>
      this.updateOne(closure.id, closure),
    )
  }

  public deleteClosures = (closureIds: string[]) => {
    this.graphExecutorStore.mutate(DeleteClosuresDocument, {
      closureIds,
    })
  }

  @computed
  public get closuresByLocationIdMap(): { [locationId: string]: Closure[] } {
    return reduceByCategories(this.list, closure => {
      const location = this.getLocationByClosure(closure) || { id: null }
      return [location.id, closure]
    })
  }

  public getLocationClosures = (id: string): Closure[] => {
    return this.closuresByLocationIdMap[id] || []
  }

  public getLocationActiveClosures = (id: string): Closure[] => {
    return (
      this.closuresByLocationIdMap[id]?.filter(
        closure =>
          !closure.isOpenByDate(this.projectDateStore, Date.now()) ||
          closure.isAfter(Date.now()),
      ) || []
    )
  }

  public getLocationInactiveClosures = (id: string): Closure[] => {
    return (
      this.closuresByLocationIdMap[id]?.filter(
        closure =>
          closure.isOpenByDate(this.projectDateStore, Date.now()) &&
          !closure.isAfter(Date.now()),
      ) || []
    )
  }

  public isLocationOpen = (id: string): boolean => {
    const locationClosures = this.closuresByLocationIdMap[id]

    return (
      !locationClosures?.length ||
      locationClosures?.every(closure =>
        closure.isOpenByDate(this.projectDateStore, Date.now()),
      )
    )
  }
}

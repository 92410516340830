import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IConfigurationByNameQueryVariables = Types.Exact<{
  name?: Types.Maybe<Types.Scalars['String']>
}>

export type IConfigurationByNameQuery = {
  configuration?: Types.Maybe<Pick<Types.IConfiguration, 'value'>>
}

export type IProjectConfigurationByNameQueryVariables = Types.Exact<{
  id: Types.Scalars['ObjectId']
}>

export type IProjectConfigurationByNameQuery = {
  project?: Types.Maybe<{
    projectConfig?: Types.Maybe<
      Pick<
        Types.IProjectPreferences,
        | 'allowProjectCreation'
        | 'allowPhotos'
        | 'allowAnalytics'
        | 'allowDeliveriesToggling'
        | 'allowLogisticsToggling'
        | 'allowTrackerToggling'
        | 'allowMaterialsToggling'
        | 'allowMaterials'
        | 'allowContainerUpdates'
        | 'allowTilesets'
      > & {
        tilesetsConfiguration?: Types.Maybe<
          Pick<Types.ITilesetsConfiguration, 'maxSize' | 'maxNumber'>
        >
      }
    >
  }>
}

export const ConfigurationByNameDocument = gql`
  query ConfigurationByName($name: String) {
    configuration(name: $name) {
      value
    }
  }
`

/**
 * __useConfigurationByNameQuery__
 *
 * To run a query within a React component, call `useConfigurationByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useConfigurationByNameQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IConfigurationByNameQuery,
    IConfigurationByNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IConfigurationByNameQuery,
    IConfigurationByNameQueryVariables
  >(ConfigurationByNameDocument, options)
}
export function useConfigurationByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IConfigurationByNameQuery,
    IConfigurationByNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IConfigurationByNameQuery,
    IConfigurationByNameQueryVariables
  >(ConfigurationByNameDocument, options)
}
export type ConfigurationByNameQueryHookResult = ReturnType<
  typeof useConfigurationByNameQuery
>
export type ConfigurationByNameLazyQueryHookResult = ReturnType<
  typeof useConfigurationByNameLazyQuery
>
export type ConfigurationByNameQueryResult = Apollo.QueryResult<
  IConfigurationByNameQuery,
  IConfigurationByNameQueryVariables
>
export const ProjectConfigurationByNameDocument = gql`
  query ProjectConfigurationByName($id: ObjectId!) {
    project(id: $id) {
      projectConfig {
        allowProjectCreation
        allowPhotos
        allowAnalytics
        allowDeliveriesToggling
        allowLogisticsToggling
        allowTrackerToggling
        allowMaterialsToggling
        allowMaterials
        allowContainerUpdates
        allowTilesets
        tilesetsConfiguration {
          maxSize
          maxNumber
        }
      }
    }
  }
`

/**
 * __useProjectConfigurationByNameQuery__
 *
 * To run a query within a React component, call `useProjectConfigurationByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectConfigurationByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectConfigurationByNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectConfigurationByNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    IProjectConfigurationByNameQuery,
    IProjectConfigurationByNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IProjectConfigurationByNameQuery,
    IProjectConfigurationByNameQueryVariables
  >(ProjectConfigurationByNameDocument, options)
}
export function useProjectConfigurationByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IProjectConfigurationByNameQuery,
    IProjectConfigurationByNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IProjectConfigurationByNameQuery,
    IProjectConfigurationByNameQueryVariables
  >(ProjectConfigurationByNameDocument, options)
}
export type ProjectConfigurationByNameQueryHookResult = ReturnType<
  typeof useProjectConfigurationByNameQuery
>
export type ProjectConfigurationByNameLazyQueryHookResult = ReturnType<
  typeof useProjectConfigurationByNameLazyQuery
>
export type ProjectConfigurationByNameQueryResult = Apollo.QueryResult<
  IProjectConfigurationByNameQuery,
  IProjectConfigurationByNameQueryVariables
>

import { action, computed, observable } from 'mobx'

import {
  DeleteFormCategoriesDocument,
  SaveFormCategoriesDocument,
} from '~/client/graph/operations/generated/FormCategories.generated'
import { IFormCategory } from '~/client/graph/types.generated'
import { TagIconType } from '~/client/src/shared/enums/TagIcon'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import FormCategory from '~/client/src/shared/models/FormCategory'
import Tag from '~/client/src/shared/models/Tag'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import InitialState from '~/client/src/shared/stores/InitialState'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { showErrorToast } from '~/client/src/shared/utils/toaster'

import Colors from '~/client/src/shared/theme.module.scss'

const createTag = (category: FormCategory): Tag =>
  new Tag(
    category.id,
    category.name,
    category.isDeleted ? Colors.neutral84 : Colors.neutral70,
    TagIconType.FormCategory,
    TagType.FormCategory,
  )

export default class FormCategoriesStore {
  @observable public isDataReceived = false
  @observable private sourceMap: Map<string, FormCategory> = new Map()

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly graphExecutorStore: GraphExecutorStore,
  ) {}

  @computed
  public get allCategories(): FormCategory[] {
    return Array.from(this.sourceMap.values())
  }

  @computed
  public get activeCategories(): FormCategory[] {
    return this.allCategories
      .filter(c => !c.isDeleted)
      .sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
      )
  }

  @computed
  public get activeCategoriesAsTags(): Tag[] {
    return this.activeCategories.map(createTag)
  }

  @action.bound
  public receiveList(list: IFormCategory[]) {
    this.clearList()

    list.forEach(dto => this.receiveOne(dto.id, dto))

    this.isDataReceived = true
  }

  @action.bound
  public receiveOne(id: string, dto: IFormCategory) {
    if (dto) {
      const statusChange = FormCategory.fromDto(dto)
      this.sourceMap.set(statusChange.id, statusChange)
    } else {
      this.sourceMap.delete(id)
    }
  }

  @action.bound
  public async saveCategories(formCategories: FormCategory[]) {
    this.state.loading.set(e.SAVE_FORM_CATEGORIES, true)

    const res = await this.graphExecutorStore.mutate(
      SaveFormCategoriesDocument,
      {
        formCategories,
      },
    )

    this.state.loading.set(e.DELETE_FORM_CATEGORIES, false)

    if (res?.error) {
      return showErrorToast(Localization.translator.failedToUpdate)
    }
  }

  @action.bound
  public async deleteCategories(ids: string[]) {
    this.state.loading.set(e.DELETE_FORM_CATEGORIES, true)

    const res = await this.graphExecutorStore.mutate(
      DeleteFormCategoriesDocument,
      {
        ids,
      },
    )

    this.state.loading.set(e.DELETE_FORM_CATEGORIES, false)

    const isDeleted = res?.data?.softDeleteManyFormCategories
    if (res?.error || !isDeleted) {
      return showErrorToast(Localization.translator.failedToDelete)
    }

    this.markAsDeleted(ids)
  }

  public getById = (
    categoryId: string,
    includeDeleted: boolean = false,
  ): FormCategory => {
    if (!this.sourceMap.has(categoryId)) {
      return
    }
    const instance = this.sourceMap.get(categoryId)
    if (!includeDeleted && instance.isDeleted) {
      return
    }
    return instance
  }

  public getByIdAsTag = (
    categoryId: string,
    includeDeleted: boolean = false,
  ): Tag => {
    const category = this.getById(categoryId, includeDeleted)
    return category ? createTag(category) : null
  }

  public getNameById = (
    categoryId: string,
    includeDeleted: boolean = false,
  ): string => {
    return this.getById(categoryId, includeDeleted)?.name || UNASSIGNED
  }

  public get isDeleting(): boolean {
    return this.state.loading.get(e.DELETE_FORM_CATEGORIES)
  }

  @action.bound
  public clearList() {
    this.sourceMap.clear()
    this.isDataReceived = false
  }

  @action.bound
  private markAsDeleted(ids: string[]) {
    if (!ids?.length) {
      return
    }

    ids.forEach(id => {
      if (!this.sourceMap.has(id)) return

      const category = this.sourceMap.get(id)
      category.isDeleted = true
    })
  }

  private get state(): InitialState {
    return this.eventsStore.appState
  }
}

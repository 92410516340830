import * as React from 'react'

import { Switch } from '@blueprintjs/core'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import Config from '../../Config'
import Localization from '../../localization/LocalizationManager'
import EventsStore from '../../stores/EventStore/Events.store'
import { INIT_AUTH_USER } from '../../stores/EventStore/eventConstants'
import InitialState from '../../stores/InitialState'
import TenantsStore, { ITenant } from '../../stores/domain/Tenants.store'
import { Content, Footer, Header, Padding, Thickness, View } from '../Layout'
import Text from '../Typography/Text'
import { TenantBlock } from './TenantBlock'

import './ChooseTenant.scss'

interface IChooseTenant {
  state?: InitialState
  eventsStore?: EventsStore
  tenantsStore?: TenantsStore
  isBack?: boolean

  /**
   * Callback to notify that a tenant has been selected.
   */
  onTenantSelected?: () => void
}
@inject('state', 'eventsStore', 'tenantsStore')
@observer
export default class ChooseTenant extends React.Component<IChooseTenant> {
  @observable private rememberChoice = false

  public componentDidMount() {
    const { state } = this.props

    if (state.shouldClearTenantlessChoices || this.props.isBack) {
      state.disableClearTenantlessChoices()
      localStorage.removeItem(state.tenantUserSession?.nameId)
    }

    this.selectLastSessionTenant()
  }

  private selectLastSessionTenant() {
    const { state, tenantsStore } = this.props

    const previousSessionInfo = localStorage.getItem(
      state.tenantUserSession?.nameId,
    )

    if (!previousSessionInfo) return

    const parsedInfo = JSON.parse(previousSessionInfo)
    if (!parsedInfo?.lastViewedTenant) return

    // If there is a last viewed tenant, select it
    const lastViewedTenant: ITenant = tenantsStore.getTenantInfoByHostname(
      atob(parsedInfo.lastViewedTenant),
    )
    if (lastViewedTenant) {
      this.selectTenant(lastViewedTenant)
    }
  }

  public render() {
    const { tenantsStore } = this.props
    const tenantBlocks = tenantsStore.userTenants.map(tenant =>
      this.renderRow(tenant),
    )

    return (
      <View className="choose-tenant">
        <Header>
          <Padding padding={Thickness.fromTRBL(20, 0, 10, 18)}>
            <Text bold className="header">
              {Localization.translator.chooseTenant}
            </Text>
          </Padding>
          <Padding padding={Thickness.fromTRBL(0, 0, 10, 18)}>
            <div className="bb-light-input-border" />
          </Padding>
        </Header>
        <Content scrollable={true}>
          <Padding padding={Thickness.symmetric(18, 10)}>
            {tenantBlocks}
          </Padding>
        </Content>
        <Footer>
          {Config.TENANTLESS_MODE && (
            <Padding padding={Thickness.symmetric(20, 20)}>
              <div className="bb-light-input-border">
                <Switch
                  checked={this.rememberChoice}
                  labelElement={
                    <span className="text bold header">
                      {Localization.translator.rememberChoice}
                    </span>
                  }
                  onChange={this.toggleRememberChoice}
                />
              </div>
            </Padding>
          )}
        </Footer>
      </View>
    )
  }

  private renderRow(item: ITenant): JSX.Element {
    const { tenantsStore } = this.props

    return (
      <Padding padding={Thickness.fromTRBL(0, 0, 10, 10)} key={item.host}>
        <TenantBlock
          tenant={item}
          isActiveTenant={tenantsStore.activeTenant?.host === item.host}
          onClick={this.handleClick}
        />
      </Padding>
    )
  }

  @action.bound
  private toggleRememberChoice() {
    this.rememberChoice = !this.rememberChoice
  }

  private handleClick = (tenant: ITenant) => {
    const { host } = tenant
    const { tenantUserSession } = this.props.state

    const stxAuthUserId = tenantUserSession?.nameId
    const isTenantHostAvailable =
      !!this.props.tenantsStore.getTenantInfoByHostname(host)

    if (this.rememberChoice && stxAuthUserId && isTenantHostAvailable) {
      const localStoredRaw = localStorage.getItem(stxAuthUserId)
      const localStored = localStoredRaw ? JSON.parse(localStoredRaw) : {}

      localStored.lastViewedTenant = btoa(host)

      localStorage.setItem(stxAuthUserId, JSON.stringify(localStored))
    }

    this.selectTenant(tenant)
  }

  @action.bound
  private selectTenant(tenant: ITenant) {
    const { state, eventsStore } = this.props
    const user = state.tenantUserSession

    // If user is present, set the default active tenant
    if (user) {
      const { host } = tenant
      const { tenantsStore, onTenantSelected } = this.props
      onTenantSelected?.()

      // Abort if the tenant is already active
      if (host === user.activeTenantHost) {
        return
      }
      user.activeTenantHost = host
      tenantsStore.setActiveTenant(user.activeTenantHost)
    }

    // Dispatch INIT_AUTH_USER regardless of user presence
    eventsStore.dispatch(INIT_AUTH_USER)
  }
}

import * as React from 'react'

import { inject, observer } from 'mobx-react'

import SharedProjectsView from '~/client/src/shared/views/Projects/Projects'

import MobileCommonStore from '../../stores/ui/MobileCommon.store'

interface IProjectsView {
  common?: MobileCommonStore
}

@inject('common')
@observer
export class ProjectsView extends React.Component<IProjectsView> {
  public componentDidMount() {
    this.props.common.showNavBar()
  }

  public render() {
    return <SharedProjectsView {...this.props} />
  }
}

import * as React from 'react'

import { observer } from 'mobx-react'

import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import Delivery from '~/client/src/shared/models/Delivery'

import DeliveryWorkflowCard from '../ExpandableWorkflowCard/components/DeliveryWorkflowCard'
import GeneralMapPopup from './GeneralMapPopup'

import './MapPopup.scss'

const noActiveDeliveries = 'No active deliveries'
interface IProps {
  item: MapViewItemBase
  onClose: () => void
  activeDeliveryId: string
  relatedDeliveries: Delivery[]
  onFilterResetClick: () => void
  onDeliveryClick: (deliveryId: string) => void
}

@observer
export default class DeliveryMapPopup extends React.Component<IProps> {
  public static defaultProps = {
    relatedDeliveries: [],
  }

  public render() {
    const { item, onClose, relatedDeliveries } = this.props

    return (
      <GeneralMapPopup item={item} onClose={onClose} className="brada4">
        <div className="relative overflow-auto draggable-elements-list">
          {relatedDeliveries?.length ? (
            relatedDeliveries.map(delivery => {
              return (
                <DeliveryWorkflowCard
                  key={delivery.id}
                  className="full-height"
                  delivery={delivery}
                  onClick={this.onDeliveryClick}
                />
              )
            })
          ) : (
            <div className="text grey pa10">{noActiveDeliveries}</div>
          )}
        </div>
      </GeneralMapPopup>
    )
  }

  private onDeliveryClick = (delivery: Delivery): void => {
    this.props.onDeliveryClick(delivery.id)
  }
}

import * as React from 'react'

import { inject, observer } from 'mobx-react'

import ConstraintsStore from '~/client/src/shared/stores/domain/Constraints.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

import Localization from '../../localization/LocalizationManager'
import { ICalendarDates } from '../../models/CalendarEvent'
import IPermitFieldDto from '../../models/IPermitFieldDto'
import SitePermit from '../../models/Permit'
import EventsStore from '../../stores/EventStore/Events.store'
import CompaniesStore from '../../stores/domain/Companies.store'
import { FileUploadingStore } from '../../stores/domain/FileUploading.store'
import LocationAttributesStore from '../../stores/domain/LocationAttributes.store'
import MaterialCategoriesStore from '../../stores/domain/MaterialCategories.store'
import MaterialsStore from '../../stores/domain/Materials.store'
import PermitInspectionChangesStore from '../../stores/domain/PermitInspectionChanges.store'
import PermitInspectionsStore from '../../stores/domain/PermitInspections.store'
import PermitStatusChangesStore from '../../stores/domain/PermitStatusChanges.store'
import PermitTypesStore from '../../stores/domain/PermitTypes.store'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import UserProjectsStore from '../../stores/domain/UserProjects.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import BaseActionButton from '../BaseActionButton/BaseActionButton'
import CalendarPlaceholderStore from '../CalendarDayView/components/CalendarPlaceholder.store'
import PermitCompactHeaderBar from '../CompactHeaderBar/components/PermitCompactHeaderBar'
import FileInputBase from '../FileInput/FileInput'
import SitePermitCreationFormStore from './SitePermitCreationForm.store'
import SitePermitCreationFormContent from './SitePermitCreationFormContent'
import PermitFormLoader from './components/PermitFormLoader'

import './SitePermitCreationForm.scss'

// localization: translated

interface IProps {
  isTypeReadOnly?: boolean
  preselectedTypeId?: string
  prepopulatedFields?: IPermitFieldDto[]
  calendarEventDates?: ICalendarDates
  isMobileMode?: boolean
  workflowToDuplicate?: SitePermit
  FileInputType: typeof FileInputBase

  close(): void

  projectDateStore?: ProjectDateStore
  eventsStore?: EventsStore
  fileUploadingStore?: FileUploadingStore
  permitTypesStore?: PermitTypesStore
  permitStatusChangesStore?: PermitStatusChangesStore
  permitInspectionsStore?: PermitInspectionsStore
  permitInspectionChangesStore?: PermitInspectionChangesStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  companiesStore?: CompaniesStore
  calendarPlaceholderStore?: CalendarPlaceholderStore
  locationAttributesStore?: LocationAttributesStore
  materialsStore?: MaterialsStore
  materialCategoryStore?: MaterialCategoriesStore
  tagsStore?: TagsStore
  constraintsStore?: ConstraintsStore
}

@inject(
  'projectDateStore',
  'eventsStore',
  'fileUploadingStore',
  'permitTypesStore',
  'permitStatusChangesStore',
  'permitInspectionsStore',
  'permitInspectionChangesStore',
  'userProjectsStore',
  'projectMembersStore',
  'companiesStore',
  'locationAttributesStore',
  'materialsStore',
  'materialCategoryStore',
  'calendarPlaceholderStore',
  'tagsStore',
  'constraintsStore',
)
@observer
export default class SitePermitCreationForm extends React.Component<IProps> {
  private readonly store: SitePermitCreationFormStore

  public constructor(props: IProps) {
    super(props)

    this.store = new SitePermitCreationFormStore(
      props.eventsStore,
      props.projectDateStore,
      props.fileUploadingStore,
      props.permitTypesStore,
      props.userProjectsStore,
      props.projectMembersStore,
      props.companiesStore,
      props.locationAttributesStore,
      props.materialsStore,
      props.materialCategoryStore,
      props.tagsStore,
      props.constraintsStore,
      null,
      props.permitStatusChangesStore,
      props.permitInspectionsStore,
      props.permitInspectionChangesStore,
      props.calendarPlaceholderStore,
    )

    this.store.resetSelectedField()
    this.store.setDefaultSitePermit(
      null,
      props.preselectedTypeId,
      false,
      props.prepopulatedFields,
    )
    this.store.workflowFieldsStore.setDuplicateWorkflowData(
      props.workflowToDuplicate,
    )
  }

  public componentDidMount() {
    if (this.props.calendarEventDates) {
      this.store.applyDates(
        this.props.calendarEventDates.startDate,
        this.props.calendarEventDates.endDate,
      )
    }

    this.store.openDeadlineModalIfNeed()
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      preselectedTypeId,
      prepopulatedFields,
      calendarEventDates,
      workflowToDuplicate,
    } = this.props

    if (
      preselectedTypeId !== prevProps.preselectedTypeId ||
      prepopulatedFields !== prevProps.prepopulatedFields
    ) {
      this.store.resetSelectedField()
      this.store.setDefaultSitePermit(
        null,
        preselectedTypeId,
        false,
        prepopulatedFields,
      )
    }

    if (workflowToDuplicate !== prevProps.workflowToDuplicate) {
      this.store.workflowFieldsStore.setDuplicateWorkflowData(
        workflowToDuplicate,
      )
    }

    if (prevProps.calendarEventDates !== calendarEventDates) {
      this.store.applyDates(
        calendarEventDates.startDate,
        calendarEventDates.endDate,
      )
    }
  }

  public render() {
    const { editablePermit, template, canSaveWorkflow } = this.store
    const { close, FileInputType, permitTypesStore, isTypeReadOnly } =
      this.props

    return (
      <div className="site-permit-creation-form full-height relative">
        <div className="site-permit-creation-form-content full-height col">
          <PermitCompactHeaderBar
            permit={editablePermit}
            permitTypesStore={permitTypesStore}
            onCloseClick={close}
          />
          <div className="full-height col overflow-hidden relative">
            <PermitFormLoader permitCreationStore={this.store} />
            <SitePermitCreationFormContent
              store={this.store}
              isReadonly={false}
              FileInputType={FileInputType}
              shouldHideResponsible
              onPermitClose={close}
              isTypeReadOnly={isTypeReadOnly}
            />
            {template && (
              <div className="bt-light-grey bg-palette-brand-lightest row y-center pa20 no-grow">
                <BaseActionButton
                  title={Localization.translator.submit_verb}
                  className="primary-theme-inverted"
                  isEnabled={canSaveWorkflow}
                  onClick={this.createPermit}
                  isGrow
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  private createPermit = () => {
    const { canSaveWorkflow, createPermit } = this.store
    if (canSaveWorkflow) {
      createPermit()
      this.props.close()
    }
  }
}

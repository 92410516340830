import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IDeliveryFilterFieldsFragment = Pick<
  Types.IDeliveryListCustomFilter,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'version'
  | 'projectId'
  | 'name'
  | 'userId'
  | 'isPublic'
  | 'defaultForUsers'
> & {
  filtersByFilterType?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IDeliveryFilter, 'type' | 'values'>>>
  >
}

export type IGetDeliveryCustomFiltersByProjectQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetDeliveryCustomFiltersByProjectQuery = {
  deliveryListCustomFilters?: Types.Maybe<{
    data: Array<IDeliveryFilterFieldsFragment>
  }>
}

export type ISaveDeliveryCustomFilterMutationVariables = Types.Exact<{
  filter: Types.IDeliveryListCustomFilterInput
}>

export type ISaveDeliveryCustomFilterMutation = {
  saveDeliveryListCustomFilter?: Types.Maybe<IDeliveryFilterFieldsFragment>
}

export type IDeleteDeliveryCustomFilterMutationVariables = Types.Exact<{
  filterId: Types.Scalars['ObjectId']
}>

export type IDeleteDeliveryCustomFilterMutation = Pick<
  Types.IMutation,
  'deleteDeliveryListCustomFilter'
>

export const DeliveryFilterFieldsFragmentDoc = gql`
  fragment DeliveryFilterFields on DeliveryListCustomFilter {
    id
    createdAt
    updatedAt
    version
    projectId
    name
    userId
    isPublic
    filtersByFilterType {
      type
      values
    }
    defaultForUsers
  }
`
export const GetDeliveryCustomFiltersByProjectDocument = gql`
  query GetDeliveryCustomFiltersByProject($projectId: ObjectId!) {
    deliveryListCustomFilters(limit: 1000000, projectId: $projectId) {
      data {
        ...DeliveryFilterFields
      }
    }
  }
  ${DeliveryFilterFieldsFragmentDoc}
`

/**
 * __useGetDeliveryCustomFiltersByProjectQuery__
 *
 * To run a query within a React component, call `useGetDeliveryCustomFiltersByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryCustomFiltersByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryCustomFiltersByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetDeliveryCustomFiltersByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetDeliveryCustomFiltersByProjectQuery,
    IGetDeliveryCustomFiltersByProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetDeliveryCustomFiltersByProjectQuery,
    IGetDeliveryCustomFiltersByProjectQueryVariables
  >(GetDeliveryCustomFiltersByProjectDocument, options)
}
export function useGetDeliveryCustomFiltersByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetDeliveryCustomFiltersByProjectQuery,
    IGetDeliveryCustomFiltersByProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetDeliveryCustomFiltersByProjectQuery,
    IGetDeliveryCustomFiltersByProjectQueryVariables
  >(GetDeliveryCustomFiltersByProjectDocument, options)
}
export type GetDeliveryCustomFiltersByProjectQueryHookResult = ReturnType<
  typeof useGetDeliveryCustomFiltersByProjectQuery
>
export type GetDeliveryCustomFiltersByProjectLazyQueryHookResult = ReturnType<
  typeof useGetDeliveryCustomFiltersByProjectLazyQuery
>
export type GetDeliveryCustomFiltersByProjectQueryResult = Apollo.QueryResult<
  IGetDeliveryCustomFiltersByProjectQuery,
  IGetDeliveryCustomFiltersByProjectQueryVariables
>
export const SaveDeliveryCustomFilterDocument = gql`
  mutation SaveDeliveryCustomFilter($filter: DeliveryListCustomFilterInput!) {
    saveDeliveryListCustomFilter(deliveryListCustomFilter: $filter) {
      ...DeliveryFilterFields
    }
  }
  ${DeliveryFilterFieldsFragmentDoc}
`
export type ISaveDeliveryCustomFilterMutationFn = Apollo.MutationFunction<
  ISaveDeliveryCustomFilterMutation,
  ISaveDeliveryCustomFilterMutationVariables
>

/**
 * __useSaveDeliveryCustomFilterMutation__
 *
 * To run a mutation, you first call `useSaveDeliveryCustomFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDeliveryCustomFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDeliveryCustomFilterMutation, { data, loading, error }] = useSaveDeliveryCustomFilterMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSaveDeliveryCustomFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveDeliveryCustomFilterMutation,
    ISaveDeliveryCustomFilterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveDeliveryCustomFilterMutation,
    ISaveDeliveryCustomFilterMutationVariables
  >(SaveDeliveryCustomFilterDocument, options)
}
export type SaveDeliveryCustomFilterMutationHookResult = ReturnType<
  typeof useSaveDeliveryCustomFilterMutation
>
export type SaveDeliveryCustomFilterMutationResult =
  Apollo.MutationResult<ISaveDeliveryCustomFilterMutation>
export type SaveDeliveryCustomFilterMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveDeliveryCustomFilterMutation,
    ISaveDeliveryCustomFilterMutationVariables
  >
export const DeleteDeliveryCustomFilterDocument = gql`
  mutation DeleteDeliveryCustomFilter($filterId: ObjectId!) {
    deleteDeliveryListCustomFilter(id: $filterId)
  }
`
export type IDeleteDeliveryCustomFilterMutationFn = Apollo.MutationFunction<
  IDeleteDeliveryCustomFilterMutation,
  IDeleteDeliveryCustomFilterMutationVariables
>

/**
 * __useDeleteDeliveryCustomFilterMutation__
 *
 * To run a mutation, you first call `useDeleteDeliveryCustomFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeliveryCustomFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeliveryCustomFilterMutation, { data, loading, error }] = useDeleteDeliveryCustomFilterMutation({
 *   variables: {
 *      filterId: // value for 'filterId'
 *   },
 * });
 */
export function useDeleteDeliveryCustomFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteDeliveryCustomFilterMutation,
    IDeleteDeliveryCustomFilterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteDeliveryCustomFilterMutation,
    IDeleteDeliveryCustomFilterMutationVariables
  >(DeleteDeliveryCustomFilterDocument, options)
}
export type DeleteDeliveryCustomFilterMutationHookResult = ReturnType<
  typeof useDeleteDeliveryCustomFilterMutation
>
export type DeleteDeliveryCustomFilterMutationResult =
  Apollo.MutationResult<IDeleteDeliveryCustomFilterMutation>
export type DeleteDeliveryCustomFilterMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteDeliveryCustomFilterMutation,
    IDeleteDeliveryCustomFilterMutationVariables
  >

import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import MobileActivityListStore from '~/client/src/mobile/stores/ui/ActivityList.store'
import CustomActivityListFilter from '~/client/src/shared/types/CustomActivityListFilter'

import CountLabel from './CountLabel'

interface IProps {
  customFilter: CustomActivityListFilter
  activityListStore?: MobileActivityListStore
}

@inject('activityListStore')
@observer
export default class CustomFilterLabel extends React.Component<IProps> {
  public render() {
    return (
      <span>
        {this.props.customFilter.name}
        <CountLabel countLabel={this.countLabel} />
      </span>
    )
  }

  @computed
  private get countLabel() {
    const { getActivitiesByCustomFilter, todayActivitiesCount } =
      this.props.activityListStore

    const activityCountByFilter = getActivitiesByCustomFilter(
      this.props.customFilter,
    ).length
    return `${activityCountByFilter} of ${todayActivitiesCount}`
  }
}

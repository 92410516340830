import * as React from 'react'

interface IProps {
  onReset: () => void
}

export default class MobileActivityPresetsSelectorHeader extends React.Component<IProps> {
  public render() {
    const { onReset } = this.props

    return (
      <>
        <div className="text extra-large bold">My Presets</div>
        <div className="select-all-button white-btn" onClick={onReset}>
          Clear All
        </div>
      </>
    )
  }
}

import * as React from 'react'

interface IListProps {
  data: any[]
  renderEmpty(): any
  renderItem(item, index, data): any
}

export default class ListComponent extends React.Component<IListProps, any> {
  public static defaultProps = {
    data: [],
    renderEmpty: () => null,
    renderItem: () => null,
  }

  public renderList() {
    return this.props.data.map(this.props.renderItem, this)
  }

  public render() {
    if (this.props.data.length === 0) {
      return this.props.renderEmpty()
    }

    if (this.props.data.length > 0) {
      return this.renderList()
    }
  }
}

import { SitePermitStatus } from '~/client/graph'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import MapViewLocationIcon from '~/client/src/shared/enums/SitemapAttributeIcon'

enum AdditionTagIcon {
  Company = 'company',
  Status = 'status',
  Role = 'role',
  Team = 'team',
  User = 'user',
  At = 'at',
  Trade = 'trade',
  FormCategory = 'formCategory',
}

enum DefaultTagIcon {
  DefaultTeam = 'defaultTeam',
}

export type TagIconType =
  | MapViewLocationIcon
  | AdditionTagIcon
  | DeliveryStatus
  | SitePermitStatus
  | DefaultTagIcon
export const TagIconType = {
  ...MapViewLocationIcon,
  ...AdditionTagIcon,
  ...DeliveryStatus,
  ...SitePermitStatus,
  ...DefaultTagIcon,
}

import {
  INotification,
  INotificationInput,
  NotificationType,
} from '~/client/graph'
import BaseModel from '~/client/src/shared/models/BaseModel'
import Guard from '~/client/src/shared/utils/Guard'

export default class BaseNotification extends BaseModel<INotification> {
  public static fromInstance(instance: BaseNotification): BaseNotification {
    return new BaseNotification(
      instance.id,
      instance.recipientId,
      instance.type,
      instance.authorId,
      instance.projectId,
      instance.wasRead,
      instance.isArchived,
      instance.createdAt,
      instance.entityId,
      instance.activityObjectId,
      instance.scheduleId,
      instance.messageId,
    )
  }

  public static fromDto(dto: INotification): BaseNotification {
    return new BaseNotification(
      dto.id,
      dto.recipientId,
      dto.type,
      dto.authorId,
      dto.projectId,
      dto.wasRead,
      dto.isArchived,
      dto.createdAt,
      dto.entityId,
      dto.activityObjectId,
      dto.scheduleId,
      dto.messageId,
    )
  }

  public constructor(
    public id: string,
    public recipientId: string,
    public type: NotificationType,
    public authorId: string,
    public projectId: string,
    public wasRead: boolean,
    public isArchived: boolean,
    public createdAt: number,
    public entityId?: string,
    public activityObjectId?: string,
    public scheduleId?: string,
    public messageId?: string,
  ) {
    super(id)
    Guard.againstUndefined(id, 'id')
  }

  public toDto(): INotificationInput {
    return {
      id: this.id,
      activityObjectId: this.activityObjectId,
      authorId: this.authorId,
      entityId: this.entityId,
      isArchived: this.isArchived,
      projectId: this.projectId,
      recipientId: this.recipientId,
      scheduleId: this.scheduleId,
      type: this.type,
      wasRead: this.wasRead,
      messageId: this.messageId,
    }
  }
}

import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import { SitePermitStatus } from '~/client/graph'
import CommonThreadEntry from '~/client/src/shared/components/CommonThread/components/CommonThreadEntry'
import DaySeparator from '~/client/src/shared/components/DaySeparator'
import FileInputBase from '~/client/src/shared/components/FileInput/FileInput'
import * as Icons from '~/client/src/shared/components/Icons'
import PermitLogActionBarStore from '~/client/src/shared/components/PermitLogActionBar.store'
import WorkflowCardStatus from '~/client/src/shared/components/WorkflowCard/Status'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import IMessage from '~/client/src/shared/models/Message/IMessage'
import PermitInspection from '~/client/src/shared/models/PermitInspection'
import PermitInspectionChange from '~/client/src/shared/models/PermitInspectionChange'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import PermitInspectionChangesStore from '~/client/src/shared/stores/domain/PermitInspectionChanges.store'
import PermitInspectionsStore from '~/client/src/shared/stores/domain/PermitInspections.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import SitePermitCreationFormStore from '../SitePermitCreationForm.store'

interface IProps {
  store: SitePermitCreationFormStore
  actionBarStore: PermitLogActionBarStore
  FileInputType: typeof FileInputBase
  openImagePreview(ev: React.MouseEvent<HTMLElement>, url: string): void

  projectDateStore?: ProjectDateStore
  messagesStore?: MessagesStore
  permitInspectionsStore?: PermitInspectionsStore
  permitInspectionChangesStore?: PermitInspectionChangesStore
}

interface IInspectionLog {
  inspectionNumber: number
  inspection: PermitInspection | PermitInspectionChange
}

const inspectionAddedOrUpdated = (
  inspectionNumber: number,
  isEmissionForm: boolean,
  isAdded: boolean,
) =>
  `${isEmissionForm ? 'Use log' : 'Inspection'} #${inspectionNumber} ${
    isAdded ? 'added' : 'updated'
  }`
const inspectionNotUsed = (inspectionNumber: number, isEmissionType: boolean) =>
  `${isEmissionType ? 'Use log' : 'Inspection'} #${inspectionNumber} not used`

@inject(
  'projectDateStore',
  'messagesStore',
  'permitInspectionsStore',
  'permitInspectionChangesStore',
)
@observer
export default class FormLogContent extends React.Component<IProps> {
  public componentDidMount(): void {
    this.props.store.scrollToBottom()
  }

  public render() {
    const {
      FileInputType,
      projectDateStore: { isSameDay },
      actionBarStore,
      openImagePreview,
    } = this.props

    return (
      <div className="col permit-log-container px16">
        {this.log.map((entry, index, array) => {
          const prevEntry = array[index - 1]
          const prevEntryDate = prevEntry && new Date(prevEntry.createdAt)
          const currEntryDate = new Date(entry.createdAt)
          const isFirstEntry = index === 0
          const isLastEntry = index + 1 === array.length
          const shouldRenderSeparator =
            isFirstEntry || !isSameDay(currEntryDate, prevEntryDate)

          return (
            <React.Fragment key={index}>
              {shouldRenderSeparator && (
                <DaySeparator
                  shouldUseLine
                  date={currEntryDate}
                  shouldHideBorder
                />
              )}
              <div className="mb20">
                <CommonThreadEntry
                  key={entry.id}
                  message={entry}
                  showImages
                  hideThread
                  isFormColumn
                  replyCaption={null}
                  threadClass={null}
                  isPhotoThread={false}
                  customPhotoClick={openImagePreview}
                  FileInputType={FileInputType}
                  isLastEntryInLog={isLastEntry}
                  actionBarStore={actionBarStore}
                />
              </div>
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  private get log(): IMessage[] {
    const { store } = this.props
    const { statusChanges } = store

    return [
      ...this.messagesByPermit,
      ...statusChanges.map(sc => ({
        createdAt: sc.createdAt,
        text: this.renderStatusMessage(
          Localization.translator.statusUpdated,
          sc.status,
          sc.workflowStepLevel,
        ),
        id: sc.id,
        userId: sc.userId,
      })),

      ...this.inspectionsLog.map(({ inspection, inspectionNumber }) => ({
        createdAt: inspection.createdAt,
        text: (
          <div>{this.renderInspectionLog(inspection, inspectionNumber)}</div>
        ),
        id: inspection.id,
        userId: inspection.userId,
      })),
    ].sort((a, b) => a.createdAt - b.createdAt)
  }

  private renderInspectionLog = (
    inspection: PermitInspection | PermitInspectionChange,
    inspectionNumber: number,
  ): JSX.Element => {
    const { isEmissionFormType } = this.props.store

    const hasPermitId = !!(inspection as PermitInspection).permitId
    const isNotUsed = !inspection?.fields?.length

    if (isNotUsed) {
      return (
        <span className="text light bold lp05 mr10">
          {inspectionNotUsed(inspectionNumber, isEmissionFormType)}
        </span>
      )
    }

    return (
      <span className="text light bold lp05 mr10">
        {inspectionAddedOrUpdated(
          inspectionNumber,
          isEmissionFormType,
          hasPermitId,
        )}
      </span>
    )
  }

  private renderStatusMessage = (
    label: string,
    status: SitePermitStatus,
    workflowStepLevel: number = 0,
  ): JSX.Element => {
    return (
      <div className="row overflow-hidden">
        <span className="text light bold lp05 mr10 no-flex">{label}</span>
        <Icons.ArrowNext className="row no-grow" />
        <WorkflowCardStatus
          className="ml5 overflow-hidden"
          tagClassName="row"
          status={status}
          workflowStepLevel={workflowStepLevel}
          isLate={false}
        />
      </div>
    )
  }

  @computed
  private get inspectionsLog(): IInspectionLog[] {
    const {
      permitInspectionsStore,
      permitInspectionChangesStore,
      projectDateStore,
      store: { editablePermit, isInspectionPermit, permitInspectionData },
    } = this.props

    if (!isInspectionPermit) {
      return []
    }

    const inspections = permitInspectionsStore.getInspectionsForPermit(
      editablePermit.id,
    )

    return inspections.reduce((list, insp) => {
      const inspectionNumber =
        permitInspectionData.findIndex(i =>
          projectDateStore.isSameDay(i?.date, insp.inspectionDate),
        ) + 1

      list.push({
        inspectionNumber,
        inspection: insp,
      })

      const inspectionChanges =
        permitInspectionChangesStore.getChangesForInspections([insp.id])

      inspectionChanges.forEach(ic =>
        list.push({ inspectionNumber, inspection: ic }),
      )

      return list
    }, [] as IInspectionLog[])
  }

  @computed
  private get messagesByPermit(): IMessage[] {
    const { store, messagesStore } = this.props
    return messagesStore
      .getMessagesByThreadId(store.editablePermit.threadId)
      .map(message => ({
        createdAt: message.createdAt,
        text: message.text,
        id: message.id,
        userId: message.userId,
      }))
  }
}

import { action, computed, observable } from 'mobx'

import { IUser, InviteStatus } from '~/client/graph'
import { SendInvitesDocument } from '~/client/graph/operations/generated/Users.generated'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'

import User from '../../models/User'
import UserProject from '../../models/UserProject'
import { IServiceUserDto } from '../../types/UserDto'
import Guard from '../../utils/Guard'
import { mapFiltered } from '../../utils/util'
import EventsStore from '../EventStore/Events.store'
import GraphExecutorStore from './GraphExecutor.store'
import UserProjectsStore from './UserProjects.store'

export default class ProjectMembersStore {
  @observable private sourceMap: Map<string, User> = new Map()

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly graphExecutorStore: GraphExecutorStore,
  ) {
    Guard.requireAll({ eventsStore, userProjectsStore, graphExecutorStore })

    this.setOne(User.service)
  }

  public getByIds = (userIds: string[] = []): User[] => {
    return mapFiltered(userIds, id => this.getById(id))
  }

  public getById = (userId: string): User => {
    return this.sourceMap.get(userId)
  }

  public getByEmail = (email: string): User => {
    return email ? this.list.find(m => m.email === email) : null
  }

  public getCompanyMembers = (companyId: string): User[] => {
    if (!companyId) {
      return []
    }

    return this.list.filter(
      member =>
        UserProject.getCompanyId(member, this.userProjectsStore) === companyId,
    )
  }

  public hasById = (memberId: string): boolean => {
    return this.sourceMap.has(memberId)
  }

  public hasUserAccessToActiveProjectById = (memberId: string): boolean => {
    return this.userProjectsStore.hasUserAccessToActiveProject(
      this.getById(memberId),
    )
  }

  public hasUserAccessToActiveProject = (member: User): boolean => {
    return this.userProjectsStore.hasUserAccessToActiveProject(member)
  }

  @computed
  public get list(): User[] {
    return [...this.sourceMap.values()]
      .filter(u => this.hasUserAccessToActiveProject(u))
      .sort((a, b) =>
        User.getFullName(a)
          .toLowerCase()
          .localeCompare(User.getFullName(b).toLowerCase()),
      )
  }

  @computed
  public get limitedList(): User[] {
    return this.list.filter(m =>
      UserProject.isAccepted(m, this.userProjectsStore),
    )
  }

  @action.bound
  public receiveList(dtos: IUser[]) {
    this.sourceMap.clear()
    this.setMany([...dtos, User.service])
  }

  @action.bound
  public async saveMembers(members: IServiceUserDto[]) {
    const memberDtos = members.map(m => ({
      ...User.toDto(m),
      userProjectSettings: m.userProjectSettings,
    }))

    return new Promise((resolve, reject) =>
      this.eventsStore.dispatch(e.SAVE_MEMBERS, memberDtos, resolve, reject),
    )
  }

  public async deleteMembers(members: User[]) {
    const userProjectDtos = members.map(m => {
      const userProject = this.userProjectsStore
        .getByUser(m)
        .getCopy() as UserProject

      userProject.setSoftDeletion()
      return userProject.toDto()
    })

    await this.userProjectsStore.save(userProjectDtos)
  }

  @action.bound
  public async inviteMembers(dtos: IUser[]): Promise<boolean> {
    if (!dtos?.length) return

    const { loading, activeProject } = this.appState

    loading.set(e.INVITE_PROJECT_MEMBERS, true)

    const res = await this.graphExecutorStore.mutate(SendInvitesDocument, {
      projectId: activeProject.id,
      userData: dtos.map(({ email, phoneNumber }) => ({ email, phoneNumber })),
    })

    loading.set(e.INVITE_PROJECT_MEMBERS, false)

    if (res.error) {
      throw res.error
    }

    return res.data.sendInvites
  }

  @action.bound
  public async approveProjectMembers(members: IUser[]): Promise<boolean> {
    if (!members?.length) return

    const userProjectDtos = members.map(m => {
      const userProject = this.userProjectsStore
        .getByUser(m)
        .getCopy() as UserProject

      userProject.setInviteStatus(InviteStatus.Pending)

      return userProject.toDto()
    })

    this.appState.loading.set(e.APPROVE_PROJECT_MEMBERS, true)

    try {
      await this.userProjectsStore.save(userProjectDtos)
    } finally {
      this.appState.loading.set(e.APPROVE_PROJECT_MEMBERS, false)
      this.inviteMembers(members)
    }
  }

  @action.bound
  public async resetInvitation(members: IUser[]): Promise<boolean> {
    if (!members?.length) return

    const userProjectDtos = members.map(m => {
      const userProject = this.userProjectsStore
        .getByUser(m)
        .getCopy() as UserProject

      userProject.resetInvitation()
      return userProject.toDto()
    })

    this.appState.loading.set(e.SET_PROJECT_MEMBERS_AS_NOT_INVITED, true)

    try {
      await this.userProjectsStore.save(userProjectDtos)
    } finally {
      this.appState.loading.set(e.SET_PROJECT_MEMBERS_AS_NOT_INVITED, false)
    }
  }

  @action.bound
  public setOne(dto: IUser) {
    this.sourceMap.set(dto.id, User.fromDto(dto))
  }

  @action.bound
  private setMany(dtos: IUser[]) {
    dtos.forEach(this.setOne)
  }

  private get appState() {
    return this.eventsStore.appState
  }
}

import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { observable } from 'mobx'
import { observer } from 'mobx-react'

import SitemapElementsWrapper from '~/client/src/shared/components/SitemapElementsWrapper'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import Awaiter from '~/client/src/shared/utils/Awaiter'

import BoundingBox from './drawnParts/BoundingBox'
import SitemapIcon from './drawnParts/SitemapIcon'
import SitemapLabel from './drawnParts/SitemapLabel'
import SitemapShape from './drawnParts/SitemapShape'

export const MAX_PERCENT = 100

interface IProps {
  items: MapViewItemBase[]
  containerWidth: number
  containerHeight: number
  onSelectItem?: (item: MapViewItemBase, top?: number, left?: number) => void
  isEditMode?: boolean
  textboxesAwaiter?: Awaiter
  textboxesCache?: ICanvasImageCache

  className?: string
  isReferenced?: boolean

  isDisabled?: boolean
}

@observer
export default class SitemapItems extends React.Component<IProps> {
  @observable private boundingBoxItem: MapViewItemBase = null

  public render() {
    const { items, className, isDisabled } = this.props
    return (
      <SitemapElementsWrapper className={className}>
        {!isDisabled && this.renderBoundingBox()}
        {items.map(this.renderShape)}
        {items.map(this.renderLabel)}
        {items.map(this.renderIcon)}
      </SitemapElementsWrapper>
    )
  }

  private renderIcon = (item: MapViewItemBase, idx: number) => {
    const { containerHeight, containerWidth } = this.props

    return (
      <SitemapIcon
        key={`${idx}-${item.id}`}
        item={item}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        onClick={this.selectItem.bind(this, item)}
        onMouseEnter={this.showBoundingBox.bind(this, item)}
        onMouseLeave={this.hideBoundingBox}
      />
    )
  }

  private renderLabel = (item: MapViewItemBase, idx: number) => {
    const {
      containerHeight,
      containerWidth,
      textboxesAwaiter,
      textboxesCache,
      isReferenced,
    } = this.props

    return (
      <SitemapLabel
        key={`${idx}-${item.id}`}
        item={item}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        onClick={this.selectItem.bind(this, item)}
        onMouseEnter={this.showBoundingBox.bind(this, item)}
        onMouseLeave={this.hideBoundingBox}
        textboxesAwaiter={textboxesAwaiter}
        textboxesCache={textboxesCache}
        isReferenced={isReferenced}
      />
    )
  }

  private renderShape = (item: MapViewItemBase, idx: number) => {
    const { containerHeight, containerWidth } = this.props

    return (
      <SitemapShape
        key={`${idx}-${item.id}`}
        item={item}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        onClick={this.selectItem.bind(this, item)}
        onMouseEnter={this.showBoundingBox.bind(this, item)}
        onMouseLeave={this.hideBoundingBox}
        isStaticSize={true}
      />
    )
  }

  private selectItem(
    item: MapViewItemBase,
    event: KonvaEventObject<MouseEvent>,
  ) {
    const { onSelectItem, isDisabled } = this.props
    const { iconProperties, labelProperties } = item.sitemapItemProperties
    if (!onSelectItem || isDisabled) {
      return
    }

    // for Line type (shape properties only)
    if (!iconProperties && !labelProperties) {
      event.cancelBubble = true
      onSelectItem(item)
      return
    }

    event.cancelBubble = true
    onSelectItem(item, event.evt.layerY, event.evt.layerX)
  }

  private renderBoundingBox() {
    const { containerHeight, containerWidth, items, isEditMode } = this.props

    const baseItem = items.find(i => i.id === this.boundingBoxItem?.id)

    if (
      !isEditMode ||
      !this.boundingBoxItem ||
      !items.includes(this.boundingBoxItem) ||
      !baseItem
    ) {
      return null
    }

    const boundingBox =
      this.boundingBoxItem.sitemapItemProperties.getBoundingBox(
        containerWidth,
        containerHeight,
        this.boundingBoxItem.iconName,
        this.boundingBoxItem.name,
      )

    return <BoundingBox boundingBox={boundingBox} isStaticSize={true} />
  }

  private showBoundingBox(item: MapViewItemBase) {
    this.boundingBoxItem = item
  }

  private hideBoundingBox = () => {
    this.boundingBoxItem = null
  }
}

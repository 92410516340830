import * as React from 'react'

import { observer } from 'mobx-react'

import OpenWeatherIcon from '~/client/src/shared/components/OpenWeatherIcon'
import WeatherUnits, {
  temperatureUnits,
  windSpeedUnits,
} from '~/client/src/shared/enums/WeatherUnits'
import WeatherForecastInfo from '~/client/src/shared/models/WeatherInfo'

interface IProps {
  isLoading: boolean
  hasProjectLocation: boolean
  todayForecast: WeatherForecastInfo
  projectWeatherUnits: WeatherUnits
}

const projectLocationIsRequired = "Project's location is required"
const loading = 'Loading...'
const noForecastForToday = 'No forecast for today'
const wind = 'Wind:'
const humidity = 'Humidity:'

@observer
export default class WeatherWidget extends React.Component<IProps> {
  public render() {
    const {
      isLoading,
      hasProjectLocation,
      todayForecast,
      projectWeatherUnits,
    } = this.props

    if (!hasProjectLocation) {
      return (
        <div className="weather-widget pb5 row nowrap">
          {projectLocationIsRequired}
        </div>
      )
    }

    if (isLoading) {
      return <div className="weather-widget pb5 row nowrap">{loading}</div>
    }

    if (!todayForecast) {
      return (
        <div className="weather-widget pb5 row nowrap">
          {noForecastForToday}
        </div>
      )
    }

    const tempUnits = temperatureUnits(projectWeatherUnits)

    return (
      <div className="weather-widget pb5 row nowrap">
        <div className="col no-grow mr15">
          <div className="text huge">
            <span>{todayForecast.currentTemp}</span>
            <span className="ml4">{tempUnits}</span>
          </div>
          <div className="min-max-temp-range ml2">
            <span>{todayForecast.maxTemp}</span>
            <span className="mx4">/</span>
            <span>{todayForecast.minTemp}</span>
            <span className="ml4">{tempUnits}</span>
          </div>
        </div>
        <div className="col no-grow">
          <div className="desc row">
            <OpenWeatherIcon
              openWeatherIconCode={todayForecast.weatherIconCode}
              className="weather-icon no-grow"
            />
            <span className="ml4">{todayForecast.weatherDescription}</span>
          </div>
          <div className="row no-grow text light">
            <div className="pr5">
              <span>{wind}</span>
              <span className="text light bold mx4">
                {todayForecast.windSpeed}
              </span>
              <span>{windSpeedUnits(projectWeatherUnits)}</span>
            </div>
            <div className="pr5">
              <span>{humidity}</span>
              <span className="text light bold mx4">
                {todayForecast.humidity}
              </span>
              <span>%</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

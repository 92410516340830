import { FirebaseError } from 'firebase/app'
import { DocumentNode } from 'graphql'

import {
  IActivityFollowingInput,
  IAnnouncementFollowing,
  IAnnouncementFollowingInput,
  IAnnouncementWithAssignmentInput,
  ICastFollowingInput,
  IClosureFollowingInput,
  ICompany,
  IContentObjectInput,
  IDeliveryFieldsConfigurations,
  IDeliveryFollowingInput,
  IGenerateReportCommandInput,
  IMutation,
  IProjectMaterialInput,
  IScanHistory,
  IScanner,
  ISitePermit,
  ISitePermitFollowingInput,
  IStatusUpdateCompanyInput,
  IStatusUpdateDateInput,
  IStatusUpdateMultiCompaniesInput,
  ITeam,
  ITrade,
  IUserProject,
  IUserRole,
  NotificationType,
  StatusUpdateType,
} from '~/client/graph'
import { SaveActivitiesConfigurationsDocument } from '~/client/graph/operations/generated/ActivitiesConfigurations.generated'
import {
  DeleteCompanyRelationshipDocument,
  SaveCompanyRelationshipDocument,
} from '~/client/graph/operations/generated/ActivityCompanies.generated'
import {
  DeleteManyActivityFollowingsDocument,
  SaveManyActivityFollowingsDocument,
} from '~/client/graph/operations/generated/ActivityFollowing.generated'
import {
  DeleteActivityLocationDocument,
  SaveActivityLocationDocument,
} from '~/client/graph/operations/generated/ActivityLocation.generated'
import {
  DeleteManyAnnouncementFollowingsDocument,
  SaveManyAnnouncementFollowingsDocument,
} from '~/client/graph/operations/generated/AnnouncementFollowings.generated'
import {
  DeleteAnnouncementsDocument,
  SaveAnnouncementsDocument,
} from '~/client/graph/operations/generated/Announcements.generated'
import {
  DeleteManyCastFollowingsDocument,
  SaveManyCastFollowingsDocument,
} from '~/client/graph/operations/generated/CastFollowing.generated'
import {
  DeleteManyClosureFollowingsDocument,
  SaveManyClosureFollowingsDocument,
} from '~/client/graph/operations/generated/ClosureFollowing.generated'
import {
  ActivateCompaniesDocument,
  DeleteCompaniesDocument,
  LinkStruxHubCompanyDocument,
  MergeCompaniesDocument,
  SaveManyCompaniesDocument,
} from '~/client/graph/operations/generated/Companies.generated'
import {
  SaveContentObjectsDocument,
  SaveThreadDocument,
} from '~/client/graph/operations/generated/ContentObjects.generated'
import {
  CreateDeliveryDocument,
  CreateRecurringDeliveriesDocument,
  DeleteManyDeliveriesDocument,
  UpdateDeliveryDocument,
  UpdateRecurringDeliveriesDocument,
} from '~/client/graph/operations/generated/Deliveries.generated'
import { SaveDeliveryConfigurationsDocument } from '~/client/graph/operations/generated/DeliveryConfigurations.generated'
import { SaveDeliveryFieldsConfigurationsDocument } from '~/client/graph/operations/generated/DeliveryFieldsConfigurations.generated'
import {
  DeleteManyDeliveryFollowingsDocument,
  SaveManyDeliveryFollowingsDocument,
} from '~/client/graph/operations/generated/DeliveryFollowing.generated'
import { SendSmsDeliveryTicketDocument } from '~/client/graph/operations/generated/DeliveryTickets.generated'
import { SaveFormsConfigurationsDocument } from '~/client/graph/operations/generated/FormsConfigurations.generated'
import { SaveLogisticsConfigurationsDocument } from '~/client/graph/operations/generated/LogisticsConfigurations.generated'
import {
  ISaveMaterialMutation,
  SaveMaterialDocument,
} from '~/client/graph/operations/generated/Materials.generated'
import { SaveMessageDocument } from '~/client/graph/operations/generated/Messages.generated'
import {
  NotifyEntityRepliedDocument,
  SaveManyNotificationsDocument,
  SaveNotificationDocument,
} from '~/client/graph/operations/generated/Notifications.generated'
import { SavePhotoDocument } from '~/client/graph/operations/generated/Photos.generated'
import { SaveProjectHistoryDocument } from '~/client/graph/operations/generated/ProjectHistory.generated'
import { GenerateReportDocument } from '~/client/graph/operations/generated/Reports.generated'
import { SaveManyScanHistoriesDocument } from '~/client/graph/operations/generated/ScanHistory.generated'
import {
  DeleteManyScannersDocument,
  DeleteScannerDocument,
  SaveManyScannersDocument,
} from '~/client/graph/operations/generated/Scanners.generated'
import { DeleteActivitiesDocument } from '~/client/graph/operations/generated/Schedule.generated'
import {
  DeleteManySitePermitFollowingsDocument,
  SaveManySitePermitFollowingsDocument,
} from '~/client/graph/operations/generated/SitePermitFollow.generated'
import {
  AddSitePermitInspectionChangeDocument,
  AddSitePermitInspectionDocument,
} from '~/client/graph/operations/generated/SitePermitInspections.generated'
import { SaveSitePermitsDocument } from '~/client/graph/operations/generated/SitePermits.generated'
import {
  DeleteStatusUpdatesDocument,
  SaveStatusUpdateDocument,
  UpdateStatusUpdatesDocument,
  UpdateStatusUpdatesForMissedDatesDocument,
} from '~/client/graph/operations/generated/StatusUpdate.generated'
import { SaveTeamDocument } from '~/client/graph/operations/generated/Teams.generated'
import {
  DeleteTradeDocument,
  SaveTradeDocument,
} from '~/client/graph/operations/generated/Trades.generated'
import { SaveManyUserProjectsDocument } from '~/client/graph/operations/generated/UserProjects.generated'
import { SaveUserRoleDocument } from '~/client/graph/operations/generated/UserRoles.generated'
import {
  SaveProjectMembersDocument,
  SendWelcomeEmailsDocument,
} from '~/client/graph/operations/generated/Users.generated'
import { SuccessMessageType } from '~/client/src/shared/components/BulkStatusUpdate/BulkStatusUpdate.store'
import Delivery from '~/client/src/shared/models/Delivery'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import InitialState from '~/client/src/shared/stores/InitialState'
import RootStore from '~/client/src/shared/stores/Root.store'
import IDeliveryAttributeDto from '~/client/src/shared/types/IDeliveryAttributeDto'

import Message from '../../models/Message'
import BaseNotification from '../../models/Notification'
import SitePermit from '../../models/Permit'
import PermitInspection from '../../models/PermitInspection'
import Photo from '../../models/Photo'
import RecurringDeliveriesSettings from '../../models/RecurringDeliveriesSettings'
import { IServiceUserDto } from '../../types/UserDto'
import { mapToList } from '../../utils/converters'
import EventsStore from './Events.store'
import { handleEventCallback } from './commonEvents'
import * as e from './eventConstants'

export default function (eventsStore: EventsStore, rootStore: RootStore) {
  eventsStore.on(
    e.SAVE_PROJECT_TEAM,
    (state, team: ITeam, callback?: (id: string) => void) => {
      state.loading.set(e.SAVE_PROJECT_TEAM, true)

      return {
        graphMutation: {
          mutation: SaveTeamDocument,
          variables: {
            team,
          },
          onSuccess: [e.SAVE_PROJECT_TEAM_SUCCESS, callback as any],
          onError: [e.REQUEST_ERROR, e.SAVE_PROJECT_TEAM],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_PROJECT_TEAM_SUCCESS,
    (state, callback: (id: string) => void, { saveTeam }: IMutation) => {
      state.loading.set(e.SAVE_PROJECT_TEAM, false)

      return {
        dispatch: [e.RUN_CALLBACK, callback, saveTeam?.id],
      }
    },
  )

  eventsStore.on(
    e.SAVE_PROJECT_ROLE,
    (state, userRole: IUserRole, callback?: (id: string) => void) => {
      state.loading.set(e.SAVE_PROJECT_ROLE, true)

      return {
        graphMutation: {
          mutation: SaveUserRoleDocument,
          variables: {
            userRole,
          },
          onSuccess: [e.SAVE_PROJECT_ROLE_SUCCESS, callback as any],
          onError: [e.REQUEST_ERROR, e.SAVE_PROJECT_ROLE],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_PROJECT_ROLE_SUCCESS,
    (state, callback: (id: string) => void, { saveUserRole }: IMutation) => {
      state.loading.set(e.SAVE_PROJECT_ROLE, false)

      return {
        dispatch: [e.RUN_CALLBACK, callback, saveUserRole?.id],
      }
    },
  )

  eventsStore.on(
    e.CREATE_DELIVERY,
    (state, delivery, onSuccessCb, onErrorCb) => {
      state.loading.set(e.CREATE_DELIVERY, true)

      return {
        graphMutation: {
          mutation: CreateDeliveryDocument,
          variables: {
            delivery: delivery.getDto(state.activeProject.id),
            timezoneId: rootStore.projectDateStore.getClientTimezoneId(),
          },
          onSuccess: handleEventCallback(e.CREATE_DELIVERY, true, onSuccessCb),
          onError: handleEventCallback(e.CREATE_DELIVERY, true, onErrorCb),
        },
      }
    },
  )

  eventsStore.on(
    e.CREATE_RECURRING_DELIVERIES,
    (
      state,
      delivery: Delivery,
      recurringSettings: RecurringDeliveriesSettings,
      onSuccessCb,
      onErrorCb,
    ) => {
      state.loading.set(e.CREATE_RECURRING_DELIVERIES, true)

      return {
        graphMutation: {
          mutation: CreateRecurringDeliveriesDocument,
          variables: {
            delivery: {
              ...delivery.getDto(state.activeProject.id),
            },
            recurringSettings,
            timezoneId: rootStore.projectDateStore.getClientTimezoneId(),
          },
          onSuccess: handleEventCallback(
            e.CREATE_RECURRING_DELIVERIES,
            true,
            onSuccessCb,
          ),
          onError: handleEventCallback(
            e.CREATE_RECURRING_DELIVERIES,
            true,
            onErrorCb,
          ),
        },
      }
    },
  )

  eventsStore.on(
    e.UPDATE_DELIVERY,
    (state, delivery: Delivery, message: string, onSuccessCb, onErrorCb) => {
      state.loading.set(e.UPDATE_DELIVERY, true)

      return {
        graphMutation: {
          mutation: UpdateDeliveryDocument,
          variables: {
            delivery: delivery.getDto(state.activeProject.id),
            message,
            timezoneId: rootStore.projectDateStore.getClientTimezoneId(),
          },
          onSuccess: handleEventCallback(e.UPDATE_DELIVERY, true, onSuccessCb),
          onError: handleEventCallback(e.UPDATE_DELIVERY, true, onErrorCb),
        },
      }
    },
  )

  eventsStore.on(
    e.UPDATE_RECURRING_DELIVERIES,
    (
      state,
      delivery: Delivery,
      message: string,
      recurringSettings: RecurringDeliveriesSettings,
      relatedRecurringIds: number[],
      onSuccessCb,
      onErrorCb,
    ) => {
      state.loading.set(e.UPDATE_RECURRING_DELIVERIES, true)

      return {
        graphMutation: {
          mutation: UpdateRecurringDeliveriesDocument,
          variables: {
            delivery: delivery.getDto(state.activeProject.id),
            message,
            recurringSettings,
            relatedRecurringIds,
            timezoneId: rootStore.projectDateStore.getClientTimezoneId(),
          },
          onSuccess: handleEventCallback(
            e.UPDATE_RECURRING_DELIVERIES,
            true,
            onSuccessCb,
          ),
          onError: handleEventCallback(
            e.UPDATE_RECURRING_DELIVERIES,
            true,
            onErrorCb,
          ),
        },
      }
    },
  )

  eventsStore.on(e.DELETE_DELIVERIES, (state, ids: string[]) => {
    state.loading.set(e.DELETE_DELIVERIES, true)

    return {
      graphMutation: {
        mutation: DeleteManyDeliveriesDocument,
        variables: { ids },
        onSuccess: [e.COMPLETE_REQUEST, e.DELETE_DELIVERIES],
        onError: [e.REQUEST_ERROR, e.DELETE_DELIVERIES],
      },
    }
  })

  eventsStore.on(e.DELETE_ACTIVITIES, (state, activitiesCodes) => {
    state.loading.set(e.DELETE_ACTIVITIES, true)
    return {
      graphMutation: {
        mutation: DeleteActivitiesDocument,
        variables: {
          projectId: state.activeProject.id,
          activitiesCodes: activitiesCodes,
        },
        onSuccess: [e.COMPLETE_REQUEST, e.DELETE_ACTIVITIES],
        onError: [e.REQUEST_ERROR, e.DELETE_ACTIVITIES],
      },
    }
  })

  eventsStore.on(
    e.NOTIFY_THREAD_REPLIED,
    (state, entityId, notificationType: NotificationType) => {
      return {
        graphQuery: {
          query: NotifyEntityRepliedDocument,
          variables: {
            data: {
              projectId: state.activeProject.id,
              entityId,
              notificationType,
            },
          },
          onSuccess: [e.NO_EFFECT],
          onError: [e.REQUEST_ERROR, e.NOTIFY_THREAD_REPLIED],
        },
      }
    },
  )

  eventsStore.on(
    e.MARK_NOTIFICATION_AS_READ,
    (state, notification: BaseNotification) => {
      if (!notification || notification.wasRead) {
        return
      }

      state.loading.set(e.MARK_NOTIFICATION_AS_READ, true)
      notification.wasRead = true

      return {
        graphMutation: {
          mutation: SaveNotificationDocument,
          variables: {
            notification: notification.toDto(),
          },
          onSuccess: [e.COMPLETE_REQUEST, e.MARK_NOTIFICATION_AS_READ],
          onError: [e.REQUEST_ERROR, e.MARK_NOTIFICATION_AS_READ],
        },
      }
    },
  )

  eventsStore.on(
    e.MARK_NOTIFICATIONS_AS_READ,
    (
      _,
      notificationsToMarkAsRead: BaseNotification[],
      onSuccess,
      wasRead = true,
    ) => {
      if (notificationsToMarkAsRead.length === 0) {
        return
      }

      const notifications = notificationsToMarkAsRead.map(n => {
        n.wasRead = wasRead
        return n
      })

      return {
        graphMutation: {
          mutation: SaveManyNotificationsDocument,
          variables: { notifications: notifications.map(n => n.toDto()) },
          onSuccess: [e.RUN_CALLBACK, onSuccess],
          onError: [e.REQUEST_ERROR, e.MARK_NOTIFICATIONS_AS_READ],
        },
      }
    },
  )

  eventsStore.on(
    e.MARK_NOTIFICATIONS_AS_ARCHIVED,
    (
      _,
      archivingNotifications: BaseNotification[],
      onSuccess,
      isArchived = true,
    ) => {
      const notifications = archivingNotifications.map(n => {
        n.isArchived = isArchived
        return n
      })

      return {
        graphMutation: {
          mutation: SaveManyNotificationsDocument,
          variables: {
            notifications: notifications.map(n => n.toDto()),
          },
          onSuccess: [e.RUN_CALLBACK, onSuccess],
          onError: [e.REQUEST_ERROR, e.MARK_NOTIFICATIONS_AS_ARCHIVED],
        },
      }
    },
  )

  interface IUpdateCompanyStatusesParams {
    activityCode: string
    date: number
    companies: string[]
    percentComplete: number
    manpower: number
    type: StatusUpdateType
    actualStartDate: number
    actualFinishDate: number
  }

  let nextStatusUpdateRequests: IUpdateCompanyStatusesParams[] = []

  eventsStore.on(
    e.UPDATE_ACTIVITY_COMPANIES_STATUSES,
    (
      state,
      activityCode: string,
      date: number,
      companies: string[],
      percentComplete: number,
      manpower: number,
      type: StatusUpdateType = StatusUpdateType.Active,
      actualStartDate: number,
      actualFinishDate: number,
    ) => {
      const params = {
        activityCode,
        date,
        companies,
        percentComplete,
        manpower,
        type,
        actualStartDate,
        actualFinishDate,
      }
      if (state.loading.get(e.UPDATE_ACTIVITY_COMPANIES_STATUSES)) {
        nextStatusUpdateRequests.push(params)
        return
      }

      return {
        dispatch: [e.UPDATE_ACTIVITY_COMPANY_STATUS_MULTIDATES, [params]],
      }
    },
  )

  eventsStore.on(e.UPDATE_ACTIVITY_COMPANY_STATUS_SUCCESS, state => {
    state.loading.set(e.UPDATE_ACTIVITY_COMPANIES_STATUSES, false)
    if (!nextStatusUpdateRequests.length) {
      return
    }

    const firstActivityCode = nextStatusUpdateRequests[0].activityCode
    const requests = nextStatusUpdateRequests.filter(
      r => r.activityCode === firstActivityCode,
    )

    // the other activities' requests are for the next time
    nextStatusUpdateRequests = nextStatusUpdateRequests.filter(
      r => r.activityCode !== firstActivityCode,
    )

    return {
      dispatch: [e.UPDATE_ACTIVITY_COMPANY_STATUS_MULTIDATES, requests],
    }
  })

  eventsStore.on(
    e.UPDATE_ACTIVITY_COMPANY_STATUS_MULTIDATES,
    (state, requests: IUpdateCompanyStatusesParams[]) => {
      state.loading.set(e.UPDATE_ACTIVITY_COMPANIES_STATUSES, true)
      const variables = {
        projectId: state.activeProject.id,
        userId: state.user.id,
        timezoneId: rootStore.projectDateStore.getClientTimezoneId(),
        activityCode: requests[0].activityCode,
        dates: requests.map(
          ({
            date,
            companies,
            manpower,
            percentComplete,
            type,
            actualStartDate,
            actualFinishDate,
          }): IStatusUpdateMultiCompaniesInput => {
            return {
              unixTime: date,
              type,
              companies: companies.map((company): IStatusUpdateCompanyInput => {
                return {
                  name: company || StatusUpdate.UNASSIGNED_COMPANY_VALUE,
                  manpower,
                  percentComplete,
                }
              }),
              actualStartDate: actualStartDate || null,
              actualFinishDate: actualFinishDate || null,
            }
          },
        ),
      }

      return {
        graphMutation: {
          mutation: UpdateStatusUpdatesDocument,
          variables,
          onSuccess: [e.UPDATE_ACTIVITY_COMPANY_STATUS_SUCCESS],
          onError: [e.REQUEST_ERROR, e.UPDATE_ACTIVITY_COMPANIES_STATUSES],
        },
      }
    },
  )

  eventsStore.on(
    e.BULK_UPDATE_ACTIVITY_COMPANY_STATUS,
    (
      state,
      activityCode: string,
      company: string,
      updates: IStatusUpdateDateInput[],
      messageType: SuccessMessageType,
      message: string,
      onSuccess,
    ) => {
      state.loading.set(e.BULK_UPDATE_ACTIVITY_COMPANY_STATUS + company, true)

      return {
        graphMutation: {
          mutation: UpdateStatusUpdatesForMissedDatesDocument,
          variables: {
            projectId: state.activeProject.id,
            userId: state.user.id,
            activityCode,
            company,
            updates,
          },
          onSuccess: [
            e.BULK_UPDATE_ACTIVITY_COMPANY_STATUS_SUCCESS,
            messageType,
            message,
            company,
            onSuccess,
          ],
          onError: [e.REQUEST_ERROR, e.BULK_UPDATE_ACTIVITY_COMPANY_STATUS],
        },
      }
    },
  )

  eventsStore.on(
    e.BULK_UPDATE_ACTIVITY_COMPANY_STATUS_SUCCESS,
    (
      state,
      type: SuccessMessageType,
      message: string,
      company: string,
      onSuccess,
    ) => {
      state.loading.set(e.BULK_UPDATE_ACTIVITY_COMPANY_STATUS + company, false)
      onSuccess(type, message)
    },
  )

  eventsStore.on(
    e.DELETE_STATUS_UPDATE_FOR_DATE,
    (state, activityCode: string, company: string, date: number) => {
      return {
        graphMutation: {
          mutation: DeleteStatusUpdatesDocument,
          variables: {
            projectId: state.activeProject.id,
            userId: state.user.id,
            unixTime: date,
            timezoneId: rootStore.projectDateStore.getClientTimezoneId(),
            activityCode,
            company,
          },
          onSuccess: [e.NO_EFFECT],
          onError: [e.COMPLETE_REQUEST, e.DELETE_STATUS_UPDATE_FOR_DATE],
        },
      }
    },
  )

  eventsStore.on(
    e.SEND_MESSAGE_TO_THREAD,
    (_, threadId: string, message: string) => {
      rootStore.slackBarStore.draftMessage = message
      rootStore.slackBarStore.replyOnThread(threadId)
    },
  )

  eventsStore.on(
    e.DELETE_ANNOUNCEMENTS,
    (state, announcementIds: string[], cb) => {
      state.loading.set(e.DELETE_ANNOUNCEMENTS, true)
      return {
        graphMutation: {
          mutation: DeleteAnnouncementsDocument,
          variables: { ids: announcementIds },
          onError: [e.REQUEST_ERROR, e.DELETE_ANNOUNCEMENTS],
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.DELETE_ANNOUNCEMENTS,
            cb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_ANNOUNCEMENTS,
    (_, announcementsWithAssignments: IAnnouncementWithAssignmentInput[]) => {
      return {
        graphMutation: {
          mutation: SaveAnnouncementsDocument,
          variables: {
            announcementsWithAssignments,
          },
          onError: [e.REQUEST_ERROR, e.SAVE_ANNOUNCEMENTS],
          onSuccess: [e.NO_EFFECT],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_SITE_PERMITS,
    (
      state,
      permits: SitePermit[],
      callbackFn?: (permits: ISitePermit[]) => void,
    ) => {
      state.loading.set(e.SAVE_SITE_PERMITS, true)

      return {
        graphMutation: {
          mutation: SaveSitePermitsDocument,
          variables: {
            permits: permits.map(p => p.getDto()),
          },
          onSuccess: [e.SAVE_SITE_PERMITS_SUCCESS, callbackFn as any],
          onError: [e.REQUEST_ERROR, e.SAVE_SITE_PERMITS],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_SITE_PERMITS_SUCCESS,
    (
      state: InitialState,
      callbackFn: (permits: ISitePermit[]) => void,
      { saveManySitePermits }: IMutation,
    ) => {
      state.loading.set(e.SAVE_SITE_PERMITS, false)

      if (callbackFn) {
        callbackFn(saveManySitePermits)
      }
    },
  )

  eventsStore.on(
    e.ADD_SITE_PERMIT_INSPECTION,
    (state, inspection: PermitInspection, onSuccessCb, onErrorCb) => {
      state.loading.set(e.ADD_SITE_PERMIT_INSPECTION, true)

      return {
        graphMutation: {
          mutation: AddSitePermitInspectionDocument,
          variables: {
            inspection: inspection.getDto(),
            fields: inspection.getFieldsDto(),
          },
          onSuccess: [e.ADD_SITE_PERMIT_INSPECTION_SUCCESS, onSuccessCb],
          onError: [e.RUN_CALLBACK, onErrorCb],
        },
      }
    },
  )

  eventsStore.on(
    e.ADD_SITE_PERMIT_INSPECTION_SUCCESS,
    (state: InitialState, callbackFn?) => {
      state.loading.set(e.ADD_SITE_PERMIT_INSPECTION, false)

      return {
        dispatch: [e.RUN_CALLBACK, callbackFn],
      }
    },
  )

  eventsStore.on(
    e.UPDATE_SITE_PERMIT_INSPECTION,
    (state, inspectionChange: PermitInspection, onSuccessCb, onErrorCb) => {
      state.loading.set(e.UPDATE_SITE_PERMIT_INSPECTION, true)

      return {
        graphMutation: {
          mutation: AddSitePermitInspectionChangeDocument,
          variables: {
            inspectionChange: inspectionChange.getDto(),
            fields: inspectionChange.getFieldsDto(),
          },
          onSuccess: [e.UPDATE_SITE_PERMIT_INSPECTION_SUCCESS, onSuccessCb],
          onError: [e.RUN_CALLBACK, onErrorCb],
        },
      }
    },
  )

  eventsStore.on(
    e.UPDATE_SITE_PERMIT_INSPECTION_SUCCESS,
    (state: InitialState, callbackFn?) => {
      state.loading.set(e.UPDATE_SITE_PERMIT_INSPECTION, false)

      return {
        dispatch: [e.RUN_CALLBACK, callbackFn],
      }
    },
  )

  eventsStore.on(e.SEND_WELCOME_EMAIL_TO_CURRENT_USER, state => {
    if (!state.user.email) {
      return
    }

    return {
      graphMutation: {
        mutation: SendWelcomeEmailsDocument,
        variables: {
          userEmails: [state.user.email],
          projectId: state.activeProject.id,
        },
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.SEND_WELCOME_EMAIL_TO_CURRENT_USER],
      },
    }
  })

  eventsStore.on(
    e.SAVE_DELIVERY_ATTRIBUTES,
    (state, attrs: [IDeliveryAttributeDto], mutation, cb) => {
      state.loading.set(e.SAVE_DELIVERY_ATTRIBUTES, true)

      return {
        graphMutation: {
          mutation,
          variables: { attrs },
          onSuccess: handleEventCallback(e.SAVE_DELIVERY_ATTRIBUTES, true, cb),
          onError: [e.REQUEST_ERROR, e.SAVE_DELIVERY_ATTRIBUTES],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_DELIVERY_ATTRIBUTES,
    (state, ids: string[], mutation) => {
      state.loading.set(e.DELETE_DELIVERY_ATTRIBUTES, true)

      return {
        graphMutation: {
          mutation,
          variables: { ids },
          onSuccess: [e.COMPLETE_REQUEST, e.DELETE_DELIVERY_ATTRIBUTES],
          onError: [e.REQUEST_ERROR, e.DELETE_DELIVERY_ATTRIBUTES],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_MATERIAL,
    (
      state: InitialState,
      projectMaterial: IProjectMaterialInput,
      onSuccessCb: (result: ISaveMaterialMutation) => void,
      onErrorCb: (result: ISaveMaterialMutation) => void,
    ) => {
      state.loading.set(e.SAVE_MATERIAL, true)

      return {
        graphMutation: {
          mutation: SaveMaterialDocument,
          variables: { projectMaterial },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_MATERIAL,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_MATERIAL,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_ACTIVITY_FOLLOWINGS,
    (
      state,
      activityFollowings: IActivityFollowingInput[],
      onSuccessCb?,
      onErrorCb?,
    ) => {
      if (!activityFollowings.length) {
        return
      }

      state.loading.set(e.SAVE_ACTIVITY_FOLLOWINGS, true)

      return {
        graphMutation: {
          mutation: SaveManyActivityFollowingsDocument,
          variables: { activityFollowings },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_ACTIVITY_FOLLOWINGS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_ACTIVITY_FOLLOWINGS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_ACTIVITY_FOLLOWINGS,
    (state, activityFollowingIds: string[], onSuccessCb?, onErrorCb?) => {
      if (!activityFollowingIds.length) {
        return
      }

      state.loading.set(e.DELETE_ACTIVITY_FOLLOWINGS, true)

      return {
        graphMutation: {
          mutation: DeleteManyActivityFollowingsDocument,
          variables: { activityFollowingIds },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.DELETE_ACTIVITY_FOLLOWINGS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.DELETE_ACTIVITY_FOLLOWINGS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_ANNOUNCEMENT_FOLLOWINGS,
    (
      state,
      announcementFollowings: IAnnouncementFollowingInput[],
      onSuccessCb?,
      onErrorCb?,
    ) => {
      if (!announcementFollowings.length) {
        return
      }

      state.loading.set(e.SAVE_ANNOUNCEMENT_FOLLOWINGS, true)

      return {
        graphMutation: {
          mutation: SaveManyAnnouncementFollowingsDocument,
          variables: { announcementFollowings },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_ANNOUNCEMENT_FOLLOWINGS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_ANNOUNCEMENT_FOLLOWINGS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_ANNOUNCEMENT_FOLLOWINGS,
    (state, announcementFollowingIds: string[], onSuccessCb?, onErrorCb?) => {
      if (!announcementFollowingIds.length) {
        return
      }

      state.loading.set(e.DELETE_ANNOUNCEMENT_FOLLOWINGS, true)

      return {
        graphMutation: {
          mutation: DeleteManyAnnouncementFollowingsDocument,
          variables: { announcementFollowingIds },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.DELETE_ANNOUNCEMENT_FOLLOWINGS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.DELETE_ANNOUNCEMENT_FOLLOWINGS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_CLOSURE_FOLLOWINGS,
    (
      state,
      closureFollowings: IClosureFollowingInput[],
      onSuccessCb?,
      onErrorCb?,
    ) => {
      if (!closureFollowings.length) {
        return
      }

      state.loading.set(e.SAVE_CLOSURE_FOLLOWINGS, true)

      return {
        graphMutation: {
          mutation: SaveManyClosureFollowingsDocument,
          variables: { closureFollowings },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_CLOSURE_FOLLOWINGS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_CLOSURE_FOLLOWINGS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_CLOSURE_FOLLOWINGS,
    (state, closureFollowingIds: string[], onSuccessCb?, onErrorCb?) => {
      if (!closureFollowingIds.length) {
        return
      }

      state.loading.set(e.DELETE_CLOSURE_FOLLOWINGS, true)

      return {
        graphMutation: {
          mutation: DeleteManyClosureFollowingsDocument,
          variables: { closureFollowingIds },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.DELETE_CLOSURE_FOLLOWINGS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.DELETE_CLOSURE_FOLLOWINGS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_SITE_PERMIT_FOLLOWINGS,
    (
      state,
      sitePermitFollowings: ISitePermitFollowingInput[],
      onSuccessCb?,
      onErrorCb?,
    ) => {
      if (!sitePermitFollowings.length) {
        return
      }

      state.loading.set(e.SAVE_SITE_PERMIT_FOLLOWINGS, true)

      return {
        graphMutation: {
          mutation: SaveManySitePermitFollowingsDocument,
          variables: { sitePermitFollowings },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_SITE_PERMIT_FOLLOWINGS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_SITE_PERMIT_FOLLOWINGS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_SITE_PERMIT_FOLLOWINGS,
    (state, sitePermitFollowingIds: string[], onSuccessCb?, onErrorCb?) => {
      if (!sitePermitFollowingIds.length) {
        return
      }

      state.loading.set(e.DELETE_SITE_PERMIT_FOLLOWINGS, true)

      return {
        graphMutation: {
          mutation: DeleteManySitePermitFollowingsDocument,
          variables: { sitePermitFollowingIds },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.DELETE_SITE_PERMIT_FOLLOWINGS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.DELETE_SITE_PERMIT_FOLLOWINGS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_CAST_FOLLOWINGS,
    (
      state,
      castFollowings: ICastFollowingInput[],
      onSuccessCb?,
      onErrorCb?,
    ) => {
      if (!castFollowings.length) {
        return
      }

      state.loading.set(e.SAVE_CAST_FOLLOWINGS, true)

      return {
        graphMutation: {
          mutation: SaveManyCastFollowingsDocument,
          variables: { castFollowings },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_CAST_FOLLOWINGS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_CAST_FOLLOWINGS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_CAST_FOLLOWINGS,
    (state, castFollowingIds: string[], onSuccessCb?, onErrorCb?) => {
      if (!castFollowingIds.length) {
        return
      }

      state.loading.set(e.DELETE_CAST_FOLLOWINGS, true)

      return {
        graphMutation: {
          mutation: DeleteManyCastFollowingsDocument,
          variables: { castFollowingIds },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.DELETE_CAST_FOLLOWINGS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.DELETE_CAST_FOLLOWINGS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_DELIVERY_FOLLOWINGS,
    (
      state,
      deliveryFollowings: IDeliveryFollowingInput[],
      onSuccessCb?,
      onErrorCb?,
    ) => {
      if (!deliveryFollowings.length) {
        return
      }

      state.loading.set(e.SAVE_DELIVERY_FOLLOWINGS, true)

      return {
        graphMutation: {
          mutation: SaveManyDeliveryFollowingsDocument,
          variables: { deliveryFollowings },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_DELIVERY_FOLLOWINGS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_DELIVERY_FOLLOWINGS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_DELIVERY_FOLLOWINGS,
    (state, deliveryFollowingIds: string[], onSuccessCb?, onErrorCb?) => {
      if (!deliveryFollowingIds.length) {
        return
      }

      state.loading.set(e.DELETE_DELIVERY_FOLLOWINGS, true)

      return {
        graphMutation: {
          mutation: DeleteManyDeliveryFollowingsDocument,
          variables: { deliveryFollowingIds },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.DELETE_DELIVERY_FOLLOWINGS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.DELETE_DELIVERY_FOLLOWINGS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_STATUS_UPDATE_AND_SEND_STAT,
    (_: InitialState, statusUpdate: StatusUpdate, onSuccess, onError) => {
      return {
        dispatchN: [
          [e.SAVE_STATUS_UPDATE, statusUpdate, onSuccess, onError],
          [
            e.COLLECT_TELEMETRY,
            {
              event: {
                eventCategory: 'statusUpdates',
                eventAction: 'submit',
                eventLabel: `${statusUpdate.activityP6Code}`,
                eventValue: 1,
              },
            },
          ],
        ],
      }
    },
  )

  eventsStore.on(
    e.SAVE_STATUS_UPDATE,
    (_, statusUpdate: StatusUpdate, onSuccess, onError) => {
      return {
        graphMutation: {
          mutation: SaveStatusUpdateDocument,
          variables: {
            statusUpdate: statusUpdate.asJson,
          },
          onSuccess: [e.RUN_CALLBACK, onSuccess],
          onError: [e.RUN_CALLBACK, onError],
        },
      }
    },
  )

  eventsStore.on(e.REQUEST_RESET_PASSWORD, (_, email, onSuccess, onError) => {
    return {
      cmd: {
        cmd: {
          execute: () => {
            return rootStore.auth.resetPassword(email)
          },
        },
        onSuccess: [e.RUN_CALLBACK, onSuccess],
        onError: [e.RESET_PASSWORD_ERROR, onError],
      },
    }
  })

  eventsStore.on(e.VERIFY_PASSWORD_RESET_CODE, (_, resetCode) => {
    return {
      cmd: {
        cmd: {
          execute: () => {
            return new Promise((res, rej) => {
              try {
                setTimeout(() => {
                  rootStore.auth
                    .verifyPasswordResetCode(resetCode)
                    .then(res, rej)
                }, 500)
              } catch (e) {
                rej(e)
              }
            })
          },
        },
        onSuccess: [e.VERIFY_PASSWORD_RESET_CODE_SUCCESS, resetCode],
        onError: [e.VERIFY_PASSWORD_RESET_CODE_ERROR],
      },
    }
  })

  eventsStore.on(e.VERIFY_PASSWORD_RESET_CODE_SUCCESS, (state, resetCode) => {
    state.onAuthStateChangedCallback()
    rootStore.common.displayResetPasswordView(resetCode)

    return {
      dispatch: [e.INIT_COMPLETE],
    }
  })

  eventsStore.on(e.VERIFY_PASSWORD_RESET_CODE_ERROR, (_, error) => {
    alert(error) // inform end-user

    return {
      dispatchN: [
        [e.INIT_AUTH_USER],
        [e.REQUEST_ERROR, e.VERIFY_PASSWORD_RESET_CODE, error],
      ],
    }
  })

  eventsStore.on(
    e.CONFIRM_RESET_PASSWORD,
    (_, resetCode, newPassword, onError) => {
      const { auth } = rootStore

      return {
        cmd: {
          cmd: {
            execute: () => {
              return auth.confirmPasswordReset(resetCode, newPassword)
            },
          },
          onSuccess: [e.CONFIRM_RESET_PASSWORD_SUCCESS],
          onError: [e.RESET_PASSWORD_ERROR, onError],
        },
      }
    },
  )

  eventsStore.on(e.CONFIRM_RESET_PASSWORD_SUCCESS, () => {
    rootStore.common.displayLoginView()
    // restore broken chain
    return {
      dispatch: [e.INIT_AUTH_USER],
    }
  })

  eventsStore.on(
    e.RESET_PASSWORD_ERROR,
    (_, onErrorCb: (message: string) => any, error: FirebaseError) => {
      onErrorCb(error.message)
    },
  )

  eventsStore.on(
    e.SAVE_ASSIGNMENTS,
    (
      state,
      mutationDocument: DocumentNode,
      assignments: IAnnouncementFollowing[],
      cb?: (message: string) => void,
    ) => {
      state.loading.set(e.SAVE_ASSIGNMENTS, true)

      return {
        graphMutation: {
          mutation: mutationDocument,
          variables: {
            assignments,
          },
          onSuccess: [e.SAVE_ASSIGNMENTS_SUCCESS, cb as any],
          onError: [e.REQUEST_ERROR, e.SAVE_ASSIGNMENTS],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_ASSIGNMENTS_SUCCESS,
    (state, cb?: (message: string) => void) => {
      state.loading.set(e.SAVE_ASSIGNMENTS, false)

      return {
        dispatch: [e.RUN_CALLBACK, cb],
      }
    },
  )

  eventsStore.on(
    e.DELETE_ASSIGNMENTS,
    (
      state,
      mutationDocument: DocumentNode,
      ids: string[],
      cb?: (message: string) => void,
    ) => {
      state.loading.set(e.DELETE_ASSIGNMENTS, true)

      return {
        graphMutation: {
          mutation: mutationDocument,
          variables: {
            ids,
          },
          onSuccess: [e.DELETE_ASSIGNMENTS_SUCCESS, cb as any],
          onError: [e.REQUEST_ERROR, e.DELETE_ASSIGNMENTS],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_ASSIGNMENTS_SUCCESS,
    (state, cb?: (message: string) => void) => {
      state.loading.set(e.DELETE_ASSIGNMENTS, false)

      return {
        dispatch: [e.RUN_CALLBACK, cb],
      }
    },
  )

  eventsStore.on(
    e.SAVE_PROJECT_TRADE,
    (state, trade: ITrade, callback?: (id: string) => void) => {
      state.loading.set(e.SAVE_PROJECT_TRADE, true)

      return {
        graphMutation: {
          mutation: SaveTradeDocument,
          variables: {
            trade,
          },
          onSuccess: [e.SAVE_PROJECT_TRADE_SUCCESS, callback as any],
          onError: [e.REQUEST_ERROR, e.SAVE_PROJECT_TRADE],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_PROJECT_TRADE_SUCCESS,
    (state, callback: (id: string) => void, { saveTrade }: IMutation) => {
      state.loading.set(e.SAVE_PROJECT_TRADE, false)

      return {
        dispatch: [e.RUN_CALLBACK, callback, saveTrade?.id],
      }
    },
  )

  eventsStore.on(
    e.DELETE_PROJECT_TRADE,
    (state, tradeId: string, callback?: (id: string) => void) => {
      state.loading.set(e.DELETE_PROJECT_TRADE, true)

      return {
        graphMutation: {
          mutation: DeleteTradeDocument,
          variables: { tradeId },
          onSuccess: [e.DELETE_PROJECT_TRADE_SUCCESS, tradeId as any, callback],
          onError: [e.REQUEST_ERROR, e.DELETE_PROJECT_TRADE],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_PROJECT_TRADE_SUCCESS,
    (state, tradeId: string, callback?: (id: string) => void) => {
      state.loading.set(e.DELETE_PROJECT_TRADE, false)

      return {
        dispatch: [e.RUN_CALLBACK, callback, tradeId],
      }
    },
  )

  eventsStore.on(
    e.SAVE_ACTIVITIES_CONFIGURATIONS,
    (state, configurations, onSuccessCb, onErrorCb) => {
      state.loading.set(e.SAVE_ACTIVITIES_CONFIGURATIONS, true)

      const activitiesConfigurations = Object.assign(
        state.activitiesSettings.configurations,
        configurations,
      )

      return {
        graphMutation: {
          mutation: SaveActivitiesConfigurationsDocument,
          variables: { activitiesConfigurations },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_ACTIVITIES_CONFIGURATIONS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_ACTIVITIES_CONFIGURATIONS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_DELIVERY_CONFIGURATIONS,
    (state, configurations, onSuccessCb, onErrorCb) => {
      state.loading.set(e.SAVE_DELIVERY_CONFIGURATIONS, true)

      const deliveryConfigurations = Object.assign(
        state.delivery.configurations,
        configurations,
      )

      return {
        graphMutation: {
          mutation: SaveDeliveryConfigurationsDocument,
          variables: { deliveryConfigurations },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_DELIVERY_CONFIGURATIONS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_DELIVERY_CONFIGURATIONS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_LOGISTICS_CONFIGURATIONS,
    (state, configurations, onSuccessCb, onErrorCb) => {
      const logisticsConfigurations = Object.assign(
        state.logistics.configurations,
        configurations,
      )
      return {
        graphMutation: {
          mutation: SaveLogisticsConfigurationsDocument,
          variables: { logisticsConfigurations },
          onSuccess: [e.RUN_CALLBACK, onSuccessCb],
          onError: [e.RUN_CALLBACK, onErrorCb],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_FORMS_CONFIGURATIONS,
    (state, configurations, onSuccessCb, onErrorCb) => {
      const formsConfigurations = Object.assign(
        state.forms.configurations,
        configurations,
      )
      return {
        graphMutation: {
          mutation: SaveFormsConfigurationsDocument,
          variables: { formsConfigurations },
          onSuccess: [e.RUN_CALLBACK, onSuccessCb],
          onError: [e.RUN_CALLBACK, onErrorCb],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_DELIVERY_FIELDS_CONFIGURATIONS,
    (state, onSuccessCb, onErrorCb) => {
      state.loading.set(e.SAVE_DELIVERY_FIELDS_CONFIGURATIONS, true)
      const {
        mandatoryFields,
        hiddenFields,
        customFieldNames,
        deliveryFieldsConfigId: configId,
      } = state.delivery

      const deliveryFieldsConfigurations: IDeliveryFieldsConfigurations = {
        id: configId,
        projectId: state.activeProject.id,
        mandatoryFields: mapToList(mandatoryFields, id => mandatoryFields[id]),
        hiddenFields: mapToList(hiddenFields, id => hiddenFields[id]),
        fieldNames: mapToList(customFieldNames, undefined, (id, name) => ({
          id,
          name,
        })),
      }

      return {
        graphMutation: {
          mutation: SaveDeliveryFieldsConfigurationsDocument,
          variables: { deliveryFieldsConfigurations },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_DELIVERY_FIELDS_CONFIGURATIONS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_DELIVERY_FIELDS_CONFIGURATIONS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_PROJECT_HISTORY,
    (state, projectHistory, onSuccessCb, onErrorCb, shouldCompleteRequest) => {
      state.loading.set(e.SAVE_PROJECT_HISTORY, true)
      return {
        graphMutation: {
          mutation: SaveProjectHistoryDocument,
          variables: { projectHistory },
          onSuccess: handleEventCallback(
            e.SAVE_PROJECT_HISTORY,
            shouldCompleteRequest,
            onSuccessCb,
          ),
          onError: handleEventCallback(
            e.SAVE_PROJECT_HISTORY,
            shouldCompleteRequest,
            onErrorCb,
          ),
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_ACTIVITY_CODE_LOCATION_RELATIONSHIP,
    (state, activityLocation) => {
      state.loading.set(e.SAVE_ACTIVITY_CODE_LOCATION_RELATIONSHIP, true)
      return {
        graphMutation: {
          mutation: SaveActivityLocationDocument,
          variables: { activityLocation },
          onSuccess: [e.NO_EFFECT],
          onError: [
            e.REQUEST_ERROR,
            e.SAVE_ACTIVITY_CODE_LOCATION_RELATIONSHIP,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_ACTIVITY_CODE_LOCATION_RELATIONSHIP,
    (state, activityLocationId) => {
      state.loading.set(e.DELETE_ACTIVITY_CODE_LOCATION_RELATIONSHIP, true)
      return {
        graphMutation: {
          mutation: DeleteActivityLocationDocument,
          variables: { activityLocationId },
          onSuccess: [e.NO_EFFECT],
          onError: [
            e.REQUEST_ERROR,
            e.DELETE_ACTIVITY_CODE_LOCATION_RELATIONSHIP,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_ACTIVITY_COMPANY_RELATIONSHIP,
    (state, companyRelationship) => {
      state.loading.set(e.SAVE_ACTIVITY_COMPANY_RELATIONSHIP, true)
      return {
        graphMutation: {
          mutation: SaveCompanyRelationshipDocument,
          variables: { companyRelationship },
          onSuccess: [e.NO_EFFECT],
          onError: [e.REQUEST_ERROR, e.SAVE_ACTIVITY_COMPANY_RELATIONSHIP],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_ACTIVITY_COMPANY_RELATIONSHIP,
    (state, companyRelationshipId) => {
      state.loading.set(e.DELETE_ACTIVITY_COMPANY_RELATIONSHIP, true)
      return {
        graphMutation: {
          mutation: DeleteCompanyRelationshipDocument,
          variables: { companyRelationshipId },
          onSuccess: [e.NO_EFFECT],
          onError: [e.REQUEST_ERROR, e.DELETE_ACTIVITY_COMPANY_RELATIONSHIP],
        },
      }
    },
  )

  eventsStore.on(e.SAVE_THREAD, (_, thread, onSuccessCb, onErrorCb) => {
    return {
      graphMutation: {
        mutation: SaveThreadDocument,
        variables: { thread },
        onSuccess: [e.RUN_CALLBACK, onSuccessCb],
        onError: [e.RUN_CALLBACK, onErrorCb],
      },
    }
  })

  eventsStore.on(
    e.SAVE_MESSAGE,
    (state, data: Message, onSuccessCb, onErrorCb) => {
      state.loading.set(e.SAVE_MESSAGE, true)

      return {
        graphMutation: {
          mutation: SaveMessageDocument,
          variables: { message: data.getDto() },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_MESSAGE,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_MESSAGE,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(e.SAVE_PHOTO, (_, data: Photo, onSuccessCb, onErrorCb) => {
    return {
      graphMutation: {
        mutation: SavePhotoDocument,
        variables: { photo: data },
        onSuccess: [e.RUN_CALLBACK, onSuccessCb],
        onError: [e.RUN_CALLBACK, onErrorCb],
      },
    }
  })

  eventsStore.on(
    e.SAVE_CONTENT_OBJECTS,
    (_, cos: IContentObjectInput[], onSuccessCb, onErrorCb) => {
      return {
        graphMutation: {
          mutation: SaveContentObjectsDocument,
          variables: { cos },
          onSuccess: [e.RUN_CALLBACK, onSuccessCb],
          onError: [e.RUN_CALLBACK, onErrorCb],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_SCANNERS,
    (state: InitialState, scanners: IScanner[], onSuccessCb?, onErrorCb?) => {
      state.loading.set(e.SAVE_SCANNERS, true)

      return {
        graphMutation: {
          mutation: SaveManyScannersDocument,
          variables: {
            scanners,
          },
          onSuccess: [e.SAVE_SCANNERS_SUCCESS, onSuccessCb],
          onError: handleEventCallback(e.SAVE_SCANNERS, true, onErrorCb),
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_SCANNERS_SUCCESS,
    (state: InitialState, callbackFn, { saveManyScanners }: IMutation) => {
      state.loading.set(e.SAVE_SCANNERS, false)

      return {
        dispatch: [e.RUN_CALLBACK, callbackFn, saveManyScanners],
      }
    },
  )

  eventsStore.on(
    e.SAVE_SCAN_HISTORIES,
    (
      state: InitialState,
      scanHistories: IScanHistory[],
      onSuccessCb?,
      onErrorCb?,
    ) => {
      state.loading.set(e.SAVE_SCAN_HISTORIES, true)

      return {
        graphMutation: {
          mutation: SaveManyScanHistoriesDocument,
          variables: {
            scanHistories,
          },
          onSuccess: [e.SAVE_SCAN_HISTORIES_SUCCESS, onSuccessCb],
          onError: handleEventCallback(e.SAVE_SCAN_HISTORIES, true, onErrorCb),
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_SCAN_HISTORIES_SUCCESS,
    (state: InitialState, callbackFn, { saveManyScanHistories }: IMutation) => {
      state.loading.set(e.SAVE_SCAN_HISTORIES, false)

      return {
        dispatch: [e.RUN_CALLBACK, callbackFn, saveManyScanHistories],
      }
    },
  )

  eventsStore.on(
    e.DELETE_SCANNER,
    (state: InitialState, scannerId: string, onSuccessCb, onErrorCb) => {
      state.loading.set(e.DELETE_SCANNER, true)

      return {
        graphMutation: {
          mutation: DeleteScannerDocument,
          variables: { scannerId },
          onSuccess: handleEventCallback(e.DELETE_SCANNER, true, onSuccessCb),
          onError: handleEventCallback(e.DELETE_SCANNER, true, onErrorCb),
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_SCANNERS,
    (state: InitialState, scannerIds: string[], onSuccessCb, onErrorCb) => {
      state.loading.set(e.DELETE_SCANNERS, true)

      return {
        graphMutation: {
          mutation: DeleteManyScannersDocument,
          variables: { scannerIds },
          onSuccess: handleEventCallback(e.DELETE_SCANNERS, true, onSuccessCb),
          onError: handleEventCallback(e.DELETE_SCANNERS, true, onErrorCb),
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_COMPANIES,
    (state: InitialState, companies: ICompany[], onSuccessCb?, onErrorCb?) => {
      state.loading.set(e.SAVE_COMPANIES, true)

      return {
        graphMutation: {
          mutation: SaveManyCompaniesDocument,
          variables: {
            companies,
          },
          onSuccess: [e.SAVE_COMPANIES_SUCCESS, onSuccessCb],
          onError: handleEventCallback(e.SAVE_COMPANIES, true, onErrorCb),
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_COMPANIES_SUCCESS,
    (state: InitialState, callbackFn, { saveManyCompanies }: IMutation) => {
      state.loading.set(e.SAVE_COMPANIES, false)

      return {
        dispatch: [e.RUN_CALLBACK, callbackFn, saveManyCompanies],
      }
    },
  )

  eventsStore.on(
    e.SAVE_MEMBERS,
    (
      state: InitialState,
      members: IServiceUserDto[],
      onSuccessCb?,
      onErrorCb?,
    ) => {
      state.loading.set(e.SAVE_MEMBERS, true)

      return {
        graphMutation: {
          mutation: SaveProjectMembersDocument,
          variables: {
            members,
          },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_MEMBERS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SAVE_MEMBERS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_USER_PROJECTS,
    (
      state: InitialState,
      userProjects: IUserProject[],
      onSuccessCb?,
      onErrorCb?,
    ) => {
      state.loading.set(e.SAVE_USER_PROJECTS, true)

      return {
        graphMutation: {
          mutation: SaveManyUserProjectsDocument,
          variables: {
            userProjects,
          },
          onSuccess: [e.SAVE_USER_PROJECTS_SUCCESS, onSuccessCb],
          onError: [e.SAVE_USER_PROJECTS_ERROR, onErrorCb],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_USER_PROJECTS_SUCCESS,
    (state: InitialState, callbackFn, { saveManyUserProjects }: IMutation) => {
      state.loading.set(e.SAVE_USER_PROJECTS, false)

      return {
        dispatch: [e.RUN_CALLBACK, callbackFn, saveManyUserProjects],
      }
    },
  )

  eventsStore.on(e.SAVE_USER_PROJECTS_ERROR, (_: InitialState, callbackFn?) => {
    return {
      dispatchN: [
        [e.RUN_CALLBACK, callbackFn],
        [e.REQUEST_ERROR, e.SAVE_USER_PROJECTS],
      ],
    }
  })

  eventsStore.on(
    e.DELETE_COMPANIES,
    (state: InitialState, companyIds: string[], onSuccessCb, onErrorCb) => {
      state.loading.set(e.DELETE_COMPANIES, true)

      return {
        graphMutation: {
          mutation: DeleteCompaniesDocument,
          variables: { companyIds },
          onSuccess: handleEventCallback(e.DELETE_COMPANIES, true, onSuccessCb),
          onError: handleEventCallback(e.DELETE_COMPANIES, true, onErrorCb),
        },
      }
    },
  )

  eventsStore.on(
    e.ACTIVATE_COMPANIES,
    (state: InitialState, companyIds: string[], onSuccessCb, onErrorCb) => {
      state.loading.set(e.ACTIVATE_COMPANIES, true)

      return {
        graphMutation: {
          mutation: ActivateCompaniesDocument,
          variables: { companyIds },
          onSuccess: handleEventCallback(
            e.ACTIVATE_COMPANIES,
            true,
            onSuccessCb,
          ),
          onError: handleEventCallback(e.ACTIVATE_COMPANIES, true, onErrorCb),
        },
      }
    },
  )

  eventsStore.on(
    e.MERGE_COMPANIES,
    (
      state: InitialState,
      companyIds: string[],
      targetCompany: ICompany,
      onSuccessCb,
      onErrorCb,
    ) => {
      state.loading.set(e.MERGE_COMPANIES, true)

      return {
        graphMutation: {
          mutation: MergeCompaniesDocument,
          variables: { companyIds, targetCompany },
          onSuccess: handleEventCallback(e.MERGE_COMPANIES, true, onSuccessCb),
          onError: handleEventCallback(e.MERGE_COMPANIES, true, onErrorCb),
        },
      }
    },
  )

  eventsStore.on(
    e.LINK_STRUXHUB_COMPANY,
    (
      state: InitialState,
      companyId: string,
      struxHubCompanyId: string,
      onSuccessCb,
      onErrorCb,
    ) => {
      state.loading.set(e.LINK_STRUXHUB_COMPANY, true)

      return {
        graphMutation: {
          mutation: LinkStruxHubCompanyDocument,
          variables: { companyId, struxHubCompanyId },
          onSuccess: handleEventCallback(
            e.LINK_STRUXHUB_COMPANY,
            true,
            onSuccessCb,
          ),
          onError: handleEventCallback(
            e.LINK_STRUXHUB_COMPANY,
            true,
            onErrorCb,
          ),
        },
      }
    },
  )

  eventsStore.on(
    e.SEND_SMS_DELIVERY_TICKET,
    (state: InitialState, deliveryId: string, onErrorCb) => {
      state.loading.set(e.SEND_SMS_DELIVERY_TICKET, true)
      return {
        graphMutation: {
          mutation: SendSmsDeliveryTicketDocument,
          variables: {
            deliveryId,
          },
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.SEND_SMS_DELIVERY_TICKET,
            onErrorCb,
          ],
          onSuccess: [e.SEND_SMS_DELIVERY_TICKET_SUCCESS],
        },
      }
    },
  )

  eventsStore.on(e.SEND_SMS_DELIVERY_TICKET_SUCCESS, (state: InitialState) => {
    state.loading.set(e.SEND_SMS_DELIVERY_TICKET, false)
  })

  eventsStore.on(e.SHOW_FULLSCREEN_PREVIEW, () => {
    // only postEventCallback is needed
  })

  eventsStore.on(
    e.DOWNLOAD_REPORT_FILE,
    (
      _: InitialState,
      generateReportInput: IGenerateReportCommandInput,
      callback?,
    ) => {
      return {
        graphMutation: {
          mutation: GenerateReportDocument,
          variables: { generateReportInput },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.DOWNLOAD_REPORT_SUCCESS,
            callback,
          ],
          onError: [e.DOWNLOAD_REPORT_ERROR, callback],
        },
      }
    },
  )

  eventsStore.on(e.DOWNLOAD_REPORT_ERROR, (_, callback?) => {
    callback?.()
    return { dispatch: [e.REPORT_ERROR, e.DOWNLOAD_REPORT_FILE] }
  })
}

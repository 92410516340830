import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import User from '~/client/src/shared/models/User'
import InitialState from '~/client/src/shared/stores/InitialState'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'

import Localization from '../../shared/localization/LocalizationManager'
import UserProject from '../models/UserProject'
import ProjectMembersStore from '../stores/domain/ProjectMembers.store'
import UserProjectsStore from '../stores/domain/UserProjects.store'

interface IProps {
  userId: string
  unknownUserLabel?: string
  showCompany?: boolean
  withBoldLastName?: boolean
  showBoldName?: boolean
  isClickable?: boolean
  isAlwaysUnderlined?: boolean

  state?: InitialState
  companiesStore?: CompaniesStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
}

@inject('state', 'companiesStore', 'userProjectsStore', 'projectMembersStore')
@observer
export default class UsernameFromUid extends React.Component<IProps> {
  public static defaultProps = {
    isClickable: true,
    unknownUserLabel: Localization.translator.unknownUser,
  }

  public render() {
    const { withBoldLastName, showBoldName, isAlwaysUnderlined } = this.props

    return (
      <span
        className={classList({
          'user-name-container': true,
          'clickable pointer force-clickable': this.isClickable,
          bold: !withBoldLastName && showBoldName,
          'always-underlined': isAlwaysUnderlined,
        })}
        onClick={this.isClickable ? this.handleClick : undefined}
      >
        {this.content}
      </span>
    )
  }

  private get user(): User {
    const {
      state: { user: currentUser },
      userId,
    } = this.props

    // for cases when currentUser is already received while the members list is still loading
    // e.g. show on settings sidebar (immediately after logging in)
    if (currentUser?.id === userId) {
      return currentUser
    }

    return this.props.projectMembersStore.getById(userId)
  }

  private get nameAsString(): string {
    const name = User.getFullNameToDisplay(
      this.user,
      this.props.userProjectsStore,
    )

    if (!this.props.showCompany) {
      return name
    }

    const { userProjectsStore, companiesStore } = this.props

    const company = UserProject.getCompanyName(
      this.user,
      userProjectsStore,
      companiesStore,
    )

    return `${name} (${company})`
  }

  private get content() {
    if (!this.user) {
      return this.props.unknownUserLabel
    }

    const { withBoldLastName, userProjectsStore } = this.props
    const { firstName, lastName } = this.user

    if (!withBoldLastName || (!firstName && !lastName)) {
      return this.nameAsString
    }

    const isDeleted = UserProject.isDeleted(this.user, userProjectsStore)

    return (
      <>
        {firstName || ''}
        {!!lastName && <strong>&nbsp;{lastName}</strong>}
        {isDeleted && <>&nbsp;{`[${Localization.translator.deactivated}]`}</>}
      </>
    )
  }

  @action.bound
  private handleClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation()
    this.props.state.setIdOfUserDisplayedOnGlobalCard(this.props.userId)
  }

  private get isClickable(): boolean {
    return this.props.isClickable && this.user && !this.user.isService
  }
}

import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import { ILocationClosureInterval } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import SitePermit from '~/client/src/shared/models/Permit'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import ClosuresStore from '../../stores/domain/Closures.store'
import OpeningIndicatorLabel from '../OpeningIndicatorLabel/OpeningIndicatorLabel'
import PermitTypeIcon from '../PermitTypeIcon/PermitTypeIcon'

import './LocationCard.scss'

const noActiveClosures = 'No active closures'

interface IProps {
  item: MapViewItemBase
  currentDate: Date
  openPermit: (permit: SitePermit) => void

  sitePermitsStore?: SitePermitsStore
  projectDateStore?: ProjectDateStore
  permitTypesStore?: PermitTypesStore
  closuresStore?: ClosuresStore
}

@inject(
  'projectDateStore',
  'sitePermitsStore',
  'permitTypesStore',
  'closuresStore',
)
@observer
export default class LocationCard extends React.Component<IProps> {
  public render() {
    const { item } = this.props

    if (!item?.dataObject) {
      return null
    }

    return (
      <div className="col bg-white location-card-holder pa5 brada10">
        <div className="row">
          <SitemapAttributeTag
            shouldShowAsTag={false}
            contentContainerClassName="text-ellipsis py2"
            dataObject={item.dataObject}
          >
            <span title={item.dataObject.name} className="text large">
              {item.dataObject.name}
            </span>
          </SitemapAttributeTag>
          <div className="no-grow">
            <OpeningIndicatorLabel
              closeLabel={Localization.translator.closed}
              openLabel={Localization.translator.opened}
              isOpen={this.isOpen}
            />
          </div>
        </div>
        {this.renderClosureContent()}
      </div>
    )
  }

  private renderClosureContent() {
    const {
      item: { dataObject },
      sitePermitsStore: { getClosedIntervals },
      currentDate,
      openPermit,
      projectDateStore,
      permitTypesStore,
      closuresStore,
    } = this.props

    const locationClosures =
      closuresStore.closuresByLocationIdMap[dataObject.id]

    const {
      getDateIntervalToDisplay,
      getTimeIntervalPerDayToDisplay,
      isClosedBetween,
      startOfDay,
      endOfDay,
    } = projectDateStore

    const { id, operatingIntervals } = dataObject
    const now = Date.now()

    const permitsForDay = getClosedIntervals(id).filter(form =>
      form.isScheduledWithinRange(
        startOfDay(currentDate),
        endOfDay(currentDate),
      ),
    )
    const activeClosure = locationClosures?.find(closure =>
      closure.isOpenByDate(projectDateStore, now),
    )
    const activeOperationalInterval = operatingIntervals.find(interval => {
      return isClosedBetween(new Date(now), new Date(now + 1), [interval], [])
    })

    switch (true) {
      case this.isOpen:
        return <div className="text grey">{noActiveClosures}</div>
      case !!permitsForDay.length:
        const { endDate, startDate, getCaption, getTypeOfPermitType } =
          permitsForDay[0]

        return (
          <div className="col">
            <div className="row">
              <Icons.CalendarClose className="mr4 no-grow calendar-icon" />
              {getDateIntervalToDisplay(startDate, endDate)}
            </div>
            <div
              className="row"
              onClick={openPermit.bind(this, permitsForDay[0])}
            >
              <PermitTypeIcon
                permitType={getTypeOfPermitType(permitTypesStore)}
                className="no-grow mr4 main-icon blue-icon"
              />
              <span className="inline-block text-ellipsis title-text">
                {getCaption(permitTypesStore)}
              </span>
              <Icon icon={IconNames.CHEVRON_RIGHT} />
            </div>
          </div>
        )
      case !!activeClosure:
        return (
          <span>
            {this.getActiveClosureLabel(activeClosure.closureInterval)}
          </span>
        )
      case !!activeOperationalInterval:
        return (
          <div className="col">
            {getTimeIntervalPerDayToDisplay(
              activeOperationalInterval.startDate,
              activeOperationalInterval.endDate,
            )}
          </div>
        )
    }
  }

  private get isOpen(): boolean {
    const {
      item: { dataObject },
      closuresStore,
    } = this.props

    return closuresStore.isLocationOpen(dataObject.id)
  }

  private getActiveClosureLabel = (
    closureInterval: ILocationClosureInterval,
  ): string => {
    const { isDaily, startDate, endDate } = closureInterval
    const { getTimeIntervalPerDayToDisplay, getDateIntervalToDisplay } =
      this.props.projectDateStore

    return isDaily
      ? `${getTimeIntervalPerDayToDisplay(startDate, endDate)} / ${
          Localization.translator.daily
        }`
      : getDateIntervalToDisplay(startDate, endDate)
  }
}

import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import PhoneInput from 'react-phone-input-2'

import InitialState from '../../stores/InitialState'
import {
  IPhoneNumber,
  PREFERRED_COUNTRY_CODES,
  isPhoneNumber,
} from '../../utils/phoneNumberHelpers'
import { EMPTY_STRING } from '../../utils/usefulStrings'
import { customDebounce } from '../../utils/util'
import BaseStruxhubInput, { ISharedProps } from './BaseStruxhubInput'

function getPhoneNumber(value: string | IPhoneNumber): string {
  return isPhoneNumber(value) ? value.phoneNumber : value
}
function getCountryCode(value: string): string {
  if (!value || value === '+') {
    return EMPTY_STRING
  }
  const code = COUNTRY_CODE_REGEX.exec(value)?.[0]
  return code ?? EMPTY_STRING
}
function getPhoneDigits(value: string): string {
  if (!value) {
    return EMPTY_STRING
  }
  return value.replace(NON_DIGITS_REGEX, EMPTY_STRING)
}

interface IProps extends ISharedProps {
  onChange: (phoneNumber: any, country: any) => void
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void
  onBlur?: () => void
  onFocus?: () => void

  id?: string
  name?: string

  state?: InitialState
}

const DIGITS_REGEX = /\d+/
const NON_DIGITS_REGEX = /\D+/g
const COUNTRY_CODE_REGEX = /^\+\d{0,3}\w/
const COUNTRY_SHELL = { name: '', dialCode: '', countryCode: '', format: '' }
const DEBOUNCE_DELAY = 500

@inject('state')
@observer
export default class StruxhubPhoneInput extends React.Component<IProps> {
  private didOverrideNumber = false
  private initialNumber = EMPTY_STRING

  public render() {
    const { isRequired, value, state, onPaste, id, name } = this.props

    const phoneNumber = isPhoneNumber(value) ? value.phoneNumber : value
    const initialCountryCode = state.countryCode || PREFERRED_COUNTRY_CODES[0]

    return (
      // onPaste goes here because react-phone-input-2 doesn't support it
      // onBlur and onFocus here are not the same as the ones in there and those don't propagate properly
      // Also, onFocusOut to make sure the event propagates
      <div
        className="relative full-width"
        onPaste={onPaste}
        onBlur={this.props.onBlur}
        onFocus={this.props.onFocus}
      >
        <BaseStruxhubInput {...this.props}>
          {(isValueInvalid, isInFocus, onFocus, onBlur) => (
            <PhoneInput
              inputProps={{
                id,
                name,
                required: isRequired,
              }}
              inputClass={classList({
                'no-outline full-width': true,
                'ba-light-blue': isInFocus && !isValueInvalid,
                'ba-palette-brand-lighter': !isInFocus && !isValueInvalid,
                'ba-red': isValueInvalid,
              })}
              country={initialCountryCode}
              value={phoneNumber}
              onFocus={onFocus}
              onFocusOut={this.props.onBlur}
              onBlur={onBlur}
              onChange={this.onPhoneNumberChange}
              preferredCountries={PREFERRED_COUNTRY_CODES}
              enableSearch
              enableAreaCodes
              disableSearchIcon
              placeholder={null}
              isValid={this.checkPhoneNumber}
            />
          )}
        </BaseStruxhubInput>
      </div>
    )
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.didOverrideNumber) {
      this.didOverrideNumber = false
      return
    }

    if (prevProps.value !== this.props.value) {
      const currentPhoneNumber = getPhoneNumber(this.props.value)
      const previousPhoneNumber = getPhoneNumber(prevProps.value)

      if (
        getPhoneDigits(previousPhoneNumber).length <= 3 &&
        getPhoneDigits(currentPhoneNumber).length > 3
      ) {
        const currentCountryCode = getCountryCode(currentPhoneNumber)
        const previousCountryCode =
          getCountryCode(previousPhoneNumber) || this.initialNumber

        if (
          !currentCountryCode
            .match(DIGITS_REGEX)?.[0]
            .startsWith(previousCountryCode.match(DIGITS_REGEX)?.[0])
        ) {
          this.didOverrideNumber = true
          this.onPhoneNumberChange(
            `${
              previousCountryCode.startsWith('+') ? '' : '+'
            }${previousCountryCode} ${currentPhoneNumber
              .match(/\d+/g)
              .join(EMPTY_STRING)}`,
            COUNTRY_SHELL,
          )
        }
      }
    }
  }

  private readonly setInitialPhoneNumber = (phoneNumber: string) => {
    this.initialNumber = phoneNumber
  }

  private readonly debouncedSetInitialPhoneNumber = customDebounce(
    this.setInitialPhoneNumber,
    DEBOUNCE_DELAY,
  )

  /**
   * Phone number validations can happen here. Any return value will trigger a validation issue.
   */
  private readonly checkPhoneNumber = (value: string): boolean => {
    if (value && !this.initialNumber) {
      // Save initial country code (present in the component but not in the value on the first render)
      // to be able to override the number with it if one is pasted without the country code
      this.debouncedSetInitialPhoneNumber(value)
    }
    return true
  }

  private onPhoneNumberChange = (phoneNumber: string, country: any) => {
    this.props.onChange(
      phoneNumber && !phoneNumber.startsWith('+')
        ? `+${phoneNumber}`
        : phoneNumber,
      country,
    )
  }
}

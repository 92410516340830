import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { IProject } from '~/client/graph'
import Config from '~/client/src/shared/Config'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import './ProjectBlock.scss'

interface IProjectBlock {
  project: IProject

  eventsStore?: EventsStore
  projectsStore?: ProjectsStore
  projectDateStore?: ProjectDateStore

  onClick?: (id: string) => void
}

@inject('eventsStore', 'projectsStore', 'projectDateStore')
@observer
export default class ProjectBlock extends React.Component<IProjectBlock> {
  public render() {
    const {
      eventsStore,
      projectsStore,
      project: { name: projectName, id },
      projectDateStore: { getMonthDayAndYearToDisplay },
    } = this.props

    const { activeProject } = eventsStore.appState
    const { projectStatuses, getAddressString } = projectsStore

    const projectStatus = projectStatuses.get(id)
    const address = getAddressString(id)

    return (
      <div
        className="project-block indication-click"
        tabIndex={0}
        key={id}
        onClick={this.handleClick}
      >
        <div className="row">
          <div
            className={classList({
              'project-name pointer': true,
              active: !Config.TENANTLESS_MODE && activeProject.id === id,
            })}
          >
            {projectName}
          </div>
        </div>
        <div className="row y-start">
          <div className="project-address col">
            <div className="project-address overflow-hidden">{address}</div>
          </div>
          {projectStatus && (
            <div className="project-schedule row">{`${getMonthDayAndYearToDisplay(
              projectStatus.startDate,
            )} - ${getMonthDayAndYearToDisplay(projectStatus.endDate)}`}</div>
          )}
        </div>
      </div>
    )
  }

  private readonly handleClick = () => {
    const { project, onClick } = this.props
    onClick?.(project.id)
  }
}

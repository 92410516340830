import * as React from 'react'

import { classList } from 'react-classlist-helper'

import { SitePermitStatus } from '~/client/graph'

import { getFormsStatusAsTag } from '../../constants/formStatusesTags'
import LocationAttributeBase from '../../models/LocationObjects/LocationAttributeBase'
import SitemapAttributeTag from '../SitemapAttributeTag/SitemapAttributeTag'

interface IProps {
  status: SitePermitStatus
  workflowStepLevel?: number
  isLate: boolean
  alignRight?: boolean
  className?: string
  tagClassName?: string
  textSize?: string
}

export default class WorkflowCardStatus extends React.Component<IProps> {
  public render() {
    const { className, alignRight, tagClassName, textSize } = this.props
    const dataObject = this.getStatusAsObjectData()

    return (
      <span
        className={classList({
          'brada3 px5': true,
          [className]: !!className,
          'float-right': !!alignRight,
        })}
      >
        <SitemapAttributeTag
          dataObject={dataObject}
          shouldShowAsTag={true}
          className={tagClassName}
          contentContainerClassName="text-ellipsis py2"
        >
          <span
            title={dataObject.name}
            className={classList({ text: true, [textSize ?? 'large']: true })}
          >
            {dataObject.name}
          </span>
        </SitemapAttributeTag>
      </span>
    )
  }

  private getStatusAsObjectData(): LocationAttributeBase {
    const { status, workflowStepLevel, isLate } = this.props
    return getFormsStatusAsTag(
      status,
      workflowStepLevel,
      isLate,
    ) as LocationAttributeBase
  }
}

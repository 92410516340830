import { action, computed, observable } from 'mobx'

import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import MapViewItemFactory from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemFactory'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import Sitemap from '~/client/src/shared/models/Sitemap'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'

import Basemap from '../../models/Basemap'
import BasemapsStore from '../../stores/domain/Basemaps.store'
import GlobeViewsStore from '../../stores/domain/GlobeViews.store'
import LocationAttributesStore from '../../stores/domain/LocationAttributes.store'
import SitemapsStore from '../../stores/domain/Sitemaps.store'
import { ALLOWED_SITEMAP_ITEMS_WITHOUT_DATA_OBJECT } from '../MapBoxViewer/MapBoxViewer.store'

export default class BaseMapViewSetUpStore {
  @observable public selectedSitemapId: string = null

  public constructor(
    public readonly sitemapsStore: SitemapsStore,
    public readonly globeViewsStore: GlobeViewsStore,
    protected readonly basemapsStore: BasemapsStore,
    protected readonly sitemapItemsStore: SitemapItemsStore,
    protected readonly locationAttributesStore: LocationAttributesStore,
  ) {}

  @computed
  public get displayedSitemapItems(): MapViewItemBase[] {
    return this.sitemapItems.filter(i => i.isDisplayed)
  }

  @computed
  public get sitemapItems(): MapViewItemBase[] {
    if (!this.sitemap) {
      return []
    }

    let items: MapViewItemBase[] = []

    this.hierarchyAttributes.forEach(dataObject => {
      const assignedItems = this.sitemapItemsStore.list.filter(s =>
        s.isAssignedTo(dataObject),
      )

      if (!assignedItems?.length) {
        return
      }

      const convertedItems: MapViewItemBase[] = assignedItems.map(item => {
        return MapViewItemFactory.fromSitemapItem(
          dataObject.copy(),
          item.copy(),
          this.sitemap,
        )
      })

      items.push(
        convertedItems.reduce((prevVal, current) =>
          current?.isDisplayed ? current : prevVal,
        ),
      )
    })

    items = items.filter(item => item)

    this.sitemapItemsStore.list
      .filter(
        item =>
          !item.isAssigned &&
          ALLOWED_SITEMAP_ITEMS_WITHOUT_DATA_OBJECT.includes(item.type) &&
          this.allSitemaps.some(s => s.hasDisplayData(item.id)),
      )
      .forEach(item => {
        items.push(
          MapViewItemFactory.fromSitemapItem(null, item.copy(), this.sitemap),
        )
      })

    return items
  }

  @computed
  public get hierarchyAttributes(): LocationBase[] {
    return this.locationAttributesStore.allAttributes
  }

  public get allSitemaps(): Sitemap[] {
    // Sorting:
    // selected sitemap first
    // then sitemaps with same basemaps
    // then rest
    return this.sitemapsStore.list.slice().sort((a, b) => {
      let aVal = 0
      let bVal = 0

      if (a === this.sitemap) {
        aVal = 2
      } else if (this.sitemap && this.sitemap.basemapId === a.basemapId) {
        aVal = 1
      }

      if (b === this.sitemap) {
        bVal = 2
      } else if (this.sitemap && this.sitemap.basemapId === b.basemapId) {
        bVal = 1
      }

      return bVal - aVal
    })
  }

  @action.bound
  public selectSitemap(sitemap: Sitemap) {
    if (!sitemap) {
      return
    }

    this.selectedSitemapId = sitemap.id
  }

  @action.bound
  public selectSitemapFromId(id: string) {
    const sitemap = this.sitemapsStore.byId.get(id)
    this.selectSitemap(sitemap)
  }

  @action.bound
  public deselectSitemap() {
    this.selectedSitemapId = null
  }

  public get sitemap(): Sitemap {
    return this.sitemapsStore.byId.get(this.selectedSitemapId)
  }

  @computed
  public get basemap(): Basemap {
    return this.sitemap && this.basemapsStore.byId.get(this.sitemap.basemapId)
  }

  public get sitemapUrl() {
    return this.basemap && this.basemap.source
  }

  public get isInitialLoaderShown(): boolean {
    return (
      !this.sitemapsStore.isDataReceived ||
      !this.sitemapItemsStore.isDataReceived ||
      !this.locationAttributesStore.isDataReceived
    )
  }

  public getSitemapItemById = (id: string) => {
    return this.sitemapItemsStore.byId.get(id)
  }
}

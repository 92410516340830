import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { CellMeasurerCache } from 'react-virtualized'

import {
  IFormMaterial,
  IMeasure,
  IPermitTypeField,
  LocationType,
  PermitFieldType,
} from '~/client/graph'
import BaseCompactPopup from '~/client/src/shared/components/BaseCompactPopup/BaseCompactPopup'
import { Loader } from '~/client/src/shared/components/Loader'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import InitialState from '~/client/src/shared/stores/InitialState'
import { getLocationTypeDisplayName } from '~/client/src/shared/types/IHierarchyParent'
import { NOOP } from '~/client/src/shared/utils/noop'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import SitePermitCreationFormStore, {
  FormDropdownType,
  IFormDropdownOption,
} from '../../../../SitePermitCreationForm.store'
import FormDropdownOptionsModalStore from './FormDropdownOptionsModal.store'
import FormCompanyPicker from './components/FormCompanyPicker'
import FormDropdownOptionsList from './components/FormDropdownOptionsList'
import FormLocationPicker from './components/FormLocationPicker'
import FormUserPicker from './components/FormUserPicker'
import FormWorkflowPicker from './components/FormWorkflowPicker'

import './FormDropdownOptionsModal.scss'

// localization: translated

interface IProps {
  store: SitePermitCreationFormStore

  isShown: boolean

  state?: InitialState
}

const DEFAULT_ROW_HEIGHT = 48

const isCorrectLocationType = (locationType: LocationType): boolean =>
  locationType && locationType !== LocationType.OffloadingEquipment

@inject('state')
@observer
export default class FormDropdownOptionsModal extends React.Component<IProps> {
  private readonly cellMeasurerCache: CellMeasurerCache = null
  private readonly selectionStore: FormDropdownOptionsModalStore

  public constructor(props: IProps) {
    super(props)

    this.cellMeasurerCache = new CellMeasurerCache({
      defaultHeight: DEFAULT_ROW_HEIGHT,
      fixedWidth: true,
    })

    this.selectionStore = new FormDropdownOptionsModalStore(
      this.props.store.selectedField,
      this.props.store.selectedFieldValues,
    )
  }

  public componentDidUpdate(prevProps: IProps): void {
    if (prevProps.isShown !== this.props.isShown) {
      this.selectionStore.reset(
        this.props.store.selectedField,
        this.props.store.selectedFieldValues,
      )
    }
  }

  public render() {
    const { state, store, isShown } = this.props
    const onOutsideClickHandler = isShown ? this.onModalClose : NOOP

    return (
      <MenuCloser
        className={classList({
          'form-dropdown-options-modal': true,
          'is-shown': isShown,
        })}
        closeMenu={onOutsideClickHandler}
      >
        <BaseCompactPopup
          childrenClassName="full-height overflow-hidden"
          titleClassName="text center large bold two-line-text-ellipsis"
          title={this.title}
          isShown={isShown}
          onHide={this.onModalClose}
          shouldIncludeSearch={this.isSearchBarShown}
          searchPlaceholder={this.searchPlaceholder}
          searchValue={store.modalSearchValue}
          onSearchValueChange={this.onSearchValueChange}
          onSearchValueClear={this.clearSearchValue}
          actionButtonTitle={
            this.isApplyButtonShown && Localization.translator.apply
          }
          onActionButtonClick={this.handleSelectionApply.bind(this)}
          isActionButtonEnabled
        >
          <div className="col full-height">
            {state.isLoading ? <Loader /> : this.renderOptions()}
          </div>
        </BaseCompactPopup>
      </MenuCloser>
    )
  }

  private renderOptions(): JSX.Element {
    if (!this.props.isShown) return null

    const { store } = this.props

    if (this.isLocationPickerShown) {
      return <FormLocationPicker store={store} />
    }
    if (this.isUserPickerShown) {
      return (
        <FormUserPicker store={store} selectionStore={this.selectionStore} />
      )
    }
    if (this.isCompanyPickerShown) {
      return <FormCompanyPicker store={store} />
    }
    if (this.isWorkflowPickerShown) {
      return <FormWorkflowPicker store={store} />
    }

    return (
      <FormDropdownOptionsList
        cellMeasurerCache={this.cellMeasurerCache}
        dropdownOptions={this.dropdownOptions}
        scrollToIndex={this.selectedOptionIndex}
        isMultiple={this.isMultiple}
        onOptionClick={this.optionClickHandler}
      />
    )
  }

  private isOptionSelected = (option: IFormDropdownOption): boolean => {
    const { selectedFieldValues, selectedFieldDropdownType, template } =
      this.props.store
    const selectedFieldValue = selectedFieldValues[this.selectedFieldIndex]

    switch (selectedFieldDropdownType) {
      case FormDropdownType.MATERIAL:
        return (
          (selectedFieldValue as IFormMaterial)?.materialId === option.value
        )
      case FormDropdownType.PROCUREMENT_ID:
        return (
          (selectedFieldValue as IFormMaterial)?.procurementId === option.value
        )
      case FormDropdownType.DEFAULT:
        return this.isMeasure
          ? (selectedFieldValues as IMeasure[]).some(
              v => v?.units === option.value,
            )
          : this.selectedValues.includes(option.value)
      case FormDropdownType.FORM_TYPE:
        return template?.id === option.value

      default:
        return false
    }
  }

  private onModalClose = () => {
    const { resetSelectedField, hideFormTypeSelector } = this.props.store
    resetSelectedField()
    hideFormTypeSelector()
    this.clearSearchValue()

    this.clearCellMeasurerCache()
  }

  private onSearchValueChange = (newValue: string) => {
    this.props.store.updateSearchValue(newValue)
    this.clearCellMeasurerCache()
  }

  private clearSearchValue = () => {
    this.props.store.updateSearchValue(EMPTY_STRING)
    this.clearCellMeasurerCache()
  }

  private handleApply(value: string) {
    const {
      changeSelectedFieldValue,
      isTypeSelectorShown,
      hideFormTypeSelector,
      onPermitTypeChanged,
    } = this.props.store

    if (isTypeSelectorShown) {
      onPermitTypeChanged(value)
      hideFormTypeSelector()
    } else {
      changeSelectedFieldValue(value)
    }

    this.clearSearchValue()
    this.clearCellMeasurerCache()
  }

  private handleSelectionApply() {
    const { changeSelectedFieldValue } = this.props.store
    changeSelectedFieldValue(this.selectedValues)

    this.clearSearchValue()
    this.clearCellMeasurerCache()
  }

  private optionClickHandler = (value: string) => {
    if (this.isSelectionUsed) {
      return this.selectionStore.onOptionClick(value)
    }

    return this.handleApply(value)
  }

  private clearCellMeasurerCache = () => {
    this.cellMeasurerCache.clearAll()
  }

  private get selectedFieldName(): string {
    const { selectedFieldDropdownType, selectedSubField } = this.props.store
    switch (selectedFieldDropdownType) {
      case FormDropdownType.PROCUREMENT_ID:
        return Localization.translator.procurementID
      case FormDropdownType.FORM_TYPE:
        return Localization.translator.formType
      default:
        return selectedSubField?.caption || this.selectedField?.caption
    }
  }

  private get title(): string {
    return `${Localization.translator.select} ${this.formattedFieldCaption}`
  }

  private get searchPlaceholder(): string {
    if (this.props.state.isLoading) {
      return `${Localization.translator.loading}...`
    }

    return `${Localization.translator.search} ${this.formattedFieldCaption}`
  }

  private get formattedFieldCaption(): string {
    const { selectedLocationType } = this.props.store
    if (
      this.isLocationPickerShown &&
      isCorrectLocationType(selectedLocationType)
    ) {
      return `${this.selectedFieldName} (${getLocationTypeDisplayName(
        selectedLocationType,
      )})`
    }
    return this.selectedFieldName
  }

  private get dropdownOptions(): IFormDropdownOption[] {
    return this.props.store.dropdownModalOptions.map(opt => ({
      ...opt,
      isSelected: this.isOptionSelected(opt),
    }))
  }

  private get selectedOptionIndex(): number {
    return this.dropdownOptions.findIndex(opt => opt.isSelected)
  }

  private get selectedField(): IPermitTypeField {
    return this.props.store.selectedField
  }

  private get selectedFieldIndex(): number {
    return this.props.store.selectedFieldIndex
  }

  private get isMeasure(): boolean {
    return this.selectedField?.type === PermitFieldType.Measure
  }

  private get isMultiple(): boolean {
    return (
      this.selectedField?.type !== PermitFieldType.Material &&
      this.selectedField?.isMultiple
    )
  }

  private get isSearchBarShown(): boolean {
    return (
      !this.isLocationPickerShown &&
      !this.isCompanyPickerShown &&
      !this.isUserPickerShown
    )
  }

  private get isLocationPickerShown(): boolean {
    return this.props.store.isLocationPickerDisplayed
  }

  private get isCompanyPickerShown(): boolean {
    return this.props.store.isCompanyPickerDisplayed
  }

  private get isWorkflowPickerShown(): boolean {
    return this.props.store.isWorkflowPickerDisplayed
  }

  private get isUserPickerShown(): boolean {
    return this.props.store.isUserPickerDisplayed
  }

  private get isSelectPickerShown(): boolean {
    return this.selectedField?.type === PermitFieldType.Select
  }

  private get selectedValues(): string[] {
    return this.selectionStore.values
  }

  private get isApplyButtonShown(): boolean {
    return this.isUserPickerShown || this.isSelectPickerShown
  }

  private get isSelectionUsed(): boolean {
    return this.isUserPickerShown || this.isSelectPickerShown
  }
}

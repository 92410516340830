import {
  IAttachment,
  IChecklistAnswerItem,
  IFormMaterial,
  IMeasure,
  IPermitFieldsInput,
  IRichText,
  ISiteLocation,
  ITablePermitFieldInput,
  PermitFieldType,
} from '~/client/graph'

import { locationPermitFieldTypes } from '../constants/PermitFieldTypeConstants'
import PermitField from '../models/PermitField'

const objectIdFieldTypes = [
  PermitFieldType.Company,
  PermitFieldType.Assignee,
  PermitFieldType.Requester,
  PermitFieldType.Subscriber,
  PermitFieldType.User,
  PermitFieldType.CompanyInformational,
  PermitFieldType.LinkedWorkflow,
]
const dateTimeFieldTypes = [PermitFieldType.AdditionalDate]
const stringFieldTypes = [
  PermitFieldType.Select,
  PermitFieldType.InputText,
  PermitFieldType.InputTextArea,
  PermitFieldType.InputNumber,
  PermitFieldType.Email,
  PermitFieldType.PhoneNumber,
  PermitFieldType.Time,
]
const checklistFieldTypes = [
  PermitFieldType.Checklist,
  PermitFieldType.Question,
]

const dtoFieldFilter = (field: PermitField): PermitField => {
  field.values = field.values.filter(value => !!value)
  return field
}

const dtoLocationFieldFilter = (field: PermitField): PermitField => {
  field.values = field.values.filter(value => {
    const loc = value as ISiteLocation
    return !!loc?.id && !!loc?.type
  })
  return field
}

const dtoMeasureFieldFilter = (field: PermitField): PermitField => {
  field.values = field.values.filter(value => {
    const measure = value as IMeasure
    return !!measure?.units || !!measure?.value
  })
  return field
}

const dtoAttachmentFieldFilter = (field: PermitField): PermitField => {
  field.values = field.values.filter(value => {
    const att = value as IAttachment
    return !!att?.size && !!att?.url && !!att?.fileName
  })
  return field
}

const dtoChecklistFieldFilter = (field: PermitField): PermitField => {
  field.values = field.values.filter(value => {
    const att = value as IChecklistAnswerItem
    return !!att?.checklistItemId
  })
  return field
}

const dtoTableFieldFilter = (field: PermitField): ITablePermitFieldInput => {
  field.values = (field.values as PermitField[][]).filter(
    row => !!row.length && row.filter(f => f.values.length),
  )
  return {
    fieldId: field.fieldId,
    type: field.type,
    values: (field.values as PermitField[][]).map(fields =>
      getPermitFieldsDto(fields, true),
    ),
  }
}

const dtoMaterialFieldFilter = (field: PermitField): PermitField => {
  field.values = field.values.filter(value => {
    const material = value as IFormMaterial
    return !!material?.materialId
  })
  return field
}

const dtoRichTextFieldFilter = (field: PermitField): PermitField => {
  field.values = field.values.filter(value => {
    const richText = value as IRichText
    return !!richText?.content || !!richText?.attachments?.length
  })
  return field
}

const getFieldsDtos = (
  fields: PermitField[],
  types: PermitFieldType[],
  dtoValuesFilterer: (field: PermitField) => any,
): any[] => {
  return fields.reduce((list, field) => {
    if (types.includes(field.type)) {
      const dtoField = dtoValuesFilterer(field)
      if (dtoField.values.length) {
        list.push(dtoField)
      }
    }
    return list
  }, [] as any[])
}

const getPermitFieldsDto = (
  fields: PermitField[],
  shouldSkipTable?: boolean,
): IPermitFieldsInput => {
  return {
    objectIdFields: getFieldsDtos(fields, objectIdFieldTypes, dtoFieldFilter),
    dateTimeFields: getFieldsDtos(fields, dateTimeFieldTypes, dtoFieldFilter),
    attachmentFields: getFieldsDtos(
      fields,
      [PermitFieldType.File],
      dtoAttachmentFieldFilter,
    ),
    locationFields: getFieldsDtos(
      fields,
      locationPermitFieldTypes,
      dtoLocationFieldFilter,
    ),
    stringFields: getFieldsDtos(fields, stringFieldTypes, dtoFieldFilter),
    checklistAnswerItemFields: getFieldsDtos(
      fields,
      checklistFieldTypes,
      dtoChecklistFieldFilter,
    ),
    measureFields: getFieldsDtos(
      fields,
      [PermitFieldType.Measure],
      dtoMeasureFieldFilter,
    ),
    tableFields: shouldSkipTable
      ? []
      : getFieldsDtos(fields, [PermitFieldType.Table], dtoTableFieldFilter),
    materialFields: getFieldsDtos(
      fields,
      [PermitFieldType.Material],
      dtoMaterialFieldFilter,
    ),
    richTextFields: getFieldsDtos(
      fields,
      [PermitFieldType.RichText],
      dtoRichTextFieldFilter,
    ),
  }
}

export default getPermitFieldsDto

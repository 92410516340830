import * as React from 'react'

import { Radio, RadioGroup } from '@blueprintjs/core'
import { observer } from 'mobx-react'

import MobileActivityPresetLabel from '~/client/src/mobile/views/ActivityList/components/ActvityFiltersPage/components/ActivityPresetSelector/components/ActivityPresetLabel/MobileActivityPresetLabel'
import MobileActivityPresetsSelectorHeader from '~/client/src/mobile/views/ActivityList/components/ActvityFiltersPage/components/ActivityPresetSelector/components/MobileActivityPresetsSelectorHeader'
import ActivityPreset from '~/client/src/shared/models/ActivityPreset'

interface IProps {
  selectedPresetId: string
  presets: ActivityPreset[]
  onChange: (event: any) => void
  onReset: () => void
}

@observer
export default class MobileActivityPresetsSelector extends React.Component<IProps> {
  public render() {
    const { onReset } = this.props
    return (
      <div>
        <div className="row no-flex-children x-between y-center bb-light-grey pb10">
          <MobileActivityPresetsSelectorHeader onReset={onReset} />
        </div>
        <div className="mb20 no-outline-container">{this.content()}</div>
      </div>
    )
  }

  private content() {
    const { presets } = this.props
    if (!presets.length) {
      return 'There are no saved activity presets'
    }

    const { onChange, selectedPresetId } = this.props
    return (
      <RadioGroup
        className="theme-radio-group"
        onChange={onChange}
        selectedValue={selectedPresetId}
      >
        {presets.map(preset => (
          <Radio key={preset.id} value={preset.id}>
            <MobileActivityPresetLabel activityPreset={preset} />
          </Radio>
        ))}
      </RadioGroup>
    )
  }
}

import { action, observable } from 'mobx'

import * as e from '~/client/src/shared/stores/EventStore/eventConstants'

import PermitType from '../../models/PermitType'
import EventsStore from '../../stores/EventStore/Events.store'
import PermitTypesStore from '../../stores/domain/PermitTypes.store'
import CommonStore from '../../stores/ui/Common.store'

export default class PermitTypeSelectorStore {
  @observable public selectedPermitTypeId: string = null

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly permitTypesStore: PermitTypesStore,
    private readonly commonStore: CommonStore,
  ) {}

  public get isLoading(): boolean {
    const { loading } = this.eventsStore.appState
    return (
      loading.get(e.INIT_AUTH_USER) ||
      loading.get(e.LOGIN_WITH_INVITE_KEY) ||
      loading.get(e.INIT_APP) ||
      loading.get(e.INIT_APP_2) ||
      loading.get(e.LOAD_AND_LISTEN_TO_PERMIT_TYPES) ||
      loading.get(e.LOAD_AND_LISTEN_TO_FORM_CATEGORIES)
    )
  }

  public get permitTypes(): PermitType[] {
    return this.permitTypesStore.enabledTypes
  }

  @action.bound
  public selectPermitType(permitTypeId: string) {
    this.selectedPermitTypeId = permitTypeId
  }

  @action.bound
  public applySelection() {
    if (this.selectedPermitTypeId) {
      this.eventsStore.appState.preSelectedPermitTypeId =
        this.selectedPermitTypeId
      this.commonStore.displayHomeView()
    }
  }
}

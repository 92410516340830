export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Byte: any
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: any
  Decimal: any
  Guid: any
  Long: any
  MillisecondsDate: any
  ObjectId: any
  Short: any
}

export interface IActivitiesConfigurations {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Order of maps. */
  maps?: Maybe<Array<Maybe<IOrderedSitemap>>>
  /** Saved activity filter groups. */
  savedActivityGroups?: Maybe<Array<Maybe<IFilterGroup>>>
}

export interface IActivitiesConfigurationsChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IActivitiesConfigurations>
}

export interface IActivitiesConfigurationsInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Order of maps. */
  maps?: Maybe<Array<Maybe<IOrderedSitemapInput>>>
  /** Saved delivery filter groups. */
  savedActivityGroups?: Maybe<Array<Maybe<IFilterGroupInput>>>
}

export interface IActivitiesConfigurationsList {
  data: Array<IActivitiesConfigurations>
  page: IPageInfo
}

export interface IActivity {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Schedule id. */
  scheduleId: Scalars['ObjectId']
  /** Entity id specified in the schedule file. */
  xerEntityId?: Maybe<Scalars['String']>
  /** Schedule entity name. */
  name: Scalars['String']
  /** P6 code being used as Id. */
  code: Scalars['String']
  /** Is activity deleted. */
  isDeleted: Scalars['Boolean']
  /** Planned start date. */
  plannedStartDate?: Maybe<Scalars['MillisecondsDate']>
  /** Planned finish date. */
  plannedFinishDate?: Maybe<Scalars['MillisecondsDate']>
  /** Actual start date. */
  actualStartDate?: Maybe<Scalars['MillisecondsDate']>
  /** Actual finish date. */
  actualFinishDate?: Maybe<Scalars['MillisecondsDate']>
  /** Remaining early start date. */
  remainingEarlyStartDate?: Maybe<Scalars['MillisecondsDate']>
  /** Remaining early finish date. */
  remainingEarlyFinishDate?: Maybe<Scalars['MillisecondsDate']>
  /** Activity complete percent. */
  percentComplete: Scalars['Int']
  /** Total float. */
  totalFloat: Scalars['Float']
  /** Activity status. */
  status: ActivityStatus
  /** Activity on time status. */
  onTimeStatus?: Maybe<OnTimeStatus>
  /** Work breakdown structure id. */
  workBreakdownStructureId?: Maybe<Scalars['ObjectId']>
  /** Resource id. */
  resourceId?: Maybe<Scalars['ObjectId']>
  /** Calendar id. */
  calendarId?: Maybe<Scalars['ObjectId']>
  /** Company id. */
  companyId?: Maybe<Scalars['ObjectId']>
  /** Requester id. */
  requesterId?: Maybe<Scalars['ObjectId']>
  /** Activity linking settings. */
  activityLinkingSettings?: Maybe<IActivityLinkingSettings>
  /** Locations. */
  locations?: Maybe<Array<Maybe<ISiteLocation>>>
}

export interface IActivityAssignment {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Activity id. */
  entityId: Scalars['String']
  /** IsDeleted value */
  isDeleted?: Maybe<Scalars['Boolean']>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Recipients. */
  recipients: Array<Maybe<IRecipients>>
}

export interface IActivityAssignmentChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IActivityAssignment>
}

export interface IActivityAssignmentInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Activity id. */
  entityId: Scalars['String']
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Recipients. */
  recipients: Array<Maybe<IRecipientsInput>>
}

export interface IActivityAssignmentList {
  data: Array<IActivityAssignment>
  page: IPageInfo
}

export interface IActivityChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IActivity>
}

export interface IActivityCode {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Schedule id. */
  scheduleId: Scalars['ObjectId']
  /** Entity id specified in the schedule file. */
  xerEntityId?: Maybe<Scalars['String']>
  /** Schedule entity name. */
  name: Scalars['String']
  /** Short name. */
  shortName?: Maybe<Scalars['String']>
  /** Count. */
  count?: Maybe<Scalars['Int']>
  /** Parent activity code id. */
  parentId?: Maybe<Scalars['ObjectId']>
  /** Activity code type id. */
  typeId?: Maybe<Scalars['ObjectId']>
}

export interface IActivityCodeChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IActivityCode>
}

export interface IActivityCodeDataInput {
  /** Activity code type name. */
  activityCodeType: Scalars['String']
  /** Activity codes. */
  activityCodes: Array<Maybe<Scalars['String']>>
}

export interface IActivityCodeList {
  data: Array<IActivityCode>
  page: IPageInfo
}

export interface IActivityCodeRelationship {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Schedule id. */
  scheduleId: Scalars['ObjectId']
  /** Entity id specified in the schedule file. */
  xerEntityId?: Maybe<Scalars['String']>
  /** Activity code type id. */
  activityCodeTypeId: Scalars['ObjectId']
  /** IsDeleted value */
  isDeleted?: Maybe<Scalars['Boolean']>
  /** Activity code id. */
  activityCodeId: Scalars['ObjectId']
  /** Activity p6 code. */
  activityP6Code: Scalars['String']
}

export interface IActivityCodeRelationshipChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IActivityCodeRelationship>
}

export interface IActivityCodeRelationshipInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Schedule id. */
  scheduleId: Scalars['ObjectId']
  /** Entity id specified in the schedule file. */
  xerEntityId?: Maybe<Scalars['String']>
  /** Activity code type id. */
  activityCodeTypeId: Scalars['ObjectId']
  /** Activity code id. */
  activityCodeId: Scalars['ObjectId']
  /** Activity p6 code. */
  activityP6Code?: Maybe<Scalars['String']>
}

export interface IActivityCodeRelationshipList {
  data: Array<IActivityCodeRelationship>
  page: IPageInfo
}

export interface IActivityCodeType {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Schedule id. */
  scheduleId: Scalars['ObjectId']
  /** Entity id specified in the schedule file. */
  xerEntityId?: Maybe<Scalars['String']>
  /** Schedule entity name. */
  name: Scalars['String']
  /** Scope. */
  scope?: Maybe<Scalars['String']>
}

export interface IActivityCodeTypeChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IActivityCodeType>
}

export interface IActivityCodeTypeList {
  data: Array<IActivityCodeType>
  page: IPageInfo
}

export interface IActivityDataInput {
  /** Activity to add. */
  activity: IActivityInput
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Message to add. */
  message?: Maybe<ISaveMessageInput>
  /** Activity resource name. */
  resource?: Maybe<Scalars['String']>
  /** Activity codes data to add. */
  activityCodeData?: Maybe<Array<Maybe<IActivityCodeDataInput>>>
}

export interface IActivityFilter {
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Values. */
  values?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface IActivityFilterInput {
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Values. */
  values?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface IActivityFiltersSettings {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  filterInfos?: Maybe<Array<Maybe<IFilterInfo>>>
  /** Schedule Id. */
  scheduleId: Scalars['ObjectId']
  hiddenActivityCodeTypesIds: Array<Maybe<Scalars['String']>>
  hiddenActivityCodeIds: Array<Maybe<Scalars['String']>>
}

export interface IActivityFiltersSettingsInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  filterInfos?: Maybe<Array<Maybe<IFilterInfoInput>>>
  /** Schedule Id. */
  scheduleId: Scalars['ObjectId']
  hiddenActivityCodeTypesIds: Array<Maybe<Scalars['String']>>
  hiddenActivityCodeIds: Array<Maybe<Scalars['String']>>
}

export interface IActivityFollowing {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Entity Id. */
  entityId: Scalars['String']
  /** User Id. */
  userId: Scalars['ObjectId']
}

export interface IActivityFollowingChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IActivityFollowing>
}

export interface IActivityFollowingInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Entity Id. */
  entityId: Scalars['String']
  /** User Id. */
  userId: Scalars['ObjectId']
}

export interface IActivityFollowingList {
  data: Array<IActivityFollowing>
  page: IPageInfo
}

export interface IActivityInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Schedule id. */
  scheduleId: Scalars['ObjectId']
  /** Entity id specified in the schedule file. */
  xerEntityId?: Maybe<Scalars['String']>
  /** Schedule entity name. */
  name: Scalars['String']
  /** P6 code being used as Id. */
  code: Scalars['String']
  /** Planned start date. */
  plannedStartDate?: Maybe<Scalars['MillisecondsDate']>
  /** Planned finish date. */
  plannedFinishDate?: Maybe<Scalars['MillisecondsDate']>
  /** Actual start date. */
  actualStartDate?: Maybe<Scalars['MillisecondsDate']>
  /** Actual finish date. */
  actualFinishDate?: Maybe<Scalars['MillisecondsDate']>
  /** Remaining early start date. */
  remainingEarlyStartDate?: Maybe<Scalars['MillisecondsDate']>
  /** Remaining early finish date. */
  remainingEarlyFinishDate?: Maybe<Scalars['MillisecondsDate']>
  /** Activity complete percent. */
  percentComplete?: Maybe<Scalars['Int']>
  /** Total float. */
  totalFloat?: Maybe<Scalars['Float']>
  /** Activity status. */
  status: ActivityStatus
  /** Activity on time status. */
  onTimeStatus?: Maybe<OnTimeStatus>
  /** Work breakdown structure id. */
  workBreakdownStructureId?: Maybe<Scalars['ObjectId']>
  /** Resource id. */
  resourceId?: Maybe<Scalars['ObjectId']>
  /** Calendar id. */
  calendarId?: Maybe<Scalars['ObjectId']>
  /** Company id. */
  companyId?: Maybe<Scalars['ObjectId']>
  /** Requester id. */
  requesterId?: Maybe<Scalars['ObjectId']>
  /** Activity linking settings. */
  activityLinkingSettings?: Maybe<IActivityLinkingSettingsInput>
  /** Locations. */
  locations?: Maybe<Array<Maybe<ISiteLocationInput>>>
}

export interface IActivityLinkingSettings {
  /** Is level linking active. */
  isLevelLinkingActive?: Maybe<Scalars['Boolean']>
  /** Is building linking active. */
  isBuildingLinkingActive?: Maybe<Scalars['Boolean']>
  /** Is company linking active. */
  isCompanyLinkingActive?: Maybe<Scalars['Boolean']>
  /** Is zone linking active. */
  isZoneLinkingActive?: Maybe<Scalars['Boolean']>
}

export interface IActivityLinkingSettingsInput {
  /** Is level linking active. */
  isLevelLinkingActive?: Maybe<Scalars['Boolean']>
  /** Is building linking active. */
  isBuildingLinkingActive?: Maybe<Scalars['Boolean']>
  /** Is company linking active. */
  isCompanyLinkingActive?: Maybe<Scalars['Boolean']>
  /** Is zone linking active. */
  isZoneLinkingActive?: Maybe<Scalars['Boolean']>
}

export interface IActivityListCustomFilter {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** User Id. */
  userId?: Maybe<Scalars['ObjectId']>
  /** Is public. */
  isPublic: Scalars['Boolean']
  /** ActivityFilters by filter type. */
  filtersByFilterType?: Maybe<Array<Maybe<IActivityFilter>>>
  /** Category names. */
  appliedCategories?: Maybe<Array<Maybe<CategoryName>>>
  /** Recently updated saved filter. */
  recentlyUpdatedSavedFilter?: Maybe<IRecentlyUpdatedSavedFilter>
  /** Status filter mode. */
  statusFilterMode?: Maybe<ActivityStatus>
}

export interface IActivityListCustomFilterInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** User Id. */
  userId?: Maybe<Scalars['ObjectId']>
  /** Is public. */
  isPublic: Scalars['Boolean']
  /** ActivityFilters by filter type. */
  filtersByFilterType?: Maybe<Array<Maybe<IActivityFilterInput>>>
  /** Category names. */
  appliedCategories?: Maybe<Array<Maybe<CategoryName>>>
  /** Recently updated saved filter. */
  recentlyUpdatedSavedFilter?: Maybe<IRecentlyUpdatedSavedFilterInput>
  /** Status filter mode. */
  statusFilterMode?: Maybe<ActivityStatus>
}

export interface IActivityListCustomFilterList {
  data: Array<IActivityListCustomFilter>
  page: IPageInfo
}

export interface IActivityLocation {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Activity code Id. In most cases it's an ActivityCode itself. */
  activityCodeId: Scalars['String']
  /** Location object id. */
  locationObjectId?: Maybe<Scalars['String']>
  /** Location object type. */
  locationObjectType?: Maybe<LocationType>
}

export interface IActivityLocationChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IActivityLocation>
}

export interface IActivityLocationInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Activity code Id. In most cases it's an ActivityCode itself. */
  activityCodeId: Scalars['String']
  /** Location object id. */
  locationObjectId?: Maybe<Scalars['String']>
  /** Location object type. */
  locationObjectType?: Maybe<LocationType>
}

export interface IActivityLocationList {
  data: Array<IActivityLocation>
  page: IPageInfo
}

export interface IActivityPreset {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Activities. */
  activities?: Maybe<Array<Maybe<Scalars['String']>>>
  /** User Id. */
  userId?: Maybe<Scalars['ObjectId']>
}

export interface IActivityPresetChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IActivityPreset>
}

export interface IActivityPresetInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Activities. */
  activities: Array<Maybe<Scalars['String']>>
  /** User Id. */
  userId: Scalars['ObjectId']
}

export interface IActivityPresetList {
  data: Array<IActivityPreset>
  page: IPageInfo
}

export interface IActivityResourceRelationship {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Schedule id. */
  scheduleId: Scalars['ObjectId']
  /** Entity id specified in the schedule file. */
  xerEntityId?: Maybe<Scalars['String']>
  /** IsDeleted value */
  isDeleted: Scalars['Boolean']
  /** Activity p6 code. */
  activityP6Code: Scalars['String']
  /** Resource id. */
  resourceId: Scalars['ObjectId']
}

export interface IActivityResourceRelationshipChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IActivityResourceRelationship>
}

export interface IActivityResourceRelationshipList {
  data: Array<IActivityResourceRelationship>
  page: IPageInfo
}

export enum ActivityStatus {
  NotStarted = 'Not_Started',
  InProgress = 'In_Progress',
  Completed = 'Completed',
  DueToday = 'Due_Today',
  DueTomorrow = 'Due_Tomorrow',
  LateFinish = 'Late_Finish',
  LateStart = 'Late_Start',
  StartsToday = 'Starts_Today',
  Done = 'Done',
  Incomplete = 'Incomplete',
}

export interface IAddressBounds {
  /** Top left point. */
  ne?: Maybe<ILatLng>
  /** Bottom right point. */
  sw?: Maybe<ILatLng>
}

export interface IAddressBoundsInput {
  /** Top left point. */
  ne?: Maybe<ILatLngInput>
  /** Bottom right point. */
  sw?: Maybe<ILatLngInput>
}

export interface IAnalyticSettingsField {
  /** Field id. */
  id?: Maybe<Scalars['ObjectId']>
  /** Field name */
  name?: Maybe<Scalars['String']>
  /** Field type */
  type?: Maybe<Scalars['String']>
  /** Whether field is enabled. */
  isEnabled?: Maybe<Scalars['Boolean']>
  /** Ids of the permit type fields. */
  permitTypeFields?: Maybe<Array<Maybe<IPermitTypeFieldReference>>>
  /** Summary fields of the analytic settings field. */
  summaryFields?: Maybe<Array<Maybe<ISummaryField>>>
}

export interface IAnalyticSettingsFieldInput {
  /** Field id. */
  id?: Maybe<Scalars['ObjectId']>
  /** Field name */
  name?: Maybe<Scalars['String']>
  /** Field type */
  type?: Maybe<Scalars['String']>
  /** Whether field is enabled. */
  isEnabled?: Maybe<Scalars['Boolean']>
  /** Ids of the permit type fields. */
  permitTypeFields?: Maybe<Array<Maybe<IPermitTypeFieldReferenceInput>>>
  /** Summary fields of the analytic settings field. */
  summaryFields?: Maybe<Array<Maybe<ISummaryFieldInput>>>
}

export interface IAnalyticsSettings {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Analytics settings fields. */
  fields?: Maybe<Array<Maybe<IAnalyticSettingsField>>>
}

export interface IAnalyticsSettingsChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IAnalyticsSettings>
}

export interface IAnalyticsSettingsInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Analytics settings fields. */
  fields?: Maybe<Array<Maybe<IAnalyticSettingsFieldInput>>>
}

export interface IAnnouncement {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Start date. */
  startDate: Scalars['MillisecondsDate']
  /** End date. */
  endDate: Scalars['MillisecondsDate']
  /** Is closure. */
  isClosure: Scalars['Boolean']
  /** Location. */
  location?: Maybe<ISiteLocation>
  /** Attachments. */
  attachments?: Maybe<Array<Maybe<IAttachment>>>
  /** Orders. */
  orders?: Maybe<Array<Maybe<IAnnouncementOrder>>>
  /** Content. */
  content?: Maybe<Scalars['String']>
  /** Title. */
  title?: Maybe<Scalars['String']>
  /** Date to appear. */
  dateToAppear?: Maybe<Scalars['MillisecondsDate']>
  /** Relationship. */
  relationship?: Maybe<IAnnouncementRelationship>
}

export interface IAnnouncementAssignment {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** All. */
  all: Scalars['Boolean']
  /** Entity id. */
  entityId?: Maybe<Scalars['ObjectId']>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Recipients. */
  recipients: Array<Maybe<IRecipients>>
}

export interface IAnnouncementAssignmentChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IAnnouncementAssignment>
}

export interface IAnnouncementAssignmentInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** All. */
  all: Scalars['Boolean']
  /** Entity id. */
  entityId?: Maybe<Scalars['ObjectId']>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Recipients. */
  recipients: Array<Maybe<IRecipientsInput>>
}

export interface IAnnouncementAssignmentList {
  data: Array<IAnnouncementAssignment>
  page: IPageInfo
}

export interface IAnnouncementChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IAnnouncement>
}

export interface IAnnouncementFollowing {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Entity Id. */
  entityId: Scalars['ObjectId']
  /** User Id. */
  userId: Scalars['ObjectId']
}

export interface IAnnouncementFollowingChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IAnnouncementFollowing>
}

export interface IAnnouncementFollowingInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Entity Id. */
  entityId: Scalars['ObjectId']
  /** User Id. */
  userId: Scalars['ObjectId']
}

export interface IAnnouncementFollowingList {
  data: Array<IAnnouncementFollowing>
  page: IPageInfo
}

export interface IAnnouncementInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Start date. */
  startDate: Scalars['MillisecondsDate']
  /** End date. */
  endDate: Scalars['MillisecondsDate']
  /** Is closure. */
  isClosure?: Maybe<Scalars['Boolean']>
  /** Location. */
  location?: Maybe<ISiteLocationInput>
  /** Attachments. */
  attachments?: Maybe<Array<Maybe<IAttachmentInput>>>
  /** Orders. */
  orders?: Maybe<Array<Maybe<IAnnouncementOrderInput>>>
  /** Content. */
  content?: Maybe<Scalars['String']>
  /** Title. */
  title?: Maybe<Scalars['String']>
  /** Date to appear. */
  dateToAppear?: Maybe<Scalars['MillisecondsDate']>
  /** Relationship. */
  relationship?: Maybe<IAnnouncementRelationshipInput>
}

export interface IAnnouncementList {
  data: Array<IAnnouncement>
  page: IPageInfo
}

export interface IAnnouncementOrder {
  /** Order. */
  order: Scalars['Int']
  /** Date. */
  date?: Maybe<Scalars['MillisecondsDate']>
}

export interface IAnnouncementOrderInput {
  /** Order. */
  order: Scalars['Int']
  /** Date. */
  date?: Maybe<Scalars['MillisecondsDate']>
}

export enum AnnouncementRelationType {
  Delivery = 'Delivery',
  Permit = 'Permit',
  Activity = 'Activity',
}

export interface IAnnouncementRelationship {
  /** Foreign key Id. */
  foreignKeyId: Scalars['ObjectId']
  /** Type. */
  type?: Maybe<AnnouncementRelationType>
}

export interface IAnnouncementRelationshipInput {
  /** Foreign key Id. */
  foreignKeyId: Scalars['ObjectId']
  /** Type. */
  type?: Maybe<AnnouncementRelationType>
}

export interface IAnnouncementWithAssignmentInput {
  /** Announcement. */
  announcement: IAnnouncementInput
  /** Announcement assignment. */
  assignment?: Maybe<IAnnouncementAssignmentInput>
}

export interface IArea extends ILocation {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateInterval>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Delivery hierarchy parent. */
  parent?: Maybe<IDeliveryHierarchyParent>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<LocationType>
  closingDate?: Maybe<Scalars['Long']>
}

export interface IAreaInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateIntervalInput>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Location parent. */
  parent?: Maybe<IDeliveryHierarchyParentInput>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
}

export interface IAssociatedCode {
  /** QR Code Name. */
  name: Scalars['String']
  /** Code Id. */
  id: Scalars['String']
}

export interface IAssociatedCodeInput {
  /** QR Code Name. */
  name: Scalars['String']
  /** Code Id. */
  id: Scalars['String']
}

export interface IAttachment {
  /** Size. */
  size: Scalars['String']
  /** Url. */
  url: Scalars['String']
  /** File name. */
  fileName: Scalars['String']
}

export interface IAttachmentInput {
  /** Size. */
  size?: Maybe<Scalars['String']>
  /** Url. */
  url?: Maybe<Scalars['String']>
  /** File name. */
  fileName?: Maybe<Scalars['String']>
}

export interface IAttachmentPermitField extends IPermitFieldInterface {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  attachmentValues?: Maybe<Array<Maybe<IAttachment>>>
}

export interface IAttachmentPermitFieldInput {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  values?: Maybe<Array<Maybe<IAttachmentInput>>>
}

export interface IBasemap {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Source. */
  source?: Maybe<Scalars['String']>
  /** File name. */
  fileName?: Maybe<Scalars['String']>
}

export interface IBasemapChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IBasemap>
}

export interface IBasemapInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Source. */
  source?: Maybe<Scalars['String']>
  /** File name. */
  fileName?: Maybe<Scalars['String']>
}

export interface IBasemapList {
  data: Array<IBasemap>
  page: IPageInfo
}

export enum BlockTypeEnum {
  Hide = 'hide',
  Warn = 'warn',
  Block = 'block',
}

export interface IBookingDeadlineOptions {
  /** Deadline interval. */
  deadlineInterval?: Maybe<Scalars['Long']>
  /** Block type. */
  blockType?: Maybe<BlockTypeEnum>
}

export interface IBookingDeadlineOptionsInput {
  /** Deadline interval. */
  deadlineInterval?: Maybe<Scalars['Long']>
  /** Block type. */
  blockType?: Maybe<BlockTypeEnum>
}

export interface IBuilding extends ILocation {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateInterval>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Delivery hierarchy parent. */
  parent?: Maybe<IDeliveryHierarchyParent>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<LocationType>
  closingDate?: Maybe<Scalars['Long']>
  /** Levels order. */
  levelsOrder?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Ground level. */
  groundLevel?: Maybe<Scalars['String']>
  /** Is ground index set to 0. */
  isGroundIndexZero?: Maybe<Scalars['Boolean']>
  /** Skip level index template. */
  skipLevelIndexTemplate?: Maybe<Scalars['String']>
  /** Level base name. */
  levelBaseName?: Maybe<Scalars['String']>
}

export interface IBuildingInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateIntervalInput>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Location parent. */
  parent?: Maybe<IDeliveryHierarchyParentInput>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Levels order. */
  levelsOrder?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Ground level. */
  groundLevel?: Maybe<Scalars['String']>
  /** Is ground index set to 0. */
  isGroundIndexZero?: Maybe<Scalars['Boolean']>
  /** Skip level index template. */
  skipLevelIndexTemplate?: Maybe<Scalars['String']>
  /** Level base name. */
  levelBaseName?: Maybe<Scalars['String']>
}

export interface ICalendar {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Schedule id. */
  scheduleId: Scalars['ObjectId']
  /** Entity id specified in the schedule file. */
  xerEntityId?: Maybe<Scalars['String']>
  /** Schedule entity name. */
  name: Scalars['String']
  /** Calendar type. */
  type: Scalars['String']
  /** Is the calendar default. */
  isDefault: Scalars['Boolean']
  /** Is the calendar personal. */
  isPersonalCalendar: Scalars['Boolean']
  /** Parent calendar id. */
  parentId?: Maybe<Scalars['ObjectId']>
  /** Date last changed. */
  dateLastChanged?: Maybe<Scalars['MillisecondsDate']>
  /** Work hours per day. */
  workHoursPerDay: Scalars['Int']
  /** Work hours per week. */
  workHoursPerWeek: Scalars['Int']
  /** Work hours per month. */
  workHoursPerMonth: Scalars['Int']
  /** Work hours per year. */
  workHoursPerYear: Scalars['Int']
  /** Field definitions of material category. */
  calendarData?: Maybe<ICalendarData>
}

export interface ICalendarData {
  /** Working days. */
  days?: Maybe<Array<Maybe<ICalendarDataDay>>>
  /** Exceptions. */
  exceptions?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export interface ICalendarDataDay {
  /** Week day. */
  weekDay: Scalars['Int']
  /** Start time. */
  start: Scalars['String']
  /** Finish time. */
  finish: Scalars['String']
}

export interface ICalendarList {
  data: Array<ICalendar>
  page: IPageInfo
}

export enum CalendricalType {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}

export interface ICastAssignment {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Delivery id. */
  entityId: Scalars['String']
  /** Recipients. */
  recipients: Array<Maybe<IRecipients>>
}

export interface ICastAssignmentChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ICastAssignment>
}

export interface ICastAssignmentInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Entity id. */
  entityId?: Maybe<Scalars['String']>
  /** Recipients. */
  recipients: Array<Maybe<IRecipientsInput>>
}

export interface ICastAssignmentList {
  data: Array<ICastAssignment>
  page: IPageInfo
}

export interface ICastFollowing {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Entity Id. */
  entityId: Scalars['String']
  /** User Id. */
  userId: Scalars['ObjectId']
}

export interface ICastFollowingChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ICastFollowing>
}

export interface ICastFollowingInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Entity Id. */
  entityId: Scalars['String']
  /** User Id. */
  userId: Scalars['ObjectId']
}

export interface ICastFollowingList {
  data: Array<ICastFollowing>
  page: IPageInfo
}

export interface ICategoryForCompare {
  /** What to compare. */
  categoryName: Scalars['String']
  /** Count before upload. */
  previousCount: Scalars['Int']
  /** Count after upload. */
  uploadCount: Scalars['Int']
}

export enum CategoryName {
  UserAssociated = 'userAssociated',
  Critical = 'critical',
  Flagged = 'flagged',
  Late = 'late',
  Rfi = 'rfi',
  Deliveries = 'deliveries',
  ScheduleComment = 'scheduleComment',
  CategoryOfVariance = 'categoryOfVariance',
  SafetyHazard = 'safetyHazard',
  RecentlyUpdated = 'recentlyUpdated',
  ActualizedFromSchedule = 'actualizedFromSchedule',
  NotCompleted = 'notCompleted',
  Status = 'status',
}

export interface IChangeDeliveriesStatusCommandInput {
  /** Project id. */
  projectId?: Maybe<Scalars['ObjectId']>
  /** Delivery ids. */
  deliveryIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Delivery status. */
  nextStatus?: Maybe<DeliveryStatus>
}

export interface IChecklistAnswerItem {
  /** Checklist item Id. */
  checklistItemId?: Maybe<Scalars['ObjectId']>
  /** Answer. */
  answer?: Maybe<Scalars['Boolean']>
}

export interface IChecklistAnswerItemInput {
  /** Checklist item Id. */
  checklistItemId: Scalars['ObjectId']
  /** Answer. */
  answer?: Maybe<Scalars['Boolean']>
}

export interface IChecklistAnswerItemPermitField extends IPermitFieldInterface {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  checklistValues?: Maybe<Array<Maybe<IChecklistAnswerItem>>>
}

export interface IChecklistAnswerItemPermitFieldInput {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  values?: Maybe<Array<Maybe<IChecklistAnswerItemInput>>>
}

export interface IChecklistItem {
  /** Id. */
  id?: Maybe<Scalars['ObjectId']>
  /** Text. */
  text?: Maybe<Scalars['String']>
  /** Questionnaire type. */
  questionnaireType?: Maybe<Scalars['String']>
}

export interface IChecklistItemInput {
  /** Id. */
  id?: Maybe<Scalars['ObjectId']>
  /** Text. */
  text?: Maybe<Scalars['String']>
  /** Questionnaire type. */
  questionnaireType?: Maybe<Scalars['String']>
}

export interface IClosure {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Location id. */
  locationId?: Maybe<Scalars['ObjectId']>
  /** Closure interval. */
  closureInterval?: Maybe<ILocationClosureInterval>
  /** Associated permit's id. */
  associatedPermitId?: Maybe<Scalars['ObjectId']>
  /** Associated announcement's id. */
  associatedAnnouncementId?: Maybe<Scalars['ObjectId']>
}

export interface IClosureAssignment {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Closure id. */
  entityId: Scalars['ObjectId']
  /** Recipients. */
  recipients: Array<Maybe<IRecipients>>
}

export interface IClosureAssignmentChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IClosureAssignment>
}

export interface IClosureAssignmentInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Entity id. */
  entityId?: Maybe<Scalars['ObjectId']>
  /** Recipients. */
  recipients: Array<Maybe<IRecipientsInput>>
}

export interface IClosureAssignmentList {
  data: Array<IClosureAssignment>
  page: IPageInfo
}

export interface IClosureChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IClosure>
}

export interface IClosureFollowing {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Entity Id. */
  entityId: Scalars['ObjectId']
  /** User Id. */
  userId: Scalars['ObjectId']
}

export interface IClosureFollowingChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IClosureFollowing>
}

export interface IClosureFollowingInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Entity Id. */
  entityId: Scalars['ObjectId']
  /** User Id. */
  userId: Scalars['ObjectId']
}

export interface IClosureFollowingList {
  data: Array<IClosureFollowing>
  page: IPageInfo
}

export interface IClosureInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Location id. */
  locationId?: Maybe<Scalars['ObjectId']>
  /** Closure interval. */
  closureInterval?: Maybe<ILocationClosureIntervalInput>
  /** Associated permit id. */
  associatedPermitId?: Maybe<Scalars['ObjectId']>
  /** Associated announcement id. */
  associatedAnnouncementId?: Maybe<Scalars['ObjectId']>
}

export interface IClosureList {
  data: Array<IClosure>
  page: IPageInfo
}

export interface IColumnConfiguration {
  /** Column id. */
  columnId: Scalars['String']
  /** Is column hidden. */
  isHidden: Scalars['Boolean']
  /** Is column fixed. */
  isFixed: Scalars['Boolean']
}

export interface IColumnConfigurationInput {
  /** Column id. */
  columnId: Scalars['String']
  /** Is column hidden. */
  isHidden: Scalars['Boolean']
  /** Is column fixed. */
  isFixed: Scalars['Boolean']
}

export interface ICompany {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Company name. */
  name: Scalars['String']
  /** Company code. */
  code?: Maybe<Scalars['String']>
  /** Company type tags. */
  typeTags: Array<Maybe<Scalars['ObjectId']>>
  /** Logo URL. */
  logoUrl?: Maybe<Scalars['String']>
  /** Avatar URL. */
  avatarUrl?: Maybe<Scalars['String']>
  /** Business phone. */
  businessPhone?: Maybe<Scalars['String']>
  /** Business email. */
  businessEmail?: Maybe<Scalars['String']>
  /** DBA. */
  dba?: Maybe<Scalars['String']>
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** License number. */
  licenseNumber?: Maybe<Scalars['String']>
  /** Company address. */
  address?: Maybe<ICompanyAddress>
  /** Responsible contact Ids. */
  responsibleContactIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Role Ids. */
  roleIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Trade Ids. */
  tradeIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Reports to id. */
  reportsToId?: Maybe<Scalars['ObjectId']>
  /** StruxHub Company id. */
  struxHubCompanyId?: Maybe<Scalars['ObjectId']>
  /** Company id. */
  value?: Maybe<Scalars['String']>
}

export interface ICompanyAddress {
  /** Address. */
  address?: Maybe<Scalars['String']>
  /** Country. */
  country?: Maybe<Scalars['String']>
  /** City. */
  city?: Maybe<Scalars['String']>
  /** State. */
  state?: Maybe<Scalars['String']>
  /** Zip code. */
  zipCode?: Maybe<Scalars['String']>
}

export interface ICompanyAddressInput {
  /** Address. */
  address?: Maybe<Scalars['String']>
  /** Country. */
  country?: Maybe<Scalars['String']>
  /** City. */
  city?: Maybe<Scalars['String']>
  /** State. */
  state?: Maybe<Scalars['String']>
  /** Zip code. */
  zipCode?: Maybe<Scalars['String']>
}

export interface ICompanyBasicInfo {
  /** Company id. */
  companyId?: Maybe<Scalars['ObjectId']>
  /** Company name. */
  name?: Maybe<Scalars['String']>
}

export interface ICompanyChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ICompany>
}

export interface ICompanyInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Company code. */
  code?: Maybe<Scalars['String']>
  /** Company type. */
  typeTags: Array<Maybe<Scalars['ObjectId']>>
  /** Logo URL. */
  logoUrl?: Maybe<Scalars['String']>
  /** Avatar URL. */
  avatarUrl?: Maybe<Scalars['String']>
  /** Business phone. */
  businessPhone?: Maybe<Scalars['String']>
  /** Business email. */
  businessEmail?: Maybe<Scalars['String']>
  /** DBA. */
  dba?: Maybe<Scalars['String']>
  /** Is deleted. */
  isDeleted?: Maybe<Scalars['Boolean']>
  /** License number. */
  licenseNumber?: Maybe<Scalars['String']>
  /** Company address. */
  address?: Maybe<ICompanyAddressInput>
  /** Responsible contact Ids. */
  responsibleContactIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Role Ids. */
  roleIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Trade Ids. */
  tradeIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Reports to id. */
  reportsToId?: Maybe<Scalars['ObjectId']>
  /** StruxHub company Id. */
  struxHubCompanyId?: Maybe<Scalars['ObjectId']>
}

export interface ICompanyList {
  data: Array<ICompany>
  page: IPageInfo
}

export interface ICompanyRelationship {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Schedule id. */
  scheduleId: Scalars['ObjectId']
  /** Entity id specified in the schedule file. */
  xerEntityId?: Maybe<Scalars['String']>
  /** StruxHub's company id. */
  struxHubCompanyId?: Maybe<Scalars['ObjectId']>
  /** External company id. */
  externalCompanyId?: Maybe<Scalars['ObjectId']>
}

export interface ICompanyRelationshipChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ICompanyRelationship>
}

export interface ICompanyRelationshipInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Schedule id. */
  scheduleId: Scalars['ObjectId']
  /** Entity id specified in the schedule file. */
  xerEntityId?: Maybe<Scalars['String']>
  /** StruxHub's company id. */
  struxHubCompanyId?: Maybe<Scalars['ObjectId']>
  /** External company id. */
  externalCompanyId?: Maybe<Scalars['ObjectId']>
}

export interface ICompanyRelationshipList {
  data: Array<ICompanyRelationship>
  page: IPageInfo
}

export interface ICompanyStatusUpdate {
  /** Company name. */
  companyName?: Maybe<Scalars['String']>
  /** Did update. */
  didUpdate?: Maybe<Scalars['Boolean']>
  /** Is deleted. */
  isDeleted?: Maybe<Scalars['Boolean']>
  /** Man power. */
  manpower?: Maybe<Scalars['Int']>
  /** Man power updated at. */
  manpowerUpdatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** Percent complete. */
  percentComplete?: Maybe<Scalars['Int']>
  /** Percent complete updated at. */
  percentCompleteUpdatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** Updated at. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** User id. */
  userId?: Maybe<Scalars['ObjectId']>
}

export interface ICompanyStatusUpdateInput {
  /** Company name. */
  companyName?: Maybe<Scalars['String']>
  /** Did update. */
  didUpdate?: Maybe<Scalars['Boolean']>
  /** Is deleted. */
  isDeleted?: Maybe<Scalars['Boolean']>
  /** Man power. */
  manpower?: Maybe<Scalars['Int']>
  /** Man power updated at. */
  manpowerUpdatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** Percent complete. */
  percentComplete?: Maybe<Scalars['Int']>
  /** Percent complete updated at. */
  percentCompleteUpdatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** Updated at. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** User id. */
  userId?: Maybe<Scalars['ObjectId']>
}

export interface IConcreteDirectAddress {
  line: Scalars['String']
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zipCode: Scalars['String']
  country: Scalars['String']
}

export interface IConcreteDirectESignature {
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  worksiteWaiverAccepted: Scalars['Boolean']
}

export interface IConcreteDirectIntegrationInput {
  /** Base URL for CD access. */
  cDBaseURL?: Maybe<Scalars['String']>
  /** Login for CD access. */
  cDLogin?: Maybe<Scalars['String']>
  /** Password for CD access. */
  cDPassword?: Maybe<Scalars['String']>
  /** Site Id for CD access. */
  siteId?: Maybe<Scalars['String']>
  /** StruxHub Project Id. */
  projectId?: Maybe<Scalars['ObjectId']>
  /** The sync start date uses for the initial data pull. */
  syncStartDate?: Maybe<Scalars['DateTime']>
  /** Is CD active. */
  isActive?: Maybe<Scalars['Boolean']>
}

export interface IConcreteDirectItem {
  id: Scalars['ID']
  description: Scalars['String']
  status: Scalars['String']
  type: Scalars['String']
  unityOfMeasure: Scalars['String']
  externalId?: Maybe<Scalars['String']>
  systemId: Scalars['String']
  defaultSlump?: Maybe<Scalars['String']>
}

export interface IConcreteDirectOrder {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  concreteDirectId: Scalars['Guid']
  externalId?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['MillisecondsDate']>
  localStartDate?: Maybe<Scalars['MillisecondsDate']>
  timeZone: Scalars['String']
  /** Coordinates. */
  location?: Maybe<IGeoJson2DGeographicCoordinates>
  address?: Maybe<IConcreteDirectAddress>
  deliveryInstructions?: Maybe<Scalars['String']>
  orderItems?: Maybe<Array<Maybe<IConcreteDirectOrderItem>>>
  orderReference?: Maybe<Scalars['String']>
  accountingReference?: Maybe<Scalars['String']>
  orderByName?: Maybe<Scalars['String']>
  orderByPhone?: Maybe<Scalars['String']>
  status: Scalars['String']
  progressPercent: Scalars['Int']
  jobsiteId: Scalars['ID']
  timeWindow: Scalars['Int']
  sentToExternalBackend: Scalars['Boolean']
  systemId: Scalars['String']
  orderCostEstimations?: Maybe<Array<Maybe<IConcreteDirectOrderCostEstimation>>>
  costEstimationCalculated: Scalars['Boolean']
  pricingPlantId: Scalars['ID']
  printBatchWeights: Scalars['Boolean']
  orderedByUserId?: Maybe<Scalars['ID']>
  isPaperless: Scalars['Boolean']
  isReviewNeeded: Scalars['Boolean']
  riskAssessmentQuestions?: Maybe<Array<Maybe<Scalars['String']>>>
  customFields?: Maybe<Array<Maybe<Scalars['String']>>>
  orderedByApp?: Maybe<Scalars['String']>
  isReservation: Scalars['Boolean']
  isManualDispatchAllowed: Scalars['Boolean']
  isWaitingForDispatchSystem: Scalars['Boolean']
  deliveryId?: Maybe<Scalars['ObjectId']>
}

export interface IConcreteDirectOrderChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IConcreteDirectOrder>
}

export interface IConcreteDirectOrderCostEstimation {
  id: Scalars['ID']
  plantId: Scalars['ID']
  travelTimeInMinutes: Scalars['Int']
  calculatedDriveCost: Scalars['Float']
  calculatedDriveCostCurrency: Scalars['String']
}

export interface IConcreteDirectOrderItem {
  id: Scalars['String']
  externalId?: Maybe<Scalars['String']>
  quantity: Scalars['Float']
  isExactQuantity: Scalars['Boolean']
  start?: Maybe<Scalars['MillisecondsDate']>
  localStart?: Maybe<Scalars['MillisecondsDate']>
  spacingInMinutes: Scalars['Int']
  qtyPerHour: Scalars['Float']
  rateType: Scalars['String']
  description: Scalars['String']
  pourMethod?: Maybe<Scalars['String']>
  pourElement?: Maybe<Scalars['String']>
  extendedPourElement?: Maybe<Scalars['String']>
  slump?: Maybe<Scalars['String']>
  payloadSize: Scalars['Float']
  plantId: Scalars['String']
  jobsiteDistanceInMeters: Scalars['Int']
  travelTimeInMinutes: Scalars['Int']
  itemId: Scalars['ID']
  item?: Maybe<IConcreteDirectItem>
  deliveredQuantity: Scalars['Float']
  pouredQuantity: Scalars['Float']
  progressPercent: Scalars['Float']
  holdQty: Scalars['Float']
  currentTicketedQuantity: Scalars['Float']
  currentBatchedQuantity: Scalars['Float']
  actualSpacingInMinutes: Scalars['Int']
  actualQtyPerHour: Scalars['Float']
  orderItemAdditions?: Maybe<Array<Maybe<IConcreteDirectOrderItemAddition>>>
}

export interface IConcreteDirectOrderItemAddition {
  id: Scalars['ID']
  externalId?: Maybe<Scalars['String']>
  itemId: Scalars['ID']
  item?: Maybe<IConcreteDirectItem>
  quantity: Scalars['Float']
}

export interface IConcreteDirectOrderList {
  data: Array<IConcreteDirectOrder>
  page: IPageInfo
}

export interface IConcreteDirectPayload {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  payloadId: Scalars['Guid']
  transportedByVehicleId?: Maybe<Scalars['String']>
  siteId: Scalars['Guid']
  orderId: Scalars['Guid']
  orderItemId: Scalars['Guid']
  quantity: Scalars['Float']
  eta?: Maybe<Scalars['MillisecondsDate']>
  etaQuality?: Maybe<Scalars['String']>
  etaCalculatedAt?: Maybe<Scalars['MillisecondsDate']>
  status: Scalars['String']
  permittedStatuses: Array<Maybe<Scalars['String']>>
  hasESignature: Scalars['Boolean']
  eSignature?: Maybe<IConcreteDirectESignature>
  ticketReference: Scalars['String']
  payloadEvent?: Maybe<IConcreteDirectPayloadEvent>
  cumulativeQuantity: Scalars['Float']
  lastUpdatedAt?: Maybe<Scalars['MillisecondsDate']>
  isReviewed: Scalars['Boolean']
  supportsAdditives: Scalars['Boolean']
  eSignatureMissing: Scalars['Boolean']
  waterContent?: Maybe<IConcreteDirectWaterContent>
  deliveredAt?: Maybe<Scalars['MillisecondsDate']>
  slump?: Maybe<Scalars['String']>
  timeOnSite: Scalars['Int']
  isRetro: Scalars['Boolean']
  isRedirect: Scalars['Boolean']
  returnedQuantity?: Maybe<Scalars['String']>
  totalNoOfTests: Scalars['Int']
  totalNoOfTestsCompleted: Scalars['Int']
  deliveredQuantity?: Maybe<Scalars['Float']>
}

export interface IConcreteDirectPayloadChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IConcreteDirectPayload>
}

export interface IConcreteDirectPayloadEvent {
  typedTime?: Maybe<Scalars['MillisecondsDate']>
  batchStartTime?: Maybe<Scalars['MillisecondsDate']>
  batchEndTime?: Maybe<Scalars['MillisecondsDate']>
  departureTime?: Maybe<Scalars['MillisecondsDate']>
  departureTimeQuality?: Maybe<Scalars['String']>
  arrivalTime?: Maybe<Scalars['MillisecondsDate']>
  pouringStartTime?: Maybe<Scalars['MillisecondsDate']>
  toPlantTime?: Maybe<Scalars['MillisecondsDate']>
  washTime?: Maybe<Scalars['MillisecondsDate']>
}

export interface IConcreteDirectPayloadList {
  data: Array<IConcreteDirectPayload>
  page: IPageInfo
}

export interface IConcreteDirectSqsConfigurationInput {
  /** Access Key for SQS. */
  accessKey?: Maybe<Scalars['String']>
  /** Secret. */
  secret?: Maybe<Scalars['String']>
  /** Queue Url. */
  queueUrl?: Maybe<Scalars['String']>
  /** Aws Region. */
  awsRegion?: Maybe<Scalars['String']>
  /** Message Group Id. */
  messageGroupId?: Maybe<Scalars['String']>
  /** Message Wait Time Seconds. */
  messageWaitTimeSeconds?: Maybe<Scalars['Int']>
  /** Max Number Of Messages. */
  maxNumberOfMessages?: Maybe<Scalars['Int']>
  /** Use Fifo. */
  useFifo?: Maybe<Scalars['Boolean']>
  /** Is Test. */
  isTest?: Maybe<Scalars['Boolean']>
}

export interface IConcreteDirectWaterContent {
  waterCementRatioDesign?: Maybe<Scalars['String']>
  waterCementRatioActual?: Maybe<Scalars['String']>
  designWater?: Maybe<Scalars['String']>
  designWaterUOM?: Maybe<Scalars['String']>
  actualWater?: Maybe<Scalars['String']>
  actualWaterUOM?: Maybe<Scalars['String']>
  waterToAdd?: Maybe<Scalars['String']>
  waterToAddUOM?: Maybe<Scalars['String']>
}

export interface IConditionalField {
  /** Key. */
  key: Scalars['String']
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Values. */
  values: Array<Maybe<IPermitTypeField>>
}

export interface IConditionalFieldInput {
  /** Key. */
  key: Scalars['String']
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Values. */
  values: Array<Maybe<IPermitTypeFieldsInput>>
}

export interface IConfiguration {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** The name of the record. */
  name: Scalars['String']
  /** The value of the configuration. */
  value: Scalars['String']
  /** The description of the configuration. */
  description: Scalars['String']
  /** The collection of tags for this configuration. */
  tags: Array<Maybe<Scalars['String']>>
}

export interface IConfigurationChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IConfiguration>
}

export interface IConstraint {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Building id. */
  buildingId?: Maybe<Scalars['String']>
  /** Is explicit. */
  isExplicit: Scalars['Boolean']
  /** Restricted areas ids. */
  restrictedAreas: Array<Maybe<Scalars['String']>>
  /** Restricted equipment ids. */
  restrictedEquipment: Array<Maybe<Scalars['String']>>
  /** Restricted gates ids. */
  restrictedGates: Array<Maybe<Scalars['String']>>
  /** Restricted levels ids. */
  restrictedLevels: Array<Maybe<Scalars['String']>>
  /** Restricted routes ids. */
  restrictedRoutes: Array<Maybe<Scalars['String']>>
  /** Restricted stagings. */
  restrictedStagings: Array<Maybe<Scalars['String']>>
  /** Restricted interior doors. */
  restrictedInteriorDoors: Array<Maybe<Scalars['String']>>
  /** Restricted interior paths. */
  restrictedInteriorPaths: Array<Maybe<Scalars['String']>>
  /** Zone id. */
  zoneId: Scalars['String']
}

export interface IConstraintChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IConstraint>
}

export interface IConstraintInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Building id. */
  buildingId?: Maybe<Scalars['String']>
  /** Is explicit. */
  isExplicit: Scalars['Boolean']
  /** Restricted areas ids. */
  restrictedAreas: Array<Maybe<Scalars['String']>>
  /** Restricted equipment ids. */
  restrictedEquipment: Array<Maybe<Scalars['String']>>
  /** Restricted gates ids. */
  restrictedGates: Array<Maybe<Scalars['String']>>
  /** Restricted levels ids. */
  restrictedLevels: Array<Maybe<Scalars['String']>>
  /** Restricted routes ids. */
  restrictedRoutes: Array<Maybe<Scalars['String']>>
  /** Restricted stagings. */
  restrictedStagings: Array<Maybe<Scalars['String']>>
  /** Restricted interior doors. */
  restrictedInteriorDoors: Array<Maybe<Scalars['String']>>
  /** Restricted interior paths. */
  restrictedInteriorPaths: Array<Maybe<Scalars['String']>>
  /** Zone id. */
  zoneId: Scalars['String']
}

export interface IConstraintList {
  data: Array<IConstraint>
  page: IPageInfo
}

export interface IContentObject {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  author?: Maybe<Scalars['ObjectId']>
  status?: Maybe<ContentObjectStatus>
  threadId?: Maybe<Scalars['String']>
  activityObjectId?: Maybe<Scalars['String']>
  contentObjectType?: Maybe<ContentObjectType>
  type?: Maybe<Scalars['String']>
}

export interface IContentObjectChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IContentObject>
}

export interface IContentObjectInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  author?: Maybe<Scalars['ObjectId']>
  status?: Maybe<ContentObjectStatus>
  threadId?: Maybe<Scalars['String']>
  activityObjectId?: Maybe<Scalars['String']>
  contentObjectType?: Maybe<ContentObjectType>
  type?: Maybe<Scalars['String']>
}

export interface IContentObjectList {
  data: Array<IContentObject>
  page: IPageInfo
}

export enum ContentObjectStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
}

export enum ContentObjectType {
  CategoryOfVariance = 'CategoryOfVariance',
  RequestForInformation = 'RequestForInformation',
  Flag = 'Flag',
  SafetyHazard = 'SafetyHazard',
  ScheduleComment = 'ScheduleComment',
}

export interface ICopyAllGraphType {
  /** Copy all Companies */
  companies?: Maybe<Scalars['Boolean']>
  /** Copy all Users */
  users?: Maybe<Scalars['Boolean']>
  /** Copy all Forms */
  forms?: Maybe<Scalars['Boolean']>
  /** Copy all Materials */
  materials?: Maybe<Scalars['Boolean']>
}

export interface ICsvFileModelInput {
  /** File. */
  file?: Maybe<Scalars['String']>
  /** Project Id. */
  projectId?: Maybe<Scalars['ObjectId']>
}

export interface IDateInterval {
  startDate: Scalars['Long']
  endDate: Scalars['Long']
}

export interface IDateIntervalInput {
  startDate: Scalars['Long']
  endDate: Scalars['Long']
}

export interface IDateTimePermitField extends IPermitFieldInterface {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  dateTimeValues?: Maybe<Array<Maybe<Scalars['MillisecondsDate']>>>
}

export interface IDateTimePermitFieldInput {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  values?: Maybe<Array<Maybe<Scalars['MillisecondsDate']>>>
}

export enum DefaultCompanyType {
  GeneralContractorPrimary = 'GeneralContractorPrimary',
  GeneralContractorSecondary = 'GeneralContractorSecondary',
  Owner = 'Owner',
  SubContractor = 'SubContractor',
  TieredSubContractor = 'TieredSubContractor',
  Vendor = 'Vendor',
}

export enum DefaultLandingPage {
  Home = 'home',
  Photos = 'photos',
  Deliveries = 'deliveries',
  Tracker = 'tracker',
  Materials = 'materials',
  Analytics = 'analytics',
  Forms = 'forms',
}

export interface IDefaultPermitType {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Permit type name. */
  name?: Maybe<Scalars['String']>
  /** Is enabled. */
  isEnabled: Scalars['Boolean']
  /** Order index. */
  orderIndex: Scalars['Int']
  /** Type (eg 'hotwork', 'ladder', 'energized', 'aerial', 'fireExtinguisher'). */
  type: Scalars['String']
  /** Inspection options. */
  inspectionOptions?: Maybe<IInspectionOptions>
  /** Determines whether the form is deleted. */
  isDeleted?: Maybe<Scalars['Boolean']>
  /** Steps. */
  workflowSteps?: Maybe<Array<Maybe<IWorkflowStep>>>
  /** Is auto activation enabled. */
  isAutoActivationEnabled?: Maybe<Scalars['Boolean']>
  /** Indicates whether the workflow form title is mandatory. */
  isWorkflowTitleMandatory?: Maybe<Scalars['Boolean']>
  /** Booking deadline options. */
  bookingDeadlineOptions?: Maybe<IBookingDeadlineOptions>
  /** IDs of steps for auto-ending. */
  autoEndStepIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Associated category id. Can be null. */
  categoryId?: Maybe<Scalars['ObjectId']>
}

export interface IDefaultPermitTypeList {
  data: Array<IDefaultPermitType>
  page: IPageInfo
}

export interface IDefaultTeam {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Name. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
}

export interface IDefaultTeamList {
  data: Array<IDefaultTeam>
  page: IPageInfo
}

export interface IDeleteUsersInput {
  /** User Id. */
  id?: Maybe<Scalars['ObjectId']>
  /** User Email. */
  email: Scalars['String']
  /** User Phone. */
  phoneNumber: Scalars['String']
}

export interface IDelivery {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Activity Id. */
  activityId?: Maybe<Scalars['String']>
  /** Actual end date. */
  actualEndDate?: Maybe<Scalars['Long']>
  /** Actual start date. */
  actualStartDate?: Maybe<Scalars['Long']>
  /** Booking Id. */
  bookingId?: Maybe<Scalars['String']>
  /** Company id. */
  company?: Maybe<Scalars['ObjectId']>
  /** Driver phone numbers. */
  driverPhoneNumbers?: Maybe<Array<Maybe<Scalars['String']>>>
  /** End date. */
  endDate?: Maybe<Scalars['Long']>
  /** Inspector user Id. */
  inspector?: Maybe<Scalars['ObjectId']>
  /** From installation zone. */
  fromInstallationZone?: Maybe<Scalars['String']>
  /** To installation zone. */
  installationZone?: Maybe<Scalars['String']>
  /** Is inspection required. */
  isInspectionRequired?: Maybe<Scalars['Boolean']>
  /** Is late request. */
  isLateRequest?: Maybe<Scalars['Boolean']>
  /** Is scheduling in advance prevented. */
  isSchedulingInAdvancePrevented?: Maybe<Scalars['Boolean']>
  /** Is unscheduled request. */
  isUnscheduledRequest?: Maybe<Scalars['Boolean']>
  /** Note. */
  note?: Maybe<Scalars['String']>
  /** Offloading equipment. */
  offloadingEquipments?: Maybe<Array<Maybe<IDeliveryAttribute>>>
  /** Number of equipment picks. */
  numberOfEquipmentPicks?: Maybe<Scalars['String']>
  /** On-site contact person Ids. */
  onSiteContactPersonIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Delivery location from. */
  locationsFrom?: Maybe<Array<Maybe<ISiteLocation>>>
  /** Delivery location to. */
  locationsTo?: Maybe<Array<Maybe<ISiteLocation>>>
  /** Sitemap urls. */
  sitemapUrls?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Globe view urls. */
  globeViewUrls?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Start date. */
  startDate?: Maybe<Scalars['Long']>
  /** Status. */
  status?: Maybe<DeliveryStatus>
  /** Type. */
  type: DeliveryType
  /** Thread Id. */
  threadId?: Maybe<Scalars['ObjectId']>
  /** Truck license plate. */
  truckLicensePlate?: Maybe<Scalars['String']>
  /** Truck number. */
  truckNumber?: Maybe<Scalars['String']>
  /** Truck size. */
  truckSize?: Maybe<Scalars['ObjectId']>
  /** User Id. */
  userId: Scalars['ObjectId']
  /** Vendor name. */
  vendor?: Maybe<Scalars['ObjectId']>
  /** Vendor emails. */
  vendorEmails?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Delivery materials. */
  materials?: Maybe<Array<Maybe<IDeliveryMaterial>>>
  /** Recurring setting id. */
  recurringSettingId?: Maybe<Scalars['ObjectId']>
  cancellationReason?: Maybe<Scalars['String']>
}

export interface IDeliveryAssignment {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Delivery id. */
  entityId: Scalars['ObjectId']
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Recipients. */
  recipients: Array<Maybe<IRecipients>>
}

export interface IDeliveryAssignmentChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IDeliveryAssignment>
}

export interface IDeliveryAssignmentInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Delivery id. */
  entityId: Scalars['ObjectId']
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Recipients. */
  recipients: Array<Maybe<IRecipientsInput>>
}

export interface IDeliveryAssignmentList {
  data: Array<IDeliveryAssignment>
  page: IPageInfo
}

export interface IDeliveryAttribute {
  /** Delivery attribute Id. */
  id?: Maybe<Scalars['ObjectId']>
}

export interface IDeliveryAttributeInput {
  /** Attribute Id. */
  id?: Maybe<Scalars['ObjectId']>
}

export interface IDeliveryCardField {
  /** Delivery field id. */
  fieldId: Scalars['String']
  /** Is field hidden. */
  isHidden: Scalars['Boolean']
}

export interface IDeliveryCardFieldInput {
  /** Delivery field id. */
  fieldId: Scalars['String']
  /** Is field hidden. */
  isHidden: Scalars['Boolean']
}

export interface IDeliveryChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IDelivery>
}

export interface IDeliveryConfigurations {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Order of maps. */
  maps?: Maybe<Array<Maybe<IOrderedSitemap>>>
  /** Delivery Duration. */
  deliveryDuration?: Maybe<Scalars['Int']>
  /** Deadline Interval. */
  deadlineInterval?: Maybe<Scalars['Int']>
  /** Should Block Late Requesting. */
  shouldBlockLateRequesting?: Maybe<Scalars['Boolean']>
  /** On time window. */
  onTimeWindow?: Maybe<Scalars['Float']>
  /** Colors list. */
  allColors?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Custom action text. */
  customActionText?: Maybe<Scalars['String']>
  /** Custom action button text. */
  customActionButtonText?: Maybe<Scalars['String']>
  /** Custom action url. */
  customActionUrl?: Maybe<Scalars['String']>
  /** Unextendable fields. */
  unextendableFields?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Is custom action included. */
  isCustomActionIncluded?: Maybe<Scalars['Boolean']>
  /** Saved delivery filter groups. */
  savedDeliveryGroups?: Maybe<Array<Maybe<IFilterGroup>>>
  /** Non work-times block type. */
  nonWorkTimesBlockType?: Maybe<BlockTypeEnum>
  /** Delivery card coloring location type. */
  cardColoringLocationType?: Maybe<LocationType>
  /** Delivery card fields configuration. */
  cardFieldsConfig?: Maybe<Array<Maybe<IDeliveryCardField>>>
  /** List of the hidden cancellation reasons. */
  hiddenCancelationReasons?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Is multiple equipment allowed. */
  isMultipleEquipmentAllowed?: Maybe<Scalars['Boolean']>
}

export interface IDeliveryConfigurationsChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IDeliveryConfigurations>
}

export interface IDeliveryConfigurationsInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Order of maps. */
  maps?: Maybe<Array<Maybe<IOrderedSitemapInput>>>
  /** Delivery Duration. */
  deliveryDuration?: Maybe<Scalars['Int']>
  /** Deadline Interval. */
  deadlineInterval?: Maybe<Scalars['Int']>
  /** Should Block Late Requesting. */
  shouldBlockLateRequesting?: Maybe<Scalars['Boolean']>
  /** On time window. */
  onTimeWindow?: Maybe<Scalars['Float']>
  /** Colors list. */
  allColors?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Custom action text. */
  customActionText?: Maybe<Scalars['String']>
  /** Custom action button text. */
  customActionButtonText?: Maybe<Scalars['String']>
  /** Custom action url. */
  customActionUrl?: Maybe<Scalars['String']>
  /** Unextendable fields. */
  unextendableFields?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Is custom action included. */
  isCustomActionIncluded?: Maybe<Scalars['Boolean']>
  /** Saved delivery filter groups. */
  savedDeliveryGroups?: Maybe<Array<Maybe<IFilterGroupInput>>>
  /** Non work-times block type. */
  nonWorkTimesBlockType?: Maybe<BlockTypeEnum>
  /** Delivery card coloring location type. */
  cardColoringLocationType?: Maybe<LocationType>
  /** Delivery card fields configuration. */
  cardFieldsConfig?: Maybe<Array<Maybe<IDeliveryCardFieldInput>>>
  /** List of the hidden cancellation reasons. */
  hiddenCancelationReasons?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Is multiple equipment allowed. */
  isMultipleEquipmentAllowed?: Maybe<Scalars['Boolean']>
}

export interface IDeliveryConfigurationsList {
  data: Array<IDeliveryConfigurations>
  page: IPageInfo
}

export interface IDeliveryEquipmentReport {
  /** Id */
  id?: Maybe<Scalars['ObjectId']>
  /** Name */
  name?: Maybe<Scalars['String']>
  /** Number of Equipment Picks */
  numberOfEquipmentPicks?: Maybe<Scalars['String']>
}

export interface IDeliveryFieldName {
  /** Field id. */
  id: Scalars['String']
  /** Custom field name. */
  name: Scalars['String']
}

export interface IDeliveryFieldNameInput {
  /** Field id. */
  id: Scalars['String']
  /** Custom field name. */
  name: Scalars['String']
}

export interface IDeliveryFieldsConfigurations {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** List of mandatory delivery fields. */
  mandatoryFields?: Maybe<Array<Maybe<Scalars['String']>>>
  /** List of hidden delivery fields. */
  hiddenFields?: Maybe<Array<Maybe<Scalars['String']>>>
  /** List of custom names of delivery fields. */
  fieldNames?: Maybe<Array<Maybe<IDeliveryFieldName>>>
}

export interface IDeliveryFieldsConfigurationsChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IDeliveryFieldsConfigurations>
}

export interface IDeliveryFieldsConfigurationsInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** List of mandatory delivery fields. */
  mandatoryFields?: Maybe<Array<Maybe<Scalars['String']>>>
  /** List of hidden delivery fields. */
  hiddenFields?: Maybe<Array<Maybe<Scalars['String']>>>
  /** List of custom names of delivery fields. */
  fieldNames?: Maybe<Array<Maybe<IDeliveryFieldNameInput>>>
}

export interface IDeliveryFieldsConfigurationsList {
  data: Array<IDeliveryFieldsConfigurations>
  page: IPageInfo
}

export interface IDeliveryFilter {
  /** Type. */
  type?: Maybe<DeliveryFilterType>
  /** Values. */
  values?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface IDeliveryFilterInput {
  /** Type. */
  type?: Maybe<DeliveryFilterType>
  /** Values. */
  values?: Maybe<Array<Maybe<Scalars['String']>>>
}

export enum DeliveryFilterType {
  Level = 'level',
  Zone = 'zone',
  Status = 'status',
  Company = 'company',
  Assignee = 'assignee',
  Locations = 'locations',
  Area = 'area',
  Gate = 'gate',
  Building = 'building',
  Route = 'route',
  Equipment = 'equipment',
  Assigners = 'assigners',
  Followers = 'followers',
  InteriorPath = 'interiorPath',
  InteriorDoor = 'interiorDoor',
  Staging = 'staging',
}

export interface IDeliveryFollowing {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Entity Id. */
  entityId: Scalars['ObjectId']
  /** User Id. */
  userId: Scalars['ObjectId']
}

export interface IDeliveryFollowingChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IDeliveryFollowing>
}

export interface IDeliveryFollowingInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Entity Id. */
  entityId: Scalars['ObjectId']
  /** User Id. */
  userId: Scalars['ObjectId']
}

export interface IDeliveryFollowingList {
  data: Array<IDeliveryFollowing>
  page: IPageInfo
}

export interface IDeliveryHierarchyParent {
  /** Parent id. */
  parentId?: Maybe<Scalars['ObjectId']>
  /** Parent type. */
  parentType?: Maybe<LocationType>
}

export interface IDeliveryHierarchyParentInput {
  /** Parent id. */
  parentId?: Maybe<Scalars['ObjectId']>
  /** Parent type. */
  parentType?: Maybe<LocationType>
}

export interface IDeliveryInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Activity Id. */
  activityId?: Maybe<Scalars['String']>
  /** Company. */
  company?: Maybe<Scalars['ObjectId']>
  /** Driver phone numbers. */
  driverPhoneNumbers?: Maybe<Array<Maybe<Scalars['String']>>>
  /** End date. */
  endDate: Scalars['Long']
  /** Is inspection required. */
  isInspectionRequired?: Maybe<Scalars['Boolean']>
  /** Is unscheduled request. */
  isUnscheduledRequest?: Maybe<Scalars['Boolean']>
  /** Offloading equipments. */
  offloadingEquipments?: Maybe<Array<Maybe<IDeliveryAttributeInput>>>
  /** Number of equipment picks. */
  numberOfEquipmentPicks?: Maybe<Scalars['String']>
  /** On site contact person ids. */
  onSiteContactPersonIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Sitemap urls. */
  sitemapUrls?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Globe view urls. */
  globeViewUrls?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Start date. */
  startDate?: Maybe<Scalars['Long']>
  /** Status. */
  status?: Maybe<DeliveryStatus>
  /** Type. */
  type: DeliveryType
  /** Truck license plate. */
  truckLicensePlate?: Maybe<Scalars['String']>
  /** Truck number. */
  truckNumber?: Maybe<Scalars['String']>
  /** Truck size. */
  truckSize?: Maybe<Scalars['ObjectId']>
  /** Vendor. */
  vendor?: Maybe<Scalars['ObjectId']>
  /** Vendor emails. */
  vendorEmails?: Maybe<Array<Maybe<Scalars['String']>>>
  /** From installation zone. */
  fromInstallationZone?: Maybe<Scalars['String']>
  /** To installation zone. */
  installationZone?: Maybe<Scalars['String']>
  /** Node. */
  note?: Maybe<Scalars['String']>
  /** Inspector. */
  inspector?: Maybe<Scalars['ObjectId']>
  /** Thread Id. */
  threadId?: Maybe<Scalars['ObjectId']>
  /** Materials. */
  materials?: Maybe<Array<Maybe<IDeliveryMaterialInput>>>
  /** Delivery location to. */
  locationsTo?: Maybe<Array<Maybe<ISiteLocationInput>>>
  /** Delivery location from. */
  locationsFrom?: Maybe<Array<Maybe<ISiteLocationInput>>>
  /** User id. */
  userId?: Maybe<Scalars['String']>
  /** Recurring setting id. */
  recurringSettingId?: Maybe<Scalars['ObjectId']>
}

export interface IDeliveryList {
  data: Array<IDelivery>
  page: IPageInfo
}

export interface IDeliveryListCustomFilter {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** User Id. */
  userId?: Maybe<Scalars['ObjectId']>
  /** Is public. */
  isPublic: Scalars['Boolean']
  /** Filters by filter type. */
  filtersByFilterType?: Maybe<Array<Maybe<IDeliveryFilter>>>
  /** Default for users. */
  defaultForUsers?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export interface IDeliveryListCustomFilterInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** User Id. */
  userId?: Maybe<Scalars['ObjectId']>
  /** Is public. */
  isPublic: Scalars['Boolean']
  /** DeliveryFilters by filter type. */
  filtersByFilterType?: Maybe<Array<Maybe<IDeliveryFilterInput>>>
  /** Default for users. */
  defaultForUsers?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export interface IDeliveryListCustomFilterList {
  data: Array<IDeliveryListCustomFilter>
  page: IPageInfo
}

export enum DeliveryListFilterType {
  None = 'none',
  Company = 'company',
  OnSiteContact = 'onSiteContact',
  Lbs = 'lbs',
  Building = 'building',
  Zone = 'zone',
  Route = 'route',
  Gate = 'gate',
  Status = 'status',
  Vendor = 'vendor',
  MaterialsCategory = 'materialsCategory',
  VehicleType = 'vehicleType',
}

export interface IDeliveryListSettings {
  /** Is following filter active. */
  isFollowingFilterActive: Scalars['Boolean']
  /** Is my company filter active. */
  isMyCompanyFilterActive: Scalars['Boolean']
  /** Selected group by option. */
  selectedGroupByOption?: Maybe<DeliveryListFilterType>
  /** Selected filters. */
  selectedFilters?: Maybe<Array<Maybe<IDeliveryFilter>>>
}

export interface IDeliveryListSettingsInput {
  /** Is following filter active. */
  isFollowingFilterActive: Scalars['Boolean']
  /** Is my company filter active. */
  isMyCompanyFilterActive: Scalars['Boolean']
  /** Selected group by option. */
  selectedGroupByOption?: Maybe<DeliveryListFilterType>
  /** Selected filters. */
  selectedFilters?: Maybe<Array<Maybe<IDeliveryFilterInput>>>
}

export interface IDeliveryMaterial {
  /** Procurement Id. */
  procurementId?: Maybe<Scalars['String']>
  /** Material Id. */
  materialId: Scalars['ObjectId']
  /** Quantity. */
  quantity?: Maybe<Scalars['Float']>
  /** Note. */
  note?: Maybe<Scalars['String']>
  /** Unit id. */
  unitId?: Maybe<Scalars['ObjectId']>
  /** Location Id. */
  locationId?: Maybe<Scalars['ObjectId']>
}

export interface IDeliveryMaterialInput {
  /** Procurement Id. */
  procurementId?: Maybe<Scalars['String']>
  /** Material Id. */
  materialId: Scalars['ObjectId']
  /** Quantity. */
  quantity?: Maybe<Scalars['Float']>
  /** Note. */
  note?: Maybe<Scalars['String']>
  /** Unit id. */
  unitId?: Maybe<Scalars['ObjectId']>
  /** Location Id. */
  locationId?: Maybe<Scalars['ObjectId']>
}

export interface IDeliveryMaterialReport {
  /** Material Category. */
  materialCategory?: Maybe<Scalars['String']>
  /** Material Name. */
  materialName?: Maybe<Scalars['String']>
  /** Material Note. */
  materialNote?: Maybe<Scalars['String']>
  /** Quantity. */
  quantity?: Maybe<Scalars['Float']>
  /** Unit. */
  unit?: Maybe<Scalars['String']>
}

export interface IDeliveryReportResponse {
  /** Activity Id. */
  activityId?: Maybe<Scalars['String']>
  /** Area. */
  area?: Maybe<Scalars['String']>
  /** Zone. */
  zone?: Maybe<Scalars['String']>
  /** Level. */
  level?: Maybe<Scalars['String']>
  /** Building. */
  building?: Maybe<Scalars['String']>
  /** Company. */
  company?: Maybe<Scalars['String']>
  /** Delivery Id. */
  deliveryId?: Maybe<Scalars['ObjectId']>
  /** Delta End. */
  deltaEnd?: Maybe<Scalars['Float']>
  /** Delta Start. */
  deltaStart?: Maybe<Scalars['Float']>
  /** Done Date. */
  doneDate?: Maybe<Scalars['String']>
  /** Driver Phone Number. */
  driverPhoneNumbers?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Duration Actual. */
  durationActual?: Maybe<Scalars['Float']>
  /** Duration Scheduled. */
  durationScheduled?: Maybe<Scalars['Float']>
  /** End Date. */
  endDate?: Maybe<Scalars['String']>
  /** Entry Gate. */
  entryGate?: Maybe<Scalars['String']>
  /** Id. */
  id?: Maybe<Scalars['String']>
  /** Inspector. */
  inspector?: Maybe<Scalars['String']>
  /** Installation Zone. */
  installationZone?: Maybe<Scalars['String']>
  /** Is Inspection Required. */
  isInspectionRequired?: Maybe<Scalars['String']>
  /** Is Late Request. */
  isLateRequest?: Maybe<Scalars['Boolean']>
  /** Is Scheduling in Advance Prevented. */
  isSchedulingInAdvancePrevented?: Maybe<Scalars['Boolean']>
  /** Is Unscheduled Requested. */
  isUnscheduledRequest?: Maybe<Scalars['Boolean']>
  /** Materials List. */
  materials?: Maybe<Array<Maybe<IDeliveryMaterialReport>>>
  /** Note. */
  note?: Maybe<Scalars['String']>
  /** Offloading Equipments List. */
  offloadingEquipments?: Maybe<Array<Maybe<IDeliveryEquipmentReport>>>
  /** On Site Contact Persons. */
  onSiteContactPersons?: Maybe<Array<Maybe<IOnSiteContactPerson>>>
  /** On Site Date. */
  onsiteDate?: Maybe<Scalars['String']>
  /** Project Name. */
  projectName?: Maybe<Scalars['String']>
  /** Route. */
  route?: Maybe<Scalars['String']>
  /** Sitemap Urls. */
  sitemapUrls?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Start Date. */
  startDate?: Maybe<Scalars['String']>
  /** Status. */
  status?: Maybe<Scalars['String']>
  /** Status Changes List. */
  statusChanges?: Maybe<Array<Maybe<IStatusChange>>>
  /** Thread Id. */
  threadId?: Maybe<Scalars['String']>
  /** Truck Number. */
  truckNumber?: Maybe<Scalars['String']>
  /** Truck Size. */
  truckSize?: Maybe<Scalars['String']>
  /** Vendor. */
  vendor?: Maybe<Scalars['String']>
  /** Vendor Emails. */
  vendorEmails?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Cancellation Reason. */
  cancellationReason?: Maybe<Scalars['String']>
}

export enum DeliveryStatus {
  Canceled = 'Canceled',
  Requested = 'Requested',
  Changed = 'Changed',
  Scheduled = 'Scheduled',
  OnSite = 'OnSite',
  PassedInspection = 'PassedInspection',
  FailedInspection = 'FailedInspection',
  IncompleteDone = 'IncompleteDone',
  Done = 'Done',
  Deleted = 'Deleted',
  Denied = 'Denied',
  Delivering = 'Delivering',
  Paused = 'Paused',
  OnHold = 'OnHold',
}

export interface IDeliveryStatusChange {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Delivery Id. */
  deliveryId?: Maybe<Scalars['ObjectId']>
  /** Status. */
  status?: Maybe<DeliveryStatus>
  /** User Id. */
  userId?: Maybe<Scalars['ObjectId']>
  /** Thread Id. */
  threadId?: Maybe<Scalars['ObjectId']>
}

export interface IDeliveryStatusChangeChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IDeliveryStatusChange>
}

export interface IDeliveryStatusChangeList {
  data: Array<IDeliveryStatusChange>
  page: IPageInfo
}

export interface IDeliveryTicketMaterial {
  /** Material name. */
  name?: Maybe<Scalars['String']>
  /** Material category. */
  category?: Maybe<Scalars['String']>
  /** Quantity. */
  quantity?: Maybe<Scalars['Float']>
  /** Unit. */
  unit?: Maybe<Scalars['String']>
  /** Location. */
  location?: Maybe<Scalars['String']>
  /** Note. */
  note?: Maybe<Scalars['String']>
}

export interface IDeliveryTicketResponse {
  /** Delivery Code. */
  deliveryCode?: Maybe<Scalars['String']>
  /** Status. */
  status?: Maybe<Scalars['String']>
  /** Address. */
  address?: Maybe<Scalars['String']>
  /** Area. */
  area?: Maybe<Scalars['String']>
  /** Building. */
  building?: Maybe<Scalars['String']>
  /** Gate. */
  gate?: Maybe<Scalars['String']>
  /** Zone. */
  zone?: Maybe<Scalars['String']>
  /** Route. */
  route?: Maybe<Scalars['String']>
  /** Level. */
  level?: Maybe<Scalars['String']>
  /** Equipments. */
  equipments?: Maybe<Scalars['String']>
  /** Date. */
  date?: Maybe<Scalars['String']>
  /** Driver Phone Number. */
  driverPhoneNumbers?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Materials. */
  materials?: Maybe<Array<Maybe<IDeliveryTicketMaterial>>>
  /** On Site Contacts. */
  onSiteContacts?: Maybe<Array<Maybe<IOnSiteContactResponse>>>
  /** Inspector Name. */
  inspectorName?: Maybe<Scalars['String']>
  /** Inspector Company. */
  inspectorCompany?: Maybe<Scalars['String']>
  /** Inspector Contacts. */
  inspectorContacts?: Maybe<Scalars['String']>
  /** Project Name. */
  projectName?: Maybe<Scalars['String']>
  /** Requester Company. */
  requesterCompany?: Maybe<Scalars['String']>
  /** Sitemap Urls. */
  sitemapUrls?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Globe view Urls. */
  globeViewUrls?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Time Range. */
  timeRange?: Maybe<Scalars['String']>
  /** Vehicle. */
  vehicle?: Maybe<Scalars['String']>
  /** Custom Action Text. */
  customActionText?: Maybe<Scalars['String']>
  /** Custom Action Button Text. */
  customActionButtonText?: Maybe<Scalars['String']>
  /** Custom Action Url. */
  customActionUrl?: Maybe<Scalars['String']>
  /** Is Custom Action Included. */
  isCustomActionIncluded?: Maybe<Scalars['Boolean']>
  displayNames?: Maybe<Array<Maybe<IDisplayName>>>
}

export enum DeliveryType {
  Regular = 'Regular',
  FromTo = 'FromTo',
}

export interface IDeliveryUnit {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Delivery unit name. */
  name: Scalars['String']
  /** Is delivery unit deleted. */
  isDeleted: Scalars['Boolean']
}

export interface IDeliveryUnitChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IDeliveryUnit>
}

export interface IDeliveryUnitInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Delivery unit name. */
  name: Scalars['String']
  /** Is delivery unit deleted. */
  isDeleted: Scalars['Boolean']
}

export interface IDeliveryUnitList {
  data: Array<IDeliveryUnit>
  page: IPageInfo
}

export interface IDeliveryVehicleType {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Delivery vehicle type name. */
  name: Scalars['String']
  /** Is delivery vehicle type deleted. */
  isDeleted: Scalars['Boolean']
}

export interface IDeliveryVehicleTypeChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IDeliveryVehicleType>
}

export interface IDeliveryVehicleTypeInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Delivery vehicle type name. */
  name: Scalars['String']
  /** Is delivery vehicle type deleted. */
  isDeleted: Scalars['Boolean']
}

export interface IDeliveryVehicleTypeList {
  data: Array<IDeliveryVehicleType>
  page: IPageInfo
}

export interface IDeviceInfo {
  /** Device token. */
  token?: Maybe<Scalars['String']>
  /** Device locale. */
  locale?: Maybe<Scalars['String']>
}

export interface IDisplayName {
  /** Key. */
  key?: Maybe<Scalars['String']>
  /** Name. */
  name?: Maybe<Scalars['String']>
}

export interface IEntityRelationship {
  /** Entity id. */
  entityId: Scalars['ObjectId']
  /** Relationship type. */
  relationshipType: RelationshipType
}

export interface IEntityTag {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Tag value. */
  value?: Maybe<Scalars['String']>
  /** Tag color. */
  color?: Maybe<Scalars['String']>
}

export interface IEntityTagChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IEntityTag>
}

export interface IEntityTagInput {
  /** Tag value. */
  value?: Maybe<Scalars['String']>
  /** Tag color. */
  color?: Maybe<Scalars['String']>
}

export interface IEntityTagList {
  data: Array<IEntityTag>
  page: IPageInfo
}

export interface IFileRequisites {
  /** File path in bucket. */
  filePath?: Maybe<Scalars['String']>
  /** Url for upload file to bucket. */
  signedUrl?: Maybe<Scalars['String']>
  /** Url for file. */
  fileURL?: Maybe<Scalars['String']>
  /** Upload content type. */
  contentType?: Maybe<Scalars['String']>
}

export enum FileType {
  Pdf = 'PDF',
  Csv = 'CSV',
}

export interface IFilterGroup {
  /** Filter set. */
  filterNames?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface IFilterGroupInput {
  /** Filter set. */
  filterNames?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface IFilterInfo {
  codeTypeIds: Array<Maybe<Scalars['String']>>
  hiddenCodeIds: Array<Maybe<Scalars['String']>>
  sourceType?: Maybe<FilterSourceType>
  isDisabled: Scalars['Boolean']
  caption?: Maybe<Scalars['String']>
  name: Scalars['String']
  isRemovable: Scalars['Boolean']
  canBeDisabled: Scalars['Boolean']
  index: Scalars['Int']
}

export interface IFilterInfoInput {
  codeTypeIds: Array<Maybe<Scalars['String']>>
  hiddenCodeIds: Array<Maybe<Scalars['String']>>
  sourceType?: Maybe<FilterSourceType>
  isDisabled: Scalars['Boolean']
  caption?: Maybe<Scalars['String']>
  name: Scalars['String']
  isRemovable: Scalars['Boolean']
  canBeDisabled: Scalars['Boolean']
  index: Scalars['Int']
}

export enum FilterSourceType {
  ActivityCode = 'ActivityCode',
  ProjectMember = 'ProjectMember',
}

export enum FilterType {
  Name = 'name',
  Period = 'period',
  Company = 'company',
  Level = 'level',
  Zone = 'zone',
  Gate = 'gate',
  Building = 'building',
}

export interface IForecastCloudsParams {
  /** Cloudiness, %. */
  cloudiness: Scalars['Short']
}

export interface IForecastConditionParams {
  /** Weather condition id. */
  id: Scalars['Int']
  /** Group of weather parameters (Rain, Snow, Extreme etc.). */
  condition: Scalars['String']
  /** Weather condition within the group. */
  description: Scalars['String']
  /** Weather icon id. */
  iconId: Scalars['String']
}

export interface IForecastMainParams {
  /** Temperature. */
  temperature: Scalars['Float']
  /** The temperature parameter accounts for the human perception of weather. */
  feelsLikeTemp: Scalars['Float']
  /** Minimal forecasted temperature. */
  minTemperature: Scalars['Float']
  /** Maximal forecasted temperature. */
  maxTemperature: Scalars['Float']
  /** Atmospheric pressure on the sea level by default, hPa. */
  pressure: Scalars['Int']
  /** Atmospheric pressure on the sea level, hPa. */
  pressureOnSeaLevel: Scalars['Int']
  /** Atmospheric pressure on the ground level, hPa. */
  pressureOnGroundLevel: Scalars['Int']
  /** Humidity, %. */
  humidity: Scalars['Short']
  /** public parameter. */
  tempKf: Scalars['Float']
}

export interface IForecastPrecipitationParams {
  /** Precipitation volume for last 3 hours, mm. */
  volume: Scalars['Float']
}

export interface IForecastSystemParams {
  /** Part of the day (n - night, d - day). */
  partOfDay: Scalars['String']
}

export interface IForecastWindParams {
  /** Wind speed. */
  speed: Scalars['Float']
  /** Wind direction, degrees (meteorological). */
  degrees: Scalars['Int']
  /** Wind gust. */
  gust: Scalars['Float']
}

export interface IFormAnalyticResponse {
  /** Workflow Fields. */
  workflowFields?: Maybe<IFormAnalyticsWorkflowFields>
  /** Status. */
  status?: Maybe<Scalars['String']>
  /** Status Change List. */
  statusChanges?: Maybe<Array<Maybe<IFormAnalyticsStatusChange>>>
  /** Locations. */
  locations?: Maybe<IFormAnalyticsLocation>
  /** Inspections. */
  inspections?: Maybe<Array<Maybe<IFormAnalyticsInspection>>>
  /** Basic Fields. */
  basicFields?: Maybe<Array<Maybe<IFormAnalyticsAdditionalInfo>>>
}

export interface IFormAnalyticsAdditionalInfo {
  /** Name. */
  name?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Values. */
  values?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface IFormAnalyticsInspection {
  /** Date. */
  date?: Maybe<Scalars['String']>
  /** Inspection Changes. */
  inspectionChanges?: Maybe<Array<Maybe<IFormAnalyticsInspectionChange>>>
}

export interface IFormAnalyticsInspectionChange {
  /** User. */
  user?: Maybe<Scalars['String']>
  /** Number Of Checklist Questions. */
  numberOfChecklistQuestions?: Maybe<Scalars['Int']>
  /** Number Of Checklist Positive. */
  numberOfChecklistPositive?: Maybe<Scalars['Int']>
}

export interface IFormAnalyticsLocation {
  /** Building. */
  building?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Level. */
  level?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Area. */
  area?: Maybe<Array<Maybe<Scalars['String']>>>
  /** OffloadingZone. */
  offloadingZone?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Entry Gate. */
  entryGate?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Vertical Objects. */
  verticalObjects?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface IFormAnalyticsStatusChange {
  /** Status. */
  status?: Maybe<Scalars['String']>
  /** Date. */
  date?: Maybe<Scalars['String']>
  /** Email. */
  email?: Maybe<Scalars['String']>
  /** User Id. */
  userId?: Maybe<Scalars['String']>
  /** User Name. */
  userName?: Maybe<Scalars['String']>
  /** User Company. */
  userCompany?: Maybe<Scalars['String']>
}

export interface IFormAnalyticsWorkflowFields {
  /** Project Name. */
  projectName?: Maybe<Scalars['String']>
  /** Tenant Name. */
  tenantName?: Maybe<Scalars['String']>
  /** Form ID. */
  formId?: Maybe<Scalars['String']>
  /** Form Code. */
  formCode?: Maybe<Scalars['String']>
  /** Permit Title. */
  permitTitle?: Maybe<Scalars['String']>
  /** Form Type. */
  formType?: Maybe<Scalars['String']>
  /** Company. */
  company?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assignee. */
  assignee?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Requester. */
  requester?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Start Date. */
  startDate?: Maybe<Scalars['String']>
  /** EndDate. */
  endDate?: Maybe<Scalars['String']>
  /** Timezone. */
  timezone?: Maybe<Scalars['String']>
  /** InspectionFrequencyPeriod. */
  inspectionFrequencyPeriod?: Maybe<Scalars['String']>
  /** InspectionFrequencyValue. */
  inspectionFrequencyValue?: Maybe<Scalars['String']>
}

export interface IFormCategory {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** The name of the record. */
  name: Scalars['String']
  /** The optional Project Id associated with this form category. */
  projectId?: Maybe<Scalars['ObjectId']>
  /** Determines whether the category is deleted. */
  isDeleted?: Maybe<Scalars['Boolean']>
}

export interface IFormCategoryChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IFormCategory>
}

export interface IFormCategoryInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** The name of the record. */
  name: Scalars['String']
  /** The Project Id associated with this form category. This is a required field to prevent adding new categories at the tenant level. */
  projectId: Scalars['ObjectId']
}

export interface IFormCategoryList {
  data: Array<IFormCategory>
  page: IPageInfo
}

export interface IFormMaterial {
  /** Material Id. */
  materialId: Scalars['ObjectId']
  /** Procurement ID. */
  procurementId?: Maybe<Scalars['String']>
  /** Quantity. */
  quantity?: Maybe<Scalars['String']>
  /** Location Id. */
  locationId?: Maybe<Scalars['ObjectId']>
}

export interface IFormMaterialInput {
  /** Material Id. */
  materialId: Scalars['ObjectId']
  /** Procurement ID. */
  procurementId?: Maybe<Scalars['String']>
  /** Quantity. */
  quantity?: Maybe<Scalars['String']>
  /** Location Id. */
  locationId?: Maybe<Scalars['ObjectId']>
}

export interface IFormsConfigurations {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Order of maps. */
  maps?: Maybe<Array<Maybe<IOrderedSitemap>>>
}

export interface IFormsConfigurationsChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IFormsConfigurations>
}

export interface IFormsConfigurationsInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Order of maps. */
  maps?: Maybe<Array<Maybe<IOrderedSitemapInput>>>
}

export interface IFormsConfigurationsList {
  data: Array<IFormsConfigurations>
  page: IPageInfo
}

export interface IGate extends ILocation {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateInterval>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Delivery hierarchy parent. */
  parent?: Maybe<IDeliveryHierarchyParent>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<LocationType>
  closingDate?: Maybe<Scalars['Long']>
}

export interface IGateInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateIntervalInput>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Location parent. */
  parent?: Maybe<IDeliveryHierarchyParentInput>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
}

export interface IGenerateReportCommandInput {
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Report additional data. */
  dataJSON?: Maybe<Scalars['String']>
  /** Report template id. */
  templateId: Scalars['String']
  /** Report title. */
  title?: Maybe<Scalars['String']>
  /** Files. */
  files?: Maybe<Array<Maybe<IReportFileInput>>>
  /** Additional recipient Ids. */
  additionalRecipientIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Report start date. */
  dateTo?: Maybe<Scalars['MillisecondsDate']>
  /** Report finish date. */
  dateFrom?: Maybe<Scalars['MillisecondsDate']>
  /** Is full time report. */
  isFullTime?: Maybe<Scalars['Boolean']>
  /** Timezone id. */
  timezoneId?: Maybe<Scalars['String']>
  /** QR code link. */
  qRCodeLink?: Maybe<Scalars['String']>
  /** Report will be sent via email if set to true. */
  sendEmail?: Maybe<Scalars['Boolean']>
}

export interface IGenerateSignedUploadUrlInput {
  /** File name. */
  fileName: Scalars['String']
  /** Uploading type. */
  uploadingType: UploadingType
  /** Determines whether the file is public. */
  isPublic: Scalars['Boolean']
}

export interface IGeoJson2DGeographicCoordinates {
  /** Latitude. */
  latitude: Scalars['Float']
  /** Longitude. */
  longitude: Scalars['Float']
}

export interface IGeoJson2DGeographicCoordinatesInput {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export interface IGlobeView {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Sitemap Ids. */
  sitemaps?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Tilesets. */
  tilesets?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Is orientation locked. */
  isOrientationLocked?: Maybe<Scalars['Boolean']>
  /** Altitude. */
  altitude?: Maybe<Scalars['Float']>
  /** Pitch. */
  pitch?: Maybe<Scalars['Float']>
  /** Zoom. */
  zoom?: Maybe<Scalars['Float']>
  /** Is project overview globe. */
  isProjectOverviewGlobe?: Maybe<Scalars['Boolean']>
  /** Bearing. */
  bearing?: Maybe<Scalars['Float']>
  /** Earth coordinates of bounding box. */
  bounds?: Maybe<IAddressBounds>
  /** Earth coordinates of the center point. */
  center?: Maybe<ILatLng>
  /** Earth coordinates of the corner points. */
  geoCorners?: Maybe<Array<Maybe<IGeoJson2DGeographicCoordinates>>>
  /** Filled image. */
  filledImage?: Maybe<Scalars['String']>
  /** Items filled image. */
  itemsFilledImage?: Maybe<Scalars['String']>
  /** Style. */
  style?: Maybe<IGlobeViewStyle>
  items?: Maybe<Array<Maybe<IGlobeViewSpecificItemData>>>
}

export interface IGlobeViewChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IGlobeView>
}

export interface IGlobeViewCircle extends IGlobeViewItemShapeInterface {
  /** Line width. */
  lineWidth: Scalars['Int']
  /** Line color. */
  lineColor?: Maybe<Scalars['String']>
  /** Fill color. */
  fillColor?: Maybe<Scalars['String']>
  /** Is item displayed on the map. */
  isDisplayed?: Maybe<Scalars['Boolean']>
  /** Fill opacity. */
  fillOpacity: Scalars['Float']
  /** Type. */
  type?: Maybe<Scalars['String']>
}

export interface IGlobeViewCircleInput {
  /** Line width. */
  lineWidth: Scalars['Int']
  /** Line color. */
  lineColor?: Maybe<Scalars['String']>
  /** Fill color. */
  fillColor?: Maybe<Scalars['String']>
  /** Is item displayed on the map. */
  isDisplayed?: Maybe<Scalars['Boolean']>
  /** Fill opacity. */
  fillOpacity: Scalars['Float']
  /** Type. */
  type?: Maybe<Scalars['String']>
}

export interface IGlobeViewInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Sitemaps. */
  sitemaps?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Tilesets. */
  tilesets?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Is orientation locked. */
  isOrientationLocked?: Maybe<Scalars['Boolean']>
  /** Altitude. */
  altitude?: Maybe<Scalars['Float']>
  /** Pitch. */
  pitch?: Maybe<Scalars['Float']>
  /** Zoom. */
  zoom?: Maybe<Scalars['Float']>
  /** Is project overview globe. */
  isProjectOverviewGlobe?: Maybe<Scalars['Boolean']>
  /** Bearing. */
  bearing?: Maybe<Scalars['Float']>
  /** Earth coordinates of bounding box. */
  bounds?: Maybe<IAddressBoundsInput>
  /** Earth coordinates of the center point. */
  center?: Maybe<ILatLngInput>
  /** Earth coordinates of the corner points. */
  geoCorners?: Maybe<Array<Maybe<IGeoJson2DGeographicCoordinatesInput>>>
  /** Filled image. */
  filledImage?: Maybe<Scalars['String']>
  /** Items filled image. */
  itemsFilledImage?: Maybe<Scalars['String']>
  /** Style. */
  style?: Maybe<IGlobeViewStyleInput>
}

export type IGlobeViewItemShapeInterface = {
  /** Line width. */
  lineWidth: Scalars['Int']
  /** Line color. */
  lineColor?: Maybe<Scalars['String']>
  /** Fill color. */
  fillColor?: Maybe<Scalars['String']>
  /** Is item displayed on the map. */
  isDisplayed?: Maybe<Scalars['Boolean']>
  /** Fill opacity. */
  fillOpacity: Scalars['Float']
  /** Type. */
  type?: Maybe<Scalars['String']>
}

export interface IGlobeViewList {
  data: Array<IGlobeView>
  page: IPageInfo
}

export enum GlobeViewMapType {
  Street = 'Street',
  Satellite = 'Satellite',
}

export interface IGlobeViewPin {
  /** IsHidden. */
  isHidden?: Maybe<Scalars['Boolean']>
}

export interface IGlobeViewPinInput {
  /** Is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>
}

export interface IGlobeViewPolyline extends IGlobeViewItemShapeInterface {
  /** Line width. */
  lineWidth: Scalars['Int']
  /** Line color. */
  lineColor?: Maybe<Scalars['String']>
  /** Fill color. */
  fillColor?: Maybe<Scalars['String']>
  /** Is item displayed on the map. */
  isDisplayed?: Maybe<Scalars['Boolean']>
  /** Fill opacity. */
  fillOpacity: Scalars['Float']
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Is closed. */
  isClosed: Scalars['Boolean']
  /** Arrow position. */
  arrowPosition?: Maybe<SitemapLineArrowPosition>
}

export interface IGlobeViewPolylineInput {
  /** Line width. */
  lineWidth: Scalars['Int']
  /** Line color. */
  lineColor?: Maybe<Scalars['String']>
  /** Fill color. */
  fillColor?: Maybe<Scalars['String']>
  /** Is item displayed on the map. */
  isDisplayed?: Maybe<Scalars['Boolean']>
  /** Fill opacity. */
  fillOpacity: Scalars['Float']
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** IsClosed. */
  isClosed?: Maybe<Scalars['Boolean']>
  /** Arrow position. */
  arrowPosition?: Maybe<SitemapLineArrowPosition>
}

export interface IGlobeViewRectangle extends IGlobeViewItemShapeInterface {
  /** Line width. */
  lineWidth: Scalars['Int']
  /** Line color. */
  lineColor?: Maybe<Scalars['String']>
  /** Fill color. */
  fillColor?: Maybe<Scalars['String']>
  /** Is item displayed on the map. */
  isDisplayed?: Maybe<Scalars['Boolean']>
  /** Fill opacity. */
  fillOpacity: Scalars['Float']
  /** Type. */
  type?: Maybe<Scalars['String']>
}

export interface IGlobeViewRectangleInput {
  /** Line width. */
  lineWidth: Scalars['Int']
  /** Line color. */
  lineColor?: Maybe<Scalars['String']>
  /** Fill color. */
  fillColor?: Maybe<Scalars['String']>
  /** Is item displayed on the map. */
  isDisplayed?: Maybe<Scalars['Boolean']>
  /** Fill opacity. */
  fillOpacity: Scalars['Float']
  /** Type. */
  type?: Maybe<Scalars['String']>
}

export interface IGlobeViewSpecificItemData {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Globe view Id. */
  globeViewId?: Maybe<Scalars['ObjectId']>
  /** Is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>
  /** Sitemap item Id. */
  sitemapItemId?: Maybe<Scalars['ObjectId']>
  /** Icon. */
  icon?: Maybe<IGlobeViewPin>
  /** Label. */
  label?: Maybe<IGlobeViewTextBox>
  /** Shape. */
  shape?: Maybe<IGlobeViewItemShapeInterface>
}

export interface IGlobeViewSpecificItemDataChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IGlobeViewSpecificItemData>
}

export interface IGlobeViewSpecificItemDataCircleInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Globe view Id. */
  globeViewId?: Maybe<Scalars['ObjectId']>
  /** Sitemap item Id. */
  sitemapItemId?: Maybe<Scalars['ObjectId']>
  /** Is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>
  /** Icon. */
  icon?: Maybe<IGlobeViewPinInput>
  /** Label. */
  label?: Maybe<IGlobeViewTextBoxInput>
  /** Shape. */
  shape?: Maybe<IGlobeViewCircleInput>
}

export interface IGlobeViewSpecificItemDataPolylineInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Globe view Id. */
  globeViewId?: Maybe<Scalars['ObjectId']>
  /** Sitemap item Id. */
  sitemapItemId?: Maybe<Scalars['ObjectId']>
  /** Is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>
  /** Icon. */
  icon?: Maybe<IGlobeViewPinInput>
  /** Label. */
  label?: Maybe<IGlobeViewTextBoxInput>
  /** Shape. */
  shape?: Maybe<IGlobeViewPolylineInput>
}

export interface IGlobeViewSpecificItemDataRectangleInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Globe view Id. */
  globeViewId?: Maybe<Scalars['ObjectId']>
  /** Sitemap item Id. */
  sitemapItemId?: Maybe<Scalars['ObjectId']>
  /** Is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>
  /** Icon. */
  icon?: Maybe<IGlobeViewPinInput>
  /** Label. */
  label?: Maybe<IGlobeViewTextBoxInput>
  /** Shape. */
  shape?: Maybe<IGlobeViewRectangleInput>
}

export interface IGlobeViewStyle {
  /** Map type. */
  mapType: GlobeViewMapType
  /** Is 3D enabled. */
  isThreeDEnabled: Scalars['Boolean']
  /** Is traffic enabled. */
  isTrafficEnabled: Scalars['Boolean']
  /** Is terrain enabled. */
  isTerrainEnabled: Scalars['Boolean']
  /** Should use map animations. */
  shouldUseMapAnimations: Scalars['Boolean']
  /** Should hide labels. */
  shouldHideLabels: Scalars['Boolean']
  /** Should show app project markers. */
  shouldShowAppProjectMarkers: Scalars['Boolean']
}

export interface IGlobeViewStyleInput {
  /** Map type. */
  mapType: GlobeViewMapType
  /** Is 3D enabled. */
  isThreeDEnabled: Scalars['Boolean']
  /** Is traffic enabled. */
  isTrafficEnabled: Scalars['Boolean']
  /** Is terrain enabled. */
  isTerrainEnabled: Scalars['Boolean']
  /** Should use map animations. */
  shouldUseMapAnimations: Scalars['Boolean']
  /** Should hide labels. */
  shouldHideLabels: Scalars['Boolean']
  /** Should show app project markers. */
  shouldShowAppProjectMarkers: Scalars['Boolean']
}

export interface IGlobeViewTextBox {
  /** Font size. */
  fontSize: Scalars['Int']
  /** Is text box displayed. */
  isTextBoxDisplayed: Scalars['Boolean']
  /** Is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>
  /** Color. */
  color?: Maybe<Scalars['String']>
}

export interface IGlobeViewTextBoxInput {
  /** Font size. */
  fontSize: Scalars['Int']
  /** Is text box displayed. */
  isTextBoxDisplayed: Scalars['Boolean']
  /** Is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>
  /** Color. */
  color?: Maybe<Scalars['String']>
}

export interface IGlobesSetupSettings {
  /** Last edited globe id. */
  lastEditedGlobeId?: Maybe<Scalars['ObjectId']>
}

export interface IGlobesSetupSettingsInput {
  /** Last edited globe id. */
  lastEditedGlobeId?: Maybe<Scalars['ObjectId']>
}

export interface IHierarchyConfigurations {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Container updates accessibility. */
  canGCUpdateActivity?: Maybe<Scalars['Boolean']>
  /** Order of hierarchy bands in hierarchy. */
  bandsHierarchyOrder?: Maybe<Array<Maybe<FilterType>>>
  /** Hierarchy modes in specific views. */
  viewsToHierarchyModes?: Maybe<IViewTypes>
}

export interface IHierarchyConfigurationsInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Container updates accessibility. */
  canGCUpdateActivity?: Maybe<Scalars['Boolean']>
  /** Order of hierarchy bands in hierarchy. */
  bandsHierarchyOrder?: Maybe<Array<Maybe<FilterType>>>
  /** Hierarchy modes in specific views. */
  viewsToHierarchyModes?: Maybe<IViewTypesInput>
}

export interface IHierarchyConfigurationsList {
  data: Array<IHierarchyConfigurations>
  page: IPageInfo
}

export enum HierarchyModes {
  Hierarchy = 'HIERARCHY',
  Wbs = 'WBS',
}

export interface IHistoryItem {
  /** Schedule file name. */
  fileName?: Maybe<Scalars['String']>
  /** Schedule id. */
  scheduleId?: Maybe<Scalars['ObjectId']>
  /** Materials upload id. */
  materialsUploadId?: Maybe<Scalars['ObjectId']>
  /** Total entities count. */
  totalCount?: Maybe<Scalars['Int']>
  /** When upload was done. */
  updatedAt: Scalars['MillisecondsDate']
  /** Who updated the schedule. */
  by?: Maybe<IUpdaterInfo>
  schedule?: Maybe<ISchedule>
}

export interface IHistoryItemInput {
  /** Schedule file name. */
  fileName?: Maybe<Scalars['String']>
  /** Schedule id. */
  scheduleId?: Maybe<Scalars['ObjectId']>
  /** Materials upload id. */
  materialsUploadId?: Maybe<Scalars['ObjectId']>
  /** Total entities count. */
  totalCount?: Maybe<Scalars['Int']>
  /** When upload was done. */
  updatedAt: Scalars['MillisecondsDate']
  /** Who updated the schedule. */
  by: IUpdaterInfoInput
}

export interface IInspectionOptions {
  /** Inspection deadline time. */
  deadlineTime?: Maybe<Scalars['Long']>
  /** Inspection frequency number. */
  inspectionFrequency?: Maybe<Scalars['Int']>
  /** Inspection frequency type. */
  inspectionFrequencyType?: Maybe<CalendricalType>
  /** Selected days to repeat. */
  selectedDaysToRepeat?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export interface IInspectionOptionsInput {
  /** Inspection deadline time. */
  deadlineTime?: Maybe<Scalars['Long']>
  /** Inspection frequency number. */
  inspectionFrequency?: Maybe<Scalars['Int']>
  /** Inspection frequency type. */
  inspectionFrequencyType?: Maybe<CalendricalType>
  /** Selected days to repeat. */
  selectedDaysToRepeat?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export interface IInstructions {
  /** List. */
  list?: Maybe<Array<Maybe<Scalars['String']>>>
  /** List type. */
  listType?: Maybe<Scalars['String']>
}

export interface IInstructionsInput {
  /** List. */
  list?: Maybe<Array<Maybe<Scalars['String']>>>
  /** List type. */
  listType?: Maybe<Scalars['String']>
}

export interface IIntegration extends ILocation {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateInterval>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Delivery hierarchy parent. */
  parent?: Maybe<IDeliveryHierarchyParent>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<LocationType>
  closingDate?: Maybe<Scalars['Long']>
}

export interface IIntegrationInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateIntervalInput>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Location parent. */
  parent?: Maybe<IDeliveryHierarchyParentInput>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
}

export interface IInteriorDoor extends ILocation {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateInterval>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Delivery hierarchy parent. */
  parent?: Maybe<IDeliveryHierarchyParent>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<LocationType>
  closingDate?: Maybe<Scalars['Long']>
  /** Max width. */
  maxWidth: Scalars['Float']
  /** Max height. */
  maxHeight: Scalars['Float']
  /** Max weight. */
  maxWeight: Scalars['Float']
}

export interface IInteriorDoorInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateIntervalInput>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Location parent. */
  parent?: Maybe<IDeliveryHierarchyParentInput>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Max width. */
  maxWidth?: Maybe<Scalars['Float']>
  /** Max height. */
  maxHeight?: Maybe<Scalars['Float']>
  /** Max weight. */
  maxWeight?: Maybe<Scalars['Float']>
}

export interface IInteriorPath extends ILocation {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateInterval>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Delivery hierarchy parent. */
  parent?: Maybe<IDeliveryHierarchyParent>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<LocationType>
  closingDate?: Maybe<Scalars['Long']>
  /** Max width. */
  maxWidth: Scalars['Float']
  /** Max height. */
  maxHeight: Scalars['Float']
  /** Max weight. */
  maxWeight: Scalars['Float']
}

export interface IInteriorPathInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateIntervalInput>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Location parent. */
  parent?: Maybe<IDeliveryHierarchyParentInput>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Max width. */
  maxWidth?: Maybe<Scalars['Float']>
  /** Max height. */
  maxHeight?: Maybe<Scalars['Float']>
  /** Max weight. */
  maxWeight?: Maybe<Scalars['Float']>
}

export enum InviteStatus {
  NotInvited = 'Not_Invited',
  Pending = 'Pending',
  Accepted = 'Accepted',
}

export interface ILastMobileActivityFilter {
  /** Filter type. */
  filterType: Scalars['String']
  /** Values. */
  values: Array<Maybe<Scalars['String']>>
}

export interface ILastMobileActivityFilterInput {
  /** Filter type. */
  filterType: Scalars['String']
  /** Values. */
  values: Array<Maybe<Scalars['String']>>
}

export interface ILastUpdatedItem {
  /** Updated page name. */
  pageName: Scalars['String']
  /** Uploaded schedule info. */
  updateInfo: IHistoryItem
}

export interface ILastUpdatedItemInput {
  /** Updated page name. */
  pageName: Scalars['String']
  /** Uploaded schedule info. */
  updateInfo: IHistoryItemInput
}

export interface ILatLng {
  /** Latitude. */
  lat: Scalars['Float']
  /** Longitude. */
  lng: Scalars['Float']
}

export interface ILatLngInput {
  /** Latitude. */
  lat: Scalars['Float']
  /** Longitude. */
  lng: Scalars['Float']
}

export interface ILevel extends ILocation {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateInterval>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Delivery hierarchy parent. */
  parent?: Maybe<IDeliveryHierarchyParent>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<LocationType>
  closingDate?: Maybe<Scalars['Long']>
  /** Is name implicitly set. */
  isNameImplicit?: Maybe<Scalars['Boolean']>
}

export interface ILevelInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateIntervalInput>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Location parent. */
  parent?: Maybe<IDeliveryHierarchyParentInput>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Is name implicitly set. */
  isNameImplicit?: Maybe<Scalars['Boolean']>
}

export interface ILevelsClosure {
  /** Date interval. */
  interval?: Maybe<IDateInterval>
  /** Levels. */
  levels?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export interface ILevelsClosureInput {
  /** Date interval. */
  interval?: Maybe<IDateIntervalInput>
  /** Levels. */
  levels?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export type ILocation = {
  id?: Maybe<Scalars['ObjectId']>
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** The name of the record. */
  name: Scalars['String']
  /** Project id. */
  projectId?: Maybe<Scalars['ObjectId']>
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateInterval>>>
  /** Color. */
  color: Scalars['String']
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Icon name. */
  iconName?: Maybe<Scalars['String']>
  /** Location parent. */
  parent?: Maybe<IDeliveryHierarchyParent>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<LocationType>
  closingDate?: Maybe<Scalars['Long']>
}

export interface ILocationChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ILocation>
}

export interface ILocationClosureInterval {
  startDate: Scalars['Long']
  endDate: Scalars['Long']
  isDaily?: Maybe<Scalars['Boolean']>
}

export interface ILocationClosureIntervalInput {
  startDate: Scalars['Long']
  endDate: Scalars['Long']
  isDaily?: Maybe<Scalars['Boolean']>
}

export interface ILocationList {
  data: Array<ILocation>
  page: IPageInfo
}

export interface ILocationPermitField extends IPermitFieldInterface {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  locationValues?: Maybe<Array<Maybe<ISiteLocation>>>
}

export interface ILocationPermitFieldInput {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  values?: Maybe<Array<Maybe<ISiteLocationInput>>>
}

export enum LocationType {
  Area = 'Area',
  Building = 'Building',
  Gate = 'Gate',
  Level = 'Level',
  LogisticsObject = 'LogisticsObject',
  OffloadingEquipment = 'OffloadingEquipment',
  Route = 'Route',
  Zone = 'Zone',
  VerticalObject = 'VerticalObject',
  Integration = 'Integration',
  InteriorPath = 'InteriorPath',
  InteriorDoor = 'InteriorDoor',
  Staging = 'Staging',
}

export interface ILogisticsConfigurations {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Order of maps. */
  maps?: Maybe<Array<Maybe<IOrderedSitemap>>>
}

export interface ILogisticsConfigurationsChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ILogisticsConfigurations>
}

export interface ILogisticsConfigurationsInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Order of maps. */
  maps?: Maybe<Array<Maybe<IOrderedSitemapInput>>>
}

export interface ILogisticsConfigurationsList {
  data: Array<ILogisticsConfigurations>
  page: IPageInfo
}

export interface ILogisticsObject extends ILocation {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateInterval>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Delivery hierarchy parent. */
  parent?: Maybe<IDeliveryHierarchyParent>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<LocationType>
  closingDate?: Maybe<Scalars['Long']>
}

export interface ILogisticsObjectInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateIntervalInput>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Location parent. */
  parent?: Maybe<IDeliveryHierarchyParentInput>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
}

export interface IMapboxTileset {
  /** Id. */
  id?: Maybe<Scalars['String']>
  /** Name. */
  name?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Center. */
  center: Array<Scalars['Float']>
  /** FileSize. */
  fileSize: Scalars['Long']
}

export interface IMaterialCategoryFieldDefinition {
  /** Name of definition. */
  name: Scalars['String']
  /** Type of definition. */
  type: Scalars['String']
}

export interface IMaterialConfiguration {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Order of maps. */
  maps?: Maybe<Array<Maybe<IOrderedSitemap>>>
  /** Saved groups. */
  savedGroups?: Maybe<Array<Maybe<IFilterGroup>>>
  /** Material list columns configuration. */
  columnsConfig?: Maybe<Array<Maybe<IColumnConfiguration>>>
}

export interface IMaterialConfigurationChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IMaterialConfiguration>
}

export interface IMaterialConfigurationInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Order of maps. */
  maps?: Maybe<Array<Maybe<IOrderedSitemapInput>>>
  /** Saved groups. */
  savedGroups?: Maybe<Array<Maybe<IFilterGroupInput>>>
  /** Material list columns configuration. */
  columnsConfig?: Maybe<Array<Maybe<IColumnConfigurationInput>>>
}

export interface IMaterialFormField extends IPermitFieldInterface {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  materialValues?: Maybe<Array<Maybe<IFormMaterial>>>
}

export interface IMaterialFormFieldInput {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  values?: Maybe<Array<Maybe<IFormMaterialInput>>>
}

export interface IMaterialProcurementData {
  /** Procurement Id. */
  procurementId?: Maybe<Scalars['String']>
  /** Install location id. */
  installLocationId?: Maybe<Scalars['ObjectId']>
  /** Delivery location id. */
  deliveryLocationId?: Maybe<Scalars['ObjectId']>
  /** Description. */
  description?: Maybe<Scalars['String']>
  /** Planned quantity. */
  plannedQuantity?: Maybe<Scalars['Float']>
}

export interface IMaterialProcurementDataInput {
  /** Procurement Id. */
  procurementId?: Maybe<Scalars['String']>
  /** Install location id. */
  installLocationId?: Maybe<Scalars['ObjectId']>
  /** Delivery location id. */
  deliveryLocationId?: Maybe<Scalars['ObjectId']>
  /** Description. */
  description?: Maybe<Scalars['String']>
  /** Planned quantity. */
  plannedQuantity?: Maybe<Scalars['Float']>
}

export interface IMaturixCast {
  /** Cast Id. */
  castId?: Maybe<Scalars['String']>
  /** Cast name. */
  castName?: Maybe<Scalars['String']>
  /** Cast status. */
  status?: Maybe<Scalars['String']>
  /** Maturix project Id. */
  maturixProjectId?: Maybe<Scalars['String']>
  /** Maturix project name. */
  projectName?: Maybe<Scalars['String']>
  /** Monitorings. */
  monitorings?: Maybe<Array<Maybe<IMonitoringLatestData>>>
}

export interface IMaturixMonitoringData {
  /** Timestamp. */
  timestamp?: Maybe<Scalars['Long']>
  /** Temperature. */
  temperature?: Maybe<Scalars['Float']>
  /** Temperature unit. */
  temperatureUnit?: Maybe<Scalars['String']>
  /** Maturity. */
  maturity?: Maybe<Scalars['Float']>
  /** Strength. */
  strength?: Maybe<Scalars['Float']>
  /** strength unit. */
  strengthUnit?: Maybe<Scalars['String']>
}

export interface IMaturixMonitoringDataResponse {
  /** Monitoring info. */
  monitoring?: Maybe<IMonitoringInfo>
  /** Data. */
  data?: Maybe<Array<Maybe<IMaturixMonitoringData>>>
}

export interface IMaturixProject {
  /** Project Id. */
  id?: Maybe<Scalars['String']>
  /** Project name. */
  projectName?: Maybe<Scalars['String']>
  /** Project description. */
  projectDescription?: Maybe<Scalars['String']>
  /** Start time. */
  startTime?: Maybe<Scalars['Long']>
  /** Project status. */
  status?: Maybe<Scalars['String']>
  /** End time. */
  endTime?: Maybe<Scalars['Long']>
}

export interface IMeasure {
  /** Units. */
  units?: Maybe<Scalars['String']>
  /** Value. */
  value?: Maybe<Scalars['String']>
}

export interface IMeasureInput {
  /** Units. */
  units?: Maybe<Scalars['String']>
  /** Value. */
  value?: Maybe<Scalars['String']>
}

export interface IMeasurePermitField extends IPermitFieldInterface {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  measureValues?: Maybe<Array<Maybe<IMeasure>>>
}

export interface IMeasurePermitFieldInput {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  values?: Maybe<Array<Maybe<IMeasureInput>>>
}

export interface IMergeResult {
  mergeLogId?: Maybe<Scalars['ObjectId']>
  activitiesUpdates?: Maybe<Scalars['Long']>
  activityAssignmentsUpdates?: Maybe<Scalars['Long']>
  activityListCustomFiltersUpdates?: Maybe<Scalars['Long']>
  announcementAssignmentsUpdates?: Maybe<Scalars['Long']>
  castAssignmentsUpdates?: Maybe<Scalars['Long']>
  closureAssignmentsUpdates?: Maybe<Scalars['Long']>
  companyRelationshipsUpdates?: Maybe<Scalars['Long']>
  deliveriesUpdates?: Maybe<Scalars['Long']>
  deliveryAssignmentsUpdates?: Maybe<Scalars['Long']>
  deliveryListCustomFiltersUpdates?: Maybe<Scalars['Long']>
  operationRulesUpdates?: Maybe<Scalars['Long']>
  sitePermitAssignmentsUpdates?: Maybe<Scalars['Long']>
  sitePermitsUpdates?: Maybe<Scalars['Long']>
  userProjectsUpdates?: Maybe<Scalars['Long']>
  whiteListsUpdates?: Maybe<Scalars['Long']>
  workflowsCustomFiltersUpdates?: Maybe<Scalars['Long']>
  locationsUpdates?: Maybe<Scalars['Long']>
}

export interface IMessage {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Attachments. */
  attachments?: Maybe<Array<Maybe<IAttachment>>>
  /** Activity Id. */
  activityId?: Maybe<Scalars['String']>
  /** File Id. */
  photoId?: Maybe<Scalars['String']>
  /** Thread Id. */
  threadId?: Maybe<Scalars['ObjectId']>
  /** Text. */
  text?: Maybe<Scalars['String']>
  /** User Id. */
  userId?: Maybe<Scalars['ObjectId']>
  /** Read by. */
  readBy?: Maybe<Array<Maybe<IReadBy>>>
}

export interface IMessageChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IMessage>
}

export interface IMessageInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Attachments. */
  attachments?: Maybe<Array<Maybe<IAttachmentInput>>>
  /** Activity Id. */
  activityId?: Maybe<Scalars['String']>
  /** File Id. */
  photoId?: Maybe<Scalars['String']>
  /** Thread Id. */
  threadId?: Maybe<Scalars['ObjectId']>
  /** Text. */
  text?: Maybe<Scalars['String']>
  /** User Id. */
  userId?: Maybe<Scalars['ObjectId']>
  /** Read by. */
  readBy?: Maybe<Array<Maybe<IReadByInput>>>
}

export interface IMessageList {
  data: Array<IMessage>
  page: IPageInfo
}

export interface IMonitoringData {
  /** Timestamp. */
  timestamp?: Maybe<Scalars['MillisecondsDate']>
  /** Temperature. */
  temperature?: Maybe<Scalars['Decimal']>
  /** Temperature unit. */
  temperatureUnit?: Maybe<Scalars['String']>
  /** Maturity. */
  maturity?: Maybe<Scalars['Decimal']>
  /** Strength. */
  strength?: Maybe<Scalars['Decimal']>
  /** Strength unit. */
  strengthUnit?: Maybe<Scalars['String']>
}

export interface IMonitoringInfo {
  /** Monitoring Id. */
  monitoringId?: Maybe<Scalars['String']>
  /** Monitoring name. */
  monitoringName?: Maybe<Scalars['String']>
  /** Monitoring status. */
  status?: Maybe<MonitoringStatus>
  /** Device Id. */
  deviceId?: Maybe<Scalars['String']>
}

export interface IMonitoringLatestData {
  /** Monitoring. */
  monitoring?: Maybe<IMonitoringInfo>
  /** Latest data. */
  latestData?: Maybe<IMonitoringData>
}

export enum MonitoringStatus {
  Active = 'Active',
  Stopped = 'Stopped',
  Planned = 'Planned',
}

export interface IMutation {
  deleteThread?: Maybe<Scalars['Boolean']>
  deleteManyThreads?: Maybe<Scalars['Boolean']>
  deleteConstraint?: Maybe<Scalars['Boolean']>
  deleteManyConstraints?: Maybe<Scalars['Boolean']>
  deleteWhiteListItem?: Maybe<Scalars['Boolean']>
  deleteManyWhiteListItems?: Maybe<Scalars['Boolean']>
  deleteClosureAssignment?: Maybe<Scalars['Boolean']>
  deleteManyClosureAssignments?: Maybe<Scalars['Boolean']>
  saveConstraint?: Maybe<IConstraint>
  saveManyConstraints?: Maybe<Array<Maybe<IConstraint>>>
  saveManyContentObjects?: Maybe<Array<Maybe<IContentObject>>>
  saveWhiteListItem?: Maybe<IWhiteListItem>
  saveDevice?: Maybe<Scalars['Boolean']>
  saveManyClosureFollowings?: Maybe<Array<Maybe<IClosureFollowing>>>
  saveManyClosures?: Maybe<Array<Maybe<IClosure>>>
  deleteManyClosures?: Maybe<Scalars['Boolean']>
  saveClosureAssignment?: Maybe<IClosureAssignment>
  saveManyClosureAssignments?: Maybe<Array<Maybe<IClosureAssignment>>>
  saveBasemap?: Maybe<IBasemap>
  saveManyBasemaps?: Maybe<Array<Maybe<IBasemap>>>
  savePhoto?: Maybe<IPhoto>
  saveManyPhotos?: Maybe<Array<Maybe<IPhoto>>>
  saveThread?: Maybe<IThread>
  saveManyThreads?: Maybe<Array<Maybe<IThread>>>
  saveMessage?: Maybe<IMessage>
  saveManyMessages?: Maybe<Array<Maybe<IMessage>>>
  savePermitMessage?: Maybe<IMessage>
  saveAnalyticsSettings?: Maybe<IAnalyticsSettings>
  saveManyAnalyticsSettings?: Maybe<Array<Maybe<IAnalyticsSettings>>>
  deleteClosureFollowing?: Maybe<Scalars['Boolean']>
  deleteManyClosureFollowings?: Maybe<Scalars['Boolean']>
  deleteDeliveryFollowing?: Maybe<Scalars['Boolean']>
  deleteManyDeliveryFollowings?: Maybe<Scalars['Boolean']>
  deleteDeliveryAssignment?: Maybe<Scalars['Boolean']>
  deleteManyDeliveryAssignments?: Maybe<Scalars['Boolean']>
  deleteDeliveryListCustomFilter?: Maybe<Scalars['Boolean']>
  deleteManyDeliveryListCustomFilters?: Maybe<Scalars['Boolean']>
  softDeleteManyDeliveries?: Maybe<Scalars['Boolean']>
  softDeleteManyDeliveryUnits?: Maybe<Scalars['Boolean']>
  softDeleteManyVehicleTypes?: Maybe<Scalars['Boolean']>
  saveDeliveryAssignment?: Maybe<IDeliveryAssignment>
  saveManyDeliveryAssignments?: Maybe<Array<Maybe<IDeliveryAssignment>>>
  saveDeliveryUnit?: Maybe<IDeliveryUnit>
  saveManyDeliveryUnits?: Maybe<Array<Maybe<IDeliveryUnit>>>
  saveDeliveryVehicleType?: Maybe<IDeliveryVehicleType>
  saveManyDeliveryVehicleTypes?: Maybe<Array<Maybe<IDeliveryVehicleType>>>
  saveDeliveryListCustomFilter?: Maybe<IDeliveryListCustomFilter>
  saveManyDeliveryListCustomFilters?: Maybe<
    Array<Maybe<IDeliveryListCustomFilter>>
  >
  createDelivery?: Maybe<IDelivery>
  createDeliveries?: Maybe<Array<Maybe<IDelivery>>>
  updateDelivery?: Maybe<IDelivery>
  updateDeliveries?: Maybe<Array<Maybe<IDelivery>>>
  saveManyDeliveryFollowings?: Maybe<Array<Maybe<IDeliveryFollowing>>>
  sendSmsDeliveryTicket?: Maybe<Scalars['Boolean']>
  /** Create recurring deliveries. */
  createRecurringDeliveries?: Maybe<Array<Maybe<IDelivery>>>
  /** Update recurring deliveries. */
  updateRecurringDeliveries?: Maybe<Array<Maybe<IDelivery>>>
  changeDeliveriesStatus?: Maybe<Scalars['Boolean']>
  updateDeliveriesFields?: Maybe<Scalars['Boolean']>
  deleteProject?: Maybe<Scalars['Boolean']>
  deleteManyProjects?: Maybe<Scalars['Boolean']>
  deleteProjectMaterialOptions?: Maybe<Scalars['Boolean']>
  deleteManyProjectMaterialOptions?: Maybe<Scalars['Boolean']>
  deleteProjectAddress?: Maybe<Scalars['Boolean']>
  deleteManyProjectAddresses?: Maybe<Scalars['Boolean']>
  deleteProjectColoringOptions?: Maybe<Scalars['Boolean']>
  deleteManyProjectColoringOptions?: Maybe<Scalars['Boolean']>
  deleteProjectStatusUpdateOptions?: Maybe<Scalars['Boolean']>
  deleteManyProjectStatusUpdateOptions?: Maybe<Scalars['Boolean']>
  deleteProjectTypeOptions?: Maybe<Scalars['Boolean']>
  deleteManyProjectTypeOptions?: Maybe<Scalars['Boolean']>
  deleteDeliveryConfigurations?: Maybe<Scalars['Boolean']>
  deleteManyDeliveryConfigurations?: Maybe<Scalars['Boolean']>
  deleteActivitiesConfigurations?: Maybe<Scalars['Boolean']>
  deleteManyActivitiesConfigurations?: Maybe<Scalars['Boolean']>
  deleteHierarchyConfigurations?: Maybe<Scalars['Boolean']>
  deleteManyHierarchyConfigurations?: Maybe<Scalars['Boolean']>
  deleteLogisticsConfigurations?: Maybe<Scalars['Boolean']>
  deleteManyLogisticsConfigurations?: Maybe<Scalars['Boolean']>
  deleteFormsConfigurations?: Maybe<Scalars['Boolean']>
  deleteManyFormsConfigurations?: Maybe<Scalars['Boolean']>
  deleteDeliveryFieldsConfigurations?: Maybe<Scalars['Boolean']>
  deleteManyDeliveryFieldsConfigurations?: Maybe<Scalars['Boolean']>
  deletePresentationSettings?: Maybe<Scalars['Boolean']>
  deleteManyPresentationSettings?: Maybe<Scalars['Boolean']>
  deleteZoneMapThresholds?: Maybe<Scalars['Boolean']>
  deleteManyZoneMapThresholds?: Maybe<Scalars['Boolean']>
  deleteProjectHistory?: Maybe<Scalars['Boolean']>
  deleteManyProjectHistories?: Maybe<Scalars['Boolean']>
  saveManyProjects?: Maybe<Array<Maybe<IProject>>>
  archiveProject?: Maybe<Scalars['Boolean']>
  saveManyProjectAddresses?: Maybe<Array<Maybe<IProjectAddress>>>
  upsertMaturixTokenForProject?: Maybe<Scalars['Boolean']>
  deleteMaturixTokenForProject?: Maybe<Scalars['Boolean']>
  upsertMaturixData?: Maybe<Scalars['Boolean']>
  deleteMaturixFromProject?: Maybe<Scalars['Boolean']>
  saveProjectMaterialOptions?: Maybe<IProjectMaterialOptions>
  saveManyProjectMaterialOptions?: Maybe<Array<Maybe<IProjectMaterialOptions>>>
  saveProjectColoringOptions?: Maybe<IProjectColoringOptions>
  saveManyProjectColoringOptions?: Maybe<Array<Maybe<IProjectColoringOptions>>>
  saveProjectStatusUpdateOptions?: Maybe<IProjectStatusUpdateOptions>
  saveManyProjectStatusUpdateOptions?: Maybe<
    Array<Maybe<IProjectStatusUpdateOptions>>
  >
  saveProjectTypeOptions?: Maybe<IProjectTypeOptions>
  saveManyProjectTypeOptions?: Maybe<Array<Maybe<IProjectTypeOptions>>>
  saveDeliveryConfigurations?: Maybe<IDeliveryConfigurations>
  saveManyDeliveryConfigurations?: Maybe<Array<Maybe<IDeliveryConfigurations>>>
  saveActivitiesConfigurations?: Maybe<IActivitiesConfigurations>
  saveManyActivitiesConfigurations?: Maybe<
    Array<Maybe<IActivitiesConfigurations>>
  >
  saveHierarchyConfigurations?: Maybe<IHierarchyConfigurations>
  saveManyHierarchyConfigurations?: Maybe<
    Array<Maybe<IHierarchyConfigurations>>
  >
  saveLogisticsConfigurations?: Maybe<ILogisticsConfigurations>
  saveManyLogisticsConfigurations?: Maybe<
    Array<Maybe<ILogisticsConfigurations>>
  >
  saveFormsConfigurations?: Maybe<IFormsConfigurations>
  saveManyFormsConfigurations?: Maybe<Array<Maybe<IFormsConfigurations>>>
  saveDeliveryFieldsConfigurations?: Maybe<IDeliveryFieldsConfigurations>
  saveManyDeliveryFieldsConfigurations?: Maybe<
    Array<Maybe<IDeliveryFieldsConfigurations>>
  >
  savePresentationSettings?: Maybe<IPresentationSettings>
  saveManyPresentationSettings?: Maybe<Array<Maybe<IPresentationSettings>>>
  saveZoneMapThresholds?: Maybe<IZoneMapThresholds>
  saveManyZoneMapThresholds?: Maybe<Array<Maybe<IZoneMapThresholds>>>
  saveProjectHistory?: Maybe<IProjectHistory>
  saveManyProjectHistories?: Maybe<Array<Maybe<IProjectHistory>>>
  saveMaterialConfiguration?: Maybe<IMaterialConfiguration>
  saveManyMaterialConfigurations?: Maybe<Array<Maybe<IMaterialConfiguration>>>
  saveSitemap?: Maybe<ISitemap>
  saveManySitemaps?: Maybe<Array<Maybe<ISitemap>>>
  saveSitemapItem?: Maybe<ISitemapItem>
  saveManySitemapItems?: Maybe<Array<Maybe<ISitemapItem>>>
  saveGlobeView?: Maybe<IGlobeView>
  saveManyGlobeViews?: Maybe<Array<Maybe<IGlobeView>>>
  saveAnnouncementAssignment?: Maybe<IAnnouncementAssignment>
  saveManyAnnouncementAssignments?: Maybe<Array<Maybe<IAnnouncementAssignment>>>
  saveTileset?: Maybe<ITileset>
  saveManyTilesets?: Maybe<Array<Maybe<ITileset>>>
  saveSitemapSpecificItemData?: Maybe<Array<Maybe<ISitemapSpecificItemData>>>
  saveGlobeViewSpecificItemData?: Maybe<
    Array<Maybe<IGlobeViewSpecificItemData>>
  >
  saveGlobeViewStyle?: Maybe<IGlobeView>
  saveManyAnnouncementFollowings?: Maybe<Array<Maybe<IAnnouncementFollowing>>>
  addSitePermitInspectionChange?: Maybe<ISitePermitInspectionChange>
  saveManyPermitTypes?: Maybe<Array<Maybe<IPermitType>>>
  saveManySitePermitFollowings?: Maybe<Array<Maybe<ISitePermitFollowing>>>
  addSitePermitInspection?: Maybe<ISitePermitInspection>
  uploadGeoTIFFToMapbox?: Maybe<ITileset>
  saveManyFormCategories?: Maybe<Array<Maybe<IFormCategory>>>
  softDeletePermitType?: Maybe<Scalars['Boolean']>
  softDeleteManyPermitTypes?: Maybe<Scalars['Boolean']>
  deleteSitePermitFollowing?: Maybe<Scalars['Boolean']>
  deleteManySitePermitFollowings?: Maybe<Scalars['Boolean']>
  deleteManySitemapItems?: Maybe<Scalars['Boolean']>
  deleteGlobeView?: Maybe<Scalars['Boolean']>
  deleteSitemap?: Maybe<Scalars['Boolean']>
  deleteSitemapSpecificItemData?: Maybe<Scalars['Boolean']>
  deleteManySitemapSpecificItemData?: Maybe<Scalars['Boolean']>
  deleteGlobeViewSpecificItemData?: Maybe<Scalars['Boolean']>
  deleteManyGlobeViewSpecificItemData?: Maybe<Scalars['Boolean']>
  deleteSitePermitInspection?: Maybe<Scalars['Boolean']>
  deleteManySitePermitInspections?: Maybe<Scalars['Boolean']>
  deleteSitePermitInspectionChange?: Maybe<Scalars['Boolean']>
  deleteManySitePermitInspectionChanges?: Maybe<Scalars['Boolean']>
  softDeleteManyForms?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  saveSitePermitAssignment?: Maybe<ISitePermitAssignment>
  saveManySitePermitAssignments?: Maybe<Array<Maybe<ISitePermitAssignment>>>
  deleteSitePermitAssignment?: Maybe<Scalars['Boolean']>
  deleteManySitePermitAssignments?: Maybe<Scalars['Boolean']>
  saveManySitePermits?: Maybe<Array<Maybe<ISitePermit>>>
  saveManyAnnouncements?: Maybe<Array<Maybe<IAnnouncement>>>
  deleteManyAnnouncements?: Maybe<Scalars['Boolean']>
  deleteAnnouncement?: Maybe<Scalars['Boolean']>
  deleteAnnouncementAssignment?: Maybe<Scalars['Boolean']>
  deleteManyAnnouncementAssignments?: Maybe<Scalars['Boolean']>
  deleteAnnouncementFollowing?: Maybe<Scalars['Boolean']>
  deleteManyAnnouncementFollowings?: Maybe<Scalars['Boolean']>
  deleteTileset?: Maybe<Scalars['Boolean']>
  deleteManyTilesets?: Maybe<Scalars['Boolean']>
  deleteTilesetFromMapbox?: Maybe<Scalars['Boolean']>
  softDeleteManyFormCategories?: Maybe<Scalars['Boolean']>
  deleteTeam?: Maybe<Scalars['Boolean']>
  deleteManyTeams?: Maybe<Scalars['Boolean']>
  deleteUserRole?: Maybe<Scalars['Boolean']>
  deleteManyUserRoles?: Maybe<Scalars['Boolean']>
  deleteTrade?: Maybe<Scalars['Boolean']>
  deleteManyTrades?: Maybe<Scalars['Boolean']>
  removeUserProjects?: Maybe<Scalars['Boolean']>
  saveTeam?: Maybe<ITeam>
  saveManyTeams?: Maybe<Array<Maybe<ITeam>>>
  saveUserRole?: Maybe<IUserRole>
  saveManyUserRoles?: Maybe<Array<Maybe<IUserRole>>>
  saveManyUserProjects?: Maybe<Array<Maybe<IUserProject>>>
  saveTrade?: Maybe<ITrade>
  saveManyTrades?: Maybe<Array<Maybe<ITrade>>>
  /** 'Sign Up' field */
  signUp?: Maybe<InviteStatus>
  /** Request project access field */
  requestProjectAccess?: Maybe<InviteStatus>
  saveMembers?: Maybe<Array<Maybe<IUser>>>
  uploadUsersFromCsv?: Maybe<Array<Maybe<IUser>>>
  deleteUsers?: Maybe<Scalars['Boolean']>
  resendInviteKey?: Maybe<Scalars['Boolean']>
  getPresentationUserToken?: Maybe<Scalars['String']>
  sendWelcomeEmails?: Maybe<Scalars['Boolean']>
  sendInvites?: Maybe<Scalars['Boolean']>
  deleteOperationRule?: Maybe<Scalars['Boolean']>
  deleteManyOperationRules?: Maybe<Scalars['Boolean']>
  saveOperationRule?: Maybe<IOperationRule>
  saveManyOperationRules?: Maybe<Array<Maybe<IOperationRule>>>
  saveNotification?: Maybe<INotification>
  saveManyNotifications?: Maybe<Array<Maybe<INotification>>>
  saveActivity?: Maybe<IActivity>
  saveManyActivities?: Maybe<Array<Maybe<IActivity>>>
  uploadSchedule?: Maybe<Scalars['String']>
  uploadExcelSchedule?: Maybe<Scalars['String']>
  saveActivityCodeRelationship?: Maybe<IActivityCodeRelationship>
  saveManyActivityCodeRelationships?: Maybe<
    Array<Maybe<IActivityCodeRelationship>>
  >
  saveStatusUpdate?: Maybe<IStatusUpdate>
  saveManyStatusUpdates?: Maybe<Array<Maybe<IStatusUpdate>>>
  softDeleteManyStatusUpdates?: Maybe<Scalars['Boolean']>
  updateStatusUpdatesMultiDates?: Maybe<Scalars['Boolean']>
  updateStatusUpdatesMissedStatuses?: Maybe<Scalars['Boolean']>
  activityWithData?: Maybe<IActivity>
  saveActivityLocation?: Maybe<IActivityLocation>
  saveManyActivityLocations?: Maybe<Array<Maybe<IActivityLocation>>>
  deleteActivityLocation?: Maybe<Scalars['Boolean']>
  deleteManyActivityLocations?: Maybe<Scalars['Boolean']>
  saveActivityFiltersSettings?: Maybe<IActivityFiltersSettings>
  saveManyActivityFiltersSettings?: Maybe<
    Array<Maybe<IActivityFiltersSettings>>
  >
  saveManyActivityFollowings?: Maybe<Array<Maybe<IActivityFollowing>>>
  updateActivityFields?: Maybe<Scalars['Boolean']>
  saveActivityListCustomFilter?: Maybe<IActivityListCustomFilter>
  saveManyActivityListCustomFilters?: Maybe<
    Array<Maybe<IActivityListCustomFilter>>
  >
  saveActivityPreset?: Maybe<IActivityPreset>
  saveManyActivityPresets?: Maybe<Array<Maybe<IActivityPreset>>>
  saveActivityAssignment?: Maybe<IActivityAssignment>
  saveManyActivityAssignments?: Maybe<Array<Maybe<IActivityAssignment>>>
  softDeleteManyActivities?: Maybe<Scalars['Boolean']>
  deleteActivityFollowing?: Maybe<Scalars['Boolean']>
  deleteManyActivityFollowings?: Maybe<Scalars['Boolean']>
  deleteActivityPreset?: Maybe<Scalars['Boolean']>
  deleteManyActivityPresets?: Maybe<Scalars['Boolean']>
  deleteActivityListCustomFilter?: Maybe<Scalars['Boolean']>
  deleteManyActivityListCustomFilters?: Maybe<Scalars['Boolean']>
  deleteActivityAssignment?: Maybe<Scalars['Boolean']>
  deleteManyActivityAssignments?: Maybe<Scalars['Boolean']>
  saveManyAreas?: Maybe<Array<Maybe<IArea>>>
  saveManyGates?: Maybe<Array<Maybe<IGate>>>
  saveManyBuildings?: Maybe<Array<Maybe<IBuilding>>>
  saveManyLevels?: Maybe<Array<Maybe<ILevel>>>
  saveManyRoutes?: Maybe<Array<Maybe<IRoute>>>
  saveManyZones?: Maybe<Array<Maybe<IZone>>>
  saveManyLogisticsObjects?: Maybe<Array<Maybe<ILogisticsObject>>>
  saveManyVerticalObjects?: Maybe<Array<Maybe<IVerticalObject>>>
  saveManyOffloadingEquipments?: Maybe<Array<Maybe<IOffloadingEquipment>>>
  saveManyIntegrations?: Maybe<Array<Maybe<IIntegration>>>
  saveManyInteriorDoors?: Maybe<Array<Maybe<IInteriorDoor>>>
  saveManyInteriorPaths?: Maybe<Array<Maybe<IInteriorPath>>>
  saveManyStagings?: Maybe<Array<Maybe<IStaging>>>
  softDeleteVerticalObject?: Maybe<Scalars['Boolean']>
  softDeleteManyVerticalObjects?: Maybe<Scalars['Boolean']>
  softDeleteManyLocations?: Maybe<Scalars['Boolean']>
  deleteLocation?: Maybe<Scalars['Boolean']>
  deleteManyLocations?: Maybe<Scalars['Boolean']>
  saveProjectMaterial?: Maybe<IProjectMaterial>
  saveManyProjectMaterials?: Maybe<Array<Maybe<IProjectMaterial>>>
  saveProjectMaterialCategory?: Maybe<IProjectMaterialCategory>
  saveManyProjectMaterialCategories?: Maybe<
    Array<Maybe<IProjectMaterialCategory>>
  >
  fillupProjectMaterialsData?: Maybe<Scalars['Boolean']>
  uploadMaterialsExcel?: Maybe<Scalars['String']>
  restoreMaterials?: Maybe<Scalars['Boolean']>
  saveCompanyRelationship?: Maybe<ICompanyRelationship>
  saveManyCompanyRelationships?: Maybe<Array<Maybe<ICompanyRelationship>>>
  savecompanyTypeTag?: Maybe<IEntityTag>
  saveManycompanyTypeTags?: Maybe<Array<Maybe<IEntityTag>>>
  softDeleteCompany?: Maybe<Scalars['Boolean']>
  softDeleteManyCompanies?: Maybe<Scalars['Boolean']>
  saveManyCompanies?: Maybe<Array<Maybe<ICompany>>>
  recoverManyCompanies?: Maybe<Scalars['Boolean']>
  deleteCompanyRelationship?: Maybe<Scalars['Boolean']>
  deleteManyCompanyRelationships?: Maybe<Scalars['Boolean']>
  /** Link company with the StruxHub company. */
  linkStruxHubCompany?: Maybe<ICompany>
  /** UnLink company with the StruxHub company. */
  unlinkStruxHubCompany?: Maybe<ICompany>
  /** Merge companies and return new merged company. */
  mergeCompanies?: Maybe<IMergeResult>
  deleteCastFollowing?: Maybe<Scalars['Boolean']>
  deleteManyCastFollowings?: Maybe<Scalars['Boolean']>
  deleteCastAssignment?: Maybe<Scalars['Boolean']>
  deleteManyCastAssignments?: Maybe<Scalars['Boolean']>
  saveManyCastFollowings?: Maybe<Array<Maybe<ICastFollowing>>>
  saveCastAssignment?: Maybe<ICastAssignment>
  saveManyCastAssignments?: Maybe<Array<Maybe<ICastAssignment>>>
  generateReport?: Maybe<Scalars['ObjectId']>
  deleteScanner?: Maybe<Scalars['Boolean']>
  deleteManyScanners?: Maybe<Scalars['Boolean']>
  saveManyScanners?: Maybe<Array<Maybe<IScanner>>>
  saveScanHistory?: Maybe<IScanHistory>
  saveManyScanHistories?: Maybe<Array<Maybe<IScanHistory>>>
  saveWorkflowsCustomFilter?: Maybe<IWorkflowsCustomFilter>
  saveManyWorkflowsCustomFilters?: Maybe<Array<Maybe<IWorkflowsCustomFilter>>>
  deleteWorkflowsCustomFilter?: Maybe<Scalars['Boolean']>
  deleteManyWorkflowsCustomFilters?: Maybe<Scalars['Boolean']>
  transferProjectData?: Maybe<Scalars['Boolean']>
  saveConcreteDirectDataForProject?: Maybe<Scalars['Boolean']>
  saveConcreteDirectSQSConfiguration?: Maybe<Scalars['Boolean']>
  deleteConcreteDirectFromProject?: Maybe<Scalars['Boolean']>
}

export type IMutationDeleteThreadArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyThreadsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteConstraintArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyConstraintsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteWhiteListItemArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyWhiteListItemsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteClosureAssignmentArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyClosureAssignmentsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSaveConstraintArgs = {
  constraint: IConstraintInput
}

export type IMutationSaveManyConstraintsArgs = {
  constraints: Array<Maybe<IConstraintInput>>
}

export type IMutationSaveManyContentObjectsArgs = {
  contentObjects: Array<Maybe<IContentObjectInput>>
}

export type IMutationSaveWhiteListItemArgs = {
  whiteListItem: IWhiteListItemInput
}

export type IMutationSaveDeviceArgs = {
  token: Scalars['String']
  locale?: Maybe<Scalars['String']>
}

export type IMutationSaveManyClosureFollowingsArgs = {
  closureFollowings: Array<Maybe<IClosureFollowingInput>>
}

export type IMutationSaveManyClosuresArgs = {
  closures: Array<Maybe<IClosureInput>>
}

export type IMutationDeleteManyClosuresArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSaveClosureAssignmentArgs = {
  closureAssignment: IClosureAssignmentInput
}

export type IMutationSaveManyClosureAssignmentsArgs = {
  closureAssignments: Array<Maybe<IClosureAssignmentInput>>
}

export type IMutationSaveBasemapArgs = {
  basemap: IBasemapInput
}

export type IMutationSaveManyBasemapsArgs = {
  basemaps: Array<Maybe<IBasemapInput>>
}

export type IMutationSavePhotoArgs = {
  photo: IPhotoInput
}

export type IMutationSaveManyPhotosArgs = {
  photos: Array<Maybe<IPhotoInput>>
}

export type IMutationSaveThreadArgs = {
  thread: IThreadInput
}

export type IMutationSaveManyThreadsArgs = {
  threads: Array<Maybe<IThreadInput>>
}

export type IMutationSaveMessageArgs = {
  message: IMessageInput
}

export type IMutationSaveManyMessagesArgs = {
  messages: Array<Maybe<IMessageInput>>
}

export type IMutationSavePermitMessageArgs = {
  permitId: Scalars['ObjectId']
  message: IMessageInput
}

export type IMutationSaveAnalyticsSettingsArgs = {
  analyticsSettings: IAnalyticsSettingsInput
}

export type IMutationSaveManyAnalyticsSettingsArgs = {
  analyticsSettings: Array<Maybe<IAnalyticsSettingsInput>>
}

export type IMutationDeleteClosureFollowingArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyClosureFollowingsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteDeliveryFollowingArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyDeliveryFollowingsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteDeliveryAssignmentArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyDeliveryAssignmentsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteDeliveryListCustomFilterArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyDeliveryListCustomFiltersArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSoftDeleteManyDeliveriesArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSoftDeleteManyDeliveryUnitsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSoftDeleteManyVehicleTypesArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSaveDeliveryAssignmentArgs = {
  deliveryAssignment: IDeliveryAssignmentInput
}

export type IMutationSaveManyDeliveryAssignmentsArgs = {
  deliveryAssignments: Array<Maybe<IDeliveryAssignmentInput>>
}

export type IMutationSaveDeliveryUnitArgs = {
  deliveryUnit: IDeliveryUnitInput
}

export type IMutationSaveManyDeliveryUnitsArgs = {
  deliveryUnits: Array<Maybe<IDeliveryUnitInput>>
}

export type IMutationSaveDeliveryVehicleTypeArgs = {
  deliveryVehicleType: IDeliveryVehicleTypeInput
}

export type IMutationSaveManyDeliveryVehicleTypesArgs = {
  deliveryVehicleTypes: Array<Maybe<IDeliveryVehicleTypeInput>>
}

export type IMutationSaveDeliveryListCustomFilterArgs = {
  deliveryListCustomFilter: IDeliveryListCustomFilterInput
}

export type IMutationSaveManyDeliveryListCustomFiltersArgs = {
  deliveryListCustomFilters: Array<Maybe<IDeliveryListCustomFilterInput>>
}

export type IMutationCreateDeliveryArgs = {
  delivery: IDeliveryInput
  timezoneId: Scalars['String']
}

export type IMutationCreateDeliveriesArgs = {
  deliveries: Array<Maybe<IDeliveryInput>>
}

export type IMutationUpdateDeliveryArgs = {
  delivery: IDeliveryInput
  message?: Maybe<ISaveMessageInput>
  timezoneId: Scalars['String']
}

export type IMutationUpdateDeliveriesArgs = {
  deliveries: Array<Maybe<IDeliveryInput>>
}

export type IMutationSaveManyDeliveryFollowingsArgs = {
  deliveryFollowings: Array<Maybe<IDeliveryFollowingInput>>
}

export type IMutationSendSmsDeliveryTicketArgs = {
  deliveryId?: Maybe<Scalars['String']>
}

export type IMutationCreateRecurringDeliveriesArgs = {
  delivery: IDeliveryInput
  recurringSettings: IRecurringDeliveriesSettingsInput
  timezoneId: Scalars['String']
}

export type IMutationUpdateRecurringDeliveriesArgs = {
  delivery: IDeliveryInput
  message?: Maybe<ISaveMessageInput>
  recurringSettings: IRecurringDeliveriesSettingsInput
  relatedRecurringIds: Array<Maybe<Scalars['ObjectId']>>
  timezoneId: Scalars['String']
}

export type IMutationChangeDeliveriesStatusArgs = {
  changeDeliveriesStatus: IChangeDeliveriesStatusCommandInput
}

export type IMutationUpdateDeliveriesFieldsArgs = {
  updateDeliveriesFields?: Maybe<IUpdateDeliveriesFieldsInput>
}

export type IMutationDeleteProjectArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyProjectsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteProjectMaterialOptionsArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyProjectMaterialOptionsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteProjectAddressArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyProjectAddressesArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteProjectColoringOptionsArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyProjectColoringOptionsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteProjectStatusUpdateOptionsArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyProjectStatusUpdateOptionsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteProjectTypeOptionsArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyProjectTypeOptionsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteDeliveryConfigurationsArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyDeliveryConfigurationsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteActivitiesConfigurationsArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyActivitiesConfigurationsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteHierarchyConfigurationsArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyHierarchyConfigurationsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteLogisticsConfigurationsArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyLogisticsConfigurationsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteFormsConfigurationsArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyFormsConfigurationsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteDeliveryFieldsConfigurationsArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyDeliveryFieldsConfigurationsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeletePresentationSettingsArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyPresentationSettingsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteZoneMapThresholdsArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyZoneMapThresholdsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteProjectHistoryArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyProjectHistoriesArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSaveManyProjectsArgs = {
  projects: Array<Maybe<IProjectInput>>
}

export type IMutationArchiveProjectArgs = {
  projectId: Scalars['ObjectId']
}

export type IMutationSaveManyProjectAddressesArgs = {
  projectAddresses: Array<Maybe<IProjectAddressInput>>
}

export type IMutationUpsertMaturixTokenForProjectArgs = {
  struxHubProjectId: Scalars['ObjectId']
  maturixToken: Scalars['String']
}

export type IMutationDeleteMaturixTokenForProjectArgs = {
  struxHubProjectId: Scalars['ObjectId']
}

export type IMutationUpsertMaturixDataArgs = {
  maturixProjectId: Scalars['String']
  struxHubProjectId?: Maybe<Scalars['ObjectId']>
  strengthUnit?: Maybe<Scalars['String']>
  temperatureUnit?: Maybe<Scalars['String']>
}

export type IMutationDeleteMaturixFromProjectArgs = {
  struxHubProjectId: Scalars['ObjectId']
}

export type IMutationSaveProjectMaterialOptionsArgs = {
  projectMaterialOptions: IProjectMaterialOptionsInput
}

export type IMutationSaveManyProjectMaterialOptionsArgs = {
  projectMaterialOptions: Array<Maybe<IProjectMaterialOptionsInput>>
}

export type IMutationSaveProjectColoringOptionsArgs = {
  projectColoringOptions: IProjectColoringOptionsInput
}

export type IMutationSaveManyProjectColoringOptionsArgs = {
  projectColoringOptions: Array<Maybe<IProjectColoringOptionsInput>>
}

export type IMutationSaveProjectStatusUpdateOptionsArgs = {
  projectStatusUpdateOptions: IProjectStatusUpdateOptionsInput
}

export type IMutationSaveManyProjectStatusUpdateOptionsArgs = {
  projectStatusUpdateOptions: Array<Maybe<IProjectStatusUpdateOptionsInput>>
}

export type IMutationSaveProjectTypeOptionsArgs = {
  projectTypeOptions: IProjectTypeOptionsInput
}

export type IMutationSaveManyProjectTypeOptionsArgs = {
  projectTypeOptions: Array<Maybe<IProjectTypeOptionsInput>>
}

export type IMutationSaveDeliveryConfigurationsArgs = {
  deliveryConfigurations: IDeliveryConfigurationsInput
}

export type IMutationSaveManyDeliveryConfigurationsArgs = {
  deliveryConfigurations: Array<Maybe<IDeliveryConfigurationsInput>>
}

export type IMutationSaveActivitiesConfigurationsArgs = {
  activitiesConfigurations: IActivitiesConfigurationsInput
}

export type IMutationSaveManyActivitiesConfigurationsArgs = {
  activitiesConfigurations: Array<Maybe<IActivitiesConfigurationsInput>>
}

export type IMutationSaveHierarchyConfigurationsArgs = {
  hierarchyConfigurations: IHierarchyConfigurationsInput
}

export type IMutationSaveManyHierarchyConfigurationsArgs = {
  hierarchyConfigurations: Array<Maybe<IHierarchyConfigurationsInput>>
}

export type IMutationSaveLogisticsConfigurationsArgs = {
  logisticsConfigurations: ILogisticsConfigurationsInput
}

export type IMutationSaveManyLogisticsConfigurationsArgs = {
  logisticsConfigurations: Array<Maybe<ILogisticsConfigurationsInput>>
}

export type IMutationSaveFormsConfigurationsArgs = {
  formsConfigurations: IFormsConfigurationsInput
}

export type IMutationSaveManyFormsConfigurationsArgs = {
  formsConfigurations: Array<Maybe<IFormsConfigurationsInput>>
}

export type IMutationSaveDeliveryFieldsConfigurationsArgs = {
  deliveryFieldsConfigurations: IDeliveryFieldsConfigurationsInput
}

export type IMutationSaveManyDeliveryFieldsConfigurationsArgs = {
  deliveryFieldsConfigurations: Array<Maybe<IDeliveryFieldsConfigurationsInput>>
}

export type IMutationSavePresentationSettingsArgs = {
  presentationSettings: IPresentationSettingsInput
}

export type IMutationSaveManyPresentationSettingsArgs = {
  presentationSettings: Array<Maybe<IPresentationSettingsInput>>
}

export type IMutationSaveZoneMapThresholdsArgs = {
  zoneMapThresholds: IZoneMapThresholdsInput
}

export type IMutationSaveManyZoneMapThresholdsArgs = {
  zoneMapThresholds: Array<Maybe<IZoneMapThresholdsInput>>
}

export type IMutationSaveProjectHistoryArgs = {
  projectHistory: IProjectHistoryInput
}

export type IMutationSaveManyProjectHistoriesArgs = {
  projectHistories: Array<Maybe<IProjectHistoryInput>>
}

export type IMutationSaveMaterialConfigurationArgs = {
  materialConfiguration: IMaterialConfigurationInput
}

export type IMutationSaveManyMaterialConfigurationsArgs = {
  materialConfigurations: Array<Maybe<IMaterialConfigurationInput>>
}

export type IMutationSaveSitemapArgs = {
  sitemap: ISitemapInput
}

export type IMutationSaveManySitemapsArgs = {
  sitemaps: Array<Maybe<ISitemapInput>>
}

export type IMutationSaveSitemapItemArgs = {
  sitemapItem: ISitemapItemInput
}

export type IMutationSaveManySitemapItemsArgs = {
  sitemapItems: Array<Maybe<ISitemapItemInput>>
}

export type IMutationSaveGlobeViewArgs = {
  globeView: IGlobeViewInput
}

export type IMutationSaveManyGlobeViewsArgs = {
  globeViews: Array<Maybe<IGlobeViewInput>>
}

export type IMutationSaveAnnouncementAssignmentArgs = {
  announcementAssignment: IAnnouncementAssignmentInput
}

export type IMutationSaveManyAnnouncementAssignmentsArgs = {
  announcementAssignments: Array<Maybe<IAnnouncementAssignmentInput>>
}

export type IMutationSaveTilesetArgs = {
  tileset: ITilesetInput
}

export type IMutationSaveManyTilesetsArgs = {
  tilesets: Array<Maybe<ITilesetInput>>
}

export type IMutationSaveSitemapSpecificItemDataArgs = {
  polylines?: Maybe<Array<Maybe<ISitemapSpecificItemDataPolylineInput>>>
  rectangles?: Maybe<Array<Maybe<ISitemapSpecificItemDataRectangleInput>>>
  circles?: Maybe<Array<Maybe<ISitemapSpecificItemDataCircleInput>>>
}

export type IMutationSaveGlobeViewSpecificItemDataArgs = {
  polylines?: Maybe<Array<Maybe<IGlobeViewSpecificItemDataPolylineInput>>>
  rectangles?: Maybe<Array<Maybe<IGlobeViewSpecificItemDataRectangleInput>>>
  circles?: Maybe<Array<Maybe<IGlobeViewSpecificItemDataCircleInput>>>
}

export type IMutationSaveGlobeViewStyleArgs = {
  style: IGlobeViewStyleInput
  globeViewId: Scalars['ObjectId']
}

export type IMutationSaveManyAnnouncementFollowingsArgs = {
  announcementFollowings: Array<Maybe<IAnnouncementFollowingInput>>
}

export type IMutationAddSitePermitInspectionChangeArgs = {
  inspectionChange: ISitePermitInspectionInput
  fields: IPermitFieldsInput
}

export type IMutationSaveManyPermitTypesArgs = {
  projectId: Scalars['ObjectId']
  permitTypes: Array<Maybe<IPermitTypeInput>>
}

export type IMutationSaveManySitePermitFollowingsArgs = {
  sitePermitFollowings: Array<Maybe<ISitePermitFollowingInput>>
}

export type IMutationAddSitePermitInspectionArgs = {
  inspection: ISitePermitInspectionInput
  fields: IPermitFieldsInput
}

export type IMutationUploadGeoTiffToMapboxArgs = {
  projectId: Scalars['ObjectId']
  fileName: Scalars['String']
  fullFilePath: Scalars['String']
}

export type IMutationSaveManyFormCategoriesArgs = {
  formCategories: Array<Maybe<IFormCategoryInput>>
}

export type IMutationSoftDeletePermitTypeArgs = {
  id: Scalars['ObjectId']
}

export type IMutationSoftDeleteManyPermitTypesArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteSitePermitFollowingArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManySitePermitFollowingsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteManySitemapItemsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteGlobeViewArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteSitemapArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteSitemapSpecificItemDataArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManySitemapSpecificItemDataArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteGlobeViewSpecificItemDataArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyGlobeViewSpecificItemDataArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteSitePermitInspectionArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManySitePermitInspectionsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteSitePermitInspectionChangeArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManySitePermitInspectionChangesArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSoftDeleteManyFormsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSaveSitePermitAssignmentArgs = {
  sitePermitAssignment: ISitePermitAssignmentInput
}

export type IMutationSaveManySitePermitAssignmentsArgs = {
  sitePermitAssignments: Array<Maybe<ISitePermitAssignmentInput>>
}

export type IMutationDeleteSitePermitAssignmentArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManySitePermitAssignmentsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSaveManySitePermitsArgs = {
  permits: Array<Maybe<IPermitWithFieldsInput>>
}

export type IMutationSaveManyAnnouncementsArgs = {
  announcementsWithAssignments: Array<Maybe<IAnnouncementWithAssignmentInput>>
}

export type IMutationDeleteManyAnnouncementsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteAnnouncementArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteAnnouncementAssignmentArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyAnnouncementAssignmentsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteAnnouncementFollowingArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyAnnouncementFollowingsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteTilesetArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyTilesetsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteTilesetFromMapboxArgs = {
  tilesetId: Scalars['String']
}

export type IMutationSoftDeleteManyFormCategoriesArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteTeamArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyTeamsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteUserRoleArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyUserRolesArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteTradeArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyTradesArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationRemoveUserProjectsArgs = {
  projectId: Scalars['ObjectId']
  userIds: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSaveTeamArgs = {
  team: ITeamInput
}

export type IMutationSaveManyTeamsArgs = {
  teams: Array<Maybe<ITeamInput>>
}

export type IMutationSaveUserRoleArgs = {
  userRole: IUserRoleInput
}

export type IMutationSaveManyUserRolesArgs = {
  userRoles: Array<Maybe<IUserRoleInput>>
}

export type IMutationSaveManyUserProjectsArgs = {
  userProjects: Array<Maybe<IUserProjectInput>>
}

export type IMutationSaveTradeArgs = {
  trade: ITradeInput
}

export type IMutationSaveManyTradesArgs = {
  trades: Array<Maybe<ITradeInput>>
}

export type IMutationSignUpArgs = {
  projectCode?: Maybe<Scalars['String']>
  firstName: Scalars['String']
  lastName: Scalars['String']
  email?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['ObjectId']>
  companyAlias?: Maybe<Scalars['String']>
}

export type IMutationRequestProjectAccessArgs = {
  projectCode: Scalars['String']
}

export type IMutationSaveMembersArgs = {
  saveMembers: Array<Maybe<ISaveMemberModelInput>>
}

export type IMutationUploadUsersFromCsvArgs = {
  uploadUsersFromCsv: ICsvFileModelInput
}

export type IMutationDeleteUsersArgs = {
  deleteUsers: Array<Maybe<IDeleteUsersInput>>
}

export type IMutationResendInviteKeyArgs = {
  inviteKey: Scalars['String']
  projectCode: Scalars['String']
}

export type IMutationGetPresentationUserTokenArgs = {
  projectId: Scalars['ObjectId']
}

export type IMutationSendWelcomeEmailsArgs = {
  userEmails: Array<Maybe<Scalars['String']>>
  projectId: Scalars['ObjectId']
}

export type IMutationSendInvitesArgs = {
  userData: Array<Maybe<IUserDataInput>>
  projectId: Scalars['ObjectId']
}

export type IMutationDeleteOperationRuleArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyOperationRulesArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSaveOperationRuleArgs = {
  operationRule: IOperationRuleInput
}

export type IMutationSaveManyOperationRulesArgs = {
  operationRules: Array<Maybe<IOperationRuleInput>>
}

export type IMutationSaveNotificationArgs = {
  notification: INotificationInput
}

export type IMutationSaveManyNotificationsArgs = {
  notifications: Array<Maybe<INotificationInput>>
}

export type IMutationSaveActivityArgs = {
  activity: IActivityInput
}

export type IMutationSaveManyActivitiesArgs = {
  activities: Array<Maybe<IActivityInput>>
}

export type IMutationUploadScheduleArgs = {
  projectId: Scalars['ObjectId']
  userId: Scalars['ObjectId']
  fileName: Scalars['String']
  file: Scalars['String']
  xerProjectId: Scalars['String']
  timezoneId: Scalars['String']
}

export type IMutationUploadExcelScheduleArgs = {
  projectId: Scalars['ObjectId']
  userId: Scalars['ObjectId']
  fileName: Scalars['String']
  fullFilePath: Scalars['String']
  sheetData: ISheetDataInput
  timezoneId: Scalars['String']
  shouldUpdateCurrentSchedule: Scalars['Boolean']
}

export type IMutationSaveActivityCodeRelationshipArgs = {
  activityCodeRelationship: IActivityCodeRelationshipInput
}

export type IMutationSaveManyActivityCodeRelationshipsArgs = {
  activityCodeRelationships: Array<Maybe<IActivityCodeRelationshipInput>>
}

export type IMutationSaveStatusUpdateArgs = {
  statusUpdate: IStatusUpdateInput
}

export type IMutationSaveManyStatusUpdatesArgs = {
  statusUpdates: Array<Maybe<IStatusUpdateInput>>
}

export type IMutationSoftDeleteManyStatusUpdatesArgs = {
  projectId: Scalars['ObjectId']
  userId: Scalars['ObjectId']
  activityCode: Scalars['String']
  company?: Maybe<Scalars['String']>
  unixTime: Scalars['MillisecondsDate']
  timezoneId: Scalars['String']
}

export type IMutationUpdateStatusUpdatesMultiDatesArgs = {
  projectId: Scalars['ObjectId']
  userId: Scalars['ObjectId']
  activityCode: Scalars['String']
  dates?: Maybe<Array<Maybe<IStatusUpdateMultiCompaniesInput>>>
  timezoneId: Scalars['String']
}

export type IMutationUpdateStatusUpdatesMissedStatusesArgs = {
  projectId: Scalars['ObjectId']
  userId: Scalars['ObjectId']
  activityCode: Scalars['String']
  updates: Array<Maybe<IStatusUpdateDateInput>>
  company?: Maybe<Scalars['String']>
}

export type IMutationActivityWithDataArgs = {
  data: IActivityDataInput
}

export type IMutationSaveActivityLocationArgs = {
  activityLocation: IActivityLocationInput
}

export type IMutationSaveManyActivityLocationsArgs = {
  activityLocations: Array<Maybe<IActivityLocationInput>>
}

export type IMutationDeleteActivityLocationArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyActivityLocationsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSaveActivityFiltersSettingsArgs = {
  activityFiltersSettings: IActivityFiltersSettingsInput
}

export type IMutationSaveManyActivityFiltersSettingsArgs = {
  activityFiltersSettings: Array<Maybe<IActivityFiltersSettingsInput>>
}

export type IMutationSaveManyActivityFollowingsArgs = {
  activityFollowings: Array<Maybe<IActivityFollowingInput>>
}

export type IMutationUpdateActivityFieldsArgs = {
  activities: Array<Maybe<IUpdateActivitiesFieldsInput>>
  projectId: Scalars['ObjectId']
}

export type IMutationSaveActivityListCustomFilterArgs = {
  activityListCustomFilter: IActivityListCustomFilterInput
}

export type IMutationSaveManyActivityListCustomFiltersArgs = {
  activityListCustomFilters: Array<Maybe<IActivityListCustomFilterInput>>
}

export type IMutationSaveActivityPresetArgs = {
  activityPreset: IActivityPresetInput
}

export type IMutationSaveManyActivityPresetsArgs = {
  activityPresets: Array<Maybe<IActivityPresetInput>>
}

export type IMutationSaveActivityAssignmentArgs = {
  activityAssignment: IActivityAssignmentInput
}

export type IMutationSaveManyActivityAssignmentsArgs = {
  activityAssignments: Array<Maybe<IActivityAssignmentInput>>
}

export type IMutationSoftDeleteManyActivitiesArgs = {
  projectId: Scalars['ObjectId']
  activitiesCodes: Array<Maybe<Scalars['String']>>
}

export type IMutationDeleteActivityFollowingArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyActivityFollowingsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteActivityPresetArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyActivityPresetsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteActivityListCustomFilterArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyActivityListCustomFiltersArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteActivityAssignmentArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyActivityAssignmentsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSaveManyAreasArgs = {
  areas: Array<Maybe<IAreaInput>>
}

export type IMutationSaveManyGatesArgs = {
  gates: Array<Maybe<IGateInput>>
}

export type IMutationSaveManyBuildingsArgs = {
  buildings: Array<Maybe<IBuildingInput>>
}

export type IMutationSaveManyLevelsArgs = {
  levels: Array<Maybe<ILevelInput>>
}

export type IMutationSaveManyRoutesArgs = {
  routes: Array<Maybe<IRouteInput>>
}

export type IMutationSaveManyZonesArgs = {
  zones: Array<Maybe<IZoneInput>>
}

export type IMutationSaveManyLogisticsObjectsArgs = {
  logisticsObjects: Array<Maybe<ILogisticsObjectInput>>
}

export type IMutationSaveManyVerticalObjectsArgs = {
  verticalObjects: Array<Maybe<IVerticalObjectInput>>
}

export type IMutationSaveManyOffloadingEquipmentsArgs = {
  offloadingEquipments: Array<Maybe<IOffloadingEquipmentInput>>
}

export type IMutationSaveManyIntegrationsArgs = {
  integrations: Array<Maybe<IIntegrationInput>>
}

export type IMutationSaveManyInteriorDoorsArgs = {
  interiorDoors: Array<Maybe<IInteriorDoorInput>>
}

export type IMutationSaveManyInteriorPathsArgs = {
  interiorPaths: Array<Maybe<IInteriorPathInput>>
}

export type IMutationSaveManyStagingsArgs = {
  stagings: Array<Maybe<IStagingInput>>
}

export type IMutationSoftDeleteVerticalObjectArgs = {
  id: Scalars['ObjectId']
}

export type IMutationSoftDeleteManyVerticalObjectsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSoftDeleteManyLocationsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteLocationArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyLocationsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSaveProjectMaterialArgs = {
  projectMaterial: IProjectMaterialInput
}

export type IMutationSaveManyProjectMaterialsArgs = {
  projectMaterials: Array<Maybe<IProjectMaterialInput>>
}

export type IMutationSaveProjectMaterialCategoryArgs = {
  projectMaterialCategory: IProjectMaterialCategoryInput
}

export type IMutationSaveManyProjectMaterialCategoriesArgs = {
  projectMaterialCategories: Array<Maybe<IProjectMaterialCategoryInput>>
}

export type IMutationFillupProjectMaterialsDataArgs = {
  projectId: Scalars['ObjectId']
}

export type IMutationUploadMaterialsExcelArgs = {
  data: IUploadMaterialsExcelInput
}

export type IMutationRestoreMaterialsArgs = {
  projectId: Scalars['ObjectId']
  materialsUploadId?: Maybe<Scalars['ObjectId']>
}

export type IMutationSaveCompanyRelationshipArgs = {
  companyRelationship: ICompanyRelationshipInput
}

export type IMutationSaveManyCompanyRelationshipsArgs = {
  companyRelationships: Array<Maybe<ICompanyRelationshipInput>>
}

export type IMutationSavecompanyTypeTagArgs = {
  entityTag: IEntityTagInput
}

export type IMutationSaveManycompanyTypeTagsArgs = {
  companyTypeTags: Array<Maybe<IEntityTagInput>>
}

export type IMutationSoftDeleteCompanyArgs = {
  id: Scalars['ObjectId']
}

export type IMutationSoftDeleteManyCompaniesArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSaveManyCompaniesArgs = {
  companies: Array<Maybe<ICompanyInput>>
}

export type IMutationRecoverManyCompaniesArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteCompanyRelationshipArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyCompanyRelationshipsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationLinkStruxHubCompanyArgs = {
  companyId: Scalars['ObjectId']
  struxHubCompanyId: Scalars['ObjectId']
}

export type IMutationUnlinkStruxHubCompanyArgs = {
  companyId: Scalars['ObjectId']
}

export type IMutationMergeCompaniesArgs = {
  companyIds: Array<Scalars['ObjectId']>
  targetCompany: ICompanyInput
}

export type IMutationDeleteCastFollowingArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyCastFollowingsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationDeleteCastAssignmentArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyCastAssignmentsArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSaveManyCastFollowingsArgs = {
  castFollowings: Array<Maybe<ICastFollowingInput>>
}

export type IMutationSaveCastAssignmentArgs = {
  castAssignment: ICastAssignmentInput
}

export type IMutationSaveManyCastAssignmentsArgs = {
  castAssignments: Array<Maybe<ICastAssignmentInput>>
}

export type IMutationGenerateReportArgs = {
  generateReport: IGenerateReportCommandInput
}

export type IMutationDeleteScannerArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyScannersArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationSaveManyScannersArgs = {
  scanners: Array<Maybe<IScannerInput>>
}

export type IMutationSaveScanHistoryArgs = {
  scanHistory: IScanHistoryInput
}

export type IMutationSaveManyScanHistoriesArgs = {
  scanHistories: Array<Maybe<IScanHistoryInput>>
}

export type IMutationSaveWorkflowsCustomFilterArgs = {
  workflowsCustomFilter: IWorkflowsCustomFilterInput
}

export type IMutationSaveManyWorkflowsCustomFiltersArgs = {
  workflowsCustomFilters: Array<Maybe<IWorkflowsCustomFilterInput>>
}

export type IMutationDeleteWorkflowsCustomFilterArgs = {
  id: Scalars['ObjectId']
}

export type IMutationDeleteManyWorkflowsCustomFiltersArgs = {
  ids: Array<Maybe<Scalars['ObjectId']>>
}

export type IMutationTransferProjectDataArgs = {
  definitions: ITransferProjectDefinitionsGraphType
  email: Scalars['String']
}

export type IMutationSaveConcreteDirectDataForProjectArgs = {
  concreteDirectIntegration?: Maybe<IConcreteDirectIntegrationInput>
}

export type IMutationSaveConcreteDirectSqsConfigurationArgs = {
  concreteDirectSQSConfiguration?: Maybe<IConcreteDirectSqsConfigurationInput>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IMutationDeleteConcreteDirectFromProjectArgs = {
  projectId: Scalars['ObjectId']
}

export interface INotification {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Activity object Id. */
  activityObjectId?: Maybe<Scalars['String']>
  /** Author Id. */
  authorId: Scalars['ObjectId']
  /** Entity Id. */
  entityId?: Maybe<Scalars['String']>
  /** Is archived. */
  isArchived: Scalars['Boolean']
  /** Recipient Id. */
  recipientId: Scalars['ObjectId']
  /** Schedule Id. */
  scheduleId?: Maybe<Scalars['String']>
  /** Message Id. */
  messageId?: Maybe<Scalars['ObjectId']>
  /** Type. */
  type: NotificationType
  /** Was read. */
  wasRead: Scalars['Boolean']
}

export interface INotificationChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<INotification>
}

export interface INotificationInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Activity object Id. */
  activityObjectId?: Maybe<Scalars['String']>
  /** Author Id. */
  authorId: Scalars['ObjectId']
  /** Entity Id. */
  entityId?: Maybe<Scalars['String']>
  /** Is archived. */
  isArchived: Scalars['Boolean']
  /** Recipient Id. */
  recipientId: Scalars['ObjectId']
  /** Schedule Id. */
  scheduleId?: Maybe<Scalars['String']>
  /** Message Id. */
  messageId?: Maybe<Scalars['ObjectId']>
  /** Type. */
  type: NotificationType
  /** Was read. */
  wasRead: Scalars['Boolean']
}

export interface INotificationList {
  data: Array<INotification>
  page: IPageInfo
}

export enum NotificationType {
  FlagCreated = 'FLAG_CREATED',
  FlagReplied = 'FLAG_REPLIED',
  MessageReceived = 'MESSAGE_RECEIVED',
  PermitMessageReceived = 'PERMIT_MESSAGE_RECEIVED',
  RequestForInformationCreated = 'REQUEST_FOR_INFORMATION_CREATED',
  RfiReplied = 'RFI_REPLIED',
  CategoryOfVarianceCreated = 'CATEGORY_OF_VARIANCE_CREATED',
  CategoryOfVarianceReplied = 'CATEGORY_OF_VARIANCE_REPLIED',
  SafetyHazardCreated = 'SAFETY_HAZARD_CREATED',
  SafetyHazardReplied = 'SAFETY_HAZARD_REPLIED',
  StatusUpdateCreated = 'STATUS_UPDATE_CREATED',
  StatusUpdateReplied = 'STATUS_UPDATE_REPLIED',
  DeliveryRequested = 'DELIVERY_REQUESTED',
  DeliveryScheduled = 'DELIVERY_SCHEDULED',
  DeliveryDenied = 'DELIVERY_DENIED',
  DeliveryOnsite = 'DELIVERY_ONSITE',
  DeliveryPassedInspection = 'DELIVERY_PASSED_INSPECTION',
  DeliveryFailedInspection = 'DELIVERY_FAILED_INSPECTION',
  DeliveryDone = 'DELIVERY_DONE',
  DeliveryIncompleteDone = 'DELIVERY_INCOMPLETE_DONE',
  DeliveryRequestedByLocationClosure = 'DELIVERY_REQUESTED_BY_LOCATION_CLOSURE',
  DeliveryUpdated = 'DELIVERY_UPDATED',
  DeliveryDeleted = 'DELIVERY_DELETED',
  DeliveryDelivering = 'DELIVERY_DELIVERING',
  DeliveryPaused = 'DELIVERY_PAUSED',
  DeliveryOnhold = 'DELIVERY_ONHOLD',
  ScheduleUpdated = 'SCHEDULE_UPDATED',
  ScheduleCommentCreated = 'SCHEDULE_COMMENT_CREATED',
  ScheduleCommentReplied = 'SCHEDULE_COMMENT_REPLIED',
  ActivityFollowed = 'ACTIVITY_FOLLOWED',
  ActivityChanged = 'ACTIVITY_CHANGED',
  DeliveryFollowed = 'DELIVERY_FOLLOWED',
  DeliveryCanceled = 'DELIVERY_CANCELED',
  PermitSubmitted = 'PERMIT_SUBMITTED',
  PermitAccepted = 'PERMIT_ACCEPTED',
  PermitClosed = 'PERMIT_CLOSED',
  PermitChanged = 'PERMIT_CHANGED',
  PermitDenied = 'PERMIT_DENIED',
  PermitToInspect = 'PERMIT_TO_INSPECT',
  PermitActivated = 'PERMIT_ACTIVATED',
  PermitOnsite = 'PERMIT_ONSITE',
  PermitReviewed = 'PERMIT_REVIEWED',
  PermitFailed = 'PERMIT_FAILED',
  PermitPassed = 'PERMIT_PASSED',
  PermitDeleted = 'PERMIT_DELETED',
  PermitEnded = 'PERMIT_ENDED',
  PermitFollowed = 'PERMIT_FOLLOWED',
  AnnouncementCreated = 'ANNOUNCEMENT_CREATED',
  AnnouncementStarted = 'ANNOUNCEMENT_STARTED',
  AnnouncementFollowed = 'ANNOUNCEMENT_FOLLOWED',
  CastFollowed = 'CAST_FOLLOWED',
  ClosureFollowed = 'CLOSURE_FOLLOWED',
  ClosureStarted = 'CLOSURE_STARTED',
  ClosureEnded = 'CLOSURE_ENDED',
  ScannerDeactivated = 'SCANNER_DEACTIVATED',
  NewUserCreatedViaSignUp = 'NEW_USER_CREATED_VIA_SIGN_UP',
}

export interface INotifyEntityInput {
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Entity id. */
  entityId: Scalars['ObjectId']
  /** Notification type. */
  notificationType?: Maybe<NotificationType>
}

export interface IObjectIdPermitField extends IPermitFieldInterface {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  objectIdValues?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export interface IObjectIdPermitFieldInput {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  values?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export interface IOffloadingEquipment extends ILocation {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateInterval>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Delivery hierarchy parent. */
  parent?: Maybe<IDeliveryHierarchyParent>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<LocationType>
  closingDate?: Maybe<Scalars['Long']>
  /** Accessible levels. */
  accessibleLevels?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export interface IOffloadingEquipmentInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateIntervalInput>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Location parent. */
  parent?: Maybe<IDeliveryHierarchyParentInput>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Accessible levels. */
  accessibleLevels?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export interface IOnSiteContactPerson {
  /** Id. */
  id?: Maybe<Scalars['ObjectId']>
  /** Vendor full name. */
  fullName?: Maybe<Scalars['String']>
}

export interface IOnSiteContactResponse {
  /** On Site Contact Name. */
  name?: Maybe<Scalars['String']>
  /** On Site Contact Company. */
  company?: Maybe<Scalars['String']>
  /** On Site Contact Contact. */
  contact?: Maybe<Scalars['String']>
}

export enum OnTimeStatus {
  None = 'None',
  LateStart = 'Late_Start',
  Overdue = 'Overdue',
  Yes = 'Yes',
  No = 'No',
}

export interface IOpenWeatherForecast {
  /** Time of data forecasted, unix, UTC, long format. */
  forecastTime: Scalars['Long']
  /** Forecast main parameters. */
  mainParams: IForecastMainParams
  /** Forecast weather conditions parameters. */
  conditionParams: Array<Maybe<IForecastConditionParams>>
  /** Forecast clouds parameters. */
  cloudsParams: IForecastCloudsParams
  /** Forecast wind parameters. */
  windParams: IForecastWindParams
  /** Average visibility, metres. The maximum value of the visibility is 10km. */
  visibility: Scalars['Int']
  /** Probability of precipitation. The values of the parameter vary between 0 and 1, where 0 is equal to 0%. */
  probabilityOfPrecipitation: Scalars['Float']
  /** Forecast rain precipitation parameters. */
  rainParams?: Maybe<IForecastPrecipitationParams>
  /** Forecast snow precipitation parameters. */
  snowParams?: Maybe<IForecastPrecipitationParams>
  /** Forecast system parameters. */
  systemParams: IForecastSystemParams
  /** Time of data forecasted, ISO, UTC. */
  forecastIsoDate: Scalars['String']
}

export interface IOperationRule {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Expression. */
  expression?: Maybe<Scalars['String']>
  /** Assign expression. */
  assignExpression?: Maybe<Scalars['String']>
  /** Operation. */
  operation: OperationType
  /** Operation subject. */
  operationSubject: OperationSubjectType
}

export interface IOperationRuleChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IOperationRule>
}

export interface IOperationRuleInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Expression. */
  expression?: Maybe<Scalars['String']>
  /** Assign expression. */
  assignExpression?: Maybe<Scalars['String']>
  /** Operation. */
  operation: OperationType
  /** Operation subject. */
  operationSubject: OperationSubjectType
}

export interface IOperationRuleList {
  data: Array<IOperationRule>
  page: IPageInfo
}

export enum OperationSubjectType {
  Delivery = 'delivery',
  Activity = 'activity',
  Permit = 'permit',
  PermitBic = 'permitBic',
}

export enum OperationType {
  Notification = 'notification',
}

export interface IOrderedSitemap {
  /** Sitemap order. */
  order?: Maybe<Scalars['Int']>
  /** Sitemap id. */
  sitemapId?: Maybe<Scalars['ObjectId']>
  /** Globe view Id. */
  globeViewId?: Maybe<Scalars['ObjectId']>
}

export interface IOrderedSitemapInput {
  /** Sitemap order. */
  order: Scalars['Int']
  /** Sitemap id. */
  sitemapId?: Maybe<Scalars['ObjectId']>
  /** Globe view Id. */
  globeViewId?: Maybe<Scalars['ObjectId']>
}

export interface IPageInfo {
  offset: Scalars['Int']
  limit: Scalars['Int']
}

export type IPermitFieldInterface = {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
}

export enum PermitFieldType {
  Company = 'Company',
  Requester = 'Requester',
  Assignee = 'Assignee',
  Subscriber = 'Subscriber',
  User = 'User',
  Location = 'Location',
  Equipment = 'Equipment',
  File = 'File',
  AdditionalDate = 'AdditionalDate',
  Checklist = 'Checklist',
  Question = 'Question',
  Select = 'Select',
  InputText = 'InputText',
  InputTextArea = 'InputTextArea',
  InputNumber = 'InputNumber',
  Email = 'Email',
  PhoneNumber = 'PhoneNumber',
  Measure = 'Measure',
  StartFinishDates = 'StartFinishDates',
  Time = 'Time',
  Section = 'Section',
  Subsection = 'Subsection',
  Divider = 'Divider',
  Instructions = 'Instructions',
  Text = 'Text',
  RequestToCloseLocations = 'RequestToCloseLocations',
  Table = 'Table',
  Material = 'Material',
  LocationInformational = 'LocationInformational',
  RichText = 'RichText',
  LinkedWorkflow = 'LinkedWorkflow',
  CompanyInformational = 'CompanyInformational',
}

export interface IPermitFieldsInput {
  /** Object Id fields. */
  objectIdFields?: Maybe<Array<Maybe<IObjectIdPermitFieldInput>>>
  /** Date time fields. */
  dateTimeFields?: Maybe<Array<Maybe<IDateTimePermitFieldInput>>>
  /** Attachment fields. */
  attachmentFields?: Maybe<Array<Maybe<IAttachmentPermitFieldInput>>>
  /** Location fields. */
  locationFields?: Maybe<Array<Maybe<ILocationPermitFieldInput>>>
  /** String fields. */
  stringFields?: Maybe<Array<Maybe<IStringPermitFieldInput>>>
  /** Checklist answer item fields. */
  checklistAnswerItemFields?: Maybe<
    Array<Maybe<IChecklistAnswerItemPermitFieldInput>>
  >
  /** Measure fields. */
  measureFields?: Maybe<Array<Maybe<IMeasurePermitFieldInput>>>
  /** Measure fields. */
  tableFields?: Maybe<Array<Maybe<ITablePermitFieldInput>>>
  /** Material fields. */
  materialFields?: Maybe<Array<Maybe<IMaterialFormFieldInput>>>
  /** Rich text fields. */
  richTextFields?: Maybe<Array<Maybe<IRichTextFieldInput>>>
}

export interface IPermitType {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Permit type name. */
  name?: Maybe<Scalars['String']>
  /** Is enabled. */
  isEnabled: Scalars['Boolean']
  /** Order index. */
  orderIndex: Scalars['Int']
  /** Type (eg 'hotwork', 'ladder', 'energized', 'aerial', 'fireExtinguisher'). */
  type: Scalars['String']
  /** Inspection options. */
  inspectionOptions?: Maybe<IInspectionOptions>
  /** Determines whether the form is deleted. */
  isDeleted?: Maybe<Scalars['Boolean']>
  /** Steps. */
  workflowSteps?: Maybe<Array<Maybe<IWorkflowStep>>>
  /** Is auto activation enabled. */
  isAutoActivationEnabled?: Maybe<Scalars['Boolean']>
  /** Indicates whether the workflow form title is mandatory. */
  isWorkflowTitleMandatory?: Maybe<Scalars['Boolean']>
  /** Booking deadline options. */
  bookingDeadlineOptions?: Maybe<IBookingDeadlineOptions>
  /** IDs of steps for auto-ending. */
  autoEndStepIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Associated category id. Can be null. */
  categoryId?: Maybe<Scalars['ObjectId']>
  /** BasedOn id. */
  basedOn?: Maybe<Scalars['ObjectId']>
  /** Should block requesting on non-work times. */
  shouldBlockOnNonWorkTimes?: Maybe<Scalars['Boolean']>
  /** Project id. */
  projectId: Scalars['ObjectId']
}

export interface IPermitTypeChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IPermitType>
}

export interface IPermitTypeChecklist {
  /** List. */
  list?: Maybe<Array<Maybe<IChecklistItem>>>
  /** List type. */
  listType?: Maybe<Scalars['String']>
}

export interface IPermitTypeChecklistInput {
  /** List. */
  list?: Maybe<Array<Maybe<IChecklistItemInput>>>
  /** List type. */
  listType?: Maybe<Scalars['String']>
}

export interface IPermitTypeField {
  /** Id. */
  id?: Maybe<Scalars['ObjectId']>
  /** Parent Id. */
  parentId?: Maybe<Scalars['ObjectId']>
  /** Is multiple. */
  isMultiple?: Maybe<Scalars['Boolean']>
  /** Is mandatory. */
  isMandatory?: Maybe<Scalars['Boolean']>
  /** Is shown. */
  isShown?: Maybe<Scalars['Boolean']>
  /** Select options */
  selectOptions?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Permit field type. */
  type?: Maybe<PermitFieldType>
  /** Caption. */
  caption: Scalars['String']
  /** Can edit is mandatory. */
  canEditIsMandatory: Scalars['Boolean']
  /** Can edit is show. */
  canEditIsShown: Scalars['Boolean']
  /** Restricted options. */
  restrictedOptions?: Maybe<Array<Maybe<IRestrictedOption>>>
  /** Instructions. */
  instructions?: Maybe<IInstructions>
  /** Checklist. */
  checklist?: Maybe<IPermitTypeChecklist>
  /** Is routing. */
  isRouting?: Maybe<Scalars['Boolean']>
}

export interface IPermitTypeFieldReference {
  /** Permit type field id. */
  permitTypeFieldId: Scalars['ObjectId']
  /** Checklist item id. */
  checkListItemId?: Maybe<Scalars['ObjectId']>
}

export interface IPermitTypeFieldReferenceInput {
  /** Permit type field id. */
  permitTypeFieldId: Scalars['ObjectId']
  /** Checklist item id. */
  checkListItemId?: Maybe<Scalars['ObjectId']>
}

export interface IPermitTypeFieldsInput {
  /** Id. */
  id?: Maybe<Scalars['ObjectId']>
  /** Parent Id. */
  parentId?: Maybe<Scalars['ObjectId']>
  /** Is multiple. */
  isMultiple?: Maybe<Scalars['Boolean']>
  /** Is mandatory. */
  isMandatory?: Maybe<Scalars['Boolean']>
  /** Select options */
  selectOptions?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Permit field type. */
  type?: Maybe<PermitFieldType>
  /** Caption. */
  caption: Scalars['String']
  /** Is shown. */
  isShown: Scalars['Boolean']
  /** Can edit is mandatory. */
  canEditIsMandatory: Scalars['Boolean']
  /** Can edit is shown. */
  canEditIsShown: Scalars['Boolean']
  /** Restricted options. */
  restrictedOptions?: Maybe<Array<Maybe<IRestrictedOptionInput>>>
  /** Instructions. */
  instructions?: Maybe<IInstructionsInput>
  /** Checklist. */
  checklist?: Maybe<IPermitTypeChecklistInput>
  /** Is routing. */
  isRouting?: Maybe<Scalars['Boolean']>
}

export interface IPermitTypeInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Name. */
  name?: Maybe<Scalars['String']>
  /** Is enabled. */
  isEnabled: Scalars['Boolean']
  /** Order index. */
  orderIndex: Scalars['Int']
  /** Id of parent template. */
  basedOn?: Maybe<Scalars['ObjectId']>
  /** Type (eg 'hotwork', 'ladder', 'energized', 'aerial', 'fireExtinguisher'). */
  type: Scalars['String']
  /** Inspection options. */
  inspectionOptions?: Maybe<IInspectionOptionsInput>
  /** Inspection options. */
  workflowSteps?: Maybe<Array<Maybe<IWorkflowStepInput>>>
  /** Should block requesting on non-work times. */
  shouldBlockOnNonWorkTimes?: Maybe<Scalars['Boolean']>
  /** Is auto activation enabled. */
  isAutoActivationEnabled?: Maybe<Scalars['Boolean']>
  /** Indicates whether the workflow form title is mandatory. */
  isWorkflowTitleMandatory?: Maybe<Scalars['Boolean']>
  /** Booking deadline options. */
  bookingDeadlineOptions?: Maybe<IBookingDeadlineOptionsInput>
  /** IDs of steps for auto-ending. */
  autoEndStepIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Associated category id. Can be null. */
  categoryId?: Maybe<Scalars['ObjectId']>
}

export interface IPermitTypeList {
  data: Array<IPermitType>
  page: IPageInfo
}

export interface IPermitWithFieldsInput {
  /** Permit. */
  permit?: Maybe<ISitePermitInput>
  /** Fields. */
  fields?: Maybe<IPermitFieldsInput>
}

export interface IPhoto {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Activity object Id. */
  activityObjectId?: Maybe<Scalars['String']>
  /** Author Id. */
  author?: Maybe<Scalars['ObjectId']>
  /** Message Id. */
  messageId?: Maybe<Scalars['ObjectId']>
  /** Thumb url. */
  thumbUrl?: Maybe<Scalars['String']>
  /** Url. */
  url?: Maybe<Scalars['String']>
}

export interface IPhotoChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IPhoto>
}

export interface IPhotoInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Activity object Id. */
  activityObjectId?: Maybe<Scalars['String']>
  /** Author Id. */
  author?: Maybe<Scalars['ObjectId']>
  /** Message Id. */
  messageId?: Maybe<Scalars['ObjectId']>
  /** Thumb url. */
  thumbUrl?: Maybe<Scalars['String']>
  /** Url. */
  url?: Maybe<Scalars['String']>
}

export interface IPhotoList {
  data: Array<IPhoto>
  page: IPageInfo
}

export interface IPosition {
  /** X. */
  x: Scalars['Float']
  /** Y. */
  y: Scalars['Float']
}

export interface IPositionInput {
  x: Scalars['Float']
  y: Scalars['Float']
}

export enum PresentationScreenEntityKey {
  Announcement = 'announcement',
}

export enum PresentationScreenKey {
  Gantt = 'gantt',
  Deliveries = 'deliveries',
  Logistics = 'logistics',
  Forms = 'forms',
}

export interface IPresentationSettings {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Inactivity duration. */
  inactivityDuration: Scalars['Int']
  /** User id who has updated the settings. */
  updatedBy?: Maybe<Scalars['ObjectId']>
  /** List of screens to show. */
  screens?: Maybe<Array<Maybe<IScreen>>>
}

export interface IPresentationSettingsChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IPresentationSettings>
}

export interface IPresentationSettingsInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Inactivity duration. */
  inactivityDuration: Scalars['Int']
  /** User id who has updated the settings. */
  updatedBy?: Maybe<Scalars['ObjectId']>
  /** List of screens to show. */
  screens?: Maybe<Array<Maybe<IScreenInput>>>
}

export interface IPresentationSettingsList {
  data: Array<IPresentationSettings>
  page: IPageInfo
}

export interface IProject {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** The name of the record. */
  name: Scalars['String']
  /** Project code. */
  code: Scalars['String']
  /** Mongo schedule id. */
  scheduleId?: Maybe<Scalars['ObjectId']>
  /** Materials upload id. */
  materialsUploadId?: Maybe<Scalars['ObjectId']>
  /** DateTime format. */
  dateTimeFormat?: Maybe<Scalars['String']>
  /** Project default landing page. */
  defaultLandingPage?: Maybe<DefaultLandingPage>
  /** Timezone id. */
  timezoneId?: Maybe<Scalars['String']>
  /** Project closures. */
  closedIntervals?: Maybe<Array<Maybe<IProjectClosedInterval>>>
  /** List of completed pages. */
  setUpFinished?: Maybe<Array<Maybe<Scalars['String']>>>
  /** List of working days. */
  workingDays?: Maybe<Array<Maybe<Scalars['Int']>>>
  /** Working hours in time milliseconds. */
  workingHours?: Maybe<IWorkingHours>
  /** Logo URL. */
  logoUrl?: Maybe<Scalars['String']>
  projectConfig?: Maybe<IProjectPreferences>
}

export enum ProjectAccessType {
  Member = 'member',
  Admin = 'admin',
  Owner = 'owner',
}

export interface IProjectAddress {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Local address - street, number of building, etc. */
  address?: Maybe<Scalars['String']>
  /** Altitude. */
  altitude?: Maybe<Scalars['Float']>
  /** Pitch. */
  pitch?: Maybe<Scalars['Float']>
  /** Zoom. */
  zoom?: Maybe<Scalars['Float']>
  /** Bearing. */
  bearing?: Maybe<Scalars['Float']>
  /** Earth coordinates of bounding box. */
  bounds?: Maybe<IAddressBounds>
  /** Earth coordinates of the center point. */
  center?: Maybe<ILatLng>
  /** Name of the city. */
  city?: Maybe<Scalars['String']>
  /** Name of the country. */
  country?: Maybe<Scalars['String']>
  /** USA state name if the country is USA. */
  state?: Maybe<Scalars['String']>
  /** Zip code. */
  zipcode?: Maybe<Scalars['String']>
}

export interface IProjectAddressChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IProjectAddress>
}

export interface IProjectAddressInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Local address - street, number of building, etc. */
  address?: Maybe<Scalars['String']>
  /** Bearing. */
  bearing?: Maybe<Scalars['Float']>
  /** Altitude. */
  altitude?: Maybe<Scalars['Float']>
  /** Zoom. */
  zoom?: Maybe<Scalars['Float']>
  /** Pitch. */
  pitch?: Maybe<Scalars['Float']>
  /** Earth coordinates of bounding box. */
  bounds?: Maybe<IAddressBoundsInput>
  /** Earth coordinates of the center point. */
  center?: Maybe<ILatLngInput>
  /** Name of the city. */
  city?: Maybe<Scalars['String']>
  /** Name of the country. */
  country?: Maybe<Scalars['String']>
  /** USA state name if the country is USA. */
  state?: Maybe<Scalars['String']>
  /** Zip code. */
  zipcode?: Maybe<Scalars['String']>
}

export interface IProjectAddressList {
  data: Array<IProjectAddress>
  page: IPageInfo
}

export interface IProjectChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IProject>
}

export interface IProjectClosedInterval {
  /** Name. */
  name?: Maybe<Scalars['String']>
  /** Start date. */
  startDate: Scalars['MillisecondsDate']
  /** End date. */
  endDate: Scalars['MillisecondsDate']
}

export interface IProjectClosedIntervalInput {
  /** Name. */
  name?: Maybe<Scalars['String']>
  /** Start date. */
  startDate: Scalars['MillisecondsDate']
  /** End date. */
  endDate: Scalars['MillisecondsDate']
}

export interface IProjectColoringOptions {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Indicates if weekly work plan coloring is disabled. */
  isWWPColoringDisabled: Scalars['Boolean']
  /** List of project colors. */
  colors?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface IProjectColoringOptionsChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IProjectColoringOptions>
}

export interface IProjectColoringOptionsInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Indicates if weekly work plan coloring is disabled. */
  isWWPColoringDisabled: Scalars['Boolean']
  /** List of project colors. */
  colors?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface IProjectColoringOptionsList {
  data: Array<IProjectColoringOptions>
  page: IPageInfo
}

export interface IProjectHistory {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Info about the last updates by pages. */
  lastUpdated: Array<Maybe<ILastUpdatedItem>>
  /** History of schedule uploads. */
  scheduleHistory?: Maybe<Array<Maybe<IHistoryItem>>>
  /** History of materials uploads. */
  materialsHistory?: Maybe<Array<Maybe<IHistoryItem>>>
}

export interface IProjectHistoryChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IProjectHistory>
}

export interface IProjectHistoryInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Info about the last updates by pages. */
  lastUpdated: Array<Maybe<ILastUpdatedItemInput>>
  /** History of schedule uploads. */
  scheduleHistory?: Maybe<Array<Maybe<IHistoryItemInput>>>
  /** History of materials uploads. */
  materialsHistory?: Maybe<Array<Maybe<IHistoryItemInput>>>
}

export interface IProjectHistoryList {
  data: Array<IProjectHistory>
  page: IPageInfo
}

export interface IProjectInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** The name of the record. */
  name: Scalars['String']
  /** Project code. */
  code: Scalars['String']
  /** Mongo schedule id. */
  scheduleId?: Maybe<Scalars['ObjectId']>
  /** Materials upload id. */
  materialsUploadId?: Maybe<Scalars['ObjectId']>
  /** DateTime format. */
  dateTimeFormat?: Maybe<Scalars['String']>
  /** Project default landing page. */
  defaultLandingPage?: Maybe<DefaultLandingPage>
  /** Timezone id. */
  timezoneId?: Maybe<Scalars['String']>
  /** Project closures. */
  closedIntervals?: Maybe<Array<Maybe<IProjectClosedIntervalInput>>>
  /** List of completed pages. */
  setUpFinished?: Maybe<Array<Maybe<Scalars['String']>>>
  /** List of working days. */
  workingDays?: Maybe<Array<Maybe<Scalars['Int']>>>
  /** Working hours in time milliseconds. */
  workingHours?: Maybe<IWorkingHoursInput>
  /** Logo URL. */
  logoUrl?: Maybe<Scalars['String']>
}

export interface IProjectList {
  data: Array<IProject>
  page: IPageInfo
}

export interface IProjectMaterial {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Materials upload id. */
  materialsUploadId?: Maybe<Scalars['ObjectId']>
  /** Name of material. */
  productName?: Maybe<Scalars['String']>
  /** Category id. */
  categoryId?: Maybe<Scalars['ObjectId']>
  /** Description of material. */
  description?: Maybe<Scalars['String']>
  /** Total planned quantity of material. */
  plannedQuantity?: Maybe<Scalars['Float']>
  /** List of procurement data. */
  procurementDataList?: Maybe<Array<Maybe<IMaterialProcurementData>>>
}

export interface IProjectMaterialCategory {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Materials upload id. */
  materialsUploadId?: Maybe<Scalars['ObjectId']>
  /** Name of material category. */
  name: Scalars['String']
  /** Description of material category. */
  description?: Maybe<Scalars['String']>
  /** Total planned quantity of material category. */
  plannedQuantity?: Maybe<Scalars['Float']>
  /** Field definitions of material category. */
  fieldDefinitions?: Maybe<Array<Maybe<IMaterialCategoryFieldDefinition>>>
  /** List of procurement data. */
  procurementDataList?: Maybe<Array<Maybe<IMaterialProcurementData>>>
}

export interface IProjectMaterialCategoryChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IProjectMaterialCategory>
}

export interface IProjectMaterialCategoryFieldDefinitionInput {
  /** Name of definition. */
  name: Scalars['String']
  /** Type of definition. */
  type: Scalars['String']
}

export interface IProjectMaterialCategoryInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Materials upload id. */
  materialsUploadId?: Maybe<Scalars['ObjectId']>
  /** Name of material category. */
  name: Scalars['String']
  /** Description of material category. */
  description?: Maybe<Scalars['String']>
  /** Total planned quantity of material category. */
  plannedQuantity?: Maybe<Scalars['Float']>
  /** Field definitions of material category. */
  fieldDefinitions?: Maybe<
    Array<Maybe<IProjectMaterialCategoryFieldDefinitionInput>>
  >
  /** List of procurement data. */
  procurementDataList?: Maybe<Array<Maybe<IMaterialProcurementDataInput>>>
}

export interface IProjectMaterialCategoryList {
  data: Array<IProjectMaterialCategory>
  page: IPageInfo
}

export interface IProjectMaterialChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IProjectMaterial>
}

export interface IProjectMaterialInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Materials upload id. */
  materialsUploadId?: Maybe<Scalars['ObjectId']>
  /** Name of material. */
  productName?: Maybe<Scalars['String']>
  /** Category id. */
  categoryId?: Maybe<Scalars['ObjectId']>
  /** Description of material. */
  description?: Maybe<Scalars['String']>
  /** Total planned quantity of material. */
  plannedQuantity?: Maybe<Scalars['Float']>
  /** List of procurement data. */
  procurementDataList?: Maybe<Array<Maybe<IMaterialProcurementDataInput>>>
}

export interface IProjectMaterialList {
  data: Array<IProjectMaterial>
  page: IPageInfo
}

export interface IProjectMaterialOptions {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Indicates if CSI categories and subcategories for materials are disabled. */
  isCSIDisabled: Scalars['Boolean']
  /** Indicates if CSI subcategories for materials are disabled. */
  isCSISubCategoriesDisabled: Scalars['Boolean']
  /** List of unchecked material categories. */
  hiddenCategoryIds?: Maybe<Array<Maybe<Scalars['String']>>>
  /** List of unchecked material subcategories. */
  hiddenSubCategoryIds?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface IProjectMaterialOptionsChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IProjectMaterialOptions>
}

export interface IProjectMaterialOptionsInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Indicates if CSI categories and subcategories for materials are disabled. */
  isCSIDisabled: Scalars['Boolean']
  /** Indicates if CSI subcategories for materials are disabled. */
  isCSISubCategoriesDisabled: Scalars['Boolean']
  /** List of unchecked material categories. */
  hiddenCategoryIds?: Maybe<Array<Maybe<Scalars['String']>>>
  /** List of unchecked material subcategories. */
  hiddenSubCategoryIds?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface IProjectMaterialOptionsList {
  data: Array<IProjectMaterialOptions>
  page: IPageInfo
}

export interface IProjectPreferences {
  /** Should photos be allowed. */
  allowPhotos: Scalars['Boolean']
  /** Should container updates be allowed. */
  allowContainerUpdates: Scalars['Boolean']
  /** Should deliveries toggling be allowed. */
  allowDeliveriesToggling: Scalars['Boolean']
  /** Should logistics toggling be allowed. */
  allowLogisticsToggling: Scalars['Boolean']
  /** Should materials be allowed. */
  allowMaterials: Scalars['Boolean']
  /** Should materials toggling be allowed. */
  allowMaterialsToggling: Scalars['Boolean']
  /** Should project creation be allowed. */
  allowProjectCreation: Scalars['Boolean']
  /** Should tracker toggling be allowed. */
  allowTrackerToggling: Scalars['Boolean']
  /** Should forms toggling be allowed. */
  allowFormsToggling: Scalars['Boolean']
  /** Should analytics be allowed. */
  allowAnalytics: Scalars['Boolean']
  /** Should tilesets be allowed. */
  allowTilesets: Scalars['Boolean']
  /** Tilesets configuration. */
  tilesetsConfiguration?: Maybe<ITilesetsConfiguration>
}

export interface IProjectSetupFinished {
  /** Page name. */
  pageName: Scalars['String']
  /** Setup is finished for this page. */
  setupFinished?: Maybe<Scalars['Boolean']>
}

export interface IProjectSetupFinishedInput {
  /** Page name. */
  pageName: Scalars['String']
  /** Setup is finished for this page. */
  setupFinished?: Maybe<Scalars['Boolean']>
}

export interface IProjectStatusUpdateOptions {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Indicates if manpower is disabled in status updates. */
  isManpowerDisabled: Scalars['Boolean']
  /** Indicates if percent complete is disabled in status updates. */
  isPercentCompleteDisabled: Scalars['Boolean']
  /** Indicates if the options hasn't been updated yet by user. */
  isAutoGenerated: Scalars['Boolean']
}

export interface IProjectStatusUpdateOptionsChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IProjectStatusUpdateOptions>
}

export interface IProjectStatusUpdateOptionsInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Indicates if manpower is disabled in status updates. */
  isManpowerDisabled: Scalars['Boolean']
  /** Indicates if percent complete is disabled in status updates. */
  isPercentCompleteDisabled: Scalars['Boolean']
  /** Indicates if the options hasn't been updated yet by user. */
  isAutoGenerated: Scalars['Boolean']
}

export interface IProjectStatusUpdateOptionsList {
  data: Array<IProjectStatusUpdateOptions>
  page: IPageInfo
}

export interface IProjectTypeOptions {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Indicates if Deliveries app is disabled. */
  isDeliveriesDisabled: Scalars['Boolean']
  /** Indicates if Logistics app is disabled. */
  isLogisticsDisabled: Scalars['Boolean']
  /** Indicates if Materials app is disabled. */
  isMaterialsDisabled: Scalars['Boolean']
  /** Indicates if Tracker app is disabled. */
  isTrackerDisabled: Scalars['Boolean']
  /** Indicates if Photos app is disabled. */
  isPhotosDisabled: Scalars['Boolean']
  /** Indicates if Analytics app is disabled. */
  isAnalyticsDisabled: Scalars['Boolean']
  /** Indicates if Forms are disabled. */
  isFormsDisabled: Scalars['Boolean']
}

export interface IProjectTypeOptionsChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IProjectTypeOptions>
}

export interface IProjectTypeOptionsInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Indicates if Deliveries app is disabled. */
  isDeliveriesDisabled: Scalars['Boolean']
  /** Indicates if Logistics app is disabled. */
  isLogisticsDisabled: Scalars['Boolean']
  /** Indicates if Materials app is disabled. */
  isMaterialsDisabled: Scalars['Boolean']
  /** Indicates if Tracker app is disabled. */
  isTrackerDisabled: Scalars['Boolean']
  /** Indicates if Photos app is disabled. */
  isPhotosDisabled: Scalars['Boolean']
  /** Indicates if Analytics app is disabled. */
  isAnalyticsDisabled: Scalars['Boolean']
  /** Indicates if Forms are disabled. */
  isFormsDisabled: Scalars['Boolean']
}

export interface IProjectTypeOptionsList {
  data: Array<IProjectTypeOptions>
  page: IPageInfo
}

export interface IQuery {
  basemaps?: Maybe<IBasemapList>
  configuration?: Maybe<IConfiguration>
  contentObjects?: Maybe<IContentObjectList>
  threads?: Maybe<IThreadList>
  messages?: Maybe<IMessageList>
  photos?: Maybe<IPhotoList>
  whiteListItems?: Maybe<IWhiteListItemList>
  constraint?: Maybe<IConstraint>
  constraints?: Maybe<IConstraintList>
  analyticsSettings?: Maybe<IAnalyticsSettings>
  closure?: Maybe<IClosure>
  closures?: Maybe<IClosureList>
  closureFollowings?: Maybe<IClosureFollowingList>
  closureAssignments?: Maybe<IClosureAssignmentList>
  weatherForecasts?: Maybe<Array<Maybe<IWeatherForecast>>>
  generateOneTimeQrCodes?: Maybe<Array<Maybe<Scalars['String']>>>
  generateQrCode?: Maybe<Scalars['String']>
  devices?: Maybe<Array<Maybe<IDeviceInfo>>>
  companiesBasicInfo?: Maybe<Array<Maybe<ICompanyBasicInfo>>>
  deliveries?: Maybe<IDeliveryList>
  deliveryStatusChanges?: Maybe<IDeliveryStatusChangeList>
  deliveryUnits?: Maybe<IDeliveryUnitList>
  deliveryVehicleTypes?: Maybe<IDeliveryVehicleTypeList>
  deliveryFollowings?: Maybe<IDeliveryFollowingList>
  deliveryAssignments?: Maybe<IDeliveryAssignmentList>
  deliveryListCustomFilters?: Maybe<IDeliveryListCustomFilterList>
  getDeliveryTicket?: Maybe<IDeliveryTicketResponse>
  recurringDeliveriesSettings?: Maybe<IRecurringDeliveriesSettings>
  project?: Maybe<IProject>
  projects?: Maybe<IProjectList>
  materialConfiguration?: Maybe<IMaterialConfiguration>
  projectMaterialOptions?: Maybe<IProjectMaterialOptionsList>
  projectAddresses?: Maybe<IProjectAddressList>
  projectColoringOptions?: Maybe<IProjectColoringOptionsList>
  projectStatusUpdateOptions?: Maybe<IProjectStatusUpdateOptionsList>
  projectTypeOptions?: Maybe<IProjectTypeOptionsList>
  deliveryConfigurations?: Maybe<IDeliveryConfigurationsList>
  activitiesConfigurations?: Maybe<IActivitiesConfigurationsList>
  hierarchyConfigurations?: Maybe<IHierarchyConfigurationsList>
  logisticsConfigurations?: Maybe<ILogisticsConfigurationsList>
  formsConfigurations?: Maybe<IFormsConfigurationsList>
  deliveryFieldsConfigurations?: Maybe<IDeliveryFieldsConfigurationsList>
  presentationSettings?: Maybe<IPresentationSettingsList>
  zoneMapThresholds?: Maybe<IZoneMapThresholdsList>
  projectHistories?: Maybe<IProjectHistoryList>
  /** Returns true if project code is unique */
  checkIsProjectCodeUnique?: Maybe<Scalars['Boolean']>
  getProjectName?: Maybe<Scalars['String']>
  getMaturixCasts?: Maybe<Array<Maybe<IMaturixCast>>>
  getMaturixMonitoringData?: Maybe<IMaturixMonitoringDataResponse>
  getMaturixProjects?: Maybe<Array<Maybe<IMaturixProject>>>
  permitTypes?: Maybe<IPermitTypeList>
  sitePermitFollowings?: Maybe<ISitePermitFollowingList>
  sitePermits?: Maybe<ISitePermitList>
  sitePermitAssignments?: Maybe<ISitePermitAssignmentList>
  announcements?: Maybe<IAnnouncementList>
  announcementAssignments?: Maybe<IAnnouncementAssignmentList>
  announcementFollowings?: Maybe<IAnnouncementFollowingList>
  sitePermitStatusChanges?: Maybe<ISitePermitStatusChangeList>
  sitePermitInspections?: Maybe<ISitePermitInspectionList>
  sitePermitInspectionChanges?: Maybe<ISitePermitInspectionChangeList>
  sitemapItems?: Maybe<ISitemapItemList>
  globeViews?: Maybe<IGlobeViewList>
  sitemaps?: Maybe<ISitemapList>
  defaultPermitTypes?: Maybe<IDefaultPermitTypeList>
  tilesets?: Maybe<ITilesetList>
  getMapboxTilesets?: Maybe<Array<Maybe<IMapboxTileset>>>
  formCategories?: Maybe<IFormCategoryList>
  operationRules?: Maybe<IOperationRuleList>
  getCsvUsers?: Maybe<Scalars['String']>
  checkPhoneNumber?: Maybe<Scalars['Boolean']>
  user?: Maybe<IUser>
  users?: Maybe<IUserList>
  userProject?: Maybe<IUserProject>
  userProjects?: Maybe<IUserProjectList>
  userRoles?: Maybe<IUserRoleList>
  teams?: Maybe<ITeamList>
  defaultTeams?: Maybe<IDefaultTeamList>
  trades?: Maybe<ITradeList>
  notifications?: Maybe<INotificationList>
  unreadNotifications?: Maybe<Array<Maybe<INotification>>>
  unreadNotificationsCount?: Maybe<Scalars['Long']>
  notifyActivityEntityChanged?: Maybe<Scalars['Boolean']>
  activityCodeRelationships?: Maybe<IActivityCodeRelationshipList>
  activityResourceRelationships?: Maybe<IActivityResourceRelationshipList>
  statusUpdates?: Maybe<IStatusUpdateList>
  schedules?: Maybe<IScheduleList>
  schedule?: Maybe<ISchedule>
  activities?: Maybe<Array<Maybe<IActivity>>>
  getActivity?: Maybe<IActivity>
  relativeActivities?: Maybe<IRelativeActivities>
  calendars?: Maybe<ICalendarList>
  resources?: Maybe<IResourceList>
  activityCodes?: Maybe<IActivityCodeList>
  activityCodeTypes?: Maybe<IActivityCodeTypeList>
  workBreakdownStructure?: Maybe<IWorkBreakdownStructure>
  workBreakdownStructures?: Maybe<IWorkBreakdownStructureList>
  xerProjects?: Maybe<Array<Maybe<IXerProjectInfo>>>
  excelSheets?: Maybe<Array<Maybe<ISheetData>>>
  activityFollowings?: Maybe<IActivityFollowingList>
  activityLocation?: Maybe<IActivityLocation>
  activityPresets?: Maybe<IActivityPresetList>
  activityLocations?: Maybe<IActivityLocationList>
  activityAssignments?: Maybe<IActivityAssignmentList>
  activityFiltersSettings?: Maybe<IActivityFiltersSettings>
  activityListCustomFilters?: Maybe<IActivityListCustomFilterList>
  isActivityCodeClaimed?: Maybe<Scalars['Boolean']>
  locations?: Maybe<ILocationList>
  projectMaterials?: Maybe<IProjectMaterialList>
  projectMaterialCategories?: Maybe<IProjectMaterialCategoryList>
  getMaterialsExcelSheets?: Maybe<Array<Maybe<ISheetData>>>
  getCurrentMaterialUploadProgress?: Maybe<IUploadMaterialsProgress>
  companies?: Maybe<ICompanyList>
  company?: Maybe<ICompany>
  companyRelationships?: Maybe<ICompanyRelationshipList>
  companyTypeTags?: Maybe<IEntityTagList>
  defaultCompanyType?: Maybe<DefaultCompanyType>
  struxHubCompanies?: Maybe<IStruxHubCompanyList>
  struxHubCompany?: Maybe<IStruxHubCompany>
  getDeliveryReport?: Maybe<Array<Maybe<IDeliveryReportResponse>>>
  getFormAnalyticReport?: Maybe<Array<Maybe<IFormAnalyticResponse>>>
  reportTemplate?: Maybe<IReportTemplate>
  castFollowings?: Maybe<ICastFollowingList>
  castAssignments?: Maybe<ICastAssignmentList>
  scanners?: Maybe<IScannerList>
  scanHistories?: Maybe<IScanHistoryList>
  workflowsCustomFilters?: Maybe<IWorkflowsCustomFilterList>
  getSignedUploadUrl?: Maybe<Array<Maybe<IFileRequisites>>>
  concreteDirectOrder?: Maybe<IConcreteDirectOrder>
  concreteDirectOrders?: Maybe<IConcreteDirectOrderList>
  concreteDirectPayloads?: Maybe<IConcreteDirectPayloadList>
}

export type IQueryBasemapsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryConfigurationArgs = {
  id?: Maybe<Scalars['ObjectId']>
  name?: Maybe<Scalars['String']>
}

export type IQueryContentObjectsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryThreadsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryMessagesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryPhotosArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryWhiteListItemsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
  companyId?: Maybe<Scalars['ObjectId']>
}

export type IQueryConstraintArgs = {
  id?: Maybe<Scalars['ObjectId']>
}

export type IQueryConstraintsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryAnalyticsSettingsArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryClosureArgs = {
  id?: Maybe<Scalars['ObjectId']>
}

export type IQueryClosuresArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryClosureFollowingsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryClosureAssignmentsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryWeatherForecastsArgs = {
  weatherForecasts: IWeatherForecastRangeInput
}

export type IQueryGenerateOneTimeQrCodesArgs = {
  qrCodeData: Array<Maybe<Scalars['String']>>
}

export type IQueryGenerateQrCodeArgs = {
  data: Scalars['String']
}

export type IQueryDevicesArgs = {
  userIds: Array<Maybe<Scalars['ObjectId']>>
}

export type IQueryCompaniesBasicInfoArgs = {
  projectCode?: Maybe<Scalars['String']>
  searchString?: Maybe<Scalars['String']>
}

export type IQueryDeliveriesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
  fromDate?: Maybe<Scalars['Long']>
  toDate?: Maybe<Scalars['Long']>
}

export type IQueryDeliveryStatusChangesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
  deliveryId?: Maybe<Scalars['ObjectId']>
}

export type IQueryDeliveryUnitsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryDeliveryVehicleTypesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryDeliveryFollowingsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryDeliveryAssignmentsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryDeliveryListCustomFiltersArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
  userId?: Maybe<Scalars['ObjectId']>
}

export type IQueryGetDeliveryTicketArgs = {
  deliveryId?: Maybe<Scalars['ObjectId']>
}

export type IQueryRecurringDeliveriesSettingsArgs = {
  id?: Maybe<Scalars['ObjectId']>
}

export type IQueryProjectArgs = {
  id?: Maybe<Scalars['ObjectId']>
}

export type IQueryProjectsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  userId?: Maybe<Scalars['ObjectId']>
}

export type IQueryMaterialConfigurationArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryProjectMaterialOptionsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryProjectAddressesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryProjectColoringOptionsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryProjectStatusUpdateOptionsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryProjectTypeOptionsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryDeliveryConfigurationsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryActivitiesConfigurationsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryHierarchyConfigurationsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryLogisticsConfigurationsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryFormsConfigurationsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryDeliveryFieldsConfigurationsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryPresentationSettingsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryZoneMapThresholdsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryProjectHistoriesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryCheckIsProjectCodeUniqueArgs = {
  projectCode: Scalars['String']
}

export type IQueryGetProjectNameArgs = {
  projectCode?: Maybe<Scalars['String']>
}

export type IQueryGetMaturixCastsArgs = {
  struxHubProjectId: Scalars['ObjectId']
}

export type IQueryGetMaturixMonitoringDataArgs = {
  struxHubProjectId: Scalars['ObjectId']
  monitoringId: Scalars['String']
}

export type IQueryGetMaturixProjectsArgs = {
  struxHubProjectId: Scalars['ObjectId']
}

export type IQueryPermitTypesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQuerySitePermitFollowingsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQuerySitePermitsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQuerySitePermitAssignmentsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryAnnouncementsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryAnnouncementAssignmentsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryAnnouncementFollowingsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQuerySitePermitStatusChangesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
  permitId?: Maybe<Scalars['ObjectId']>
}

export type IQuerySitePermitInspectionsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQuerySitePermitInspectionChangesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQuerySitemapItemsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryGlobeViewsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQuerySitemapsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryDefaultPermitTypesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export type IQueryTilesetsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryGetMapboxTilesetsArgs = {
  projectId: Scalars['ObjectId']
}

export type IQueryFormCategoriesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryOperationRulesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryGetCsvUsersArgs = {
  projectId: Scalars['ObjectId']
}

export type IQueryCheckPhoneNumberArgs = {
  phoneNumber: Scalars['String']
}

export type IQueryUserArgs = {
  id?: Maybe<Scalars['ObjectId']>
  email?: Maybe<Scalars['String']>
  firebaseId?: Maybe<Scalars['String']>
}

export type IQueryUsersArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
  getDeleted?: Maybe<Scalars['Boolean']>
}

export type IQueryUserProjectArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
  userId?: Maybe<Scalars['ObjectId']>
}

export type IQueryUserProjectsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  getDeleted?: Maybe<Scalars['Boolean']>
  projectId?: Maybe<Scalars['ObjectId']>
  userId?: Maybe<Scalars['ObjectId']>
}

export type IQueryUserRolesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryTeamsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryDefaultTeamsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export type IQueryTradesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryNotificationsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryUnreadNotificationsArgs = {
  projectId: Scalars['ObjectId']
}

export type IQueryUnreadNotificationsCountArgs = {
  projectId: Scalars['ObjectId']
}

export type IQueryNotifyActivityEntityChangedArgs = {
  notifyActivityEntityChanged: INotifyEntityInput
}

export type IQueryActivityCodeRelationshipsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  scheduleId?: Maybe<Scalars['ObjectId']>
}

export type IQueryActivityResourceRelationshipsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  scheduleId?: Maybe<Scalars['ObjectId']>
}

export type IQueryStatusUpdatesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
  activityP6Codes?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type IQuerySchedulesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryScheduleArgs = {
  id?: Maybe<Scalars['ObjectId']>
}

export type IQueryActivitiesArgs = {
  scheduleId?: Maybe<Scalars['ObjectId']>
  date?: Maybe<Scalars['MillisecondsDate']>
  timezoneId?: Maybe<Scalars['String']>
}

export type IQueryGetActivityArgs = {
  scheduleId?: Maybe<Scalars['ObjectId']>
  activityCode: Scalars['String']
}

export type IQueryRelativeActivitiesArgs = {
  scheduleId?: Maybe<Scalars['ObjectId']>
  activityP6Code?: Maybe<Scalars['String']>
}

export type IQueryCalendarsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  scheduleId?: Maybe<Scalars['ObjectId']>
}

export type IQueryResourcesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  scheduleId?: Maybe<Scalars['ObjectId']>
}

export type IQueryActivityCodesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  scheduleId?: Maybe<Scalars['ObjectId']>
}

export type IQueryActivityCodeTypesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  scheduleId?: Maybe<Scalars['ObjectId']>
}

export type IQueryWorkBreakdownStructureArgs = {
  id?: Maybe<Scalars['ObjectId']>
}

export type IQueryWorkBreakdownStructuresArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  scheduleId?: Maybe<Scalars['ObjectId']>
}

export type IQueryXerProjectsArgs = {
  file: Scalars['String']
}

export type IQueryExcelSheetsArgs = {
  fullFilePath: Scalars['String']
}

export type IQueryActivityFollowingsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryActivityLocationArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryActivityPresetsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
  userId?: Maybe<Scalars['ObjectId']>
}

export type IQueryActivityLocationsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryActivityAssignmentsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryActivityFiltersSettingsArgs = {
  id?: Maybe<Scalars['ObjectId']>
  scheduleId?: Maybe<Scalars['ObjectId']>
}

export type IQueryActivityListCustomFiltersArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryIsActivityCodeClaimedArgs = {
  scheduleId: Scalars['ObjectId']
  code: Scalars['String']
}

export type IQueryLocationsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
  attributeType?: Maybe<LocationType>
}

export type IQueryProjectMaterialsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
  materialsUploadId?: Maybe<Scalars['ObjectId']>
}

export type IQueryProjectMaterialCategoriesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
  materialsUploadId?: Maybe<Scalars['ObjectId']>
}

export type IQueryGetMaterialsExcelSheetsArgs = {
  fullFilePath: Scalars['String']
}

export type IQueryGetCurrentMaterialUploadProgressArgs = {
  projectId: Scalars['ObjectId']
}

export type IQueryCompaniesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryCompanyArgs = {
  id?: Maybe<Scalars['ObjectId']>
  name?: Maybe<Scalars['String']>
}

export type IQueryCompanyRelationshipsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  scheduleId?: Maybe<Scalars['ObjectId']>
}

export type IQueryCompanyTypeTagsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export type IQueryStruxHubCompaniesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
}

export type IQueryStruxHubCompanyArgs = {
  id?: Maybe<Scalars['ObjectId']>
}

export type IQueryReportTemplateArgs = {
  id?: Maybe<Scalars['ObjectId']>
  templateId?: Maybe<Scalars['String']>
}

export type IQueryCastFollowingsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryCastAssignmentsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryScannersArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryScanHistoriesArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type IQueryWorkflowsCustomFiltersArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  projectId?: Maybe<Scalars['ObjectId']>
  userId?: Maybe<Scalars['ObjectId']>
}

export type IQueryGetSignedUploadUrlArgs = {
  getSignedUploadUrl: IGenerateSignedUploadUrlInput
}

export type IQueryConcreteDirectOrderArgs = {
  id?: Maybe<Scalars['ObjectId']>
  cdId?: Maybe<Scalars['Guid']>
}

export type IQueryConcreteDirectOrdersArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  cdIds: Array<Maybe<Scalars['String']>>
}

export type IQueryConcreteDirectPayloadsArgs = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<ISortBy>
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  cdIds?: Maybe<Array<Maybe<Scalars['String']>>>
  cdOrderId?: Maybe<Scalars['Guid']>
}

export interface IReadBy {
  /** User Id. */
  userId?: Maybe<Scalars['ObjectId']>
  /** Was message read. */
  isRead: Scalars['Boolean']
}

export interface IReadByInput {
  /** User Id. */
  userId?: Maybe<Scalars['ObjectId']>
  /** Was message read. */
  isRead: Scalars['Boolean']
}

export enum RecentlyUpdatedMode {
  LastP6Update = 'LastP6Update',
  Today = 'Today',
  LastWeek = 'LastWeek',
  LastMonth = 'LastMonth',
  Last6Week = 'Last6Week',
  DateRange = 'DateRange',
}

export interface IRecentlyUpdatedSavedFilter {
  /** Mode. */
  mode?: Maybe<RecentlyUpdatedMode>
  /** StartDate. */
  startDate?: Maybe<Scalars['MillisecondsDate']>
  /** EndDate. */
  endDate?: Maybe<Scalars['MillisecondsDate']>
}

export interface IRecentlyUpdatedSavedFilterInput {
  /** Mode. */
  mode?: Maybe<RecentlyUpdatedMode>
  /** StartDate. */
  startDate?: Maybe<Scalars['MillisecondsDate']>
  /** EndDate. */
  endDate?: Maybe<Scalars['MillisecondsDate']>
}

export enum RecipientType {
  Roles = 'roles',
  Teams = 'teams',
  Trades = 'trades',
  DefaultTeams = 'defaultTeams',
  Company = 'company',
  User = 'user',
}

export interface IRecipients {
  /** Recipient ids. */
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Recipient type. */
  type?: Maybe<RecipientType>
}

export interface IRecipientsInput {
  /** Recipient ids. */
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Recipient type. */
  type?: Maybe<RecipientType>
}

export interface IRecurringDeliveriesSettings {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Frequency of repeating. */
  frequency: Scalars['Short']
  /** Frequency type enum (weeks, days). */
  frequencyType: CalendricalType
  /** End of repeating date. */
  endDate: Scalars['Long']
  /** Selected day numbers. */
  selectedDays?: Maybe<Array<Maybe<Scalars['Byte']>>>
}

export interface IRecurringDeliveriesSettingsChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IRecurringDeliveriesSettings>
}

export interface IRecurringDeliveriesSettingsInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Frequency of repeating. */
  frequency: Scalars['Short']
  /** Frequency type enum (weeks, days). */
  frequencyType: CalendricalType
  /** End of repeating date. */
  endDate: Scalars['Long']
  /** Selected day numbers. */
  selectedDays?: Maybe<Array<Maybe<Scalars['Byte']>>>
}

export enum RelationshipType {
  ChildOf = 'CHILD_OF',
  ParentOf = 'PARENT_OF',
}

export interface IRelativeActivities {
  /** Predecessors. */
  predecessors: Array<Maybe<IActivity>>
  /** Successors. */
  successors: Array<Maybe<IActivity>>
}

export interface IReport {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Requestor id. */
  requestorId?: Maybe<Scalars['ObjectId']>
  /** User Id. */
  status?: Maybe<ReportStatus>
  /** Failed step. */
  failedStep?: Maybe<Scalars['String']>
  /** Fail reason. */
  failReason?: Maybe<Scalars['ObjectId']>
  /** Report url. */
  reportUrls?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface IReportChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IReport>
}

export interface IReportFileInput {
  /** File id. */
  fileId?: Maybe<Scalars['String']>
  /** File name. */
  fileName?: Maybe<Scalars['String']>
}

export enum ReportStatus {
  Starting = 'STARTING',
  LoadingTemplates = 'LOADING_TEMPLATES',
  FetchingData = 'FETCHING_DATA',
  CompilingHandlebarsTemplate = 'COMPILING_HANDLEBARS_TEMPLATE',
  GeneratingReport = 'GENERATING_REPORT',
  Done = 'DONE',
  Failed = 'FAILED',
}

export interface IReportTemplate {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Report name. */
  name?: Maybe<Scalars['String']>
  /** Template id. */
  templateId?: Maybe<Scalars['String']>
  /** Is deleted. */
  isDeleted?: Maybe<Scalars['Boolean']>
  /** Logo url. */
  files?: Maybe<Array<Maybe<IReportTemplateFile>>>
}

export interface IReportTemplateFile {
  /** Report file title. */
  name?: Maybe<Scalars['String']>
  /** File id. */
  fileId?: Maybe<Scalars['String']>
  /** File type. */
  fileType?: Maybe<FileType>
}

export interface IResource {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Schedule id. */
  scheduleId: Scalars['ObjectId']
  /** Entity id specified in the schedule file. */
  xerEntityId?: Maybe<Scalars['String']>
  /** Schedule entity name. */
  name: Scalars['String']
  /** Short name. */
  shortName?: Maybe<Scalars['String']>
  /** Guid. */
  guid?: Maybe<Scalars['String']>
  /** Parent resource id. */
  parentId?: Maybe<Scalars['ObjectId']>
}

export interface IResourceChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IResource>
}

export interface IResourceList {
  data: Array<IResource>
  page: IPageInfo
}

export interface IRestrictedOption {
  /** Id. */
  id?: Maybe<Scalars['ObjectId']>
  /** Type. */
  type?: Maybe<Scalars['String']>
}

export interface IRestrictedOptionInput {
  /** Id. */
  id?: Maybe<Scalars['ObjectId']>
  /** Type. */
  type?: Maybe<Scalars['String']>
}

export interface IRichText {
  /** Content. */
  content?: Maybe<Scalars['String']>
  /** Attachments. */
  attachments?: Maybe<Array<Maybe<IAttachment>>>
}

export interface IRichTextFieldInput {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  values?: Maybe<Array<Maybe<IRichTextInput>>>
}

export interface IRichTextFormField extends IPermitFieldInterface {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  richTextValues?: Maybe<Array<Maybe<IRichText>>>
}

export interface IRichTextInput {
  /** Content. */
  content?: Maybe<Scalars['String']>
  /** Attachments. */
  attachments?: Maybe<Array<Maybe<IAttachmentInput>>>
}

export interface IRoute extends ILocation {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateInterval>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Delivery hierarchy parent. */
  parent?: Maybe<IDeliveryHierarchyParent>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<LocationType>
  closingDate?: Maybe<Scalars['Long']>
}

export interface IRouteInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateIntervalInput>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Location parent. */
  parent?: Maybe<IDeliveryHierarchyParentInput>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
}

export interface ISaveMemberModelInput {
  /** Id. */
  id?: Maybe<Scalars['ObjectId']>
  /** Email. */
  email?: Maybe<Scalars['String']>
  /** Phone number. */
  phoneNumber?: Maybe<Scalars['String']>
  /** Company alias. */
  companyAlias?: Maybe<Scalars['String']>
  /** First name. */
  firstName?: Maybe<Scalars['String']>
  /** Last name. */
  lastName?: Maybe<Scalars['String']>
  /** Initials. */
  initials?: Maybe<Scalars['String']>
  /** Avatar url. */
  avatarUrl?: Maybe<Scalars['String']>
  /** Setup finished. */
  setUpFinished?: Maybe<Array<Maybe<IProjectSetupFinishedInput>>>
  /** Added by. */
  addedBy?: Maybe<Scalars['String']>
  /** Global role. */
  globalRole?: Maybe<Scalars['String']>
  /** Account position. */
  accountPosition?: Maybe<UserAccountPosition>
  /** Reports to Id. */
  reportsToId?: Maybe<Scalars['ObjectId']>
  /** User project settings. */
  userProjectSettings?: Maybe<IUserProjectInput>
  /** External company name. */
  externalCompanyName?: Maybe<Scalars['String']>
  /** Hide Users in User Directory. */
  isHidden?: Maybe<Scalars['Boolean']>
  /** Optional. Tenant's internal EmployeeId. */
  employeeId?: Maybe<Scalars['String']>
}

export interface ISaveMessageInput {
  readBy?: Maybe<Array<Maybe<IReadByInput>>>
  thumbUrl?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export interface ISavedViewSettings {
  /** Workflow custom filter Id */
  workflowCustomFilterId: Scalars['ObjectId']
  /** Is hidden */
  isHidden?: Maybe<Scalars['Boolean']>
}

export interface ISavedViewSettingsInput {
  /** Workflow custom filter Id */
  workflowCustomFilterId: Scalars['ObjectId']
  /** Is hidden */
  isHidden?: Maybe<Scalars['Boolean']>
}

export interface IScanHistory {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** ScannerId. */
  scannerId?: Maybe<Scalars['ObjectId']>
  /** UserId. */
  userId?: Maybe<Scalars['ObjectId']>
  /** IsAllowed. */
  isAllowed: Scalars['Boolean']
  /** Date. */
  date?: Maybe<Scalars['MillisecondsDate']>
  /** End date. */
  endDate?: Maybe<Scalars['MillisecondsDate']>
  /** Queue date. */
  queueDate?: Maybe<Scalars['MillisecondsDate']>
}

export interface IScanHistoryChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IScanHistory>
}

export interface IScanHistoryInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** ScannerId. */
  scannerId?: Maybe<Scalars['ObjectId']>
  /** UserId. */
  userId?: Maybe<Scalars['ObjectId']>
  /** IsAllowed. */
  isAllowed: Scalars['Boolean']
  /** Date. */
  date?: Maybe<Scalars['MillisecondsDate']>
  /** End date. */
  endDate?: Maybe<Scalars['MillisecondsDate']>
  /** Queue date. */
  queueDate?: Maybe<Scalars['MillisecondsDate']>
}

export interface IScanHistoryList {
  data: Array<IScanHistory>
  page: IPageInfo
}

export interface IScanner {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Is Open Scanner. */
  isOpenScanner: Scalars['Boolean']
  /** Is Scanner Active. */
  isActive: Scalars['Boolean']
  /** Is Timed Scanner. */
  isTimedScanner: Scalars['Boolean']
  /** Is Queue Scanner. */
  isQueueScanner: Scalars['Boolean']
  /** Is Queue Active. */
  isQueueActive: Scalars['Boolean']
  /** Timer automatically ENDS at close of work day. */
  isTimedByDay: Scalars['Boolean']
  /** Is Deleted. */
  isDeleted: Scalars['Boolean']
  /** AllowedUsers. */
  allowedUsers?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** AssociatedCodes. */
  associatedCodes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** BadgeName. */
  badgeName?: Maybe<Scalars['String']>
  /** MaxElapsedTime. */
  maxElapsedTime?: Maybe<Scalars['Int']>
  /** Location. */
  location?: Maybe<ISiteLocation>
  /** IsRealLocationRelated. */
  isRealLocationRelated: Scalars['Boolean']
  /** LocationName. */
  locationName?: Maybe<Scalars['String']>
  /** IsInverted. */
  isInverted: Scalars['Boolean']
}

export interface IScannerChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IScanner>
}

export interface IScannerInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Is Open Scanner. */
  isOpenScanner: Scalars['Boolean']
  /** Is Scanner Active. */
  isActive: Scalars['Boolean']
  /** Is Timed Scanner. */
  isTimedScanner: Scalars['Boolean']
  /** Is Queue Scanner. */
  isQueueScanner: Scalars['Boolean']
  /** Is Queue Active. */
  isQueueActive: Scalars['Boolean']
  /** Timer automatically ENDS at close of work day. */
  isTimedByDay: Scalars['Boolean']
  /** Is Deleted. */
  isDeleted: Scalars['Boolean']
  /** AllowedUsers. */
  allowedUsers?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** AssociatedCodes. */
  associatedCodes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** BadgeName. */
  badgeName?: Maybe<Scalars['String']>
  /** MaxElapsedTime. */
  maxElapsedTime?: Maybe<Scalars['Int']>
  /** Location. */
  location?: Maybe<ISiteLocationInput>
  /** IsRealLocationRelated. */
  isRealLocationRelated: Scalars['Boolean']
  /** LocationName. */
  locationName?: Maybe<Scalars['String']>
  /** IsInverted. */
  isInverted: Scalars['Boolean']
}

export interface IScannerList {
  data: Array<IScanner>
  page: IPageInfo
}

export interface ISchedule {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Uploader id. */
  userId?: Maybe<Scalars['ObjectId']>
  /** Schedule file name. */
  fileName: Scalars['String']
  /** Number of activities in the file. */
  activityQuantity: Scalars['Int']
  /** Number of companies in the file. */
  companyQuantity: Scalars['Int']
}

export interface IScheduleChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ISchedule>
}

export interface IScheduleComparingResult {
  /** Resources before and after upload. */
  resourceForCompare: ICategoryForCompare
  /** Activity codes by types before and after upload. */
  activityCodeTypesForCompare: Array<Maybe<ICategoryForCompare>>
}

export interface IScheduleList {
  data: Array<ISchedule>
  page: IPageInfo
}

export interface IScreen {
  /** Duration of showing screen. */
  duration: Scalars['Int']
  /** Is screen shown. */
  isShown: Scalars['Boolean']
  /** Screen id. */
  key: PresentationScreenKey
  /** Order to be shown. */
  order: Scalars['Int']
  /** Should autoplay screen entity. */
  shouldShowEntity?: Maybe<Scalars['Boolean']>
  /** Duration of showing entity on the screen. */
  entityDuration?: Maybe<Scalars['Int']>
  /** Screen entity id. */
  entityKey?: Maybe<PresentationScreenEntityKey>
}

export interface IScreenInput {
  /** Duration of showing screen. */
  duration: Scalars['Int']
  /** Is screen shown. */
  isShown: Scalars['Boolean']
  /** Screen id. */
  key: PresentationScreenKey
  /** Order to be shown. */
  order: Scalars['Int']
  /** Should autoplay screen entity. */
  shouldShowEntity?: Maybe<Scalars['Boolean']>
  /** Duration of showing entity on the screen. */
  entityDuration?: Maybe<Scalars['Int']>
  /** Screen entity id. */
  entityKey?: Maybe<PresentationScreenEntityKey>
}

export interface ISheetData {
  /** Name. */
  name: Scalars['String']
  /** Column names. */
  columns: Array<Maybe<Scalars['String']>>
  /** Column names to map to. */
  columnsToMapTo?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface ISheetDataInput {
  /** Name. */
  name: Scalars['String']
  /** Column names. */
  columns: Array<Maybe<Scalars['String']>>
  /** Column names to map to. */
  columnsToMapTo: Array<Maybe<Scalars['String']>>
}

export interface ISiteLocation {
  /** Location id. */
  id: Scalars['ObjectId']
  /** Location type. */
  type?: Maybe<LocationType>
  /** Levels. */
  levels?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface ISiteLocationInput {
  /** Location id. */
  id: Scalars['ObjectId']
  /** Location type. */
  type?: Maybe<LocationType>
  /** Levels. */
  levels?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface ISitePermit {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Status. */
  status: SitePermitStatus
  /** Start date. */
  startDate: Scalars['MillisecondsDate']
  /** Title. */
  title?: Maybe<Scalars['String']>
  /** Is auto activation enabled. */
  isAutoActivationEnabled?: Maybe<Scalars['Boolean']>
  /** Type Id. */
  typeId: Scalars['ObjectId']
  /** End Date. */
  endDate: Scalars['MillisecondsDate']
  /** Is closure. */
  isClosure: Scalars['Boolean']
  /** Thread Id. */
  threadId?: Maybe<Scalars['ObjectId']>
  /** Workflow step level. */
  workflowStepLevel?: Maybe<Scalars['Int']>
  /** Current step Id. */
  currentWorkflowStepId: Scalars['ObjectId']
  /** Site permit code. */
  code?: Maybe<Scalars['String']>
  /** Is late request. */
  isLateRequest?: Maybe<Scalars['Boolean']>
  /** Specific inspection options. */
  specificInspectionOptions?: Maybe<IInspectionOptions>
  /** Fields. */
  fields?: Maybe<Array<Maybe<IPermitFieldInterface>>>
  /** Determines whether the workflow is deleted. */
  isDeleted?: Maybe<Scalars['Boolean']>
  /** Is all day. */
  isAllDay?: Maybe<Scalars['Boolean']>
}

export interface ISitePermitAssignment {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Site permit id. */
  entityId: Scalars['ObjectId']
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Recipients. */
  recipients: Array<Maybe<IRecipients>>
}

export interface ISitePermitAssignmentChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ISitePermitAssignment>
}

export interface ISitePermitAssignmentInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Site permit id. */
  entityId: Scalars['ObjectId']
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Recipients. */
  recipients: Array<Maybe<IRecipientsInput>>
}

export interface ISitePermitAssignmentList {
  data: Array<ISitePermitAssignment>
  page: IPageInfo
}

export interface ISitePermitChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ISitePermit>
}

export interface ISitePermitFollowing {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Entity Id. */
  entityId: Scalars['ObjectId']
  /** User Id. */
  userId: Scalars['ObjectId']
}

export interface ISitePermitFollowingChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ISitePermitFollowing>
}

export interface ISitePermitFollowingInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Entity Id. */
  entityId: Scalars['ObjectId']
  /** User Id. */
  userId: Scalars['ObjectId']
}

export interface ISitePermitFollowingList {
  data: Array<ISitePermitFollowing>
  page: IPageInfo
}

export interface ISitePermitInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Status. */
  status: SitePermitStatus
  /** Title. */
  title?: Maybe<Scalars['String']>
  /** Is auto activation enabled. */
  isAutoActivationEnabled?: Maybe<Scalars['Boolean']>
  /** Type Id. */
  typeId: Scalars['ObjectId']
  /** Current workflow step Id. */
  currentWorkflowStepId: Scalars['ObjectId']
  /** End date. */
  endDate: Scalars['MillisecondsDate']
  /** Is closure. */
  isClosure?: Maybe<Scalars['Boolean']>
  /** Is all day. */
  isAllDay?: Maybe<Scalars['Boolean']>
  /** Start date. */
  startDate: Scalars['MillisecondsDate']
  /** Thread Id. */
  threadId?: Maybe<Scalars['ObjectId']>
  /** Workflow step level. */
  workflowStepLevel: Scalars['Int']
  /** Site permit code. */
  code?: Maybe<Scalars['String']>
  /** Specific inspection options. */
  specificInspectionOptions?: Maybe<IInspectionOptionsInput>
}

export interface ISitePermitInspection {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Permit Id. */
  permitId: Scalars['ObjectId']
  /** User Id. */
  userId: Scalars['ObjectId']
  /** Fields. */
  fields?: Maybe<Array<Maybe<IPermitFieldInterface>>>
  /** Inspection date. */
  inspectionDate?: Maybe<Scalars['MillisecondsDate']>
}

export interface ISitePermitInspectionChange {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Inspection Id . */
  inspectionId: Scalars['ObjectId']
  /** User Id. */
  userId: Scalars['ObjectId']
  /** Fields. */
  fields?: Maybe<Array<Maybe<IPermitFieldInterface>>>
}

export interface ISitePermitInspectionChangeChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ISitePermitInspectionChange>
}

export interface ISitePermitInspectionChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ISitePermitInspection>
}

export interface ISitePermitInspectionChangeList {
  data: Array<ISitePermitInspectionChange>
  page: IPageInfo
}

export interface ISitePermitInspectionInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Permit Id. */
  permitId: Scalars['ObjectId']
  /** User Id. */
  userId: Scalars['ObjectId']
  /** Inspection date. */
  inspectionDate?: Maybe<Scalars['MillisecondsDate']>
}

export interface ISitePermitInspectionList {
  data: Array<ISitePermitInspection>
  page: IPageInfo
}

export interface ISitePermitList {
  data: Array<ISitePermit>
  page: IPageInfo
}

export enum SitePermitStatus {
  Changed = 'Changed',
  Requested = 'Requested',
  Submitted = 'Submitted',
  Reviewed = 'Reviewed',
  Accepted = 'Accepted',
  Denied = 'Denied',
  Active = 'Active',
  OnSite = 'OnSite',
  Done = 'Done',
  ToInspect = 'ToInspect',
  Failed = 'Failed',
  Passed = 'Passed',
  Deleted = 'Deleted',
  Ended = 'Ended',
}

export interface ISitePermitStatusChange {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Permit status. */
  status?: Maybe<SitePermitStatus>
  /** Workflow step level. */
  workflowStepLevel?: Maybe<Scalars['Int']>
  /** Permit Id. */
  permitId?: Maybe<Scalars['ObjectId']>
  /** Thread Id. */
  threadId?: Maybe<Scalars['ObjectId']>
  /** User Id. */
  userId?: Maybe<Scalars['ObjectId']>
}

export interface ISitePermitStatusChangeChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ISitePermitStatusChange>
}

export interface ISitePermitStatusChangeList {
  data: Array<ISitePermitStatusChange>
  page: IPageInfo
}

export interface ISitemap {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Basemap Id. */
  basemapId?: Maybe<Scalars['ObjectId']>
  /** Filled image. */
  filledImage?: Maybe<Scalars['String']>
  /** Items filled image. */
  itemsFilledImage?: Maybe<Scalars['String']>
  /** Is labels shown. */
  isLabelsShown: Scalars['Boolean']
  /** Is project overview map. */
  isProjectOverviewMap: Scalars['Boolean']
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Altitude. */
  altitude?: Maybe<Scalars['Float']>
  /** Pitch. */
  pitch?: Maybe<Scalars['Float']>
  /** Zoom. */
  zoom?: Maybe<Scalars['Float']>
  /** Bearing. */
  bearing?: Maybe<Scalars['Float']>
  /** Width. */
  width?: Maybe<Scalars['Float']>
  /** Height. */
  height?: Maybe<Scalars['Float']>
  /** Earth coordinates of bounding box. */
  bounds?: Maybe<IAddressBounds>
  /** Earth coordinates of the center point. */
  center?: Maybe<ILatLng>
  /** Earth coordinates of the corner points. */
  geoCorners?: Maybe<Array<Maybe<IGeoJson2DGeographicCoordinates>>>
  items?: Maybe<Array<Maybe<ISitemapSpecificItemData>>>
}

export interface ISitemapChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ISitemap>
}

export interface ISitemapCircle extends ISitemapItemShapeInterface {
  /** Line width. */
  lineWidth: Scalars['Int']
  /** Line color. */
  lineColor?: Maybe<Scalars['String']>
  /** Fill color. */
  fillColor?: Maybe<Scalars['String']>
  /** Is item displayed on the map. */
  isDisplayed?: Maybe<Scalars['Boolean']>
  /** Fill opacity. */
  fillOpacity: Scalars['Float']
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Radius. */
  radius: Scalars['Float']
  /** Position. */
  position?: Maybe<IPosition>
  /** Is divided. */
  isDivided: Scalars['Boolean']
  /** Division start angle. */
  divisionStartAngle?: Maybe<Scalars['Float']>
  /** Division end angle. */
  divisionEndAngle?: Maybe<Scalars['Float']>
}

export interface ISitemapCircleInput {
  /** Line width. */
  lineWidth: Scalars['Int']
  /** Line color. */
  lineColor?: Maybe<Scalars['String']>
  /** Fill color. */
  fillColor?: Maybe<Scalars['String']>
  /** Is item displayed on the map. */
  isDisplayed?: Maybe<Scalars['Boolean']>
  /** Fill opacity. */
  fillOpacity: Scalars['Float']
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Radius. */
  radius: Scalars['Float']
  /** Position. */
  position?: Maybe<IPositionInput>
  /** Is divided. */
  isDivided: Scalars['Boolean']
  /** Division start angle. */
  divisionStartAngle?: Maybe<Scalars['Float']>
  /** Division end angle. */
  divisionEndAngle?: Maybe<Scalars['Float']>
}

export interface ISitemapInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Basemap Id. */
  basemapId?: Maybe<Scalars['ObjectId']>
  /** Filled image. */
  filledImage?: Maybe<Scalars['String']>
  /** Items filled image. */
  itemsFilledImage?: Maybe<Scalars['String']>
  /** Is labels shown. */
  isLabelsShown: Scalars['Boolean']
  /** Is project overview map. */
  isProjectOverviewMap: Scalars['Boolean']
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Bearing. */
  bearing?: Maybe<Scalars['Float']>
  /** Altitude. */
  altitude?: Maybe<Scalars['Float']>
  /** Height. */
  height?: Maybe<Scalars['Float']>
  /** Width. */
  width?: Maybe<Scalars['Float']>
  /** Zoom. */
  zoom?: Maybe<Scalars['Float']>
  /** Pitch. */
  pitch?: Maybe<Scalars['Float']>
  /** Earth coordinates of bounding box. */
  bounds?: Maybe<IAddressBoundsInput>
  /** Earth coordinates of the center point. */
  center?: Maybe<ILatLngInput>
  /** Earth coordinates of the corner points. */
  geoCorners?: Maybe<Array<Maybe<IGeoJson2DGeographicCoordinatesInput>>>
}

export interface ISitemapItem {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Coordinates. */
  coordinates?: Maybe<IGeoJson2DGeographicCoordinates>
  /** Color. */
  color?: Maybe<Scalars['String']>
  /** Is referenced. */
  isReferenced?: Maybe<Scalars['Boolean']>
  /** Icon name. */
  iconName?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Assigned to. */
  assignedTo?: Maybe<LocationType>
  /** Assigned Id. */
  assignedId?: Maybe<Scalars['ObjectId']>
  /** Parent. */
  parent?: Maybe<IDeliveryHierarchyParent>
  /** Coordinates of the shape. */
  shapeCoordinates?: Maybe<ISitemapItemShapeCoordinates>
}

export interface ISitemapItemChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ISitemapItem>
}

export interface ISitemapItemInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Coordinates. */
  coordinates?: Maybe<IGeoJson2DGeographicCoordinatesInput>
  /** Color. */
  color?: Maybe<Scalars['String']>
  /** Is referenced. */
  isReferenced?: Maybe<Scalars['Boolean']>
  /** Icon name. */
  iconName?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Assigned to. */
  assignedTo?: Maybe<LocationType>
  /** Assigned Id. */
  assignedId?: Maybe<Scalars['ObjectId']>
  /** Parent. */
  parent?: Maybe<IDeliveryHierarchyParentInput>
  /** Coordinates of the shape. */
  shapeCoordinates?: Maybe<ISitemapItemShapeCoordinatesInput>
}

export interface ISitemapItemList {
  data: Array<ISitemapItem>
  page: IPageInfo
}

export interface ISitemapItemShapeCoordinates {
  /** Coordinates. */
  coordinates?: Maybe<Array<Maybe<IGeoJson2DGeographicCoordinates>>>
  /** Is closed. */
  isClosed?: Maybe<Scalars['Boolean']>
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Division end angle. */
  divisionEndAngle?: Maybe<Scalars['Float']>
  /** Division start angle. */
  divisionStartAngle?: Maybe<Scalars['Float']>
}

export interface ISitemapItemShapeCoordinatesInput {
  /** Coordinates. */
  coordinates?: Maybe<Array<Maybe<IGeoJson2DGeographicCoordinatesInput>>>
  /** Is closed. */
  isClosed?: Maybe<Scalars['Boolean']>
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Division end angle. */
  divisionEndAngle?: Maybe<Scalars['Float']>
  /** Division start angle. */
  divisionStartAngle?: Maybe<Scalars['Float']>
}

export type ISitemapItemShapeInterface = {
  /** Line width. */
  lineWidth: Scalars['Int']
  /** Line color. */
  lineColor?: Maybe<Scalars['String']>
  /** Fill color. */
  fillColor?: Maybe<Scalars['String']>
  /** Is item displayed on the map. */
  isDisplayed?: Maybe<Scalars['Boolean']>
  /** Fill opacity. */
  fillOpacity: Scalars['Float']
  /** Type. */
  type?: Maybe<Scalars['String']>
}

export enum SitemapItemShapeType {
  Circle = 'circle',
  Polyline = 'polyline',
  Rectangle = 'rectangle',
}

export enum SitemapLineArrowPosition {
  None = 'None',
  StartEnd = 'StartEnd',
  StartEndReversed = 'StartEndReversed',
  StartMiddleEnd = 'StartMiddleEnd',
  StartMiddleEndReversed = 'StartMiddleEndReversed',
}

export interface ISitemapList {
  data: Array<ISitemap>
  page: IPageInfo
}

export interface ISitemapPin {
  /** Position. */
  position?: Maybe<IPosition>
  /** IsHidden. */
  isHidden?: Maybe<Scalars['Boolean']>
}

export interface ISitemapPinInput {
  /** Position. */
  position?: Maybe<IPositionInput>
  /** Is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>
}

export interface ISitemapPolyline extends ISitemapItemShapeInterface {
  /** Line width. */
  lineWidth: Scalars['Int']
  /** Line color. */
  lineColor?: Maybe<Scalars['String']>
  /** Fill color. */
  fillColor?: Maybe<Scalars['String']>
  /** Is item displayed on the map. */
  isDisplayed?: Maybe<Scalars['Boolean']>
  /** Fill opacity. */
  fillOpacity: Scalars['Float']
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Is closed. */
  isClosed: Scalars['Boolean']
  /** Points. */
  points?: Maybe<Array<Maybe<IPosition>>>
  /** Arrow position. */
  arrowPosition?: Maybe<SitemapLineArrowPosition>
}

export interface ISitemapPolylineInput {
  /** Line width. */
  lineWidth: Scalars['Int']
  /** Line color. */
  lineColor?: Maybe<Scalars['String']>
  /** Fill color. */
  fillColor?: Maybe<Scalars['String']>
  /** Is item displayed on the map. */
  isDisplayed?: Maybe<Scalars['Boolean']>
  /** Fill opacity. */
  fillOpacity: Scalars['Float']
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** IsClosed. */
  isClosed?: Maybe<Scalars['Boolean']>
  /** Points. */
  points?: Maybe<Array<Maybe<IPositionInput>>>
  /** Arrow position. */
  arrowPosition?: Maybe<SitemapLineArrowPosition>
}

export interface ISitemapRectangle extends ISitemapItemShapeInterface {
  /** Line width. */
  lineWidth: Scalars['Int']
  /** Line color. */
  lineColor?: Maybe<Scalars['String']>
  /** Fill color. */
  fillColor?: Maybe<Scalars['String']>
  /** Is item displayed on the map. */
  isDisplayed?: Maybe<Scalars['Boolean']>
  /** Fill opacity. */
  fillOpacity: Scalars['Float']
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Position. */
  position?: Maybe<IPosition>
  /** Width. */
  width: Scalars['Float']
  /** Height. */
  height: Scalars['Float']
  /** Rotation. */
  rotation?: Maybe<Scalars['Float']>
}

export interface ISitemapRectangleInput {
  /** Line width. */
  lineWidth: Scalars['Int']
  /** Line color. */
  lineColor?: Maybe<Scalars['String']>
  /** Fill color. */
  fillColor?: Maybe<Scalars['String']>
  /** Is item displayed on the map. */
  isDisplayed?: Maybe<Scalars['Boolean']>
  /** Fill opacity. */
  fillOpacity: Scalars['Float']
  /** Type. */
  type?: Maybe<Scalars['String']>
  /** Position. */
  position?: Maybe<IPositionInput>
  /** Width. */
  width: Scalars['Float']
  /** Height. */
  height: Scalars['Float']
  /** Rotation. */
  rotation?: Maybe<Scalars['Float']>
}

export interface ISitemapSpecificItemData {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Sitemap Id. */
  sitemapId?: Maybe<Scalars['ObjectId']>
  /** Sitemap item Id. */
  sitemapItemId?: Maybe<Scalars['ObjectId']>
  /** Is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>
  /** Icon. */
  icon?: Maybe<ISitemapPin>
  /** Label. */
  label?: Maybe<ISitemapTextBox>
  /** Shape. */
  shape?: Maybe<ISitemapItemShapeInterface>
}

export interface ISitemapSpecificItemDataChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ISitemapSpecificItemData>
}

export interface ISitemapSpecificItemDataCircleInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Sitemap Id. */
  sitemapId?: Maybe<Scalars['ObjectId']>
  /** Sitemap item Id. */
  sitemapItemId?: Maybe<Scalars['ObjectId']>
  /** Is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>
  /** Icon. */
  icon?: Maybe<ISitemapPinInput>
  /** Label. */
  label?: Maybe<ISitemapTextBoxInput>
  /** Shape. */
  shape?: Maybe<ISitemapCircleInput>
}

export interface ISitemapSpecificItemDataPolylineInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Sitemap Id. */
  sitemapId?: Maybe<Scalars['ObjectId']>
  /** Sitemap item Id. */
  sitemapItemId?: Maybe<Scalars['ObjectId']>
  /** Is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>
  /** Icon. */
  icon?: Maybe<ISitemapPinInput>
  /** Label. */
  label?: Maybe<ISitemapTextBoxInput>
  /** Shape. */
  shape?: Maybe<ISitemapPolylineInput>
}

export interface ISitemapSpecificItemDataRectangleInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Sitemap Id. */
  sitemapId?: Maybe<Scalars['ObjectId']>
  /** Sitemap item Id. */
  sitemapItemId?: Maybe<Scalars['ObjectId']>
  /** Is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>
  /** Icon. */
  icon?: Maybe<ISitemapPinInput>
  /** Label. */
  label?: Maybe<ISitemapTextBoxInput>
  /** Shape. */
  shape?: Maybe<ISitemapRectangleInput>
}

export interface ISitemapTextBox {
  /** Font size. */
  fontSize: Scalars['Int']
  /** Is text box displayed. */
  isTextBoxDisplayed: Scalars['Boolean']
  /** Position. */
  position?: Maybe<IPosition>
  /** Is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>
  /** Color. */
  color?: Maybe<Scalars['String']>
}

export interface ISitemapTextBoxInput {
  /** Font size. */
  fontSize: Scalars['Int']
  /** Is text box displayed. */
  isTextBoxDisplayed: Scalars['Boolean']
  /** Position. */
  position?: Maybe<IPositionInput>
  /** Is hidden. */
  isHidden?: Maybe<Scalars['Boolean']>
  /** Color. */
  color?: Maybe<Scalars['String']>
}

export interface ISitemapsSetupSettings {
  /** Last edited sitemap id. */
  lastEditedSitemapId?: Maybe<Scalars['ObjectId']>
}

export interface ISitemapsSetupSettingsInput {
  /** Last edited sitemap id. */
  lastEditedSitemapId?: Maybe<Scalars['ObjectId']>
}

export interface ISortBy {
  by: Scalars['String']
  order?: Maybe<SortDirection>
}

export enum SortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export interface IStaging extends ILocation {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateInterval>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Delivery hierarchy parent. */
  parent?: Maybe<IDeliveryHierarchyParent>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<LocationType>
  closingDate?: Maybe<Scalars['Long']>
}

export interface IStagingInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateIntervalInput>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Location parent. */
  parent?: Maybe<IDeliveryHierarchyParentInput>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
}

export interface IStatusChange {
  /** Date */
  date?: Maybe<Scalars['String']>
  /** Email */
  email?: Maybe<Scalars['String']>
  /** Status */
  status?: Maybe<Scalars['String']>
  /** User Id */
  userId?: Maybe<Scalars['String']>
  /** User Name */
  userName?: Maybe<Scalars['String']>
  /** User Company */
  userCompany?: Maybe<Scalars['String']>
}

export interface IStatusUpdate {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Activity P6 code. */
  activityP6Code: Scalars['String']
  /** Actual finish date. */
  actualFinishDate?: Maybe<Scalars['MillisecondsDate']>
  /** Actual start date. */
  actualStartDate?: Maybe<Scalars['MillisecondsDate']>
  /** The date this status update is created for. */
  dateFor: Scalars['MillisecondsDate']
  /** Author id. */
  authorId: Scalars['ObjectId']
  /** Thread id. */
  threadId?: Maybe<Scalars['ObjectId']>
  /** Companies. */
  companies?: Maybe<Array<Maybe<ICompanyStatusUpdate>>>
  /** Company name. */
  companyName?: Maybe<Scalars['String']>
  /** Is deleted. */
  isDeleted?: Maybe<Scalars['Boolean']>
  /** Man power. */
  manpower?: Maybe<Scalars['Int']>
  /** Man power updated at. */
  manpowerUpdatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** Percent complete. */
  percentComplete?: Maybe<Scalars['Int']>
  /** Percent complete updated at. */
  percentCompleteUpdatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** Planned percent complete. */
  plannedPercentComplete?: Maybe<Scalars['Float']>
  /** Type. */
  type?: Maybe<StatusUpdateType>
  /** Is inherited. */
  isInherited?: Maybe<Scalars['Boolean']>
  /** Update version. */
  updateVersion?: Maybe<Scalars['Int']>
}

export interface IStatusUpdateChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IStatusUpdate>
}

export interface IStatusUpdateCompanyInput {
  /** Name. */
  name?: Maybe<Scalars['String']>
  /** Manpower. */
  manpower?: Maybe<Scalars['Int']>
  /** Percent complete. */
  percentComplete?: Maybe<Scalars['Int']>
}

export interface IStatusUpdateDateInput {
  /** Man power. */
  manpower?: Maybe<Scalars['Int']>
  /** Percent complete. */
  percentComplete?: Maybe<Scalars['Int']>
  /** Unix time. */
  unixTime: Scalars['MillisecondsDate']
  /** Timezone id. */
  timezoneId: Scalars['String']
}

export interface IStatusUpdateInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Activity P6 code. */
  activityP6Code: Scalars['String']
  /** Actual finish date. */
  actualFinishDate?: Maybe<Scalars['MillisecondsDate']>
  /** Actual start date. */
  actualStartDate?: Maybe<Scalars['MillisecondsDate']>
  /** The date this status update is created for. */
  dateFor: Scalars['MillisecondsDate']
  /** Author id. */
  authorId: Scalars['ObjectId']
  /** Thread id. */
  threadId?: Maybe<Scalars['ObjectId']>
  /** Companies. */
  companies?: Maybe<Array<Maybe<ICompanyStatusUpdateInput>>>
  /** Company name. */
  companyName?: Maybe<Scalars['String']>
  /** Is deleted. */
  isDeleted?: Maybe<Scalars['Boolean']>
  /** Man power. */
  manpower?: Maybe<Scalars['Int']>
  /** Man power updated at. */
  manpowerUpdatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** Percent complete. */
  percentComplete?: Maybe<Scalars['Int']>
  /** Percent complete updated at. */
  percentCompleteUpdatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** Planned percent complete. */
  plannedPercentComplete?: Maybe<Scalars['Float']>
  /** Type. */
  type?: Maybe<StatusUpdateType>
  /** Is inherited. */
  isInherited?: Maybe<Scalars['Boolean']>
  /** Update version. */
  updateVersion?: Maybe<Scalars['Int']>
}

export interface IStatusUpdateList {
  data: Array<IStatusUpdate>
  page: IPageInfo
}

export interface IStatusUpdateMultiCompaniesInput {
  /** Unix time. */
  unixTime: Scalars['MillisecondsDate']
  /** Actual start date. */
  actualStartDate?: Maybe<Scalars['MillisecondsDate']>
  /** Actual finish date. */
  actualFinishDate?: Maybe<Scalars['MillisecondsDate']>
  /** Type. */
  type?: Maybe<StatusUpdateType>
  /** Companies. */
  companies?: Maybe<Array<Maybe<IStatusUpdateCompanyInput>>>
}

export enum StatusUpdateType {
  Active = 'Active',
  P6 = 'P6',
  SetDate = 'SetDate',
  Missed = 'Missed',
  Recalc = 'Recalc',
  Bulk = 'Bulk',
  Change = 'Change',
}

export interface IStringPair {
  /** The Key. */
  key?: Maybe<Scalars['String']>
  /** The Value. */
  value?: Maybe<Scalars['String']>
}

export interface IStringPairInput {
  /** The Key. */
  key?: Maybe<Scalars['String']>
  /** The Value. */
  value?: Maybe<Scalars['String']>
}

export interface IStringPermitField extends IPermitFieldInterface {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  stringValues?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface IStringPermitFieldInput {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  values?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface IStruxHubCompany {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Company relationships. */
  relationships?: Maybe<Array<Maybe<IEntityRelationship>>>
  /** Company name. */
  name?: Maybe<Scalars['String']>
  /** Company code. */
  code?: Maybe<Scalars['String']>
  /** FavIcon URL. */
  favIconUrl?: Maybe<Scalars['String']>
  /** Logo URL. */
  logoUrl?: Maybe<Scalars['String']>
  /** Avatar URL. */
  avatarUrl?: Maybe<Scalars['String']>
  /** Business phone. */
  businessPhone?: Maybe<Scalars['String']>
  /** Business email. */
  businessEmail?: Maybe<Scalars['String']>
  /** DBA. */
  dba?: Maybe<Scalars['String']>
  /** License number. */
  licenseNumber?: Maybe<Scalars['String']>
  /** Company address. */
  address?: Maybe<ICompanyAddress>
}

export interface IStruxHubCompanyList {
  data: Array<IStruxHubCompany>
  page: IPageInfo
}

export interface ISubscription {
  basemap?: Maybe<IBasemapChangeEvent>
  thread?: Maybe<IThreadChangeEvent>
  contentObject?: Maybe<IContentObjectChangeEvent>
  message?: Maybe<IMessageChangeEvent>
  photo?: Maybe<IPhotoChangeEvent>
  sitemapItem?: Maybe<ISitemapItemChangeEvent>
  constraint?: Maybe<IConstraintChangeEvent>
  whiteListItem?: Maybe<IWhiteListItemChangeEvent>
  weatherForecast?: Maybe<IWeatherForecastChangeEvent>
  analyticsSettings?: Maybe<IAnalyticsSettingsChangeEvent>
  configuration?: Maybe<IConfigurationChangeEvent>
  closure?: Maybe<IClosureChangeEvent>
  closureFollowing?: Maybe<IClosureFollowingChangeEvent>
  closureAssignment?: Maybe<IClosureAssignmentChangeEvent>
  delivery?: Maybe<IDeliveryChangeEvent>
  deliveryStatusChange?: Maybe<IDeliveryStatusChangeChangeEvent>
  deliveryUnit?: Maybe<IDeliveryUnitChangeEvent>
  deliveryVehicleType?: Maybe<IDeliveryVehicleTypeChangeEvent>
  deliveryFollowing?: Maybe<IDeliveryFollowingChangeEvent>
  deliveryAssignment?: Maybe<IDeliveryAssignmentChangeEvent>
  recurringDeliveriesSettings?: Maybe<IRecurringDeliveriesSettingsChangeEvent>
  operationRule?: Maybe<IOperationRuleChangeEvent>
  project?: Maybe<IProjectChangeEvent>
  projectMaterialOptions?: Maybe<IProjectMaterialOptionsChangeEvent>
  projectAddress?: Maybe<IProjectAddressChangeEvent>
  projectColoringOptions?: Maybe<IProjectColoringOptionsChangeEvent>
  projectStatusUpdateOptions?: Maybe<IProjectStatusUpdateOptionsChangeEvent>
  projectTypeOptions?: Maybe<IProjectTypeOptionsChangeEvent>
  deliveryConfigurations?: Maybe<IDeliveryConfigurationsChangeEvent>
  activitiesConfigurations?: Maybe<IActivitiesConfigurationsChangeEvent>
  logisticsConfigurations?: Maybe<ILogisticsConfigurationsChangeEvent>
  formsConfigurations?: Maybe<IFormsConfigurationsChangeEvent>
  deliveryFieldsConfigurations?: Maybe<IDeliveryFieldsConfigurationsChangeEvent>
  presentationSettings?: Maybe<IPresentationSettingsChangeEvent>
  zoneMapThresholds?: Maybe<IZoneMapThresholdsChangeEvent>
  projectHistory?: Maybe<IProjectHistoryChangeEvent>
  materialConfiguration?: Maybe<IMaterialConfigurationChangeEvent>
  permitType?: Maybe<IPermitTypeChangeEvent>
  sitePermit?: Maybe<ISitePermitChangeEvent>
  sitePermitAssignment?: Maybe<ISitePermitAssignmentChangeEvent>
  sitePermitFollowing?: Maybe<ISitePermitFollowingChangeEvent>
  announcement?: Maybe<IAnnouncementChangeEvent>
  announcementAssignment?: Maybe<IAnnouncementAssignmentChangeEvent>
  announcementFollowing?: Maybe<IAnnouncementFollowingChangeEvent>
  sitePermitStatusChange?: Maybe<ISitePermitStatusChangeChangeEvent>
  sitePermitInspectionChange?: Maybe<ISitePermitInspectionChangeChangeEvent>
  sitePermitInspection?: Maybe<ISitePermitInspectionChangeEvent>
  sitemap?: Maybe<ISitemapChangeEvent>
  sitemapSpecificItemData?: Maybe<ISitemapSpecificItemDataChangeEvent>
  globeView?: Maybe<IGlobeViewChangeEvent>
  globeViewSpecificItemData?: Maybe<IGlobeViewSpecificItemDataChangeEvent>
  tileset?: Maybe<ITilesetChangeEvent>
  formCategory?: Maybe<IFormCategoryChangeEvent>
  user?: Maybe<IUserChangeEvent>
  userProject?: Maybe<IUserProjectChangeEvent>
  userRole?: Maybe<IUserRoleChangeEvent>
  team?: Maybe<ITeamChangeEvent>
  trade?: Maybe<ITradeChangeEvent>
  notification?: Maybe<INotificationChangeEvent>
  activityCodeRelationship?: Maybe<IActivityCodeRelationshipChangeEvent>
  statusUpdate?: Maybe<IStatusUpdateChangeEvent>
  schedule?: Maybe<IScheduleChangeEvent>
  uploadScheduleProgress?: Maybe<IUploadScheduleProgressChangeEvent>
  activity?: Maybe<IActivityChangeEvent>
  activityCodeType?: Maybe<IActivityCodeTypeChangeEvent>
  activityCode?: Maybe<IActivityCodeChangeEvent>
  resource?: Maybe<IResourceChangeEvent>
  activityResourceRelationship?: Maybe<IActivityResourceRelationshipChangeEvent>
  activityPreset?: Maybe<IActivityPresetChangeEvent>
  activityLocation?: Maybe<IActivityLocationChangeEvent>
  activityAssignment?: Maybe<IActivityAssignmentChangeEvent>
  activityFollowing?: Maybe<IActivityFollowingChangeEvent>
  location?: Maybe<ILocationChangeEvent>
  projectMaterial?: Maybe<IProjectMaterialChangeEvent>
  projectMaterialCategory?: Maybe<IProjectMaterialCategoryChangeEvent>
  uploadMaterialsProgress?: Maybe<IUploadMaterialsProgressChangeEvent>
  company?: Maybe<ICompanyChangeEvent>
  companyRelationship?: Maybe<ICompanyRelationshipChangeEvent>
  companyTypeTag?: Maybe<IEntityTagChangeEvent>
  report?: Maybe<IReportChangeEvent>
  scanner?: Maybe<IScannerChangeEvent>
  scanHistory?: Maybe<IScanHistoryChangeEvent>
  castFollowing?: Maybe<ICastFollowingChangeEvent>
  castAssignment?: Maybe<ICastAssignmentChangeEvent>
  transferProjectDataProgress?: Maybe<ITransferProjectDataProgressChangeEvent>
  concreteDirectOrder?: Maybe<IConcreteDirectOrderChangeEvent>
  concreteDirectPayload?: Maybe<IConcreteDirectPayloadChangeEvent>
}

export type ISubscriptionBasemapArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionThreadArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionContentObjectArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionMessageArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionPhotoArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionSitemapItemArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionConstraintArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionWhiteListItemArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionWeatherForecastArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionAnalyticsSettingsArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionConfigurationArgs = {
  id?: Maybe<Scalars['ObjectId']>
  name?: Maybe<Scalars['String']>
}

export type ISubscriptionClosureArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionClosureFollowingArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionClosureAssignmentArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionDeliveryArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionDeliveryStatusChangeArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionDeliveryUnitArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionDeliveryVehicleTypeArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionDeliveryFollowingArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionDeliveryAssignmentArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionRecurringDeliveriesSettingsArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionOperationRuleArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionProjectArgs = {
  id?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionProjectMaterialOptionsArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionProjectAddressArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionProjectColoringOptionsArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionProjectStatusUpdateOptionsArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionProjectTypeOptionsArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionDeliveryConfigurationsArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionActivitiesConfigurationsArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionLogisticsConfigurationsArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionFormsConfigurationsArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionDeliveryFieldsConfigurationsArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionPresentationSettingsArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionZoneMapThresholdsArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionProjectHistoryArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionMaterialConfigurationArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionPermitTypeArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionSitePermitArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionSitePermitAssignmentArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionSitePermitFollowingArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionAnnouncementArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionAnnouncementAssignmentArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionAnnouncementFollowingArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionSitePermitStatusChangeArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
  permitId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionSitePermitInspectionChangeArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionSitePermitInspectionArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionSitemapArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionSitemapSpecificItemDataArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionGlobeViewArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionGlobeViewSpecificItemDataArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionTilesetArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionFormCategoryArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionUserArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionUserProjectArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
  userId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionUserRoleArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionTeamArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionTradeArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionNotificationArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionActivityCodeRelationshipArgs = {
  id?: Maybe<Scalars['ObjectId']>
  scheduleId: Scalars['ObjectId']
}

export type ISubscriptionStatusUpdateArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionScheduleArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionUploadScheduleProgressArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId: Scalars['ObjectId']
}

export type ISubscriptionActivityArgs = {
  id?: Maybe<Scalars['ObjectId']>
  scheduleId: Scalars['ObjectId']
}

export type ISubscriptionActivityCodeTypeArgs = {
  id?: Maybe<Scalars['ObjectId']>
  scheduleId: Scalars['ObjectId']
}

export type ISubscriptionActivityCodeArgs = {
  id?: Maybe<Scalars['ObjectId']>
  scheduleId: Scalars['ObjectId']
}

export type ISubscriptionResourceArgs = {
  id?: Maybe<Scalars['ObjectId']>
  scheduleId: Scalars['ObjectId']
}

export type ISubscriptionActivityResourceRelationshipArgs = {
  id?: Maybe<Scalars['ObjectId']>
  scheduleId: Scalars['ObjectId']
}

export type ISubscriptionActivityPresetArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
  userId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionActivityLocationArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionActivityAssignmentArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionActivityFollowingArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionLocationArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionProjectMaterialArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
  materialsUploadId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionProjectMaterialCategoryArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
  materialsUploadId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionUploadMaterialsProgressArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionCompanyArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionCompanyRelationshipArgs = {
  id?: Maybe<Scalars['ObjectId']>
  scheduleId: Scalars['ObjectId']
}

export type ISubscriptionCompanyTypeTagArgs = {
  id?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionReportArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionScannerArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionScanHistoryArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionCastFollowingArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionCastAssignmentArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionTransferProjectDataProgressArgs = {
  id?: Maybe<Scalars['ObjectId']>
  operationId?: Maybe<Scalars['String']>
}

export type ISubscriptionConcreteDirectOrderArgs = {
  id?: Maybe<Scalars['ObjectId']>
  projectId?: Maybe<Scalars['ObjectId']>
}

export type ISubscriptionConcreteDirectPayloadArgs = {
  id?: Maybe<Scalars['ObjectId']>
  cdOrderId?: Maybe<Scalars['Guid']>
}

export interface ISummaryField {
  /** Name. */
  name?: Maybe<Scalars['String']>
  /** Field id. */
  fieldId?: Maybe<Scalars['ObjectId']>
}

export interface ISummaryFieldInput {
  /** Name. */
  name?: Maybe<Scalars['String']>
  /** Field id. */
  fieldId?: Maybe<Scalars['ObjectId']>
}

export interface ITablePermitField extends IPermitFieldInterface {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  tableValues?: Maybe<Array<Maybe<ITableRowPermitField>>>
}

export interface ITablePermitFieldInput {
  /** Field Id. */
  fieldId: Scalars['ObjectId']
  /** Permit field type. */
  type: PermitFieldType
  /** Values. */
  values?: Maybe<Array<Maybe<IPermitFieldsInput>>>
}

export interface ITableRowPermitField {
  /** Cells. */
  cells?: Maybe<Array<Maybe<IPermitFieldInterface>>>
}

export interface ITeam {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  color: Scalars['String']
  name: Scalars['String']
}

export interface ITeamChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ITeam>
}

export interface ITeamInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  name: Scalars['String']
  color: Scalars['String']
}

export interface ITeamList {
  data: Array<ITeam>
  page: IPageInfo
}

export interface IThread {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  activityObjectId?: Maybe<Scalars['String']>
}

export interface IThreadChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IThread>
}

export interface IThreadInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  activityObjectId?: Maybe<Scalars['String']>
}

export interface IThreadList {
  data: Array<IThread>
  page: IPageInfo
}

export enum ThresholdUnit {
  Number = 'number',
  Percent = 'percent',
}

export interface ITileset {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** MapboxTilesetId. */
  mapboxTilesetId?: Maybe<Scalars['String']>
  /** IsProcessed. */
  isProcessed: Scalars['Boolean']
}

export interface ITilesetChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ITileset>
}

export interface ITilesetInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** MapboxTilesetId. */
  mapboxTilesetId?: Maybe<Scalars['String']>
  /** IsProcessed. */
  isProcessed: Scalars['Boolean']
}

export interface ITilesetList {
  data: Array<ITileset>
  page: IPageInfo
}

export interface ITilesetsConfiguration {
  /** Max size of tileset file to upload. */
  maxSize?: Maybe<Scalars['String']>
  /** Max number of tilesets to upload. */
  maxNumber: Scalars['Int']
}

export interface ITrade {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color?: Maybe<Scalars['String']>
}

export interface ITradeChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ITrade>
}

export interface ITradeInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color?: Maybe<Scalars['String']>
}

export interface ITradeList {
  data: Array<ITrade>
  page: IPageInfo
}

export enum TransferNotificationEnum {
  Initiate = 'Initiate',
  CopyingCompanies = 'CopyingCompanies',
  CompanyFailure = 'CompanyFailure',
  CompaniesFinished = 'CompaniesFinished',
  CopyingUsers = 'CopyingUsers',
  UsersFinished = 'UsersFinished',
  UsersFailure = 'UsersFailure',
  CopyingMaterials = 'CopyingMaterials',
  MaterialsFailure = 'MaterialsFailure',
  MaterialsFinished = 'MaterialsFinished',
  CopyingForms = 'CopyingForms',
  FormsFailure = 'FormsFailure',
  FormsFinished = 'FormsFinished',
  Custom = 'Custom',
}

export interface ITransferProjectDataProgressChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<ITransferProjectProgressGraphType>
}

export interface ITransferProjectDefinitionsGraphType {
  /** Operation ID */
  operationId?: Maybe<Scalars['String']>
  /** Project Source */
  projectSource?: Maybe<Scalars['ObjectId']>
  /** Project Destination */
  projectDestination?: Maybe<Scalars['ObjectId']>
  /** list of definitions if should copy all for each APP */
  all?: Maybe<ICopyAllGraphType>
  /** ID */
  id?: Maybe<Scalars['String']>
  /** Tenant Name */
  tenantName?: Maybe<Scalars['String']>
  /** Template Name */
  name?: Maybe<Scalars['String']>
  /** User Companies */
  userCompanies?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Users */
  users?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Forms */
  forms?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Companies */
  companies?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Materials */
  materials?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Material Groups */
  materialGroups?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Overwriting Forms */
  overwritingForms?: Maybe<Scalars['Boolean']>
}

export interface ITransferProjectProgressGraphType {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** OperationId */
  operationId?: Maybe<Scalars['String']>
  /** Project Source */
  projectSource: Scalars['ObjectId']
  /** Project Destination */
  projectDestination: Scalars['ObjectId']
  /** Status. */
  status: TransferNotificationEnum
  /** Message */
  message?: Maybe<Scalars['String']>
}

export interface IUpdateActivitiesFieldsInput {
  /** Activity id. */
  id: Scalars['ObjectId']
  /** Message. */
  message?: Maybe<ISaveMessageInput>
  /** P6 code being used as Id. */
  code?: Maybe<Scalars['String']>
  /** Planned start date. */
  plannedStartDate?: Maybe<Scalars['MillisecondsDate']>
  /** Planned finish date. */
  plannedFinishDate?: Maybe<Scalars['MillisecondsDate']>
  /** Actual start date. */
  actualStartDate?: Maybe<Scalars['MillisecondsDate']>
  /** Actual finish date. */
  actualFinishDate?: Maybe<Scalars['MillisecondsDate']>
  /** Remaining early start date. */
  remainingEarlyStartDate?: Maybe<Scalars['MillisecondsDate']>
  /** Remaining early finish date. */
  remainingEarlyFinishDate?: Maybe<Scalars['MillisecondsDate']>
  /** Activity complete percent. */
  percentComplete?: Maybe<Scalars['Int']>
  /** Total float. */
  totalFloat?: Maybe<Scalars['Float']>
  /** Activity status. */
  status?: Maybe<ActivityStatus>
  /** Activity on time status. */
  onTimeStatus?: Maybe<OnTimeStatus>
  /** Work breakdown structure id. */
  workBreakdownStructureId?: Maybe<Scalars['ObjectId']>
  /** Resource id. */
  resourceId?: Maybe<Scalars['ObjectId']>
  /** Calendar id. */
  calendarId?: Maybe<Scalars['ObjectId']>
  /** Company id. */
  companyId?: Maybe<Scalars['ObjectId']>
  /** Requester id. */
  requesterId?: Maybe<Scalars['ObjectId']>
  /** Locations. */
  locations?: Maybe<Array<Maybe<ISiteLocationInput>>>
  /** Activity linking settings. */
  activityLinkingSettings?: Maybe<IActivityLinkingSettingsInput>
}

export interface IUpdateDeliveriesFieldsInput {
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Delivery ids. */
  deliveryIds: Array<Maybe<Scalars['ObjectId']>>
  /** Timezone id. */
  timezoneId: Scalars['String']
  /** Message. */
  message?: Maybe<ISaveMessageInput>
  /** Activity Id. */
  activityId?: Maybe<Scalars['String']>
  /** Company. */
  company?: Maybe<Scalars['ObjectId']>
  /** Driver phone numbers. */
  driverPhoneNumbers?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Offloading equipments. */
  offloadingEquipments?: Maybe<Array<Maybe<IDeliveryAttributeInput>>>
  /** On site contact person ids. */
  onSiteContactPersonIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Sitemap urls. */
  sitemapUrls?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Truck license plate. */
  truckLicensePlate?: Maybe<Scalars['String']>
  /** Truck number. */
  truckNumber?: Maybe<Scalars['String']>
  /** Truck size. */
  truckSize?: Maybe<Scalars['ObjectId']>
  /** Vendor. */
  vendor?: Maybe<Scalars['ObjectId']>
  /** Vendor emails. */
  vendorEmails?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Installation zone. */
  installationZone?: Maybe<Scalars['String']>
  /** Node. */
  note?: Maybe<Scalars['String']>
  /** Materials. */
  materials?: Maybe<Array<Maybe<IDeliveryMaterialInput>>>
  /** Inspector user Id. */
  inspector?: Maybe<Scalars['ObjectId']>
  /** Is inspection required. */
  isInspectionRequired?: Maybe<Scalars['Boolean']>
  /** Number of equipment picks. */
  numberOfEquipmentPicks?: Maybe<Scalars['String']>
  /** StartDate. */
  startDate?: Maybe<Scalars['MillisecondsDate']>
  /** EndDate. */
  endDate?: Maybe<Scalars['MillisecondsDate']>
  /** Delivery location to. */
  locationsTo?: Maybe<Array<Maybe<ISiteLocationInput>>>
  /** Delivery location from. */
  locationsFrom?: Maybe<Array<Maybe<ISiteLocationInput>>>
}

export interface IUpdaterInfo {
  /** Id. */
  id?: Maybe<Scalars['ObjectId']>
  /** Email. */
  email?: Maybe<Scalars['String']>
  /** First name. */
  firstName?: Maybe<Scalars['String']>
  /** Last name. */
  lastName?: Maybe<Scalars['String']>
}

export interface IUpdaterInfoInput {
  /** Id. */
  id: Scalars['ObjectId']
  /** Email. */
  email?: Maybe<Scalars['String']>
  /** First name. */
  firstName?: Maybe<Scalars['String']>
  /** Last name. */
  lastName?: Maybe<Scalars['String']>
}

export interface IUploadMaterialsExcelInput {
  /** Full path to the file. */
  fullFilePath: Scalars['String']
  /** Name of the file. */
  fileName: Scalars['String']
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** User id. */
  userId: Scalars['ObjectId']
  /** Sheet data. */
  sheetData: ISheetDataInput
  /** Flag for copying existing materials to upload or not. */
  shouldMergeWithExistingData: Scalars['Boolean']
}

export interface IUploadMaterialsProgress {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Materials upload id. */
  materialsUploadId?: Maybe<Scalars['ObjectId']>
  /** Current upload status. */
  status: UploadMaterialsStatus
  /** Failed step. */
  failedStep?: Maybe<UploadMaterialsStatus>
  /** Fail reason. */
  failReason?: Maybe<Scalars['String']>
  /** Items to process. */
  itemsCount?: Maybe<Scalars['Int']>
  /** Processed items. */
  savedItemsCount?: Maybe<Scalars['Int']>
}

export interface IUploadMaterialsProgressChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IUploadMaterialsProgress>
}

export enum UploadMaterialsStatus {
  Starting = 'STARTING',
  ReadingFile = 'READING_FILE',
  ExtractingData = 'EXTRACTING_DATA',
  RemovingOldData = 'REMOVING_OLD_DATA',
  SavingMaterials = 'SAVING_MATERIALS',
  Finishing = 'FINISHING',
  Done = 'DONE',
  Failed = 'FAILED',
}

export interface IUploadScheduleProgress {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Schedule id. */
  scheduleId: Scalars['ObjectId']
  /** Entity id specified in the schedule file. */
  xerEntityId?: Maybe<Scalars['String']>
  /** Current upload status. */
  status: UploadScheduleStatus
  /** Failed step. */
  failedStep?: Maybe<UploadScheduleStatus>
  /** Fail reason. */
  failReason?: Maybe<Scalars['String']>
  /** Items to process. */
  itemsCount?: Maybe<Scalars['Int']>
  /** Processed items. */
  savedItemsCount?: Maybe<Scalars['Int']>
  /** Previous schedule id. */
  previousScheduleId?: Maybe<Scalars['ObjectId']>
  /** Project id. */
  projectId: Scalars['ObjectId']
  uploadResult?: Maybe<IUploadScheduleResult>
}

export interface IUploadScheduleProgressChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IUploadScheduleProgress>
}

export interface IUploadScheduleResult {
  /** Previous schedule. */
  previousSchedule?: Maybe<ISchedule>
  /** Uploaded schedule. */
  schedule: ISchedule
  /** Comparing info. */
  compareData: IScheduleComparingResult
}

export enum UploadScheduleStatus {
  Starting = 'STARTING',
  ReadingFile = 'READING_FILE',
  ExtractingData = 'EXTRACTING_DATA',
  SavingActivities = 'SAVING_ACTIVITIES',
  SavingSchedule = 'SAVING_SCHEDULE',
  RecalculateStatusUpdates = 'RECALCULATE_STATUS_UPDATES',
  Done = 'DONE',
  Failed = 'FAILED',
}

export enum UploadingType {
  Avatar = 'Avatar',
  Image = 'Image',
  Pdf = 'PDF',
  Schedule = 'Schedule',
  BidPackage = 'BidPackage',
  ManPower = 'ManPower',
  Material = 'Material',
  MsWord = 'MSWord',
  MsExcel = 'MSExcel',
  MsPowerPoint = 'MSPowerPoint',
  Tileset = 'Tileset',
}

export interface IUser {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  email?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  companyAlias?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  initials?: Maybe<Scalars['String']>
  avatarUrl?: Maybe<Scalars['String']>
  setUpFinished?: Maybe<Array<Maybe<IProjectSetupFinished>>>
  addedBy?: Maybe<Scalars['String']>
  globalRole?: Maybe<Scalars['String']>
  accountPosition?: Maybe<UserAccountPosition>
  reportsToId?: Maybe<Scalars['ObjectId']>
  isHidden?: Maybe<Scalars['Boolean']>
  /** Optional. Tenant's internal EmployeeId. */
  employeeId?: Maybe<Scalars['String']>
}

export enum UserAccountPosition {
  Field = 'field',
  Management = 'management',
  Office = 'office',
}

export interface IUserChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IUser>
}

export interface IUserDataInput {
  /** Email. */
  email?: Maybe<Scalars['String']>
  /** Phone number. */
  phoneNumber?: Maybe<Scalars['String']>
  /** Company alias. */
  companyAlias?: Maybe<Scalars['String']>
}

export interface IUserList {
  data: Array<IUser>
  page: IPageInfo
}

export interface IUserNofiticationInput {
  inApp: Scalars['Boolean']
  sms: Scalars['Boolean']
  email: Scalars['Boolean']
}

export interface IUserNotification {
  inApp: Scalars['Boolean']
  sms: Scalars['Boolean']
  email: Scalars['Boolean']
}

export interface IUserProject {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** User id. */
  userId?: Maybe<Scalars['ObjectId']>
  /** Access type. */
  accessType?: Maybe<ProjectAccessType>
  /** Invite status. */
  inviteStatus?: Maybe<InviteStatus>
  /** Company id. */
  companyId?: Maybe<Scalars['ObjectId']>
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** Roles. */
  roles?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Teams. */
  teams?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Trades. */
  trades?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Default teams */
  defaultTeams?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Activities date picker mode. */
  activitiesDatePickerMode?: Maybe<Scalars['String']>
  /** Associated Codes */
  associatedCodes?: Maybe<Array<Maybe<IAssociatedCode>>>
  /** Delivery notifications. */
  deliveryNotifications?: Maybe<IUserNotification>
  /** Saved view settings. */
  savedViewsSettings?: Maybe<Array<Maybe<ISavedViewSettings>>>
  /** Forms notifications. */
  formsNotifications?: Maybe<IUserNotification>
  /** Sensor notifications. */
  scannerNotifications?: Maybe<IUserNotification>
  /** Activity notifications. */
  activityNotifications?: Maybe<IUserNotification>
  /** Announcement notifications. */
  announcementNotifications?: Maybe<IUserNotification>
  /** Permit notifications. */
  permitNotifications?: Maybe<IUserNotification>
  /** Deliveries settings. */
  deliveriesSettings?: Maybe<IDeliveryListSettings>
  /** Sitemaps setup settings. */
  sitemapsSetupSettings?: Maybe<ISitemapsSetupSettings>
  /** Globes setup settings. */
  globesSetupSettings?: Maybe<IGlobesSetupSettings>
  /** Responsibilities. */
  responsibilities?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Last mobile activity filters. */
  lastMobileActivityFilters?: Maybe<Array<Maybe<ILastMobileActivityFilter>>>
}

export interface IUserProjectChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IUserProject>
}

export interface IUserProjectInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** User id. */
  userId?: Maybe<Scalars['ObjectId']>
  /** Access type. */
  accessType?: Maybe<ProjectAccessType>
  /** Invite status. */
  inviteStatus?: Maybe<InviteStatus>
  /** Company id. */
  companyId?: Maybe<Scalars['ObjectId']>
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** Roles. */
  roles?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Teams. */
  teams?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Trades. */
  trades?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Default teams */
  defaultTeams?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Activities date picker mode. */
  activitiesDatePickerMode?: Maybe<Scalars['String']>
  /** Associated Codes */
  associatedCodes?: Maybe<Array<Maybe<IAssociatedCodeInput>>>
  /** Delivery notifications. */
  deliveryNotifications?: Maybe<IUserNofiticationInput>
  /** Saved veiw settings. */
  savedViewsSettings?: Maybe<Array<Maybe<ISavedViewSettingsInput>>>
  /** Forms notifications. */
  formsNotifications?: Maybe<IUserNofiticationInput>
  /** Sensor notifications. */
  scannerNotifications?: Maybe<IUserNofiticationInput>
  /** Activity notifications. */
  activityNotifications?: Maybe<IUserNofiticationInput>
  /** Announcement notifications. */
  announcementNotifications?: Maybe<IUserNofiticationInput>
  /** Permit notifications. */
  permitNotifications?: Maybe<IUserNofiticationInput>
  /** Deliveries settings. */
  deliveriesSettings?: Maybe<IDeliveryListSettingsInput>
  /** Sitemaps setup settings. */
  sitemapsSetupSettings?: Maybe<ISitemapsSetupSettingsInput>
  /** Globes setup settings. */
  globesSetupSettings?: Maybe<IGlobesSetupSettingsInput>
  /** Responsibilities. */
  responsibilities?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Last mobile activity filters. */
  lastMobileActivityFilters?: Maybe<
    Array<Maybe<ILastMobileActivityFilterInput>>
  >
}

export interface IUserProjectList {
  data: Array<IUserProject>
  page: IPageInfo
}

export interface IUserRole {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  color: Scalars['String']
  name: Scalars['String']
}

export interface IUserRoleChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IUserRole>
}

export interface IUserRoleInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  name: Scalars['String']
  color: Scalars['String']
}

export interface IUserRoleList {
  data: Array<IUserRole>
  page: IPageInfo
}

export interface IVerticalObject extends ILocation {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateInterval>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Delivery hierarchy parent. */
  parent?: Maybe<IDeliveryHierarchyParent>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<LocationType>
  closingDate?: Maybe<Scalars['Long']>
  /** Accessible levels. */
  accessibleLevels?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Levels closures. */
  levelsClosures?: Maybe<Array<Maybe<ILevelsClosure>>>
}

export interface IVerticalObjectInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateIntervalInput>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Location parent. */
  parent?: Maybe<IDeliveryHierarchyParentInput>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Accessible levels. */
  accessibleLevels?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Levels closures. */
  levelsClosures?: Maybe<Array<Maybe<ILevelsClosureInput>>>
}

export interface IViewTypes {
  /** Mobile configuration. */
  mobile?: Maybe<HierarchyModes>
  /** Tablet configuration. */
  tablet?: Maybe<HierarchyModes>
  /** Desktop sitemap configuration. */
  webSitemap?: Maybe<HierarchyModes>
  /** Desktop gantt configuration. */
  webGantt?: Maybe<HierarchyModes>
}

export interface IViewTypesInput {
  /** Mobile configuration. */
  mobile?: Maybe<HierarchyModes>
  /** Tablet configuration. */
  tablet?: Maybe<HierarchyModes>
  /** Desktop sitemap configuration. */
  webSitemap?: Maybe<HierarchyModes>
  /** Desktop gantt configuration. */
  webGantt?: Maybe<HierarchyModes>
}

export interface IWeatherForecast {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Forecast date. */
  forecastDate: Scalars['MillisecondsDate']
  /** Open weather forecast object. */
  openWeatherForecast: IOpenWeatherForecast
}

export interface IWeatherForecastChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IWeatherForecast>
}

export interface IWeatherForecastRangeInput {
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Timezone Id. */
  timezoneId: Scalars['String']
  /** Start date. */
  startDate: Scalars['MillisecondsDate']
  /** End date. */
  endDate: Scalars['MillisecondsDate']
}

export interface IWhiteListItem {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Company Id. */
  companyId?: Maybe<Scalars['ObjectId']>
  /** Domain name. */
  domainName?: Maybe<Scalars['String']>
}

export interface IWhiteListItemChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IWhiteListItem>
}

export interface IWhiteListItemInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Company Id. */
  companyId?: Maybe<Scalars['ObjectId']>
  /** Domain name. */
  domainName?: Maybe<Scalars['String']>
}

export interface IWhiteListItemList {
  data: Array<IWhiteListItem>
  page: IPageInfo
}

export interface IWorkBreakdownStructure {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Schedule id. */
  scheduleId: Scalars['ObjectId']
  /** Entity id specified in the schedule file. */
  xerEntityId?: Maybe<Scalars['String']>
  /** Schedule entity name. */
  name: Scalars['String']
  /** Parent wbs id. */
  parentId?: Maybe<Scalars['ObjectId']>
}

export interface IWorkBreakdownStructureList {
  data: Array<IWorkBreakdownStructure>
  page: IPageInfo
}

export interface IWorkflowStep {
  /** Id. */
  id?: Maybe<Scalars['ObjectId']>
  /** Workflow step type. */
  type: WorkflowStepType
  /** Fields. */
  fields: Array<Maybe<IPermitTypeField>>
  /** Conditional fields. */
  conditionalFields: Array<Maybe<IConditionalField>>
  /** Workflow rule Ids. */
  workflowRuleIds: Array<Maybe<Scalars['ObjectId']>>
  /** Notification field Ids. */
  notificationFieldIds: Array<Maybe<Scalars['ObjectId']>>
}

export interface IWorkflowStepInput {
  /** Id. */
  id?: Maybe<Scalars['ObjectId']>
  /** Workflow step type. */
  type: WorkflowStepType
  /** Fields. */
  fields: Array<Maybe<IPermitTypeFieldsInput>>
  /** Conditional fields. */
  conditionalFields: Array<Maybe<IConditionalFieldInput>>
  /** Workflow rule Ids. */
  workflowRuleIds: Array<Maybe<Scalars['ObjectId']>>
  /** Notification field Ids. */
  notificationFieldIds: Array<Maybe<Scalars['ObjectId']>>
}

export enum WorkflowStepType {
  Submission = 'Submission',
  Request = 'Request',
  Review = 'Review',
  Approval = 'Approval',
  Start = 'Start',
  OnSite = 'OnSite',
  ReadyToInspect = 'ReadyToInspect',
  Inspection = 'Inspection',
  RecurringInspection = 'RecurringInspection',
  Finish = 'Finish',
}

export interface IWorkflowsCustomFilter {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** User Id. */
  userId?: Maybe<Scalars['ObjectId']>
  /** Is public. */
  isPublic: Scalars['Boolean']
  /** Filters by filter type. */
  filtersByFilterType?: Maybe<Array<Maybe<IWorkflowsFilter>>>
  /** Default for users. */
  defaultForUsers?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Group By. */
  groupBy?: Maybe<Scalars['String']>
}

export interface IWorkflowsCustomFilterInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** User Id. */
  userId?: Maybe<Scalars['ObjectId']>
  /** Is public. */
  isPublic: Scalars['Boolean']
  /** Filters by filter type. */
  filtersByFilterType?: Maybe<Array<Maybe<IWorkflowsFilterInput>>>
  /** Default for users. */
  defaultForUsers?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Group By. */
  groupBy?: Maybe<Scalars['String']>
}

export interface IWorkflowsCustomFilterList {
  data: Array<IWorkflowsCustomFilter>
  page: IPageInfo
}

export interface IWorkflowsFilter {
  /** Type. */
  type?: Maybe<WorkflowsFilterType>
  /** Values. */
  values?: Maybe<Array<Maybe<Scalars['String']>>>
}

export interface IWorkflowsFilterInput {
  /** Type. */
  type?: Maybe<WorkflowsFilterType>
  /** Values. */
  values?: Maybe<Array<Maybe<Scalars['String']>>>
}

export enum WorkflowsFilterType {
  Status = 'status',
  Location = 'location',
  Equipment = 'equipment',
  Company = 'company',
  ResponsibleContact = 'responsibleContact',
  Type = 'type',
}

export interface IWorkingHours {
  /** Start time. */
  startTime: Scalars['Long']
  /** End time. */
  endTime: Scalars['Long']
}

export interface IWorkingHoursInput {
  /** Start time. */
  startTime: Scalars['Long']
  /** End time. */
  endTime: Scalars['Long']
}

export interface IXerProjectInfo {
  /** Id. */
  id: Scalars['String']
  /** Name. */
  name: Scalars['String']
  /** Activities count. */
  activitiesCount: Scalars['Int']
}

export interface IZone extends ILocation {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Is deleted. */
  isDeleted: Scalars['Boolean']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateInterval>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Delivery hierarchy parent. */
  parent?: Maybe<IDeliveryHierarchyParent>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
  /** Type. */
  type?: Maybe<LocationType>
  closingDate?: Maybe<Scalars['Long']>
}

export interface IZoneInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** The name of the record. */
  name: Scalars['String']
  /** Color. */
  color: Scalars['String']
  /** Icon name. */
  iconName: Scalars['String']
  /** Operating intervals. */
  operatingIntervals?: Maybe<Array<Maybe<IDateIntervalInput>>>
  /** Assigned sitemaps. */
  assignedSitemaps?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Assigned globes. */
  assignedGlobes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Location parent. */
  parent?: Maybe<IDeliveryHierarchyParentInput>
  /** Permitted companies. */
  permittedCompanies?: Maybe<Array<Maybe<Scalars['ObjectId']>>>
  /** Maximum overlapping deliveries. */
  maxOverlappingDeliveries?: Maybe<Scalars['Int']>
  /** Maximum booking duration. */
  maxBookingDuration?: Maybe<Scalars['Float']>
  /** Code. */
  code?: Maybe<Scalars['String']>
}

export interface IZoneMapThresholds {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPair>>>
  /** Project id. */
  projectId: Scalars['ObjectId']
  /** Delta days unit. */
  deltaDaysUnit: ThresholdUnit
  /** Delta days value. */
  deltaDaysValue: Scalars['Int']
  /** Delta days behind value. */
  deltaDaysBehindValue: Scalars['Int']
  /** Manpower unit. */
  manpowerUnit: ThresholdUnit
  /** Manpower value. */
  manpowerValue: Scalars['Int']
  /** Is manpower disabled. */
  isManpowerDisabled: Scalars['Boolean']
}

export interface IZoneMapThresholdsChangeEvent {
  id?: Maybe<Scalars['ObjectId']>
  item?: Maybe<IZoneMapThresholds>
}

export interface IZoneMapThresholdsInput {
  /** The id of the record. */
  id?: Maybe<Scalars['ObjectId']>
  /** The time the record was created. */
  createdAt?: Maybe<Scalars['MillisecondsDate']>
  /** The last time the record was changed. Ignored on save. */
  updatedAt?: Maybe<Scalars['MillisecondsDate']>
  /** The version of the record. */
  version?: Maybe<Scalars['Int']>
  /** Metadata. */
  metadata?: Maybe<Array<Maybe<IStringPairInput>>>
  /** Project Id. */
  projectId: Scalars['ObjectId']
  /** Delta days unit. */
  deltaDaysUnit: ThresholdUnit
  /** Delta days value. */
  deltaDaysValue: Scalars['Int']
  /** Delta days behind value. */
  deltaDaysBehindValue: Scalars['Int']
  /** Manpower unit. */
  manpowerUnit: ThresholdUnit
  /** Manpower value. */
  manpowerValue: Scalars['Int']
  /** Is manpower disabled. */
  isManpowerDisabled: Scalars['Boolean']
}

export interface IZoneMapThresholdsList {
  data: Array<IZoneMapThresholds>
  page: IPageInfo
}

import React from 'react'

import { Spinner } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'

import AnnouncementsStore from '~/client/src/shared/stores/domain/Announcements.store'
import ClosuresStore from '~/client/src/shared/stores/domain/Closures.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import FormCategoriesStore from '~/client/src/shared/stores/domain/FormCategories.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'

import Logistics from './Logistics'

interface IProps {
  isPermitOnly?: boolean

  sitemapsStore?: SitemapsStore
  announcementsStore?: AnnouncementsStore
  sitePermitsStore?: SitePermitsStore
  permitTypesStore?: PermitTypesStore
  companiesStore?: CompaniesStore
  closuresStore?: ClosuresStore
  formCategoriesStore?: FormCategoriesStore
}

@inject(
  'sitemapsStore',
  'announcementsStore',
  'sitePermitsStore',
  'permitTypesStore',
  'companiesStore',
  'closuresStore',
  'formCategoriesStore',
)
@observer
export default class LogisticsContainer extends React.Component<IProps> {
  public render(): React.ReactNode {
    if (this.isLoading) {
      return (
        <div className="full-height row x-center y-center">
          <Spinner size={50} className="loader" />
        </div>
      )
    }

    return <Logistics isPermitOnly={this.props.isPermitOnly} />
  }

  private get isLoading() {
    const {
      isPermitOnly,
      sitemapsStore,
      announcementsStore,
      sitePermitsStore,
      permitTypesStore,
      companiesStore,
      closuresStore,
      formCategoriesStore,
    } = this.props

    const isBaseLoading = [
      sitemapsStore,
      sitePermitsStore,
      permitTypesStore,
      companiesStore,
      closuresStore,
      formCategoriesStore,
    ].some(store => !store.isDataReceived)

    return (
      isBaseLoading || (!isPermitOnly && !announcementsStore.isDataReceived)
    )
  }
}

import * as React from 'react'

import { observer } from 'mobx-react'
import { NOOP } from 'mobx-utils'
import { classList } from 'react-classlist-helper'

import Activity from '../../models/Activity'
import ActivityItemContentWithStatusUpdate from '../ActivityItemContentWithStatusUpdate/ActivityItemContentWithStatusUpdate'
import MapViewItemBase from '../SitemapHelpers/models/MapViewItemBase'
import GeneralMapPopup from './GeneralMapPopup'

interface IProps {
  item: MapViewItemBase
  selectedActivities: Activity[]
  selectedActivityId: string

  openActivity(code: string): void
  onClose(): void

  currentViewDate?: Date
}

const noActiveActivities = 'No active activities'
@observer
export default class ActivityMapPopup extends React.Component<IProps> {
  public render() {
    const {
      selectedActivities,
      selectedActivityId,
      currentViewDate,
      item,
      onClose,
    } = this.props

    return (
      <GeneralMapPopup item={item} onClose={onClose} className="brada4">
        <div className="relative overflow-auto draggable-elements-list">
          {selectedActivities?.length ? (
            selectedActivities.map(activity => (
              <div
                key={activity.code}
                onClick={this.openActivity.bind(this, activity)}
                className={classList({
                  'bt-palette-grey activity-item': true,
                  'selected-card': activity.id === selectedActivityId,
                })}
              >
                <ActivityItemContentWithStatusUpdate
                  activity={activity}
                  currentViewDate={currentViewDate}
                  displayStatusUpdateModal={NOOP}
                  displayActivityDetails={this.openActivity.bind(
                    this,
                    activity,
                  )}
                  activitiesTreeStore={null}
                />
              </div>
            ))
          ) : (
            <div className="text grey pa10">{noActiveActivities}</div>
          )}
        </div>
      </GeneralMapPopup>
    )
  }

  private openActivity(activity: Activity) {
    this.props.openActivity(activity.code)
  }
}

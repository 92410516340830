enum KnownTranslatorKeys {
  name = 'name',
  tag = 'tag',
  avatar = 'avatar',
  route = 'route',
  offloadingEquipment = 'offloadingEquipment',
  equipment = 'equipment',
  logisticObject = 'logisticObject',
  verticalObject = 'verticalObject',
  interiorPath = 'interiorPath',
  interiorDoor = 'interiorDoor',
  staging = 'staging',
  area = 'area',
  level = 'level',
  gate = 'gate',
  zone = 'zone',
  storageArea = 'storageArea',
  company = 'company',
  manPower = 'manPower',
  manpower = 'manpower',
  code = 'code',
  description = 'description',
  plannedValue = 'plannedValue',
  contract = 'contract',
  bidStatus = 'bidStatus',
  dates = 'dates',
  confirmDeletionOfTheFollowingXAnnouncements = 'confirmDeletionOfTheFollowingXAnnouncements',
  delete = 'delete',
  deactivate = 'deactivate',
  activate = 'activate',
  confirmDeletion = 'confirmDeletion',
  confirmDeactivation = 'confirmDeactivation',
  confirmActivation = 'confirmActivation',
  confirmDeactivationOfTheFollowingXCompanies = 'confirmDeactivationOfTheFollowingXCompanies',
  confirmActivationOfTheFollowingXCompanies = 'confirmActivationOfTheFollowingXCompanies',
  email_noun = 'email_noun',
  phoneNumber = 'phoneNumber',
  confirmDeletionOfTheFollowingXProjectMembers = 'confirmDeletionOfTheFollowingXProjectMembers',
  confirmInvitationOfTheFollowingXProjectMembers = 'confirmInvitationOfTheFollowingXProjectMembers',
  invite = 'invite',
  confirmInvitation = 'confirmInvitation',
  confirmSettingAsNotInvitedOfTheFollowingXProjectMembers = 'confirmSettingAsNotInvitedOfTheFollowingXProjectMembers',
  confirmApprovalOfTheFollowingXProjectMembers = 'confirmApprovalOfTheFollowingXProjectMembers',
  approve = 'approve_verb',
  confirmApproval = 'confirmApproval',
  setNotInvited = 'setNotInvited',
  confirmSettingUsersAsNotInvited = 'confirmSettingUsersAsNotInvited',
  plannedCompanyManpower = 'plannedCompanyManpower',
  responsibilityCode = 'responsibilityCode',
  responsibilityDescription = 'responsibilityDescription',
  contractN = 'contractN',
  plannedValueS = 'plannedValueS',
  addCustomProjectClosures = 'addCustomProjectClosures',
  addCompany = 'addCompany',
  editCompany = 'editCompany',
  userProfileDescriptions_name = 'userProfileDescriptions.name',
  projectRoles = 'projectRoles',
  userProfileDescriptions_projectRoles = 'userProfileDescriptions.projectRoles',
  phone = 'phone',
  currentPassword = 'currentPassword',
  newPassword = 'newPassword',
  confirmNewPassword = 'confirmNewPassword',
  role = 'role',
  status = 'status',
  inviteStatus = 'inviteStatus',
  monitoringStatus = 'monitoringStatus',
  castStatus = 'castStatus',
  accountType = 'accountType',
  projectTrades = 'projectTrades',
  teams = 'teams',
  accountPosition = 'accountPosition',
  editUsers = 'editUsers',
  gantt = 'gantt',
  schedule = 'schedule',
  home = 'home',
  presentationModeDescriptions_gantt = 'presentationModeDescriptions.gantt',
  deliveries = 'deliveries',
  presentationModeDescriptions_deliveries = 'presentationModeDescriptions.deliveries',
  logistics = 'logistics',
  forms = 'forms',
  presentationModeDescriptions_logistics = 'presentationModeDescriptions.logistics',
  presentationModeDescriptions_logisticsEntity = 'presentationModeDescriptions.logisticsEntity',
  viewName = 'viewName',
  basemap = 'basemap',
  numObjects = 'numObjects',
  publishTo = 'publishTo',
  geoReferencing = 'geoReferencing',
  companyName = 'companyName',
  logo = 'logo',
  companyType = 'companyType',
  responsibleUser = 'responsibleUser',
  type = 'type',
  members = 'members',
  addUser = 'addUser',
  options = 'options',
  selectActivityGroups = 'selectActivityGroups',
  numberOfBands = 'numberOfBands',
  none = 'none',
  workBreakdownStructureWbs = 'workBreakdownStructureWbs',
  locationBreakdownStructureLbs = 'locationBreakdownStructureLbs',
  companies = 'companies',
  lbs = 'lbs',
  date = 'date',
  seeXActivities = 'seeXActivities',
  seeXDeliveries = 'seeXDeliveries',
  seeXUsers = 'seeXUsers',
  seeXObjects = 'seeXObjects',
  seeXSitemaps = 'seeXSitemaps',
  seeXForms = 'seeXForms',
  xForms = 'xForms',
  seeXClosures = 'seeXClosures',
  seeXLogistics = 'seeXLogistics',
  seeXReports = 'seeXReports',
  seeXSensors = 'seeXSensors',
  seeXMaterials = 'seeXMaterials',
  xActivities = 'xActivities',
  xDeliveries = 'xDeliveries',
  xWorkflows = 'xWorkflows',
  dateRangeForRecentlyUpdatedActivities = 'dateRangeForRecentlyUpdatedActivities',
  whitelistedDomains = 'whitelistedDomains',
  device = 'device',
  cast = 'cast',
  lastReading = 'lastReading',
  firstReading = 'firstReading',
  app = 'app',
  location = 'location',
  scannerName = 'scannerName',
  building = 'building',
  truckSize = 'truckSize',
  material = 'material',
  vendor = 'vendor',
  duration = 'duration',
  inspection = 'inspection',
  cancelationReason = 'cancelationReason',
  all = 'all',
  day = 'day',
  week = 'week',
  month = 'month',
  materialsCategory = 'materialsCategory',
  materialDescription = 'materialDescription',
  plannedInstallLocation = 'plannedInstallLocation',
  plannedDeliveryLocation = 'plannedDeliveryLocation',
  currentLocation = 'currentLocation',
  employeeIDNumber = 'employeeIDNumber',
  category = 'category',
  isActive = 'isActive',
  createdBy = 'createdBy',
}

export default KnownTranslatorKeys

import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'

import IFilePreviewProperties from '~/client/src/shared/interfaces/IFilePreviewProperties'
import { ICalendarDates } from '~/client/src/shared/models/CalendarEvent'
import SitePermit from '~/client/src/shared/models/Permit'

import FileInputBase from '../FileInput/FileInput'
import SitePermitCreationForm from './SitePermitCreationForm'
import SitePermitViewForm from './SitePermitViewForm'

interface IProps {
  workflowToShow: SitePermit
  isMobileMode?: boolean
  calendarEventDates?: ICalendarDates
  isDateMoveConfirmModalOpen?: boolean
  FileInputType: typeof FileInputBase

  onClose(): void
  openFilePreview?: (
    files: IFilePreviewProperties[],
    currentIndex: number,
  ) => void
  hideDateMoveConfirmModal?: () => void
  openWorkflow?: (workflow: SitePermit) => void
}

@observer
export default class WorkflowViewFormWrapper extends React.Component<IProps> {
  @observable private workflowToDuplicate: SitePermit

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { workflowToShow } = this.props

    if (workflowToShow !== prevProps.workflowToShow) {
      this.unsetDuplicateWorkflow()
    }
  }

  public render() {
    const {
      workflowToShow,
      isMobileMode,
      calendarEventDates,
      isDateMoveConfirmModalOpen,
      FileInputType,
      openFilePreview,
      hideDateMoveConfirmModal,
      onClose,
    } = this.props

    if (this.workflowToDuplicate) {
      return (
        <SitePermitCreationForm
          workflowToDuplicate={this.workflowToDuplicate}
          isTypeReadOnly
          isMobileMode={isMobileMode}
          FileInputType={FileInputType}
          close={onClose}
        />
      )
    }

    return (
      <SitePermitViewForm
        permitToShow={workflowToShow}
        isMobileMode={isMobileMode}
        calendarEventDates={calendarEventDates}
        isDateMoveConfirmModalOpen={isDateMoveConfirmModalOpen}
        FileInputType={FileInputType}
        close={onClose}
        openFilePreview={openFilePreview}
        hideDateMoveConfirmModal={hideDateMoveConfirmModal}
        onDuplicateClick={this.setDuplicateWorkflow}
        openWorkflow={this.props.openWorkflow}
      />
    )
  }

  @action.bound
  private setDuplicateWorkflow(workflow: SitePermit) {
    this.workflowToDuplicate = workflow
  }

  @action.bound
  private unsetDuplicateWorkflow() {
    this.workflowToDuplicate = null
  }
}

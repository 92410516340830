export function enumToList<T>(enumeration: { [key: string]: T }): T[] {
  // https://stackoverflow.com/a/65285392/2599881
  return Object.keys(enumeration)
    .filter(key => isNaN(Number(key)))
    .map(key => enumeration[key])
}

export function listToMap<T>(
  list: T[],
  getKey: (item?: T) => string = (i: T) => i.toString(),
  getValue: (item?: T) => any = () => true,
) {
  return list.reduce((map, item) => {
    map[getKey(item)] = getValue(item)
    return map
  }, {})
}

export function objectToMap(obj: any) {
  return new Map(Object.entries(obj))
}

export function mapGroupedToArray<T, K>(map: Map<T, K[]>): K[] {
  const newArray: K[] = []

  map.forEach(values => {
    newArray.push(...values)
  })

  return newArray
}

export function mapToList<T, K>(
  map: { [key: string]: T },
  include: (key: string, value: T) => boolean = () => true,
  getValue: (key: string, value: T) => K = (key: string) => key as any,
) {
  return Object.entries(map).reduce((res, [key, value]) => {
    if (include(key, value)) {
      res.push(getValue(key, value))
    }
    return res
  }, [])
}

export function convertToBytes(str: string) {
  const units = {
    B: 1,
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
    TB: 1024 * 1024 * 1024 * 1024,
  }

  const match = str.match(/^(\d+)([A-Za-z]+)$/)
  if (!match) {
    throw new Error('Invalid format')
  }

  const value = parseInt(match[1], 10)
  const unit = match[2].toUpperCase()

  if (!units[unit]) {
    throw new Error('Unknown unit')
  }

  return value * units[unit]
}

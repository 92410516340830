import * as React from 'react'

import { observer } from 'mobx-react'

import UsernameFromUid from '~/client/src/shared/components/UsernameFromUid'

import BaseNotification from '../../models/Notification'

interface INotificationAuthor {
  notification: BaseNotification
  showCompany?: boolean
}

@observer
export default class NotificationAuthor extends React.Component<INotificationAuthor> {
  public render() {
    const { notification, showCompany } = this.props

    return (
      <UsernameFromUid
        userId={notification.authorId}
        showCompany={showCompany}
      />
    )
  }
}

import { InviteStatus } from '~/client/graph'

/**
 * Expand the InviteStatus enum from backend schema with custom statuses.
 * Can be retired if the backend schema is updated with the custom statuses.
 */
enum CustomInviteStatus {
  Requested = 'Requested',
}

export type ExpandedInviteStatus = InviteStatus | CustomInviteStatus
export const ExpandedInviteStatus = { ...InviteStatus, ...CustomInviteStatus }

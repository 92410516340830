import * as React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import MenuCloser from '~/client/src/shared/components/MenuCloser'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import Language from '../../localization/Language'

import './LanguageSelector.scss'

interface IProps {
  className?: string
  textClassName?: string
  textSize?: string
}

@observer
export default class LanguageSelector extends React.Component<IProps> {
  @observable private shouldShowLanguages: boolean = false

  public render() {
    const { className = '', textSize = 'large', textClassName } = this.props

    return (
      <div
        className={classList({
          'relative pointer text': true,
          [textSize]: true,
          [className]: true,
        })}
      >
        <span
          onClick={this.clickOnLanguage}
          className={classList({
            'row no-flex-children selected-language': true,
            'unclickable-element': this.shouldShowLanguages,
            [textClassName]: !!textClassName,
          })}
        >
          {Localization.currentLanguage}
          <img
            src="/static/icons/language-icon.svg"
            className="language-icon ml10"
          />
        </span>
        {this.shouldShowLanguages && this.renderLanguagesDropDown()}
      </div>
    )
  }

  private renderLanguagesDropDown() {
    return (
      <MenuCloser closeMenu={this.clickOnLanguage}>
        <div className="languages ba-light-input-border">
          {Localization.getLanguageOptions().map(name => {
            return (
              <div
                key={name}
                className="languages-item left large text pointer"
                onClick={this.changeLanguage.bind(null, name)}
              >
                {name}
              </div>
            )
          })}
        </div>
      </MenuCloser>
    )
  }

  @action.bound
  private clickOnLanguage() {
    this.shouldShowLanguages = !this.shouldShowLanguages
  }

  @action.bound
  private changeLanguage(languageToSelect: Language) {
    Localization.setLanguage(languageToSelect)
    this.shouldShowLanguages = false
  }
}

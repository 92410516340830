import { enumToList } from '~/client/src/shared/utils/converters'

import Localization from '../localization/LocalizationManager'

export enum FormTypeCreatedByEnum {
  DEFAULT = 'Default',
  PROJECT = 'Project',
}

export const getFormTypeCreatedByCaption = (type: FormTypeCreatedByEnum) => {
  switch (type) {
    case FormTypeCreatedByEnum.DEFAULT:
      return Localization.translator.default
    case FormTypeCreatedByEnum.PROJECT:
      return Localization.translator.project
    default:
      throw new Error(type + ' is unhandled')
  }
}

export const formTypeCreatedByList = enumToList(FormTypeCreatedByEnum)

import { action, computed, observable } from 'mobx'

import {
  IGlobeView,
  IGlobeViewSpecificItemData,
  IGlobeViewStyleInput,
} from '~/client/graph'
import Guard from '~/client/src/shared/utils/Guard'

import GlobeView from '../../models/GlobeView'
import EventsStore from '../EventStore/Events.store'
import * as e from '../EventStore/eventConstants'

export default class GlobeViewsStore {
  @observable public isDataReceived = false

  public constructor(private readonly eventsStore: EventsStore) {
    Guard.requireAll({
      eventsStore,
    })
  }

  @computed
  public get list(): GlobeView[] {
    return Array.from(this.byId.values())
  }

  public get appState() {
    return this.eventsStore.appState
  }

  public get byId() {
    const { globeViews } = this.eventsStore.appState
    return globeViews
  }

  @action.bound
  public clearList() {
    this.isDataReceived = false
    this.byId.clear()
  }

  @action.bound
  public receiveList(list: IGlobeView[]) {
    this.clearList()

    list.forEach(dto => {
      const globeView = GlobeView.fromDto(dto)
      this.byId.set(globeView.id, globeView)
    })

    this.isDataReceived = true
  }

  @action.bound
  public receiveOne(id: string, dto: IGlobeView) {
    if (dto) {
      const existingGlobeView = this.byId.get(dto.id)
      dto.items = existingGlobeView?.items
      this.byId.set(dto.id, GlobeView.fromDto(dto))
    } else {
      this.byId.delete(id)
    }
  }

  @action.bound
  public receiveOneItem(id: string, dto: IGlobeViewSpecificItemData) {
    if (dto) {
      const globeView = this.byId.get(dto.globeViewId)
      globeView.setItemDisplayData(dto.sitemapItemId, dto)
      this.byId.set(dto.globeViewId, globeView)
    } else {
      const globeView = this.getGlobeViewByItemId(id)
      if (globeView) {
        globeView.removeItemDisplayData(id)
        this.byId.set(globeView.id, globeView)
      }
    }
  }

  private getGlobeViewByItemId(id: string) {
    const globeViews = Array.from(this.byId, ([, value]) => value)
    return globeViews.find(g => g.items.some(i => i.id === id))
  }

  @action.bound
  public deleteGlobeViewItem(
    itemId: string,
    callbackFn?: (id: string) => void,
    shouldApplyChangesBeforeSave?: boolean,
    avoidSaving?: boolean,
  ) {
    if (!avoidSaving) {
      this.eventsStore.dispatch(
        e.DELETE_GLOBE_VIEW_ITEM_DATA,
        itemId,
        callbackFn,
      )
    }
  }

  @action.bound
  public saveGlobeViewItems(
    items: IGlobeViewSpecificItemData[],
    callbackFn?: (id: string) => void,
    shouldApplyChangesBeforeSave?: boolean,
    avoidSaving?: boolean,
  ) {
    const { getPolylineItemDtos, getRectangleItemDtos, getCircleItemDtos } =
      GlobeView

    if (shouldApplyChangesBeforeSave) {
      const globe = this.byId.get(items[0].globeViewId)
      items.forEach(i => globe.setItemDisplayData(i.sitemapItemId, i))
      this.byId.set(globe.id, globe.getFullCopy())
    }

    if (!avoidSaving) {
      this.eventsStore.dispatch(
        e.SAVE_GLOBE_VIEW_ITEM_DATA,
        getPolylineItemDtos(items),
        getRectangleItemDtos(items),
        getCircleItemDtos(items),
        callbackFn,
      )
    }
  }

  @action.bound
  public async saveGlobeViewStyle(
    style: IGlobeViewStyleInput,
    globeViewId: string,
    callbackFn?: (id: string) => void,
  ) {
    this.eventsStore.dispatch(
      e.SAVE_GLOBE_VIEW_STYLE,
      style,
      globeViewId,
      callbackFn,
    )
  }

  @action.bound
  public save(
    globeView: GlobeView,
    callbackFn?: (id: string) => void,
    shouldApplyChangesBeforeSave?: boolean,
    avoidSaving?: boolean,
  ) {
    const { id: projectId } = this.eventsStore.appState.activeProject

    const globeViewInput: IGlobeView = {
      id: globeView.id,
      name: globeView.name,
      projectId: globeView.projectId || projectId,
      bounds: globeView.bounds,
      zoom: globeView.zoom,
      pitch: globeView.pitch,
      center: globeView.center,
      bearing: globeView.bearing,
      geoCorners: globeView.geoCorners,
      altitude: globeView.altitude,
      sitemaps: globeView.sitemaps,
      tilesets: globeView.tilesets,
      isOrientationLocked: globeView.isOrientationLocked,
      isProjectOverviewGlobe: globeView.isProjectOverviewGlobe,
      filledImage: globeView.filledImage,
      style: globeView.style,
    }

    if (shouldApplyChangesBeforeSave && globeView.id) {
      this.byId.set(globeView.id, globeView.getFullCopy())
    }

    if (!avoidSaving) {
      this.eventsStore.dispatch(e.SAVE_GLOBE_VIEW, globeViewInput, callbackFn)
    }
  }

  @action.bound
  public removeOne(globeViewId: string, callbackFn?: () => void) {
    if (!this.byId.has(globeViewId)) {
      return
    }

    this.byId.delete(globeViewId)

    this.eventsStore.dispatch(e.DELETE_GLOBE_VIEW, globeViewId, callbackFn)
  }
}

import * as React from 'react'

import mobileRoutes from '~/client/src/mobile/constants/mobileRoutes'
import * as Icons from '~/client/src/shared/components/Icons'

import './SectionIcon.scss'

interface IProps {
  route: string
}

export default class SectionIcon extends React.Component<IProps> {
  public render() {
    return <div className="section-icon-container">{this.renderIcon()}</div>
  }

  private renderIcon() {
    switch (this.props.route) {
      case mobileRoutes.HOME:
        return <Icons.Home className="row" />
      case mobileRoutes.ACTIVITIES:
        return <Icons.Activities className="row" />
      case mobileRoutes.DELIVERIES:
        return <Icons.Delivery className="row" />
      case mobileRoutes.USERS_DIRECTORY:
        return <Icons.UsersDirectory className="row dark" />
      case mobileRoutes.USER_PROFILE:
        return <Icons.Profile className="row" />
      case mobileRoutes.NOTIFICATIONS:
        return <Icons.Notifications className="row" />
      case mobileRoutes.FORMS:
        return <Icons.GeneralForm className="row" />
      case mobileRoutes.DOCUMENTS:
        return <Icons.Documents className="row" />
      case mobileRoutes.PROJECTS:
        return <Icons.Projects className="row" />
      case mobileRoutes.CHAT:
        return <Icons.Messages className="row" />
      case mobileRoutes.ADD_TEAMMATE:
        return <Icons.PlusCircle className="row dark" />
      case mobileRoutes.QR_CODES:
        return <Icons.QRCode className="row dark" />
      case mobileRoutes.NOTIFICATION_SETTINGS:
        return <Icons.NotificationBell className="row dark" />
      default:
        return <Icons.LogoDark className="row" />
    }
  }
}

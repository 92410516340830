import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IUsersFieldsFragment = Pick<
  Types.IUser,
  | 'id'
  | 'email'
  | 'phoneNumber'
  | 'firstName'
  | 'lastName'
  | 'initials'
  | 'avatarUrl'
  | 'globalRole'
  | 'reportsToId'
  | 'accountPosition'
  | 'employeeId'
  | 'companyAlias'
  | 'isHidden'
> & {
  setUpFinished?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IProjectSetupFinished, 'pageName' | 'setupFinished'>
      >
    >
  >
}

export type IListenToUsersByProjectSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToUsersByProjectSubscription = {
  user?: Types.Maybe<
    Pick<Types.IUserChangeEvent, 'id'> & {
      item?: Types.Maybe<IUsersFieldsFragment>
    }
  >
}

export type ISaveProjectMembersMutationVariables = Types.Exact<{
  members:
    | Array<Types.Maybe<Types.ISaveMemberModelInput>>
    | Types.Maybe<Types.ISaveMemberModelInput>
}>

export type ISaveProjectMembersMutation = {
  saveMembers?: Types.Maybe<Array<Types.Maybe<Pick<Types.IUser, 'id'>>>>
}

export type IUploadUsersFromCsvMutationVariables = Types.Exact<{
  uploadUsersFromCsv: Types.ICsvFileModelInput
}>

export type IUploadUsersFromCsvMutation = {
  uploadUsersFromCsv?: Types.Maybe<Array<Types.Maybe<Pick<Types.IUser, 'id'>>>>
}

export type IDownloadUsersCsvByProjectQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IDownloadUsersCsvByProjectQuery = Pick<Types.IQuery, 'getCsvUsers'>

export type IGetUserByFirebaseIdQueryVariables = Types.Exact<{
  userFirebaseId: Types.Scalars['String']
}>

export type IGetUserByFirebaseIdQuery = {
  user?: Types.Maybe<IUsersFieldsFragment>
}

export type IGetProjectMembersQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetProjectMembersQuery = {
  users?: Types.Maybe<{ data: Array<IUsersFieldsFragment> }>
}

export type IGetUserByEmailQueryVariables = Types.Exact<{
  userEmail: Types.Scalars['String']
}>

export type IGetUserByEmailQuery = { user?: Types.Maybe<IUsersFieldsFragment> }

export type ISendInvitesMutationVariables = Types.Exact<{
  userData:
    | Array<Types.Maybe<Types.IUserDataInput>>
    | Types.Maybe<Types.IUserDataInput>
  projectId: Types.Scalars['ObjectId']
}>

export type ISendInvitesMutation = Pick<Types.IMutation, 'sendInvites'>

export type ISendWelcomeEmailsMutationVariables = Types.Exact<{
  userEmails:
    | Array<Types.Maybe<Types.Scalars['String']>>
    | Types.Maybe<Types.Scalars['String']>
  projectId: Types.Scalars['ObjectId']
}>

export type ISendWelcomeEmailsMutation = Pick<
  Types.IMutation,
  'sendWelcomeEmails'
>

export const UsersFieldsFragmentDoc = gql`
  fragment UsersFields on User {
    id
    email
    phoneNumber
    firstName
    lastName
    initials
    avatarUrl
    globalRole
    reportsToId
    accountPosition
    setUpFinished {
      pageName
      setupFinished
    }
    employeeId
    companyAlias
    isHidden
  }
`
export const ListenToUsersByProjectDocument = gql`
  subscription ListenToUsersByProject($projectId: ObjectId!) {
    user(projectId: $projectId) {
      id
      item {
        ...UsersFields
      }
    }
  }
  ${UsersFieldsFragmentDoc}
`

/**
 * __useListenToUsersByProjectSubscription__
 *
 * To run a query within a React component, call `useListenToUsersByProjectSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToUsersByProjectSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToUsersByProjectSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToUsersByProjectSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToUsersByProjectSubscription,
    IListenToUsersByProjectSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToUsersByProjectSubscription,
    IListenToUsersByProjectSubscriptionVariables
  >(ListenToUsersByProjectDocument, options)
}
export type ListenToUsersByProjectSubscriptionHookResult = ReturnType<
  typeof useListenToUsersByProjectSubscription
>
export type ListenToUsersByProjectSubscriptionResult =
  Apollo.SubscriptionResult<IListenToUsersByProjectSubscription>
export const SaveProjectMembersDocument = gql`
  mutation SaveProjectMembers($members: [SaveMemberModelInput]!) {
    saveMembers(saveMembers: $members) {
      id
    }
  }
`
export type ISaveProjectMembersMutationFn = Apollo.MutationFunction<
  ISaveProjectMembersMutation,
  ISaveProjectMembersMutationVariables
>

/**
 * __useSaveProjectMembersMutation__
 *
 * To run a mutation, you first call `useSaveProjectMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProjectMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProjectMembersMutation, { data, loading, error }] = useSaveProjectMembersMutation({
 *   variables: {
 *      members: // value for 'members'
 *   },
 * });
 */
export function useSaveProjectMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveProjectMembersMutation,
    ISaveProjectMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveProjectMembersMutation,
    ISaveProjectMembersMutationVariables
  >(SaveProjectMembersDocument, options)
}
export type SaveProjectMembersMutationHookResult = ReturnType<
  typeof useSaveProjectMembersMutation
>
export type SaveProjectMembersMutationResult =
  Apollo.MutationResult<ISaveProjectMembersMutation>
export type SaveProjectMembersMutationOptions = Apollo.BaseMutationOptions<
  ISaveProjectMembersMutation,
  ISaveProjectMembersMutationVariables
>
export const UploadUsersFromCsvDocument = gql`
  mutation UploadUsersFromCsv($uploadUsersFromCsv: CsvFileModelInput!) {
    uploadUsersFromCsv(uploadUsersFromCsv: $uploadUsersFromCsv) {
      id
    }
  }
`
export type IUploadUsersFromCsvMutationFn = Apollo.MutationFunction<
  IUploadUsersFromCsvMutation,
  IUploadUsersFromCsvMutationVariables
>

/**
 * __useUploadUsersFromCsvMutation__
 *
 * To run a mutation, you first call `useUploadUsersFromCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUsersFromCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUsersFromCsvMutation, { data, loading, error }] = useUploadUsersFromCsvMutation({
 *   variables: {
 *      uploadUsersFromCsv: // value for 'uploadUsersFromCsv'
 *   },
 * });
 */
export function useUploadUsersFromCsvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUploadUsersFromCsvMutation,
    IUploadUsersFromCsvMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUploadUsersFromCsvMutation,
    IUploadUsersFromCsvMutationVariables
  >(UploadUsersFromCsvDocument, options)
}
export type UploadUsersFromCsvMutationHookResult = ReturnType<
  typeof useUploadUsersFromCsvMutation
>
export type UploadUsersFromCsvMutationResult =
  Apollo.MutationResult<IUploadUsersFromCsvMutation>
export type UploadUsersFromCsvMutationOptions = Apollo.BaseMutationOptions<
  IUploadUsersFromCsvMutation,
  IUploadUsersFromCsvMutationVariables
>
export const DownloadUsersCsvByProjectDocument = gql`
  query DownloadUsersCsvByProject($projectId: ObjectId!) {
    getCsvUsers(projectId: $projectId)
  }
`

/**
 * __useDownloadUsersCsvByProjectQuery__
 *
 * To run a query within a React component, call `useDownloadUsersCsvByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadUsersCsvByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadUsersCsvByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDownloadUsersCsvByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    IDownloadUsersCsvByProjectQuery,
    IDownloadUsersCsvByProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IDownloadUsersCsvByProjectQuery,
    IDownloadUsersCsvByProjectQueryVariables
  >(DownloadUsersCsvByProjectDocument, options)
}
export function useDownloadUsersCsvByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IDownloadUsersCsvByProjectQuery,
    IDownloadUsersCsvByProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IDownloadUsersCsvByProjectQuery,
    IDownloadUsersCsvByProjectQueryVariables
  >(DownloadUsersCsvByProjectDocument, options)
}
export type DownloadUsersCsvByProjectQueryHookResult = ReturnType<
  typeof useDownloadUsersCsvByProjectQuery
>
export type DownloadUsersCsvByProjectLazyQueryHookResult = ReturnType<
  typeof useDownloadUsersCsvByProjectLazyQuery
>
export type DownloadUsersCsvByProjectQueryResult = Apollo.QueryResult<
  IDownloadUsersCsvByProjectQuery,
  IDownloadUsersCsvByProjectQueryVariables
>
export const GetUserByFirebaseIdDocument = gql`
  query GetUserByFirebaseId($userFirebaseId: String!) {
    user(firebaseId: $userFirebaseId) {
      ...UsersFields
    }
  }
  ${UsersFieldsFragmentDoc}
`

/**
 * __useGetUserByFirebaseIdQuery__
 *
 * To run a query within a React component, call `useGetUserByFirebaseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByFirebaseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByFirebaseIdQuery({
 *   variables: {
 *      userFirebaseId: // value for 'userFirebaseId'
 *   },
 * });
 */
export function useGetUserByFirebaseIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetUserByFirebaseIdQuery,
    IGetUserByFirebaseIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetUserByFirebaseIdQuery,
    IGetUserByFirebaseIdQueryVariables
  >(GetUserByFirebaseIdDocument, options)
}
export function useGetUserByFirebaseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetUserByFirebaseIdQuery,
    IGetUserByFirebaseIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetUserByFirebaseIdQuery,
    IGetUserByFirebaseIdQueryVariables
  >(GetUserByFirebaseIdDocument, options)
}
export type GetUserByFirebaseIdQueryHookResult = ReturnType<
  typeof useGetUserByFirebaseIdQuery
>
export type GetUserByFirebaseIdLazyQueryHookResult = ReturnType<
  typeof useGetUserByFirebaseIdLazyQuery
>
export type GetUserByFirebaseIdQueryResult = Apollo.QueryResult<
  IGetUserByFirebaseIdQuery,
  IGetUserByFirebaseIdQueryVariables
>
export const GetProjectMembersDocument = gql`
  query GetProjectMembers($projectId: ObjectId!) {
    users(projectId: $projectId, getDeleted: true, limit: 1000000) {
      data {
        ...UsersFields
      }
    }
  }
  ${UsersFieldsFragmentDoc}
`

/**
 * __useGetProjectMembersQuery__
 *
 * To run a query within a React component, call `useGetProjectMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectMembersQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetProjectMembersQuery,
    IGetProjectMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetProjectMembersQuery,
    IGetProjectMembersQueryVariables
  >(GetProjectMembersDocument, options)
}
export function useGetProjectMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetProjectMembersQuery,
    IGetProjectMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetProjectMembersQuery,
    IGetProjectMembersQueryVariables
  >(GetProjectMembersDocument, options)
}
export type GetProjectMembersQueryHookResult = ReturnType<
  typeof useGetProjectMembersQuery
>
export type GetProjectMembersLazyQueryHookResult = ReturnType<
  typeof useGetProjectMembersLazyQuery
>
export type GetProjectMembersQueryResult = Apollo.QueryResult<
  IGetProjectMembersQuery,
  IGetProjectMembersQueryVariables
>
export const GetUserByEmailDocument = gql`
  query GetUserByEmail($userEmail: String!) {
    user(email: $userEmail) {
      ...UsersFields
    }
  }
  ${UsersFieldsFragmentDoc}
`

/**
 * __useGetUserByEmailQuery__
 *
 * To run a query within a React component, call `useGetUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByEmailQuery({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useGetUserByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetUserByEmailQuery,
    IGetUserByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetUserByEmailQuery, IGetUserByEmailQueryVariables>(
    GetUserByEmailDocument,
    options,
  )
}
export function useGetUserByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetUserByEmailQuery,
    IGetUserByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetUserByEmailQuery,
    IGetUserByEmailQueryVariables
  >(GetUserByEmailDocument, options)
}
export type GetUserByEmailQueryHookResult = ReturnType<
  typeof useGetUserByEmailQuery
>
export type GetUserByEmailLazyQueryHookResult = ReturnType<
  typeof useGetUserByEmailLazyQuery
>
export type GetUserByEmailQueryResult = Apollo.QueryResult<
  IGetUserByEmailQuery,
  IGetUserByEmailQueryVariables
>
export const SendInvitesDocument = gql`
  mutation SendInvites($userData: [UserDataInput]!, $projectId: ObjectId!) {
    sendInvites(userData: $userData, projectId: $projectId)
  }
`
export type ISendInvitesMutationFn = Apollo.MutationFunction<
  ISendInvitesMutation,
  ISendInvitesMutationVariables
>

/**
 * __useSendInvitesMutation__
 *
 * To run a mutation, you first call `useSendInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitesMutation, { data, loading, error }] = useSendInvitesMutation({
 *   variables: {
 *      userData: // value for 'userData'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSendInvitesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISendInvitesMutation,
    ISendInvitesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISendInvitesMutation,
    ISendInvitesMutationVariables
  >(SendInvitesDocument, options)
}
export type SendInvitesMutationHookResult = ReturnType<
  typeof useSendInvitesMutation
>
export type SendInvitesMutationResult =
  Apollo.MutationResult<ISendInvitesMutation>
export type SendInvitesMutationOptions = Apollo.BaseMutationOptions<
  ISendInvitesMutation,
  ISendInvitesMutationVariables
>
export const SendWelcomeEmailsDocument = gql`
  mutation SendWelcomeEmails($userEmails: [String]!, $projectId: ObjectId!) {
    sendWelcomeEmails(userEmails: $userEmails, projectId: $projectId)
  }
`
export type ISendWelcomeEmailsMutationFn = Apollo.MutationFunction<
  ISendWelcomeEmailsMutation,
  ISendWelcomeEmailsMutationVariables
>

/**
 * __useSendWelcomeEmailsMutation__
 *
 * To run a mutation, you first call `useSendWelcomeEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWelcomeEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWelcomeEmailsMutation, { data, loading, error }] = useSendWelcomeEmailsMutation({
 *   variables: {
 *      userEmails: // value for 'userEmails'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSendWelcomeEmailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISendWelcomeEmailsMutation,
    ISendWelcomeEmailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISendWelcomeEmailsMutation,
    ISendWelcomeEmailsMutationVariables
  >(SendWelcomeEmailsDocument, options)
}
export type SendWelcomeEmailsMutationHookResult = ReturnType<
  typeof useSendWelcomeEmailsMutation
>
export type SendWelcomeEmailsMutationResult =
  Apollo.MutationResult<ISendWelcomeEmailsMutation>
export type SendWelcomeEmailsMutationOptions = Apollo.BaseMutationOptions<
  ISendWelcomeEmailsMutation,
  ISendWelcomeEmailsMutationVariables
>

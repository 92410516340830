import * as React from 'react'

import { classList } from 'react-classlist-helper'

import { NONE_COUNT_LABEL } from '~/client/src/shared/utils/usefulStrings'

import CountLabel from './CountLabel'

interface IProps {
  label: string
  countLabel: string
}
export default class MultiSelectItemLabel extends React.PureComponent<IProps> {
  public render() {
    const { label, countLabel } = this.props
    const isDisabled = countLabel === NONE_COUNT_LABEL
    return (
      <span
        className={classList({
          'ml10 text extra-large filter-label': true,
          disabled: isDisabled,
        })}
      >
        {label}
        <CountLabel countLabel={countLabel} />
      </span>
    )
  }
}

import * as Sentry from '@sentry/browser'

import { IDeliveryTicketResponse, IQuery } from '~/client/graph'
import { GetDeliveryTicketDocument } from '~/client/graph/operations/generated/DeliveryTickets.generated'
import config from '~/client/src/shared/Config'
import IDeliveryTicketParams from '~/client/src/shared/models/IDeliveryTicketParams'
import InitialState from '~/client/src/shared/stores/InitialState'

import { LocalStorageKey } from '../../enums/LocalStorageKey'
import LocalStorageHelper from '../../utils/LocalStorageHelper'
import RootStore from '../Root.store'
import { EffectMap, EventHandler } from './BaseEvents.store'
import {
  COMPLETE_REQUEST,
  GET_DELIVERY_TICKET,
  GET_DELIVERY_TICKET_RESULT,
  REPORT_ERROR,
} from './eventConstants'
import EventTypes from './eventTypes'

export const handleCompleteRequest = (state, eventType) => {
  state.loading.set(eventType, false)
}

const IOS_CONNECTION_ERROR = 'Error: The network connection was lost.'

export const handleReportError = (
  state: InitialState,
  eventType: EventTypes,
  error: Error,
): EffectMap => {
  if (config.ENVIRONMENT === 'development') {
    console.error(error, eventType)
    return
  }

  Sentry.withScope(scope => {
    scope.setTag('event', eventType)
    scope.setUser({
      id: state.user?.id,
      email: state.user?.email,
    })
    scope.setLevel(Sentry.Severity.Error)

    Sentry.captureException(error)
  })
}

export const handleRequestError = (_, eventType, error): EffectMap => {
  console.error(`Event of type ${eventType} failed.`)

  // relaunch event to prevent IOS connection bug
  if (error?.toString() === IOS_CONNECTION_ERROR) {
    return {
      dispatchN: [[COMPLETE_REQUEST, eventType], [eventType]],
    }
  }

  return {
    dispatchN: [
      [COMPLETE_REQUEST, eventType],
      [REPORT_ERROR, eventType, error],
    ],
  }
}

export const handleGetDeliveryTicket: EventHandler = (
  state: InitialState,
  params: IDeliveryTicketParams,
) => {
  if (!params.deliveryId) {
    return
  }

  state.loading.set(GET_DELIVERY_TICKET, true)

  return {
    graphQuery: {
      query: GetDeliveryTicketDocument,
      variables: {
        deliveryId: params.deliveryId,
      },
      onSuccess: [GET_DELIVERY_TICKET_RESULT, params.postActionSuccess],
      onError: [GET_DELIVERY_TICKET_RESULT, params.postActionError],
    },
  }
}

export const handleGetDeliveryTicketResult: EventHandler = (
  state,
  postAction: (ticket?: IDeliveryTicketResponse) => void,
  { getDeliveryTicket: deliveryTicket }: IQuery,
) => {
  state.loading.set(GET_DELIVERY_TICKET, false)

  if (postAction) {
    postAction(deliveryTicket)
  }
}

export function shouldRedirectToAuthLinkingPage({
  state: { userActiveProjectSettings, user },
  auth: { withPasswordProvider, fromMicrosoftAzure },
}: RootStore): boolean {
  if (userActiveProjectSettings?.isPresentationUser || fromMicrosoftAzure) {
    return false
  }

  const shouldSkipEmailLinking = !!LocalStorageHelper.getUserPreferenceByKey(
    user.id,
    LocalStorageKey.ShouldSkipEmailLinking,
  )

  if (shouldSkipEmailLinking || config.TENANTLESS_MODE) {
    return false
  }

  return !withPasswordProvider
}

export function emptyHandler() {
  /* empty handler */
}

import * as React from 'react'

import { FileInput } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { Quill } from 'react-quill'

import { IAttachment, UploadingType } from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'

import { FileUploadingStore } from '../../../stores/domain/FileUploading.store'
import formatBytes from '../../../utils/formatBytes'

import './TextEditorToolbar.scss'

const icons = Quill.import('ui/icons')
icons.image = null
icons.bold = null
icons.italic = null
icons.link = null
icons.list = null
icons.underline = null

interface IProps {
  id: string

  fileUploadingStore?: FileUploadingStore
  isVertical?: boolean
  isCompact?: boolean
  addAttachment(attachment: IAttachment)
}

@inject('fileUploadingStore')
@observer
export default class TextEditorToolbar extends React.Component<IProps> {
  public render() {
    const { isVertical, isCompact } = this.props
    const itemClassName = `no-grow ${isVertical ? 'py12' : 'px24'} col y-center`

    return (
      <div
        id={this.props.id}
        className={classList({
          row: !isVertical,
          col: isVertical,
          'overflow-x-auto': isCompact,
        })}
      >
        <div className={itemClassName}>
          <button className="ql-image">
            <Icons.CameraFilled className="camera-icon" />
          </button>
        </div>
        <div className={itemClassName}>
          <FileInput
            disabled={false}
            className="attachment-upload-button"
            inputProps={{
              onChange: this.uploadAttachment,
              accept: '.pdf',
              className: 'bare-file-input',
            }}
            text={<Icons.AttachFile />}
          />
        </div>
        <div className={itemClassName}>
          <button className="ql-bold">
            <Icons.Bold />
          </button>
        </div>
        <div className={itemClassName}>
          <button className="ql-custom-header" value="1">
            <Icons.Header />
            <sub>1</sub>
          </button>
        </div>
        <div className={itemClassName}>
          <button className="ql-custom-header" value="2">
            <Icons.Header />
            <sub>2</sub>
          </button>
        </div>
        <div className={itemClassName}>
          <button className="ql-custom-header" value="3">
            <Icons.Header />
            <sub>3</sub>
          </button>
        </div>
        <div className={itemClassName}>
          <button className="ql-underline">
            <Icons.Underlined />
          </button>
        </div>
        <div className={itemClassName}>
          <button className="ql-list" value="bullet">
            <Icons.BulletList />
          </button>
        </div>
        <div className={itemClassName}>
          <button className="ql-list" value="ordered">
            <Icons.NumberedList />
          </button>
        </div>
        <div className={itemClassName}>
          <button className="ql-hr">
            <Icons.HorizontalLine />
          </button>
        </div>
        <div className={itemClassName}>
          <button className="ql-link">
            <Icons.Link />
          </button>
        </div>
        <div className={itemClassName}>
          <button className="ql-video" />
        </div>
      </div>
    )
  }

  private uploadAttachment = async event => {
    const file: File = event.target.files[0]
    event.target.value = ''

    if (!file) {
      return
    }

    const [result] = await this.props.fileUploadingStore.uploadFile(
      file,
      UploadingType.Pdf,
      file.name,
    )

    this.props.addAttachment({
      fileName: file.name,
      size: formatBytes(file.size),
      url: result.fileURL,
    })
  }
}

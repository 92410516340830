import { LocalStorageKey } from '../enums/LocalStorageKey'

interface IEnvs {
  prod: string
  dev: string
}

const inviteKeyStr = 'inviteKey='
const mobile = '-mobile'
const localhostStr = 'localhost'
const tenantlessStr = 'tenantless'
const login = 'login'
const devSubdomain = 'dev'

export default function isTenantLessMode({ prod, dev }: IEnvs): boolean {
  const { host, hostname, search } = location
  const key = LocalStorageKey.Tenant

  // To stop Dev and Sandbox from being tenantless for you, write the tenant hostname to local storage ;)
  const tenantInSessionStorage =
    sessionStorage.getItem(key) || localStorage.getItem(key)

  const isTenantless =
    // Tenant host in session storage is not the same as the current host
    tenantInSessionStorage !== host &&
    // No inviteKey in query string
    !search.includes(inviteKeyStr) &&
    // Sandbox (desktop or mobile)
    (new RegExp(`^${login}(${mobile})?\\.${prod}$`).test(hostname) ||
      // Dev (desktop or mobile)
      new RegExp(`^${login}(${mobile})?\\.${dev}$`).test(hostname) ||
      // Localhost or any preview environment with `tenantless` in the querystring
      ([localhostStr, `${devSubdomain}.${dev}`].some(h =>
        hostname.includes(h),
      ) &&
        search.includes(tenantlessStr)))

  return isTenantless
}

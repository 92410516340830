import * as React from 'react'

import { inject, observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import ActivityCode from '~/client/src/shared/models/ActivityCode'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'

import './ExtendedActivityInfo.scss'

interface IExtendedActivityInfo {
  activity: Activity
  activitiesStore?: ActivitiesStore
  activityFiltersStore?: ActivityFiltersStore
}

const EDGE = 3

@inject('activitiesStore', 'activityFiltersStore')
@observer
export default class ExtendedActivityInfo extends React.Component<IExtendedActivityInfo> {
  public render() {
    const { activity, activitiesStore } = this.props
    const { code: activityId } = activity
    const activityModel = activitiesStore.byId.get(activityId)

    const activityCompanies = activityModel.companies
    const { codes } = activity

    const companies = this.retrieveCompanyLabel(activityCompanies)
    const locations = this.retrieveLocationLabel(codes)

    return (
      <div className="text large ellipsis">
        <span className="text large ellipsis companies">{companies}</span>
        {locations && ` — ${locations}`}
      </div>
    )
  }

  private retrieveCompanyLabel(activityCompanies: string[]): string {
    if (!activityCompanies.length) {
      return Localization.translator.unassignedActivity
    }

    if (activityCompanies.length === 1) {
      return activityCompanies[0]
    }
    return activityCompanies.map(company => company.slice(0, EDGE)).join(', ')
  }

  private retrieveLocationLabel(codes: ActivityCode[]): string {
    const { activityCodeFilterTypes, getCodesByFilterType } =
      this.props.activityFiltersStore

    const allLocationCodeIds = []
    activityCodeFilterTypes.forEach(filterType => {
      allLocationCodeIds.push(
        ...getCodesByFilterType(filterType).map(({ id }) => id),
      )
    })

    const locationCodes = codes.filter(code =>
      allLocationCodeIds.includes(code.id),
    )

    if (!locationCodes.length) {
      return ''
    }

    return locationCodes.map(code => code.name).join(' • ')
  }
}

import * as React from 'react'

import { ApolloProvider } from '@apollo/client'
import { Provider, observer } from 'mobx-react'
import { BrowserRouter } from 'react-router-dom'

import RootStore from '../stores/Root.store'

interface AppProviderProps {
  rootStore: RootStore
}
@observer
export default class AppProvider extends React.Component<AppProviderProps> {
  public render() {
    const { rootStore, children } = this.props
    const { graphClient } = rootStore.state

    return (
      <ApolloProvider client={graphClient}>
        <Provider {...rootStore}>
          <BrowserRouter>{children}</BrowserRouter>
        </Provider>
      </ApolloProvider>
    )
  }
}

import * as React from 'react'

import { inject, observer } from 'mobx-react'

import PermitTypesStore from '../../../stores/domain/PermitTypes.store'
import SitePermitsStore from '../../../stores/domain/SitePermits.store'
import { ISharedProps } from '../BaseStruxhubInput'
import BaseStruxhubSelector from './BaseStruxhubSelector'

// localization: no text to translate

interface IProps extends ISharedProps {
  onClick?: () => void
  caption?: string

  sitePermitsStore?: SitePermitsStore
  permitTypesStore?: PermitTypesStore
}

@inject('sitePermitsStore', 'permitTypesStore')
@observer
export default class StruxhubWorkflowSelector extends React.Component<IProps> {
  public render() {
    const { sitePermitsStore, value, caption } = this.props

    const workflowCaption =
      caption ||
      sitePermitsStore
        .getFormById(value)
        ?.getCaption(this.props.permitTypesStore)

    return (
      <BaseStruxhubSelector {...this.props} selectorClassName="h56">
        <span
          className="text extra-large text-ellipsis"
          title={workflowCaption}
        >
          {workflowCaption}
        </span>
      </BaseStruxhubSelector>
    )
  }
}

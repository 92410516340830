import { IAttachment, IMessage, IThread } from '~/client/graph'
import {
  ISaveMessageMutation,
  SaveWorkflowMessageDocument,
} from '~/client/graph/operations/generated/Messages.generated'
import Message from '~/client/src/shared/models/Message'
import Thread from '~/client/src/shared/models/Thread'

import MessageDto from '../../../types/MessageDto'
import EventsStore from '../../EventStore/Events.store'
import {
  SAVE_MESSAGE,
  SAVE_WORKFLOW_MESSAGE,
} from '../../EventStore/eventConstants'
import BaseStoreWithById from '../../baseStores/BaseWithById.store'
import ActivitiesStore from '../Activities.store'
import GraphExecutorStore from '../GraphExecutor.store'

export default class MessagesStore extends BaseStoreWithById<
  Message,
  IMessage
> {
  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly activitiesStore: ActivitiesStore,
    private readonly graphExecutorStore: GraphExecutorStore,
  ) {
    super(Message)
  }

  public get byId() {
    return this.eventsStore.appState.messages
  }

  public getPreparedMessageModel = (
    text: string,
    thread: Thread | IThread | string,
    attachments?: IAttachment[],
    id?: string,
  ): Message => {
    if (!thread) {
      return
    }

    let threadId: string

    if (typeof thread === 'string') {
      threadId = thread
    } else {
      threadId = thread.id
    }

    if (!threadId) {
      throw new Error('Invalid thread type received')
    }

    const {
      user,
      activeProject: { id: projectId },
    } = this.eventsStore.appState

    const m = new Message(id)

    m.text = text || ''
    m.threadId = threadId
    m.markAsReadByUser(user)
    m.userId = user.id
    m.projectId = projectId
    m.attachments = attachments

    const { selectedActivity } = this.activitiesStore
    if (selectedActivity) {
      m.activityId = selectedActivity.code
    }

    return m
  }

  public post = (
    text: string,
    thread: Thread | IThread | string,
    attachments?: IAttachment[],
  ): Promise<MessageDto> => {
    try {
      const message = this.getPreparedMessageModel(text, thread, attachments)

      return this.save(message)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  public getMessagesByThreadId(threadId: string): Message[] {
    if (!threadId) {
      return []
    }

    return this.list.filter(message => message.threadId === threadId)
  }

  public save = (item: Message): Promise<MessageDto> => {
    return new Promise((resolve, reject) => {
      this.eventsStore.dispatch(
        SAVE_MESSAGE,
        item,
        (data: ISaveMessageMutation) => {
          resolve({
            ...data.saveMessage,
          } as MessageDto)
        },
        reject,
      )
    })
  }

  public saveWorkflowMessage = async (
    workflowId: string,
    message: Message,
  ): Promise<MessageDto> => {
    this.eventsStore.appState.loading.set(SAVE_WORKFLOW_MESSAGE, true)

    const result = await this.graphExecutorStore.mutate(
      SaveWorkflowMessageDocument,
      {
        workflowId,
        message,
      },
    )

    this.eventsStore.appState.loading.set(SAVE_WORKFLOW_MESSAGE, false)

    return result?.data?.savePermitMessage as MessageDto
  }
}

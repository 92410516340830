import { action, observable } from 'mobx'

import Config, { remoteProdDomain, remoteTestDomain } from '../../Config'
import InitialState, { ILocation } from '../InitialState'

type ValidAccessTokenGetter = () => Promise<string>
export interface ITenant {
  name: string
  host: string
}
export default class TenantsStore {
  @observable public activeTenant: ITenant = null
  @observable public userTenants: Array<ITenant> = []

  public constructor(
    private readonly getValidAccessToken: ValidAccessTokenGetter,
    private readonly initialState: InitialState,
  ) {
    // Set initial tenant based on hostname when tenantless mode is off
    if (
      !Config.TENANTLESS_MODE &&
      (location.hostname === 'localhost' ||
        [remoteProdDomain, remoteTestDomain].some(suffix =>
          location.hostname.endsWith(suffix),
        ))
    ) {
      this.activeTenant = { name: 'StruxHub', host: location.hostname }
    }
  }

  public get baseUrl(): string {
    return this.activeTenant?.host ?? Config.BASE_URL
  }

  public getTenantInfoByHostname(tenantHostName: string): ITenant {
    return this.userTenants.find(t => t.host === tenantHostName)
  }

  @action.bound
  public setTenants(userTenants: ITenant[]) {
    this.userTenants = userTenants
  }

  @action.bound
  public setActiveTenant(tenantHostName: string) {
    this.activeTenant = this.getTenantInfoByHostname(tenantHostName)
    this.updateUserCurrentTenantInStorage()
  }

  public isTenantDifferentByHost(hostToCompare: string): boolean {
    return hostToCompare !== this.activeTenant?.host
  }

  public clearTenants() {
    this.setTenants([])
    this.setActiveTenant(null)
  }

  /* Here we make a GraphQL query with Fetch API instead of using an already
  defined and created graph client. The reason for this is that the created
  graph client may point to a different URL than the one we need*/
  public async hasUserAccessToTenant(location: ILocation): Promise<boolean> {
    const sampleQueryName = 'GetUser'

    try {
      const user = this.initialState.tenantUserSession
      const validToken = await this.getValidAccessToken()
      const fetchUrl = `${location.protocol}//${location.hostname}/graphql`
      const fetchParams = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${validToken}`,
          Accept: 'application/json',
        },
        body: JSON.stringify({
          operationName: sampleQueryName,
          variables: {
            userEmail: user.email,
          },
          query: `query ${sampleQueryName}($userEmail: String) {
            user(email: $userEmail) {
              id
            }
          }`,
        }),
      }

      const response = await fetch(fetchUrl, fetchParams)

      const result = await response.json()
      return !result.errors && !result?.data?.user
    } catch (error) {
      console.error('error: ', error)
      return false
    }
  }

  public updateUserCurrentTenantInStorage() {
    const user = this.initialState.tenantUserSession
    if (user && this.activeTenant?.host) {
      user.activeTenantHost = this.activeTenant.host
    }
  }
}

import { action, computed, observable } from 'mobx'

import { IMapboxTileset, ITileset, UploadingType } from '~/client/graph'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import Guard from '~/client/src/shared/utils/Guard'

import {
  DELETE_TILESET,
  LOAD_TILESETS,
  UPLOAD_TILESET,
} from '../EventStore/eventConstants'
import { FileUploadingStore } from './FileUploading.store'

export default class TilesetsStore {
  @observable public isDataReceived: boolean = false
  @observable public processingTilesets: ITileset[] = []
  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly fileUploadingStore: FileUploadingStore,
  ) {
    Guard.requireAll({
      eventsStore,
    })
  }

  @computed
  public get list(): IMapboxTileset[] {
    return Array.from(this.byId.values())
  }

  public get byId() {
    const { tilesets } = this.eventsStore.appState
    return tilesets
  }

  @action.bound
  public clearList() {
    this.byId.clear()
    this.processingTilesets = []
  }

  @action.bound
  public receiveList(list: IMapboxTileset[]) {
    this.clearList()

    list.forEach(dto => {
      this.byId.set(dto?.id, dto)
    })
    this.isDataReceived = true
  }

  @action.bound
  public removeOne(tilesetId: string, callbackFn?: () => void) {
    if (!this.byId.has(tilesetId)) {
      return
    }

    this.byId.delete(tilesetId)

    this.eventsStore.dispatch(DELETE_TILESET, tilesetId, callbackFn)
  }

  public receiveOne(tileset: ITileset) {
    if (tileset?.isProcessed) {
      this.processingTilesets = this.processingTilesets.filter(
        obj => obj.id !== tileset.id,
      )
      this.eventsStore.dispatch(LOAD_TILESETS)
    } else {
      this.processingTilesets.push(tileset)
    }
  }

  @action.bound
  public async uploadTileset(
    file: Blob,
    fileName?: string,
    callbackFn?: () => void,
  ) {
    const { id: projectId } = this.eventsStore.appState.activeProject
    const [fileInfo] = await this.fileUploadingStore.uploadFile(
      file,
      UploadingType.Tileset,
      fileName,
    )
    this.eventsStore.dispatch(
      UPLOAD_TILESET,
      projectId,
      fileName,
      fileInfo.filePath,
      callbackFn,
    )
  }
}

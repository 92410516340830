enum FlaggedFeatures {
  MATERIALS = 'allowMaterials',
  CONTAINER_UPDATES = 'allowContainerUpdates',
  PROJECT_CREATION = 'allowProjectCreation',
  DELIVERIES_PROJECT_TYPE_TOGGLING = 'allowDeliveriesToggling',
  FORMS_PROJECT_TYPE_TOGGLING = 'allowFormsToggling',
  LOGISTICS_PROJECT_TYPE_TOGGLING = 'allowLogisticsToggling',
  TRACKER_PROJECT_TYPE_TOGGLING = 'allowTrackerToggling',
  MATERIALS_PROJECT_TYPE_TOGGLING = 'allowMaterialsToggling',
  ALLOW_PHOTOS = 'allowPhotos',
  ALLOW_ANALYTICS = 'allowAnalytics',
  ALLOW_TILESETS = 'allowTilesets',
}

export const flaggedFeaturesList = Object.values(FlaggedFeatures)

export default FlaggedFeatures

import { action, computed, observable } from 'mobx'

import { ICompany } from '~/client/graph'
import { CompaniesByProjectCodeAndNameSubstrDocument } from '~/client/graph/operations/generated/Companies.generated'
import { GetProjectNameDocument } from '~/client/graph/operations/generated/Project.generated'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import { FormFieldType } from '../../enums/FormFieldType'
import UserFieldId from '../../enums/UserFieldId'
import { Field } from '../../types/CustomFieldType'
import { NOOP } from '../../utils/noop'
import {
  ICountry,
  getFormattedPhoneNumberForSubmission,
  isPhoneNumberValidForSubmission,
} from '../../utils/phoneNumberHelpers'
import {
  NON_EMPTY_VALUE_PATTERN,
  VALID_EMAIL_PATTERN,
} from '../../utils/regExpPatterns'
import { customDebounce } from '../../utils/util'
import EventsStore from '../EventStore/Events.store'
import InitialState from '../InitialState'
import GraphExecutorStore from '../domain/GraphExecutor.store'

interface ICompanyBasicInfo {
  name: string
  companyId: string
}

export const projectCodeKey = 'projectCode'

const DEBOUNCE_TIME = 500

const MIN_LENGTH = 3
const MAX_RESULTS = 5

export default abstract class BaseSignUpStore {
  @observable public initProjectName = ''

  @observable public errorMessage = ''

  @observable private fieldValuesMap: Map<UserFieldId, string> = null
  @observable private invalidFieldsMap = new Map<UserFieldId, boolean>()

  @observable private _currentSearchResults: ICompanyBasicInfo[] = []
  @observable public isCompanySearching = false

  public previewCompany: (company: ICompany) => JSX.Element = null

  private debouncedSearchCompany: (substring: string) => void

  public constructor(
    protected readonly eventsStore: EventsStore,
    protected readonly graphExecutorStore: GraphExecutorStore,
    private readonly optionFieldsIds: UserFieldId[] = [],
  ) {
    const fieldValuesMap: any = Object.entries(this.initFieldValuesMap || {})
    this.fieldValuesMap = new Map<UserFieldId, string>(fieldValuesMap)

    this.debouncedSearchCompany = customDebounce(
      this.searchCompanyByNameSubstring.bind(this),
      DEBOUNCE_TIME,
    )
  }

  public get appState(): InitialState {
    return this.eventsStore.appState
  }

  public get isLoading(): boolean {
    return false
  }

  public get initFieldValuesMap(): { [key: string]: string } {
    return {}
  }

  public get fields(): Field[] {
    const fields: Field[] = [
      {
        id: UserFieldId.FirstName,
        type: FormFieldType.Text,
        value: this.getFieldValueById(UserFieldId.FirstName),
        label: Localization.translator.firstName,
        onChange: this.handleFieldChange.bind(this, UserFieldId.FirstName),
        onValueReset: this.handleValueReset.bind(this, UserFieldId.FirstName),
        pattern: NON_EMPTY_VALUE_PATTERN.source,
      },
      {
        id: UserFieldId.LastName,
        type: FormFieldType.Text,
        value: this.getFieldValueById(UserFieldId.LastName),
        label: Localization.translator.lastName,
        onChange: this.handleFieldChange.bind(this, UserFieldId.LastName),
        onValueReset: this.handleValueReset.bind(this, UserFieldId.LastName),
        pattern: NON_EMPTY_VALUE_PATTERN.source,
      },

      {
        id: UserFieldId.Email,
        type: FormFieldType.Email,
        value: this.getFieldValueById(UserFieldId.Email),
        label: Localization.translator.email_noun,
        onChange: this.handleFieldChange.bind(this, UserFieldId.Email),
        onValueReset: this.handleValueReset.bind(this, UserFieldId.Email),
        pattern: VALID_EMAIL_PATTERN.source,
        isRequired: false,
        validationMessage:
          Localization.translator.userValidationErrors.invalidEmail,
      },
      {
        id: UserFieldId.PhoneNumber,
        type: FormFieldType.Phone,
        value: this.getFieldValueById(UserFieldId.PhoneNumber),
        label: Localization.translator.phoneNumber,
        onChange: this.handlePhoneFieldChange.bind(
          this,
          UserFieldId.PhoneNumber,
        ),
      },
      {
        id: UserFieldId.Company,
        type: FormFieldType.IncrementalSearch,
        value: this.getFieldValueById(UserFieldId.Company),
        pattern: this.getFieldValueById(UserFieldId.CompanyAlias) as string,
        label: Localization.translator.company,
        onChange: this.handleCompanyChange.bind(this),
        onValueReset: this.handleValueReset.bind(this, UserFieldId.Company),
        membersList: this.currentSearchResults,
        maxResults: MAX_RESULTS,
        previewMember: this.previewCompany,
        onApply: this.handleCompanyApply.bind(this, UserFieldId.Company),
        onCancel: this.handleCompanyCancel.bind(this, UserFieldId.Company),
        minlength: MIN_LENGTH,
        maxNumber: 1, // Always returns array, even if single-value
        isValid: this.isCompanyValid,
        isLoading: this.isCompanySearching,
        isRequired: true,
        validationMessage:
          this.getFieldValueById(UserFieldId.CompanyAlias).length >= MIN_LENGTH
            ? Localization.translator.useCompanyAliasValidationMessage
            : Localization.translator.userValidationErrors.minLengthX(
                MIN_LENGTH,
              ),
      },
      {
        id: UserFieldId.UseCompanyAlias,
        type: FormFieldType.Switch,
        value: this.getFieldValueById(UserFieldId.UseCompanyAlias),
        label: Localization.translator.useCompanyAlias,
        onChange: this.handleCompanySwitchChange.bind(
          this,
          UserFieldId.UseCompanyAlias,
        ),
        isRequired: this.isCompanyAliasRequired,
        isValid: this.isCompanyValid,
        isHidden: !this.isCompanyAliasRequired,
        inline: 1,
      },
      {
        isHidden: true,
        id: UserFieldId.CompanyAlias,
        type: FormFieldType.Text,
        value: this.getFieldValueById(UserFieldId.CompanyAlias),
        label: Localization.translator.company,
        isRequired: this.isCompanyAliasRequired,
        onChange: NOOP, // value gets updated from Company field
      },
    ]

    fields.forEach(field => {
      if (field.isValid === undefined)
        field.isValid = this.isFieldValid(field.id)
      if (field.isRequired === undefined)
        field.isRequired = this.isFieldRequired(field.id)
    })

    return fields
  }

  @computed
  private get isCompanyAliasRequired() {
    return (
      this.getFieldValueById(UserFieldId.CompanyAlias).length >= MIN_LENGTH &&
      !this.getFieldValueById(UserFieldId.Company).length
    )
  }

  @computed
  private get currentSearchResults() {
    const companySearch = this.getFieldValueById(UserFieldId.CompanyAlias)
    if (companySearch.length < MIN_LENGTH) {
      return []
    }
    const companySearchLowerCase = (companySearch as string).toLowerCase()
    const returns = this._currentSearchResults.filter(
      (member: ICompanyBasicInfo) =>
        member.name.toLowerCase().includes(companySearchLowerCase),
    )
    return returns
  }

  @computed
  private get isCompanyValid() {
    const hasCompanySelected =
      this.getFieldValueById(UserFieldId.Company).length > 0
    const hasCompanyAlias =
      this.getFieldValueById(UserFieldId.CompanyAlias).length >= MIN_LENGTH
    const isCompanyAliasChecked = !!this.getFieldValueById(
      UserFieldId.UseCompanyAlias,
    )
    return hasCompanySelected || (hasCompanyAlias && isCompanyAliasChecked)
  }

  @action.bound
  public handleSubmit() {
    if (this.canSubmit) {
      this.submit()
    } else {
      this.showError()
    }
  }

  @action.bound
  public async requestProjectName() {
    const res = await this.graphExecutorStore.query(GetProjectNameDocument, {
      projectCode: this.appState.initProjectCode,
    })

    if (res.data) {
      this.initProjectName = res.data.getProjectName
    }
  }

  /**
   * Performs search and updates `currentResults`
   * @param substring Part of the company name to search for
   */
  @action.bound
  private async searchCompanyByNameSubstring(substring: string) {
    if (substring.length < MIN_LENGTH) return

    this.isCompanySearching = true
    const res = await this.graphExecutorStore.query(
      CompaniesByProjectCodeAndNameSubstrDocument,
      {
        projectCode: this.appState.initProjectCode,
        searchString: substring,
      },
    )

    const companiesBasicInfo =
      res.data.companiesBasicInfo?.map(company => ({
        // Just in case because the schema type permits; we don't expect companies not to have a name here
        name: company.name ?? 'Missing name',
        companyId: company.companyId,
      })) || []
    this._currentSearchResults = companiesBasicInfo
    this.isCompanySearching = false
  }

  protected submit() {
    throw new Error('[submit] should be overridden in derived classes')
  }

  protected getFieldValueById(fieldId: UserFieldId): string | ICompany[] {
    return this.fieldValuesMap.get(fieldId) || ''
  }

  @action
  protected handleCompanyChange(event: any) {
    this.setFieldValueById(UserFieldId.CompanyAlias, event.target.value)
    this.debouncedSearchCompany(event.target.value)
  }

  @action
  protected handleCompanyApply(fieldId: UserFieldId, company: ICompany) {
    const currentValue: ICompany[] =
      (this.getFieldValueById(fieldId) as ICompany[]) || []

    this.clearError()
    currentValue.push(company)
    this.setFieldValueById(fieldId, currentValue)
    this.invalidFieldsMap.set(fieldId, false)
  }

  @action
  protected handleCompanyCancel(fieldId: UserFieldId, company: ICompany) {
    const currentValue: ICompany[] =
      (this.getFieldValueById(fieldId) as ICompany[]) || []

    this.clearError()
    const index = currentValue.findIndex(item => item.id === company.id)
    if (index !== -1) {
      currentValue.splice(index, 1)
    }
    this.setFieldValueById(fieldId, currentValue)
    this.invalidFieldsMap.set(fieldId, false)
  }

  protected handleCompanySwitchChange(fieldId: UserFieldId) {
    const currentCheckedValue = this.getFieldValueById(fieldId)
    this.setFieldValueById(fieldId, !currentCheckedValue)
    this.invalidFieldsMap.set(
      fieldId,
      this.isCompanyAliasRequired && !!currentCheckedValue,
    )
    this.clearError()
  }

  @action
  protected handleFieldChange(fieldId: UserFieldId, { target }: any) {
    this.clearError()
    this.setFieldValueById(fieldId, target.value)
    this.invalidFieldsMap.set(fieldId, !target.checkValidity())
  }

  @action
  protected handleValueReset(fieldId: UserFieldId) {
    this.clearError()
    this.setFieldValueById(fieldId, '')
    this.invalidFieldsMap.set(fieldId, true)
  }

  @action.bound
  protected handlePhoneFieldChange(
    fieldId: UserFieldId,
    phoneNumber: string,
    country: ICountry,
  ) {
    this.clearError()
    this.setFieldValueById(fieldId, phoneNumber)

    const isRequired = this.isFieldRequired(fieldId)
    const isValid = isPhoneNumberValidForSubmission(
      phoneNumber,
      country,
      isRequired,
    )

    this.invalidFieldsMap.set(fieldId, !isValid)
  }

  @action.bound
  protected setFieldValueById(fieldId: UserFieldId, value: any) {
    this.fieldValuesMap.set(fieldId, value)
  }

  @action.bound
  protected setError(errorMessage: string) {
    this.errorMessage =
      errorMessage ||
      Localization.translator.somethingWentWrongDuringAPIInteraction
  }

  @action.bound
  private clearError() {
    this.errorMessage = ''
  }

  protected isFieldValid(fieldId: UserFieldId): boolean {
    return !this.invalidFieldsMap.get(fieldId)
  }

  protected isFieldRequired(fieldId: UserFieldId): boolean {
    return !this.optionFieldsIds.includes(fieldId)
  }

  protected get formattedFieldMapForSubmission(): { [key: string]: string } {
    const formattedFieldMap = this.fields.reduce((acc, field) => {
      const formattedValue = this.getFormattedFieldForSubmission(field)
      if (formattedValue) {
        Object.assign(acc, {
          [field.id]: formattedValue,
        })
      }
      return acc
    }, {})

    // Only submit companyAlias if companyId not provided
    if (formattedFieldMap['companyId']) {
      delete formattedFieldMap['companyAlias']
    }
    return formattedFieldMap
  }

  private getFormattedFieldForSubmission(field): string | undefined {
    const { id, value } = field
    let returnValue: string

    switch (id) {
      case UserFieldId.PhoneNumber:
        returnValue = getFormattedPhoneNumberForSubmission(value)
        break

      case UserFieldId.Company:
        returnValue = Array.isArray(value)
          ? value.map(({ companyId }) => companyId).join(',')
          : value
        break

      case UserFieldId.UseCompanyAlias:
        // Don't submit this field; it is used for UI only
        return undefined

      default:
        returnValue = value
    }

    return returnValue?.trim()
  }

  private get invalidFields(): Field[] {
    return this.fields.filter(({ isValid }) => !isValid)
  }

  @computed
  private get unfilledRequiredFields(): Field[] {
    return this.fields.filter(({ isRequired, value, type }) => {
      if (!isRequired) return false

      switch (type) {
        case FormFieldType.IncrementalSearch:
          return !this.isCompanyValid

        case FormFieldType.Switch:
          // This is validated along with the incremental search field
          return false

        default:
          return !value.trim()
      }
    })
  }

  public get canSubmit(): boolean {
    return !this.invalidFields.length && !this.unfilledRequiredFields.length
  }

  private showError() {
    let errorMessage = Localization.translator.pleaseCorrectErrors
    let labels = ''

    if (this.unfilledRequiredFields.length) {
      errorMessage = Localization.translator.requiredFieldCannotBeEmpty
      labels = this.unfilledRequiredFields
        .map(({ label }) => `[${label}]`)
        .join(',')
    } else if (this.invalidFields.length) {
      labels = this.invalidFields.map(({ label }) => `[${label}]`).join(',')
    }

    this.setError(`${errorMessage} : ${labels}`)
  }
}

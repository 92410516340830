import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IWorkflowsCustomFilterFieldsFragment = Pick<
  Types.IWorkflowsCustomFilter,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'version'
  | 'projectId'
  | 'name'
  | 'userId'
  | 'isPublic'
  | 'defaultForUsers'
  | 'groupBy'
> & {
  filtersByFilterType?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IWorkflowsFilter, 'type' | 'values'>>>
  >
}

export type IGetWorkflowCustomFiltersByProjectQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetWorkflowCustomFiltersByProjectQuery = {
  workflowsCustomFilters?: Types.Maybe<{
    data: Array<IWorkflowsCustomFilterFieldsFragment>
  }>
}

export type ISaveWorkflowCustomFilterMutationVariables = Types.Exact<{
  filter: Types.IWorkflowsCustomFilterInput
}>

export type ISaveWorkflowCustomFilterMutation = {
  saveWorkflowsCustomFilter?: Types.Maybe<IWorkflowsCustomFilterFieldsFragment>
}

export type IDeleteWorkflowCustomFilterMutationVariables = Types.Exact<{
  filterId: Types.Scalars['ObjectId']
}>

export type IDeleteWorkflowCustomFilterMutation = Pick<
  Types.IMutation,
  'deleteWorkflowsCustomFilter'
>

export const WorkflowsCustomFilterFieldsFragmentDoc = gql`
  fragment WorkflowsCustomFilterFields on WorkflowsCustomFilter {
    id
    createdAt
    updatedAt
    version
    projectId
    name
    userId
    isPublic
    filtersByFilterType {
      type
      values
    }
    defaultForUsers
    groupBy
  }
`
export const GetWorkflowCustomFiltersByProjectDocument = gql`
  query GetWorkflowCustomFiltersByProject($projectId: ObjectId!) {
    workflowsCustomFilters(limit: 1000000, projectId: $projectId) {
      data {
        ...WorkflowsCustomFilterFields
      }
    }
  }
  ${WorkflowsCustomFilterFieldsFragmentDoc}
`

/**
 * __useGetWorkflowCustomFiltersByProjectQuery__
 *
 * To run a query within a React component, call `useGetWorkflowCustomFiltersByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowCustomFiltersByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowCustomFiltersByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetWorkflowCustomFiltersByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetWorkflowCustomFiltersByProjectQuery,
    IGetWorkflowCustomFiltersByProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetWorkflowCustomFiltersByProjectQuery,
    IGetWorkflowCustomFiltersByProjectQueryVariables
  >(GetWorkflowCustomFiltersByProjectDocument, options)
}
export function useGetWorkflowCustomFiltersByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetWorkflowCustomFiltersByProjectQuery,
    IGetWorkflowCustomFiltersByProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetWorkflowCustomFiltersByProjectQuery,
    IGetWorkflowCustomFiltersByProjectQueryVariables
  >(GetWorkflowCustomFiltersByProjectDocument, options)
}
export type GetWorkflowCustomFiltersByProjectQueryHookResult = ReturnType<
  typeof useGetWorkflowCustomFiltersByProjectQuery
>
export type GetWorkflowCustomFiltersByProjectLazyQueryHookResult = ReturnType<
  typeof useGetWorkflowCustomFiltersByProjectLazyQuery
>
export type GetWorkflowCustomFiltersByProjectQueryResult = Apollo.QueryResult<
  IGetWorkflowCustomFiltersByProjectQuery,
  IGetWorkflowCustomFiltersByProjectQueryVariables
>
export const SaveWorkflowCustomFilterDocument = gql`
  mutation SaveWorkflowCustomFilter($filter: WorkflowsCustomFilterInput!) {
    saveWorkflowsCustomFilter(workflowsCustomFilter: $filter) {
      ...WorkflowsCustomFilterFields
    }
  }
  ${WorkflowsCustomFilterFieldsFragmentDoc}
`
export type ISaveWorkflowCustomFilterMutationFn = Apollo.MutationFunction<
  ISaveWorkflowCustomFilterMutation,
  ISaveWorkflowCustomFilterMutationVariables
>

/**
 * __useSaveWorkflowCustomFilterMutation__
 *
 * To run a mutation, you first call `useSaveWorkflowCustomFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWorkflowCustomFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWorkflowCustomFilterMutation, { data, loading, error }] = useSaveWorkflowCustomFilterMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSaveWorkflowCustomFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveWorkflowCustomFilterMutation,
    ISaveWorkflowCustomFilterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveWorkflowCustomFilterMutation,
    ISaveWorkflowCustomFilterMutationVariables
  >(SaveWorkflowCustomFilterDocument, options)
}
export type SaveWorkflowCustomFilterMutationHookResult = ReturnType<
  typeof useSaveWorkflowCustomFilterMutation
>
export type SaveWorkflowCustomFilterMutationResult =
  Apollo.MutationResult<ISaveWorkflowCustomFilterMutation>
export type SaveWorkflowCustomFilterMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveWorkflowCustomFilterMutation,
    ISaveWorkflowCustomFilterMutationVariables
  >
export const DeleteWorkflowCustomFilterDocument = gql`
  mutation DeleteWorkflowCustomFilter($filterId: ObjectId!) {
    deleteWorkflowsCustomFilter(id: $filterId)
  }
`
export type IDeleteWorkflowCustomFilterMutationFn = Apollo.MutationFunction<
  IDeleteWorkflowCustomFilterMutation,
  IDeleteWorkflowCustomFilterMutationVariables
>

/**
 * __useDeleteWorkflowCustomFilterMutation__
 *
 * To run a mutation, you first call `useDeleteWorkflowCustomFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkflowCustomFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkflowCustomFilterMutation, { data, loading, error }] = useDeleteWorkflowCustomFilterMutation({
 *   variables: {
 *      filterId: // value for 'filterId'
 *   },
 * });
 */
export function useDeleteWorkflowCustomFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteWorkflowCustomFilterMutation,
    IDeleteWorkflowCustomFilterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteWorkflowCustomFilterMutation,
    IDeleteWorkflowCustomFilterMutationVariables
  >(DeleteWorkflowCustomFilterDocument, options)
}
export type DeleteWorkflowCustomFilterMutationHookResult = ReturnType<
  typeof useDeleteWorkflowCustomFilterMutation
>
export type DeleteWorkflowCustomFilterMutationResult =
  Apollo.MutationResult<IDeleteWorkflowCustomFilterMutation>
export type DeleteWorkflowCustomFilterMutationOptions =
  Apollo.BaseMutationOptions<
    IDeleteWorkflowCustomFilterMutation,
    IDeleteWorkflowCustomFilterMutationVariables
  >

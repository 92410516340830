import * as React from 'react'

import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import GlobeView from '../../models/GlobeView'
import Sitemap from '../../models/Sitemap'
import EventsStore from '../../stores/EventStore/Events.store'
import GlobeViewsStore from '../../stores/domain/GlobeViews.store'
import SitemapsStore from '../../stores/domain/Sitemaps.store'
import FileInputBase from '../FileInput/FileInput'
import GeoreferencingLabel from '../GeoreferencingLabel/GeoreferenceingLabel'
import GlobeDeliveryAttributes from '../SitemapDeliveryAttributes/GlobeAttributes'
import SitemapDeliveryAttributes from '../SitemapDeliveryAttributes/SitemapDeliveryAttributes'
import { IMapConfiguration } from '../SitemapsGallery/MapViewsGallery'
import StruxhubInput from '../StruxhubInputs/StruxhubInput'

import './CompactMapViewPicker.scss'

const SortableItem = SortableElement(({ item }) => item)

const SortableList = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((item, index) => (
        <SortableItem key={`item-${item.key}`} index={index} item={item} />
      ))}
    </div>
  )
})

interface IProps {
  onClose: () => void
  onGlobeApply: (globe: GlobeView) => void
  onWhiteboardApply: (whiteboard: Sitemap) => void
  mapIds: IMapConfiguration[]
  selectedWhiteboardId: string
  selectedGlobeId: string
  reassignMapOrders?: ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: any
    newIndex: any
  }) => void

  FileInputType: typeof FileInputBase
  globeViewsStore?: GlobeViewsStore
  sitemapsStore?: SitemapsStore
  eventsStore?: EventsStore
  isCompact?: boolean
}

const doneTypingInterval = 300

@inject('eventsStore', 'globeViewsStore', 'sitemapsStore')
@observer
export default class CompactMapViewPicker extends React.Component<IProps> {
  @observable public searchKey: string = ''

  public constructor(props: IProps) {
    super(props)
  }

  public render() {
    const { onClose } = this.props
    return (
      <>
        <div onClick={onClose} className="dimmer shown" />
        <div className="sitemap-picker-holder col filter-holder bb-light-grey shown">
          {this.renderHeader()}
          {this.renderSearchBox()}
          <div className="scrollable sitemaps-options-list">
            {this.renderContent()}
          </div>
        </div>
      </>
    )
  }

  private renderHeader() {
    const { onClose } = this.props
    return (
      <div className="row pa10 bb-brand-dark sitemaps-options-header ">
        <div className="col no-grow">
          <Icons.Cross
            className="cross-icon no-grow row y-center"
            onClick={onClose}
          />
        </div>
        <div className="col x-center text large bold mr20">
          {Localization.translator.jumpToMap}
        </div>
      </div>
    )
  }

  private renderContent() {
    const { reassignMapOrders } = this.props

    return (
      <SortableList
        shouldCancelStart={this.shouldCancelStart}
        items={this.mapItems}
        onSortEnd={reassignMapOrders}
        lockAxis="y"
      />
    )
  }

  private get filteredMaps(): (GlobeView | Sitemap)[] {
    const { mapIds, globeViewsStore, sitemapsStore } = this.props
    const key = this.searchKey.toLowerCase()
    return mapIds
      .map(id => {
        return id.globeViewId
          ? globeViewsStore.byId.get(id.globeViewId)
          : id.sitemapId
          ? sitemapsStore.byId.get(id.sitemapId)
          : null
      })
      .filter(({ name }) => name.toLowerCase().includes(key))
  }

  private renderGlobeViewItem(globe: GlobeView) {
    const { FileInputType, isCompact } = this.props
    const isSelected = this.isGlobeSelected(globe.id)
    return (
      <div
        className={classList({
          'row sitemap-option pa10 bb-light-grey z-index-100': true,
          selected: isSelected,
        })}
        key={globe.id}
        onClick={this.onGlobeSelect.bind(this, globe)}
      >
        <div
          style={{ maxWidth: 80, maxHeight: 60, minWidth: 80, minHeight: 60 }}
        >
          <FileInputType
            id={globe.id}
            name=""
            value={globe.filledImage}
            isReadonly={true}
            textClassName="hint"
            shouldHideIconAndOutline={true}
            shouldHideName={true}
          />
        </div>
        <div className="ml20 name-line relative">
          <div className="text large bold text-ellipsis full-width">
            {globe.name}
          </div>
          <GlobeDeliveryAttributes
            globe={globe}
            shouldHideCrossIcons={true}
            areTagsBordered={true}
            className="row wrap full-width x-start y-start tags-holder"
          />
        </div>
        <GeoreferencingLabel
          className="no-grow"
          isReferenced={true}
          isCompact={isCompact}
        />
        <Icons.CheckEmptyBlue
          className={classList({ 'no-grow': true, transparent: !isSelected })}
        />
      </div>
    )
  }

  private renderWhiteboardItem(whiteboard: Sitemap) {
    const { FileInputType, isCompact } = this.props
    const isSelected = this.isWhiteboardSelected(whiteboard.id)
    return (
      <div
        className={classList({
          'row sitemap-option pa10 bb-light-grey z-index-100': true,
          selected: isSelected,
        })}
        key={whiteboard.id}
        onClick={this.onWhiteboardSelect.bind(this, whiteboard)}
      >
        <div
          style={{ maxWidth: 80, maxHeight: 60, minWidth: 80, minHeight: 60 }}
        >
          <FileInputType
            id={whiteboard.id}
            name=""
            value={whiteboard.filledImage}
            isReadonly={true}
            textClassName="hint"
            shouldHideIconAndOutline={true}
            shouldHideName={true}
          />
        </div>
        <div className="ml20 name-line relative">
          <div className="text large bold text-ellipsis full-width">
            {whiteboard.name}
          </div>
          <SitemapDeliveryAttributes
            sitemap={whiteboard}
            shouldHideCrossIcons={true}
            areTagsBordered={true}
            className="row wrap full-width x-start y-start tags-holder"
          />
        </div>
        <GeoreferencingLabel
          className="no-grow"
          isReferenced={false}
          isCompact={isCompact}
        />
        <Icons.CheckEmptyBlue
          className={classList({ 'no-grow': true, transparent: !isSelected })}
        />
      </div>
    )
  }

  @computed
  private get mapItems() {
    return this.filteredMaps.map(m => {
      if (m instanceof GlobeView) {
        return this.renderGlobeViewItem(m)
      } else {
        return this.renderWhiteboardItem(m)
      }
    })
  }

  private isGlobeSelected = (id: string): boolean => {
    return id === this.props.selectedGlobeId
  }

  private isWhiteboardSelected = (id: string): boolean => {
    return id === this.props.selectedWhiteboardId
  }

  private onGlobeSelect = (
    globe: GlobeView,
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    event.stopPropagation()
    this.props.onGlobeApply(globe)
  }

  private onWhiteboardSelect = (
    sitemap: Sitemap,
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    event.stopPropagation()
    this.props.onWhiteboardApply(sitemap)
  }

  private renderSearchBox() {
    return (
      <div className="row">
        <StruxhubInput
          isMinimalisticMode={true}
          placeholder={Localization.translator.search}
          value={this.searchKey}
          onChange={this.updateSearchKey}
          withDelay={true}
          inputDelay={doneTypingInterval}
        />
      </div>
    )
  }

  @action.bound
  private updateSearchKey(event: React.ChangeEvent<HTMLInputElement>) {
    this.searchKey = event.target.value
  }

  private shouldCancelStart = () => {
    return !this.props.eventsStore.appState.userActiveProjectSettings?.isAdmin
  }
}

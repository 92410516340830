/**
 * Here we get the globally inject config object from webpack, merge it into an
 * empty object and export it for use
 *
 */

import mapboxgl from 'mapbox-gl'

import isTenantLessMode from './utils/isTenantLessMode'

interface IFirebaseSSOProvider {
  providerId: string
  displayName: string
  type: string
}

export interface IAppConfig {
  // client section
  REDIRECT_CONFIG: {
    isDesktopVersion: boolean
    redirects: Array<{
      from: string
      to: string
    }>
  }

  TENANTLESS_MODE: boolean
  SUBDOMAIN: string
  BASE_URL: string

  // remote section
  ENVIRONMENT: 'development' | 'qa' | 'production' | 'staging'
  GOOGLE_ANALYTICS_ID: string

  FIREBASE_CONFIG: object
  MAPBOX_TOKEN: string
  IPAPI_KEY: string
  TENANT_ID: string
  AUTH_EXTERNAL_URL: string
  firebaseSSOProviders?: IFirebaseSSOProvider[]

  // webpack section
  VERSION: string
  BUILD: string
  REVISION: string
  DEPLOYMENT_VERSION: string

  // client service section
  READY: Promise<void>
}

const REMOTE_CONFIGURATION_PATH = '/configuration'

const redirectConfig = {
  isDesktopVersion: false,
  redirects: [],
}

export const baseUrl = location.host
  ? `${location.protocol}//${location.host}`
  : location.origin

export const remoteProdDomain = 'struxhub.com'
export const remoteTestDomain = 'struxsite.com'

const desktopPort = ':3000'
const mobilePort = ':3002'

const { host } = location
export const isRemote = [remoteProdDomain, remoteTestDomain].some(d =>
  host.includes(d),
)

if (isRemote) {
  const hostParts = location.host.split('.')
  const subdomain = hostParts[0]
  hostParts.splice(0, 1)
  const domain = hostParts.join('.')
  const tenant = subdomain.replace('-mobile', '')

  const desktopUrl = `${tenant}.${domain}`
  const mobileUrl = `${tenant}-mobile.${domain}`

  if (!subdomain.endsWith('-mobile')) {
    redirectConfig.isDesktopVersion = true
    redirectConfig.redirects = [
      {
        from: desktopUrl,
        to: mobileUrl,
      },
    ]
  } else {
    redirectConfig.isDesktopVersion = false
    redirectConfig.redirects = [
      {
        from: mobileUrl,
        to: desktopUrl,
      },
    ]
  }
} else if (location.host.includes(desktopPort)) {
  redirectConfig.isDesktopVersion = true
  redirectConfig.redirects = [
    {
      from: desktopPort,
      to: mobilePort,
    },
  ]
} else {
  redirectConfig.isDesktopVersion = false
  redirectConfig.redirects = [
    {
      from: mobilePort,
      to: desktopPort,
    },
  ]
}

const TENANT_CONFIGURATION: IAppConfig = {
  REDIRECT_CONFIG: redirectConfig,
  BASE_URL: host.includes(remoteProdDomain)
    ? remoteProdDomain
    : remoteTestDomain,
  TENANTLESS_MODE: isTenantLessMode({
    prod: remoteProdDomain,
    dev: remoteTestDomain,
  }),
} as IAppConfig

TENANT_CONFIGURATION.READY = globalThis.document
  ? fetch(REMOTE_CONFIGURATION_PATH)
      .then(response => response.json())
      .then(remoteConfig => {
        mapboxgl.accessToken = remoteConfig.MAPBOX_TOKEN

        Object.assign(
          TENANT_CONFIGURATION,
          remoteConfig,
          // !CONFIG => webpack config {REVISION, BUILD, ... } (check "app-version.config.js")
          typeof CONFIG !== 'undefined' ? CONFIG : {},
        )
      })
      .catch(error =>
        console.error(
          `We can not retrieve application configuration: ${error}`,
        ),
      )
  : Promise.resolve()

export default TENANT_CONFIGURATION

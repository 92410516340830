import { action } from 'mobx'

import {
  CheckUserByPhoneNumberDocument,
  ResendInviteKeyDocument,
} from '~/client/graph/operations/generated/Auth.generated'
import Config from '~/client/src/shared/Config'
import InfoCode from '~/client/src/shared/enums/InfoCode'
import ApiHelper, { IResponseData } from '~/client/src/shared/utils/ApiHelper'

import AuthEndpoints from '../enums/AuthEndpoints'
import { GrantTypesEnum } from '../enums/GrantTypes'
import Localization from '../localization/LocalizationManager'
import InitialState from '../stores/InitialState'
import { ITenant } from '../stores/domain/Tenants.store'
import GraphExecutor from '../utils/GraphExecutor'
import { isRefreshTokenValid, isTokenAboutToExpire } from '../utils/JwtHelper'

// Do not translate. These are error messages from the API
const INVALID_PHONE_NUMBER = 'Invalid phone number'
const PHONE_NUMBER_NOT_FOUND_REGEX = /phone number \+?(\d+) not found/

interface IAuth {
  grantType: GrantTypes
}

export interface EmailAuth extends IAuth {
  email: string
  password: string
}

export interface PhoneAuth extends IAuth {
  phone: string
  oneTimeCode: string
  nonsense: string
}

interface IFreshToken {
  refreshToken: string
  grantType: GrantTypes
}

interface IAuthenticateResult {
  accessToken: string
  refreshToken: string
  expiresIn: string
}

type GrantTypes = `${GrantTypesEnum}`
export default class ApiAuthService {
  public accessToken: string = null

  public constructor(
    private readonly state: InitialState,
    private readonly graphExecutor: GraphExecutor,
  ) {}

  public async loginWithInviteKey(inviteKey?: string): Promise<IResponseData> {
    if (!inviteKey) {
      throw new Error(InfoCode.INVALID_INVITE_TOKEN.toString())
    }

    const response = await fetch(
      Config.AUTH_EXTERNAL_URL + AuthEndpoints.INVITE_KEY,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Tenant-Name': Config.TENANT_ID,
        },
        body: JSON.stringify({ inviteKey }),
      },
    )

    if (response.ok) {
      return ApiHelper.toResponseData(response)
    }

    throw new Error(response.status.toString())
  }

  public async getInviteKey(
    accessToken: string,
    tenantHostName?: string,
  ): Promise<string> {
    const response = await fetch(
      Config.AUTH_EXTERNAL_URL + AuthEndpoints.INVITE_KEY,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Tenant-Name': Config.TENANT_ID,
          TenantHost: tenantHostName, // Will be ignored if absent
        },
      },
    )

    if (response.ok) {
      return (await ApiHelper.toResponseData(response)).data
    }

    throw new Error(response.status.toString())
  }

  public async checkUserByPhoneNumber(phoneNumber: string): Promise<boolean> {
    const res = await this.graphExecutor.executeQuery(
      CheckUserByPhoneNumberDocument,
      { phoneNumber },
    )

    return !!res.data?.checkPhoneNumber
  }

  public async resendInviteKey(expiredInviteKey: string): Promise<void> {
    const res = await this.graphExecutor.executeMutation(
      ResendInviteKeyDocument,
      { inviteKey: expiredInviteKey, projectCode: this.state.initProjectCode },
    )

    if (!res.data?.resendInviteKey) {
      throw new Error(
        Localization.translator.somethingWentWrongDuringAPIInteraction,
      )
    }
  }

  //#region Tenantless Auth
  public async getFreshAccessToken(): Promise<IAuthenticateResult> {
    const user = this.state.tenantUserSession
    if (!user) {
      return null
    }

    const isValid = isRefreshTokenValid(user.accessToken)
    let result: IAuthenticateResult = null

    if (isValid) {
      try {
        result = await this.getFreshStruxhubTokens({
          refreshToken: user.refreshToken,
          grantType: GrantTypesEnum.refresh_token,
        })
      } catch (error) {
        console.error(error)
      }
    } else {
      throw new Error('Invalid refresh token')
    }

    return result
  }

  @action.bound
  public async updateAccessToken(): Promise<string> {
    const result = await this.getFreshAccessToken()
    if (!result) {
      return null
    }

    this.state.tenantUserSession.refreshToken = result.refreshToken
    this.state.tenantUserSession.accessToken = result.accessToken
    this.setAccessToken(result.accessToken)

    return result.accessToken
  }

  public getValidAccessToken = async (): Promise<string> => {
    if (!this.accessToken) {
      this.setAccessToken(this.state.tenantUserSession?.accessToken)
    }

    if (this.accessToken && isTokenAboutToExpire(this.accessToken)) {
      return this.updateAccessToken()
    }
    return this.accessToken
  }

  @action.bound
  public setAccessToken(token: string) {
    this.accessToken = token
  }

  @action.bound
  public resetAccessToken() {
    this.accessToken = null
  }

  public async sendOneTimeCode(phoneNumber: string): Promise<IOneTimeCode> {
    const url = Config.AUTH_EXTERNAL_URL + '/send-otc'
    const body = JSON.stringify({ phoneNumber })
    const headers = {
      'Content-Type': 'application/json;charset=utf-8',
    }
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body,
    })
    if (response.ok) {
      return response.json()
    }

    if (response?.status >= 400) {
      const responseTxt = await response.text()
      if (
        !responseTxt ||
        responseTxt.includes(INVALID_PHONE_NUMBER) ||
        !!responseTxt.match(PHONE_NUMBER_NOT_FOUND_REGEX)
      ) {
        const errorMsg = `sendOneTimeCode failed with ${response.status} HTTP status code and the following message: ${responseTxt}`
        console.error(errorMsg)
      }
    }
    return null
  }

  public logInViaStruxhub(
    authData: EmailAuth | PhoneAuth,
  ): Promise<IAuthenticateResult> {
    return this.authenticate(authData, '/authenticate')
  }

  public getFreshStruxhubTokens(
    authData: IFreshToken,
  ): Promise<IAuthenticateResult> {
    return this.authenticate(authData, '/authenticate')
  }

  private async authenticate(
    data: IAuth | IFreshToken,
    path: string,
    additionalHeaders?: Record<string, string>,
  ): Promise<IAuthenticateResult> {
    const response = await fetch(Config.AUTH_EXTERNAL_URL + path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        ...additionalHeaders,
      },
      body: JSON.stringify(data),
    })
    if (response.ok) {
      const result = await response.json()
      return result
    }

    return null
  }

  public async getTenantsForUser(accessToken: string): Promise<Array<ITenant>> {
    const url = Config.AUTH_EXTERNAL_URL + '/tenants'
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      },
    }

    const tenantsResponse = await fetch(url, options)
    if (!tenantsResponse.ok) {
      throw new Error(tenantsResponse.status.toString())
    }

    const result = await tenantsResponse.json()
    const tenants: ITenant[] = []
    for (const [key, value] of Object.entries(result)) {
      tenants.push({ name: key, host: value as string })
    }

    // Allow testing tenantless on localhost
    if (Config.TENANTLESS_MODE && location.hostname === 'localhost') {
      const localhost: ITenant = {
        name: 'Local Host',
        host: location.host, // include port
      }
      tenants.push(localhost)
    }

    return tenants
  }
}

interface ISuccessfulOneTimeCodeResponse {
  nonsense: string
  error?: never
}
interface IFailedOneTimeCodeResponse {
  error: number | string
  nonsense?: never
}
type IOneTimeCode =
  | ISuccessfulOneTimeCodeResponse
  | IFailedOneTimeCodeResponse
  | null

//#endregion

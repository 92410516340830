import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ICompanyFieldsFragment = Pick<
  Types.ICompany,
  | 'id'
  | 'struxHubCompanyId'
  | 'name'
  | 'projectId'
  | 'typeTags'
  | 'code'
  | 'isDeleted'
  | 'businessEmail'
  | 'businessPhone'
  | 'responsibleContactIds'
  | 'roleIds'
  | 'tradeIds'
  | 'reportsToId'
  | 'logoUrl'
  | 'avatarUrl'
  | 'dba'
  | 'licenseNumber'
> & {
  address?: Types.Maybe<
    Pick<
      Types.ICompanyAddress,
      'address' | 'country' | 'city' | 'state' | 'zipCode'
    >
  >
}

export type ICompaniesByProjectIdQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type ICompaniesByProjectIdQuery = {
  companies?: Types.Maybe<{ data: Array<ICompanyFieldsFragment> }>
}

export type ICompaniesByProjectCodeAndNameSubstrQueryVariables = Types.Exact<{
  projectCode?: Types.Maybe<Types.Scalars['String']>
  searchString?: Types.Maybe<Types.Scalars['String']>
}>

export type ICompaniesByProjectCodeAndNameSubstrQuery = {
  companiesBasicInfo?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.ICompanyBasicInfo, 'companyId' | 'name'>>>
  >
}

export type ICompanyTypeTagsQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type ICompanyTypeTagsQuery = {
  companyTypeTags?: Types.Maybe<{
    data: Array<Pick<Types.IEntityTag, 'id' | 'value' | 'color'>>
  }>
}

export type IStruxHubCompaniesQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type IStruxHubCompaniesQuery = {
  struxHubCompanies?: Types.Maybe<{
    data: Array<Pick<Types.IStruxHubCompany, 'id' | 'name'>>
  }>
}

export type IStruxHubCompanyQueryVariables = Types.Exact<{
  struxHubCompanyId: Types.Scalars['ObjectId']
}>

export type IStruxHubCompanyQuery = {
  struxHubCompany?: Types.Maybe<Pick<Types.IStruxHubCompany, 'id' | 'name'>>
}

export type IListenCompanyTypeTagSubscriptionVariables = Types.Exact<{
  id?: Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IListenCompanyTypeTagSubscription = {
  companyTypeTag?: Types.Maybe<
    Pick<Types.IEntityTagChangeEvent, 'id'> & {
      item?: Types.Maybe<Pick<Types.IEntityTag, 'id' | 'value'>>
    }
  >
}

export type IListenCompaniesByProjectIdSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenCompaniesByProjectIdSubscription = {
  company?: Types.Maybe<
    Pick<Types.ICompanyChangeEvent, 'id'> & {
      item?: Types.Maybe<ICompanyFieldsFragment>
    }
  >
}

export type ISaveManyCompaniesMutationVariables = Types.Exact<{
  companies:
    | Array<Types.Maybe<Types.ICompanyInput>>
    | Types.Maybe<Types.ICompanyInput>
}>

export type ISaveManyCompaniesMutation = {
  saveManyCompanies?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.ICompany, 'id' | 'name'>>>
  >
}

export type IDeleteCompaniesMutationVariables = Types.Exact<{
  companyIds:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteCompaniesMutation = Pick<
  Types.IMutation,
  'softDeleteManyCompanies'
>

export type IActivateCompaniesMutationVariables = Types.Exact<{
  companyIds:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IActivateCompaniesMutation = Pick<
  Types.IMutation,
  'recoverManyCompanies'
>

export type IMergeCompaniesMutationVariables = Types.Exact<{
  companyIds: Array<Types.Scalars['ObjectId']> | Types.Scalars['ObjectId']
  targetCompany: Types.ICompanyInput
}>

export type IMergeCompaniesMutation = {
  mergeCompanies?: Types.Maybe<
    Pick<
      Types.IMergeResult,
      | 'activitiesUpdates'
      | 'activityAssignmentsUpdates'
      | 'activityListCustomFiltersUpdates'
      | 'announcementAssignmentsUpdates'
      | 'castAssignmentsUpdates'
      | 'closureAssignmentsUpdates'
      | 'companyRelationshipsUpdates'
      | 'deliveriesUpdates'
      | 'deliveryAssignmentsUpdates'
      | 'deliveryListCustomFiltersUpdates'
      | 'operationRulesUpdates'
      | 'sitePermitAssignmentsUpdates'
      | 'sitePermitsUpdates'
      | 'userProjectsUpdates'
      | 'whiteListsUpdates'
      | 'workflowsCustomFiltersUpdates'
      | 'locationsUpdates'
    >
  >
}

export type ILinkStruxHubCompanyMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ObjectId']
  struxHubCompanyId: Types.Scalars['ObjectId']
}>

export type ILinkStruxHubCompanyMutation = {
  linkStruxHubCompany?: Types.Maybe<ICompanyFieldsFragment>
}

export type IUnlinkStruxHubCompanyMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ObjectId']
}>

export type IUnlinkStruxHubCompanyMutation = {
  unlinkStruxHubCompany?: Types.Maybe<Pick<Types.ICompany, 'id'>>
}

export const CompanyFieldsFragmentDoc = gql`
  fragment CompanyFields on Company {
    id
    struxHubCompanyId
    name
    projectId
    typeTags
    code
    isDeleted
    address {
      address
      country
      city
      state
      zipCode
    }
    businessEmail
    businessPhone
    responsibleContactIds
    roleIds
    tradeIds
    reportsToId
    logoUrl
    avatarUrl
    dba
    licenseNumber
  }
`
export const CompaniesByProjectIdDocument = gql`
  query CompaniesByProjectId($projectId: ObjectId!) {
    companies(projectId: $projectId, limit: 1000000) {
      data {
        ...CompanyFields
      }
    }
  }
  ${CompanyFieldsFragmentDoc}
`

/**
 * __useCompaniesByProjectIdQuery__
 *
 * To run a query within a React component, call `useCompaniesByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCompaniesByProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ICompaniesByProjectIdQuery,
    ICompaniesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ICompaniesByProjectIdQuery,
    ICompaniesByProjectIdQueryVariables
  >(CompaniesByProjectIdDocument, options)
}
export function useCompaniesByProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICompaniesByProjectIdQuery,
    ICompaniesByProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ICompaniesByProjectIdQuery,
    ICompaniesByProjectIdQueryVariables
  >(CompaniesByProjectIdDocument, options)
}
export type CompaniesByProjectIdQueryHookResult = ReturnType<
  typeof useCompaniesByProjectIdQuery
>
export type CompaniesByProjectIdLazyQueryHookResult = ReturnType<
  typeof useCompaniesByProjectIdLazyQuery
>
export type CompaniesByProjectIdQueryResult = Apollo.QueryResult<
  ICompaniesByProjectIdQuery,
  ICompaniesByProjectIdQueryVariables
>
export const CompaniesByProjectCodeAndNameSubstrDocument = gql`
  query CompaniesByProjectCodeAndNameSubstr(
    $projectCode: String
    $searchString: String
  ) {
    companiesBasicInfo(projectCode: $projectCode, searchString: $searchString) {
      companyId
      name
    }
  }
`

/**
 * __useCompaniesByProjectCodeAndNameSubstrQuery__
 *
 * To run a query within a React component, call `useCompaniesByProjectCodeAndNameSubstrQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesByProjectCodeAndNameSubstrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesByProjectCodeAndNameSubstrQuery({
 *   variables: {
 *      projectCode: // value for 'projectCode'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useCompaniesByProjectCodeAndNameSubstrQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ICompaniesByProjectCodeAndNameSubstrQuery,
    ICompaniesByProjectCodeAndNameSubstrQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ICompaniesByProjectCodeAndNameSubstrQuery,
    ICompaniesByProjectCodeAndNameSubstrQueryVariables
  >(CompaniesByProjectCodeAndNameSubstrDocument, options)
}
export function useCompaniesByProjectCodeAndNameSubstrLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICompaniesByProjectCodeAndNameSubstrQuery,
    ICompaniesByProjectCodeAndNameSubstrQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ICompaniesByProjectCodeAndNameSubstrQuery,
    ICompaniesByProjectCodeAndNameSubstrQueryVariables
  >(CompaniesByProjectCodeAndNameSubstrDocument, options)
}
export type CompaniesByProjectCodeAndNameSubstrQueryHookResult = ReturnType<
  typeof useCompaniesByProjectCodeAndNameSubstrQuery
>
export type CompaniesByProjectCodeAndNameSubstrLazyQueryHookResult = ReturnType<
  typeof useCompaniesByProjectCodeAndNameSubstrLazyQuery
>
export type CompaniesByProjectCodeAndNameSubstrQueryResult = Apollo.QueryResult<
  ICompaniesByProjectCodeAndNameSubstrQuery,
  ICompaniesByProjectCodeAndNameSubstrQueryVariables
>
export const CompanyTypeTagsDocument = gql`
  query CompanyTypeTags {
    companyTypeTags {
      data {
        id
        value
        color
      }
    }
  }
`

/**
 * __useCompanyTypeTagsQuery__
 *
 * To run a query within a React component, call `useCompanyTypeTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTypeTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTypeTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyTypeTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ICompanyTypeTagsQuery,
    ICompanyTypeTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ICompanyTypeTagsQuery, ICompanyTypeTagsQueryVariables>(
    CompanyTypeTagsDocument,
    options,
  )
}
export function useCompanyTypeTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICompanyTypeTagsQuery,
    ICompanyTypeTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ICompanyTypeTagsQuery,
    ICompanyTypeTagsQueryVariables
  >(CompanyTypeTagsDocument, options)
}
export type CompanyTypeTagsQueryHookResult = ReturnType<
  typeof useCompanyTypeTagsQuery
>
export type CompanyTypeTagsLazyQueryHookResult = ReturnType<
  typeof useCompanyTypeTagsLazyQuery
>
export type CompanyTypeTagsQueryResult = Apollo.QueryResult<
  ICompanyTypeTagsQuery,
  ICompanyTypeTagsQueryVariables
>
export const StruxHubCompaniesDocument = gql`
  query StruxHubCompanies {
    struxHubCompanies(limit: 10000) {
      data {
        id
        name
      }
    }
  }
`

/**
 * __useStruxHubCompaniesQuery__
 *
 * To run a query within a React component, call `useStruxHubCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStruxHubCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStruxHubCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStruxHubCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IStruxHubCompaniesQuery,
    IStruxHubCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IStruxHubCompaniesQuery,
    IStruxHubCompaniesQueryVariables
  >(StruxHubCompaniesDocument, options)
}
export function useStruxHubCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IStruxHubCompaniesQuery,
    IStruxHubCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IStruxHubCompaniesQuery,
    IStruxHubCompaniesQueryVariables
  >(StruxHubCompaniesDocument, options)
}
export type StruxHubCompaniesQueryHookResult = ReturnType<
  typeof useStruxHubCompaniesQuery
>
export type StruxHubCompaniesLazyQueryHookResult = ReturnType<
  typeof useStruxHubCompaniesLazyQuery
>
export type StruxHubCompaniesQueryResult = Apollo.QueryResult<
  IStruxHubCompaniesQuery,
  IStruxHubCompaniesQueryVariables
>
export const StruxHubCompanyDocument = gql`
  query StruxHubCompany($struxHubCompanyId: ObjectId!) {
    struxHubCompany(id: $struxHubCompanyId) {
      id
      name
    }
  }
`

/**
 * __useStruxHubCompanyQuery__
 *
 * To run a query within a React component, call `useStruxHubCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useStruxHubCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStruxHubCompanyQuery({
 *   variables: {
 *      struxHubCompanyId: // value for 'struxHubCompanyId'
 *   },
 * });
 */
export function useStruxHubCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    IStruxHubCompanyQuery,
    IStruxHubCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IStruxHubCompanyQuery, IStruxHubCompanyQueryVariables>(
    StruxHubCompanyDocument,
    options,
  )
}
export function useStruxHubCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IStruxHubCompanyQuery,
    IStruxHubCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IStruxHubCompanyQuery,
    IStruxHubCompanyQueryVariables
  >(StruxHubCompanyDocument, options)
}
export type StruxHubCompanyQueryHookResult = ReturnType<
  typeof useStruxHubCompanyQuery
>
export type StruxHubCompanyLazyQueryHookResult = ReturnType<
  typeof useStruxHubCompanyLazyQuery
>
export type StruxHubCompanyQueryResult = Apollo.QueryResult<
  IStruxHubCompanyQuery,
  IStruxHubCompanyQueryVariables
>
export const ListenCompanyTypeTagDocument = gql`
  subscription ListenCompanyTypeTag($id: ObjectId) {
    companyTypeTag(id: $id) {
      id
      item {
        id
        value
      }
    }
  }
`

/**
 * __useListenCompanyTypeTagSubscription__
 *
 * To run a query within a React component, call `useListenCompanyTypeTagSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenCompanyTypeTagSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenCompanyTypeTagSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListenCompanyTypeTagSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    IListenCompanyTypeTagSubscription,
    IListenCompanyTypeTagSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenCompanyTypeTagSubscription,
    IListenCompanyTypeTagSubscriptionVariables
  >(ListenCompanyTypeTagDocument, options)
}
export type ListenCompanyTypeTagSubscriptionHookResult = ReturnType<
  typeof useListenCompanyTypeTagSubscription
>
export type ListenCompanyTypeTagSubscriptionResult =
  Apollo.SubscriptionResult<IListenCompanyTypeTagSubscription>
export const ListenCompaniesByProjectIdDocument = gql`
  subscription ListenCompaniesByProjectId($projectId: ObjectId!) {
    company(projectId: $projectId) {
      id
      item {
        ...CompanyFields
      }
    }
  }
  ${CompanyFieldsFragmentDoc}
`

/**
 * __useListenCompaniesByProjectIdSubscription__
 *
 * To run a query within a React component, call `useListenCompaniesByProjectIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenCompaniesByProjectIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenCompaniesByProjectIdSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenCompaniesByProjectIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenCompaniesByProjectIdSubscription,
    IListenCompaniesByProjectIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenCompaniesByProjectIdSubscription,
    IListenCompaniesByProjectIdSubscriptionVariables
  >(ListenCompaniesByProjectIdDocument, options)
}
export type ListenCompaniesByProjectIdSubscriptionHookResult = ReturnType<
  typeof useListenCompaniesByProjectIdSubscription
>
export type ListenCompaniesByProjectIdSubscriptionResult =
  Apollo.SubscriptionResult<IListenCompaniesByProjectIdSubscription>
export const SaveManyCompaniesDocument = gql`
  mutation SaveManyCompanies($companies: [CompanyInput]!) {
    saveManyCompanies(companies: $companies) {
      id
      name
    }
  }
`
export type ISaveManyCompaniesMutationFn = Apollo.MutationFunction<
  ISaveManyCompaniesMutation,
  ISaveManyCompaniesMutationVariables
>

/**
 * __useSaveManyCompaniesMutation__
 *
 * To run a mutation, you first call `useSaveManyCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveManyCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveManyCompaniesMutation, { data, loading, error }] = useSaveManyCompaniesMutation({
 *   variables: {
 *      companies: // value for 'companies'
 *   },
 * });
 */
export function useSaveManyCompaniesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveManyCompaniesMutation,
    ISaveManyCompaniesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveManyCompaniesMutation,
    ISaveManyCompaniesMutationVariables
  >(SaveManyCompaniesDocument, options)
}
export type SaveManyCompaniesMutationHookResult = ReturnType<
  typeof useSaveManyCompaniesMutation
>
export type SaveManyCompaniesMutationResult =
  Apollo.MutationResult<ISaveManyCompaniesMutation>
export type SaveManyCompaniesMutationOptions = Apollo.BaseMutationOptions<
  ISaveManyCompaniesMutation,
  ISaveManyCompaniesMutationVariables
>
export const DeleteCompaniesDocument = gql`
  mutation DeleteCompanies($companyIds: [ObjectId]!) {
    softDeleteManyCompanies(ids: $companyIds)
  }
`
export type IDeleteCompaniesMutationFn = Apollo.MutationFunction<
  IDeleteCompaniesMutation,
  IDeleteCompaniesMutationVariables
>

/**
 * __useDeleteCompaniesMutation__
 *
 * To run a mutation, you first call `useDeleteCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompaniesMutation, { data, loading, error }] = useDeleteCompaniesMutation({
 *   variables: {
 *      companyIds: // value for 'companyIds'
 *   },
 * });
 */
export function useDeleteCompaniesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteCompaniesMutation,
    IDeleteCompaniesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteCompaniesMutation,
    IDeleteCompaniesMutationVariables
  >(DeleteCompaniesDocument, options)
}
export type DeleteCompaniesMutationHookResult = ReturnType<
  typeof useDeleteCompaniesMutation
>
export type DeleteCompaniesMutationResult =
  Apollo.MutationResult<IDeleteCompaniesMutation>
export type DeleteCompaniesMutationOptions = Apollo.BaseMutationOptions<
  IDeleteCompaniesMutation,
  IDeleteCompaniesMutationVariables
>
export const ActivateCompaniesDocument = gql`
  mutation ActivateCompanies($companyIds: [ObjectId]!) {
    recoverManyCompanies(ids: $companyIds)
  }
`
export type IActivateCompaniesMutationFn = Apollo.MutationFunction<
  IActivateCompaniesMutation,
  IActivateCompaniesMutationVariables
>

/**
 * __useActivateCompaniesMutation__
 *
 * To run a mutation, you first call `useActivateCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCompaniesMutation, { data, loading, error }] = useActivateCompaniesMutation({
 *   variables: {
 *      companyIds: // value for 'companyIds'
 *   },
 * });
 */
export function useActivateCompaniesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IActivateCompaniesMutation,
    IActivateCompaniesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IActivateCompaniesMutation,
    IActivateCompaniesMutationVariables
  >(ActivateCompaniesDocument, options)
}
export type ActivateCompaniesMutationHookResult = ReturnType<
  typeof useActivateCompaniesMutation
>
export type ActivateCompaniesMutationResult =
  Apollo.MutationResult<IActivateCompaniesMutation>
export type ActivateCompaniesMutationOptions = Apollo.BaseMutationOptions<
  IActivateCompaniesMutation,
  IActivateCompaniesMutationVariables
>
export const MergeCompaniesDocument = gql`
  mutation MergeCompanies(
    $companyIds: [ObjectId!]!
    $targetCompany: CompanyInput!
  ) {
    mergeCompanies(companyIds: $companyIds, targetCompany: $targetCompany) {
      activitiesUpdates
      activityAssignmentsUpdates
      activityListCustomFiltersUpdates
      announcementAssignmentsUpdates
      castAssignmentsUpdates
      closureAssignmentsUpdates
      companyRelationshipsUpdates
      deliveriesUpdates
      deliveryAssignmentsUpdates
      deliveryListCustomFiltersUpdates
      operationRulesUpdates
      sitePermitAssignmentsUpdates
      sitePermitsUpdates
      userProjectsUpdates
      whiteListsUpdates
      workflowsCustomFiltersUpdates
      locationsUpdates
    }
  }
`
export type IMergeCompaniesMutationFn = Apollo.MutationFunction<
  IMergeCompaniesMutation,
  IMergeCompaniesMutationVariables
>

/**
 * __useMergeCompaniesMutation__
 *
 * To run a mutation, you first call `useMergeCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeCompaniesMutation, { data, loading, error }] = useMergeCompaniesMutation({
 *   variables: {
 *      companyIds: // value for 'companyIds'
 *      targetCompany: // value for 'targetCompany'
 *   },
 * });
 */
export function useMergeCompaniesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IMergeCompaniesMutation,
    IMergeCompaniesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IMergeCompaniesMutation,
    IMergeCompaniesMutationVariables
  >(MergeCompaniesDocument, options)
}
export type MergeCompaniesMutationHookResult = ReturnType<
  typeof useMergeCompaniesMutation
>
export type MergeCompaniesMutationResult =
  Apollo.MutationResult<IMergeCompaniesMutation>
export type MergeCompaniesMutationOptions = Apollo.BaseMutationOptions<
  IMergeCompaniesMutation,
  IMergeCompaniesMutationVariables
>
export const LinkStruxHubCompanyDocument = gql`
  mutation LinkStruxHubCompany(
    $companyId: ObjectId!
    $struxHubCompanyId: ObjectId!
  ) {
    linkStruxHubCompany(
      companyId: $companyId
      struxHubCompanyId: $struxHubCompanyId
    ) {
      ...CompanyFields
    }
  }
  ${CompanyFieldsFragmentDoc}
`
export type ILinkStruxHubCompanyMutationFn = Apollo.MutationFunction<
  ILinkStruxHubCompanyMutation,
  ILinkStruxHubCompanyMutationVariables
>

/**
 * __useLinkStruxHubCompanyMutation__
 *
 * To run a mutation, you first call `useLinkStruxHubCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkStruxHubCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkStruxHubCompanyMutation, { data, loading, error }] = useLinkStruxHubCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      struxHubCompanyId: // value for 'struxHubCompanyId'
 *   },
 * });
 */
export function useLinkStruxHubCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ILinkStruxHubCompanyMutation,
    ILinkStruxHubCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ILinkStruxHubCompanyMutation,
    ILinkStruxHubCompanyMutationVariables
  >(LinkStruxHubCompanyDocument, options)
}
export type LinkStruxHubCompanyMutationHookResult = ReturnType<
  typeof useLinkStruxHubCompanyMutation
>
export type LinkStruxHubCompanyMutationResult =
  Apollo.MutationResult<ILinkStruxHubCompanyMutation>
export type LinkStruxHubCompanyMutationOptions = Apollo.BaseMutationOptions<
  ILinkStruxHubCompanyMutation,
  ILinkStruxHubCompanyMutationVariables
>
export const UnlinkStruxHubCompanyDocument = gql`
  mutation UnlinkStruxHubCompany($companyId: ObjectId!) {
    unlinkStruxHubCompany(companyId: $companyId) {
      id
    }
  }
`
export type IUnlinkStruxHubCompanyMutationFn = Apollo.MutationFunction<
  IUnlinkStruxHubCompanyMutation,
  IUnlinkStruxHubCompanyMutationVariables
>

/**
 * __useUnlinkStruxHubCompanyMutation__
 *
 * To run a mutation, you first call `useUnlinkStruxHubCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkStruxHubCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkStruxHubCompanyMutation, { data, loading, error }] = useUnlinkStruxHubCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUnlinkStruxHubCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUnlinkStruxHubCompanyMutation,
    IUnlinkStruxHubCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUnlinkStruxHubCompanyMutation,
    IUnlinkStruxHubCompanyMutationVariables
  >(UnlinkStruxHubCompanyDocument, options)
}
export type UnlinkStruxHubCompanyMutationHookResult = ReturnType<
  typeof useUnlinkStruxHubCompanyMutation
>
export type UnlinkStruxHubCompanyMutationResult =
  Apollo.MutationResult<IUnlinkStruxHubCompanyMutation>
export type UnlinkStruxHubCompanyMutationOptions = Apollo.BaseMutationOptions<
  IUnlinkStruxHubCompanyMutation,
  IUnlinkStruxHubCompanyMutationVariables
>

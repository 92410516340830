import { IProjectSetupFinished, IUser } from '~/client/graph'
import AccountPosition from '~/client/src/shared/enums/AccountPosition'
import BaseModel from '~/client/src/shared/models/BaseModel'
import Delivery from '~/client/src/shared/models/Delivery'

import Localization from '../../shared/localization/LocalizationManager'
import UserProjectsStore from '../stores/domain/UserProjects.store'
import UserProject from './UserProject'

export const UNKNOWN_USER_NAME = 'Unknown (Deleted) User'
export const UNKNOWN_COMPANY_NAME = 'Unknown (Deleted) Company'

export enum QRResponse {
  allowed = 'allowed',
  notAllowed = 'notAllowed',
  notActivated = 'notActivated',
}

export default class User extends BaseModel<IUser> {
  public static service: User = User.fromDto({
    id: 'service-user',
    firstName: 'StruxHub',
    avatarUrl: '/static/icons/struxhub-black.svg',
  })

  public static getDefaultCompanyName = () =>
    `[${Localization.translator.noCompany}]`

  public static fromDto(u: IUser) {
    return new User(
      u.email,
      u.id,
      u.firstName,
      u.lastName,
      u.reportsToId,
      u.phoneNumber,
      u.setUpFinished,
      u.avatarUrl,
      u.initials,
      u.globalRole,
      u.accountPosition as AccountPosition,
      u.isHidden,
      u.employeeId,
      u.companyAlias,
    )
  }

  public static toDto(u: User): IUser {
    return {
      id: u.id,
      email: u.email || null,
      firstName: u.firstName,
      lastName: u.lastName,
      reportsToId: u.reportsToId || null,
      phoneNumber: u.phoneNumber || null,
      setUpFinished: u.setUpFinished,
      avatarUrl: u.avatarUrl,
      initials: u.initials,
      accountPosition: u.accountPosition,
      globalRole: u.globalRole,
      employeeId: u.employeeId,
      companyAlias: u.companyAlias || null,
    }
  }

  public static getFullName(user: User): string {
    if (!user) {
      return ''
    }

    if (!user.firstName && !user.lastName) {
      return user.email || ''
    }

    return (user.firstName || '') + ' ' + (user.lastName || '')
  }

  public static getFullNameToDisplay(
    user: User,
    userProjectsStore: UserProjectsStore,
    shouldMarkAsUnknownIfNone: boolean = false,
  ): string {
    if (!user) {
      return shouldMarkAsUnknownIfNone
        ? Localization.translator.unknownUser
        : ''
    }

    const fullName = User.getFullName(user)
    const isDeleted = UserProject.isDeleted(user, userProjectsStore)

    return isDeleted
      ? `${fullName} [${Localization.translator.deactivated}]`
      : fullName
  }

  public static getInitialsToDisplay({
    initials,
    firstName,
    lastName,
    email,
  }: any): string {
    let initialsToDisplay = ''

    if (initials) {
      initialsToDisplay = initials
    } else if (firstName || lastName) {
      initialsToDisplay = (firstName || ' ')[0] + (lastName || ' ')[0]
    } else if (email) {
      initialsToDisplay = email.slice(0, 2)
    }

    return initialsToDisplay.toUpperCase().trim()
  }

  public static hasAccountPositionTag(user: User, tagId: string): boolean {
    return user.accountPosition === tagId
  }

  public constructor(
    public readonly email: string,
    public readonly id: string,
    public firstName: string = '',
    public lastName: string = '',
    public reportsToId: string = '',
    public phoneNumber: string = '',
    public setUpFinished: IProjectSetupFinished[] = [],
    public avatarUrl: string = '',
    public initials: string = '',
    public globalRole: string = '',
    public accountPosition: AccountPosition = AccountPosition.Field,
    public isHidden?: boolean,
    public employeeId?: string,
    public readonly companyAlias?: string,
  ) {
    super(id)
  }

  public get fullName() {
    return User.getFullName(this)
  }

  public isDeliveryRequester(delivery: Delivery) {
    return delivery ? this.id === delivery.userId : false
  }

  public get isService(): boolean {
    return this.isSameAs(User.service)
  }
}

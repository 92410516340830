import { observable } from 'mobx'

import { IClosure, ILocationClosureInterval } from '~/client/graph'

import ProjectDateStore, {
  areIntervalTimesIntersects,
  isAfter,
  isWithinRange,
} from '../stores/ui/ProjectDate.store'
import BaseModel from './BaseModel'

export default class Closure extends BaseModel<IClosure> implements IClosure {
  public static fromDto(dto: IClosure) {
    return new Closure(
      dto.id,
      dto.projectId,
      dto.locationId,
      dto.closureInterval,
      dto.associatedPermitId,
      dto.associatedAnnouncementId,
      dto.createdAt,
      dto.updatedAt,
    )
  }

  @observable public locationId: string
  @observable public closureInterval: ILocationClosureInterval
  @observable public associatedPermitId: string
  @observable public associatedAnnouncementId: string

  public constructor(
    public id: string,
    public projectId: string,
    locationId: string,
    closureInterval: ILocationClosureInterval,
    associatedPermitId: string,
    associatedAnnouncementId: string,
    createdAt: number,
    updatedAt: number,
  ) {
    super(id)

    this.locationId = locationId
    this.closureInterval = closureInterval
    this.associatedPermitId = associatedPermitId
    this.associatedAnnouncementId = associatedAnnouncementId

    this.setCreatedAt(createdAt)
    this.setUpdatedAt(updatedAt)
  }

  public isOpenByDate(projectDateStore: ProjectDateStore, date: Date | number) {
    return !isWithinRange(
      projectDateStore.startOfDay(date),
      projectDateStore.startOfDay(this.closureInterval.startDate),
      projectDateStore.endOfDay(this.closureInterval.endDate),
    )
  }

  public isAfter(date: Date | number) {
    return isAfter(this.closureInterval.startDate, date)
  }

  public areWithinSelectedRange(
    projectDateStore: ProjectDateStore,
    startDate: Date,
    endDate: Date,
  ): boolean {
    const { isDailyIntervalIntersectsWithDates } = projectDateStore

    if (this.closureInterval.isDaily) {
      return isDailyIntervalIntersectsWithDates(
        {
          startDate: this.closureInterval.startDate,
          endDate: this.closureInterval.endDate,
          isDaily: this.closureInterval.isDaily,
        },
        startDate,
        endDate,
      )
    }

    return areIntervalTimesIntersects(
      {
        startDate: new Date(this.closureInterval.startDate),
        endDate: new Date(this.closureInterval.endDate),
      },
      {
        startDate,
        endDate,
      },
    )
  }

  public copy(): Closure {
    return new Closure(
      this.id,
      this.projectId,
      this.locationId,
      this.closureInterval,
      this.associatedPermitId,
      this.associatedAnnouncementId,
      this.createdAt,
      this.updatedAt,
    )
  }
}

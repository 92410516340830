import * as React from 'react'

import { observer } from 'mobx-react'

import SitePermit from '../../models/Permit'
import LocationCard from '../SitemapCards/LocationCard'
import MapViewItemBase from '../SitemapHelpers/models/MapViewItemBase'
import GeneralMapPopup from './GeneralMapPopup'

interface IProps {
  item: MapViewItemBase
  openPermit: (permit: SitePermit) => void

  onClose(): void
  currentDate: Date
}

// localization: no text to translate

@observer
export default class LocationMapPopup extends React.Component<IProps> {
  public render() {
    const { onClose, item, openPermit, currentDate } = this.props

    return (
      <GeneralMapPopup
        item={item}
        onClose={onClose}
        customHeader={<></>}
        className="brada10"
      >
        <LocationCard
          item={item}
          openPermit={openPermit}
          currentDate={currentDate}
        />
      </GeneralMapPopup>
    )
  }
}
